import { createSlice } from '@reduxjs/toolkit';

import { EnrolledCourse } from '@/app/modules/enrolled-course/models';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { getEnrolledCourses } from './service';

interface EnrolledCourseState {
  loading: LoadingState;
  error?: string | null;
  enrolledCourses: EnrolledCourse[];
}

const initialState: EnrolledCourseState = {
  loading: LoadingState.Idle,
  error: null,
  enrolledCourses: [],
};

const enrolledCourseSlice = createSlice({
  name: 'enrolledCourse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region Get enrolled courses
    builder
      .addCase(getEnrolledCourses.pending, sharedPendingReducer)
      .addCase(getEnrolledCourses.rejected, sharedRejectedReducer)
      .addCase(getEnrolledCourses.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.enrolledCourses = action.payload.enrolledCourses;
      });
    //#endregion Get enrolled courses
  },
});

export default enrolledCourseSlice;
