import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableDataModel } from '@/app/components/Table/models';
import { Card, CardIndexFilterQueryParams } from '@/app/modules/profile/card/models';

const ENDPOINT_PREFIX = '/ajax/profile/cards';
const ENDPOINT_PAYMENT_METHOD_PREFIX = '/ajax/profile/payment-methods';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  entity: (id: number) => `${ENDPOINT_PAYMENT_METHOD_PREFIX}/${id}`,
};

//#region Get cards
export type GetCardsResponse = {
  cards: TableDataModel<Card[]>;
};

export const getCards = createAsyncThunk('card/getCards', async function (queryParams: CardIndexFilterQueryParams) {
  const response = await axios.get<GetCardsResponse>(ENDPOINTS.resource, {
    params: queryParams,
  });
  return response.data;
});
//#endregion Get cards

//#region Destroy Card
export async function destroyCard(cardID: number) {
  const response = await axios.delete(ENDPOINTS.entity(cardID));

  return response.data;
}
//#endregion Destroy Card
