import { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';

import { KTSVG } from '@/_metronic/helpers/components/KTSVG';

import { PaginationButton } from './PaginationButton';
import { getPages, getPagesCount } from './utils';

type PaginationProps = {
  total: number;
  perPage: number;
  currentPage: number;
  onChange: (page: number) => void | unknown;
  isLoading: boolean;
  shownPagesCount?: number;
};

export function Pagination({ total, perPage, currentPage, onChange, isLoading, shownPagesCount = 5 }: PaginationProps) {
  const pagesCount = getPagesCount(total, perPage);
  const pages = useMemo(
    () => getPages(currentPage, pagesCount, shownPagesCount),
    [currentPage, pagesCount, shownPagesCount]
  );
  const isPagesLoaded = pages.length > 0;
  const isFirstPageHidden = isPagesLoaded && pages?.[0] !== 1;
  const isLastPageHidden = isPagesLoaded && pages?.[pages?.length - 1] !== pagesCount;

  const handleFirstPageClick = useCallback(() => {
    onChange(1);
  }, [onChange]);

  const handleLastPageClick = useCallback(() => {
    onChange(pagesCount);
  }, [onChange, pagesCount]);

  const handlePreviousPageClick = useCallback(() => {
    if (currentPage > 1) {
      onChange(currentPage - 1);
    }
  }, [currentPage, onChange]);

  const handleNextPageClick = useCallback(() => {
    if (currentPage < pagesCount) {
      onChange(currentPage + 1);
    }
  }, [currentPage, onChange, pagesCount]);

  const handlePageClick = useCallback(
    (page: number) => {
      return () => {
        onChange(page);
      };
    },
    [onChange]
  );

  useEffect(
    function handleNoPages() {
      if (!pages.includes(currentPage)) {
        onChange(1);
      }
    },
    [pages, currentPage] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (pages.length === 0) {
    return null;
  }

  return (
    <div className={clsx('d-flex flex-wrap ms-n2', { disabled: pagesCount < 1 || isLoading })}>
      {isFirstPageHidden && (
        <PaginationButton onClick={handleFirstPageClick}>
          <KTSVG path="/img/svg/icons/Navigation/Angle-double-left.svg" />
        </PaginationButton>
      )}

      <PaginationButton onClick={handlePreviousPageClick} isDisabled={currentPage === 1}>
        <KTSVG path="/img/svg/icons/Navigation/Angle-left.svg" />
      </PaginationButton>

      {isFirstPageHidden && (
        <PaginationButton isDisabled isLight={false}>
          ...
        </PaginationButton>
      )}

      {pages.map((page) => (
        <PaginationButton key={page} onClick={handlePageClick(page)} isActive={currentPage === page}>
          {page}
        </PaginationButton>
      ))}

      {isLastPageHidden && (
        <PaginationButton isDisabled isLight={false}>
          ...
        </PaginationButton>
      )}

      <PaginationButton onClick={handleNextPageClick} isDisabled={currentPage === pagesCount}>
        <KTSVG path="/img/svg/icons/Navigation/Angle-right.svg" />
      </PaginationButton>

      {isLastPageHidden && (
        <PaginationButton onClick={handleLastPageClick}>
          <KTSVG path="/img/svg/icons/Navigation/Angle-double-right.svg" />
        </PaginationButton>
      )}
    </div>
  );
}
