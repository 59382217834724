import { FormEvent } from 'react';
import { FormikContextType, FormikValues } from 'formik';

export type StepperInstance = {
  stepInstances: Record<number, StepInstance>;
  currentStep: number | null;
  currentStepInstance: StepInstance | null;
  stepCount: number;
  isSubmitting: boolean;
  nextStep: () => void;
  previousStep: () => void;
  goToStep: (step: number) => void;
  incrementStepCount: () => void;
  decrementStepCount: () => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void | Promise<void>;
  getStepData: (stepKey: string) => FormikValues | null;
  isLoading?: boolean;
};

export interface StepInstance<T = unknown, C = string> {
  formik: FormikContextType<T>;
  stepNumber: number;
  cache?: C | null;
}

export interface CurrentStepInstance<T = unknown> extends StepInstance<T> {
  stepKey: string;
}

export function createCurrentStepInstance<T = unknown>(stepInstance: StepInstance<T>, stepKey: string) {
  return { ...stepInstance, stepKey };
}

export type BaseStepperValue = Record<string, unknown>;
