import { AnalyticsProduct, ConversionEventValues } from '@/app/modules/analytics/models';
import { getCurrentBulgarianTime } from '@/app/modules/analytics/service';
import { CustomEventItem, NewMessageEventItem } from '@/app/utils/google-analytics/models';

export async function transformEcommerceEventToConversionEventValues(
  eventName: string,
  product: AnalyticsProduct
): Promise<ConversionEventValues> {
  const currentBulgarianTime = await getCurrentBulgarianTime();
  const eventTime = currentBulgarianTime.time * 1000;
  const url = window.location.pathname;
  return {
    eventID: `${eventName}_${eventTime}`,
    eventName: eventName,
    eventURL: url,
    products: [product],
  };
}

export async function transformCustomEventToConversionEventValues(
  eventName: string,
  customEventItem?: CustomEventItem
): Promise<ConversionEventValues> {
  const currentBulgarianTime = await getCurrentBulgarianTime();
  const eventTime = currentBulgarianTime.time * 1000;
  const url = window.location.pathname;
  const conversionValues = {
    eventID: `${eventName}_${eventTime}`,
    eventName: eventName,
    eventURL: url,
  };
  if (!customEventItem) {
    return conversionValues;
  }
  return {
    ...conversionValues,
    products: [
      {
        id: customEventItem?.item_id as number,
        name: customEventItem?.item_name as string,
        brand: customEventItem?.item_brand as string,
        category: `${customEventItem?.item_category}/${customEventItem?.item_category2}` as string,
      },
    ],
  };
}

export async function transformEcommerceEventToMultipleConversionEventValues(
  eventName: string,
  products: AnalyticsProduct[]
): Promise<ConversionEventValues> {
  const currentBulgarianTime = await getCurrentBulgarianTime();
  const eventTime = currentBulgarianTime.time * 1000;
  const url = window.location.pathname;
  return {
    eventID: `${eventName}_${eventTime}`,
    eventName: eventName,
    eventURL: url,
    products: products,
  };
}

export async function transformNewMessageEventToConversionEventValues(
  eventName: string,
  newMessage: NewMessageEventItem
): Promise<ConversionEventValues> {
  const currentBulgarianTime = await getCurrentBulgarianTime();
  const eventTime = currentBulgarianTime.time * 1000;
  const url = window.location.pathname;

  return {
    eventID: `${eventName}_${eventTime}`,
    eventName: eventName,
    eventURL: url,
    newMessage: newMessage,
  };
}
