import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_DURATION, DEFAULT_RESERVATION_BUFFER_HOURS } from '@/app/components/Calendar/constants';
import { EditableTimeSlotEntity, TimeSlotEntity } from '@/app/components/Calendar/models';
import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { getCanceledReservationDetails } from '../reservation/service';
import {
  CanceledReservationDetails,
  CourseCheckoutData,
  CourseDetailsData,
  CourseFreeStudentProposalData,
  CourseReservationData,
  CourseReviewData,
  CourseStudentProposal,
  FirstCourseEditData,
  RecurrenceType,
  Subject,
  TargetAudience,
  TutorWithCourse,
} from './models';
import {
  getConnectedStudentScheduleData,
  getCourse,
  getCourseCheckoutData,
  getCourseCreateData,
  getCourseEditData,
  getCourseFeedbacks,
  getCourseFreeStudentProposalData,
  getCourseReservationData,
  getCourseReservationTimeSlotsData,
  getCourseReviewData,
  getCourses,
  getCourseScheduleData,
  GetCoursesResponse,
  getCourseStudentProposalData,
} from './service';

interface CourseState {
  loading: LoadingState;
  scheduleLoading: LoadingState;
  paymentIntentLoading: LoadingState;
  error?: string | null;
  reservationBufferHours: number | string | null;
  duration: number | string | null;
  canChangeCourseDuration: boolean;
  canUseLegacyCourseDuration: boolean;
  blockNewStudentsReservations: 0 | 1;
  courseFreeStudentProposalData: CourseFreeStudentProposalData;
  subjects: Subject[];
  recurrenceTypes: RecurrenceType[];
  timeSlots: TimeSlotEntity[] | EditableTimeSlotEntity[];
  hasValidTimeSlotsForApproval: boolean;
  lastTimeSlotUpdatedAt: string | null;
  index: {
    isPreloaded: boolean;
    subjectName: string | null;
    subjectDescription: string | null;
    targetAudienceName: string | null;
    tutorsWithCoursesTotal: number;
    tutorsWithCourses: TutorWithCourse[];
    similarCourses: TutorWithCourse[];
    allTargetAudiences: TargetAudience[];
    allSubjectKeywords: SubjectKeyword[];
    hasVisitedPLBefore: boolean;
    subjects: Subject[];
  };
  show: CourseDetailsData;
  edit: FirstCourseEditData;
  reservation: CourseReservationData;
  canceledReservationDetails: CanceledReservationDetails;
  checkout: CourseCheckoutData;
  reviews: CourseReviewData;
  studentProposal: CourseStudentProposal | null;
}

declare global {
  interface SSRStore {
    course: {
      index: GetCoursesResponse;
    };
  }
}

function setIndexData(state: CourseState, payload: GetCoursesResponse) {
  state.index.subjectName = payload.subjectName;
  state.index.subjectDescription = payload.subjectDescription;
  state.index.targetAudienceName = payload.targetAudienceName;
  state.index.tutorsWithCoursesTotal = payload.tutorsWithCoursesTotal;
  state.index.tutorsWithCourses = payload.tutorsWithCourses;
  state.index.similarCourses = payload.similarCourses;
  state.index.allTargetAudiences = payload.allTargetAudiences;
  state.index.allSubjectKeywords = payload.allSubjectKeywords;
  state.index.hasVisitedPLBefore = payload.hasVisitedPLBefore;
  state.index.subjects = payload.subjects;
}

export function createInitialState(): CourseState {
  const initialState: CourseState = {
    loading: LoadingState.Idle,
    scheduleLoading: LoadingState.Idle,
    paymentIntentLoading: LoadingState.Idle,
    error: null,
    reservationBufferHours: DEFAULT_RESERVATION_BUFFER_HOURS,
    duration: DEFAULT_DURATION,
    canChangeCourseDuration: true,
    canUseLegacyCourseDuration: false,
    blockNewStudentsReservations: 0,
    courseFreeStudentProposalData: {
      id: 0,
      name: '',
      currentRating: 0,
      tutorIdentityID: 0,
      tutorName: '',
      tutorProfileImagePath: '',
    },
    subjects: [],
    recurrenceTypes: [],
    timeSlots: [],
    hasValidTimeSlotsForApproval: false,
    lastTimeSlotUpdatedAt: null,
    index: {
      isPreloaded: false,
      subjectName: null,
      subjectDescription: null,
      targetAudienceName: null,
      tutorsWithCoursesTotal: 0,
      tutorsWithCourses: [],
      similarCourses: [],
      allTargetAudiences: [],
      allSubjectKeywords: [],
      hasVisitedPLBefore: false,
      subjects: [],
    },
    show: {
      tutor: {
        name: null,
        identityID: null,
        profileFile: null,
        videoFile: null,
        about: [],
        hasAvailableFutureTimeSlots: true,
      },
      additionalTutorInfo: {
        reactionTime: null,
        numberStudents: null,
        numberReservedLessons: null,
        uniqueVisitsCount: null,
      },
      course: {
        name: null,
        goals: '',
        methodology: '',
        skills: '',
        content: null,
        description: EMPTY_VALUE,
        targetAudienceName: null,
        targetAudienceTransliteratedSlug: null,
        currentRating: null,
        subjects: [],
        ratingCount: 0,
        lessonDuration: null,
        keywords: [],
      },
      courseSubjectTransliteratedSlug: null,
      availableLessonCount: 0,
      initialCoursePackageID: null,
      individualLesson: null,
      coursePackages: [],
      isCourseOwner: false,
      canBuyCourse: false,
      isFavorite: false,
      canReturnIndividualLessonBonus: false,
      canRefund: false,
      firstAvailableTimeSlotDateTime: null,
    },
    edit: {
      course: {
        courseName: '',
        description: [],
        goals: '',
        methodology: '',
        content: '',
        keywords: [],
        skills: '',
        subjectID: null,
        subjectName: '',
        targetAudienceID: null,
        targetAudienceName: null,
        individualLesson: null,
        coursePackages: [],
      },
      duration: DEFAULT_DURATION,
      reservationBufferHours: DEFAULT_RESERVATION_BUFFER_HOURS,
      canChangeCourseDuration: true,
      canUseLegacyCourseDuration: false,
      hasBlockedNewStudentsReservations: false,
      subjects: [],
      recurrenceTypes: [],
      lastTimeSlotUpdatedAt: null,
      blockNewStudentsReservations: 0,
      hasValidTimeSlotsForApproval: false,
    },
    reservation: {
      course: {
        name: '',
        courseStatusID: 0,
        tutorID: null,
        tutorName: '',
        tutorProfileImagePath: null,
        currentRating: null,
        availableLessonCount: 0,
        courseTransliteratedSlug: '',
        targetAudienceName: '',
        subjectName: '',
      },
      preselectedTimeSlot: null,
      timeSlots: [],
      defaultConnectedStudent: {
        name: '',
        phone: '',
        email: '',
        connectedStudentLevelID: null,
      },
      connectedStudents: [],
      connectedStudentLevels: [],
      reservationConnectedStudentIds: [],
    },
    canceledReservationDetails: {
      course: {
        id: null,
        name: '',
      },
      reservation: null,
      isStudent: null,
      promoCode: null,
    },
    checkout: {
      course: {
        name: null,
        currentRating: null,
        tutorName: undefined,
        tutorProfileImagePath: undefined,
        targetAudienceName: '',
        subjects: [],
        tutorIdentityID: null,
      },
      coursePackages: [],
      paymentMethods: [],
      timeSlotsCount: 0,
    },
    reviews: {
      loading: LoadingState.Idle,
      courseRatingStats: [],
      courseSummary: {
        currentRating: 0,
        ratingCount: 0,
      },
      courseFeedbacks: {
        loading: LoadingState.Idle,
        data: [],
        currentPage: 1,
        hasMore: true,
      },
      canLeaveFeedback: false,
      shouldLeaveFeedback: false,
    },
    studentProposal: null,
  };

  if (import.meta.env.SSR && window?.context?.store?.course?.index) {
    initialState.index.isPreloaded = true;
    setIndexData(initialState, window?.context?.store?.course?.index);
  }

  return initialState;
}

const courseSlice = createSlice({
  name: 'course',
  initialState: createInitialState(),
  reducers: {
    resetCourseIndexPage(state) {
      state.index = createInitialState().index;
    },
    resetCourseDetailsPage(state) {
      state.show = createInitialState().show;
    },
    resetCourseCheckoutPage(state) {
      state.checkout = createInitialState().checkout;
    },
    resetCourseReviewsSection(state) {
      state.reviews = createInitialState().reviews;
    },
    resetCourseReservationPage(state) {
      state.reservation = createInitialState().reservation;
    },
    setCourseReviewsCurrentPage(state, action: PayloadAction<number>) {
      state.reviews.courseFeedbacks.currentPage = action.payload;
    },
    resetCourseStudentProposal(state) {
      state.studentProposal = null;
    },
    changePaymentIntentLoadingState(state, action: PayloadAction<LoadingState>) {
      state.paymentIntentLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    //#region Get show course
    builder
      .addCase(getCourse.pending, sharedPendingReducer)
      .addCase(getCourse.rejected, sharedRejectedReducer)
      .addCase(getCourse.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.show = action.payload;
      });
    //#endregion Get show course

    //#region Get edit course
    builder
      .addCase(getCourseEditData.pending, sharedPendingReducer)
      .addCase(getCourseEditData.rejected, sharedRejectedReducer)
      .addCase(getCourseEditData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.edit.course.courseName = action.payload.course.courseName;
        state.edit.course.description = action.payload.course.description;
        state.edit.course.goals = action.payload.course.goals;
        state.edit.course.methodology = action.payload.course.methodology;
        state.edit.course.content = action.payload.course.content;
        state.edit.course.keywords = action.payload.course.keywords;
        state.edit.course.skills = action.payload.course.skills;
        state.edit.course.subjectID = action.payload.course.subjectID;
        state.edit.course.subjectName = action.payload.course.subjectName;
        state.edit.course.targetAudienceID = action.payload.course.targetAudienceID;
        state.edit.course.targetAudienceName = action.payload.course.targetAudienceName;
        state.edit.course.individualLesson = action.payload.course.individualLesson;
        state.edit.course.coursePackages = action.payload.course.coursePackages;

        state.edit.reservationBufferHours = action.payload.reservationBufferHours;
        state.edit.canChangeCourseDuration = action.payload.canChangeCourseDuration;
        state.edit.hasBlockedNewStudentsReservations = action.payload.hasBlockedNewStudentsReservations;
        state.edit.duration = action.payload.duration;
        state.edit.blockNewStudentsReservations = action.payload.blockNewStudentsReservations;
        state.edit.subjects = action.payload.subjects;
        state.edit.recurrenceTypes = action.payload.recurrenceTypes;
        state.edit.lastTimeSlotUpdatedAt = action.payload.lastTimeSlotUpdatedAt;
      });
    //#endregion Get edit course

    //#region Get index courses
    builder
      .addCase(getCourses.pending, sharedPendingReducer)
      .addCase(getCourses.rejected, sharedRejectedReducer)
      .addCase(getCourses.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        setIndexData(state, action.payload);
      });
    //#endregion Get index courses

    //#region Get course create data
    builder
      .addCase(getCourseCreateData.pending, sharedPendingReducer)
      .addCase(getCourseCreateData.rejected, sharedRejectedReducer)
      .addCase(getCourseCreateData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.duration = action.payload.duration;
        state.reservationBufferHours = action.payload.reservationBufferHours;
        state.canChangeCourseDuration = action.payload.canChangeCourseDuration;
        state.canUseLegacyCourseDuration = action.payload.canUseLegacyCourseDuration;
        state.blockNewStudentsReservations = action.payload.hasBlockedNewStudentsReservations ? 1 : 0;
        state.subjects = action.payload.subjects;
        state.recurrenceTypes = action.payload.recurrenceTypes;
        state.lastTimeSlotUpdatedAt = action.payload.lastTimeSlotUpdatedAt;
      });
    //#endregion Get course create data

    //#region Get course reservation data
    builder
      .addCase(getCourseReservationData.pending, sharedPendingReducer)
      .addCase(getCourseReservationData.rejected, sharedRejectedReducer)
      .addCase(getCourseReservationData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.reservation.course = action.payload.course;
        state.reservation.defaultConnectedStudent = action.payload.defaultConnectedStudent;
        state.reservation.reservationConnectedStudentIds = action.payload.reservationConnectedStudentIds;
        state.reservation.connectedStudents = action.payload.connectedStudents;
        state.reservation.connectedStudentLevels = action.payload.connectedStudentLevels;
        state.reservation.preselectedTimeSlot = action.payload.preselectedTimeSlot;
      });
    //#endregion Get course reservation data

    //#region Get course reservation time slot data
    builder
      .addCase(getCourseReservationTimeSlotsData.pending, (state) => {
        if (state.scheduleLoading === LoadingState.Idle) {
          state.scheduleLoading = LoadingState.Pending;
        }
      })
      .addCase(getCourseReservationTimeSlotsData.rejected, (state, action) => {
        if (state.scheduleLoading === LoadingState.Pending) {
          state.scheduleLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getCourseReservationTimeSlotsData.fulfilled, (state, action) => {
        state.scheduleLoading = LoadingState.Idle;
        state.reservation.timeSlots = action.payload.timeSlots;
      });
    //#endregion Get course reservation time slot data

    //#region Get course free student proposal data
    builder
      .addCase(getCourseFreeStudentProposalData.pending, sharedPendingReducer)
      .addCase(getCourseFreeStudentProposalData.rejected, sharedRejectedReducer)
      .addCase(getCourseFreeStudentProposalData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseFreeStudentProposalData = action.payload.course;
      });
    //#endregion Get course free student proposal data

    //#region Get canceled reservation details
    builder
      .addCase(getCanceledReservationDetails.pending, sharedPendingReducer)
      .addCase(getCanceledReservationDetails.rejected, sharedRejectedReducer)
      .addCase(getCanceledReservationDetails.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.canceledReservationDetails.course = action.payload.course;
        state.canceledReservationDetails.reservation = action.payload.reservation;
        state.canceledReservationDetails.isStudent = action.payload.isStudent;
        state.canceledReservationDetails.promoCode = action.payload.promoCode;
      });
    //#endregion Get canceled reservation details

    //#region Get course checkout data
    builder
      .addCase(getCourseCheckoutData.pending, sharedPendingReducer)
      .addCase(getCourseCheckoutData.rejected, sharedRejectedReducer)
      .addCase(getCourseCheckoutData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.checkout = action.payload;
      });
    //#endregion Get course checkout data

    //#region Get course schedule data
    builder
      .addCase(getCourseScheduleData.pending, (state) => {
        if (state.scheduleLoading === LoadingState.Idle) {
          state.scheduleLoading = LoadingState.Pending;
        }
      })
      .addCase(getCourseScheduleData.rejected, (state, action) => {
        if (state.scheduleLoading === LoadingState.Pending) {
          state.scheduleLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getCourseScheduleData.fulfilled, (state, action) => {
        state.scheduleLoading = LoadingState.Idle;
        state.duration = action.payload.duration;
        state.timeSlots = action.payload.timeSlots;
        state.hasValidTimeSlotsForApproval = action.payload.hasValidTimeSlotsForApproval;
        state.blockNewStudentsReservations = action.payload.hasBlockedNewStudentsReservations ? 1 : 0;
      });
    //#endregion Get course schedule data

    //#region Get course review data
    builder
      .addCase(getCourseReviewData.pending, (state) => {
        if (state.reviews.loading === LoadingState.Idle) {
          state.reviews.loading = LoadingState.Pending;
        }
      })
      .addCase(getCourseReviewData.rejected, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.reviews.loading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getCourseReviewData.fulfilled, (state, action) => {
        state.reviews.loading = LoadingState.Idle;
        state.reviews.courseSummary = action.payload.courseSummary;
        state.reviews.courseRatingStats = action.payload.courseRatingStats;
        state.reviews.courseFeedbacks.data = action.payload.courseFeedbacks.data;
        state.reviews.courseFeedbacks.currentPage = action.payload.courseFeedbacks.currentPage;
        state.reviews.courseFeedbacks.hasMore = action.payload.courseFeedbacks.hasMore;
        state.reviews.canLeaveFeedback = action.payload.canLeaveFeedback;
        state.reviews.shouldLeaveFeedback = action.payload.shouldLeaveFeedback;
      });
    //#endregion Get course review data

    //#region Get course feedbacks for course show page
    builder
      .addCase(getCourseFeedbacks.pending, (state) => {
        if (state.reviews.loading === LoadingState.Idle) {
          state.reviews.loading = LoadingState.Pending;
        }
      })
      .addCase(getCourseFeedbacks.rejected, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.reviews.loading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getCourseFeedbacks.fulfilled, (state, action) => {
        state.reviews.loading = LoadingState.Idle;
        state.reviews.courseFeedbacks.data = action.payload.data;
        state.reviews.courseFeedbacks.currentPage = action.payload.currentPage;
        state.reviews.courseFeedbacks.hasMore = action.payload.hasMore;
      });
    //#endregion Get course feedback for course show page

    //#region Get connected student schedule data
    builder
      .addCase(getConnectedStudentScheduleData.pending, sharedPendingReducer)
      .addCase(getConnectedStudentScheduleData.rejected, sharedRejectedReducer)
      .addCase(getConnectedStudentScheduleData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.reservation.timeSlots = action.payload.timeSlots;
      });
    //#endregion Get connected student schedule data

    //#region Get course student proposal data
    builder
      .addCase(getCourseStudentProposalData.pending, sharedPendingReducer)
      .addCase(getCourseStudentProposalData.rejected, sharedRejectedReducer)
      .addCase(getCourseStudentProposalData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.duration = action.payload.duration;
        state.timeSlots = action.payload.timeSlots;
        state.studentProposal = action.payload.studentProposal;
      });
    //#endregion Get course student proposal data
  },
});

export default courseSlice;
