import { createSlice } from '@reduxjs/toolkit';

import { Select2Option } from '@/app/components/SelectControl/models';
import { ProfileTimeline, TimelineEvent } from '@/app/models/Timeline';
import { getProfileTimelines, getProfileTimelineShowData } from '@/app/modules/profile/timeline/service';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

interface ProfileTimelineState {
  loading: LoadingState;
  error?: string | null;
  timeline: ProfileTimeline;
  timelineEvents: TimelineEvent[];
  timelines: ProfileTimeline[];
  timelineEventTypes: Select2Option[];
  timeSlots: Select2Option[];
  hasMore: boolean;
  currentPage: number;
}

const initialState: ProfileTimelineState = {
  loading: LoadingState.Idle,
  error: null,
  timeline: {
    timelineID: null,
    courseName: '',
    connectedStudentName: '',
    tutorName: '',
    tutorIdentityID: null,
    studentName: '',
    studentIdentityID: null,
  },
  timelineEvents: [],
  timelines: [],
  currentPage: 1,
  hasMore: true,
  timelineEventTypes: [],
  timeSlots: [],
};

const profileTimelineSlice = createSlice({
  name: 'profileTimeline',
  initialState,
  reducers: {
    loadMore(state) {
      if (state.loading === LoadingState.Idle && state.hasMore) {
        state.currentPage++;
      }
    },
    resetTimelineEvents(state) {
      state.timelineEvents = [];
      state.currentPage = 1;
      state.hasMore = true;
    },
  },
  extraReducers: (builder) => {
    //#region GET PROFILE TIMELINE INDEX
    builder
      .addCase(getProfileTimelines.pending, sharedPendingReducer)
      .addCase(getProfileTimelines.rejected, sharedRejectedReducer)
      .addCase(getProfileTimelines.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.timelines = action.payload.timelines;
        }
      });
    //#endregion GET PROFILE TIMELINE INDEX

    //#region GET PROFILE TIMELINE SHOW DATA
    builder
      .addCase(getProfileTimelineShowData.pending, sharedPendingReducer)
      .addCase(getProfileTimelineShowData.rejected, sharedRejectedReducer)
      .addCase(getProfileTimelineShowData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.timeline = action.payload.timeline;
          if (action.payload.currentPage >= state.currentPage) {
            for (const timelineEvent of action.payload.timelineEvents) {
              const idx = state.timelineEvents.findIndex((x) => x.id === timelineEvent.id);

              if (idx === -1) {
                state.timelineEvents.push(timelineEvent);
              }
            }
          }
          state.hasMore = action.payload.hasMore;
          state.currentPage = action.payload.currentPage;
          state.timelineEventTypes = action.payload.timelineEventTypes;
          state.timeSlots = action.payload.timeSlots;
        }
      });
    //#endregion GET PROFILE TIMELINE SHOW DATA
  },
});

export default profileTimelineSlice;
