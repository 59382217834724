import { RenderElementProps } from 'slate-react';

import { LinkElement } from '@/app/components/RichTextControl/LinkElement';

export function Element({ attributes, children, element }: RenderElementProps) {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return (
        <LinkElement attributes={attributes} element={element}>
          {children}
        </LinkElement>
      );
    case 'align-left':
    case 'align-right':
    case 'align-center':
    case 'align-justify':
      return (
        <p {...attributes} style={{ textAlign: element.type.split('-')[1] as CanvasTextAlign }}>
          {children}
        </p>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
}
