import React, { useEffect, useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { CardToolbar } from '@/app/components/Card/CardToolbar';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { Table } from '@/app/components/Table/Table';
import { formatPrice } from '@/app/utils/money';
import { useQueryParams } from '@/app/utils/query';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import {
  DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
  DEFAULT_PAGE_VALUE,
  ITEMS_PER_PAGE_OPTIONS,
  PaymentIndexFilterInitialValues,
} from './constants';
import { PaymentIndexFilterQueryParams, PaymentIndexFilterValues } from './models';
import { getProfilePayments } from './service';
import paymentsSlice from './store';
import { transformPaymentIndexFormikValuesToSearchParams } from './utils';

const columns: ColumnDescription[] = [
  {
    dataField: 'createdAt',
    text: 'Дата',
  },
  {
    dataField: 'courseName',
    text: 'Обучение',
    classes: 'text-break',
  },
  {
    dataField: 'tutorName',
    text: 'Обучител',
    classes: 'text-break',
  },
  {
    dataField: 'lessonCount',
    text: 'Брой уроци',
  },
  {
    dataField: 'paymentTransactionAmount',
    text: 'Сума',
    formatter: formatPrice,
  },
  {
    dataField: 'paymentMethodCard',
    text: 'Платено с карта',
  },
  {
    dataField: 'paymentTransactionStatus',
    text: 'Статус',
  },
];

export function ProfilePaymentIndexPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const content = useAppSelector((state) => state.profilePayment);
  const [loadingState, isInitialDataLoaded, setLoadingState] = useLoading(content.loading);
  const { currentSearchParams, queryParams } = useQueryParams<PaymentIndexFilterQueryParams>();

  useEffect(
    function handleFetchingData() {
      if (content.loading === LoadingState.Idle) {
        dispatch(getProfilePayments(queryParams));
      }
    },
    [queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#region Handle pagination form
  const initialValues = useMemo<PaymentIndexFilterValues>(
    () => ({
      page: queryParams.page ?? DEFAULT_PAGE_VALUE,
      itemsPerPage: queryParams?.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
    }),
    [queryParams]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<PaymentIndexFilterValues>({
    initialValues,
    validationSchema,
    initialStatus: loadingState,
  });
  //#endregion Handle pagination form

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformPaymentIndexFormikValuesToSearchParams(
        formik.values,
        formik.initialValues.itemsPerPage,
        queryParams
      );

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
        setLoadingState(LoadingState.Pending);
      }

      if (newSearchParams === '') {
        formik.setValues(PaymentIndexFilterInitialValues);
      }
    },
    [content, currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  //#region Handle page change
  useEffect(
    function handlePageChange() {
      formik.setFieldValue('page', content.currentPage);
    },
    [content.currentPage] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle page change

  return (
    <>
      {!isInitialDataLoaded && <PageTitle className="my-2">Зарежда се...</PageTitle>}
      {isInitialDataLoaded && (
        <>
          <Helmet>
            <title>Плащания</title>
          </Helmet>
          <PageTop className="mb-9">
            <PageTitle>Плащания</PageTitle>
          </PageTop>
          <Card>
            <CardHeader isRow>
              <CardTitle>Списък с плащания</CardTitle>
              <CardToolbar>
                <Form formik={formik} skipDirtyPrompt>
                  <FormGroup>
                    <Label htmlFor="itemsPerPage" className="mb-0 me-3">
                      Резултати на страница:
                    </Label>
                    <SelectControl
                      name="itemsPerPage"
                      options={ITEMS_PER_PAGE_OPTIONS}
                      isSearchable={false}
                      hasSolidBackground={false}
                      className="w-175px"
                    />
                  </FormGroup>
                </Form>
              </CardToolbar>
            </CardHeader>
            <CardBody>
              <Table
                columns={columns}
                data={content.transactions}
                isLoading={loadingState === LoadingState.Pending}
                setCurrentPageAction={paymentsSlice.actions.setCurrentPage}
                noDataIndication={
                  <>
                    <p className="mb-0 text-center">Все още нямате направени плащания.</p>
                  </>
                }
              />
            </CardBody>
          </Card>
        </>
      )}
      {loadingState === LoadingState.Pending && <GlobalSpinner />}
    </>
  );
}
