import { Button } from '@/app/components/Button/Button';
import { markAllNotificationsRead } from '@/app/modules/notification/service';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { NotificationsList } from '../modules/notification/NotificationsList';
import { HeaderMenu } from './HeaderMenu/HeaderMenu';
import { HeaderMenuButton } from './HeaderMenu/HeaderMenuButton';
import { HeaderMenuButtonBadge } from './HeaderMenu/HeaderMenuButtonBadge';
import { HeaderMenuButtonIcon } from './HeaderMenu/HeaderMenuButtonIcon';
import { HeaderMenuContent } from './HeaderMenu/HeaderMenuContent';
import { HeaderMenuDropdown } from './HeaderMenu/HeaderMenuDropdown';
import { HeaderMenuTitle } from './HeaderMenu/HeaderMenuTitle';

type HeaderNotificationsMenuProps = {
  className?: string;
};

export function HeaderNotificationsMenu({ className }: HeaderNotificationsMenuProps) {
  const dispatch = useAppDispatch();
  const unreadNotificationsCount = useAppSelector((state) => state.notification.unreadNotificationsCount);
  const notifications = useAppSelector((state) => state.notification.notifications);

  function handleNotificationReadAllClick() {
    if (unreadNotificationsCount > 0) {
      dispatch(markAllNotificationsRead(notifications[0].createdAt));
    }
  }

  return (
    <HeaderMenu className={className}>
      <HeaderMenuButton>
        <HeaderMenuButtonIcon path="/img/svg/icons/General/Notifications.svg" />
        {unreadNotificationsCount > 0 && <HeaderMenuButtonBadge>{unreadNotificationsCount}</HeaderMenuButtonBadge>}
      </HeaderMenuButton>

      <HeaderMenuDropdown>
        <div className="d-flex justify-content-between">
          <HeaderMenuTitle>Известия</HeaderMenuTitle>
          <Button variant="link" className="me-4 fs-7" onClick={handleNotificationReadAllClick}>
            Маркирай всички като прочетени
          </Button>
        </div>
        <HeaderMenuContent>
          <NotificationsList />
        </HeaderMenuContent>
      </HeaderMenuDropdown>
    </HeaderMenu>
  );
}
