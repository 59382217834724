import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '@/app/components/Button/Button';
import { handleValidation } from '@/app/components/Form/utils';
import {
  NotificationUnsubscribeQueryParams,
  ProfileNotificationFormValues,
} from '@/app/modules/profile/notifications/models';
import {
  getNotificationUnsubscribeData,
  getProfileGeneralNotifications,
  getProfileTutorNotifications,
  updateProfileNotificationSetting,
} from '@/app/modules/profile/notifications/services';
import { useQueryParams } from '@/app/utils/query';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

type NotificationUnsubscribeModalProps = {
  onClose: typeof getProfileGeneralNotifications | typeof getProfileTutorNotifications;
};

export function NotificationUnsubscribeModal({ onClose }: NotificationUnsubscribeModalProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useAppSelector((state) => state.profileNotifications.unsubscribe.loading);
  const isLoading = loading !== LoadingState.Idle;
  const authIdentityID = useAppSelector((state) => state.auth.identityID);
  const { email, notificationTypeName, notificationTypeChannelID } = useAppSelector(
    (state) => state.profileNotifications.unsubscribe
  );
  const { queryParams } = useQueryParams<NotificationUnsubscribeQueryParams>();
  const isValidIdentityID = authIdentityID === Number(queryParams?.identityID);

  const [isShown, setIsShown] = useState<boolean>(
    Boolean(authIdentityID) && Boolean(queryParams?.notificationTypeID) && Boolean(queryParams?.identityID)
  );

  useEffect(function () {
    if (loading === LoadingState.Idle && Boolean(queryParams?.notificationTypeID && queryParams?.identityID)) {
      dispatch(getNotificationUnsubscribeData(queryParams));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleNotificationUnSubscribeModalClose() {
    setIsShown(false);
    history.replace({ search: '' });
  }

  async function handleNotificationUnSubscribeModalConfirm() {
    const handleSubmit = handleValidation<ProfileNotificationFormValues>(
      async (values: ProfileNotificationFormValues) => {
        return await updateProfileNotificationSetting(values);
      }
    );
    await handleSubmit({ typeChannelID: Number(notificationTypeChannelID), isActive: false });

    dispatch(onClose());
    setIsShown(false);
  }

  return (
    <Modal show={isShown} onHide={handleNotificationUnSubscribeModalClose} contentClassName="text-center" centered>
      <Modal.Header className="flex-wrap position-relative">
        <Modal.Title className="flex-grow-1 px-10 fs-2x fw-bold">Отписване от известие</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isValidIdentityID && (
          <>
            <p className="fs-5">
              Не може да се отпишете от това имейл известие с този потребител. Моля, влезте с потребител{' '}
              <strong>{email}</strong>
            </p>
            <div className="d-flex justify-content-center flex-wrap mt-7 font-weight-bold font-size-h6">
              <Button variant="primary" onClick={handleNotificationUnSubscribeModalClose} disabled={isLoading}>
                Затваряне
              </Button>
            </div>
          </>
        )}
        {isValidIdentityID && (
          <>
            <p className="fs-5">
              Сигурни ли сте, че искате да се отпишете от тип известие <strong>{notificationTypeName}</strong>?
            </p>
            <div className="d-flex justify-content-center flex-wrap mt-7 font-weight-bold font-size-h6">
              <Button
                variant="secondary"
                onClick={handleNotificationUnSubscribeModalConfirm}
                isLoading={isLoading}
                className="me-5 mb-3 mb-sm-0"
              >
                Да
              </Button>
              <Button variant="primary" onClick={handleNotificationUnSubscribeModalClose} disabled={isLoading}>
                Не
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
