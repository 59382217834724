import { HTMLAttributes } from 'react';
import BootstrapTable, {
  ColumnDescription,
  ExpandColumnRendererProps,
  ExpandHeaderColumnRenderer,
} from 'react-bootstrap-table-next';

import { formatPrice } from '@/app/utils/money';

import { ExpectedIncome } from './models';

import './ExpectedIncomeExpandedRow.scss';

const columns: ColumnDescription[] = [
  {
    dataField: 'name',
    text: 'От пакет',
    formatter: function CoursePackageNameColumn(coursePackageName: string, expectedIncome: ExpectedIncome) {
      const spanProps: HTMLAttributes<HTMLSpanElement> = {};

      if (expectedIncome.willBeBlocked) {
        spanProps.title = 'Ще се изисква декларация за осигурителния Ви доход за тази покупка';
      }

      return (
        <span {...spanProps}>
          {Boolean(expectedIncome.willBeBlocked) && (
            <i className="fa-solid fa-triangle-exclamation text-warning me-2" />
          )}{' '}
          {coursePackageName}
        </span>
      );
    },
  },
  {
    dataField: 'lessonCount',
    text: 'Брой уроци',
  },
  {
    dataField: 'incomePerLesson',
    text: 'Приход от урок',
    formatter: formatPrice,
  },
  {
    dataField: 'income',
    text: 'Общ приход',
    formatter: formatPrice,
  },
];

export function ExpectedIncomeExpandedRow(row: ExpectedIncome) {
  return (
    <BootstrapTable
      keyField="id"
      columns={columns}
      data={row.packages}
      wrapperClasses="expected-income-expanded-table ms-7"
      bootstrap4
    />
  );
}

export function ExpectedIncomeHeaderRenderer({ isAnyExpands }: ExpandHeaderColumnRenderer) {
  if (isAnyExpands) {
    return <i className="far fa-chevron-double-down" />;
  }
  return <i className="far fa-chevron-double-right" />;
}

export function ExpectedIncomeColumnRenderer({ expanded }: ExpandColumnRendererProps) {
  if (expanded) {
    return <i className="far fa-chevron-down" />;
  }
  return <i className="far fa-chevron-right" />;
}
