/* eslint-disable simple-import-sort/imports */
import './fullCalendarVdom';
import FullCalendar, {
  ClassNamesGenerator,
  CustomContentGenerator,
  DatesSetArg,
  EventApi,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventMountArg,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import bgLocale from '@fullcalendar/core/locales/bg';
/* eslint-enable simple-import-sort/imports */
import { forwardRef, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import composeRefs from '@seznam/compose-react-refs';
import { add, format, isAfter, isBefore, isEqual, isSameDay, parse } from 'date-fns';
import produce from 'immer';
import clsx from 'clsx';
import cuid from 'cuid';
import Toastr from 'toastr';

import {
  CALENDAR_CALLBACK_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '@/app/components/Form/DatePickerControl/constants';
import { RecurrenceType } from '@/app/modules/course/models';
import { isString } from '@/app/utils/isString';
import { transformIndividualCalenderEventToRecurringCalenderEvent } from '@/app/modules/course/components/steps/utils';
import { EventTypeActionModal } from '@/app/modules/course/forms/EventTypeActionModal';
import { CalendarEventType, ScheduleStepModel } from '@/app/modules/course/create/models';
import { MEDIUM_BREAKPOINTS, SIZE_XS, useBreakpoint } from '@/app/utils/useBreakpoint';

import { AddEventModal, useAddEventModal } from './AddEventModal';
import { EditEventModal, useEditEventModal } from './EditEventModal';
import {
  CalendarEvent,
  CalendarEventFormValues,
  EditableTimeSlotEntity,
  RecurrenceTypeID,
  TimeSlotProposalPriceTypeID,
} from './models';
import {
  AVAILABLE_TIME_GROUP_ID,
  DEFAULT_DURATION,
  EVENT_EDIT_INTERVAL_VALIDATION_ERROR_MESSAGE,
  EVENT_OVERLAP_ERROR_MESSAGE,
  INTERVAL_RECURRENCE_TYPE_IDS,
  RECURRENCE_TYPE_IDS,
  SLOT_MAX_TIME,
  SLOT_MIN_TIME,
} from './constants';
import {
  calculateIntervalCalendarEventDayDuration,
  generateIdentifiableEventClassName,
  hasTimeSlotsOutOfDayLimit,
  isCalendarEventOverlapAnyIntervalTreeNode,
  transformCalendarEventsToIntervalTree,
  transformCalendarEventToFormValues,
} from './utils';
import { MobileMonthCellContent } from './MobileMonthCellContent';
import { DayHeaderContent } from './DayHeaderContent';

import './Calendar.scss';
import { FormikContextType } from 'formik';
import { DAYS_FOR_TUTOR_APPROVAL } from '@/app/constants';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { getHolidays } from '@/app/modules/schedule/service';
import { Skeleton } from '@/app/components/Skeleton/Skeleton';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';

type CalendarProps = {
  onAddEvent?: (calendarEvent: CalendarEvent) => void | undefined | Promise<void>;
  onChangeEvent?: (calendarEvent: CalendarEvent) => void | undefined | Promise<void>;
  onDeleteEvent?: (calendarEvent: CalendarEvent) => void | undefined | Promise<void>;
  onDateClick?: (date: Date) => void | undefined;
  onDateChange?: (start: Date, end: Date) => void | undefined;
  getInitialTimeSlot?: (calendarEvent: CalendarEvent) => EditableTimeSlotEntity | undefined;
  onEventClick?: (arg: EventClickArg) => void;
  renderEventPopoverContent?: (calendarEvent: CalendarEvent) => ReactNode;
  eventContent?: CustomContentGenerator<EventContentArg>;
  eventClassNames?: ClassNamesGenerator<EventContentArg>;
  recurrenceTypeOptions?: RecurrenceType[];
  resetScheduleForm?: () => void;
  calendarEventType?: CalendarEventType | null;
  duration?: number;
  events?: CalendarEvent[];
  isEditable?: boolean;
  isLoading?: boolean;
  areTimeSlotsLoading?: boolean;
  scheduleFormik?: FormikContextType<ScheduleStepModel>;
  shouldHideInternalPopover?: boolean;
  resetInternalPopoverState?: () => void;
  isCompactWeek?: boolean;
  preselectedTimeSlotDateTime?: Date | null;
};

export const Calendar = forwardRef<FullCalendar, CalendarProps>(function Calendar(
  {
    onAddEvent,
    onChangeEvent,
    onDeleteEvent,
    onEventClick,
    onDateClick,
    onDateChange,
    getInitialTimeSlot,
    renderEventPopoverContent,
    eventContent,
    eventClassNames,
    resetScheduleForm,
    calendarEventType,
    duration = DEFAULT_DURATION,
    events = [],
    isEditable = false,
    isLoading = true,
    areTimeSlotsLoading = false,
    recurrenceTypeOptions,
    scheduleFormik,
    shouldHideInternalPopover = false,
    resetInternalPopoverState,
    isCompactWeek = false,
    preselectedTimeSlotDateTime = null,
  },
  externalRef
) {
  const innerRef = useRef<FullCalendar>(null);
  //#region Handle breakpoints
  const breakpoint = useBreakpoint();
  const isMediumScreen = MEDIUM_BREAKPOINTS.includes(breakpoint);
  const gridWeekView = isCompactWeek ? 'dayGridWeek' : 'timeGridWeek';
  const initialView = isMediumScreen ? 'list' : gridWeekView;
  const calendarAPI = innerRef?.current?.getApi?.();
  const calendarViewApi = calendarAPI?.view;
  const [currentStart, setCurrentStart] = useState<Date | null>(null);
  const [currentEnd, setCurrentEnd] = useState<Date | null>(null);
  const [isPreselected, setIsPreselected] = useState(false);

  function areSameDays(start: Date, end?: Date) {
    return Boolean(
      calendarViewApi &&
        start &&
        end &&
        currentStart &&
        currentEnd &&
        isSameDay(start, currentStart) &&
        isSameDay(end, currentEnd)
    );
  }

  useEffect(
    function handleScrollToDateChange() {
      const isNotWithinCurrentDateTimeRange =
        preselectedTimeSlotDateTime &&
        calendarViewApi &&
        (isAfter(preselectedTimeSlotDateTime, calendarViewApi.currentEnd) ||
          isBefore(preselectedTimeSlotDateTime, calendarViewApi.currentStart));

      if (
        preselectedTimeSlotDateTime &&
        !isPreselected &&
        isNotWithinCurrentDateTimeRange &&
        !isLoading &&
        calendarAPI &&
        calendarViewApi
      ) {
        calendarAPI.gotoDate(preselectedTimeSlotDateTime);
        onDateChange?.(calendarViewApi.currentStart, calendarViewApi.currentEnd);
        setCurrentStart(calendarViewApi.currentStart);
        setCurrentEnd(calendarViewApi.currentEnd);
        setIsPreselected(true);
      }
    },
    [preselectedTimeSlotDateTime, isPreselected, calendarAPI, calendarViewApi, isLoading] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleDatesSet = useCallback(
    ({ start, end }: DatesSetArg) => {
      if (!areTimeSlotsLoading && !areSameDays(start, end)) {
        onDateChange?.(start, end);
        setCurrentStart(start);
        setCurrentEnd(end);
      }
    },
    [areTimeSlotsLoading, calendarViewApi] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function handleMobileViewSwitch() {
      // CASE 1: If week view + mobile -> switch to list view.
      if (isMediumScreen && innerRef?.current?.getApi()?.view?.type === gridWeekView) {
        calendarViewApi?.calendar?.changeView?.('list');
      }

      // CASE 2: If list view + not-mobile -> switch to week view.
      if (!isMediumScreen && innerRef?.current?.getApi()?.view?.type === 'list') {
        calendarViewApi?.calendar?.changeView?.(gridWeekView);
      }

      // CASE 3: If regular month view + mobile -> switch to mobile month view.
      if (isMediumScreen && innerRef?.current?.getApi()?.view?.type === 'dayGridMonth') {
        calendarViewApi?.calendar?.changeView?.('list');
      }

      // CASE 4: If mobile month view + not-mobile -> switch to regular mobile view.
      if (!isMediumScreen && innerRef?.current?.getApi()?.view?.type === 'mobileMonth') {
        calendarViewApi?.calendar?.changeView?.('dayGridMonth');
      }
    },
    [isMediumScreen] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle breakpoints

  //#region Handle events
  const dispatch = useAppDispatch();
  const holidayEvents = useAppSelector((state) => state.mySchedule.holidays);

  useEffect(function handleHolidays() {
    dispatch(getHolidays());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const internalEvents = useMemo<CalendarEvent[]>(() => {
    return [
      ...events,
      ...holidayEvents,
      // `AVAILABLE_TIME_GROUP_ID` is used to constraint events only for the future.
      {
        groupId: AVAILABLE_TIME_GROUP_ID,
        start: new Date(),
        end: add(new Date(), { years: 1 }),
        display: 'none',
      } as CalendarEvent,
    ];
  }, [events, holidayEvents]);

  // IntervalTree is used for quick checks if events overlap.
  const intervalTree = useMemo(() => {
    return transformCalendarEventsToIntervalTree(events);
  }, [events]);
  //#endregion Handle events

  //#region Handle modals
  const addEventModalInstance = useAddEventModal({
    duration: Number(duration),
    intervalTree,
    onSubmit: onAddEvent,
    scheduleFormik: scheduleFormik,
  });
  const editEventModalInstance = useEditEventModal({
    duration: Number(duration),
    intervalTree,
    onSubmit: onChangeEvent,
    onDeleteEvent,
    getInitialTimeSlot,
    isEditable: isEditable,
    scheduleFormik: scheduleFormik,
  });
  const { setIsShown: setIsAddEventShown, formik: addFormik } = addEventModalInstance;
  const { setIsShown: setIsEditEventShown, formik: editFormik } = editEventModalInstance;
  const [editedDragAndDropCalendarEvent, setEditedDragAndDropCalendarEvent] = useState<CalendarEvent | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [hasThisAndFutureCheckboxOption, setHasThisAndFutureCheckboxOption] = useState(true);
  const [hasRecurringEventCheckboxOption, setHasRecurringEventCheckboxOption] = useState(true);

  function closeEditModal() {
    setEditedDragAndDropCalendarEvent(null);
    setHasThisAndFutureCheckboxOption(true);
    setHasRecurringEventCheckboxOption(true);
    setIsEditModalOpen(false);
  }

  async function handleEventDidMount(info: EventMountArg) {
    // Add tooltip to holidays
    const { Tooltip } = await import('bootstrap');

    if (info.event.extendedProps.isHoliday) {
      new Tooltip(info.el, {
        title: info.event.title,
        placement: 'top',
        trigger: 'hover',
        container: 'body',
      });
    }
  }

  function revertEventDragAndDrop() {
    closeEditModal();
    resetScheduleForm?.();
  }

  function handleEdit() {
    if (!editedDragAndDropCalendarEvent) {
      return;
    }

    if (
      calendarEventType !== CalendarEventType.IndividualEvent &&
      isCalendarEventOverlapAnyIntervalTreeNode(editedDragAndDropCalendarEvent, intervalTree)
    ) {
      Toastr.error(EVENT_OVERLAP_ERROR_MESSAGE);
      revertEventDragAndDrop();
      return;
    }

    if (
      calendarEventType !== CalendarEventType.IndividualEvent &&
      RECURRENCE_TYPE_IDS.includes(Number(editedDragAndDropCalendarEvent.recurrenceTypeID)) &&
      hasTimeSlotsOutOfDayLimit(editedDragAndDropCalendarEvent)
    ) {
      Toastr.error(EVENT_EDIT_INTERVAL_VALIDATION_ERROR_MESSAGE);
      revertEventDragAndDrop();
      return;
    }

    onChangeEvent?.(editedDragAndDropCalendarEvent);
    closeEditModal();
  }
  //#endregion Handle modals

  //#region Handle event handlers
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);

  const handleAddEventClick = useCallback(() => {
    if (!isEditable || isLoading || scheduleFormik?.isSubmitting) {
      return;
    }
    setIsAddEventShown(true);

    if (tutorStatusID === TutorStatusID.ProfileDetailsReady) {
      const dateAfterDaysOfApproval = add(new Date(), { days: DAYS_FOR_TUTOR_APPROVAL });
      const formValues: CalendarEventFormValues = {
        recurrenceTypeID: RecurrenceTypeID.Never,
        startDateTime: dateAfterDaysOfApproval,
        startHour: dateAfterDaysOfApproval,
        daysOfWeek: [],
        recurrenceTypes: [],
        hasProposal: 0,
        hasInitialProposal: 0,
        proposalPriceType: TimeSlotProposalPriceTypeID.Standard,
      };

      addFormik.resetForm({ values: formValues });
      return;
    }

    addFormik.resetForm();
  }, [isEditable, isLoading, scheduleFormik?.isSubmitting, setIsAddEventShown, tutorStatusID, addFormik]);

  const handleDateClick = useCallback(
    ({ date }: DateClickArg) => {
      if (onDateClick) {
        return onDateClick(date);
      }

      if (!isEditable) {
        return;
      }

      if (isBefore(date, new Date())) {
        return;
      }

      const formValues: CalendarEventFormValues = {
        recurrenceTypeID: RecurrenceTypeID.Never,
        startDateTime: date,
        startHour: date,
        daysOfWeek: [],
        recurrenceTypes: [],
        hasProposal: 0,
        hasInitialProposal: 0,
        proposalPriceType: TimeSlotProposalPriceTypeID.Standard,
      };

      addFormik.resetForm({ values: formValues });
      setIsAddEventShown(true);
    },
    [isEditable, addFormik, setIsAddEventShown, onDateClick]
  );

  const handleEventDrop = useCallback(
    ({ event, delta, revert }: EventDropArg) => {
      if (!isEditable) {
        return revert();
      }

      const individualCalendarEvent = internalEvents.find((calendarEvent) => calendarEvent.id === event.id);
      if (!individualCalendarEvent || individualCalendarEvent.extendedProps?.reservationStatusID) {
        return revert();
      }
      const calendarEvent = transformIndividualCalenderEventToRecurringCalenderEvent(individualCalendarEvent);

      if (!calendarEvent) {
        return revert();
      }

      if (Number(calendarEvent?.timeSlots?.length) > 0) {
        return revert();
      }

      if (isBefore(calendarEvent.start as Date, new Date())) {
        return revert();
      }

      const newCalendarEvent = produce(calendarEvent, (draft) => {
        if (calendarEvent.recurrenceTypeID === RecurrenceTypeID.Never) {
          const seconds = Math.ceil(delta.milliseconds / 1000);

          draft.start = add(calendarEvent.start as Date, {
            years: delta.years,
            months: delta.months,
            days: delta.days,
            seconds,
          });
          draft.end = add(calendarEvent.end as Date, {
            years: delta.years,
            months: delta.months,
            days: delta.days,
            seconds,
          });
        }

        if (calendarEvent.recurrenceTypeID !== RecurrenceTypeID.Never) {
          const seconds = Math.ceil(delta.milliseconds / 1000);
          const hasIntervals = INTERVAL_RECURRENCE_TYPE_IDS.includes(calendarEvent.recurrenceTypeID);

          draft.startRecur = add(individualCalendarEvent.start as Date, {
            years: delta.years,
            months: delta.months,
            days: delta.days,
            seconds: seconds,
          });
          const endRecur = add(calendarEvent.endRecur as Date, {
            years: delta.years,
            months: delta.months,
            days: delta.days,
          });
          if (hasIntervals) {
            const minutesToAdd = calculateIntervalCalendarEventDayDuration(
              Number(calendarEvent.duration),
              Number(calendarEvent.count),
              Number(calendarEvent.interval)
            );
            const endHour = add(draft.startRecur, { minutes: minutesToAdd });
            endRecur.setHours(endHour.getHours());
            endRecur.setMinutes(endHour.getMinutes());
          }
          draft.endRecur = endRecur;
          draft.startTime = format(draft.startRecur, DEFAULT_TIME_FORMAT);
          draft.endTime = format(draft.endRecur, DEFAULT_TIME_FORMAT);
        }
      });

      if (newCalendarEvent.recurrenceTypeID === RecurrenceTypeID.Never) {
        onChangeEvent?.(newCalendarEvent);
        return;
      }

      setEditedDragAndDropCalendarEvent(newCalendarEvent);
      if (newCalendarEvent.recurrenceTypeID === RecurrenceTypeID.WeeklyInterval) {
        setHasThisAndFutureCheckboxOption(false);
      }
      if (
        !isEqual(
          individualCalendarEvent.start as Date,
          parse(individualCalendarEvent.extendedProps?.startRecur as string, DEFAULT_DATETIME_FORMAT, new Date())
        )
      ) {
        setHasRecurringEventCheckboxOption(false);
      }
      setIsEditModalOpen(true);
    },
    [internalEvents, isEditable, onChangeEvent]
  );

  const handleEventClick = useCallback(
    ({ event }: EventClickArg) => {
      if (!isEditable) {
        return;
      }

      const calendarEvent = internalEvents.find((calendarEvent) => calendarEvent.id === event.id);

      if (!calendarEvent) {
        return;
      }

      if (isBefore(event.start as Date, new Date())) {
        return;
      }

      const formValues = transformCalendarEventToFormValues(calendarEvent);
      editFormik.resetForm({ values: formValues });

      setIsEditEventShown(true);
    },
    [editFormik, internalEvents, isEditable, setIsEditEventShown]
  );

  const handleEventOverlap = useCallback((stillEvent: EventApi | null) => {
    return stillEvent?.groupId === AVAILABLE_TIME_GROUP_ID;
  }, []);
  //#endregion Handle event handlers

  //#region Handle event popover
  const popoverID = useRef(cuid());
  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const [targetPopoverEl, setTargetPopoverTimeSlotEl] = useState<HTMLElement | null>(null);
  const [targetPopoverEvent, setTargetPopoverEvent] = useState<EventApi | null>(null);
  const targetEvent = useMemo(
    () => events.find((event) => event.id === targetPopoverEvent?.id),
    [events, targetPopoverEvent?.id]
  );

  useLayoutEffect(
    function handleTargetPopoverTimeSlotEl() {
      if (targetPopoverEvent) {
        setTimeout(() => {
          const eventClassName = generateIdentifiableEventClassName(targetPopoverEvent.id);
          const eventEl = document.querySelector(`.${eventClassName}`);
          setTargetPopoverTimeSlotEl((eventEl as HTMLElement) ?? null);
        });
      }
    },
    [targetPopoverEvent, internalEvents]
  );

  const handlePopoverHide = useCallback(() => {
    setIsPopoverShown(false);
  }, []);
  //#endregion Handle event popover

  //#region Internal wrapper events
  const handleInternalEventClick = useCallback(
    (arg: EventClickArg) => {
      const { el, event, jsEvent } = arg;

      // STEP 1: Make sure JS event is not propagated.
      jsEvent.stopPropagation();

      // STEP 2: Make `holidays` to be not clickable
      if (event.extendedProps.isHoliday) {
        return;
      }

      // STEP 3: Handle `onEventClick` prop if needed.
      if (onEventClick) {
        onEventClick(arg);
        return;
      }

      // STEP 4: Handle editable calendar if needed.
      if (isEditable) {
        handleEventClick(arg);
      }

      // STEP 5: Handle Event popover if needed.
      if (renderEventPopoverContent) {
        // CASE 5.1: If previous state is hidden -> show.
        if (!isPopoverShown) {
          setTargetPopoverTimeSlotEl(el);
          setTargetPopoverEvent(event);
          setIsPopoverShown(true);
          return;
        }

        // CASE 5.2: If same element and previous state is shown -> hide.
        if (isPopoverShown && targetPopoverEvent?.id === event.id) {
          setIsPopoverShown(false);
          return;
        }

        // CASE 5.3: If different element and previous state is shown -> change target element and event.
        if (isPopoverShown && targetPopoverEvent?.id !== event.id) {
          setTargetPopoverTimeSlotEl(el);
          setTargetPopoverEvent(event);
          return;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleEventClick, isEditable, isPopoverShown, onEventClick, renderEventPopoverContent, targetPopoverEvent?.id]
  );

  // Close popover if it is open on explicit external action that requires closing the popover
  useEffect(() => {
    if (isPopoverShown && shouldHideInternalPopover) {
      setIsPopoverShown(false);
      resetInternalPopoverState?.();
    }
  }, [isPopoverShown, shouldHideInternalPopover, resetInternalPopoverState]);

  const internalEventClassNameGenerator = useCallback(
    (arg: EventContentArg) => {
      const { event } = arg;
      const baseClassName = clsx({
        'fc-event--clickable': renderEventPopoverContent || onEventClick,
        [generateIdentifiableEventClassName(event.id)]: renderEventPopoverContent,
      });

      if (typeof eventClassNames === 'function') {
        return clsx(baseClassName, eventClassNames?.(arg));
      }

      if (isString(eventClassNames)) {
        return clsx(baseClassName, eventClassNames);
      }

      if (Array.isArray(eventClassNames)) {
        return clsx(baseClassName, ...eventClassNames);
      }

      return baseClassName;
    },
    [eventClassNames, onEventClick, renderEventPopoverContent]
  );
  //#endregion Internal wrapper events

  //#region Mobile month view
  const handleMobileMonthViewDateClick = useCallback(
    ({ date }: DateClickArg) => {
      const selectedDate = format(date, CALENDAR_CALLBACK_DATE_FORMAT);
      calendarAPI?.gotoDate(selectedDate);
      calendarAPI?.changeView('timeGridDay');
      const listDayEl = document.querySelector(`.fc-list-day[data-date="${selectedDate}"]`) as HTMLElement;
      const listScrollerEl = document.querySelector('.fc-list-view > .fc-scroller');

      listScrollerEl?.scrollTo?.({ top: listDayEl?.offsetTop });
    },
    [calendarAPI]
  );
  //#endregion Mobile month view

  return (
    <>
      {areTimeSlotsLoading && <GlobalSpinner />}
      {isLoading && <Skeleton height={370} />}
      {!isLoading && (
        <>
          <div
            className={clsx('calendar', {
              'calendar--editable': Boolean(isEditable && !scheduleFormik?.isSubmitting && !isLoading),
            })}
          >
            <FullCalendar
              {...(isCompactWeek && { height: 'auto' })}
              ref={composeRefs(innerRef, externalRef)}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              locale={bgLocale}
              initialView={initialView}
              customButtons={{
                addEventButton: {
                  text: 'Добави',
                  click: handleAddEventClick,
                },
              }}
              headerToolbar={{
                left: 'prev,today,next',
                center: 'title',
                right: isMediumScreen
                  ? `timeGridDay,list,mobileMonth${isEditable ? ' addEventButton' : ''}`
                  : `timeGridDay,${gridWeekView},dayGridMonth${isEditable ? ' addEventButton' : ''}`,
              }}
              buttonText={{
                today: 'Към Днес',
              }}
              views={{
                week: {
                  dayHeaderFormat: {
                    weekday: 'long',
                  },
                  dayHeaderContent: DayHeaderContent,
                  dayHeaderClassNames: 'py-1',
                },
                month: {
                  dayHeaderFormat: {
                    weekday: breakpoint === SIZE_XS ? 'short' : 'long',
                  },
                },
                list: {
                  type: 'list',
                  duration: { weeks: 1 },
                  noEventsContent: 'Няма часове за показване.',
                  buttonText: 'Седмица',
                },
                mobileMonth: {
                  type: 'dayGridMonth',
                  moreLinkContent: function MoreLinkContent() {
                    return <MobileMonthCellContent />;
                  },
                  dayMaxEvents: 0,
                  dateClick: handleMobileMonthViewDateClick,
                },
              }}
              scrollTime={SLOT_MIN_TIME}
              slotMinTime={SLOT_MIN_TIME}
              slotMaxTime={SLOT_MAX_TIME}
              editable={isEditable && !isLoading}
              eventDurationEditable={false}
              allDaySlot={true}
              allDayText=""
              eventOverlap={isEditable ? handleEventOverlap : undefined}
              dateClick={isEditable || onDateClick ? handleDateClick : undefined}
              eventDrop={isEditable ? handleEventDrop : undefined}
              eventClick={handleInternalEventClick}
              eventDidMount={handleEventDidMount}
              eventConstraint={AVAILABLE_TIME_GROUP_ID}
              events={internalEvents}
              eventContent={eventContent}
              eventClassNames={internalEventClassNameGenerator}
              datesSet={handleDatesSet}
            />

            {recurrenceTypeOptions !== undefined && (
              <AddEventModal
                instance={addEventModalInstance}
                recurrenceTypeOptions={recurrenceTypeOptions}
                scheduleFormik={scheduleFormik}
                isLoading={isLoading}
              />
            )}
            {recurrenceTypeOptions !== undefined && (
              <EditEventModal
                instance={editEventModalInstance}
                recurrenceTypeOptions={recurrenceTypeOptions}
                scheduleFormik={scheduleFormik}
                getInitialTimeSlot={getInitialTimeSlot}
              />
            )}

            {Boolean(renderEventPopoverContent) && targetEvent !== undefined && (
              <Overlay
                target={targetPopoverEl}
                show={isPopoverShown}
                onHide={handlePopoverHide}
                placement="bottom"
                rootClose={true}
                popperConfig={{
                  modifiers: [
                    {
                      name: 'flip',
                      enabled: true,
                      options: {
                        rootBoundary: 'viewport',
                        fallbackPlacements: ['top', 'right', 'left'],
                      },
                    },
                  ],
                }}
              >
                <Popover id={popoverID.current} className="mw-350px">
                  {renderEventPopoverContent?.(targetEvent)}
                </Popover>
              </Overlay>
            )}
          </div>
          {isEditable && (
            <EventTypeActionModal
              isShown={isEditModalOpen}
              onHide={revertEventDragAndDrop}
              onSubmit={handleEdit}
              isEditAction
              hasThisAndFutureCheckboxOption={hasThisAndFutureCheckboxOption}
              hasRecurringEventCheckboxOption={hasRecurringEventCheckboxOption}
            />
          )}
        </>
      )}
    </>
  );
});
