import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Toastr from 'toastr';

import { KTSVG } from '@/_metronic/helpers';
import { Button } from '@/app/components/Button/Button';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { ModalCloseButton } from '@/app/components/Modal/ModalCloseButton';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { TabItem } from '@/app/components/Tabs/TabItem';
import { TabNav } from '@/app/components/Tabs/TabNav';
import authStore from '@/app/modules/auth/store';
import { formatPrice } from '@/app/utils/money';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { StickyHeader } from '../StickyHeader';
import { getProfileProfitData, makePayout } from './service';

type ProfileProfitBasePageProps = {
  title: string;
  children: ReactNode;
  onPayout?: (balance: string) => void;
};

export function ProfileProfitBasePage({ title, onPayout, children }: ProfileProfitBasePageProps) {
  const dispatch = useAppDispatch();
  const content = useAppSelector((state) => state.profileProfit);
  const isLoading = content.loading === LoadingState.Pending;
  const [isPayoutResultModalShown, setIsPayoutResultModalShown] = useState(false);

  const insufficientFundsMessage = useMemo(
    () => `За да прехвърлите сума към Вашата банкова сметка, е необходимо текущият Ви баланс да бъде минимум
      ${formatPrice(content.minRequiredBalanceToPayout ?? 0)}`,
    [content.minRequiredBalanceToPayout]
  );

  useEffect(function componentDidMount() {
    if (content.loading === LoadingState.Idle) {
      dispatch(getProfileProfitData());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function handlePayout() {
    // TODO: Disable functionality due to issue with wrong tutor balance transfers for expired free lessons
    if (content.hasBlockedPayouts) {
      Toastr.error('Нямате права за това действие! Свържете се с екипа на ШКОЛО за повече информация.');
    }

    if (!content.canPayout) {
      Toastr.error(insufficientFundsMessage);
      return;
    }

    const payoutResponse = await dispatch(makePayout()).unwrap();

    setIsPayoutResultModalShown(true);

    dispatch(
      authStore.actions.updateBalanceAmount({
        balanceAmount: payoutResponse.balance,
      })
    );

    onPayout?.(payoutResponse.balance);
  }

  function handlePayoutResultModalClose() {
    setIsPayoutResultModalShown(false);
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageTop className="mb-9">
        <PageTitle>Приходи</PageTitle>
      </PageTop>
      <Card className="mb-6">
        <CardBody>
          <div className="d-flex flex-column flex-sm-row align-items-sm-center">
            <div className="d-flex flex-column">
              <span className="h2 fw-normal">Текущ баланс:</span>
              <span className="h3">{content.balance && formatPrice(content.balance)}</span>
            </div>
            <Button
              isLoading={isLoading}
              disabled={isLoading || content.hasBlockedPayouts}
              onClick={handlePayout}
              className="mt-3 mt-sm-0 ms-sm-auto"
            >
              Изтегли към моя сметка
            </Button>
          </div>
          <CardSubTitle>
            За да прехвърлите сума към Вашата банкова сметка, е необходимо текущият Ви баланс да бъде минимум
            <strong className="ms-1 me-1">{formatPrice(content.minRequiredBalanceToPayout ?? 0)}</strong>
          </CardSubTitle>
        </CardBody>
      </Card>
      {content.blockedBalance && Number(content.blockedBalance) !== 0 && (
        <Card className="mb-6">
          <CardBody>
            <div className="d-flex flex-column">
              <h2 className="fw-normal">
                Блокирани суми: <strong className="text-danger">{formatPrice(content.blockedBalance)}</strong>
              </h2>
            </div>
            <CardSubTitle>
              Сумите се отблокират след изтичане на текущия месец, за който са блокирани и след подаване на декларация в{' '}
              началото на новия месец. При нужда от съдействие, свържете се с нас на телефон{' '}
              <a href="tel:+359894729199">+359894729199</a> или имейл{' '}
              <a href="mailto:desislava.cholakova@shkolo.com">desislava.cholakova@shkolo.com</a>
            </CardSubTitle>
            <CardSubTitle>
              <a href="/additional-tutor-terms" target="_blank">
                За повече информация
              </a>
            </CardSubTitle>
          </CardBody>
        </Card>
      )}
      <Row>
        <Col sm={6}>
          <Card>
            <Link to="/profile/bank-details">
              <CardBody className="d-flex align-items-center">
                <div>
                  <h4>Банкова сметка</h4>
                  <p className="mb-0 text-muted">
                    Сметката на която ще получавате Вашето възнаграждение при изтегляне.
                  </p>
                </div>
                <KTSVG className="svg-icon-2tx ms-6" path="/img/svg/icons/Navigation/Arrow-right.svg" />
              </CardBody>
            </Link>
          </Card>
        </Col>
        <Col sm={6}>
          <Card className="mt-6 mt-sm-0">
            <Link to="/profile/invoice-details">
              <CardBody className="d-flex align-items-center">
                <div>
                  <h4>Фактуриране</h4>
                  <p className="mb-0 text-muted">
                    Данни, които ще се използват за създаване на фактура към потребител, който е закупил Ваше обучение.
                  </p>
                </div>
                <KTSVG className="svg-icon-2tx ms-6" path="/img/svg/icons/Navigation/Arrow-right.svg" />
              </CardBody>
            </Link>
          </Card>
        </Col>
      </Row>
      <Row>
        <StickyHeader className="pt-5" containerClassName="mb-7">
          <TabNav>
            <TabItem to="/profile/profit">Изтегляния</TabItem>
            <TabItem to="/profile/profit/income">
              Приходи {content.hasBlockedIncome && <i className="fa-solid fa-triangle-exclamation text-warning ms-2" />}
            </TabItem>
            <TabItem to="/profile/profit/expected-income">
              Очаквани приходи{' '}
              {content.hasBlockedExpectedIncome && <i className="fa-solid fa-triangle-exclamation text-warning ms-2" />}
            </TabItem>
            <TabItem to="/profile/profit/sold-courses">Продадени обучения</TabItem>
            <TabItem to="/profile/profit/monthly-income">Месечни приходи</TabItem>
          </TabNav>
        </StickyHeader>
      </Row>
      <Card>{children}</Card>
      <Modal
        show={isPayoutResultModalShown}
        onHide={handlePayoutResultModalClose}
        contentClassName="text-center"
        centered
      >
        <Modal.Header className="border-0 flex-wrap position-relative">
          <Modal.Title className="flex-grow-1 px-10 fs-2x fw-bold">Изтегли към моята сметка</Modal.Title>
          <ModalCloseButton onClose={handlePayoutResultModalClose} isAbsolutePositioned />
        </Modal.Header>
        <Modal.Body className="p-md-10">
          <KTSVG className="svg-icon-6tx svg-icon-danger" path="/img/svg/icons/CheckCircle.svg" />
          <h4 className="mt-6">Операцията е извършена успешно</h4>
          <p>Сумата беше прехвърлена към Вашата банка. Изчакайте докато банката обработи транзакцията.</p>
        </Modal.Body>
      </Modal>
      {isLoading && <GlobalSpinner />}
    </>
  );
}
