import { useEffect, useMemo, useState } from 'react';
import Feedback from 'react-bootstrap/Feedback';
import DatePicker, { registerLocale } from 'react-datepicker';
import clsx from 'clsx';
import { endOfDay, format, isAfter, isBefore, isSameDay, parse, startOfDay } from 'date-fns'; // eslint-disable-line import/no-duplicates
import bg from 'date-fns/locale/bg'; // eslint-disable-line import/no-duplicates
import { useFormikContext } from 'formik';

import { isString } from '@/app/utils/isString';

import { DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT } from './constants';
import { CustomDateInput } from './CustomDateInput';
import { isDateValid } from './utils';

import './DatePickerControl.scss';

registerLocale('bg', bg);

type DatePickerControlProps = {
  name: string;
  dateFormat?: string;
  onChange?: (value: Date) => void | unknown | Date;
  minDateFieldName?: string;
  maxDateFieldName?: string;
  minDate?: Date;
  maxDate?: Date;
  timeIntervals?: number;
  isTimeSelectShown?: boolean;
  isValueDateObject?: boolean;
  isDisabled?: boolean;
  className?: string;
  hasSolidBackground?: boolean;
  popperClassName?: string;
};

export function DatePickerControl({
  name,
  dateFormat,
  onChange,
  minDateFieldName,
  maxDateFieldName,
  minDate,
  maxDate,
  timeIntervals = 30,
  isTimeSelectShown = false,
  isValueDateObject = false,
  isDisabled = false,
  hasSolidBackground = true,
  popperClassName,
}: DatePickerControlProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<string | Date>(name);
  const fieldHelpers = formik.getFieldHelpers<string | Date | null>(name);
  const isInvalid = Boolean(field.error);
  const startOfToday = useMemo(() => startOfDay(new Date()), []);
  const endOfToday = useMemo(() => endOfDay(new Date()), []);

  const internalDateFormat = useMemo(() => {
    if (dateFormat) {
      return dateFormat;
    }

    if (isTimeSelectShown) {
      return DEFAULT_DATETIME_FORMAT;
    }

    return DEFAULT_DATE_FORMAT;
  }, [dateFormat, isTimeSelectShown]);

  //#region Handle value changes
  const [internalValue, setInternalValue] = useState<Date | null>(null);

  useEffect(
    function handleFieldValueChange() {
      let newValue: Date | string = field.value;

      if (isString(newValue)) {
        newValue = parse(field.value as string, internalDateFormat, new Date());
      }

      if (isDateValid(newValue)) {
        setInternalValue(newValue);
      } else {
        setInternalValue(null);
        fieldHelpers.setValue(null);
      }
    },
    [field.value] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function handleChange(date: Date) {
    const newDate = onChange?.(date) as Date;

    if (newDate) {
      return fieldHelpers.setValue(newDate);
    }

    setInternalValue(date);

    if (isValueDateObject) {
      return fieldHelpers.setValue(date);
    }

    fieldHelpers.setValue(date ? format(date, internalDateFormat) : null);
  }
  //#endregion Handle value changes

  //#region Min/max dates
  const internalMinDate = useMemo<Date | undefined>(() => {
    const minDateField = minDateFieldName ? formik.getFieldMeta<string | Date>(minDateFieldName) : undefined;

    // CASE 1: No `minDateField` -> rely on `minDate`.
    if (!minDateField?.value) {
      return minDate;
    }

    // CASE 2: Handle `minDateField`.
    let maxDateValue = minDateField.value as Date;

    if (isString(minDateField.value)) {
      maxDateValue = parse(minDateField.value, internalDateFormat, new Date());
    }

    // CASE 3: Both `minDateField` and `minDate` are provided -> use the newer date.
    if (minDate && isAfter(minDate, maxDateValue)) {
      return minDate;
    }

    return maxDateValue;
  }, [formik, internalDateFormat, minDate, minDateFieldName]);

  const internalMaxDate = useMemo<Date | undefined>(() => {
    const maxDateField = maxDateFieldName ? formik.getFieldMeta<string | Date>(maxDateFieldName) : undefined;

    // CASE 1: No `maxDateField` -> rely on `maxDate`.
    if (!maxDateField?.value) {
      return maxDate;
    }

    // CASE 2: Handle `maxDateField`.
    let maxDateValue = maxDateField.value as Date;

    if (isString(maxDateField.value)) {
      maxDateValue = parse(maxDateField.value, internalDateFormat, new Date());
    }

    // CASE 3: Both `maxDateField` and `maxDate` are provided -> use the older date.
    if (maxDate && isBefore(maxDate, maxDateValue)) {
      return maxDate;
    }

    return maxDateValue;
  }, [maxDateFieldName, formik, maxDate, internalDateFormat]);

  const minTime = useMemo(() => {
    if (!internalMinDate) {
      return startOfToday;
    }

    let fieldValue = field.value as Date;

    if (isString(fieldValue)) {
      fieldValue = parse(fieldValue, internalDateFormat, new Date());
    }

    if (!isSameDay(fieldValue, new Date())) {
      return startOfToday;
    }

    return internalMinDate;
  }, [field.value, internalDateFormat, internalMinDate, startOfToday]);
  //#endregion Min/max dates

  return (
    <>
      <DatePicker
        selected={internalValue}
        dateFormat={internalDateFormat}
        onChange={handleChange}
        locale="bg"
        wrapperClassName={clsx({
          'is-invalid': isInvalid,
        })}
        calendarClassName={clsx({
          'react-datepicker--with-time': isTimeSelectShown,
        })}
        customInput={<CustomDateInput isDisabled={isDisabled} hasSolidBackground={hasSolidBackground} />}
        timeCaption="Час"
        minDate={internalMinDate}
        maxDate={internalMaxDate}
        minTime={minTime}
        maxTime={internalMaxDate ?? endOfToday}
        showTimeSelect={isTimeSelectShown}
        timeIntervals={timeIntervals}
        disabled={isDisabled}
        popperClassName={popperClassName}
      />

      {isInvalid && <Feedback type="invalid">{field.error}</Feedback>}
    </>
  );
}
