import { ReactNode } from 'react';
import clsx from 'clsx';

type HeaderMenuProps = {
  children: ReactNode;
  className?: string;
};

export function HeaderMenu({ children, className }: HeaderMenuProps) {
  return <div className={clsx('d-flex align-items-center', className)}>{children}</div>;
}
