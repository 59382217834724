import { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';

import { Button } from '@/app/components/Button/Button';
import { ModalCloseButton } from '@/app/components/Modal/ModalCloseButton';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformCustomEventToConversionEventValues } from '@/app/modules/analytics/utils';
import errorsSlice from '@/app/modules/errors/store';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagStudentRegistrationEvent, tagTutorRegistrationEvent } from '@/app/utils/google-analytics/customEvents';
import { useAppDispatch } from '@/redux/store';

import './RegistrationModal.scss';

type RegistrationModalProps = {
  isShown: boolean;
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
};

export function RegistrationModal({ isShown, setIsShown }: RegistrationModalProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const from = location.pathname + location.search;
  const tutorBecomePath = '/tutor/become';

  useEffect(function componentDidMount() {
    dispatch(errorsSlice.actions.setHttpGetError({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleClose() {
    setIsShown(false);
  }

  async function handleTutorRegistration() {
    const conversionEventValues = await transformCustomEventToConversionEventValues(
      FACEBOOK_PIXEL_CUSTOM_EVENTS.registrationTutor
    );
    tagTutorRegistrationEvent(conversionEventValues.eventID);
    await trackAnalyticsEventConversionsAPI(conversionEventValues);
  }

  async function handleStudentRegistration() {
    const conversionEventValues = await transformCustomEventToConversionEventValues(
      FACEBOOK_PIXEL_CUSTOM_EVENTS.registrationStudent
    );
    tagStudentRegistrationEvent(conversionEventValues.eventID);
    await trackAnalyticsEventConversionsAPI(conversionEventValues);
  }

  return (
    <Modal
      show={isShown}
      onHide={handleClose}
      backdropClassName="modal-backdrop--blurred"
      contentClassName="text-center"
      centered
    >
      <Modal.Header className="border-0 flex-wrap position-relative">
        <Modal.Title className="flex-grow-1 px-10 fs-2x fw-bold">Като какъв желаете да продължите?</Modal.Title>
        <ModalCloseButton onClose={handleClose} isAbsolutePositioned />
      </Modal.Header>
      <Modal.Body className="p-md-10">
        <Row className="sign-up-modal__row">
          <Col sm={6} className="sign-up-modal__column">
            <div className="w-50px h-50px d-inline-flex align-items-center justify-content-center">
              <i className="fas fa-chalkboard-teacher fs-3tx mb-4 text-primary" />
            </div>
            <h4 className="fs-2 fw-bold mb-5">Обучител</h4>
            <Button
              variant="primary"
              className="w-100 mw-200px"
              href={`/auth/register?from=${tutorBecomePath}`}
              onClick={handleTutorRegistration}
            >
              Регистрация
            </Button>
          </Col>

          <Col sm={6} className="sign-up-modal__column">
            <div className="w-50px h-50px d-inline-flex align-items-center justify-content-center">
              <i className="fas fa-user-graduate fs-3x mb-4 text-primary" />
            </div>
            <h4 className="fs-2 fw-bold mb-5">Обучаем</h4>
            <Button
              variant="primary"
              className="w-100 mw-200px"
              href={`/auth/register?from=${from}`}
              onClick={handleStudentRegistration}
            >
              Регистрация
            </Button>
          </Col>
        </Row>

        <p className="text-muted mt-9 mb-0">Ще бъдете препратени към единната профилна част на Школо.</p>
      </Modal.Body>
    </Modal>
  );
}
