import React, { useEffect } from 'react';
import { Form as BaseForm, FormProps as BaseFormProps } from 'react-bootstrap';
import { Prompt } from 'react-router';
import { FormikContextType, FormikProvider } from 'formik';

import { EXITING_DIRTY_FORM_MESSAGE } from './constants';

type FormProps = React.PropsWithChildren<BaseFormProps> & {
  formik?: FormikContextType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  className?: string;
  disabled?: boolean;
  skipDirtyPrompt?: boolean;
};

export function Form({ formik, children, disabled = false, skipDirtyPrompt = false, ...props }: FormProps) {
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (disabled) {
      return;
    }

    if (props.onSubmit) {
      return props.onSubmit(event);
    }

    formik?.handleSubmit?.(event);
  }

  //#region Handle exiting dirty form
  useEffect(
    function handleExitingDirtyFormBeforeUnload() {
      function handleBeforeUnload(event: BeforeUnloadEvent) {
        if (skipDirtyPrompt) {
          return;
        }

        if (formik?.dirty || formik?.isSubmitting) {
          event.preventDefault();
          event.returnValue = EXITING_DIRTY_FORM_MESSAGE;
        }
      }

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    },
    [formik?.dirty, formik?.isSubmitting, skipDirtyPrompt]
  );

  function handleExitingDirtyFormReactRouter() {
    if (formik?.dirty || formik?.isSubmitting) {
      return EXITING_DIRTY_FORM_MESSAGE;
    }

    return true;
  }
  //#endregion Handle exiting dirty form

  if (!formik) {
    return <>{children}</>;
  }

  return (
    <BaseForm {...props} onSubmit={handleSubmit} noValidate>
      {!skipDirtyPrompt && <Prompt message={handleExitingDirtyFormReactRouter} />}
      <FormikProvider value={formik}>{children}</FormikProvider>
    </BaseForm>
  );
}
