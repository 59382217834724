import React from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from '@/app/components/Button/Button';

type DurationOverlapModalProps = {
  isShown: boolean;
  duration: number | string;
  onHide: () => void;
};

export function DurationOverlapModal({ isShown, duration, onHide }: DurationOverlapModalProps) {
  return (
    <Modal show={isShown} onEscapeKeyDown={onHide} centered>
      <Modal.Body>
        <p className="text-grey-500 font-weight-normal fs-6">
          Не може да промените продължителността на часовете на {duration} минути. Това ще доведе до застъпване на
          часовете.
        </p>
        <div className="d-flex flex-wrap align-items-center justify-content-end mt-5">
          <Button variant="light-primary" onClick={onHide}>
            Добре
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
