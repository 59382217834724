import React, { useCallback, useEffect } from 'react';

import { Button } from '@/app/components/Button/Button';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import {
  BuyLessonStepNumber,
  GetTutorCourseSubjectTargetAudiencesResponse,
  TutorCourseSubject,
  TutorCourseSubjectTargetAudience,
} from '../models';
import { getTutorCourseSubjectTargetAudiences } from '../service';

type ChooseTargetAudienceStepProps = {
  tutorID: number;
  selectedSubject: TutorCourseSubject | null;
  setTitle: React.Dispatch<React.SetStateAction<string | null>>;
  setBuyLessonStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedTargetAudience: React.Dispatch<React.SetStateAction<TutorCourseSubjectTargetAudience | null>>;
  onClose: () => void;
};

export function ChooseTargetAudienceStep({
  tutorID,
  selectedSubject,
  setTitle,
  setBuyLessonStep,
  setSelectedTargetAudience,
  onClose,
}: ChooseTargetAudienceStepProps) {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.buyCourseLesson.loading);
  const targetAudiences = useAppSelector((state) => state.buyCourseLesson.targetAudences);

  useEffect(
    function componentDidMount() {
      setTitle('Изберете клас/възраст на обучаемия');

      async function fetchTargetAudiencesData() {
        const response = await dispatch(
          getTutorCourseSubjectTargetAudiences({
            tutorID: Number(tutorID),
            subjectID: Number(selectedSubject?.id),
          })
        );

        const responsePayload = response.payload as GetTutorCourseSubjectTargetAudiencesResponse;

        if (responsePayload?.targetAudiences?.length === 0) {
          return onClose();
        }

        if (responsePayload?.targetAudiences?.length === 1) {
          setSelectedTargetAudience(responsePayload?.targetAudiences[0]);
          setBuyLessonStep(BuyLessonStepNumber.Course);
          return;
        }
      }

      if (loading === LoadingState.Idle) {
        fetchTargetAudiencesData();
      }
    },
    [dispatch, setBuyLessonStep, setSelectedTargetAudience, setTitle]
  );

  const handleSelectTargetAudience = useCallback(
    (event) => {
      const targetAudienceID = event.target.value;
      const targetAudience = targetAudiences?.find((ta) => ta.id === Number(targetAudienceID));

      if (targetAudience) {
        setSelectedTargetAudience(targetAudience);
        setBuyLessonStep(BuyLessonStepNumber.Course);
      }
    },
    [targetAudiences, setSelectedTargetAudience, setBuyLessonStep]
  );

  return (
    <div className="d-flex flex-column mb-n2">
      {targetAudiences?.map((targetAudince, index) => (
        <Button
          key={index}
          value={targetAudince.id}
          variant="white"
          className="border border-2 text-dark mb-2"
          onClick={handleSelectTargetAudience}
        >
          {targetAudince.name}
        </Button>
      ))}
    </div>
  );
}
