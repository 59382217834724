import { isNaN } from 'formik';

import { END_OF_DAY_TIME_IN_HOURS, START_OF_DAY_TIME_IN_HOURS } from './constants';

export function isDateValid(date: Date | null): boolean {
  return !isNaN(date?.getTime?.());
}

export function isDateHoursValid(date: Date): boolean {
  return date.getHours() >= START_OF_DAY_TIME_IN_HOURS && date.getHours() <= END_OF_DAY_TIME_IN_HOURS;
}
