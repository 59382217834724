import { AnchorHTMLAttributes, MouseEvent } from 'react';

export type ScrollToLinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  to: string;
};

export function ScrollToLink({ to, onClick, ...props }: ScrollToLinkProps) {
  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    document.querySelector(to)?.scrollIntoView?.({ behavior: 'smooth' });
    onClick?.(event);
  }

  return <a href={to} onClick={handleClick} {...props} />;
}
