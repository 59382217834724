import { useState } from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';

import { KTSVG } from '@/_metronic/helpers';
import { Button } from '@/app/components/Button/Button';
import { CardSection } from '@/app/components/Card/CardSection';
import { CardSectionContent } from '@/app/components/Card/CardSectionContent';
import { CardSectionDescription } from '@/app/components/Card/CardSectionDescription';
import { CardSectionTitle } from '@/app/components/Card/CardSectionTitle';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { formatPromoCodeValue } from '@/app/utils/money';

import { CoursePackageModel } from '../create/models';
import { PromoCodeData } from '../models';
import { checkPromoCode } from '../service';
import {
  EMPTY_VALUE,
  MAXIMUM_LENGTH_PROMOCODE,
  MAXIMUM_LENGTH_PROMOCODE_ERROR_MESSAGE,
  NOT_APPLICABLE_PROMOCODE,
  REQUIRED_FIELD_ERROR_MESSAGE,
} from './constants';
import { CourseCheckoutValues } from './models';
import { PromoCodeListModal } from './PromoCodeListModal';

interface PromoCodeSectionProps {
  name: string;
  isAttachedPromoCode: boolean;
  setIsAttachedPromoCode: React.Dispatch<React.SetStateAction<boolean>>;
  promoCodeData: PromoCodeData | null;
  setPromoCodeData: React.Dispatch<React.SetStateAction<PromoCodeData | null>>;
  coursePackages: CoursePackageModel[];
}

export function PromoCodeSection({
  name,
  isAttachedPromoCode,
  setIsAttachedPromoCode,
  promoCodeData,
  setPromoCodeData,
  coursePackages,
}: PromoCodeSectionProps) {
  const formik = useFormikContext<CourseCheckoutValues>();
  const field = formik.getFieldMeta<string>(name);
  const fieldHelpers = formik.getFieldHelpers<string>(name);
  const [isPromoCodeListModalShown, setIsPromoCodeListModalShown] = useState<boolean>(false);

  async function handleApplyPromoCodeClick() {
    applyPromoCode(field.value);
  }

  async function applyPromoCode(promoCode: string) {
    if (promoCode === EMPTY_VALUE) {
      formik.setErrors({ promoCode: REQUIRED_FIELD_ERROR_MESSAGE });
      return;
    }

    if (Number(promoCode.length) > MAXIMUM_LENGTH_PROMOCODE) {
      formik.setErrors({ promoCode: MAXIMUM_LENGTH_PROMOCODE_ERROR_MESSAGE });
      return;
    }

    try {
      const response = await checkPromoCode(promoCode);

      if (
        Array.isArray(response.applicableLessonCounts) &&
        response.applicableLessonCounts.length !== 0 &&
        !coursePackages.some((coursePackage) => response.applicableLessonCounts?.includes(coursePackage.lessonCount))
      ) {
        formik.setErrors({ promoCode: NOT_APPLICABLE_PROMOCODE });
        return;
      }

      if (response) {
        setPromoCodeData(response);
        setIsAttachedPromoCode(true);
        formik.setErrors({ promoCode: '' });
      }
    } catch (error: any) {
      formik.setErrors({ promoCode: error.response.data.errors.promoCode.shift() });
    }
  }

  function removePromoCode() {
    fieldHelpers.setValue(EMPTY_VALUE);
    setIsAttachedPromoCode(false);
  }

  return (
    <CardSection>
      <CardSectionDescription>
        <CardSectionTitle>Промокод</CardSectionTitle>
      </CardSectionDescription>
      <CardSectionContent className="pt-3 d-flex flex-column flex-sm-row">
        <div className="me-sm-5 mb-4 mb-sm-0 w-100">
          {!isAttachedPromoCode ? (
            <div className="position-relative">
              <FormControl autoComplete="off" name="promoCode" className="h-75px h-sm-50px" />
              <div className="position-absolute top-0 end-0 mt-16 mt-sm-5">
                <strong
                  className={clsx('cursor-pointer fs-5 my-0 text-primary me-3', {
                    'me-sm-10': formik.errors.promoCode,
                  })}
                  onClick={() => setIsPromoCodeListModalShown(true)}
                >
                  Мои промокодове <KTSVG path="/img/svg/Template.svg" className="svg-icon-1 svg-icon-primary" />
                </strong>
              </div>
            </div>
          ) : (
            <p className="pb-4 pt-md-4 mb-0">
              Приложен код за отстъпка от{' '}
              {formatPromoCodeValue(Number(promoCodeData?.value), promoCodeData?.discountType)}
            </p>
          )}
        </div>
        {!isAttachedPromoCode ? (
          <Button
            disabled={field.value === EMPTY_VALUE}
            className="col-sm-auto h-sm-50px"
            onClick={handleApplyPromoCodeClick}
          >
            Приложи промокод
          </Button>
        ) : (
          <Button variant="danger" className="col-sm-auto h-sm-50px" onClick={removePromoCode}>
            Премахни промокод
          </Button>
        )}
      </CardSectionContent>

      <PromoCodeListModal
        isShown={isPromoCodeListModalShown}
        setIsShown={setIsPromoCodeListModalShown}
        applyPromoCode={applyPromoCode}
      />
    </CardSection>
  );
}
