import cuid from 'cuid';
import { addDays, addMinutes, differenceInMinutes, endOfDay, format, getDay, isBefore, isEqual, parse } from 'date-fns';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { Descendant } from 'slate';

import {
  CalendarEvent,
  createDailyCalendarEvent,
  createDailyIntervalCalendarEvent,
  createNotRepeatingCalendarEvent,
  createTimeSlotEntity,
  createWeeklyCalendarEvent,
  createWeeklyWithDailyIntervalCalendarEvent,
  RecurrenceTypeID,
  ReservedTimeSlot,
  TimeSlotEntity,
  TimeSlotProposalPriceTypeID,
  WeekDay,
} from '@/app/components/Calendar/models';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { StepInstance } from '@/app/components/Stepper/models';

import {
  CoursePackageModel,
  createDailyIntervalTimeSlot,
  createDailyTimeSlot,
  createNotRepeatingTimeSlot,
  createWeeklyTimeSlot,
  createWeeklyWithDailyIntervalTimeSlot,
  TimeSlot,
} from '../../create/models';
import { CourseDetailsData, CourseSubject, Subject } from '../../models';

export function transformIndividualCalenderEventToRecurringCalenderEvent(calendarEvent: CalendarEvent): CalendarEvent {
  let proposalStudent = undefined;
  let proposalCourse = undefined;
  let proposalPriceType = TimeSlotProposalPriceTypeID.Standard;

  if (calendarEvent.extendedProps?.hasProposal) {
    proposalStudent = {
      id: calendarEvent.extendedProps?.reservationStudentID as number,
      text: calendarEvent.extendedProps?.reservationStudentName as string,
    };

    proposalCourse = {
      id: calendarEvent.extendedProps?.reservationCourseID as number,
      text: calendarEvent.extendedProps?.reservationCourseName as string,
    };
  }

  if (calendarEvent.extendedProps?.proposalPrice && !calendarEvent.extendedProps?.isFreeProposal) {
    proposalPriceType = TimeSlotProposalPriceTypeID.Special;
  } else if (!calendarEvent.extendedProps?.isFreeProposal) {
    proposalPriceType = TimeSlotProposalPriceTypeID.Standard;
  } else {
    proposalPriceType = TimeSlotProposalPriceTypeID.Free;
  }

  switch (Number(calendarEvent.extendedProps?.recurrenceTypeID)) {
    case RecurrenceTypeID.Weekly: {
      return createWeeklyCalendarEvent(
        String(calendarEvent.id),
        calendarEvent.extendedProps?.startTime as string,
        calendarEvent.extendedProps?.endTime as string,
        parse(calendarEvent.extendedProps?.startRecur as string, DEFAULT_DATETIME_FORMAT, new Date()),
        endOfDay(parse(calendarEvent.extendedProps?.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date())),
        calendarEvent.extendedProps?.daysOfWeek as WeekDay[],
        Number(calendarEvent.extendedProps?.duration),
        calendarEvent.extendedProps?.timeSlots as ReservedTimeSlot[],
        calendarEvent.extendedProps?.recurringTimeSlotID as number | string,
        (calendarEvent.extendedProps?.hasProposal as number) ?? 0,
        proposalStudent,
        proposalCourse,
        proposalPriceType,
        calendarEvent.extendedProps?.proposalPrice as number
      );
    }
    case RecurrenceTypeID.Daily: {
      return createDailyCalendarEvent(
        String(calendarEvent.id),
        calendarEvent.extendedProps?.startTime as string,
        calendarEvent.extendedProps?.endTime as string,
        parse(calendarEvent.extendedProps?.startRecur as string, DEFAULT_DATETIME_FORMAT, new Date()),
        endOfDay(parse(calendarEvent.extendedProps?.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date())),
        Number(calendarEvent.extendedProps?.duration),
        calendarEvent.extendedProps?.timeSlots as ReservedTimeSlot[],
        calendarEvent.extendedProps?.recurringTimeSlotID as number | string,
        (calendarEvent.extendedProps?.hasProposal as number) ?? 0,
        proposalStudent,
        proposalCourse,
        proposalPriceType,
        calendarEvent.extendedProps?.proposalPrice as number
      );
    }
    case RecurrenceTypeID.DailyInterval: {
      const startRecur = parse(calendarEvent.extendedProps?.startRecur as string, DEFAULT_DATETIME_FORMAT, new Date());

      return createDailyIntervalCalendarEvent(
        String(calendarEvent.id),
        calendarEvent.extendedProps?.startTime as string,
        calendarEvent.extendedProps?.endTime as string,
        startRecur,
        parse(calendarEvent.extendedProps?.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date()),
        Number(calendarEvent.extendedProps?.count),
        Number(calendarEvent.extendedProps?.interval),
        Number(calendarEvent.extendedProps?.duration),
        calendarEvent.extendedProps?.timeSlots as ReservedTimeSlot[],
        calendarEvent.extendedProps?.recurringTimeSlotID as number | string,
        (calendarEvent.extendedProps?.hasProposal as number) ?? 0,
        proposalStudent,
        proposalCourse,
        proposalPriceType,
        calendarEvent.extendedProps?.proposalPrice as number
      );
    }
    case RecurrenceTypeID.WeeklyInterval: {
      const startRecur = parse(calendarEvent.extendedProps?.startRecur as string, DEFAULT_DATETIME_FORMAT, new Date());

      return createWeeklyWithDailyIntervalCalendarEvent(
        String(calendarEvent.id),
        calendarEvent.extendedProps?.startTime as string,
        calendarEvent.extendedProps?.endTime as string,
        startRecur,
        parse(calendarEvent.extendedProps?.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date()),
        calendarEvent.extendedProps?.daysOfWeek as WeekDay[],
        Number(calendarEvent.extendedProps?.count),
        Number(calendarEvent.extendedProps?.interval),
        Number(calendarEvent.extendedProps?.duration),
        calendarEvent.extendedProps?.timeSlots as ReservedTimeSlot[],
        calendarEvent.extendedProps?.recurringTimeSlotID as number | string,
        (calendarEvent.extendedProps?.hasProposal as number) ?? 0,
        proposalStudent,
        proposalCourse,
        proposalPriceType,
        calendarEvent.extendedProps?.proposalPrice as number
      );
    }
    case RecurrenceTypeID.Never:
    default: {
      return createNotRepeatingCalendarEvent(
        String(calendarEvent.id),
        parse(calendarEvent.extendedProps?.startRecur as string, DEFAULT_DATETIME_FORMAT, new Date()),
        parse(calendarEvent.extendedProps?.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date()),
        Number(calendarEvent.extendedProps?.duration),
        calendarEvent.extendedProps?.timeSlots as ReservedTimeSlot[],
        undefined,
        calendarEvent.extendedProps?.recurringTimeSlotID as number | string,
        (calendarEvent.extendedProps?.hasProposal as number) ?? 0,
        proposalStudent,
        proposalCourse,
        proposalPriceType,
        calendarEvent.extendedProps?.proposalPrice as number
      );
    }
  }
}

export function transformCalendarEventToTimeSlot(calendarEvent: CalendarEvent, id?: string | number): TimeSlot {
  switch (Number(calendarEvent.recurrenceTypeID)) {
    case RecurrenceTypeID.Weekly: {
      return createWeeklyTimeSlot(
        id ?? cuid(),
        calendarEvent.startTime,
        calendarEvent.endTime,
        format(calendarEvent.startRecur as Date, DEFAULT_DATETIME_FORMAT),
        format(calendarEvent.endRecur as Date, DEFAULT_DATETIME_FORMAT),
        calendarEvent.daysOfWeek,
        Number(calendarEvent.duration),
        calendarEvent.timeSlots ?? [],
        calendarEvent.hasProposal,
        calendarEvent.proposalStudent,
        calendarEvent.proposalCourse,
        Number(calendarEvent.proposalPriceType),
        calendarEvent.proposalPrice
      );
    }
    case RecurrenceTypeID.Daily: {
      return createDailyTimeSlot(
        id ?? cuid(),
        calendarEvent.startTime,
        calendarEvent.endTime,
        format(calendarEvent.startRecur as Date, DEFAULT_DATETIME_FORMAT),
        format(calendarEvent.endRecur as Date, DEFAULT_DATETIME_FORMAT),
        Number(calendarEvent.duration),
        calendarEvent.timeSlots ?? [],
        calendarEvent.hasProposal,
        calendarEvent.proposalStudent,
        calendarEvent.proposalCourse,
        Number(calendarEvent.proposalPriceType),
        calendarEvent.proposalPrice
      );
    }
    case RecurrenceTypeID.DailyInterval: {
      return createDailyIntervalTimeSlot(
        id ?? cuid(),
        calendarEvent.startTime as string,
        calendarEvent.endTime as string,
        format(calendarEvent.startRecur as Date, DEFAULT_DATETIME_FORMAT),
        format(calendarEvent.endRecur as Date, DEFAULT_DATETIME_FORMAT),
        Number(calendarEvent.count),
        Number(calendarEvent.interval),
        Number(calendarEvent.duration),
        calendarEvent.timeSlots ?? [],
        calendarEvent.hasProposal,
        calendarEvent.proposalStudent,
        calendarEvent.proposalCourse,
        Number(calendarEvent.proposalPriceType),
        calendarEvent.proposalPrice
      );
    }
    case RecurrenceTypeID.WeeklyInterval: {
      return createWeeklyWithDailyIntervalTimeSlot(
        id ?? cuid(),
        calendarEvent.startTime as string,
        calendarEvent.endTime as string,
        format(calendarEvent.startRecur as Date, DEFAULT_DATETIME_FORMAT),
        format(calendarEvent.endRecur as Date, DEFAULT_DATETIME_FORMAT),
        calendarEvent.daysOfWeek,
        Number(calendarEvent.count),
        Number(calendarEvent.interval),
        Number(calendarEvent.duration),
        calendarEvent.timeSlots ?? [],
        calendarEvent.hasProposal,
        calendarEvent.proposalStudent,
        calendarEvent.proposalCourse,
        Number(calendarEvent.proposalPriceType),
        calendarEvent.proposalPrice
      );
    }
    case RecurrenceTypeID.Never:
    default: {
      return createNotRepeatingTimeSlot(
        id ?? cuid(),
        format(calendarEvent.start as Date, DEFAULT_DATETIME_FORMAT),
        format(calendarEvent.end as Date, DEFAULT_DATETIME_FORMAT),
        Number(calendarEvent.duration),
        calendarEvent.timeSlots ?? [],
        calendarEvent.hasProposal,
        calendarEvent.proposalStudent,
        calendarEvent.proposalCourse,
        Number(calendarEvent.proposalPriceType),
        calendarEvent.proposalPrice
      );
    }
  }
}

export function transformTimeSlotsToTimeSlotEntities(timeSlots: TimeSlot[]): TimeSlotEntity[] {
  const timeSlotEntities: TimeSlotEntity[] = [];

  for (const timeSlot of timeSlots) {
    // CASE 1: Never recurring time slot.
    if (timeSlot.recurrenceTypeID === RecurrenceTypeID.Never) {
      timeSlotEntities.push(
        createTimeSlotEntity(timeSlot.id, timeSlot.startDateTime ?? '', timeSlot.endDateTime ?? '')
      );
      continue;
    }

    // CASE 2.2. Recurring time slots -> loop days until current date is equal to the end recurrence date.
    const startRecur = timeSlot.startRecur ?? timeSlot.startDateTime ?? '';
    const endRecur = timeSlot.endRecur ?? timeSlot.endDateTime ?? '';
    const startRecurDate = parse(startRecur, DEFAULT_DATETIME_FORMAT, new Date());
    const endRecurDate = parse(endRecur, DEFAULT_DATETIME_FORMAT, new Date());
    const startTime = parse(timeSlot.startTime ?? '', DEFAULT_TIME_FORMAT, new Date());
    const endTime = parse(timeSlot.endTime ?? '', DEFAULT_TIME_FORMAT, new Date());
    const duration = differenceInMinutes(endTime, startTime);

    let currentDate = new Date(startRecurDate.getTime());

    while (isBefore(currentDate, endRecurDate) || isEqual(currentDate, endRecurDate)) {
      // Skip this date if this is a weekly recurring time slot and today is not included in `daysOfWeek`.
      const isWeeklyRecurring = timeSlot.recurrenceTypeID === RecurrenceTypeID.Weekly;
      const daysOfWeek = timeSlot.daysOfWeek?.map?.((dayOfWeek) => Number(dayOfWeek));
      const currentDayOfWeek = getDay(currentDate);

      if (isWeeklyRecurring && !daysOfWeek?.includes(currentDayOfWeek)) {
        currentDate = addDays(currentDate, 1);
        continue;
      }

      const startDateTime = format(currentDate, DEFAULT_DATETIME_FORMAT);
      const endDateTime = format(addMinutes(currentDate, duration), DEFAULT_DATETIME_FORMAT);

      timeSlotEntities.push(createTimeSlotEntity(timeSlot.id, startDateTime, endDateTime));

      currentDate = addDays(currentDate, 1);
    }
  }

  return timeSlotEntities;
}

export function transformStepInstancesToCourseDetailsData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepInstances: Record<string, StepInstance<any>>,
  tutor: CourseDetailsData['tutor'],
  additionalTutorInfo: CourseDetailsData['additionalTutorInfo'],
  subjects?: Subject[]
): CourseDetailsData {
  const subject = subjects?.find?.(
    (subject) => subject.id === Number(stepInstances.courseDetails.formik.values.subjectID)
  );

  const individualLesson = stepInstances.coursePackage.formik.values.isIndividualLessonEnabled
    ? (stepInstances.coursePackage.formik.values.individualLesson as CoursePackageModel)
    : null;

  const coursePackages = stepInstances.coursePackage.formik.values.isCoursePackagesEnabled
    ? produce(
        stepInstances.coursePackage.formik.values.coursePackages,
        (draft: WritableDraft<CoursePackageModel[]>) => {
          draft.sort((a: CoursePackageModel, b: CoursePackageModel) => Number(a.lessonCount) - Number(b.lessonCount));
        }
      )
    : [];

  return {
    tutor: {
      name: tutor.name,
      identityID: tutor.identityID,
      profileFile: tutor.profileFile,
      about: tutor.about,
      videoFile: tutor.videoFile,
      hasAvailableFutureTimeSlots: tutor.hasAvailableFutureTimeSlots,
    },
    additionalTutorInfo: {
      reactionTime: additionalTutorInfo.reactionTime,
      numberStudents: additionalTutorInfo.numberStudents,
      numberReservedLessons: additionalTutorInfo.numberReservedLessons,
      uniqueVisitsCount: additionalTutorInfo.uniqueVisitsCount,
    },
    course: {
      name: stepInstances.courseDetails.formik.values.name as string,
      goals: stepInstances.courseDetails.formik.values.goals as string,
      methodology: stepInstances.courseDetails.formik.values.methodology as string,
      skills: stepInstances.courseDetails.formik.values.skills as string,
      content: stepInstances.courseDetails.formik.values.content as string,
      description: stepInstances.courseDetails.formik.values.description as Descendant[],
      subjects: [
        {
          id: subject?.id as number,
          name: subject?.text as string,
        } as CourseSubject,
      ],
      keywords: stepInstances.courseDetails.formik.values.keywords,
      targetAudienceName: stepInstances.courseDetails.formik.values.targetAudience?.text ?? null,
      lessonDuration: stepInstances.schedule.formik.values.duration as number,
    },
    availableLessonCount: 0,
    initialCoursePackageID: -1,
    individualLesson: individualLesson,
    coursePackages: coursePackages as CoursePackageModel[],
    isCourseOwner: false,
    canBuyCourse: true,
    isFavorite: false,
    canReturnIndividualLessonBonus: false,
    canRefund: false,
    firstAvailableTimeSlotDateTime: null,
  };
}
