import { useContext, useEffect } from 'react';

import { HeaderContext } from './HeaderContext';

export function useHiddenHeader() {
  const { show, hide } = useContext(HeaderContext);

  useEffect(() => {
    hide();

    return () => show();
  }, [hide, show]);
}
