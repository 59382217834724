import React, { useEffect, useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

import './CopyToClipboard.scss';

interface CopyToClipboardProps {
  text: string;
  copyMessage?: string;
  messageTimeout?: number;
  children?: React.ReactNode;
}

export function CopyToClipboard({
  text,
  copyMessage = 'Текстът е копиран',
  messageTimeout = 3000,
  children = null,
}: CopyToClipboardProps) {
  const copyRef = useRef<HTMLTextAreaElement>(null);
  const tooltipTargetRef = useRef(null);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  useEffect(
    function () {
      if (showCopyTooltip) {
        const hideTimeoutId = setTimeout(() => setShowCopyTooltip(false), messageTimeout);

        return () => clearTimeout(hideTimeoutId);
      }
    },
    [showCopyTooltip] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function handleCopy() {
    if (copyRef.current === null) {
      return;
    }

    copyRef.current.focus();
    copyRef.current.select();

    document.execCommand('copy');
    setShowCopyTooltip(true);
  }

  return (
    <div className="copy-to-clipboard">
      <textarea value={text} ref={copyRef} style={{ width: 0, height: 0, opacity: 0, padding: 0 }} readOnly />
      <span>{children || text}</span>
      <button className="btn btn-sm btn-icon btn-active-light-primary" ref={tooltipTargetRef} onClick={handleCopy}>
        <i className="far fa-copy text-primary" />
      </button>
      <Overlay target={tooltipTargetRef.current} show={showCopyTooltip} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {copyMessage}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
}
