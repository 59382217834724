import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

type CardTitleProps = {
  children: ReactNode;
};

export function CardTitle({ children }: CardTitleProps) {
  return (
    <Card.Title as="h2" className="fs-1 fw-normal my-0">
      {children}
    </Card.Title>
  );
}
