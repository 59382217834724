import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ScrollTopComponent } from '@/_metronic/assets/ts/components';

export function useScrollTopOnHistoryChange() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      ScrollTopComponent.goTop();
    });

    return () => {
      unlisten();
    };
  }, [history]);
}
