import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { NotificationEditForm } from '@/app/modules/profile/notifications/edit/NotificationEditForm';
import { NotificationBasePage } from '@/app/modules/profile/notifications/NotificationBasePage';
import { getProfileGeneralNotifications } from '@/app/modules/profile/notifications/services';
import { NotificationUnsubscribeModal } from '@/app/modules/profile/notifications/Unsubscribe/NotificationUnsubscribeModal';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

const GENERAL_NOTIFICATION_PAGE_TITLE = 'Основни известия';

export function GeneralNotificationPage() {
  const dispatch = useDispatch();
  const loading = useAppSelector((state) => state.profileNotifications.loading);
  const notificationTypes = useAppSelector((state) => state.profileNotifications.notificationGeneralTypes);
  const notificationGeneralSettings = useAppSelector((state) => state.profileNotifications.notificationGeneralSettings);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getProfileGeneralNotifications());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NotificationBasePage title={GENERAL_NOTIFICATION_PAGE_TITLE}>
      <CardHeader>
        <CardTitle>{GENERAL_NOTIFICATION_PAGE_TITLE}</CardTitle>
      </CardHeader>
      <CardBody>
        <NotificationEditForm
          notificationTypes={notificationTypes}
          notificationSettings={notificationGeneralSettings}
          onSubmit={getProfileGeneralNotifications}
        />
        <NotificationUnsubscribeModal onClose={getProfileGeneralNotifications} />
      </CardBody>
    </NotificationBasePage>
  );
}
