import { LinkButton } from '../Button/LinkButton';

type GetStartedButtonProps = {
  to: string;
  isDisabled?: boolean;
};

export function GetStartedButton({ to, isDisabled = false }: GetStartedButtonProps) {
  return (
    <LinkButton className="w-100 mw-150px w-sm-auto" variant="light-primary" to={to} isDisabled={isDisabled}>
      Започни
    </LinkButton>
  );
}
