import { Dropdown } from 'react-bootstrap';
import { DropdownMenuProps } from 'react-bootstrap/esm/DropdownMenu';

export function DropdownMenu({ ...props }: DropdownMenuProps) {
  return (
    <Dropdown.Menu
      {...props}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
        ],
      }}
    />
  );
}
