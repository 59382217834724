import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import clsx from 'clsx';

import './IconButton.scss';

type IconButtonProps = React.PropsWithChildren<ButtonProps> & {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  as?: React.ElementType;
  className?: string;
};

export function IconButton({ onClick, as, className = '', ...props }: IconButtonProps) {
  return <Button {...props} size="lg" onClick={onClick} as={as} className={clsx(className, 'icon-button')} />;
}
