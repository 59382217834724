import { useContext, useMemo } from 'react';
import { Row } from 'react-bootstrap';
import * as yup from 'yup';

import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { useForm } from '@/app/components/Form/useForm';
import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { serializeToHtml } from '@/app/components/RichTextControl/utils';
import { StepInstance } from '@/app/components/Stepper/models';
import { StepperContext } from '@/app/components/Stepper/StepperContext';
import { TutorEducationalDegreeID } from '@/app/modules/tutor/models';
import { getEducationDegree, getTeachingExperience } from '@/app/modules/tutor/utils';
import { LoadingState } from '@/redux/constants';

import { TUTOR_PROFILE_PREVIEW_STEP_TITLE } from '../constants';
import { TutorProfilePreviewCard } from './TutorProfilePreviewCard';

import './TutorProfilePreviewStep.scss';

export function useTutorProfilePreviewStep(stepNumber: number): StepInstance {
  const initialValues = useMemo(() => ({}), []);

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<unknown>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });

  return { formik, stepNumber };
}

export function TutorProfilePreviewStep() {
  const stepperContext = useContext(StepperContext);

  const tutorProfileData = stepperContext.getStepData('tutorProfileDetails');
  const tutorAboutDetails = stepperContext.getStepData('tutorAboutDetails');
  const invoiceData = stepperContext.getStepData('invoiceDetails');
  const bankData = stepperContext.getStepData('bankDetails');

  const educationDegree = useMemo(
    () => getEducationDegree(tutorAboutDetails?.educationDegreeID),
    [tutorAboutDetails?.educationDegreeID]
  );

  const teachingExperience = useMemo(
    () => getTeachingExperience(tutorAboutDetails?.teachingExperienceID),
    [tutorAboutDetails?.teachingExperienceID]
  );

  const serializedAbout = useMemo(
    () => serializeToHtml(tutorProfileData?.about ?? EMPTY_VALUE),
    [tutorProfileData?.about]
  );

  return (
    <>
      <CardHeader>
        <CardTitle>{TUTOR_PROFILE_PREVIEW_STEP_TITLE}</CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="g-8">
          <TutorProfilePreviewCard header="Профил" editStep={1}>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Име</div>
              <div className="tutor-preview-card__field-value">
                {tutorProfileData?.firstName} {tutorProfileData?.lastName}
              </div>
            </div>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Телефон</div>
              <div className="tutor-preview-card__field-value">{tutorProfileData?.phone}</div>
            </div>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Имейл Адрес</div>
              <div className="tutor-preview-card__field-value">{tutorProfileData?.email}</div>
            </div>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Потребителско име</div>
              <div className="tutor-preview-card__field-value">{tutorProfileData?.username}</div>
            </div>
            <div className="tutor-preview-card__field mb-0">
              <div className="tutor-preview-card__field-label">За мен</div>
              <div className="tutor-preview-card__field-value">
                <p dangerouslySetInnerHTML={{ __html: serializedAbout }} />
              </div>
            </div>
          </TutorProfilePreviewCard>
          <TutorProfilePreviewCard header="За мен" editStep={2}>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Моето образование е:</div>
              <div className="tutor-preview-card__field-value">{educationDegree}</div>
            </div>
            {Number(tutorAboutDetails?.educationDegreeID) === TutorEducationalDegreeID.University &&
              tutorAboutDetails?.university && (
                <div className="tutor-preview-card__field">
                  <div className="tutor-preview-card__field-label">Университет/Специалност:</div>
                  <div className="tutor-preview-card__field-value">{tutorAboutDetails?.university ?? ''}</div>
                </div>
              )}
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Опитът ми като обучител е:</div>
              <div className="tutor-preview-card__field-value">{teachingExperience}</div>
            </div>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Подходът ми в преподаването е:</div>
              <div className="tutor-preview-card__field-value">{tutorAboutDetails?.approach ?? ''}</div>
            </div>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Мотивацията ми като обучител е:</div>
              <div className="tutor-preview-card__field-value">{tutorAboutDetails?.motivation ?? ''}</div>
            </div>
            <div className="tutor-preview-card__field mb-0">
              <div className="tutor-preview-card__field-label">Най-важното за мен в работата с учениците е:</div>
              <div className="tutor-preview-card__field-value">{tutorAboutDetails?.importance ?? ''}</div>
            </div>
          </TutorProfilePreviewCard>
          <TutorProfilePreviewCard header="Издаване на фактура" editStep={3}>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Име</div>
              <div className="tutor-preview-card__field-value">
                {invoiceData?.firstName} {invoiceData?.lastName}
              </div>
            </div>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Телефон</div>
              <div className="tutor-preview-card__field-value">{invoiceData?.phone}</div>
            </div>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Имейл Адрес</div>
              <div className="tutor-preview-card__field-value">{invoiceData?.email}</div>
            </div>
            <div className="tutor-preview-card__field mb-0">
              <div className="tutor-preview-card__field-label">Дата на раждане</div>
              <div className="tutor-preview-card__field-value">{invoiceData?.birthday}</div>
            </div>
          </TutorProfilePreviewCard>

          <TutorProfilePreviewCard header="Изплащане на приходи" editStep={4}>
            <div className="tutor-preview-card__field">
              <div className="tutor-preview-card__field-label">Име на собственик на сметката</div>
              <div className="tutor-preview-card__field-value">{bankData?.bankAccountOwnerName}</div>
            </div>
            <div className="tutor-preview-card__field mb-0">
              <div className="tutor-preview-card__field-label">IBAN на сметката</div>
              <div className="tutor-preview-card__field-value">{bankData?.bankAccountIBAN}</div>
            </div>
          </TutorProfilePreviewCard>
        </Row>
      </CardBody>
    </>
  );
}
