import { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import { StepInstance } from './models';
import { useStepperContext } from './useStepperContext';
import { getDefaultStepperItemTitle } from './utils';

import './StepperItem.scss';

type StepperItemProps = {
  number: number;
  title?: string;
  description?: string;
};

export function StepperItem({ number, title, description }: StepperItemProps) {
  const { stepInstances, currentStep, incrementStepCount, decrementStepCount } = useStepperContext();
  const internalTitle = title ?? getDefaultStepperItemTitle(number);
  const stepInstance = useMemo<StepInstance | null>(() => {
    for (const stepKey in stepInstances) {
      // Sanity check.
      if (!Object.prototype.hasOwnProperty.call(stepInstances, stepKey)) {
        continue;
      }

      const stepInstance = stepInstances[stepKey];

      if (stepInstance.stepNumber !== number) {
        continue;
      }

      return stepInstance;
    }

    return null;
  }, [number, stepInstances]);
  const elRef = useRef<HTMLDivElement | null>(null);
  const isValid = stepInstance?.formik?.isValid ?? true;
  const isCurrent = currentStep === number;
  const isCompleted = currentStep !== null && currentStep > number;

  useEffect(function componentDidMount() {
    incrementStepCount();

    return function componentWillUnmount() {
      decrementStepCount();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    function handleScroll() {
      if (isCurrent && elRef.current) {
        setTimeout(() => {
          elRef.current?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }, 1000); // a little hack to make sure re-render is done
      }
    },
    [isCurrent]
  );

  return (
    <div ref={elRef} className={clsx('stepper-item my-4', { current: isCurrent, completed: isCompleted })}>
      <div className={clsx('stepper-icon w-40px h-40px', { 'bg-light-danger': !isValid && !isCurrent && isCompleted })}>
        <i
          className={clsx('stepper-check fas', {
            'fa-check': isValid,
            'text-danger fa-exclamation': !isValid,
          })}
        ></i>
        <span className="stepper-number">{number}</span>
      </div>

      <div className="stepper-label">
        <h3 className="stepper-title text-nowrap">{internalTitle}</h3>

        {description && <div className="stepper-desc text-nowrap">{description}</div>}
      </div>
    </div>
  );
}
