import { createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import {
  ReservationProposalData,
  ReservationProposalSuggestionData,
  ReservationProposalSuggestionSummaryData,
  ReservationProposalSummaryData,
  ReservationProposalTimeSlotsData,
} from './models';
import {
  getReservationProposalData,
  getReservationProposalSuggestionData,
  getReservationProposalSuggestionSummaryData,
  getReservationProposalSummaryData,
  getReservationProposalTimeSlots,
} from './service';

interface ReservationProposalState {
  loading: LoadingState;
  scheduleLoading: LoadingState;
  timeSlotsLoading: LoadingState;
  error?: string | null;
  reservationProposal: ReservationProposalData;
  reservationProposalSummary: ReservationProposalSummaryData;
  reservationProposalSuggestion: ReservationProposalSuggestionData & ReservationProposalTimeSlotsData;
  reservationProposalSuggestionSummary: ReservationProposalSuggestionSummaryData | null;
}

const initialState: ReservationProposalState = {
  loading: LoadingState.Idle,
  scheduleLoading: LoadingState.Idle,
  timeSlotsLoading: LoadingState.Idle,
  error: null,
  reservationProposal: {
    course: {
      name: '',
      courseStatusID: 0,
      tutorID: null,
      tutorName: '',
      tutorProfileImagePath: null,
      currentRating: null,
      availableLessonCount: 0,
      courseTransliteratedSlug: '',
      targetAudienceName: '',
      subjectName: '',
    },
    reservation: null,
    defaultConnectedStudent: {
      name: '',
      phone: '',
      email: '',
      connectedStudentLevelID: null,
    },
    connectedStudents: [],
    connectedStudentLevels: [],
    reservationConnectedStudentIds: [],
  },
  reservationProposalSummary: {
    course: {
      name: '',
      tutorID: null,
      tutorName: '',
      tutorProfileImagePath: null,
    },
    connectedStudent: {
      name: '',
      phone: '',
      email: '',
      connectedStudentLevelText: null,
      expectations: '',
    },
    reservation: null,
    order: null,
  },
  reservationProposalSuggestion: {
    courseName: '',
    connectedStudentName: '',
    connectedStudentID: null,
    studentID: null,
    courseID: null,
    timeSlots: [],
  },
  reservationProposalSuggestionSummary: null,
};

const reservationProposalSlice = createSlice({
  name: 'reservationProposal',
  initialState,
  reducers: {
    resetCourseReservationPage(state) {
      state.reservationProposal = initialState.reservationProposal;
    },
  },
  extraReducers: (builder) => {
    //#region Get reservation proposal data
    builder
      .addCase(getReservationProposalData.pending, sharedPendingReducer)
      .addCase(getReservationProposalData.rejected, sharedRejectedReducer)
      .addCase(getReservationProposalData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.reservationProposal.course = action.payload.course;
        state.reservationProposal.reservation = action.payload.reservation;
        state.reservationProposal.defaultConnectedStudent = action.payload.defaultConnectedStudent;
        state.reservationProposal.reservationConnectedStudentIds = action.payload.reservationConnectedStudentIds;
        state.reservationProposal.connectedStudents = action.payload.connectedStudents;
        state.reservationProposal.connectedStudentLevels = action.payload.connectedStudentLevels;
      });
    //#endregion Get reservation proposal data

    //#region Get reservation proposal summary data
    builder
      .addCase(getReservationProposalSummaryData.pending, sharedPendingReducer)
      .addCase(getReservationProposalSummaryData.rejected, sharedRejectedReducer)
      .addCase(getReservationProposalSummaryData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.reservationProposalSummary.course = action.payload.course;
        state.reservationProposalSummary.connectedStudent = action.payload.connectedStudent;
        state.reservationProposalSummary.reservation = action.payload.reservation;
        state.reservationProposalSummary.order = action.payload.order;
      });
    //#endregion Get reservation proposal summary data

    //#region Get canceled reservation proposal data
    builder
      .addCase(getReservationProposalSuggestionData.pending, sharedPendingReducer)
      .addCase(getReservationProposalSuggestionData.rejected, sharedRejectedReducer)
      .addCase(getReservationProposalSuggestionData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.reservationProposalSuggestion.studentID = action.payload.studentID;
        state.reservationProposalSuggestion.courseID = action.payload.courseID;
        state.reservationProposalSuggestion.courseName = action.payload.courseName;
        state.reservationProposalSuggestion.connectedStudentName = action.payload.connectedStudentName;
        state.reservationProposalSuggestion.connectedStudentID = action.payload.connectedStudentID;
      });
    //#endregion Get canceled reservation proposal data

    //#region Get reservation proposal time slots
    builder
      .addCase(getReservationProposalTimeSlots.pending, (state) => {
        if (state.timeSlotsLoading === LoadingState.Idle) {
          state.timeSlotsLoading = LoadingState.Pending;
          state.reservationProposalSuggestion.timeSlots = initialState.reservationProposalSuggestion.timeSlots;
        }
      })
      .addCase(getReservationProposalTimeSlots.rejected, (state, action) => {
        if (state.timeSlotsLoading === LoadingState.Pending) {
          state.timeSlotsLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getReservationProposalTimeSlots.fulfilled, (state, action) => {
        state.timeSlotsLoading = LoadingState.Idle;
        state.reservationProposalSuggestion.timeSlots = action.payload.timeSlots;
      });
    //#endregion Get canceled reservation proposal time slots

    //#region Get canceled reservation proposal summary data
    builder
      .addCase(getReservationProposalSuggestionSummaryData.pending, sharedPendingReducer)
      .addCase(getReservationProposalSuggestionSummaryData.rejected, sharedRejectedReducer)
      .addCase(getReservationProposalSuggestionSummaryData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.reservationProposalSuggestionSummary = action.payload.summary;
      });
    //#endregion Get canceled reservation proposal summary data
  },
});

export default reservationProposalSlice;
