import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { Notification } from './models';
import { getNotifications, markAllNotificationsRead, markNotificationRead } from './service';

interface NotificationState {
  loading: LoadingState;
  notifications: Notification[];
  unreadNotificationsCount: number;
  hasMore: boolean;
  currentPage: number;
}

const initialState: NotificationState = {
  loading: LoadingState.Idle,
  notifications: [],
  unreadNotificationsCount: 0,
  hasMore: true,
  currentPage: 1,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    loadMore(state) {
      if (state.loading === LoadingState.Idle && state.hasMore) {
        state.currentPage++;
      }
    },
    reset(state) {
      state.currentPage = 1;
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    //#region Get notifications
    builder
      .addCase(getNotifications.pending, sharedPendingReducer)
      .addCase(getNotifications.rejected, sharedRejectedReducer)
      .addCase(getNotifications.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.hasMore = action.payload.hasMore;
          state.unreadNotificationsCount = action.payload.unreadNotificationsCount;

          // Make sure only the new notifications are added to the state.
          if (action.payload.currentPage >= state.currentPage) {
            for (const notification of action.payload.notifications) {
              const idx = state.notifications.findIndex((x) => x.id === notification.id);

              if (idx === -1) {
                state.notifications.push(notification);
              }
            }
          }

          state.currentPage = action.payload.currentPage;
        }
      });
    //#endregion Get notifications

    //#region Mark notification as read
    builder
      .addCase(markNotificationRead.pending, sharedPendingReducer)
      .addCase(markNotificationRead.rejected, sharedRejectedReducer)
      .addCase(markNotificationRead.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.unreadNotificationsCount = action.payload.unreadNotificationsCount;

          for (const notification of state.notifications) {
            if (notification.id === action.payload.readNotificationID) {
              notification.isRead = 1;
            }
          }
        }
      });
    //#endregion Mark notification as read

    //#region Mark all notifications as read
    builder
      .addCase(markAllNotificationsRead.pending, sharedPendingReducer)
      .addCase(markAllNotificationsRead.rejected, sharedRejectedReducer)
      .addCase(markAllNotificationsRead.fulfilled, (state) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.unreadNotificationsCount = 0;
          for (const notification of state.notifications) {
            notification.isRead = 1;
          }
        }
      });
    //#endregion Mark all notifications as read
  },
});

export default notificationSlice;
