import { MouseEventHandler } from 'react';
import { Options } from 'select2';

export interface Select2Option {
  id: number | string | null;
  text: string;
}

export function createSelect2Option(id: number | null, text: string): Select2Option {
  return { id, text };
}

export interface ExtendedSelect2Options extends Options {
  selectionCssClass?: string;
}

export interface SharedSelectControlProps {
  name: string;
  id?: string;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  onClick?: MouseEventHandler;
  hasSolidBackground?: boolean;
  isSearchable?: boolean;
  minimumInputLength?: number;
}

export interface SharedMultipleSelectControlProps extends SharedSelectControlProps {
  maximumSelectionLength?: number;
  maximumSelectionLengthErrorMessage?: string;
}
