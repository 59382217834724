import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useIdleTimer } from 'react-idle-timer';

import { useAppSelector } from '@/redux/store';

import { keepAlive } from './service';

export function SessionTimeoutModal() {
  const [isIdleModalShown, setIsIdleModalShown] = useState(false);
  const [modalShownTime, setModalShownTime] = useState<number | null>(null);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const { reset } = useIdleTimer({
    timeout: 3540000, // 59 minutes
    onIdle: () => {
      setIsIdleModalShown(true);
      setModalShownTime(Date.now());
    },
    debounce: 500,
  });

  function handleKeepAlive() {
    keepAlive();
    setIsIdleModalShown(false);
    setModalShownTime(null);
    reset();
  }

  useEffect(
    function componentDidMount() {
      if (isAuthenticated) {
        const interval = setInterval(() => {
          if (!modalShownTime) {
            return;
          }

          const waitedTime = Date.now() - Number(modalShownTime);

          if (waitedTime < 60000) {
            return;
          }

          window.location.reload();
        }, 1000);

        return () => clearInterval(interval);
      }
    },
    [isAuthenticated, modalShownTime]
  );

  return (
    <Modal show={isIdleModalShown && isAuthenticated} onHide={handleKeepAlive}>
      <Modal.Body>
        <p className="font-size-h3 m-0">Сесията Ви ще изтече след 1 минута.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" href="/auth/logout">
          Изход
        </Button>
        <Button variant="primary" onClick={handleKeepAlive}>
          Остани логнат
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
