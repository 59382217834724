import produce from 'immer';

import { FavoriteCourseFilterQueryParams, FavoriteCourseFilterValues } from '@/app/modules/favorite-course/models';
import { stringifyQuery } from '@/app/utils/query';

export function transformFavoriteCourseFormikValuesToSearchParams(
  formikValues: FavoriteCourseFilterValues,
  queryParams: FavoriteCourseFilterQueryParams
) {
  const newQueryParams: FavoriteCourseFilterQueryParams = produce(queryParams, (draft) => {
    if (formikValues.search !== '') {
      draft.search = formikValues.search;
    } else {
      delete draft.search;
    }

    if (formikValues.availableLessonsRange.length > 0) {
      draft.availableLessonsRange = formikValues.availableLessonsRange;
    } else {
      delete draft.availableLessonsRange;
    }
  });

  return stringifyQuery(newQueryParams);
}
