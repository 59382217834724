/**
 * Formats hour with zero padding.
 *
 * @param hour Hour to format
 * @returns Formatted hour
 */
export function formatHour(hour: number) {
  const formattedHour = String(hour);

  if (formattedHour.length === 2) {
    return formattedHour;
  }

  if (formattedHour.length < 2) {
    return '0' + formattedHour;
  }
}
