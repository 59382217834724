import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { CardBody } from '@/app/components/Card/CardBody';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { formatInfoCount } from '@/app/utils/formatInfoCount';
import { formatInfoRating } from '@/app/utils/formatInfoRating';
import { formatPrice } from '@/app/utils/money';

import { MAX_NUMBER_OF_SUBJECTS_IN_CARD, MAX_RATING } from '../../constants';
import { TutorIndexData, TutorSubject } from '../../models';

import './TutorItem.scss';

type TutorItemProps = {
  tutor: TutorIndexData;
};

export function TutorItem({ tutor }: TutorItemProps) {
  return (
    <Col md={6}>
      <Link to={`tutors/${tutor.transliteratedSlug}`} className="card card--tutor-item flex-sm-row text-dark">
        <Card.Img
          src={tutor.profileFile ?? NO_AVATAR_PATH}
          alt="Профилна снимка"
          className="w-100px h-100px rounded ms-8 mt-9 mb-sm-9"
        />

        <CardBody className="d-flex flex-column align-items-start">
          <h3>{tutor.name}</h3>
          <span className="badge bg-gray-200 text-dark mb-2">
            {formatInfoRating(tutor.currentRating)} / {MAX_RATING} ({formatInfoCount(tutor.ratingCount)} оценки)
          </span>
          <div className="mt-auto w-100">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="mb-1">
                {tutor.subjects
                  .filter((_subject: TutorSubject, index: number) => index < MAX_NUMBER_OF_SUBJECTS_IN_CARD)
                  .map((subject: TutorSubject, index: number) => (
                    <span className="badge bg-gray-200 text-dark me-2 mb-2 text-wrap" key={index}>
                      {subject.subjectName}
                    </span>
                  ))}
                {tutor.subjects.length > MAX_NUMBER_OF_SUBJECTS_IN_CARD && (
                  <span className="badge bg-gray-200 text-dark me-2 mb-2">+</span>
                )}
              </div>

              <div className="ms-auto">
                <p className="m-0">
                  от <strong className="fs-3 fw-bolder">{formatPrice(tutor.startingPrice)}</strong>
                </p>
                <p className="m-0  text-center text-gray-500">(за {tutor.lessonDuration} мин.)</p>
              </div>
            </div>
          </div>
        </CardBody>
      </Link>
    </Col>
  );
}
