import { useContext, useEffect } from 'react';

import { ConsultUsContext } from '@/_metronic/layout/components/consult-us/ConsultUsContext';

export function useHiddenConsultUs() {
  const { show, hide } = useContext(ConsultUsContext);

  useEffect(() => {
    hide();

    return () => show();
  }, [hide, show]);
}
