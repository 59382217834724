import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createTableData, TableDataModel } from '@/app/components/Table/models';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { DEFAULT_PAGE_VALUE } from './constants';
import { Card } from './models';
import { getCards } from './service';

interface CardState {
  loading: LoadingState;
  error?: string | null;
  cards: TableDataModel<Card[]>;
  currentPage: number;
}

const initialState: CardState = {
  loading: LoadingState.Idle,
  error: null,
  cards: createTableData(),
  currentPage: DEFAULT_PAGE_VALUE,
};

const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    //#region Get cards
    builder
      .addCase(getCards.pending, sharedPendingReducer)
      .addCase(getCards.rejected, sharedRejectedReducer)
      .addCase(getCards.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.currentPage = action.payload.cards.currentPage;
        state.cards = action.payload.cards;
      });
    //#endregion Get cards
  },
});

export default cardSlice;
