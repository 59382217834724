import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorsState {
  httpStatusCode?: number;
  errorMessage?: string;
  type?: string;
}

const initialState: ErrorsState = {
  httpStatusCode: undefined,
  errorMessage: undefined,
  type: undefined,
};

const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setHttpGetError(state, action: PayloadAction<ErrorsState>) {
      state.httpStatusCode = action.payload.httpStatusCode;
      state.errorMessage = action.payload.errorMessage;
      state.type = action.payload.type;
    },
  },
});

export default errorsSlice;
