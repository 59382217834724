import { ReactNode } from 'react';
import { Col } from 'react-bootstrap';

type CardSectionDescriptionProps = {
  children?: ReactNode;
};

export function CardSectionDescription({ children }: CardSectionDescriptionProps) {
  return <Col className="mw-xl-250px pb-6 px-9 pt-md-6">{children}</Col>;
}
