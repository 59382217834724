import cuid from 'cuid';

export class EventInterval {
  private id: number | string;
  private recurringID: number | string;
  private startDate?: Date;
  private endDate?: Date;
  private low: number;
  private high: number;

  public constructor(id?: number | string, recurringID?: number | string, startDate?: Date, endDate?: Date) {
    this.id = id ?? cuid();
    this.recurringID = recurringID ?? this.id;
    this.startDate = startDate;
    this.endDate = endDate;
    this.low = startDate?.getTime?.() ?? 0;
    this.high = 0;

    if (endDate) {
      this.high = endDate.getTime() - 1;
    }
  }
  public getID() {
    return [this.id, this.recurringID];
  }

  public getInterval(): [number, number] {
    return [this.low, this.high];
  }

  public getStartDate() {
    return this.startDate;
  }

  public getEndDate() {
    return this.endDate;
  }
}
