import { AxiosError, AxiosInstance } from 'axios';
import Qs from 'qs';
import Toastr from 'toastr';

import auth from '@/app/modules/auth/store';
import { GENERAL_ERROR_MESSAGE } from '@/app/modules/errors/constants';
import errors from '@/app/modules/errors/store';

import { AppDispatch } from './store';

export function setupAxios(axios: AxiosInstance, dispatch: AppDispatch) {
  axios.interceptors.request.use(
    (config) => {
      // Add AJAX header for Laravel
      config.headers['X-Requested-With'] = 'XMLHttpRequest';

      // Handle Laravel's PUT request with FormData
      if (config.method === 'put' && config.headers['Content-Type'] === 'multipart/form-data') {
        config.method = 'post';
        config.data = config.data ?? new FormData();
        config.data.append('_method', 'PUT');
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const statusCode = Number(error?.response?.status);
      const isGetRequest = error.config.method === 'get';
      const isFormRequest =
        error.config.method === 'post' || error.config.method === 'put' || error.config.method === 'delete';

      //#region CASE 1: Handle authorization
      const isAuthorizationError = statusCode === 403;

      if (isGetRequest && isAuthorizationError) {
        dispatch(
          errors.actions.setHttpGetError({
            httpStatusCode: statusCode,
            errorMessage: error?.response?.data?.message,
            type: error?.response?.data?.type,
          })
        );
        return Promise.reject(error);
      }

      if (isFormRequest && isAuthorizationError) {
        Toastr.error('Нямате права за това действие.');
        return Promise.reject(error);
      }
      //#endregion

      //#region CASE 2: Handle generic error
      const isGenericError = statusCode > 299 && statusCode !== 422 && statusCode !== 401;
      const isCustomError = error?.response?.data?.type === 'custom';

      if (isGetRequest && isGenericError) {
        dispatch(
          errors.actions.setHttpGetError({
            httpStatusCode: statusCode,
            errorMessage: error?.response?.data?.message,
            type: error?.response?.data?.type,
          })
        );
        return Promise.reject(error);
      }

      if (isFormRequest && isGenericError) {
        Toastr.error(isCustomError ? error?.response?.data?.message : GENERAL_ERROR_MESSAGE);
        return Promise.reject(error);
      }
      //#endregion

      // CASE 3: Handle authentication
      if (error?.response?.status === 401) {
        dispatch(auth.actions.resetAuthentication());
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  /**
   * Structures the parameters in a way Laravel understands
   * Given an object with a structure
   * filters : {
   *   name: 'Test'
   * }
   * the interceptor will transform it into ?filters[name]='Test'
   */
  axios.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => {
      // Qs is not included in the Axios package
      return Qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      });
    };

    return config;
  });
}
