import { Node, Path, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { CustomElement } from '@/types/slate';

import { ENTER_BLOCK, PARAGRAPH } from '../constants';

export const withLists = (editor: ReactEditor) => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    const path = editor?.selection?.focus?.path as Path;
    const currentNode = Node.get(editor, path) as CustomElement;

    if (currentNode.text === '') {
      const nodes = Node.ancestors(editor, path, { reverse: true }) as Generator<CustomElement[], void, undefined>;

      for (const [n] of nodes) {
        // Only unwrap the current selection if the cursor is in a list and the current node text is empty
        if (ENTER_BLOCK[n.type as string]) {
          Transforms.unwrapNodes(editor, { match: (n: CustomElement) => ENTER_BLOCK[n.type as string], split: true });
          Transforms.setNodes(editor, { type: PARAGRAPH });
          return;
        }
      }
    }

    insertBreak();
  };

  return editor;
};
