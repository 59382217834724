import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableDataModel } from '@/app/components/Table/models';

import {
  ExpectedIncome,
  ExpectedIncomeFilterQueryParams,
  Income,
  IncomeFilterQueryParams,
  MonthlyIncome,
  MonthlyIncomeFilterQueryParams,
  SoldCourse,
  SoldCoursesFilterQueryParams,
  Withdrawal,
  WithdrawalsFilterQueryParams,
} from './models';

const ENDPOINT_PREFIX = '/ajax/profile/profit';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  payout: '/ajax/profile/payout',
  withdrawals: `${ENDPOINT_PREFIX}/withdrawals`,
  income: `${ENDPOINT_PREFIX}/income`,
  expectedIncome: `${ENDPOINT_PREFIX}/expected-income`,
  soldCourses: `${ENDPOINT_PREFIX}/sold-courses`,
  monthlyIncome: `${ENDPOINT_PREFIX}/monthly-income`,
};

//#region GET PROFIT DATA
export const getProfileProfitData = createAsyncThunk('profileProfit/getProfileProfitData', async function () {
  const response = await axios.get<GetProfileProfitDataResponse>(ENDPOINTS.resource);
  return response.data;
});

export interface GetProfileProfitDataResponse {
  balance: string;
  blockedBalance: string;
  canPayout: boolean;
  hasBlockedPayouts: boolean;
  minRequiredBalanceToPayout: string;
  hasBlockedIncome: boolean;
  hasBlockedExpectedIncome: boolean;
}
//#endregion GET PROFIT DATA

//#region MAKE PAYOUT
export const makePayout = createAsyncThunk('profileProfit/makePayout', async function () {
  const response = await axios.put<MakePayoutResponse>(ENDPOINTS.payout);
  return response.data;
});

export type MakePayoutResponse = GetProfileProfitDataResponse;
//#endregion MAKE PAYOUT

//#region GET WITHDRAWALS DATA
export const getProfileProfitWithdrawalsData = createAsyncThunk(
  'profileProfit/getProfileProfitWithdrawalsData',
  async function (queryParams: WithdrawalsFilterQueryParams) {
    const response = await axios.get<GetProfileProfitWithdrawalsDataResponse>(ENDPOINTS.withdrawals, {
      params: queryParams,
    });
    return response.data;
  }
);

export interface GetProfileProfitWithdrawalsDataResponse {
  withdrawals: TableDataModel<Withdrawal[]>;
}
//#endregion GET WITHDRAWALS DATA

//#region GET INCOME DATA
export const getProfileProfitIncomeData = createAsyncThunk(
  'profileProfit/getProfileProfitIncomeData',
  async function (queryParams: IncomeFilterQueryParams) {
    const response = await axios.get<GetProfileProfitIncomeDataResponse>(ENDPOINTS.income, {
      params: queryParams,
    });
    return response.data;
  }
);

export interface GetProfileProfitIncomeDataResponse {
  income: TableDataModel<Income[]>;
}
//#endregion GET INCOME DATA

//#region GET EXPECTED INCOME DATA
export const getProfileProfitExpectedIncomeData = createAsyncThunk(
  'profileProfit/getProfileProfitExpectedIncomeData',
  async function (queryParams: ExpectedIncomeFilterQueryParams) {
    const response = await axios.get<GetProfileProfitExpectedIncomeDataResponse>(ENDPOINTS.expectedIncome, {
      params: queryParams,
    });
    return response.data;
  }
);

export interface GetProfileProfitExpectedIncomeDataResponse {
  expectedIncome: TableDataModel<ExpectedIncome[]>;
}
//#endregion GET EXPECTED INCOME DATA

//#region GET SOLD COURSES DATA
export const getProfileProfitSoldCoursesData = createAsyncThunk(
  'profileProfit/getProfileProfitSoldCoursesData',
  async function (queryParams: SoldCoursesFilterQueryParams) {
    const response = await axios.get<GetProfileProfitSoldCoursesDataResponse>(ENDPOINTS.soldCourses, {
      params: queryParams,
    });
    return response.data;
  }
);

export interface GetProfileProfitSoldCoursesDataResponse {
  soldCourses: TableDataModel<SoldCourse[]>;
}
//#endregion GET SOLD COURSES  DATA

//#region GET MONTHLY INCOME DATA
export const getProfileProfitMonthlyIncomeData = createAsyncThunk(
  'profileProfit/getProfileProfitMonthlyData',
  async function (queryParams: MonthlyIncomeFilterQueryParams) {
    const response = await axios.get<GetProfileProfitMonthlyIncomeDataResponse>(ENDPOINTS.monthlyIncome, {
      params: queryParams,
    });
    return response.data;
  }
);

export interface GetProfileProfitMonthlyIncomeDataResponse {
  monthlyIncome: TableDataModel<MonthlyIncome[]>;
}
//#endregion GET MONTHLY INCOME DATA
