import { ReactNode } from 'react';
import SVG from 'react-inlinesvg';

import { PromoBannerContainer } from '@/app/components/PromoBannerContainer/PromoBannerContainer';
import { ScrollToLink } from '@/app/components/ScrollToLink/ScrollToLink';
import { Promotion } from '@/app/modules/promotion/constants';
import { useAppSelector } from '@/redux/store';

type LessonPromotionAlertProps = {
  className?: string;
};

export function LessonPromotionAlert({ className }: LessonPromotionAlertProps) {
  const promotionStore = useAppSelector((state) => state.promotion);

  let promotionText: string | ReactNode = '';

  switch (promotionStore.activePromotionSlug) {
    case Promotion.BonusLesson:
      promotionText = `Първите 100 потребители, закупили индивидуален урок, ще получат два безплатни урока бонус.`;
      break;
    case Promotion.FreeLesson:
      promotionText = 'Първите 100 нови потребители през март получават БЕЗПЛАТЕН урок.';
      break;
    case Promotion.Discount50Percentage:
      promotionText = `Вземи първи индивидуален урок с ${promotionStore.discountFormatted} отстъпка.`;
      break;
    case Promotion.Discount25Percentage:
      promotionText = (
        <>
          {`Успешна учебна година с подарък ${promotionStore.discountFormatted} отстъпка за първи индивидуален урок `}
          <ScrollToLink to="#promotion-description" className="fs-1 text-white">
            *
          </ScrollToLink>
        </>
      );
      break;
  }

  if (!(promotionStore.activePromotionSlug && promotionStore.canUseLessonPromotion)) {
    return null;
  }

  return (
    <PromoBannerContainer className={className} contentClassName="justify-content-between flex-row">
      <div className="promo-banner-content__icon">
        <SVG src="/img/svg/attractive-promotion/PresentIcon.svg" className="promo-banner-content__icon-svg" />
      </div>
      <p className="promo-banner-content__text">{promotionText}</p>
      <div className="promo-banner-content__icon">
        <SVG src="/img/svg/attractive-promotion/PresentIcon.svg" className="promo-banner-content__icon-svg" />
      </div>
    </PromoBannerContainer>
  );
}
