import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { EditButton } from '@/app/components/Button/EditButton';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { formatPrice, singleLessonPriceString } from '@/app/utils/money';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getProfileCoursePackagesShowData } from '../service';
import { ProfileCourseShowPageBase } from './ProfileCourseShowPageBase';

export function CoursePackageShowPage() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const profileCourse = useAppSelector((state) => state.profileCourse);

  useEffect(function componentDidMount() {
    if (profileCourse.loading === LoadingState.Idle) {
      dispatch(getProfileCoursePackagesShowData(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isIndividualLessonEnabled = useMemo(
    () => Boolean(profileCourse.individualLesson),
    [profileCourse.individualLesson]
  );

  const isCoursePackagesEnabled = useMemo(
    () => Boolean(profileCourse.coursePackages.length > 0),
    [profileCourse.coursePackages]
  );

  return (
    <ProfileCourseShowPageBase
      title={profileCourse.courseName}
      actions={<EditButton to={`/profile/courses/${id}/edit/packages`} />}
    >
      <CardHeader>
        <CardTitle>Цени</CardTitle>
      </CardHeader>
      <CardBody>
        <Card className="course-package-list card-border">
          {isIndividualLessonEnabled && (
            <>
              <Row>
                <Col xl={3}>
                  <h2 className="fs-2 fw-normal text-gray-800 mb-5">
                    {profileCourse.individualLesson && 'Индувидуален урок'}
                  </h2>
                </Col>
                <Col xl={9}>
                  <Card variant="bordered" className="p-5 mb-5">
                    <Row>
                      <Col xl={4}>
                        <p className="mb-0 fw-bold fs-5">Крайна цена на пакета</p>
                        <label className="fs-5">{formatPrice(profileCourse.individualLesson?.taxedPrice)}</label>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {isCoursePackagesEnabled && (
            <>
              {!profileCourse.isSingleCoursePackageEnabled && <hr className="text-gray-400 mb-10" />}
              <Row className="course-package-list">
                <Col xl={3}>
                  <h2 className="fs-2 fw-normal text-gray-800 mb-5">
                    {profileCourse.isSingleCoursePackageEnabled ? 'Единичен пакет от уроци' : 'Пакет от уроци'}
                  </h2>
                </Col>
                <Col xl={9}>
                  {profileCourse.coursePackages.map((coursePackage) => (
                    <Card variant="bordered" key={coursePackage.id} className="p-5 mb-5">
                      <Row>
                        <Col xl={4}>
                          <p className="mb-0 fw-bold fs-5">Име на пакета</p>
                          <label className="fs-5">{coursePackage.name}</label>
                        </Col>
                        <Col xl={4}>
                          <p className="mb-0 fw-bold fs-5">Брой уроци в пакета</p>
                          <label className="fs-5">{coursePackage.lessonCount}</label>
                        </Col>
                        <Col xl={4}>
                          <p className="mb-0 fw-bold fs-5">Крайна цена на пакета</p>
                          <label className="fs-5">
                            {formatPrice(coursePackage.taxedPrice)}
                            <span className="text-gray-600 fs-6">
                              {' '}
                              ({singleLessonPriceString(coursePackage.taxedPrice, Number(coursePackage.lessonCount))} на
                              урок)
                            </span>
                          </label>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </Col>
              </Row>
            </>
          )}
        </Card>
      </CardBody>
    </ProfileCourseShowPageBase>
  );
}
