import { Slug } from '@/app/models/Slug';

export interface CalendarEvent {
  id?: string;
  groupId?: string;
  recurrenceTypeID: RecurrenceTypeID;
  start?: Date;
  end?: Date;
  startTime?: string;
  endTime?: string;
  startRecur?: Date;
  endRecur?: Date | null;
  daysOfWeek?: WeekDay[];
  timeSlots?: ReservedTimeSlot[] | null;
  count?: number | null;
  interval?: number | null;
  duration?: number;
  display?: 'auto' | 'block' | 'list-item' | 'background' | 'inverse-background' | 'none';
  extendedProps?: Record<string, unknown>;
  recurringTimeSlotID?: number | string;
  canCancel?: boolean | null;
  hasProposal?: number;
  proposalStudent?: Slug;
  proposalCourse?: Slug;
  proposalPriceType?: number;
  proposalPrice?: number;
  isFreeProposal?: boolean;
  isSpecialProposal?: boolean;
}

export interface ReservedTimeSlot {
  id: number;
  duration: number;
  startDate: string;
  endDate: string;
  courseName: string;
  timeSlotStatusID: TimeSlotStatusID;
  reservationStatusID: ReservationStatusID | null;
  reservationStudentID: number | null;
  isTutor: boolean;
}

export function createNotRepeatingCalendarEvent(
  id: string,
  start: Date,
  end: Date,
  duration?: number,
  timeSlots?: ReservedTimeSlot[] | null,
  extendedProps?: Record<string, unknown> | null,
  recurringTimeSlotID?: number | string,
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number,
  isFreeProposal?: boolean
): CalendarEvent {
  const calendarEvent: CalendarEvent = {
    id,
    recurrenceTypeID: RecurrenceTypeID.Never,
    start,
    end,
    duration,
    timeSlots,
    recurringTimeSlotID,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
    isFreeProposal,
  };

  if (extendedProps) {
    calendarEvent.extendedProps = extendedProps;
  } else {
    calendarEvent.extendedProps = { timeSlotStatusID: TimeSlotStatusID.Available };
  }

  return calendarEvent;
}

export function createDailyCalendarEvent(
  id: string,
  startTime: string,
  endTime: string,
  startRecur: Date,
  endRecur: Date,
  duration?: number,
  timeSlots?: ReservedTimeSlot[],
  recurringTimeSlotID?: number | string,
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number,
  isFreeProposal?: boolean
): CalendarEvent {
  return {
    id,
    recurrenceTypeID: RecurrenceTypeID.Daily,
    startTime,
    endTime,
    startRecur,
    endRecur,
    duration,
    timeSlots,
    extendedProps: { timeSlotStatusID: TimeSlotStatusID.Available },
    recurringTimeSlotID,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
    isFreeProposal,
  };
}

export function createDailyIntervalCalendarEvent(
  id: string,
  startTime: string,
  endTime: string,
  startRecur: Date,
  endRecur: Date,
  count: number | null,
  interval: number | null,
  duration?: number,
  timeSlots?: ReservedTimeSlot[],
  recurringTimeSlotID?: number | string,
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number,
  isFreeProposal?: boolean
): CalendarEvent {
  return {
    id,
    recurrenceTypeID: RecurrenceTypeID.DailyInterval,
    startTime,
    endTime,
    startRecur,
    endRecur,
    count,
    interval,
    duration,
    timeSlots,
    extendedProps: { timeSlotStatusID: TimeSlotStatusID.Available },
    recurringTimeSlotID,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
    isFreeProposal,
  };
}

export function createWeeklyWithDailyIntervalCalendarEvent(
  id: string,
  startTime: string,
  endTime: string,
  startRecur: Date,
  endRecur: Date,
  daysOfWeek: WeekDay[],
  count: number | null,
  interval: number | null,
  duration: number,
  timeSlots?: ReservedTimeSlot[],
  recurringTimeSlotID?: number | string,
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number,
  isFreeProposal?: boolean
): CalendarEvent {
  return {
    id,
    recurrenceTypeID: RecurrenceTypeID.WeeklyInterval,
    startTime,
    endTime,
    startRecur,
    endRecur,
    daysOfWeek,
    count,
    interval,
    duration,
    timeSlots,
    extendedProps: { timeSlotStatusID: TimeSlotStatusID.Available },
    recurringTimeSlotID,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
    isFreeProposal,
  };
}

export function createWeeklyCalendarEvent(
  id: string,
  startTime: string,
  endTime: string,
  startRecur: Date,
  endRecur: Date,
  daysOfWeek: WeekDay[],
  duration?: number,
  timeSlots?: ReservedTimeSlot[],
  recurringTimeSlotID?: number | string,
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number,
  isFreeProposal?: boolean
): CalendarEvent {
  return {
    id,
    recurrenceTypeID: RecurrenceTypeID.Weekly,
    startTime,
    endTime,
    startRecur,
    endRecur,
    daysOfWeek,
    duration,
    timeSlots,
    extendedProps: { timeSlotStatusID: TimeSlotStatusID.Available },
    recurringTimeSlotID,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
    isFreeProposal,
  };
}

export interface CalendarEventFormValues extends CalendarEvent {
  startDateTime: Date;
  startHour: Date;
  recurrenceTypes: RecurrenceTypeID[];
  isStudentProposal?: boolean;
  recurringStartRecur?: Date;
  hasInitialProposal?: number;
}

export interface TimeSlotEntity {
  id: number | string;
  startDateTime: string;
  endDateTime: string;
  timeSlotStatusID: TimeSlotStatusID;
  reservationID: number | null;
  reservationStatusID: ReservationStatusID | null;
  reservationStudentID: number | null;
  reservationCourseID: number | null;
  reservationCourseName: string | null;
  tutorIdentityID: string | null;
  tutorName: string | null;
  isTutor?: boolean;
}

export interface EditableTimeSlotEntity {
  id: number | string;
  startDateTime: string;
  endDateTime: string;
  timeSlotStatusID: TimeSlotStatusID;
  reservationStatusID: ReservationStatusID | null;
  reservationStudentID: number | null;
  reservationStudentName: string | null;
  reservationCourseID: number | null;
  reservationCourseName: string | null;
  isTutor: boolean;
  recurringTimeSlotID: number | string;
  recurrenceTypeID: RecurrenceTypeID;
  startTime: string | null;
  endTime: string | null;
  startRecur: string | null;
  endRecur: string | null;
  daysOfWeek: WeekDay[] | null;
  count: number | null;
  interval: number | null;
  duration: number | null;
  hasProposal: boolean;
  proposalPrice: number | null;
  isFreeProposal: boolean;
}

export function createTimeSlotEntity(
  id: number | string,
  startDateTime: string,
  endDateTime: string,
  timeSlotStatusID: TimeSlotStatusID = TimeSlotStatusID.Available,
  reservationStatusID: ReservationStatusID | null = null,
  reservationID: number | null = null,
  reservationStudentID: number | null = null,
  reservationCourseID: number | null = null,
  reservationCourseName: string | null = null,
  tutorIdentityID: string | null = null,
  tutorName: string | null = null
): TimeSlotEntity {
  return {
    id,
    startDateTime,
    endDateTime,
    timeSlotStatusID,
    reservationID,
    reservationStatusID,
    reservationStudentID,
    reservationCourseID,
    reservationCourseName,
    tutorIdentityID,
    tutorName,
  };
}

export enum RecurrenceTypeID {
  Never = 1,
  Daily = 2,
  Weekly = 3,
  DailyInterval = 4,
  WeeklyInterval = 5,
}

export enum WeekDay {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 0,
}

export enum TimeSlotStatusID {
  Available = 1,
  Unavailable = 2,
}

export enum ReservationStatusID {
  Failed = 1,
  WaitingApproval = 2,
  Approved = 3,
  Rejected = 4,
  Unapproved = 5,
  Canceled = 6,
  Proposed = 7,
  ProposedFree = 8,
}

export enum TimeSlotProposalPriceTypeID {
  Standard = 1,
  Special = 2,
  Free = 3,
}
