import { ReactNode } from 'react';

import { TimelineAttachment } from '@/app/models/Timeline';

import { AttachmentPreviewContainer } from '../AttachmentPreview/AttachmentPreviewContainer';
import { AttachmentPreviewItem } from '../AttachmentPreview/AttachmentPreviewItem';

import './TimelineContent.scss';

type TimelineContentProps = {
  title: string;
  datetime: string;
  children: ReactNode;
  attachments?: TimelineAttachment[];
};

export function TimelineContent({ title, datetime, children, attachments = [] }: TimelineContentProps) {
  return (
    <div className="timeline-content">
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-3">
        <span className="fw-bold">{title}</span>
        <span className="text-muted ml-2">{datetime}</span>
      </div>
      {children}
      {attachments?.length > 0 && (
        <ul className="list-unstyled mb-0 mt-2">
          <AttachmentPreviewContainer>
            {attachments.map((attachment, idx) => {
              return <AttachmentPreviewItem key={idx} attachment={attachment} />;
            })}
          </AttachmentPreviewContainer>
        </ul>
      )}
    </div>
  );
}
