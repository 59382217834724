import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { wrappedReactLazy } from '@/app/utils/wrapped-react-lazy';

import { BecomeTutorPage } from './BecomeTutorPage';
import TutorProfileEditPage from './profile/TutorProfileEditPage';
import { TutorRejectedPage } from './TutorRejectedPage';
import { TutorWaitingApprovalPage } from './waiting-approval/TutorWaitingApprovalPage';

const TutorProfileCreatePage = wrappedReactLazy(() => import('./profile/TutorProfileCreatePage'));

export function TutorPage() {
  return (
    <Switch>
      <Route path="/tutor/profile/create" exact>
        <TutorProfileCreatePage />
      </Route>
      <Route path="/tutor/profile/edit" exact>
        <TutorProfileEditPage />
      </Route>
      <Route path="/tutor/become" exact>
        <BecomeTutorPage />
      </Route>
      <Route path="/tutor/waiting-approval" exact>
        <TutorWaitingApprovalPage />
      </Route>
      <Route path="/tutor/rejected" exact>
        <TutorRejectedPage />
      </Route>

      <Route component={NoMatch} />
    </Switch>
  );
}
