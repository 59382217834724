import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormikContextType, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { PriceControl } from '@/app/components/Form/Price/PriceControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleFrontEndValidations } from '@/app/components/Form/utils';
import { PlatformTaxFormText } from '@/app/modules/course/components/PlatformTaxFormText';

import { IndividualLessonFormValues } from '../../models';

type IndividualLessonModalConfig = {
  taxedPrice: string;
  onSubmit: (
    values: IndividualLessonFormValues,
    formikHelpers: FormikHelpers<IndividualLessonFormValues>
  ) => void | Promise<unknown>;
};

type IndividualLessonModalInstance = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  formik: FormikContextType<IndividualLessonFormValues>;
};

export function useIndividualLessonEditModal({
  taxedPrice,
  onSubmit,
}: IndividualLessonModalConfig): IndividualLessonModalInstance {
  const [isShown, setIsShown] = useState(false);

  const initialValues = useMemo<IndividualLessonFormValues>(
    () => ({
      taxedPrice: taxedPrice,
    }),
    [taxedPrice]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        taxedPrice: yup.number().required(),
      }),
    []
  );

  const formik = useForm<IndividualLessonFormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit(values, formikHelpers);
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm();
      setIsShown(false);
    },
  });

  return { isShown, setIsShown, formik };
}

type IndividualLessonEditModalProps = {
  instance: IndividualLessonModalInstance;
};

export function IndividualLessonEditModal({
  instance: { isShown, setIsShown, formik },
}: IndividualLessonEditModalProps) {
  const handleClose = useCallback(() => {
    setIsShown(false);
  }, [setIsShown]);

  return (
    <Modal show={isShown} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Промени цена за индивидуален урок</Modal.Title>
      </Modal.Header>

      <Form formik={formik} disabled={formik.isSubmitting} onSubmit={handleFrontEndValidations(formik)} skipDirtyPrompt>
        <Modal.Body>
          <h4>Какво се случва с текущите уроци?</h4>
          <p>Текущо закупените уроци на тази цена, ще продължат да съществуват до извършването им.</p>

          <h4>Какво се случва с новите уроци?</h4>
          <p>Всеки един нов закупен урок ще бъде с новата стойност.</p>

          <FormGroup controlId="taxedPrice">
            <Label isRequired>Крайна цена за урок</Label>
            <PriceControl name="taxedPrice" hasSolidBackground={false} />
            <PlatformTaxFormText taxedPrice={formik.values.taxedPrice} />
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Откажи
          </Button>
          <Button variant="primary" type="submit">
            Запази промените
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
