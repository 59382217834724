export const OPEN_LESSON_BUFFER_MINUTES = 15;

export const CLOSE_LESSON_BUFFER_MINUTES = 30;

export const MyScheduleFilterInitialValues = {
  course: {
    text: '',
    id: null,
  },
  reservationStatusID: [],
};

export const PROPOSAL_APPROVAL_NO_AVAILABLE_LESSONS_CONFIRM_MESSAGE =
  'Нямате налични уроци за това обучение. Искате ли да закупите?';
export const PROPOSAL_APPROVAL_SPECIAL_PACKAGE_PURCHASE_CONFIRM_MESSAGE =
  'Необходимо е да направите плащане. Искате ли да закупите урок на извънредна цена?';
