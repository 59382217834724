import { FormEvent, MouseEvent, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import $ from 'jquery';
import { BaseRange, Editor, Location, Node, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { EditLinkForm } from '@/app/components/RichTextControl/EditLinkForm';
import {
  changeLinkNode,
  getCurrentLink,
  getTextLength,
  insertLink,
  isURL,
} from '@/app/components/RichTextControl/utils';

import { DEFAULT_URL_PROTOCOL, MAIL_TO_PREFIX, PROTOCOL_REGEX } from '../constants';
import { RichTextButton } from '../RichTextButton/RichTextButton';

type InsertLinkButtonProps = {
  isDisabled?: boolean;
};

export function InsertLinkButton({ isDisabled = false }: InsertLinkButtonProps) {
  const editor = useSlate() as ReactEditor;
  const target: MutableRefObject<HTMLElement | null> = useRef<HTMLElement>(null);
  const [show, setShow] = useState(false);
  const [originalSelection, setOriginalSelection] = useState<BaseRange | null>(null);
  const [defaultText, setDefaultText] = useState<string | null>(null);

  function handleHide() {
    setShow(false);
  }

  function handleClick(event: MouseEvent) {
    event.preventDefault();

    if (editor.selection) {
      const isEditorEmpty = getTextLength(editor.children) === 0;
      //If no text, position the edit link form below the root node,to prevent random position at the bottom of the page
      const targetNodePath = isEditorEmpty ? [] : editor.selection.focus.path;

      target.current = ReactEditor.toDOMNode(editor, Node.get(editor, targetNodePath));
      setDefaultText(Editor.string(editor, editor.selection));

      setOriginalSelection(editor.selection);
      setShow((prevState) => !prevState);
    }
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    let url = event.currentTarget.url.value;
    const text = event.currentTarget.text.value;

    if (!PROTOCOL_REGEX.test(url) && !url.startsWith(MAIL_TO_PREFIX)) {
      url = DEFAULT_URL_PROTOCOL + url;
    }

    if (!url || !text || !isURL(url)) {
      handleHide();
      return;
    }

    Transforms.select(editor, originalSelection as Location);
    insertLink(editor, url, text);
    Transforms.collapse(editor, { edge: 'end' });
    ReactEditor.focus(editor);
    handleHide();
  }

  const isActive = useMemo(() => {
    if (!editor.selection) {
      return false;
    }
    const [link] = getCurrentLink(editor);

    changeLinkNode(editor, link as unknown as Node[]);

    return Boolean(link);
  }, [editor]);

  //#region Handle popover not working in modals
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const referenceEl: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const modalEl: MutableRefObject<HTMLElement> = useRef<HTMLElement>(document.body);

  useEffect(function componentDidMount() {
    if (referenceEl.current) {
      const $target: JQuery<HTMLDivElement> = $(referenceEl.current);
      const $modal = $target?.closest?.('.modal');

      if ($modal.length > 0) {
        modalEl.current = $modal[0];
      } else {
        modalEl.current = document.body;
      }
    }

    setIsInitialLoaded(true);
  }, []);
  //#endregion Handle popover not working in modals

  return (
    <>
      <div ref={referenceEl} />
      <RichTextButton tooltip="Вмъкни връзка" isDisabled={isDisabled} isActive={isActive} onClick={handleClick}>
        <i className="fas fa-link" />
      </RichTextButton>
      {isInitialLoaded && (
        <Overlay
          show={show}
          target={target.current}
          placement="bottom"
          rootClose
          onHide={handleHide}
          container={modalEl}
        >
          <Popover id="insert-link-popover" className="rounded-2" style={{ width: '400px' }}>
            <Popover.Body>
              <EditLinkForm
                defaultUrl=""
                defaultText={defaultText ?? ''}
                handleSubmit={handleSubmit}
                handleHide={handleHide}
              />
            </Popover.Body>
          </Popover>
        </Overlay>
      )}
    </>
  );
}
