import { useCallback, useEffect, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Toastr from 'toastr';

import { PROPOSED_RESERVATION_STATUS_IDS } from '@/app/components/Calendar/constants';
import { ReservationStatusID } from '@/app/components/Calendar/models';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { InfiniteScroll } from '@/app/components/InfiniteScroll/InfiniteScroll';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { Reservation } from '@/app/modules/my-schedule/models';
import { ScheduleItem } from '@/app/modules/my-schedule/MyScheduleActions/ScheduleItem';
import { MyScheduleFilters } from '@/app/modules/my-schedule/MyScheduleFilters';
import { getFutureLessonsListView } from '@/app/modules/my-schedule/service';
import myScheduleSlice from '@/app/modules/my-schedule/store';
import { formatDateFromDateFormat, formatWeekDayFromDateFormat, isCurrentDay } from '@/app/modules/my-schedule/utils';
import { approveReservation } from '@/app/modules/reservation/service';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { MyScheduleBasePage } from './MyScheduleBasePage';

import './MyScheduleIndexPage.scss';

export function MyScheduleIndexPage() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.mySchedule.loading);
  const currentPageLessons = useAppSelector((state) => state.mySchedule.futureLessons);
  const hasMore = useAppSelector((state) => state.mySchedule.hasMore);
  const currentPage = useAppSelector((state) => state.mySchedule.currentPage);
  const [loadingState] = useLoading(loading);
  const tutorID = useAppSelector((state) => state.auth.tutorID);

  const groupedFutureLessons = useMemo(
    () => {
      const lessonGroupsByDate = currentPageLessons.reduce(
        (groups: Record<string, Reservation[]>, reservation: Reservation) => {
          const group = groups[reservation.reservationDate] || [];
          group.push(reservation);
          groups[reservation.reservationDate] = group;
          return groups;
        },
        {}
      );

      return Object.keys(lessonGroupsByDate).map((reservationDate) => {
        return {
          reservationDate,
          reservations: lessonGroupsByDate[reservationDate] as Reservation[],
        };
      });
    },
    [currentPageLessons] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const isEmpty = loadingState === LoadingState.Idle && groupedFutureLessons.length === 0 && !hasMore;

  const handleLoadMore = useCallback(() => {
    dispatch(myScheduleSlice.actions.loadMore());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleReservationApproval(reservationID: number) {
    const response = await dispatch(approveReservation({ id: reservationID }));

    if (response.payload) {
      Toastr.success('Успешно запазихте промените!');
    }
  }

  function getReservationLink(reservation: Reservation): string | null {
    if (tutorID && !reservation.reservationGroupID) {
      return null;
    }

    if (tutorID && reservation.reservationGroupID) {
      return `/reservation-groups/${reservation.reservationGroupID}/details`;
    }

    return `/tutors/${reservation.tutorTransliteratedSlug}`;
  }

  useEffect(function componentDidMount() {
    return function componentDidUnmount() {
      dispatch(myScheduleSlice.actions.resetFutureLessons());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MyScheduleBasePage
      filters={<MyScheduleFilters page={currentPage} loading={loading} onLoad={getFutureLessonsListView} />}
    >
      {groupedFutureLessons?.map((futureLesson, idx) => (
        <div className="mb-5" key={idx}>
          <div className="d-flex align-items-center">
            <h6>{formatDateFromDateFormat(futureLesson.reservationDate)}</h6>
            <span className="mb-2 ms-1 text-gray-500">
              ({isCurrentDay(futureLesson.reservationDate) ? 'Днес, ' : ''}
              {formatWeekDayFromDateFormat(futureLesson.reservationDate)})
            </span>
          </div>
          {futureLesson.reservations.map((reservation) => (
            <Card
              key={reservation.id}
              className={clsx('future-lesson-card shadow-sm overflow-hidden', {
                'border-warning': reservation.reservationStatusID === ReservationStatusID.WaitingApproval,
                'border-primary': reservation.reservationStatusID === ReservationStatusID.Approved,
                'border-danger': reservation.reservationStatusID === ReservationStatusID.Rejected,
                'border-lightblue': PROPOSED_RESERVATION_STATUS_IDS.includes(reservation.reservationStatusID),
              })}
            >
              <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
                <div className="d-flex flex-column flex-md-row mb-n5 me-n5 p-5 pb-0 pb-md-5 pe-md-0">
                  <h6 className="min-w-100px mb-5 me-5">
                    {reservation.startTime} - {reservation.endTime}
                  </h6>
                  <div className="mb-5 me-5">
                    <Link to={`/courses/${reservation.courseTransliteratedSlug}`}>
                      <h6>{reservation.courseName}</h6>
                    </Link>
                    <div className="d-flex flex-wrap flex-sm-nowrap align-items-start me-n3 mb-n3">
                      <a
                        className="btn profile-menu-link d-flex px-0 me-3"
                        href={getReservationLink(reservation) as string}
                      >
                        <div className="symbol rounded profile-image">
                          <img
                            src={reservation.participantProfilePicturePath ?? NO_AVATAR_PATH}
                            alt="Профилна снимка"
                            className="profile-image me-3"
                          />
                        </div>
                        <span className="fw-normal fs-7 future-lesson-participant-name text-start">
                          {reservation.participantName}
                          {tutorID &&
                            reservation.connectedStudentName &&
                            ` за обучаем ${reservation.connectedStudentName}`}
                          {!tutorID &&
                            reservation.connectedStudentName &&
                            ` с обучаем ${reservation.connectedStudentName}`}
                        </span>
                      </a>
                      <Link
                        className="btn btn-sm btn-light-primary d-flex align-items-center justify-content-center mt-3 me-3 w-100 w-sm-auto"
                        to={`/messenger/create?recipientIdentityID=${reservation.participantIdentityID}`}
                      >
                        <i className="far fa-comment-dots" />
                        <span>Съобщение</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <ScheduleItem reservation={reservation} handleReservationApproval={handleReservationApproval} />
              </div>
            </Card>
          ))}
        </div>
      ))}
      {isEmpty && <p className="mb-0 fw-bold text-center">Няма намерени резервации.</p>}
      {loadingState === LoadingState.Pending && <GlobalSpinner />}
      <InfiniteScroll onLoadMore={handleLoadMore} hasMore={hasMore} />
    </MyScheduleBasePage>
  );
}
