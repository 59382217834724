import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { Course } from '@/app/modules/course/models';
import { getDistinctKeywords } from '@/app/modules/course/utils';
import {
  KEYWORD_COURSE_CARD_MAX_LENGTH,
  KEYWORD_COURSE_CARD_MIN_LENGTH,
} from '@/app/modules/subject-keywords/constants';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';
import { formatPrice } from '@/app/utils/money';

type CompactCourseItemProps = {
  course: Course;
};

export function CompactCourseItem({ course }: CompactCourseItemProps) {
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const courseDetailsURL = `/courses/${course.transliteratedSlug}`;

  const keywords = getDistinctKeywords(course?.keywords);
  const visibleKeywords = useMemo(() => {
    return keywords?.filter(
      (keyword: SubjectKeyword, index: number) =>
        index < (showAllKeywords ? KEYWORD_COURSE_CARD_MAX_LENGTH : KEYWORD_COURSE_CARD_MIN_LENGTH)
    );
  }, [keywords, showAllKeywords]);

  function handleKeywordsExpandToggle(e: React.MouseEvent) {
    e.preventDefault();
    setShowAllKeywords(!showAllKeywords);
  }

  return (
    <div className="tutor-with-courses__section">
      <Link to={courseDetailsURL} className="px-5 pb-3 pt-5 d-flex flex-column flex-sm-row">
        <div className="w-100 me-2">
          <h4>{course?.name}</h4>
          <div className="d-flex flex-wrap justify-content-between align-items-end mt-auto mt-2 w-100">
            <div className="mb-2">
              <span className="badge bg-gray-200 text-dark mb-2 me-2">{course?.targetAudienceName}</span>

              {visibleKeywords.map((keyword) => {
                return (
                  <span key={keyword.id} className="badge badge-light-primary me-2 mb-2">
                    {keyword.text}
                  </span>
                );
              })}
              {keywords?.length > KEYWORD_COURSE_CARD_MIN_LENGTH && (
                <span className="badge badge-light-primary mb-2 fs-8" onClick={handleKeywordsExpandToggle}>
                  {showAllKeywords ? '-' : '+'}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="ms-auto text-center">
          <LinkButton
            to={courseDetailsURL}
            variant="primary"
            className="text-nowrap fw-normal btn-sm py-2 px-8 px-md-15"
          >
            <span className="fs-6 fw-bold">Виж повече</span>
          </LinkButton>
          <p className="m-0 mt-1 text-dark text-nowrap text-center">
            от <strong className="fs-3 text-dark">{formatPrice(course?.startingPrice)}</strong>
            <span className="text-gray-500 ms-1">(за {course?.lessonDuration} мин.)</span>
          </p>
        </div>
      </Link>
    </div>
  );
}
