import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import * as yup from 'yup';

import { AcceleratedCoursesAlert } from '@/app/components/AcceleratedCoursesAlert/AcceleratedCoursesAlert';
import { LinkButton } from '@/app/components/Button/LinkButton';
import { Form } from '@/app/components/Form/Form';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { useForm } from '@/app/components/Form/useForm';
import { LessonPromotionAlert } from '@/app/components/LessonPromotionAlert/LessonPromotionAlert';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { usePromotionDescription } from '@/app/layout/Footer/usePromotionDescription';
import { DEFAULT_SORT_BY_FILTER_VALUE, SubjectIndexFilterInitialValues } from '@/app/modules/subject/constants';
import { SubjectItem } from '@/app/modules/subject/index/SubjectItem/SubjectItem';
import { SubjectIndexFilterQueryParams, SubjectIndexFilterValues } from '@/app/modules/subject/models';
import { getSubjects } from '@/app/modules/subject/service';
import { transformSubjectIndexFormikValuesToSearchParams } from '@/app/modules/subject/utils';
import { useQueryParams } from '@/app/utils/query';
import { SMALL_BREAKPOINTS, useBreakpoint } from '@/app/utils/useBreakpoint';
import { useDebounce } from '@/app/utils/useDebounce';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { SupportTicketContactUs } from '../../support-ticket/contact-us/SupportTicketContactUs';
import { SubjectItemMobile } from './SubjectItem/SubjectItemMobile';

export function SubjectIndexPage() {
  const history = useHistory();
  const location = useLocation();
  const { currentSearchParams, queryParams } = useQueryParams<SubjectIndexFilterQueryParams>();
  const debouncedQueryParams = useDebounce<SubjectIndexFilterQueryParams>(queryParams, 600);
  const breakpoint = useBreakpoint();
  const isSmallScreen = SMALL_BREAKPOINTS.includes(breakpoint);

  //#region Handle data
  const dispatch = useAppDispatch();
  const isPreloaded = useAppSelector((state) => state.subject.isPreloaded);
  const loading = useAppSelector((state) => state.subject.loading);
  const subjects = useAppSelector((state) => state.subject.subjects);
  const targetAudiences = useAppSelector((state) => state.subject.targetAudiences);
  const [loadingState, isInitialDataLoaded, , canRefetch] = useLoading(loading, isPreloaded);
  const isEmpty = loadingState === LoadingState.Idle && subjects.length === 0;

  useEffect(() => {
    if (loading === LoadingState.Idle && canRefetch) {
      dispatch(getSubjects(debouncedQueryParams));
    }
  }, [debouncedQueryParams]); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  //#region Handle filter form
  const initialValues = useMemo<SubjectIndexFilterValues>(
    () => ({
      sortBy: queryParams.sortBy ?? DEFAULT_SORT_BY_FILTER_VALUE,
      search: queryParams.search ?? SubjectIndexFilterInitialValues.search,
    }),
    [queryParams.search, queryParams.sortBy]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<SubjectIndexFilterValues>({
    initialValues,
    validationSchema,
    onSubmit() {
      formik.setSubmitting(false);
    },
  });

  //#endregion Handle filter form

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformSubjectIndexFormikValuesToSearchParams(formik.values, queryParams);

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
      }

      if (newSearchParams === '') {
        formik.setValues(SubjectIndexFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  usePromotionDescription();

  return (
    <>
      <Helmet>
        <title>Предмети</title>
        <meta name="keywords" content="Частни уроци, Онлайн уроци, Уроци, Математика, БЕЛ, Английски език" />
        <meta
          name="description"
          content="Онлай уроци, при доверени учители. Уроци по Математига, БЕЛ, Английски език"
        />
      </Helmet>
      <Page
        className="bg-white p-0"
        renderContent={(props) => <Form {...props} formik={formik} disabled={formik.isSubmitting} skipDirtyPrompt />}
      >
        <div className="bg-gray-200">
          <LessonPromotionAlert />
          <AcceleratedCoursesAlert />
          <Content isContainerContent className="pb-0">
            <PageTop isVertical>
              <PageTitle className="mt-2 mb-5 fw-bolder fs-2x text-center">
                {isInitialDataLoaded ? 'Избери предмет' : 'Зарежда се...'}
              </PageTitle>

              <div className="d-flex flex-wrap align-items-center w-100">
                <FormGroup controlId="search" className="d-flex align-items-center mb-7 w-100">
                  <FormControl
                    name="search"
                    type="text"
                    placeholder="Търси"
                    value={formik.values.search}
                    hasSolidBackground={false}
                    className="border-0"
                  />
                </FormGroup>
              </div>
            </PageTop>
          </Content>
        </div>

        {!isSmallScreen && (
          <Content isContainerContent>
            <Row className="g-9">
              {subjects.map((subject) => (
                <Col sm={6} lg={4} xl={3} key={subject.id}>
                  <SubjectItem subject={subject} targetAudiences={targetAudiences} />
                </Col>
              ))}

              {isEmpty && <p className="mb-0 fw-bold text-center">Няма намерени предмети.</p>}
            </Row>
          </Content>
        )}

        {isSmallScreen && (
          <Content className="p-3 pb-0">
            {subjects.map((subject) => (
              <SubjectItemMobile subject={subject} key={subject.id} />
            ))}

            {isEmpty && <p className="mb-0 fw-bold text-center">Няма намерени предмети.</p>}
          </Content>
        )}

        <Content isContainerContent className="pt-0">
          <Row className="mw-100 mx-auto">
            <LinkButton
              to="/vsichki-predmeti"
              variant="light-primary"
              className={clsx('mx-auto mt-5 w-fit-content', { 'w-100': isSmallScreen })}
            >
              Виж обученията по всички предмети
            </LinkButton>
          </Row>
        </Content>

        {isEmpty && <SupportTicketContactUs />}

        {loadingState === LoadingState.Pending && <GlobalSpinner />}
      </Page>
    </>
  );
}
