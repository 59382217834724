import { ReservationStatusID } from '@/app/components/Calendar/models';
import { Reservation } from '@/app/models/Reservation';

export interface ReservationGroupDetails {
  course: {
    id: number | null;
    name: string;
    tutorID: number | null;
    tutorName: string;
    tutorProfileImagePath: string | null;
  };
  connectedStudent: {
    name: string;
    phone: string;
    email: string;
    connectedStudentLevelText: string | null;
    expectations: string;
  };
  reservations: Reservation[];
}

export interface ReservationWithTime {
  id: number;
  date: string;
  dayOfWeek: string;
  startTime: Date;
  endTime: Date;
  reservationStatusID: ReservationStatusID;
}

export function createReservationWithTime(
  id: number,
  date: string,
  dayOfWeek: string,
  startTime: Date,
  endTime: Date,
  reservationStatusID: ReservationStatusID
) {
  return { id, date, dayOfWeek, startTime, endTime, reservationStatusID };
}

export interface ReservationGroupSummaryQueryParams {
  orderID: number | null;
}

export interface ReservationGroupSummaryPayload {
  reservationGroupID: number;
  orderID: number | null;
}
