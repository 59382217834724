import { FileTypes } from '@/app/utils/constants';

import { AttachmentPreviewContent } from './AttachmentPreviewContent';
import { AttachmentPreviewMedia } from './AttachmentPreviewMedia';
import { AttachmentPreviewName } from './AttachmentPreviewName';
import { FileAttachment } from './models';

type AttachmentPreviewItemProps = {
  attachment: FileAttachment;
};

export function AttachmentPreviewItem({ attachment }: AttachmentPreviewItemProps) {
  switch (attachment.fileType) {
    case FileTypes.Image:
      return (
        <AttachmentPreviewContent url={attachment.url} title={attachment.fileName}>
          <AttachmentPreviewMedia>
            <img className="mw-75px mh-75px" src={attachment.thumbnailUrl} alt={attachment.fileName} />
          </AttachmentPreviewMedia>
          <AttachmentPreviewName text={attachment.fileName} />
        </AttachmentPreviewContent>
      );
    case FileTypes.PDF:
      return (
        <AttachmentPreviewContent url={attachment.url} title={attachment.fileName}>
          <AttachmentPreviewMedia>
            <i className="fas fa-file-pdf fa-3x text-primary" />
          </AttachmentPreviewMedia>
          <AttachmentPreviewName text={attachment.fileName} />
        </AttachmentPreviewContent>
      );
    case FileTypes.WordDocument:
      return (
        <AttachmentPreviewContent url={attachment.url} title={attachment.fileName}>
          <AttachmentPreviewMedia>
            <i className="fas fa-file-word fa-3x text-primary" />
          </AttachmentPreviewMedia>
          <AttachmentPreviewName text={attachment.fileName} />
        </AttachmentPreviewContent>
      );
    case FileTypes.ExcelDocument:
      return (
        <AttachmentPreviewContent url={attachment.url} title={attachment.fileName}>
          <AttachmentPreviewMedia>
            <i className="fas fa-file-excel fa-3x text-primary" />
          </AttachmentPreviewMedia>
          <AttachmentPreviewName text={attachment.fileName} />
        </AttachmentPreviewContent>
      );
    case FileTypes.PowerPointPresentation:
      return (
        <AttachmentPreviewContent url={attachment.url} title={attachment.fileName}>
          <AttachmentPreviewMedia>
            <i className="fas fa-file-powerpoint fa-3x text-primary" />
          </AttachmentPreviewMedia>
          <AttachmentPreviewName text={attachment.fileName} />
        </AttachmentPreviewContent>
      );
    case FileTypes.Video:
      return (
        <AttachmentPreviewContent url={attachment.url} title={attachment.fileName}>
          <AttachmentPreviewMedia>
            <i className="fas fa-file-video fa-3x text-primary" />
          </AttachmentPreviewMedia>
          <AttachmentPreviewName text={attachment.fileName} />
        </AttachmentPreviewContent>
      );
    default:
      return (
        <AttachmentPreviewContent url={attachment.url} title={attachment.fileName}>
          <AttachmentPreviewMedia>
            <i className="fas fa-file-alt fa-3x text-primary" />
          </AttachmentPreviewMedia>
          <AttachmentPreviewName text={attachment.fileName} />
        </AttachmentPreviewContent>
      );
  }
}
