import { createContext } from 'react';

import { FooterInstance } from './models';

export const FooterContext = createContext<FooterInstance>({
  isVisible: true,
  isPromotionDescriptionVisible: false,
  show: () => null,
  hide: () => null,
  showPromotionDescription: () => null,
  hidePromotionDescription: () => null,
});
