import { useMemo } from 'react';
import * as yup from 'yup';

import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { StepInstance } from '@/app/components/Stepper/models';
import { LoadingState } from '@/redux/constants';
import { RootState, useAppSelector } from '@/redux/store';

import { TutorState } from '../../models';
import { BankDetailsStepModel } from '../models';

const BANK_DETAILS_STEP_TITLE = 'Данни за изплащане на приходи';

const contentSelectorCreate = (state: RootState) => state.tutor;
const contentSelectorEdit = (state: RootState) => state.tutor.edit.bankDetails;

export function useBankDetailsInitialValues(content: TutorState | BankDetailsStepModel) {
  const initialValues = useMemo<BankDetailsStepModel>(
    () => ({
      bankAccountOwnerName: content.bankAccountOwnerName,
      bankAccountIBAN: content.bankAccountIBAN,
    }),
    [content]
  );

  return initialValues;
}

export function useBankDetailsValidationSchema() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        bankAccountOwnerName: yup.string().required(),
        bankAccountIBAN: yup.string().required().iban(),
      }),
    []
  );

  return validationSchema;
}

export function useBankDetailsCreateCreateStep(stepNumber: number): StepInstance<BankDetailsStepModel> {
  const content = useAppSelector(contentSelectorCreate);

  const initialValues = useBankDetailsInitialValues(content);

  const validationSchema = useBankDetailsValidationSchema();

  const formik = useForm<BankDetailsStepModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });

  return { formik, stepNumber };
}

export function useBankDetailsEditStep(stepNumber: number): StepInstance<BankDetailsStepModel> {
  const content = useAppSelector(contentSelectorEdit);

  const initialValues = useBankDetailsInitialValues(content);

  const validationSchema = useBankDetailsValidationSchema();

  const formik = useForm<BankDetailsStepModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });

  return { formik, stepNumber };
}

export function BankDetailsStep() {
  return (
    <>
      <CardHeader>
        <CardTitle>{BANK_DETAILS_STEP_TITLE}</CardTitle>
        <CardSubTitle>Приходите от проведени уроци ще бъдат преведени на тази сметка.</CardSubTitle>
      </CardHeader>
      <CardBody>
        <FormGroup controlId="bankAccountOwnerName">
          <Label isRequired>Име на собственика на сметката</Label>
          <FormControl type="text" name="bankAccountOwnerName" />
        </FormGroup>
        <FormGroup controlId="bankAccountIBAN">
          <Label isRequired>IBAN на сметката</Label>
          <FormControl type="text" name="bankAccountIBAN" />
        </FormGroup>
      </CardBody>
    </>
  );
}
