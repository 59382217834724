import { ReactNode } from 'react';
import clsx from 'clsx';

import { AlertVariant } from './models';

type AlertProps = {
  children: ReactNode;
  className?: string;
  variant?: AlertVariant;
};

export function Alert({ children, className, variant = 'primary' }: AlertProps) {
  return (
    <div className={clsx(className, `alert alert-${variant} border-0 d-flex align-items-center p-5`)}>{children}</div>
  );
}
