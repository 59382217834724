import { useEffect, useMemo, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import FullCalendar, { EventClickArg } from '@fullcalendar/react';
import { format } from 'date-fns';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { Calendar } from '@/app/components/Calendar/Calendar';
import { Event } from '@/app/components/Calendar/Event/Event';
import { EventTime } from '@/app/components/Calendar/Event/EventTime';
import { EventTitle } from '@/app/components/Calendar/Event/EventTitle';
import { ReservationStatusID, TimeSlotStatusID } from '@/app/components/Calendar/models';
import { eventClassNameGenerator, getIntervalFormat } from '@/app/components/Calendar/utils';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardFooter } from '@/app/components/Card/CardFooter';
import { CardSectionDescriptionText } from '@/app/components/Card/CardSectionDescriptionText';
import { CardSectionTitle } from '@/app/components/Card/CardSectionTitle';
import { Feedback } from '@/app/components/Feedback/Feedback';
import { CALENDAR_CALLBACK_DATE_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { Form } from '@/app/components/Form/Form';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { isString } from '@/app/utils/isString';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { transformTimeSlotEntityToCalendarEvent } from '../course/utils';
import { ReservationProposalValues } from './models';
import {
  createReservationProposal,
  getReservationProposalSuggestionData,
  getReservationProposalTimeSlots,
} from './service';

export function ReservationProposalSuggestionCreatePage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const fullCalendar = useRef<FullCalendar | null>(null);
  const data = useAppSelector((state) => state.reservationProposal.reservationProposalSuggestion);
  const loading = useAppSelector((state) => state.reservationProposal.loading);
  const timeSlotsLoading = useAppSelector((state) => state.reservationProposal.timeSlotsLoading);
  const studentID = useAppSelector((state) => state.auth.studentID);

  //#region Handle data
  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getReservationProposalSuggestionData(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  //#region Handle form
  const initialValues = useMemo<ReservationProposalValues>(
    () => ({
      timeSlotID: null,
      studentID: data.studentID,
      courseID: data.courseID,
      connectedStudentID: data.connectedStudentID ?? -1,
    }),
    [data.connectedStudentID, data.courseID, data.studentID]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        timeSlotID: yup.number().required(),
      }),
    []
  );

  const formik = useForm<ReservationProposalValues>({
    initialValues,
    validationSchema,
    initialStatus: loading,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<ReservationProposalValues>(async (values) => {
        return await createReservationProposal(Number(id), values);
      });

      const newReservationID = await submitWithValidation(values, formikHelpers);

      if (newReservationID) {
        formik.resetForm({ values });
        history.push(`/reservation-proposals/${newReservationID}/suggestion-summary`);
      }
    },
  });
  //#endregion Handle form

  const events = useMemo(() => {
    return data.timeSlots.map((timeSlotEntity) => {
      const timeSlotIDs = formik.values.timeSlotID ? [formik.values.timeSlotID] : [];
      return transformTimeSlotEntityToCalendarEvent(timeSlotEntity, timeSlotIDs);
    });
  }, [data.timeSlots, formik.values.timeSlotID]);

  //#region Handle TimeSlot click event for reservation selection
  function handleTimeSlotsReservationSelection(eventClickArg: EventClickArg) {
    const calendarEvent = eventClickArg.event;

    // Sanity check
    if (calendarEvent.id === null || calendarEvent.id === undefined) {
      return;
    }

    // If time slot is already reserved (its status is unavailable) => skip
    if (calendarEvent?.extendedProps?.timeSlotStatusID === TimeSlotStatusID.Unavailable) {
      return;
    }

    const timeSlotID = Number(calendarEvent.id);

    // STEP 1: Check if time slot id is already selected, so if user clicks on it again, it will become deselected.
    if (formik.values.timeSlotID === timeSlotID) {
      formik.setFieldValue('timeSlotID', null);
      return;
    }

    // STEP 2: Select the new TimeSlot ID.
    formik.setFieldValue('timeSlotID', Number(timeSlotID));
  }

  //#endregion Handle TimeSlot click event for reservation selection

  const sendButtonIsDisabled = formik.values.timeSlotID === null;

  function handleCalendarDateChange(start: Date, end: Date) {
    if (loading === LoadingState.Idle) {
      const startDate = format(start, CALENDAR_CALLBACK_DATE_FORMAT);
      const endDate = format(end, CALENDAR_CALLBACK_DATE_FORMAT);
      dispatch(getReservationProposalTimeSlots({ id: Number(id), startDate, endDate }));
    }
  }

  return (
    <>
      <Helmet>
        <title>Изпратете покана</title>
      </Helmet>
      <Page
        renderContent={(props) => (
          <Form
            {...props}
            formik={formik}
            disabled={formik.isSubmitting}
            onSubmit={handleFrontEndValidations(formik)}
          />
        )}
      >
        <Content>
          <div className="mx-auto text-center mw-1000px">
            <h1 className="text-gray-800 fs-tx">Изпратете покана</h1>

            <Card className="text-start mb-10">
              <CardBody>
                <Row>
                  <Col className="mw-md-200px pb-6 pb-md-0">
                    <h2 className="h1 fw-bold mb-0">Обучение</h2>
                  </Col>
                  <Col className="d-flex align-items-center">
                    <h3 className="fw-bold">{data.courseName}</h3>
                  </Col>
                </Row>

                <hr className="bg-gray-200 opacity-100 my-8" />

                <Row>
                  <Col className="mw-md-200px pb-6 pb-md-0">
                    <h2 className="h1 fw-bold mb-0">Обучаем</h2>
                  </Col>
                  <Col className="d-flex align-items-center">
                    <h3 className="fw-bold mb-0">{data.connectedStudentName}</h3>
                  </Col>
                </Row>

                <hr className="bg-gray-200 opacity-100 my-8" />

                <Row>
                  <Col className="mw-md-200px pb-6 pb-md-0">
                    <CardSectionTitle>Изберете час</CardSectionTitle>
                    <CardSectionDescriptionText>
                      Кликнете върху конкретен свободен час, за да го резервирате.
                    </CardSectionDescriptionText>
                  </Col>
                  <Col>
                    <Calendar
                      ref={fullCalendar}
                      events={events}
                      isEditable={false}
                      isCompactWeek
                      onEventClick={handleTimeSlotsReservationSelection}
                      eventContent={({ event }) => {
                        if (event.start === null || event.end === null) {
                          return;
                        }

                        return (
                          <Event>
                            <EventTime>{getIntervalFormat(event.start, event.end)}</EventTime>

                            {Boolean(event.extendedProps.reservationCourseName) &&
                              event.extendedProps.reservationStatusID !== ReservationStatusID.Canceled && (
                                <EventTitle>{event.extendedProps.reservationCourseName}</EventTitle>
                              )}

                            {event.extendedProps.timeSlotStatusID === TimeSlotStatusID.Available &&
                              (event.extendedProps.reservationStatusID !== ReservationStatusID.Rejected ||
                                event.extendedProps.reservationStatusID == ReservationStatusID.Canceled) && (
                                <EventTitle>Свободен час</EventTitle>
                              )}

                            {!event.extendedProps.reservationStudentID &&
                              event.extendedProps.timeSlotStatusID === TimeSlotStatusID.Unavailable && (
                                <EventTitle>Зает час</EventTitle>
                              )}
                          </Event>
                        );
                      }}
                      eventClassNames={eventClassNameGenerator({ studentID })}
                      onDateChange={handleCalendarDateChange}
                      isLoading={loading === LoadingState.Pending}
                      areTimeSlotsLoading={timeSlotsLoading === LoadingState.Pending}
                    />

                    {isString(formik.errors?.timeSlotID) && <Feedback>{formik.errors.timeSlotID}</Feedback>}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="d-flex justify-content-end gap-3">
                <Button type="submit" isLoading={formik.isSubmitting} disabled={sendButtonIsDisabled}>
                  Изпрати покана
                </Button>
              </CardFooter>
            </Card>
          </div>

          {loading === LoadingState.Pending && <GlobalSpinner />}
        </Content>
      </Page>
    </>
  );
}
