import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { SideNav } from '@/app/components/SideNav/SideNav';
import { SideNavItemLink } from '@/app/components/SideNav/SideNavItemLink';
import { SideNavItemTitle } from '@/app/components/SideNav/SideNavItemTitle';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { NoMatch } from '@/app/modules/errors/NoMatch';
import { CardIndexPage } from '@/app/modules/profile/card/CardIndexPage';
import { ConnectedStudentPage } from '@/app/modules/profile/connected-student/ConnectedStudentPage';
import { ProfileAboutPage } from '@/app/modules/profile/details/ProfileAboutPage';
import { ProfileBankDetailsPage } from '@/app/modules/profile/details/ProfileBankDetailsPage';
import { ProfileInvoiceDetailsPage } from '@/app/modules/profile/details/ProfileInvoiceDetailsPage';
import { ProfileNotificationPage } from '@/app/modules/profile/notifications/ProfileNotificationPage';
import { ProfilePaymentPage } from '@/app/modules/profile/payments/ProfilePaymentPage';
import { ProfileProfitMonthlyIncomePage } from '@/app/modules/profile/profit/ProfileProfitMonthlyIncomePage';
import { PromoCodeIndexPage } from '@/app/modules/profile/promo-codes/PromoCodeIndexPage';
import { ProfileTimelinePage } from '@/app/modules/profile/timeline/ProfileTimelinePage';
import { useAppSelector } from '@/redux/store';

import { ProfileCoursePage } from './course/ProfileCoursePage';
import { ProfileDetailsPage } from './details/ProfileDetailsPage';
import { ProfileMyStudentPage } from './my-student/ProfileMyStudentPage';
import { ProfileProfitExpectedIncomePage } from './profit/ProfileProfitExpectedIncomePage';
import { ProfileProfitIncomePage } from './profit/ProfileProfitIncomePage';
import { ProfileProfitSoldCoursesPage } from './profit/ProfileProfitSoldCoursesPage';
import { ProfileProfitWithdrawalsPage } from './profit/ProfileProfitWithdrawalsPage';

const coursesAccessTutorStatuses = [TutorStatusID.WaitingApproval, TutorStatusID.Approved];

export function ProfilePage() {
  const { tutorStatusID } = useAppSelector((state) => state.auth);
  const canAccessCourses = useMemo(
    () => tutorStatusID && coursesAccessTutorStatuses.includes(tutorStatusID),
    [tutorStatusID]
  );

  return (
    <Page>
      <Content>
        <Row className="g-9">
          <Col md={4} xl={3}>
            <SideNav>
              <SideNavItemTitle>Моят профил</SideNavItemTitle>
              <SideNavItemLink to="/profile/details">Данни за профила</SideNavItemLink>
              <SideNavItemLink to="/profile/payments">Плащания</SideNavItemLink>
              <SideNavItemLink to="/profile/promo-codes">Промокодове</SideNavItemLink>
              <SideNavItemLink to="/profile/cards">Карти</SideNavItemLink>
              <SideNavItemLink to="/profile/connected-students">Обучаеми</SideNavItemLink>
              <SideNavItemLink to="/profile/timelines">История на обученията</SideNavItemLink>
              <SideNavItemLink to="/profile/notifications">Известия</SideNavItemLink>

              {canAccessCourses && (
                <>
                  <SideNavItemTitle>Моите обучения</SideNavItemTitle>
                  <SideNavItemLink to="/profile/courses">Обучения</SideNavItemLink>
                </>
              )}
              {tutorStatusID === TutorStatusID.Approved && (
                <>
                  <SideNavItemLink to="/profile/my-students">Мои обучаеми</SideNavItemLink>
                  <SideNavItemLink to="/profile/profit">Приходи</SideNavItemLink>
                </>
              )}
            </SideNav>
          </Col>

          <Col md={8} xl={9}>
            <Switch>
              <Redirect from="/profile" to="/profile/details" exact />

              <Route path="/profile/details">
                <ProfileDetailsPage />
              </Route>

              <Route path="/profile/about">
                <ProfileAboutPage />
              </Route>

              <Route path="/profile/bank-details">
                <ProfileBankDetailsPage />
              </Route>

              <Route path="/profile/invoice-details">
                <ProfileInvoiceDetailsPage />
              </Route>

              <Route path="/profile/payments">
                <ProfilePaymentPage />
              </Route>

              <Route path="/profile/promo-codes">
                <PromoCodeIndexPage />
              </Route>

              <Route path="/profile/courses">
                <ProfileCoursePage />
              </Route>

              <Route path="/profile/cards">
                <CardIndexPage />
              </Route>

              <Route path="/profile/connected-students">
                <ConnectedStudentPage />
              </Route>

              <Route path="/profile/my-students">
                <ProfileMyStudentPage />
              </Route>

              <Route path="/profile/timelines">
                <ProfileTimelinePage />
              </Route>

              <Route path="/profile/notifications">
                <ProfileNotificationPage />
              </Route>

              <Route path="/profile/profit" exact>
                <ProfileProfitWithdrawalsPage />
              </Route>

              <Route path="/profile/profit/income" exact>
                <ProfileProfitIncomePage />
              </Route>

              <Route path="/profile/profit/expected-income" exact>
                <ProfileProfitExpectedIncomePage />
              </Route>

              <Route path="/profile/profit/sold-courses" exact>
                <ProfileProfitSoldCoursesPage />
              </Route>

              <Route path="/profile/profit/monthly-income" exact>
                <ProfileProfitMonthlyIncomePage />
              </Route>

              <Route component={NoMatch} />
            </Switch>
          </Col>
        </Row>
      </Content>
    </Page>
  );
}
