import { useEffect, useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Toastr from 'toastr';
import * as yup from 'yup';

import { CancelButton } from '@/app/components/Button/CancelButton';
import { SaveButton } from '@/app/components/Button/SaveButton';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { CourseStatusCard } from '@/app/components/CourseStatusCard';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation } from '@/app/components/Form/utils';
import { CourseStatusID } from '@/app/models/CourseStatusID';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import CourseApprovedListPermissions from '../components/CourseApprovedListPermissions';
import CourseArchivedListPermissions from '../components/CourseArchivedListPermissions';
import CourseInReviewListPermissions from '../components/CourseInReviewListPermissions';
import { ProfileCourseStatusValues } from '../models';
import { getProfileCourseStatusEditData, updateProfileCourseStatus } from '../service';
import { ProfileCourseEditPageBase } from './ProfileCourseEditPageBase';

export function CourseStatusEditPage() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  //#region Handle data
  const dispatch = useAppDispatch();
  const profileCourse = useAppSelector((state) => state.profileCourse);

  useEffect(function componentDidMount() {
    if (profileCourse.loading === LoadingState.Idle) {
      dispatch(getProfileCourseStatusEditData(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  //#region Handle form
  const initialValues = useMemo<ProfileCourseStatusValues>(
    () => ({
      courseStatusID: profileCourse.courseStatusID ?? null,
    }),
    [profileCourse.courseStatusID]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        courseStatusID: yup.number().required(),
      }),
    []
  );

  const formik = useForm<ProfileCourseStatusValues>({
    initialValues,
    validationSchema,
    initialStatus: profileCourse.loading,
    onSubmit: async (values, formikHelpers) => {
      if (profileCourse.courseStatusID === CourseStatusID.InReview) {
        return;
      }

      const submitWithValidation = handleBackEndValidation<ProfileCourseStatusValues>(async (values) => {
        return await updateProfileCourseStatus(Number(id), values);
      });

      const response = await submitWithValidation(values, formikHelpers);

      if (response) {
        Toastr.success('Успешно запазихте промените!');
        formik.resetForm({ values });
        history.push(`/profile/courses/${id}/status`);
      }
    },
  });
  //#endregion Handle form

  return (
    <ProfileCourseEditPageBase
      formik={formik}
      title={profileCourse.courseName}
      actions={
        <>
          <CancelButton to={`/profile/courses/${id}/status`} />
          <SaveButton disable={profileCourse.courseStatusID === CourseStatusID.InReview && true} />
        </>
      }
    >
      <CardHeader>
        <CardTitle>Статус на обучението</CardTitle>
      </CardHeader>
      <CardBody>
        {profileCourse.courseStatusID === CourseStatusID.InReview && (
          <CourseStatusCard header="Чака одобрение">
            <CourseInReviewListPermissions />
          </CourseStatusCard>
        )}

        {profileCourse.courseStatusID !== CourseStatusID.InReview && (
          <Row className="g-8">
            <CourseStatusCard header="Публичен" md={6} courseStatus={CourseStatusID.Approved}>
              <CourseApprovedListPermissions />
            </CourseStatusCard>
            <CourseStatusCard header="Архивиран" md={6} courseStatus={CourseStatusID.Archived}>
              <CourseArchivedListPermissions />
            </CourseStatusCard>
          </Row>
        )}
      </CardBody>
    </ProfileCourseEditPageBase>
  );
}
