import { useEffect } from 'react';

import { getUpcomingLessons } from '@/app/modules/my-schedule/service';
import myScheduleStore from '@/app/modules/my-schedule/store';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function useEnterLesson() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const upcomingLessons = useAppSelector((state) => state.mySchedule.upcomingLessons);

  useEffect(
    function fetchUpcomingLessons() {
      if (auth.isAuthenticated) {
        dispatch(getUpcomingLessons());
      }
    },
    [auth.isAuthenticated] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function lessonsToEnter() {
      dispatch(myScheduleStore.actions.updateLessonsToEnter());

      const intervalID = setInterval(() => {
        dispatch(myScheduleStore.actions.updateLessonsToEnter());
      }, 1000 * 60); //Check for new lessons that can be entered every minute

      return () => {
        clearInterval(intervalID);
      };
    },
    [upcomingLessons] // eslint-disable-line react-hooks/exhaustive-deps
  );
}
