import React, { useEffect, useRef, useState } from 'react';
import Sticky from 'react-stickynode';

type UseCollapsableFilterProps = {
  stickyStateStatusCode: Sticky.StatusCode;
  contentContainerRef: React.RefObject<HTMLDivElement>;
};

export function useCollapsableFilter({ stickyStateStatusCode, contentContainerRef }: UseCollapsableFilterProps) {
  const isFilterToggledOnScroll = useRef(true);
  const [isFilterToggled, setIsFilterToggled] = useState(false);

  function handleFilterToggle() {
    setIsFilterToggled((prev) => !prev);
    isFilterToggledOnScroll.current = false;
  }

  useEffect(
    function onCallbackChange() {
      function onTouchMove(event: TouchEvent) {
        // if sticky status ain't fixed, we don't need to close the filters
        if (stickyStateStatusCode !== Sticky.STATUS_FIXED) {
          return;
        }

        const eventTarget = event.target as Node;
        const footerElement = document.querySelector('#kt_footer');

        const insideAllowedElements =
          (contentContainerRef?.current !== null && contentContainerRef?.current.contains(eventTarget)) ||
          footerElement?.contains(event.target as Node);

        if (!insideAllowedElements) {
          return;
        }

        if (isFilterToggledOnScroll.current) {
          setIsFilterToggled(false);
        }

        isFilterToggledOnScroll.current = true;
      }

      window.addEventListener('touchmove', onTouchMove);

      return () => window.removeEventListener('touchmove', onTouchMove);
    },
    [contentContainerRef, stickyStateStatusCode]
  );

  return { isFilterToggled, handleFilterToggle };
}
