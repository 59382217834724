import { Col, FormText, Row } from 'react-bootstrap';
import clsx from 'clsx';

import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { PriceControl } from '@/app/components/Form/Price/PriceControl';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { PlatformTaxFormText } from '@/app/modules/course/components/PlatformTaxFormText';
import { COURSE_PACKAGE_LESSON_COUNT_OPTIONS } from '@/app/modules/course/components/steps/constants';
import { MINIMUM_COURSE_PACKAGE_LESSON_PRICE } from '@/app/modules/course/constants';
import { CoursePackageModel } from '@/app/modules/course/create/models';

export type SingleCoursePackageItemProps = {
  coursePackage: CoursePackageModel;
};

export function SingleCoursePackageItem({ coursePackage }: SingleCoursePackageItemProps) {
  return (
    <Col md={12}>
      <Card variant="bordered" className={clsx({ 'border-danger': false })}>
        <CardBody>
          <Row className="g-5">
            <Col xl={6}>
              <FormGroup controlId="singlePackage.lessonCount">
                <Label>Брой уроци в пакета</Label>
                <SelectControl name="singlePackage.lessonCount" options={COURSE_PACKAGE_LESSON_COUNT_OPTIONS} />
              </FormGroup>
            </Col>
            <Col xl={6}>
              <FormGroup controlId="singlePackage.taxedPrice">
                <Label>Крайна цена на пакета</Label>
                <PriceControl name="singlePackage.taxedPrice" hasSolidBackground={false} />
                <PlatformTaxFormText taxedPrice={coursePackage.taxedPrice} />
                <br />
                <FormText>Минималната цена на урок в пакет е {MINIMUM_COURSE_PACKAGE_LESSON_PRICE} лв.</FormText>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}
