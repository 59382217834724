import { useRef, useState } from 'react';
import { ListGroup, Overlay, Popover } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { HeaderButton } from '@/app/components/Button/HeaderButton';
import { useAppSelector } from '@/redux/store';

import './AppSwitcher.scss';

export function AppSwitcher() {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const apps = useAppSelector((state) => state.auth.apps);

  function handleClick() {
    setShow((prevState) => !prevState);
  }

  function handleHide() {
    setShow(false);
  }

  return (
    <>
      <HeaderButton onClick={handleClick} title="Приложения" className="ms-lg-4 order-0 order-lg-1" ref={target}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="header-button__icon">
          <path d="M3.2 3.2H0V0h3.2v3.2zM9.6 0H6.4v3.2h3.2V0zM16 0h-3.2v3.2H16V0zm0 6.4h-3.2v3.2H16V6.4zm-6.4 0H6.4v3.2h3.2V6.4zm-6.4 0H0v3.2h3.2V6.4zm0 6.4H0V16h3.2v-3.2zm6.4 0H6.4V16h3.2v-3.2zm6.4 0h-3.2V16H16v-3.2z" />
        </svg>
      </HeaderButton>
      <Overlay show={show} target={target.current} placement="bottom-start" rootClose onHide={handleHide}>
        <Popover id="app-switcher-popover" className="app-switcher-popover rounded-2 border-0">
          <Popover.Body className="p-0 b-0">
            <ListGroup horizontal={true} className="app-switcher__group b-0">
              <a
                className="list-group-item list-group-item-action text-center app-switcher__app"
                href={apps.mainWebAppUrl}
                title="Дневник"
              >
                <SVG src={toAbsoluteUrl('/img/svg/app-switcher/DiaryIcon.svg')} />
                <span className="app-switcher__app-name">Дневник</span>
              </a>
              <a
                className="list-group-item list-group-item-action text-center app-switcher__app"
                href={apps.vsWebAppUrl}
                title="Виртуално училище"
              >
                <SVG src={toAbsoluteUrl('/img/svg/app-switcher/VirtualSchoolIcon.svg')} />
                <span className="app-switcher__app-name">Виртуално училище</span>
              </a>
              <a
                className="list-group-item list-group-item-action text-center app-switcher__app"
                href={apps.portfolioWebAppUrl}
                title="Портфолио"
              >
                <SVG src={toAbsoluteUrl('/img/svg/app-switcher/PortfolioIcon.svg')} />
                <span className="app-switcher__app-name">Портфолио</span>
              </a>
              <a
                className="list-group-item list-group-item-action text-center app-switcher__app"
                href={apps.plWebAppUrl}
                title="Уроци"
              >
                <SVG src={toAbsoluteUrl('/img/svg/app-switcher/PrivateLessonsIcon.svg')} />
                <span className="app-switcher__app-name">Уроци</span>
              </a>
            </ListGroup>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}
