import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from '@/app/modules/auth/PrivateRoute';
import { ConsultationsPage } from '@/app/modules/consultation-page/ConsultationPage';
import { CoursePage } from '@/app/modules/course/CoursePage';
import { CourseIndexPage } from '@/app/modules/course/index/CourseIndexPage';
import { NoMatch } from '@/app/modules/errors/NoMatch';
import { MainHomePage } from '@/app/modules/home/MainHomePage';
import { TutorHomePage } from '@/app/modules/home/TutorHomePage';
import { MessengerPage } from '@/app/modules/messenger/MessengerPage';
import { ProfilePage } from '@/app/modules/profile/ProfilePage';
import { ReservationGroupSummaryPage } from '@/app/modules/reservation-group/ReservationGroupSummaryPage';
import { ReservationProposalPage } from '@/app/modules/reservation-proposal/ReservationProposalPage';
import { SubjectIndexPage } from '@/app/modules/subject/index/SubjectIndexPage';
import { TutorPage } from '@/app/modules/tutor/TutorPage';

import { EnrolledCourseIndexPage } from './modules/enrolled-course/EnrolledCourseIndexPage';
import { FavoriteCourseIndexPage } from './modules/favorite-course/FavoriteCourseIndexPage';
import { LessonPage } from './modules/lesson/LessonPage';
import { MySchedulePage } from './modules/my-schedule/MySchedulePage';
import { OrderPage } from './modules/order/OrderPage';
import { GroupReservationDetailsPage } from './modules/reservation-group/GroupReservationDetailsPage';
import { AdditionalTermsPage } from './modules/static-pages/AdditionalTermsPage';
import { ChildProtectionPolicyPage } from './modules/static-pages/ChildProtectionPolicyPage';
import { QualityCriteriaPage } from './modules/static-pages/QualityCriteriaPage';
import { TutorAdvicesPage } from './modules/static-pages/TutorAdvicesPage';
import { SupportTicketPage } from './modules/support-ticket/SupportTicketPage';
import { TestRoomPage } from './modules/test-room/TestRoomPage';
import { TutorIndexPage } from './modules/tutor/index/TutorIndexPage';
import { TutorShowPage } from './modules/tutor/show/TutorShowPage';
import { wrappedReactLazy } from './utils/wrapped-react-lazy';

const TutorTermsPage = wrappedReactLazy(() => import('./modules/static-pages/TutorTermsPage'));
const UserTermsPage = wrappedReactLazy(() => import('./modules/static-pages/UserTermsPage'));

export function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <MainHomePage />
      </Route>
      <Route path="/courses">
        <CoursePage />
      </Route>
      <Route path="/orders">
        <OrderPage />
      </Route>
      <Route exact path="/tutor-home">
        <TutorHomePage />
      </Route>
      <PrivateRoute path="/tutor">
        <TutorPage />
      </PrivateRoute>
      <PrivateRoute path="/profile">
        <ProfilePage />
      </PrivateRoute>
      <PrivateRoute path="/subjects/contact-us" exact>
        <SubjectIndexPage />
      </PrivateRoute>
      <Route exact path="/subjects">
        <SubjectIndexPage />
      </Route>
      <PrivateRoute path="/my-schedule">
        <MySchedulePage />
      </PrivateRoute>
      <PrivateRoute path="/reservation-proposals">
        <ReservationProposalPage />
      </PrivateRoute>
      <PrivateRoute path="/support-tickets">
        <SupportTicketPage />
      </PrivateRoute>
      <PrivateRoute path="/messenger">
        <MessengerPage />
      </PrivateRoute>
      <PrivateRoute path="/lessons">
        <LessonPage />
      </PrivateRoute>
      <Route path="/tutors" exact>
        <TutorIndexPage />
      </Route>
      <Route path="/tutors/:transliteratedSlug" exact>
        <TutorShowPage />
      </Route>
      <PrivateRoute exact path="/enrolled-courses">
        <EnrolledCourseIndexPage />
      </PrivateRoute>
      <PrivateRoute exact path="/favorite-courses">
        <FavoriteCourseIndexPage />
      </PrivateRoute>
      <Route path="/tutor-terms" exact>
        <TutorTermsPage />
      </Route>
      <Route path="/terms" exact>
        <UserTermsPage />
      </Route>
      <Route path="/child-protection-policy" exact>
        <ChildProtectionPolicyPage />
      </Route>
      <Route path="/quality-criteria" exact>
        <QualityCriteriaPage />
      </Route>
      <Route path="/additional-tutor-terms" exact>
        <AdditionalTermsPage />
      </Route>
      <Route path="/tutor-advices" exact>
        <TutorAdvicesPage />
      </Route>
      <Route path="/consultations" exact>
        <ConsultationsPage />
      </Route>
      <PrivateRoute path="/test-room" exact>
        <TestRoomPage />
      </PrivateRoute>
      <PrivateRoute path="/reservation-groups/:id/summary" exact>
        <ReservationGroupSummaryPage />
      </PrivateRoute>
      <PrivateRoute path="/reservation-groups/:id/details" exact>
        <GroupReservationDetailsPage />
      </PrivateRoute>
      <PrivateRoute path="/:subjectSlug/contact-us" exact>
        <CourseIndexPage />
      </PrivateRoute>
      <PrivateRoute path="/:subjectSlug/:targetAudienceSlug/contact-us" exact>
        <CourseIndexPage />
      </PrivateRoute>
      <Route path="/:subjectSlug/:targetAudienceSlug?" exact>
        <CourseIndexPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
