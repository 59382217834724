import {
  FREE_INDIVIDUAL_LESSON_COUPON_TITLE,
  GOOGLE_ANALYTICS_ECOMMERCE_EVENTS,
} from '@/app/utils/google-analytics/constants';

import { EcommerceEventItem } from './models';

export function tagViewItemEvent(item: EcommerceEventItem, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_ECOMMERCE_EVENTS.viewItem,
      pixelEventID: pixelEventID,
      ecommerce: {
        detail: {
          products: [
            {
              id: item.id,
              name: item.name,
              price: item.price,
              brand: item.brand,
              variant: item.variant,
              category: item.category,
            },
          ],
        },
      },
    });
  }
}

export function tagAddToCartEvent(item: EcommerceEventItem, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_ECOMMERCE_EVENTS.addToCart,
      pixelEventID: pixelEventID,
      ecommerce: {
        add: {
          products: [
            {
              id: item.id,
              name: item.name,
              price: item.price,
              brand: item.brand,
              variant: item.variant,
              quantity: item.quantity,
              category: item.category,
            },
          ],
        },
      },
    });
  }
}

export function tagBeginCheckoutEvent(item: EcommerceEventItem, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_ECOMMERCE_EVENTS.beginCheckout,
      pixelEventID: pixelEventID,
      ecommerce: {
        checkout: {
          products: [
            {
              id: item.id,
              name: item.name,
              price: item.price,
              brand: item.brand,
              variant: item.variant,
              quantity: item.quantity,
              category: item.category,
            },
          ],
        },
      },
    });
  }
}

export function tagRemoveFromCartEvent(item: EcommerceEventItem) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_ECOMMERCE_EVENTS.removeFromCart,
      ecommerce: {
        remove: {
          products: [
            {
              id: item.id,
              name: item.name,
              price: item.price,
              brand: item.brand,
              variant: item.variant,
              quantity: item.quantity,
              category: item.category,
            },
          ],
        },
      },
    });
  }
}

export function tagAddPaymentInfoEvent(item: EcommerceEventItem, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_ECOMMERCE_EVENTS.addPaymentInfo,
      pixelEventID: pixelEventID,
      ecommerce: {
        checkout: {
          products: [
            {
              id: item.id,
              name: item.name,
              price: item.price,
              brand: item.brand,
              variant: item.variant,
              quantity: item.quantity,
              category: item.category,
              discount: item.discount,
            },
          ],
        },
      },
    });
  }
}

export function tagProductImpressionEvent(items: EcommerceEventItem[], pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_ECOMMERCE_EVENTS.productImpression,
      pixelEventID: pixelEventID,
      ecommerce: {
        impressions: items,
      },
    });
  }
}

export function tagPurchaseEvent(item: EcommerceEventItem, orderID: number, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_ECOMMERCE_EVENTS.purchase,
      pixelEventID: pixelEventID,
      currency: item.currency,
      transactionId: orderID,
      transactionTotal: item.price,
      ecommerce: {
        purchase: {
          products: [
            {
              id: item.id,
              name: item.name,
              price: item.price,
              brand: item.brand,
              variant: item.variant,
              quantity: item.quantity,
              category: item.category,
              discount: item.discount,
            },
          ],
        },
      },
    });
  }
}

export function tagFreeLessonPurchase(item: EcommerceEventItem, orderID: number, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_ECOMMERCE_EVENTS.purchase,
      pixelEventID: pixelEventID,
      currency: item.currency,
      transactionId: orderID,
      transactionTotal: item.price,
      ecommerce: {
        purchase: {
          actionField: {
            coupon: FREE_INDIVIDUAL_LESSON_COUPON_TITLE,
          },
          products: [
            {
              id: item.id,
              name: item.name,
              price: item.price,
              brand: item.brand,
              variant: item.variant,
              quantity: item.quantity,
              category: item.category,
              discount: item.discount,
            },
          ],
        },
      },
    });
  }
}
