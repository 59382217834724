import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createTableData, TableDataModel } from '@/app/components/Table/models';
import { normalizeAttachment } from '@/app/modules/support-ticket/utils';
import { LoadingState } from '@/redux/constants';

import {
  SupportTicket,
  SupportTicketAttachment,
  SupportTicketAttachmentResponse,
  SupportTicketComment,
  SupportTicketShowModel,
} from './models';
import { getSupportTicket, getSupportTickets, getSupportTicketsNotifications } from './service';

interface SupportTicketState {
  error?: string | null;
  index: {
    loading: LoadingState;
    supportTickets: TableDataModel<SupportTicket[]>;
    currentPage: number;
  };
  notifications: {
    loading: LoadingState;
    supportTickets: SupportTicket[];
    unreadSupportTicketsCount: number;
    hasMore: boolean;
    currentPage: number;
  };
  show: {
    supportTicket: SupportTicketShowModel | null;
    loading: LoadingState;
  };
}

const initialState: SupportTicketState = {
  index: {
    loading: LoadingState.Idle,
    supportTickets: createTableData(),
    currentPage: 1,
  },
  notifications: {
    loading: LoadingState.Idle,
    supportTickets: [],
    unreadSupportTicketsCount: 0,
    hasMore: false,
    currentPage: 1,
  },
  show: {
    supportTicket: null,
    loading: LoadingState.Idle,
  },
};

const supportTicketSlice = createSlice({
  name: 'supportTickets',
  initialState,
  reducers: {
    loadMoreNotifications(state) {
      if (state.notifications.loading === LoadingState.Idle && state.notifications.hasMore) {
        state.notifications.currentPage++;
      }
    },
    resetNotifications(state) {
      state.notifications.currentPage = 1;
      state.notifications.supportTickets = [];
      state.notifications.unreadSupportTicketsCount = 0;
    },
    setCurrentIndexPage(state, action: PayloadAction<number>) {
      state.index.currentPage = action.payload;
    },
    storeSupportTicketComment(state, action: PayloadAction<SupportTicketComment<SupportTicketAttachmentResponse>>) {
      state.show.supportTicket?.comments.push({
        id: action.payload.id,
        isAdmin: action.payload.isAdmin,
        comment: action.payload.comment,
        createdAt: action.payload.createdAt,
        commentFrom: action.payload.commentFrom,
        attachments: action.payload.attachments.map(
          (attachment): SupportTicketAttachment => normalizeAttachment(attachment)
        ),
      });
    },
  },
  extraReducers: (builder) => {
    //#region Get support tickets notifications
    builder
      .addCase(getSupportTicketsNotifications.pending, (state) => {
        if (state.notifications.loading === LoadingState.Idle) {
          state.notifications.loading = LoadingState.Pending;
        }
      })
      .addCase(getSupportTicketsNotifications.rejected, (state, action) => {
        if (state.notifications.loading === LoadingState.Pending) {
          state.notifications.loading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getSupportTicketsNotifications.fulfilled, (state, action) => {
        if (state.notifications.loading === LoadingState.Pending) {
          state.notifications.loading = LoadingState.Idle;
          state.notifications.hasMore = action.payload.hasMore;
          state.notifications.unreadSupportTicketsCount = action.payload.unreadSupportTicketsCount ?? 0;

          // Make sure only the new notifications are added to the state.
          if (action.payload.currentPage >= state.notifications.currentPage) {
            for (const supportTicket of action.payload.supportTickets) {
              const idx = state.notifications.supportTickets.findIndex((x) => x.id === supportTicket.id);

              if (idx === -1) {
                state.notifications.supportTickets.push(supportTicket);
              }
            }
          }

          state.notifications.currentPage = action.payload.currentPage;
        }
      });
    //#endregion Get support tickets notifications

    //#region Get support tickets
    builder
      .addCase(getSupportTickets.pending, (state) => {
        if (state.index.loading === LoadingState.Idle) {
          state.index.loading = LoadingState.Pending;
        }
      })
      .addCase(getSupportTickets.rejected, (state, action) => {
        if (state.index.loading === LoadingState.Pending) {
          state.index.loading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getSupportTickets.fulfilled, (state, action) => {
        state.index.loading = LoadingState.Idle;
        state.index.currentPage = action.payload.supportTickets.currentPage;
        state.index.supportTickets = action.payload.supportTickets;
      });
    //#endregion Get support tickets

    //#region Get support ticket
    builder
      .addCase(getSupportTicket.pending, (state) => {
        if (state.show.loading === LoadingState.Idle) {
          state.show.loading = LoadingState.Pending;
        }
      })
      .addCase(getSupportTicket.rejected, (state, action) => {
        if (state.show.loading === LoadingState.Pending) {
          state.show.loading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getSupportTicket.fulfilled, (state, action) => {
        state.show.loading = LoadingState.Idle;
        state.show.supportTicket = {
          id: action.payload.supportTicket.id,
          status: action.payload.supportTicket.status,
          description: action.payload.supportTicket.description,
          createdAt: action.payload.supportTicket.createdAt,
          creator: action.payload.supportTicket.creator,
          comments: action.payload.supportTicket.comments.map(
            (comment): SupportTicketComment => ({
              id: comment.id,
              isAdmin: comment.isAdmin,
              comment: comment.comment,
              commentFrom: comment.commentFrom,
              createdAt: comment.createdAt,
              attachments: comment.attachments.map(
                (attachment): SupportTicketAttachment => normalizeAttachment(attachment)
              ),
            })
          ),
          attachments: action.payload.supportTicket.attachments.map(
            (attachment): SupportTicketAttachment => normalizeAttachment(attachment)
          ),
          isClosed: action.payload.supportTicket.isClosed,
        };
      });
    //#endregion Get support ticket
  },
});

export default supportTicketSlice;
