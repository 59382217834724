import { match, matchPath, RouteProps } from 'react-router';
import * as H from 'history';

export function isMenuActive<S>(active?: string | string[] | RouteProps | RouteProps[]) {
  function isActive<Params extends { [K in keyof Params]?: string }>(
    match: match<Params> | null,
    { pathname }: H.Location<S>
  ) {
    if (match) {
      return true;
    }

    if (active === undefined) {
      return false;
    }

    if (!Array.isArray(active)) {
      return Boolean(matchPath(pathname, active));
    }

    for (const props of active) {
      return Boolean(matchPath(pathname, props));
    }

    return false;
  }

  return isActive;
}
