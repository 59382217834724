import { useContext, useEffect } from 'react';

import { BottomNavigationContext } from './BottomNavigationContext';

export function useHiddenBottomNavigation() {
  const { show, hide } = useContext(BottomNavigationContext);

  useEffect(() => {
    hide();

    return () => show();
  }, [hide, show]);
}
