import { MouseEventHandler } from 'react';

import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { HeaderMenuContentItem } from '@/app/layout/HeaderMenu/HeaderMenuContentItem';
import { HeaderMenuContentItemBody } from '@/app/layout/HeaderMenu/HeaderMenuContentItemBody';
import { HeaderMenuContentItemIcon } from '@/app/layout/HeaderMenu/HeaderMenuContentItemIcon';
import { HeaderMenuContentItemImg } from '@/app/layout/HeaderMenu/HeaderMenuContentItemImg';
import { HeaderMenuContentItemText } from '@/app/layout/HeaderMenu/HeaderMenuContentItemText';
import { HeaderMenuContentItemTime } from '@/app/layout/HeaderMenu/HeaderMenuContentItemTime';
import { LessonFeedbackReminderNotification } from '@/app/modules/notification/LessonFeedbackReminderNotification';
import { NotificationSystemIcon } from '@/app/modules/notification/NotificationSystemIcon';

import { NotificationType } from './constants';
import { BaseNotification, Notification, TutorLessonFeedbackReminderNotification } from './models';

type NotificationItemProps = {
  notification: BaseNotification;
  onClick: MouseEventHandler;
};

export function NotificationItem({ notification, onClick }: NotificationItemProps) {
  switch (notification.type) {
    case NotificationType.CourseReservationRequest:
    case NotificationType.CourseReservationResponse:
    case NotificationType.StudentCancelFreeLesson:
    case NotificationType.CancelReservation:
    case NotificationType.LessonFeedbackSent:
    case NotificationType.ReservationProposalSent:
    case NotificationType.TutorCancelProposedReservation:
    case NotificationType.StudentCancelProposedReservation:
    case NotificationType.ReservationProposalApprovalResponse:
    case NotificationType.ExpiredPaymentTransactionReminder: {
      const notificationData = (notification as Notification).data;
      return (
        <HeaderMenuContentItem
          to={notificationData.url}
          createdAt={notification.createdAt}
          isRead={Boolean(notification.isRead)}
          onClick={onClick}
        >
          <HeaderMenuContentItemImg src={notificationData.imagePath ?? NO_AVATAR_PATH} alt="Профилна снимка" />
          <HeaderMenuContentItemBody>
            <HeaderMenuContentItemText>{notificationData.text}</HeaderMenuContentItemText>
            <HeaderMenuContentItemTime createdAt={notification.createdAt} />
          </HeaderMenuContentItemBody>
        </HeaderMenuContentItem>
      );
    }
    case NotificationType.TutorLessonBoughtCount:
    case NotificationType.TutorLowTimeSlotCount:
    case NotificationType.TutorApprovalResponse:
    case NotificationType.LessonStarts:
    case NotificationType.AutomaticPayoutSent:
    case NotificationType.BlockedTutorBalanceTransfer:
    case NotificationType.ReleasedTutorBalanceTransfer: {
      const notificationData = (notification as Notification).data;
      return (
        <HeaderMenuContentItem
          to={notificationData.url}
          createdAt={notification.createdAt}
          isRead={Boolean(notification.isRead)}
          onClick={onClick}
        >
          <HeaderMenuContentItemIcon>
            <NotificationSystemIcon />
          </HeaderMenuContentItemIcon>
          <HeaderMenuContentItemBody>
            <HeaderMenuContentItemText>{notificationData.text}</HeaderMenuContentItemText>
            <HeaderMenuContentItemTime createdAt={notification.createdAt} />
          </HeaderMenuContentItemBody>
        </HeaderMenuContentItem>
      );
    }
    case NotificationType.TutorLessonFeedbackReminder: {
      return (
        <LessonFeedbackReminderNotification
          notification={notification as TutorLessonFeedbackReminderNotification}
          onClick={onClick}
        />
      );
    }
    default: {
      return null;
    }
  }
}
