import { Node } from 'slate';

import { EditableTimeSlotEntity, RecurrenceTypeID, ReservedTimeSlot, WeekDay } from '@/app/components/Calendar/models';
import { Select2Option } from '@/app/components/SelectControl/models';
import { BaseStepperValue } from '@/app/components/Stepper/models';
import { CoursePackageStatusID } from '@/app/models/CoursePackageStatusID';
import { Slug } from '@/app/models/Slug';
import { CoursePackageLessonCount } from '@/app/modules/course/constants';

import { SubjectKeyword } from '../../subject-keywords/models';

export interface CourseDetailsStepModel {
  name: string;
  subjectID: number | null;
  keywords: string[] | SubjectKeyword[];
  targetAudience: Select2Option;
  goals: string;
  methodology: string;
  skills: string;
  content: string | null;
  description: Node[];
}

//#region Schedule step
export interface TimeSlot {
  id: string | number;
  recurrenceTypeID: RecurrenceTypeID;
  startDateTime?: string;
  endDateTime?: string;
  startTime?: string;
  endTime?: string;
  startRecur?: string;
  endRecur?: string;
  daysOfWeek?: WeekDay[];
  count?: number;
  interval?: number;
  duration?: number;
  timeSlots?: ReservedTimeSlot[];
  hasProposal?: number;
  proposalStudent?: Slug;
  proposalCourse?: Slug;
  proposalPriceType?: number;
  proposalPrice?: number;
}

export function createNotRepeatingTimeSlot(
  id: string | number,
  startDateTime?: string,
  endDateTime?: string,
  duration?: number,
  timeSlots?: ReservedTimeSlot[],
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number
): TimeSlot {
  return {
    id,
    recurrenceTypeID: Number(RecurrenceTypeID.Never),
    startDateTime,
    endDateTime,
    duration,
    timeSlots,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
  };
}

export function createDailyTimeSlot(
  id: string | number,
  startTime?: string,
  endTime?: string,
  startRecur?: string,
  endRecur?: string,
  duration?: number,
  timeSlots?: ReservedTimeSlot[],
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number
): TimeSlot {
  return {
    id,
    recurrenceTypeID: RecurrenceTypeID.Daily,
    startTime,
    endTime,
    startRecur,
    endRecur,
    duration,
    timeSlots,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
  };
}

export function createDailyIntervalTimeSlot(
  id: string | number,
  startTime: string,
  endTime?: string,
  startRecur?: string,
  endRecur?: string,
  count?: number,
  interval?: number,
  duration?: number,
  timeSlots?: ReservedTimeSlot[],
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number
): TimeSlot {
  return {
    id,
    recurrenceTypeID: RecurrenceTypeID.DailyInterval,
    startTime,
    endTime,
    startRecur,
    endRecur,
    count,
    interval,
    duration,
    timeSlots,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
  };
}

export function createWeeklyWithDailyIntervalTimeSlot(
  id: string | number,
  startTime: string,
  endTime?: string,
  startRecur?: string,
  endRecur?: string,
  daysOfWeek: WeekDay[] = [],
  count?: number,
  interval?: number,
  duration?: number,
  timeSlots?: ReservedTimeSlot[],
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number
): TimeSlot {
  return {
    id,
    recurrenceTypeID: RecurrenceTypeID.WeeklyInterval,
    startTime,
    endTime,
    startRecur,
    endRecur,
    daysOfWeek,
    count,
    interval,
    duration,
    timeSlots,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
  };
}

export function createWeeklyTimeSlot(
  id: string | number,
  startTime?: string,
  endTime?: string,
  startRecur?: string,
  endRecur?: string,
  daysOfWeek: WeekDay[] = [],
  duration?: number,
  timeSlots?: ReservedTimeSlot[],
  hasProposal?: number,
  proposalStudent?: Slug,
  proposalCourse?: Slug,
  proposalPriceType?: number,
  proposalPrice?: number
): TimeSlot {
  return {
    id,
    recurrenceTypeID: RecurrenceTypeID.Weekly,
    startTime,
    endTime,
    startRecur,
    endRecur,
    daysOfWeek,
    duration,
    timeSlots,
    hasProposal,
    proposalStudent,
    proposalCourse,
    proposalPriceType,
    proposalPrice,
  };
}

export interface ScheduleStepModel extends Record<string, unknown> {
  duration: number | string;
  reservationBufferHours: number | string;
  blockNewStudentsReservations: 0 | 1;
  canChangeCourseDuration: boolean;
  canUseLegacyCourseDuration: boolean;
  calendarEventType: CalendarEventType | null;
  becomeTutorScheduleTimeSlots?: EditableTimeSlotEntity[];
}
//#endregion Schedule step

//#region Course package step
export interface CoursePackageModel {
  id: number;
  lessonCount: CoursePackageLessonCount;
  taxedPrice: string;
  typeID: number;
  statusID: number;
  isOrdered: boolean;
  name?: string;
}

interface CoursePackageModelCreateValues {
  id?: number;
  lessonCount: CoursePackageLessonCount;
  taxedPrice?: string;
  typeID?: number;
  statusID?: number;
  isOrdered?: boolean;
  name?: string;
}

export function createCoursePackage({
  lessonCount,
  id = -1,
  taxedPrice = '',
  typeID = CoursePackageStatusID.Active,
  statusID = CoursePackageStatusID.Active,
  isOrdered = false,
}: CoursePackageModelCreateValues): CoursePackageModel {
  return { id, lessonCount, taxedPrice, typeID, statusID, isOrdered };
}

export interface CoursePackageStepModel {
  individualLesson: CoursePackageModel;
  coursePackages: CoursePackageModel[];
  coursePricingStrategyID: CoursePricingStrategyID;
  singlePackage: CoursePackageModel;
}
//#endregion Course package step

export interface CourseCreateValues extends BaseStepperValue {
  courseDetails: CourseDetailsStepModel;
  schedule: ScheduleStepModel;
  coursePackage: CoursePackageStepModel;
}

export type FirstCourseUpdateValues = CourseCreateValues;

export enum CalendarEventType {
  IndividualEvent = 'individual_event',
  ThisAndFollowingEvents = 'this_and_following_events',
  RecurringEvent = 'recurring_event',
}

export enum CoursePricingStrategyID {
  MultiplePackages = 1,
  SinglePackage = 2,
}
