export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string, isExact = true) {
  const current = getCurrentUrl(pathname);

  if (!current || !url) {
    return false;
  }

  if (!isExact && current.startsWith(url)) {
    return true;
  }

  if (isExact && current === url) {
    return true;
  }

  return false;
}
