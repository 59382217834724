import { MutableRefObject, useEffect } from 'react';
import { useLocation } from 'react-router';

interface RefValues {
  [key: string]: MutableRefObject<HTMLElement | null>;
}

export function useScrollIntoView(refs: RefValues, timeout = 1000) {
  const { hash } = useLocation();

  useEffect(() => {
    let handler: NodeJS.Timeout | number | undefined = undefined;
    for (const sectionID in refs) {
      if (`#${sectionID}` !== hash) {
        continue;
      }
      handler = setTimeout(() => {
        (refs?.[sectionID]?.current as unknown as HTMLElement)?.scrollIntoView?.({
          behavior: 'smooth',
        });
      }, timeout);
    }

    return () => {
      clearTimeout(handler as number);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
