import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

type CardBodyProps = {
  children: ReactNode;
  className?: string;
};

export function CardBody({ children, className }: CardBodyProps) {
  return <Card.Body className={className}>{children}</Card.Body>;
}
