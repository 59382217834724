import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { parse } from 'date-fns';

import { KTSVG } from '@/_metronic/helpers';
import { ReservationStatusID } from '@/app/components/Calendar/models';
import { getIntervalFormat } from '@/app/components/Calendar/utils';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { formatDateFromDateTimeFormat, formatWeekDayFromDateTimeFormat } from '@/app/modules/my-schedule/utils';
import { OrderDetailsData } from '@/app/modules/order/models';
import { OrderDetails } from '@/app/modules/order/OrderDetails';
import { ReservationProposalQueryParams } from '@/app/modules/reservation-proposal/models';
import { getReservationProposalSummaryData } from '@/app/modules/reservation-proposal/service';
import { useQueryParams } from '@/app/utils/query';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function ReservationProposalSummaryPage() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  //#region Handle data
  const loading = useAppSelector((state) => state.reservationProposal.loading);
  const { queryParams } = useQueryParams<ReservationProposalQueryParams>();
  const reservationProposalSummary = useAppSelector((state) => state.reservationProposal.reservationProposalSummary);
  const reservation = reservationProposalSummary?.reservation;
  const order = reservationProposalSummary?.order;
  const isFailed = reservation?.reservationStatusID === ReservationStatusID.Failed;
  const shouldShowOrderDetails = useMemo(() => {
    return Boolean(queryParams?.orderID);
  }, [queryParams]);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getReservationProposalSummaryData({ reservationID: Number(id), orderID: queryParams?.orderID }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  return (
    <>
      <Helmet>
        <title>Детайли за резервация</title>
      </Helmet>
      <Page>
        <Content>
          <div className="mx-auto text-center mw-800px">
            <div className="my-10">
              {!isFailed && <KTSVG path="/img/svg/icons/CheckCircle.svg" className="svg-icon-6tx" />}
              {isFailed && (
                <KTSVG path="/img/svg/icons/ExclamationCircle.svg" className="svg-icon-6tx svg-icon-danger" />
              )}
            </div>

            {shouldShowOrderDetails && (
              <OrderDetails data={order as OrderDetailsData} isSuccessful={Boolean(order?.isSuccessful)} />
            )}

            <h1 className="text-gray-800 fs-2tx mb-10">
              {!isFailed && 'Успешна резервация на час'}
              {isFailed && 'Неуспешна резервация на час'}
            </h1>

            <Card className="text-start mb-10">
              <CardBody>
                <Row>
                  <Col className="mw-md-150px pb-6 pb-md-0">
                    <h2>Обучение</h2>
                  </Col>
                  <Col xs={12} md={{ span: true }}>
                    <h3 className="fw-bold">{reservationProposalSummary?.course?.name}</h3>
                    <div className="d-flex flex-wrap align-items-center">
                      <img
                        src={reservationProposalSummary?.course?.tutorProfileImagePath ?? NO_AVATAR_PATH}
                        alt="Профилна снимка"
                        className="w-20px h-20px rounded me-2"
                      />
                      <h4 className="mb-0 fs-6 fw-normal">{reservationProposalSummary?.course?.tutorName}</h4>
                    </div>
                  </Col>
                </Row>

                <hr className="bg-gray-200 opacity-100 my-8" />

                <Row>
                  <Col className="mw-md-150px pb-6 pb-md-0">
                    <h2>За обучаемия</h2>
                  </Col>
                  <Col xs={12} md={{ span: true }}>
                    <div>
                      <span>Имена на обучаемия: </span>
                      <span className="fw-bold">{reservationProposalSummary?.connectedStudent?.name}</span>
                    </div>
                    <div>
                      <span>Клас/възраст на обучаемия: </span>
                      <span className="fw-bold">
                        {reservationProposalSummary?.connectedStudent?.connectedStudentLevelText}
                      </span>
                    </div>
                    <div>
                      <span>Телефон: </span>
                      <span className="fw-bold">{reservationProposalSummary?.connectedStudent?.phone}</span>
                    </div>
                    <div>
                      <span>Имейл адрес: </span>
                      <span className="fw-bold">{reservationProposalSummary?.connectedStudent?.email}</span>
                    </div>
                    {reservationProposalSummary?.connectedStudent?.expectations && (
                      <div>
                        <span>Очаквания и коментари: </span>
                        <span className="fw-bold">{reservationProposalSummary?.connectedStudent?.expectations}</span>
                      </div>
                    )}
                  </Col>
                </Row>

                <hr className="bg-gray-200 opacity-100 my-8" />

                <Row>
                  <Col className="mw-md-150px pb-6 pb-md-0">
                    <h2>Избрани часове</h2>
                  </Col>

                  {reservation && (
                    <Col xs={12} md={{ span: true }} className="my-n5">
                      <div className="reservation-group-item border-bottom text-danger">
                        <h3 className={clsx('fw-normal', { 'text-danger': isFailed })}>
                          {isFailed && (
                            <i className={clsx('far fa-exclamation-circle fs-3 me-2', { 'text-danger': isFailed })} />
                          )}
                          {formatDateFromDateTimeFormat(
                            reservationProposalSummary?.reservation?.startDateTime as string
                          )}
                          <span className={clsx('opacity-50', { 'text-danger': isFailed })}>
                            {' '}
                            (
                            {formatWeekDayFromDateTimeFormat(
                              reservationProposalSummary?.reservation?.startDateTime as string
                            )}
                            )
                          </span>
                        </h3>
                        <h4 className={clsx('mb-0 fw-normal', { 'text-danger': isFailed })}>
                          {getIntervalFormat(
                            parse(
                              reservationProposalSummary?.reservation?.startDateTime as string,
                              DEFAULT_DATETIME_FORMAT,
                              new Date()
                            ),
                            parse(
                              reservationProposalSummary?.reservation?.endDateTime as string,
                              DEFAULT_DATETIME_FORMAT,
                              new Date()
                            )
                          )}
                        </h4>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>

            {!isFailed && (
              <Link to="/my-schedule" className="btn btn-light-primary">
                Kъм Моят график
              </Link>
            )}

            {isFailed && (
              <Link
                to={`/courses/${reservationProposalSummary?.course?.id}/reservation`}
                className="btn btn-light-primary"
              >
                Резервирай отново
              </Link>
            )}
          </div>

          {loading === LoadingState.Pending && <GlobalSpinner />}
        </Content>
      </Page>
    </>
  );
}
