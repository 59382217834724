export const ROLES = {
  director: 'director',
  deputydirector: 'deputydirector',
  sysadmin: 'sysadmin',
  teacher: 'teacher',
  pupil: 'pupil',
  parent: 'parent',
};

export const ADMIN_ROLES = [ROLES.director, ROLES.deputydirector, ROLES.sysadmin];

export const EMAIL_NOTIFICATION_QUERY_PARAMETER = 'notificationForEmail';
