import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Node } from 'slate';

import { TimeSlotEntity } from '@/app/components/Calendar/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

import { CourseFeedbacksPaginatedData, Subject } from '../course/models';
import {
  CourseDetails,
  CourseItemSubject,
  TutorAttachment,
  TutorIndexData,
  TutorIndexFilterQueryParams,
} from './models';
import { TutorProfileCreateValues, TutorProfileEditValues, TutorProfileUpdateValues } from './profile/models';

const ENDPOINT_PREFIX = '/ajax/tutor';

export const ENDPOINTS = {
  profile: {
    resource: `${ENDPOINT_PREFIX}/profile`,
    create: `${ENDPOINT_PREFIX}/profile/create`,
    edit: (tutorID: number | null) => `${ENDPOINT_PREFIX}/profile/${tutorID}/edit`,
    update: (tutorID: number | null) => `${ENDPOINT_PREFIX}/profile/${tutorID}`,
  },
  show: (transliteratedSlug: string) => `/ajax/tutors/${transliteratedSlug}`,
  resource: `/ajax/tutors`,
  visit: (transliteratedSlug: string) => `/ajax/tutors/${transliteratedSlug}/visit`,
  timeSlots: (id: number) => `/ajax/tutors/${id}/time-slots`,
  firstCourseTransliteratedSlug: `${ENDPOINT_PREFIX}/first-course-transliterated-slug`,
  courseFeedbacks: (id: number) => `/ajax/tutors/${id}/course-feedbacks`,
};

export async function createTutorProfile(values: TutorProfileCreateValues) {
  const formData = serializeFormData(values);
  const response = await axios.post(ENDPOINTS.profile.resource, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export async function updateTutorProfile(tutorID: number | null, values: TutorProfileUpdateValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.profile.update(tutorID), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

//#region Get create tutor profile
export const getCreateTutorProfileData = createAsyncThunk('tutor/getCreateTutorProfileData', async function () {
  const response = await axios.get<GetCreateTutorProfileDataResponse>(ENDPOINTS.profile.create);
  return response.data;
});

interface GetCreateTutorProfileDataResponse {
  bankAccountOwnerName: string;
  firstName: string;
  lastName: string;
  address: string;
  birthday: string;
  postCode: string;
  phone: string;
  email: string;
  username: string;
  about: Node[];
  picture: string;
}
//#endregion Get create tutor profile

//#region Get edit tutor profile
export const getEditTutorProfileData = createAsyncThunk(
  'tutor/getEditTutorProfileData',
  async function (identityID: number) {
    const response = await axios.get<TutorProfileEditValues>(ENDPOINTS.profile.edit(identityID));
    return response.data;
  }
);
//#endregion Get edit tutor profile

//#region Get tutor show data
export const getTutorShowData = createAsyncThunk('tutor/getTutorShowData', async function (transliteratedSlug: string) {
  const response = await axios.get<GetTutorShowDataResponse>(ENDPOINTS.show(transliteratedSlug));
  return response.data;
});

interface GetTutorShowDataResponse {
  name: string;
  about: Node[];
  identityID: number;
  currentRating: string;
  ratingCount: number;
  profileFile: string;
  courses: CourseDetails[];
  educationDegreeID: string | null;
  university: string;
  hasPedagogicalDegree: boolean;
  pedagogicalDegree: string;
  teachingExperienceID: string | null;
  hasStudentPrepareExperience: boolean;
  technicalExperience: number;
  approach: string;
  motivation: string;
  importance: string;
  pupilAchievement: string;
  profileDetailsAttachments: TutorAttachment[];
  aboutDetailsAttachments: TutorAttachment[];
  subjects: CourseItemSubject[];
  videoFileUrl: string | null;
  reactionTime: string | null;
  numberStudents: number | null;
  numberReservedLessons: number | null;
  uniqueVisitsCount: number | null;
  firstAvailableTutorTimeSlotTime: string | null;
  tutorID?: number;
  hasAvailableFutureTimeSlots: boolean;
  courseFeedbacks: CourseFeedbacksPaginatedData;
}
//#endregion Get tutor show data

//#region Get all tutors
export const getAllTutors = createAsyncThunk(
  'tutor/getAllTutors',
  async function (queryParams: TutorIndexFilterQueryParams) {
    const response = await axios.get<GetAllTutorsResponse>(ENDPOINTS.resource, { params: queryParams });
    return response.data;
  }
);

interface GetAllTutorsResponse {
  tutorsTotal: number;
  tutors: TutorIndexData[];
  subjects: Subject[];
}
//#endregion Get all tutors

//#region Update tutor visitors
export async function updateTutorVisitors(transliteratedSlug: string) {
  const response = await axios.post<null>(ENDPOINTS.visit(transliteratedSlug));
  return response.data;
}
//#endregion Update tutor visitors

//#region Get tutor time slots
export type GetTutorTimeSlotsResponse = {
  timeSlots: TimeSlotEntity[];
};

type GetTutorTimeSlotsArgs = {
  id: number;
  startDate?: string;
  endDate?: string;
};

export const getTutorTimeSlots = createAsyncThunk(
  'tutor/getTutorTimeSlots',
  async function ({ id, startDate, endDate }: GetTutorTimeSlotsArgs) {
    const response = await axios.get<GetTutorTimeSlotsResponse>(ENDPOINTS.timeSlots(id), {
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  }
);
//#endregion Get tutor time slots

//#region Get first course transliterated slug
export interface GetTutorFirstCourseTransliteratedSlugResponse {
  firstCourseTransliteratedSlug: string;
}
export const getTutorFirstCourseTransliteratedSlug = createAsyncThunk(
  'tutor/getTutorFirstCourseTransliteratedSlug',
  async () => {
    const response = await axios.get<GetTutorFirstCourseTransliteratedSlugResponse>(
      ENDPOINTS.firstCourseTransliteratedSlug
    );
    return response.data;
  }
);
//#endregion Get first course transliterated slug

//#region Get tutor course feedbacks paginated index result
export interface GetTutorCourseFeedbackResponse {
  courseFeedbacks: CourseFeedbacksPaginatedData;
}

export const getTutorCourseFeedbacks = createAsyncThunk<GetTutorCourseFeedbackResponse, { id: number; page?: number }>(
  'tutor/getTutorCourseFeedbacks',
  async ({ id, page = 1 }) => {
    const response = await axios.get<GetTutorCourseFeedbackResponse>(ENDPOINTS.courseFeedbacks(id), {
      params: { page },
    });
    return response.data;
  }
);
//#endregion Get tutor feedbacks paginated index result
