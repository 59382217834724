import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { KTSVG } from '@/_metronic/helpers';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { OrderDetails } from '@/app/modules/order/OrderDetails';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getOrderDetails } from './service';

export function OrderDetailsPage() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  //#region Handle data
  const loading = useAppSelector((state) => state.order.loading);
  const data = useAppSelector((state) => state.order.details);
  const isSuccessful = data.isSuccessful;

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getOrderDetails(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //#endregion Handle data

  return (
    <Page>
      <Content>
        <div className="mx-auto text-center mw-800px">
          <div className="my-10">
            {isSuccessful && <KTSVG path="/img/svg/icons/CheckCircle.svg" className="svg-icon-6tx" />}
            {!isSuccessful && (
              <KTSVG path="/img/svg/icons/ExclamationCircle.svg" className="svg-icon-6tx svg-icon-danger" />
            )}
          </div>

          <OrderDetails data={data} isSuccessful={isSuccessful} />
          <div className="d-flex flex-column flex-sm-row justify-content-center">
            <Link to={`/courses/${data.course.courseTransliteratedSlug}`} className="btn btn-light-primary">
              Разгледайте обучението
            </Link>

            <Link to={`/courses/${data.course.id}/reservation`} className="btn btn-primary mt-6 mt-sm-0 ms-sm-6">
              Резервирайте час
            </Link>
          </div>

          {!isSuccessful && (
            <Link to={`/courses/${data.course.id}/checkout`} className="btn btn-primary mt-6">
              Опитайте отново
            </Link>
          )}
        </div>

        {loading === LoadingState.Pending && <GlobalSpinner />}
      </Content>
    </Page>
  );
}
