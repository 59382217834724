import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';

import { Button } from '@/app/components/Button/Button';
import { ModalCloseButton } from '@/app/components/Modal/ModalCloseButton';
import errorsSlice from '@/app/modules/errors/store';
import { useAppDispatch } from '@/redux/store';

import './AuthenticationRequiredModal.scss';

export function AuthenticationRequiredModal() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isShown, setIsShown] = useState(true);
  const from = encodeURIComponent(location.pathname + location.search);

  useEffect(function componentDidMount() {
    dispatch(errorsSlice.actions.setHttpGetError({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleClose() {
    setIsShown(false);
  }

  function handleExited() {
    if (history.action === 'POP') {
      return dispatch(errorsSlice.actions.setHttpGetError({ httpStatusCode: 401 }));
    }

    history.goBack();
  }

  return (
    <Modal
      show={isShown}
      onHide={handleClose}
      onExited={handleExited}
      backdropClassName="modal-backdrop--blurred"
      contentClassName="text-center"
      centered
    >
      <Modal.Header className="border-0 flex-wrap position-relative">
        <Modal.Title className="flex-grow-1 px-10 fs-2x fw-bold">Как желаете да продължите?</Modal.Title>
        <ModalCloseButton onClose={handleClose} isAbsolutePositioned />
      </Modal.Header>
      <Modal.Body className="p-md-10">
        <Row className="authentication-required-modal__row">
          <Col sm={6} className="authentication-required-modal__column">
            <div className="w-50px h-50px d-inline-flex align-items-center justify-content-center">
              <i className="fas fa-sign-in fs-3tx mb-4 text-primary" />
            </div>
            <h4 className="fs-2 fw-bold mb-5">Влезте в своя акаунт</h4>
            <Button variant="light-primary" className="w-100 mw-200px" href={`/auth?from=${from}`}>
              Вход
            </Button>
          </Col>

          <Col sm={6} className="authentication-required-modal__column">
            <div className="w-50px h-50px d-inline-flex align-items-center justify-content-center">
              <i className="fas fa-user-plus fs-3x mb-4 text-primary" />
            </div>
            <h4 className="fs-2 fw-bold mb-5">Създайте нов акаунт</h4>
            <Button variant="primary" className="w-100 mw-200px" href={`/auth/register?from=${from}`}>
              Регистрация
            </Button>
          </Col>
        </Row>

        <p className="text-muted mt-9 mb-0">Ще бъдете препратени към единната профилна част на Школо.</p>
      </Modal.Body>
    </Modal>
  );
}
