import { Link } from 'react-router-dom';
import { Widget } from '@typeform/embed-react';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { useHiddenConsultUs } from '@/_metronic/layout/components/consult-us/useHiddenConsultUs';
import { useHiddenBottomNavigation } from '@/app/layout/BottomNavigation/useHiddenBottomNavigation';
import { useHiddenFooter } from '@/app/layout/Footer/useHiddenFooter';
import { useHiddenHeader } from '@/app/layout/useHiddenHeader';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformCustomEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagConsultationCompleteEvent } from '@/app/utils/google-analytics/customEvents';

import './ConsultationPage.scss';

export function ConsultationsPage() {
  useHiddenHeader();
  useHiddenFooter();
  useHiddenConsultUs();
  useHiddenBottomNavigation();

  async function handleConsultation() {
    const conversionEventValues = await transformCustomEventToConversionEventValues(
      FACEBOOK_PIXEL_CUSTOM_EVENTS.consultationComplete
    );
    tagConsultationCompleteEvent(conversionEventValues.eventID);
    await trackAnalyticsEventConversionsAPI(conversionEventValues);
  }

  return (
    <div className="position-relative h-100">
      <div className="logo-container container-fluid px-0 d-flex align-items-stretch justify-content-between">
        <div className="flex-grow-1 flex-lg-grow-0 bg-primary">
          <div className="d-flex justify-content-between align-items-center h-100 px-5 mx-n2">
            <div className="d-flex align-items-center">
              <Link to="/" className="app-logo flex-grow-0 order-lg-0 mx-2">
                <img alt="Logo" src={toAbsoluteUrl('/img/svg/PrivateLessonsLogo.svg')} className="app-logo__img" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Widget
        className="h-100"
        id="IcaC7NAd?typeform-embed-id=9581148885378379&amp;typeform-embed=embed-widget&amp;typeform-source=&amp;typeform-medium=snippet&amp;typeform-medium-version=next&amp;embed-opacity=100"
        onSubmit={handleConsultation}
      />
    </div>
  );
}
