import { QueryOptions } from 'select2';

export function sharedAjaxDataCallback(params: QueryOptions) {
  const query = {
    search: params.term,
  };

  return query;
}
export function sharedPaginatedAjaxDataCallback(params: QueryOptions) {
  const query = {
    page: params.page || 1,
    search: params.term,
  };

  return query;
}
