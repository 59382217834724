import { createSlice } from '@reduxjs/toolkit';

import { Select2Option } from '@/app/components/SelectControl/models';
import { ProfileTimeline, TimelineEvent } from '@/app/models/Timeline';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { MyStudent } from './models';
import { getProfileMyStudents, getProfileMyStudentTimelineShowData } from './service';

interface ProfileMyStudentsState {
  loading: LoadingState;
  error?: string | null;
  myStudents: MyStudent[];
  timeline: ProfileTimeline;
  timelineEvents: TimelineEvent[];
  timelineEventTypes: Select2Option[];
  timeSlots: Select2Option[];
  hasMore: boolean;
  currentPage: number;
}

const initialState: ProfileMyStudentsState = {
  loading: LoadingState.Idle,
  error: null,
  myStudents: [],
  timeline: {
    timelineID: null,
    courseName: '',
    connectedStudentName: '',
    tutorName: '',
    tutorIdentityID: null,
    studentName: '',
    studentIdentityID: null,
  },
  timelineEvents: [],
  timelineEventTypes: [],
  timeSlots: [],
  hasMore: true,
  currentPage: 1,
};

const profileMyStudentSlice = createSlice({
  name: 'profileMyStudent',
  initialState,
  reducers: {
    loadMore(state) {
      if (state.loading === LoadingState.Idle && state.hasMore) {
        state.currentPage++;
      }
    },
    resetTimelineEvents(state) {
      state.timelineEvents = [];
      state.currentPage = 1;
      state.hasMore = true;
    },
  },
  extraReducers: (builder) => {
    //#region GET PROFILE MY STUDENT INDEX
    builder
      .addCase(getProfileMyStudents.pending, sharedPendingReducer)
      .addCase(getProfileMyStudents.rejected, sharedRejectedReducer)
      .addCase(getProfileMyStudents.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.myStudents = action.payload.myStudents;
        }
      });
    //#endregion GET PROFILE MY STUDENT INDEX

    //#region GET PROFILE MY STUDENT TIMELINE SHOW DATA
    builder
      .addCase(getProfileMyStudentTimelineShowData.pending, sharedPendingReducer)
      .addCase(getProfileMyStudentTimelineShowData.rejected, sharedRejectedReducer)
      .addCase(getProfileMyStudentTimelineShowData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.timeline = action.payload.timeline;
          if (action.payload.currentPage >= state.currentPage) {
            for (const timelineEvent of action.payload.timelineEvents) {
              const idx = state.timelineEvents.findIndex((x) => x.id === timelineEvent.id);

              if (idx === -1) {
                state.timelineEvents.push(timelineEvent);
              }
            }
          }
          state.hasMore = action.payload.hasMore;
          state.currentPage = action.payload.currentPage;
          state.timelineEventTypes = action.payload.timelineEventTypes;
          state.timeSlots = action.payload.timeSlots;
        }
      });
    //#endregion GET PROFILE MY STUDENT TIMELINE SHOW DATA
  },
});

export default profileMyStudentSlice;
