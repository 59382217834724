import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import * as H from 'history';

import './GoBackLink.scss';

type GoBackLinkProps<S> = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  className?: string;
  children?: ReactNode;
};

export function GoBackLink<S>({ to, className, children = 'Назад' }: GoBackLinkProps<S>) {
  return (
    <div className={clsx('go-back-link-container', className)}>
      <Link to={to} className="go-back-link">
        <i className="fal fa-arrow-left go-back-link__icon" /> {children}
      </Link>
    </div>
  );
}
