import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { ReservationStatusID } from '@/app/components/Calendar/models';
import { getIntervalFormat } from '@/app/components/Calendar/utils';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { ReservationWithTime } from '@/app/modules/reservation-group/models';
import { getReservationGroupDetails } from '@/app/modules/reservation-group/services';
import { transformReservationToReservationWithTime } from '@/app/modules/reservation-group/utils';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import './CourseReservationDetailsPage.scss';

export function GroupReservationDetailsPage() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  //#region Handle data
  const loading = useAppSelector((state) => state.reservationGroup.loading);
  const data = useAppSelector((state) => state.reservationGroup.details);
  const reservations = useMemo<ReservationWithTime[]>(() => {
    return data.reservations.map(transformReservationToReservationWithTime);
  }, [data.reservations]);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getReservationGroupDetails(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  return (
    <>
      <Page>
        <PageTitle className="mb-9 text-center">Резервация за урок</PageTitle>
        <Content>
          <div className="mx-auto text-center mw-800px">
            <Card className="text-start mb-10">
              <CardBody>
                <Row>
                  <Col className="mw-md-150px pb-6 pb-md-0">
                    <h2>Обучение</h2>
                  </Col>
                  <Col xs={12} md={{ span: true }}>
                    <h3 className="fw-bold">{data.course.name}</h3>
                    <div className="d-flex flex-wrap align-items-center">
                      <img
                        src={data.course.tutorProfileImagePath ?? NO_AVATAR_PATH}
                        alt="Профилна снимка"
                        className="w-20px h-20px rounded me-2"
                      />
                      <h4 className="mb-0 fs-6 fw-normal">{data.course.tutorName}</h4>
                    </div>
                  </Col>
                </Row>

                <hr className="bg-gray-200 opacity-100 my-8" />

                <Row>
                  <Col className="mw-md-150px pb-6 pb-md-0">
                    <h2>За обучаемия</h2>
                  </Col>
                  <Col xs={12} md={{ span: true }}>
                    <div>
                      <span>Имена на обучаемия: </span>
                      <span className="fw-bold">{data.connectedStudent.name}</span>
                    </div>
                    <div>
                      <span>Клас/възраст на обучаемия: </span>
                      <span className="fw-bold">{data.connectedStudent.connectedStudentLevelText}</span>
                    </div>
                    <div>
                      <span>Телефон: </span>
                      <span className="fw-bold">{data.connectedStudent.phone}</span>
                    </div>
                    <div>
                      <span>Имейл адрес: </span>
                      <span className="fw-bold">{data.connectedStudent.email}</span>
                    </div>
                    {data.connectedStudent.expectations && (
                      <div>
                        <span>Очаквания и коментари: </span>
                        <span className="fw-bold">{data.connectedStudent.expectations}</span>
                      </div>
                    )}
                  </Col>
                </Row>

                <hr className="bg-gray-200 opacity-100 my-8" />

                <Row>
                  <Col className="mw-md-150px pb-6 pb-md-0">
                    <h2>Избрани часове</h2>
                  </Col>

                  <Col xs={12} md={{ span: true }} className="my-n5">
                    {reservations.map((reservation) => {
                      const isFailed = reservation.reservationStatusID === ReservationStatusID.Failed;

                      return (
                        <div key={reservation.id} className="reservation-group-item border-bottom text-danger">
                          <h3 className={clsx('fw-normal', { 'text-danger': isFailed })}>
                            {isFailed && (
                              <i className={clsx('far fa-exclamation-circle fs-3 me-2', { 'text-danger': isFailed })} />
                            )}
                            {reservation.date}{' '}
                            <span className={clsx('opacity-50', { 'text-danger': isFailed })}>
                              ({reservation.dayOfWeek})
                            </span>
                          </h3>
                          <h4 className={clsx('mb-0 fw-normal', { 'text-danger': isFailed })}>
                            {getIntervalFormat(reservation.startTime, reservation.endTime)}
                          </h4>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Link to="/my-schedule" className="btn btn-light-primary">
              Kъм Моят график
            </Link>
          </div>

          {loading === LoadingState.Pending && <GlobalSpinner />}
        </Content>
      </Page>
    </>
  );
}
