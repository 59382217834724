// eslint-disable-next-line import/no-duplicates
import { add, addMinutes, endOfDay, format, getHours, isBefore, isEqual, parse } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { Dinero } from 'dinero.js';
import produce from 'immer';

import { INTERVAL_RECURRENCE_TYPE_IDS } from '@/app/components/Calendar/constants';
import {
  CalendarEvent,
  CalendarEventFormValues,
  createNotRepeatingCalendarEvent,
  EditableTimeSlotEntity,
  RecurrenceTypeID,
  TimeSlotEntity,
  WeekDay,
} from '@/app/components/Calendar/models';
import { calculateIntervalCalendarEventDayDuration } from '@/app/components/Calendar/utils';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  END_OF_DAY_TIME_IN_HOURS,
} from '@/app/components/Form/DatePickerControl/constants';
import { Select2Option } from '@/app/components/SelectControl/models';
import { CalendarEventType } from '@/app/modules/course/create/models';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';
import { formatHour } from '@/app/utils/formatHour';
import { EcommerceEventItem } from '@/app/utils/google-analytics/models';
import { DEFAULT_ROUNDING_MODE, parsePrice } from '@/app/utils/money';
import { stringifyQuery } from '@/app/utils/query';

import {
  DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
  DEFAULT_PAGE_VALUE,
  DEFAULT_SORT_BY_FILTER_VALUE,
  ExceptionalFormikKeys,
  PL_DEPRECATION_MESSAGE,
  SHOW_FREE_LESSONS_AT_LEAST_ONE_HOUR,
} from './constants';
import {
  CheckboxFilterValue,
  Course,
  CourseDetailsData,
  CourseIndexFilterDaysOfWeekLabel,
  CourseIndexFilterNumberOfLessons,
  CourseIndexFilterNumberOfLessonsLabel,
  CourseIndexFilterPriceRange,
  CourseIndexFilterPriceRangeLabel,
  CourseIndexFilterQueryParams,
  CourseIndexFilterTimeRange,
  CourseIndexFilterTimeRangeLabel,
  CourseIndexFilterValues,
  LabelItem,
  TargetAudience,
} from './models';

export function transformCourseIndexFormikValuesToSearchParams(
  formikValues: CourseIndexFilterValues,
  queryParams: CourseIndexFilterQueryParams
) {
  const newQueryParams: CourseIndexFilterQueryParams = produce(queryParams, (draft) => {
    if (Number(formikValues.page) !== DEFAULT_PAGE_VALUE) {
      draft.page = formikValues.page;
    } else {
      delete draft.page;
    }

    if (formikValues.sortBy !== DEFAULT_SORT_BY_FILTER_VALUE) {
      draft.sortBy = formikValues.sortBy;
    } else {
      delete draft.sortBy;
    }

    if (Number(formikValues.itemsPerPage) !== DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE) {
      draft.itemsPerPage = formikValues.itemsPerPage;
    } else {
      delete draft.itemsPerPage;
    }

    if (formikValues.priceRanges?.length > 0) {
      draft.priceRanges = formikValues.priceRanges;
    } else {
      delete draft.priceRanges;
    }

    if (formikValues.numberOfLessons?.length > 0) {
      draft.numberOfLessons = formikValues.numberOfLessons;
    } else {
      delete draft.numberOfLessons;
    }

    if (formikValues.timeRanges?.length > 0) {
      draft.timeRanges = formikValues.timeRanges;
    } else {
      delete draft.timeRanges;
    }

    if (formikValues.fromDate !== '' && formikValues.fromDate !== null) {
      draft.fromDate = formikValues.fromDate;
    } else {
      delete draft.fromDate;
    }

    if (formikValues.toDate !== '' && formikValues.toDate !== null) {
      draft.toDate = formikValues.toDate;
    } else {
      delete draft.toDate;
    }

    if (formikValues.daysOfWeek?.length > 0) {
      draft.daysOfWeek = formikValues.daysOfWeek;
    } else {
      delete draft.daysOfWeek;
    }

    if (Number(formikValues.hasFutureTimeSlots) === CheckboxFilterValue.Checked) {
      draft.hasFutureTimeSlots = CheckboxFilterValue.Checked;
    } else {
      delete draft.hasFutureTimeSlots;
    }

    if (formikValues.keywordIds?.length > 0) {
      draft.keywordIds = formikValues.keywordIds;
    } else {
      delete draft.keywordIds;
    }
  });

  return stringifyQuery(newQueryParams);
}

export function transformTimeSlotEntityToCalendarEvent(
  timeSlotEntity: TimeSlotEntity,
  timeSlotIDs: number[] = []
): CalendarEvent {
  const extendedProps: Record<string, unknown> = {
    timeSlotStatusID: timeSlotEntity.timeSlotStatusID,
    reservationID: timeSlotEntity.reservationID,
    reservationStatusID: timeSlotEntity.reservationStatusID,
    reservationStudentID: timeSlotEntity.reservationStudentID,
    reservationCourseID: timeSlotEntity.reservationCourseID,
    reservationCourseName: timeSlotEntity.reservationCourseName,
    tutorIdentityID: timeSlotEntity.tutorIdentityID,
    tutorName: timeSlotEntity.tutorName,
    isTutor: timeSlotEntity.isTutor,
  };

  if (timeSlotIDs.includes(Number(timeSlotEntity.id))) {
    extendedProps.isSelected = true;
  }

  return createNotRepeatingCalendarEvent(
    String(timeSlotEntity.id),
    parse(timeSlotEntity.startDateTime as string, DEFAULT_DATETIME_FORMAT, new Date()),
    parse(timeSlotEntity.endDateTime as string, DEFAULT_DATETIME_FORMAT, new Date()),
    undefined, // TODO: Add time slot duration to back end query
    null,
    extendedProps
  );
}

export function transformTimeSlotEntityToEditableCalendarEvent(timeSlotEntity: EditableTimeSlotEntity): CalendarEvent {
  const extendedProps: Record<string, unknown> = {
    timeSlotStatusID: timeSlotEntity.timeSlotStatusID,
    reservationStatusID: timeSlotEntity.reservationStatusID,
    reservationStudentID: timeSlotEntity.reservationStudentID,
    reservationStudentName: timeSlotEntity.reservationStudentName,
    reservationCourseID: timeSlotEntity.reservationCourseID,
    reservationCourseName: timeSlotEntity.reservationCourseName,
    isTutor: timeSlotEntity.isTutor,
    recurringTimeSlotID: timeSlotEntity.recurringTimeSlotID,
    recurrenceTypeID: timeSlotEntity.recurrenceTypeID,
    startTime: timeSlotEntity.startTime,
    endTime: timeSlotEntity.endTime,
    startRecur: timeSlotEntity.startRecur,
    endRecur: timeSlotEntity.endRecur,
    daysOfWeek: timeSlotEntity.daysOfWeek,
    count: timeSlotEntity.count,
    interval: timeSlotEntity.interval,
    duration: timeSlotEntity.duration,
    hasProposal: timeSlotEntity.hasProposal,
    proposalPrice: timeSlotEntity.proposalPrice,
    isFreeProposal: timeSlotEntity.isFreeProposal,
  };

  return createNotRepeatingCalendarEvent(
    String(timeSlotEntity.id),
    parse(timeSlotEntity.startDateTime as string, DEFAULT_DATETIME_FORMAT, new Date()),
    parse(timeSlotEntity.endDateTime as string, DEFAULT_DATETIME_FORMAT, new Date()),
    Number(timeSlotEntity.duration),
    null,
    extendedProps
  );
}

export function isRecurringChanged(initialTimeSlot: EditableTimeSlotEntity, updatedTimeSlot: CalendarEvent) {
  if (initialTimeSlot.recurrenceTypeID !== updatedTimeSlot.recurrenceTypeID) {
    return true;
  }

  if (initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.Never) {
    const startDateTime = format(updatedTimeSlot.start as Date, DEFAULT_DATETIME_FORMAT);
    if (initialTimeSlot.startDateTime !== startDateTime) {
      return true;
    }
  }

  if (
    initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.DailyInterval ||
    initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.WeeklyInterval
  ) {
    if (
      initialTimeSlot.count !== Number(updatedTimeSlot.count) ||
      initialTimeSlot.interval !== Number(updatedTimeSlot.interval)
    ) {
      return true;
    }
  }

  if (
    initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.Weekly ||
    initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.WeeklyInterval
  ) {
    if (initialTimeSlot.daysOfWeek?.length !== updatedTimeSlot.daysOfWeek?.length) {
      return true;
    }

    const daysOfWeek = initialTimeSlot.daysOfWeek?.map?.((dayOfWeek) => Number(dayOfWeek));

    for (const weekDay of updatedTimeSlot.daysOfWeek as WeekDay[]) {
      if (!daysOfWeek?.includes(Number(weekDay))) {
        return true;
      }
    }
  }

  if (
    initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.Weekly ||
    initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.WeeklyInterval
  ) {
    const initialEndRecur = parse(initialTimeSlot.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date());
    if (
      format(initialEndRecur, DEFAULT_DATE_FORMAT) !== format(updatedTimeSlot.endRecur as Date, DEFAULT_DATE_FORMAT)
    ) {
      return true;
    }
  }

  return false;
}

export function hasIntervalValidationErrors(
  values: CalendarEventFormValues,
  duration: number,
  calendarEventType: CalendarEventType,
  initialTimeSlot: EditableTimeSlotEntity
) {
  if (!INTERVAL_RECURRENCE_TYPE_IDS.includes(values.recurrenceTypeID)) {
    return false;
  }

  if (calendarEventType === CalendarEventType.IndividualEvent) {
    return false;
  }

  let count = values.count as number;
  if (calendarEventType === CalendarEventType.ThisAndFollowingEvents) {
    count = calculateDailyCountFromInitialTimeSlotStartingPoint(initialTimeSlot, duration);
  }

  // Calculate duration in minutes for the whole event for a day. Add that value to the start day time of the event to get end date time for the day.
  const minutesToAdd = calculateIntervalCalendarEventDayDuration(duration, count, values.interval as number);
  const startHour = new Date(values.startHour);
  const startDateTime = values.startDateTime as Date;

  startDateTime.setHours(startHour.getHours());
  startDateTime.setMinutes(startHour.getMinutes());

  const endDateTime = add(startDateTime, { minutes: minutesToAdd });
  const isEndDateTimeWithinCurrentDayLimits =
    isBefore(endDateTime, endOfDay(startDateTime)) && getHours(endDateTime) <= END_OF_DAY_TIME_IN_HOURS;

  return !isEndDateTimeWithinCurrentDayLimits;
}

export function calculateDailyCountFromInitialTimeSlotStartingPoint(
  initialTimeSlot: EditableTimeSlotEntity,
  duration: number
) {
  const startHour = parse(initialTimeSlot.startDateTime as string, DEFAULT_DATETIME_FORMAT, new Date());
  const startRecur = parse(initialTimeSlot.startRecur as string, DEFAULT_DATETIME_FORMAT, new Date());
  const endRecur = parse(initialTimeSlot.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date());
  const minutesToAdd = duration + Number(initialTimeSlot.interval);

  startRecur.setHours(startHour.getHours());
  startRecur.setMinutes(startHour.getMinutes());

  endRecur.setDate(startRecur.getDate());

  let count = 0;
  let currentDate = new Date(startRecur.getTime());

  while (isBefore(currentDate, endRecur || isEqual(currentDate, endRecur))) {
    count++;
    currentDate = addMinutes(currentDate, minutesToAdd);
  }

  return count;
}

export function generateTimeRanges(startHour: number, endHour: number): Select2Option[] {
  const timeRanges: Select2Option[] = [];

  while (startHour <= endHour) {
    timeRanges.push({
      id: `from_${startHour}_to_${startHour + 1}`,
      text: `${formatHour(startHour)}:00-${formatHour(startHour + 1)}:00`,
    });

    startHour++;
  }

  return timeRanges;
}

export function getEcommerceItemFromCourseDetails(
  data: CourseDetailsData,
  coursePackageID: number
): EcommerceEventItem {
  const course = data.course;
  let coursePackage = null;
  if (data.individualLesson && coursePackageID === data.individualLesson.id) {
    coursePackage = data.individualLesson;
  } else {
    coursePackage = data.coursePackages.find((x) => x.id === coursePackageID);
  }
  return {
    id: Number(course.id),
    name: `${course.subjects[0].name} ${course.targetAudienceName} ${data.tutor.name}`,
    price: Number(coursePackage?.taxedPrice),
    brand: data.tutor.name as string,
    variant: Number(coursePackage?.lessonCount),
    quantity: 1,
    category: `${course.subjects[0].name as string}/${course.targetAudienceName as string}`,
    currency: 'BGN',
  };
}

export function getEcommerceItemsFromCourseIndex(courses: Course[], subjectName: string): EcommerceEventItem[] {
  const ecommerceEventItems = [] as EcommerceEventItem[];
  for (let i = 0; i < courses.length; i++) {
    ecommerceEventItems.push({
      id: Number(courses[i].id),
      name: `${subjectName} ${courses[i].targetAudienceName} ${courses[i].tutorName}`,
      price: Number(courses[i].startingPrice),
      brand: courses[i].tutorName as string,
      variant: 1,
      quantity: 1,
      category: `${subjectName}/${courses[i].targetAudienceName as string}`,
      currency: 'BGN',
      position: i,
      list: `${subjectName} ${courses[i].targetAudienceName}`,
    });
  }

  return ecommerceEventItems;
}

export function calculateCoursePackageDiscountAmount(pricePerLesson: Dinero, individualPrice: string | undefined) {
  if (!individualPrice || pricePerLesson.greaterThanOrEqual(parsePrice(individualPrice))) {
    return null;
  }
  return pricePerLesson
    .subtract(parsePrice(individualPrice))
    .divide(Number(individualPrice), DEFAULT_ROUNDING_MODE)
    .multiply(-1)
    .getAmount();
}

export function getDistinctKeywords(keywords: SubjectKeyword[]) {
  return keywords?.filter((x, idx) => {
    return keywords?.findIndex((y) => y.id === x.id) === idx;
  });
}

export function transformCourseIndexFormikValuesToLabels(
  formikValues: CourseIndexFilterValues,
  subjectName: string | null,
  targetAudiences: TargetAudience[],
  subjectKeywords: SubjectKeyword[]
): LabelItem[] {
  const labels = [];

  if (formikValues.targetAudienceSlug) {
    // Find the target audience based on the slug.
    const targetAudience = targetAudiences.find(
      (targetAudience) => targetAudience.id.toString() === formikValues.targetAudienceSlug
    );
    if (targetAudience) {
      labels.push({ [formikValues.targetAudienceSlug]: targetAudience.text });
    }
  }

  if (formikValues.keywordIds.length > 0) {
    // Iterate over the keywordIds and find the corresponding keyword.
    for (const keywordId of formikValues.keywordIds) {
      const keyword = subjectKeywords.find((subjectKeyword) => subjectKeyword.id === Number(keywordId));
      if (keyword) {
        labels.push({ [keywordId]: keyword?.text });
      }
    }
  }

  if (formikValues.priceRanges.length > 0) {
    // Iterate over the priceRanges and map them to their corresponding labels.
    for (const priceRangeValue of formikValues.priceRanges) {
      const priceRangeKeys = Object.keys(CourseIndexFilterPriceRange);
      const priceRangeKey = priceRangeKeys.find(
        (key) => CourseIndexFilterPriceRange[key as keyof typeof CourseIndexFilterPriceRange] === priceRangeValue
      );
      if (priceRangeKey) {
        const priceRangeLabel =
          CourseIndexFilterPriceRangeLabel[priceRangeKey as keyof typeof CourseIndexFilterPriceRangeLabel];
        labels.push({ [priceRangeValue]: priceRangeLabel });
      }
    }
  }

  if (formikValues.numberOfLessons.length > 0) {
    // Iterate over the numberOfLessons and map them to their corresponding labels.
    for (const numberOfLessonValue of formikValues.numberOfLessons) {
      const numberOfLessonKeys = Object.keys(CourseIndexFilterNumberOfLessons);
      const numberOfLessonKey = numberOfLessonKeys.find(
        (key) =>
          CourseIndexFilterNumberOfLessons[key as keyof typeof CourseIndexFilterNumberOfLessons] === numberOfLessonValue
      );
      if (numberOfLessonKey) {
        const numberOfLessonLabel =
          CourseIndexFilterNumberOfLessonsLabel[
            numberOfLessonKey as keyof typeof CourseIndexFilterNumberOfLessonsLabel
          ];
        labels.push({ [numberOfLessonValue]: numberOfLessonLabel });
      }
    }
  }

  if (Number(formikValues.hasFutureTimeSlots) === CheckboxFilterValue.Checked) {
    labels.push({ hasFutureTimeSlots: SHOW_FREE_LESSONS_AT_LEAST_ONE_HOUR });
  }

  if (formikValues.fromDate !== '' && formikValues.fromDate !== null && formikValues.fromDate !== undefined) {
    labels.push({ fromDate: `от ${formikValues.fromDate}` });
  }

  if (formikValues.toDate !== '' && formikValues.toDate !== null && formikValues.toDate !== undefined) {
    labels.push({ toDate: `до ${formikValues.toDate}` });
  }

  if (formikValues.timeRanges.length > 0) {
    // Iterate over the timeRanges and map them to their corresponding labels.
    for (const timeRangeValue of formikValues.timeRanges) {
      const timeRangeKeys = Object.keys(CourseIndexFilterTimeRange);
      const timeRangeKey = timeRangeKeys.find(
        (key) => CourseIndexFilterTimeRange[key as keyof typeof CourseIndexFilterTimeRange] === timeRangeValue
      );
      if (timeRangeKey) {
        const timeRangeLabel =
          CourseIndexFilterTimeRangeLabel[timeRangeKey as keyof typeof CourseIndexFilterTimeRangeLabel];
        labels.push({ [timeRangeValue]: timeRangeLabel });
      }
    }
  }

  if (formikValues.daysOfWeek.length > 0) {
    // Iterate over the daysOfWeek and map them to their corresponding labels.
    for (const day of formikValues.daysOfWeek) {
      switch (day.toString()) {
        case WeekDay.Monday.toString():
          labels.push({ [WeekDay.Monday]: CourseIndexFilterDaysOfWeekLabel.Monday });
          break;
        case WeekDay.Tuesday.toString():
          labels.push({ [WeekDay.Tuesday]: CourseIndexFilterDaysOfWeekLabel.Tuesday });
          break;
        case WeekDay.Wednesday.toString():
          labels.push({ [WeekDay.Wednesday]: CourseIndexFilterDaysOfWeekLabel.Wednesday });
          break;
        case WeekDay.Thursday.toString():
          labels.push({ [WeekDay.Thursday]: CourseIndexFilterDaysOfWeekLabel.Thursday });
          break;
        case WeekDay.Friday.toString():
          labels.push({ [WeekDay.Friday]: CourseIndexFilterDaysOfWeekLabel.Friday });
          break;
        case WeekDay.Saturday.toString():
          labels.push({ [WeekDay.Saturday]: CourseIndexFilterDaysOfWeekLabel.Saturday });
          break;
        case WeekDay.Sunday.toString():
          labels.push({ [WeekDay.Sunday]: CourseIndexFilterDaysOfWeekLabel.Sunday });
          break;
      }
    }
  }

  return [...labels, { [ExceptionalFormikKeys.subjectName]: subjectName }];
}

export function handlePlDeprecatedPurchaseAction(e?: React.MouseEvent | React.FormEvent) {
  e?.preventDefault();
  alert(PL_DEPRECATION_MESSAGE);
  return false;
}
