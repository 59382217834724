import { useEffect, useRef, useState } from 'react';
import { Dropdown, Overlay, Tooltip } from 'react-bootstrap';
import { DropdownToggleProps as BaseDropdownToggleProps } from 'react-bootstrap/esm/DropdownToggle';
import clsx from 'clsx';

import { LoadingState } from '@/redux/constants';

import { DropdownToggleVariant } from './models';

import './DropdownToggle.scss';

type DropdownToggle = BaseDropdownToggleProps & {
  variant: DropdownToggleVariant;
  tooltipTitle?: string;
  loading?: LoadingState;
};

export function DropdownToggle({ className, variant, tooltipTitle = '', loading, ...props }: DropdownToggle) {
  const internalVariant = variant === 'white-bordered' ? 'bg-white' : variant;
  const internalBsPrefix = variant === 'white-bordered' ? 'border-gray-300' : undefined;
  const target = useRef(null);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (loading === LoadingState.Idle && tooltipTitle) {
      setIsShown(true);
    }
  }, [tooltipTitle, loading]);

  function handleHide() {
    setIsShown(false);
  }

  if (!tooltipTitle) {
    return (
      <Dropdown.Toggle
        {...props}
        bsPrefix={internalBsPrefix}
        variant={internalVariant}
        className={clsx(className, 'dropdown-toggle--custom')}
      />
    );
  }

  return (
    <>
      <Dropdown.Toggle
        {...props}
        bsPrefix={internalBsPrefix}
        variant={internalVariant}
        className={clsx(className, 'dropdown-toggle--custom')}
        ref={target}
      />
      <Overlay target={target.current} placement="top" show={isShown} rootClose onHide={handleHide}>
        <Tooltip id="date-filter-tooltip" className="tooltip-dark">
          {tooltipTitle}
        </Tooltip>
      </Overlay>
    </>
  );
}
