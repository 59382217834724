import React from 'react';
import { useHistory } from 'react-router';

import { LessonFeedbackForm } from '@/app/modules/lesson/LessonFeedbackForm';
import { useQueryParams } from '@/app/utils/query';

export function StudentLessonFeedbackCreatePage() {
  const { queryParams } = useQueryParams<{ timelineID: number }>();
  const history = useHistory();
  const previousURL = `/profile/timelines/${queryParams.timelineID}`;

  function handleLessonFeedbackSuccess() {
    history.replace(previousURL);
  }

  return <LessonFeedbackForm onSuccess={handleLessonFeedbackSuccess} previousLocation={previousURL} />;
}
