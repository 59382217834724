import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { ProfileCourseIndexPage } from '@/app/modules/profile/course/ProfileCourseIndexPage';

import { ProfileCourseEditPage } from './edit/ProfileCourseEditPage';
import { ProfileCourseShowPage } from './show/ProfileCourseShowPage';

export function ProfileCoursePage() {
  return (
    <Switch>
      <Route path="/profile/courses" exact>
        <ProfileCourseIndexPage />
      </Route>
      <Route path="/profile/courses/:id/edit">
        <ProfileCourseEditPage />
      </Route>
      <Route path="/profile/courses/:id">
        <ProfileCourseShowPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
