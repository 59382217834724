import { useContext, useEffect } from 'react';

import { ConsultUsContext } from '@/_metronic/layout/components/consult-us/ConsultUsContext';

export function GlobalSpinner() {
  const { showOnLoadedPage, hideOnLoadingPage } = useContext(ConsultUsContext);

  useEffect(
    function componentDidMount() {
      const globalSpinner = document.getElementById('global-spinner');

      globalSpinner?.classList?.remove?.('hidden');
      globalSpinner?.classList?.add?.('transparent');
      hideOnLoadingPage();

      return function componentDidUnmount() {
        globalSpinner?.classList?.add?.('hidden');
        globalSpinner?.classList?.remove?.('transparent');
        showOnLoadedPage();
      };
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return null;
}
