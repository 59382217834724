import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { parse } from 'date-fns';

import { AddEventModal, useAddEventModal } from '@/app/components/Calendar/AddEventModal';
import { DEFAULT_DURATION, DEFAULT_RESERVATION_BUFFER_HOURS } from '@/app/components/Calendar/constants';
import {
  CalendarEvent,
  CalendarEventFormValues,
  EditableTimeSlotEntity,
  RecurrenceTypeID,
  TimeSlotProposalPriceTypeID,
} from '@/app/components/Calendar/models';
import { getNearestDate, transformCalendarEventsToIntervalTree } from '@/app/components/Calendar/utils';
import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { useForm } from '@/app/components/Form/useForm';
import { handleValidation } from '@/app/components/Form/utils';
import { SectionSpinner } from '@/app/components/Spinner/SectionSpinner';
import { transformCalendarEventToTimeSlot } from '@/app/modules/course/components/steps/utils';
import { ScheduleStepModel } from '@/app/modules/course/create/models';
import { useScheduleValidationSchema } from '@/app/modules/course/forms/ScheduleForm';
import { getCourseStudentProposalData } from '@/app/modules/course/service';
import courseSlice from '@/app/modules/course/store';
import { transformTimeSlotEntityToEditableCalendarEvent } from '@/app/modules/course/utils';
import { MessengerMessageItem } from '@/app/modules/messenger/MessengerMessageItem';
import { MessengerMessage } from '@/app/modules/messenger/models';
import messengerSlice from '@/app/modules/messenger/store';
import { StoreRecurringTimeSlotValues } from '@/app/modules/schedule/models';
import { storeRecurringTimeSlot } from '@/app/modules/schedule/service';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

type MessengerListSectionProps = {
  threadID: number | null;
  handleLoadMore: () => void;
  messengerMessages: MessengerMessage[];
  virtuosoRef: React.MutableRefObject<VirtuosoHandle | null>;
  hasMore?: boolean;
};

export function MessengerListSection({
  threadID,
  messengerMessages,
  handleLoadMore,
  virtuosoRef,
  hasMore = false,
}: MessengerListSectionProps) {
  const dispatch = useAppDispatch();
  const course = useAppSelector((state) => state.course);
  const messagesStartIndex = useAppSelector((state) => state.messenger.messagesStartIndex);
  const previousMessagesLoading = useAppSelector((state) => state.messenger.previousMessagesLoading);
  const timeSlots = course.timeSlots as EditableTimeSlotEntity[];
  const [messageWithProposal, setMessageWithProposal] = useState<MessengerMessage | null>(null);
  const lastMessengerMessageID = messengerMessages?.at(-1)?.id;

  useEffect(
    function handleFetchingData() {
      if (course.loading === LoadingState.Idle && messageWithProposal) {
        dispatch(
          getCourseStudentProposalData({
            id: Number(messageWithProposal.studentProposalCourseID),
            studentProposalID: Number(messageWithProposal.studentProposalID),
          })
        );
      }
    },
    [messageWithProposal] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function componentDidMount() {
      return function componentDidUnmount() {
        dispatch(courseSlice.actions.resetCourseStudentProposal());
      };
    },
    [messengerMessages] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#region Handle form
  const initialValues = useMemo<ScheduleStepModel>(
    () => ({
      reservationBufferHours: DEFAULT_RESERVATION_BUFFER_HOURS,
      duration: DEFAULT_DURATION,
      calendarEventType: null,
      canChangeCourseDuration: true,
      canUseLegacyCourseDuration: false,
      blockNewStudentsReservations: 1,
    }),
    []
  );

  const { validationSchema } = useScheduleValidationSchema();

  const formik = useForm<ScheduleStepModel>({
    initialValues,
    validationSchema,
  });
  //#endregion Handle form

  //#region Handle events
  const events = useMemo(() => {
    return timeSlots.map((timeSlotEntity) => transformTimeSlotEntityToEditableCalendarEvent(timeSlotEntity));
  }, [timeSlots]);

  const intervalTree = useMemo(() => {
    return transformCalendarEventsToIntervalTree(events);
  }, [events]);

  async function handleAddEvent(calendarEvent: CalendarEvent) {
    if (!messageWithProposal) {
      return;
    }

    // Transform calendar event to recurring time slot, set it in persist request values and submit the form
    const recurringTimeSlot = transformCalendarEventToTimeSlot(calendarEvent);
    await handleSlotStore({
      recurringTimeSlot: recurringTimeSlot,
      studentProposalID: messageWithProposal.studentProposalID,
    });
  }

  async function handleSlotStore(values: StoreRecurringTimeSlotValues) {
    if (!values.recurringTimeSlot || !values.studentProposalID || !messageWithProposal) {
      return;
    }

    const handleSubmit = handleValidation<StoreRecurringTimeSlotValues>(async (values) => {
      const response = await storeRecurringTimeSlot(values);

      if (response) {
        dispatch(messengerSlice.actions.updateMessageStudentProposalStatus(messageWithProposal.id));
      }
    });
    await handleSubmit(values);
  }
  //#endregion Handle events

  const addEventModalInstance = useAddEventModal({
    duration: Number(course.duration) ?? Number(DEFAULT_DURATION),
    intervalTree,
    onSubmit: handleAddEvent,
    scheduleFormik: formik,
  });

  const { setIsShown: setIsAddEventShown, formik: addFormik } = addEventModalInstance;

  useEffect(
    () => {
      if (formik?.isSubmitting) {
        return;
      }

      const studentProposal = course.studentProposal;

      if (!studentProposal) {
        return;
      }

      const daysOfWeek = studentProposal.daysOfWeek;
      const formValues: CalendarEventFormValues = {
        recurrenceTypeID: daysOfWeek ? RecurrenceTypeID.Weekly : RecurrenceTypeID.Never,
        startDateTime: studentProposal.startDate
          ? parse(studentProposal.startDate, DEFAULT_DATETIME_FORMAT, new Date())
          : getNearestDate(),
        startHour: studentProposal.startDate
          ? parse(studentProposal.startDate, DEFAULT_DATETIME_FORMAT, new Date())
          : getNearestDate(),
        endRecur: studentProposal.endDate ? parse(studentProposal.endDate, DEFAULT_DATETIME_FORMAT, new Date()) : null,
        daysOfWeek: daysOfWeek ? daysOfWeek : [],
        recurrenceTypes: daysOfWeek ? [RecurrenceTypeID.Weekly] : [],
        isStudentProposal: true,
        hasProposal: 1,
        hasInitialProposal: 1,
        proposalPriceType: TimeSlotProposalPriceTypeID.Standard,
        proposalStudent: {
          id: studentProposal.studentID,
          text: studentProposal.studentName,
        },
        proposalCourse: {
          id: studentProposal.courseID,
          text: studentProposal.courseName,
        },
      };

      addFormik.resetForm({ values: formValues });
      setIsAddEventShown(true);
    },
    [course.studentProposal] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function resetMessageWithProposal() {
    setMessageWithProposal(null);
  }

  function handleLoadMoreMessages() {
    if (!hasMore || !threadID) {
      return;
    }
    handleLoadMore();
  }

  if (typeof messagesStartIndex === 'undefined') {
    return <div className="messenger-messages flex-grow-1 p-3 mb-n3" />;
  }

  return (
    <>
      <Virtuoso
        alignToBottom
        className="h-100"
        followOutput="auto"
        ref={virtuosoRef}
        firstItemIndex={messagesStartIndex}
        startReached={handleLoadMoreMessages}
        initialTopMostItemIndex={messengerMessages.length - 1}
        data={messengerMessages}
        itemContent={(index, message) => {
          return (
            <MessengerMessageItem
              key={index}
              message={message}
              handleAddEventClick={setMessageWithProposal}
              isLast={message.id === lastMessengerMessageID}
            />
          );
        }}
        components={{
          List: forwardRef(function List(props, ref) {
            return (
              <div className="messenger-messages flex-grow-1 p-3 mb-n3 py-0 pt-3">
                {previousMessagesLoading === LoadingState.Pending && <SectionSpinner />}
                <div ref={ref} {...props} />
              </div>
            );
          }),
        }}
      />

      <AddEventModal
        instance={addEventModalInstance}
        recurrenceTypeOptions={[]}
        scheduleFormik={formik}
        isLoading={false}
        header="Изпрати покани"
        buttonTitle="Изпрати"
        onExternalClose={resetMessageWithProposal}
      />
    </>
  );
}
