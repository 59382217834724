import React, { ReactNode, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { Form } from '@/app/components/Form/Form';
import { useForm } from '@/app/components/Form/useForm';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { SegmentedNav } from '@/app/components/SegmentedNav/SegmentedNav';
import { SegmentedNavItem } from '@/app/components/SegmentedNav/SegmentedNavItem';
import { MyScheduleFilterInitialValues } from '@/app/modules/my-schedule/constants';
import { MyScheduleFilterQueryParams, MyScheduleFilterValues } from '@/app/modules/my-schedule/models';
import myScheduleSlice from '@/app/modules/my-schedule/store';
import { useQueryParams } from '@/app/utils/query';
import { useAppDispatch } from '@/redux/store';

import { transformMyScheduleFormikValuesToSearchParams } from './utils';

type MyScheduleBasePageProps = {
  children: ReactNode;
  filters?: ReactNode;
};

export function MyScheduleBasePage({ children, filters }: MyScheduleBasePageProps) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { currentSearchParams, queryParams } = useQueryParams<MyScheduleFilterQueryParams>();

  const initialValues = useMemo<MyScheduleFilterValues>(
    () => ({
      course: queryParams?.course ?? MyScheduleFilterInitialValues.course,
      reservationStatusID: queryParams?.reservationStatusID ?? MyScheduleFilterInitialValues.reservationStatusID,
    }),
    [queryParams?.course, queryParams?.reservationStatusID]
  );
  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<MyScheduleFilterValues>({
    initialValues,
    validationSchema,
  });

  useEffect(
    () => {
      const newSearchParams = transformMyScheduleFormikValuesToSearchParams(formik.values, queryParams);

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
      }

      dispatch(myScheduleSlice.actions.resetFutureLessons());

      if (newSearchParams === '') {
        formik.setValues(MyScheduleFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Helmet>
        <title>Моят график</title>
      </Helmet>
      <Page
        className="bg-white p-0"
        renderContent={(props) => <Form {...props} formik={formik} disabled={formik.isSubmitting} skipDirtyPrompt />}
      >
        <div className="bg-gray-200">
          <Content isContainerContent>
            <PageTop className="mb-5">
              <PageTitle className="my-2 fw-bolder fs-2x text-center">Моят график</PageTitle>
            </PageTop>

            <div className="d-flex flex-column flex-md-row flex-nowrap align-items-start align-items-md-center justify-content-betweenr mb-n4 w-100">
              <div className="d-flex flex-wrap align-items-center mb-4">
                <span className="mb-0 me-3 form-label">Изглед:</span>

                <SegmentedNav className="d-flex flex-wrap align-items-center">
                  <SegmentedNavItem to={`/my-schedule?${currentSearchParams}`}>Списък</SegmentedNavItem>
                  <SegmentedNavItem to={`/my-schedule/calendar?${currentSearchParams}`}>Календар</SegmentedNavItem>
                </SegmentedNav>
              </div>

              {Boolean(filters) && filters}
            </div>
          </Content>
        </div>

        <Content isContainerContent>{children}</Content>
      </Page>
    </>
  );
}
