import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { KTSVG } from '@/_metronic/helpers';
import { ReservationStatusID } from '@/app/components/Calendar/models';
import { getIntervalFormat } from '@/app/components/Calendar/utils';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getCanceledReservationDetails } from '../../reservation/service';
import { transformReservationToReservationWithTime } from '../../reservation-group/utils';

export function CourseReservationCanceledPage() {
  const dispatch = useAppDispatch();
  const { reservationID } = useParams<{ reservationID: string }>();
  const loading = useAppSelector((state) => state.course.loading);
  const data = useAppSelector((state) => state.course.canceledReservationDetails);
  const isFailed = data.reservation?.reservationStatusID === ReservationStatusID.Failed;
  const reservation = data?.reservation && transformReservationToReservationWithTime(data.reservation);
  const courseID = data?.course.id;
  const isStudent = useAppSelector((state) => state.course.canceledReservationDetails.isStudent);

  useEffect(
    function handleReservationIDChange() {
      if (loading === LoadingState.Idle) {
        dispatch(getCanceledReservationDetails(Number(reservationID)));
      }
    },
    [reservationID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Helmet>
        <title>Детайли за отказана резервация</title>
      </Helmet>
      <Page>
        <Content>
          <div className="mx-auto text-center mw-800px">
            <div className="my-10">
              {!isFailed && <KTSVG path="/img/svg/icons/xCircle.svg" className="svg-icon-6tx" />}
              {isFailed && (
                <KTSVG path="/img/svg/icons/ExclamationCircle.svg" className="svg-icon-6tx svg-icon-danger" />
              )}
            </div>

            <h1 className="text-gray-800 fs-tx">
              {!isFailed && 'Отказан час'}
              {isFailed && 'Часът е отказан неуспешно'}
            </h1>

            {!isFailed && <p className="text-gray-700 fs-4 mb-10 px-9">{data.reservation?.reasonForCancelation}</p>}

            <Card className="text-start mb-10">
              <CardBody>
                <Row>
                  <Col className="mw-md-200px pb-6 pb-md-0">
                    <h2 className="h1 fw-bold mb-0">Обучение</h2>
                  </Col>
                  <Col xs={12} md={{ span: true }}>
                    <h3 className="fw-bold">{data.course.name}</h3>
                    <div className="d-flex flex-wrap align-items-center">
                      <img
                        src={data.reservation?.canceledForProfileImagePath ?? NO_AVATAR_PATH}
                        alt="Профилна снимка"
                        className="w-20px h-20px rounded me-2"
                      />
                      <h4 className="mb-0 fs-6 fw-normal">
                        {data.reservation?.canceledForName}

                        {!isStudent &&
                          data.reservation?.connectedStudentName &&
                          ` за обучаем ${data.reservation?.connectedStudentName}`}
                      </h4>
                    </div>
                  </Col>
                </Row>

                <hr className="bg-gray-200 opacity-100 my-8" />

                <Row>
                  <Col className="mw-md-200px pb-6 pb-md-0">
                    <h2 className="h1 fw-bold mb-0">Отказан час</h2>
                  </Col>

                  <Col xs={12} md={{ span: true }} className="my-n5">
                    {reservation && (
                      <div className="course-reservation-item text-danger">
                        <h3 className={clsx('fw-normal', { 'text-danger': isFailed })}>
                          {isFailed && (
                            <i className={clsx('far fa-exclamation-circle fs-3 me-2', { 'text-danger': isFailed })} />
                          )}
                          {reservation.date}{' '}
                          <span className={clsx('opacity-50', { 'text-danger': isFailed })}>
                            ({reservation.dayOfWeek})
                          </span>
                        </h3>
                        <h4 className={clsx('mb-0 fw-normal', { 'text-danger': isFailed })}>
                          {getIntervalFormat(reservation.startTime, reservation.endTime)}
                        </h4>
                      </div>
                    )}
                  </Col>
                </Row>

                {isStudent && Boolean(data.promoCode) && (
                  <>
                    <hr className="bg-gray-200 opacity-100 my-8" />
                    <Row>
                      <Col className="mw-md-200px pb-6 pb-md-0">
                        <h2 className="h1 fw-bold mb-0">Промокод</h2>
                      </Col>

                      <Col className="mw-md-200px pb-6 pb-md-0 d-flex align-items-center">
                        <h4 className="mb-0 fw-normal">{data.promoCode}</h4>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>

            {!isFailed && (
              <div>
                <Link to="/my-schedule" className="btn btn-light-primary me-5">
                  Kъм Моят график
                </Link>
                {isStudent && (
                  <Link to={`/courses/${courseID}/reservation`} className="btn btn-primary">
                    Резервирай час
                  </Link>
                )}
                {!isStudent && (
                  <Link to={`/reservation-proposals/${reservationID}/suggestion`} className="btn btn-primary">
                    Предложи друг час
                  </Link>
                )}
              </div>
            )}
          </div>

          {loading === LoadingState.Pending && <GlobalSpinner />}
        </Content>
      </Page>
    </>
  );
}
