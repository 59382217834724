import { Editor, Path, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { isSelectionLinkEnd, isURL, wrapLink } from '@/app/components/RichTextControl/utils';

export const withLinks = (editor: ReactEditor) => {
  const { insertData, isInline, insertBreak } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text) {
      isURL(text) ? wrapLink(editor, text) : insertData(data);
    }
  };

  editor.insertBreak = () => {
    if (isSelectionLinkEnd(editor)) {
      const point = Editor.after(editor, editor?.selection?.focus?.path as Path);
      Transforms.setSelection(editor, {
        anchor: point,
        focus: point,
      });
      insertBreak();
    } else {
      insertBreak();
    }
  };

  return editor;
};
