import { useEffect, useMemo, useState } from 'react';

import { Form } from '@/app/components/Form/Form';
import { RadioButtonControl } from '@/app/components/Form/RadioButtonControl/RadioButtonControl';
import { SearchControl } from '@/app/components/Form/SearchControl/SearchControl';
import { useForm } from '@/app/components/Form/useForm';

import { Subject } from '../../../models';
import {
  ALL_SUBJECTS,
  NO_SUBJECTS_FOUND_MESSAGE,
  SUBJECT_SEARCH_PLACEHOLDER,
  TRANSLITERATED_SLUG_ALL_SUBJECTS,
} from '../constants';
import { FilterDesktop } from './components/FilterDesktop';

type SubjectsIndexPageFilterDesktopProps = {
  subjects: Subject[];
};

export function SubjectsIndexPageFilterDesktop({ subjects }: SubjectsIndexPageFilterDesktopProps) {
  const formik = useForm({ initialValues: { search: '' } });
  const filteredSubjects = useMemo(() => {
    const search = String(formik.values.search).toLocaleLowerCase();
    return subjects.filter((x) => x.text.toLocaleLowerCase().includes(search));
  }, [formik.values.search, subjects]);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    setIsEmpty(filteredSubjects.length === 0);
  }, [filteredSubjects]);

  return (
    <FilterDesktop name="Предмети" className="pt-1">
      <Form formik={formik} skipDirtyPrompt className="mb-4">
        <SearchControl placeholder={SUBJECT_SEARCH_PLACEHOLDER} size="sm" />
      </Form>
      <div className="overflow-scroll mh-100px pt-1">
        <label className="d-flex align-items-center cursor-pointer mb-3">
          <RadioButtonControl name="subjectSlug" value={TRANSLITERATED_SLUG_ALL_SUBJECTS} isInline />
          <span className="ps-2">{ALL_SUBJECTS}</span>
        </label>
        {filteredSubjects.map((subject) => (
          <label key={subject.id} className="d-flex align-items-center cursor-pointer mb-3">
            <RadioButtonControl name="subjectSlug" value={subject.transliteratedSlug} isInline />
            <span className="ps-2">{subject.text}</span>
          </label>
        ))}
      </div>
      {isEmpty && <p className="mb-0 fw-bold text-center">{NO_SUBJECTS_FOUND_MESSAGE}</p>}
    </FilterDesktop>
  );
}
