import { Redirect, Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { wrappedReactLazy } from '@/app/utils/wrapped-react-lazy';

import { CoursePackageEditPage } from './CoursePackageEditPage';
import { CourseStatusEditPage } from './CourseStatusEditPage';

const CourseDetailsEditPage = wrappedReactLazy(() => import('./CourseDetailsEditPage'));
const ScheduleEditPage = wrappedReactLazy(() => import('./ProfileCourseScheduleEditPage'));

export function ProfileCourseEditPage() {
  return (
    <Switch>
      <Redirect from="/profile/courses/:id/edit" to="/profile/courses/:id/edit/status" exact />

      <Route path="/profile/courses/:id/edit/status" exact>
        <CourseStatusEditPage />
      </Route>

      <Route path="/profile/courses/:id/edit/details" exact>
        <CourseDetailsEditPage />
      </Route>

      <Route path="/profile/courses/:id/edit/schedule" exact>
        <ScheduleEditPage />
      </Route>

      <Route path="/profile/courses/:id/edit/packages" exact>
        <CoursePackageEditPage />
      </Route>

      <Route component={NoMatch} />
    </Switch>
  );
}
