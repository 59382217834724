import Toastr from 'toastr';

Toastr.options = {
  closeButton: true,
  debug: false,
  showDuration: 2000,
  hideDuration: 0,
  timeOut: 10000,
  extendedTimeOut: 15000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};
