import { ReactNode, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';

import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
} from '@/_metronic/assets/ts/components';
import { ConsultUsProvider } from '@/_metronic/layout/components/consult-us/ConsultUsProvider';
import { ScrollTop } from '@/_metronic/layout/components/ScrollTop';
import { PageDataProvider } from '@/_metronic/layout/core/PageData';
import { BottomNavigation } from '@/app/layout/BottomNavigation/BottomNavigation';
import { BottomNavigationProvider } from '@/app/layout/BottomNavigation/BottomNavigationProvider';
import { HeaderContext } from '@/app/layout/HeaderContext';

import { AsideMenu } from './AsideMenu/AsideMenu';
import { Footer } from './Footer/Footer';
import { FooterProvider } from './Footer/FooterProvider';
import { HeaderWrapper } from './HeaderWrapper';

type LayoutProps = {
  children: ReactNode;
};

export function Layout({ children }: LayoutProps) {
  const { isVisible } = useContext(HeaderContext);
  const isFirstRun = useRef(true);
  const pluginsInitialization = () => {
    isFirstRun.current = false;
    setTimeout(() => {
      ScrollTopComponent.bootstrap();
      DrawerComponent.bootstrap();
      StickyComponent.bootstrap();
      MenuComponent.bootstrap();
      ScrollComponent.bootstrap();
    }, 200);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      pluginsInitialization();
    }
  }, []);

  return (
    <PageDataProvider>
      <BottomNavigationProvider>
        <FooterProvider>
          <ConsultUsProvider>
            <div className="page d-flex flex-row flex-column-fluid">
              <AsideMenu />

              <div id="kt_wrapper" className={clsx('d-flex flex-column flex-row-fluid', { wrapper: isVisible })}>
                <HeaderWrapper />

                {children}

                <Footer />
                <BottomNavigation />
              </div>
            </div>

            <ScrollTop />
          </ConsultUsProvider>
        </FooterProvider>
      </BottomNavigationProvider>
    </PageDataProvider>
  );
}
