import { Form, FormControlProps as BaseFormControlProps } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import { BsPrefixRefForwardingComponent } from 'react-bootstrap/helpers'; // eslint-disable-line import/no-unresolved
import clsx from 'clsx';
import { useFormikContext } from 'formik';

import { Size } from '@/app/components/Form/models';
import { LoadingState } from '@/redux/constants';

import './FormControl.scss';

type FormControlProps = Omit<BaseFormControlProps, 'size'> & {
  name: string;
  className?: string;
  feedbackClassName?: string;
  isReadOnly?: boolean;
  hasSolidBackground?: boolean;
  size?: Size;
};

export const FormControl: BsPrefixRefForwardingComponent<'input', FormControlProps> = ({
  name,
  disabled,
  className,
  size = 'lg',
  feedbackClassName,
  isReadOnly = false,
  hasSolidBackground = true,
  ...props
}: FormControlProps) => {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<string>(name);
  const fieldProps = formik.getFieldProps<string>(name);
  const isInvalid = Boolean(field.error);

  return (
    <>
      <Form.Control
        {...fieldProps}
        {...props}
        name={name}
        value={field.value ?? ''}
        className={clsx(`form-control-${size}`, className, {
          'is-invalid': isInvalid,
          'form-control-solid': hasSolidBackground,
          'form-control-edit': isReadOnly,
        })}
        disabled={disabled || formik.status === LoadingState.Pending || formik.isSubmitting}
        readOnly={isReadOnly}
      />
      {isInvalid && (
        <Feedback className={clsx(feedbackClassName)} type="invalid">
          {field.error}
        </Feedback>
      )}
    </>
  );
};
