import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import './MobileMonthCellContent.scss';

export function MobileMonthCellContent() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [statuses, setStatuses] = useState<Map<string, number>>();

  useEffect(function componentDidMount() {
    if (containerRef.current) {
      // STEP 1: Find event elements.
      const eventsContainer = containerRef.current?.closest('.fc-daygrid-day-events');
      const eventElements = [...(eventsContainer?.querySelectorAll?.('.fc-event') ?? [])];

      // STEP 2: Extract only status class names.
      const eventClassNames = eventElements.map((eventEl) => {
        const allClassNames = [...eventEl.classList];
        const statusClasses = allClassNames.filter(
          (className) => className.startsWith('fc-event--') && className !== 'fc-event--clickable'
        );

        return statusClasses?.[statusClasses.length - 1];
      });

      // STEP 3: Generate mapping of status class name to occurence count.
      const statuses = new Map();

      for (const eventClassName of eventClassNames) {
        let statusCount = statuses.get(eventClassName);

        if (statusCount) {
          statusCount++;
        } else {
          statusCount = 1;
        }

        statuses.set(eventClassName, statusCount);
      }

      setStatuses(statuses);
    }
  }, []);

  return (
    <div className="mobile-month-statuses-container" ref={containerRef}>
      {[...(statuses?.entries?.() ?? [])].map(([className, num], idx) => (
        <span key={idx} className={clsx('badge mobile-month-statuses-badge', className)}>
          {num}
        </span>
      ))}
    </div>
  );
}
