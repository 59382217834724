import { forwardRef, MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';

import './HeaderButton.scss';

type HeaderButtonProps = {
  children: ReactNode;
  title?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  id?: string;
  'data-kt-menu-trigger'?: string;
  'data-kt-menu-attach'?: string;
  'data-kt-menu-placement'?: string;
  'data-kt-menu-flip'?: string;
};

export const HeaderButton = forwardRef<HTMLButtonElement, HeaderButtonProps>(function HeaderButton(
  { children, title, onClick, className, id, ...props },
  ref
) {
  return (
    <button
      type="button"
      title={title}
      onClick={onClick}
      className={clsx('btn btn-icon btn-clean header-button', className)}
      id={id}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );
});
