import { produce } from 'immer';

import { FileControlValue } from '../models';
import { ReorderableFilesValue } from './models';

/**
 * Transform ReorderableFilesValue to FileControlValue[]. Reorder them if required.
 *
 * @param reorderableFilesValue Files value to transform
 */
export function transformReorderableFilesToFileControlValue(reorderableFilesValue: ReorderableFilesValue) {
  let fileControlValue: FileControlValue[] = [];

  for (const key in reorderableFilesValue) {
    fileControlValue = [...fileControlValue, ...reorderableFilesValue[key]];
  }

  return reorderFiles(fileControlValue);
}

/**
 * Reorder FileControlValue if required.
 *
 * @param fileControlValue Files value to reorder
 */
export function reorderFiles(fileControlValue: FileControlValue[]) {
  return produce(fileControlValue ?? [], (draftFiles) => {
    for (const [idx, file] of draftFiles.entries()) {
      file.order = idx;
    }
  });
}
