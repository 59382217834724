import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type CourseDetailsContentProps = {
  children: ReactNode;
  transliteratedSlug?: string | null;
};

export function CourseDetailsContent({ children, transliteratedSlug = null }: CourseDetailsContentProps) {
  if (!transliteratedSlug) {
    return <>{children}</>;
  }

  return (
    <Link to={`/courses/${transliteratedSlug}?from=checkout`} className="w-100">
      {children}
    </Link>
  );
}
