import { ReactNode } from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';

import { Button } from './Button';

type SaveButtonProps = {
  children?: ReactNode;
  disable?: boolean;
  className?: string;
};

export function SaveButton({ children = 'Запази промените', disable = false, className = '' }: SaveButtonProps) {
  const formik = useFormikContext();
  return (
    <Button type="submit" isLoading={formik.isSubmitting} disabled={disable} className={clsx(className)}>
      {children}
    </Button>
  );
}
