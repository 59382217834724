import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getSupportTicketsNotifications } from './service';

export function useSupportTickets() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const data = useAppSelector((state) => state.supportTicket);

  useEffect(
    function fetchSupportTicketsNotifications() {
      if (auth.isAuthenticated) {
        dispatch(getSupportTicketsNotifications({ page: data.notifications.currentPage }));
      }
    },
    [auth.isAuthenticated, data.notifications.currentPage] // eslint-disable-line react-hooks/exhaustive-deps
  );
}
