import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CopyToClipboard } from '@/app/components/CopyToClipboard/CopyToClipboard';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { getProfilePromoCodes } from '@/app/modules/profile/promo-codes/services';
import { formatPromoCodeValue } from '@/app/utils/money';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import './PromoCodeIndexPage.scss';

export function PromoCodeIndexPage() {
  const dispatch = useAppDispatch();
  const loadingState = useAppSelector((state) => state.profilePromoCode.loading);
  const promoCodes = useAppSelector((state) => state.profilePromoCode.promoCodes);

  useEffect(
    function componentDidMount() {
      if (loadingState === LoadingState.Idle) {
        dispatch(getProfilePromoCodes());
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Helmet>
        <title>Промокодове</title>
      </Helmet>
      <PageTop className="mb-9">
        <PageTitle>Промокодове</PageTitle>
      </PageTop>
      <Card>
        <CardBody>
          <table className="table table-striped table-hover table-rounded border border-gray-300 table-row-bordered table-row-gray-300 gy-5 gs-5 bg-white mb-0 vertical-align-middle">
            <thead>
              <tr>
                <td>Код</td>
                <td>Стойност</td>
                <td>Валиден от</td>
                <td>Валиден до</td>
              </tr>
            </thead>
            <tbody>
              {promoCodes.map((promoCode) => {
                return (
                  <tr key={promoCode.id}>
                    <td>
                      <CopyToClipboard text={promoCode.code} copyMessage="Промокодът е копиран">
                        {promoCode.code}
                      </CopyToClipboard>
                    </td>
                    <td>{formatPromoCodeValue(promoCode.value, promoCode.discountType)}</td>
                    <td>{promoCode.startDateTime}</td>
                    <td>{promoCode.endDateTime}</td>
                  </tr>
                );
              })}
              {promoCodes.length === 0 && (
                <tr>
                  <td colSpan={4}>
                    <p className="mb-0 text-center">Не са намерени активни промокодове</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
      {loadingState !== LoadingState.Idle && <GlobalSpinner />}
    </>
  );
}
