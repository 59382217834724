import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { Form } from '@/app/components/Form/Form';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { SearchControl } from '@/app/components/Form/SearchControl/SearchControl';
import { useForm } from '@/app/components/Form/useForm';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import messengerSlice from './store';

import './MessengerThreadList.scss';

export function MessengerThreadList() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const formik = useForm({ initialValues: { search: '' } });
  const threads = useAppSelector((state) => state.messenger.messengerThreads);
  const filteredThreads = useMemo(() => {
    const search = String(formik.values.search).toLocaleLowerCase();
    return threads.filter((x) => x.subject.toLocaleLowerCase().includes(search));
  }, [formik.values.search, threads]);

  function handleResetMessages(messengerThreadID: number) {
    if (messengerThreadID === Number(id)) {
      return;
    }

    dispatch(messengerSlice.actions.resetMessages());
  }

  return (
    <>
      <Helmet>
        <title>Съобщения</title>
      </Helmet>
      <div className="d-flex flex-column h-100">
        <div className="p-5 border-bottom">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <h4 className="m-0">Съобщения</h4>

            <Link to="/messenger/create" className="btn btn-icon btn-light-primary">
              <i className="fas fa-plus fa-2x" />
            </Link>
          </div>
          <Form formik={formik} skipDirtyPrompt>
            <SearchControl placeholder="Търсене по потребител" />
          </Form>
        </div>

        <div className="messenger-threads d-flex flex-column flex-grow-1">
          {filteredThreads.map((messengerThread) => {
            const isSelected = messengerThread.id === Number(id);

            return (
              <Link
                key={messengerThread.id}
                to={`/messenger/${messengerThread.id}`}
                className={clsx('d-flex flex-wrap align-items-center p-5 border-bottom', {
                  'hoverable bg-light-dark': isSelected,
                  'bg-hover-gray-100': !isSelected,
                })}
                onClick={() => handleResetMessages(messengerThread.id)}
              >
                <img
                  src={messengerThread.picture ?? NO_AVATAR_PATH}
                  alt="Профилна снимка"
                  className="rounded w-50px h-50px"
                />

                <span className="text-dark fw-bold mx-5">{messengerThread.subject}</span>

                {messengerThread.unreadMessagesCount > 0 && (
                  <span className="badge badge-circle badge-primary ms-auto flex-shrink-0">
                    {messengerThread.unreadMessagesCount}
                  </span>
                )}
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}
