import { ReactNode } from 'react';
import Echo from 'laravel-echo';

import { EchoContext } from '@/app/utils/real-time/EchoContext';

type EchoProviderProps = {
  children: ReactNode;
  echoInstance: Echo;
};

export function EchoProvider({ children, echoInstance }: EchoProviderProps) {
  return <EchoContext.Provider value={echoInstance}>{children}</EchoContext.Provider>;
}
