import { ReactNode } from 'react';
import clsx from 'clsx';

import './PageActions.scss';

type PageActionsProps = {
  children: ReactNode;
  className?: string;
};

export function PageActions({ children, className = '' }: PageActionsProps) {
  return <div className={clsx('page-actions', className)}>{children}</div>;
}
