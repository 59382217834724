import axios from 'axios';

import { ConversionEventValues, CurrentBulgarianTime } from '@/app/modules/analytics/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

const ENDPOINT_PREFIX = '/ajax/conversions-api';

export const ENDPOINTS = {
  track: ENDPOINT_PREFIX,
  currentBGTime: `${ENDPOINT_PREFIX}/current-bg-time`,
};

export async function trackAnalyticsEventConversionsAPI(values: ConversionEventValues) {
  const formData = serializeFormData(values);
  const response = await axios.post(ENDPOINTS.track, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export async function getCurrentBulgarianTime() {
  const response = await axios.get<CurrentBulgarianTime>(ENDPOINTS.currentBGTime);

  return response.data;
}
