import { TutorStatusID } from '@/app/models/TutorStatusID';

export const EDITABLE_TUTOR_STATUS_IDS = [
  TutorStatusID.Approved,
  TutorStatusID.WaitingApproval,
  TutorStatusID.Rejected,
];
export const IN_PROGRESS_TUTOR_STATUS_IDS = [TutorStatusID.ProfileDetailsReady, TutorStatusID.FirstCourseReady];

export const PROFILE_BANK_DETAILS_TITLE = 'Банкова сметка';
export const PROFILE_BANK_DETAILS_SUBTITLE = 'Сметката на която ще получавате Вашето възнаграждение при изтегляне.';

export const PROFILE_INVOICE_DETAILS_TITLE = 'Фактуриране';
export const PROFILE_INVOICE_DETAILS_SUBTITLE =
  'Данни, които ще се използват за създаване на фактура към потребител, който е закупил Ваше обучение.';

export const PROFILE_FILE_DIMENSION_ERROR = 'Профилната Ви снимка трябва да е с минимален размер от 200 x 200 пиксела';
