import { CoursePackageLessonCount } from '@/app/modules/course/constants';
import { createCoursePackage } from '@/app/modules/course/create/models';

export const TIME_SLOT_DURATION_LEGACY_OPTIONS = [
  {
    id: 30,
    text: '30 минути',
  },
  {
    id: 45,
    text: '45 минути',
  },
  {
    id: 60,
    text: '60 минути',
  },
  {
    id: 90,
    text: '90 минути',
  },
  {
    id: 120,
    text: '120 минути',
  },
];

export const TIME_SLOT_DURATION_OPTIONS = [
  {
    id: 45,
    text: '45 минути',
  },
  {
    id: 60,
    text: '60 минути',
  },
];

const DAY_END_HOUR = 24;
const RESERVATION_BUFFER_HOURS = Array.from({ length: DAY_END_HOUR }, (_, i) => i + 1);
export const RESERVATION_BUFFER_HOURS_OPTIONS = RESERVATION_BUFFER_HOURS.map((hour) => ({
  id: hour,
  text: `${hour} ${hour === 1 ? 'час' : 'часа'}`,
}));

export const INITIAL_COURSE_PACKAGES = [
  createCoursePackage({ id: -1, lessonCount: CoursePackageLessonCount.Four }),
  createCoursePackage({ id: -2, lessonCount: CoursePackageLessonCount.Eight }),
  createCoursePackage({ id: -3, lessonCount: CoursePackageLessonCount.Sixteen }),
];

export const COURSE_DETAILS_STEP_TITLE = 'Детайли за обучението';
export const SCHEDULE_STEP_TITLE = 'График';
export const COURSE_PACKAGE_STEP_TITLE = 'Цени и пакети';
export const PREVIEW_COURSE_STEP_TITLE = 'Преглед';

export const COURSE_PACKAGE_LESSON_COUNT_OPTIONS = [
  {
    id: 4,
    text: '4',
  },
  {
    id: 8,
    text: '8',
  },
  {
    id: 16,
    text: '16',
  },
  {
    id: 32,
    text: '32',
  },
];
