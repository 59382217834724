import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';

import { PrivateRoute } from '../auth/PrivateRoute';
import { OrderDetailsPage } from './OrderDetailsPage';

export function OrderPage() {
  return (
    <Switch>
      <PrivateRoute path="/orders/:id" exact>
        <OrderDetailsPage />
      </PrivateRoute>
      <Route component={NoMatch} />
    </Switch>
  );
}
