import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { GetFilteredTutorCoursesResponse, TutorCourseSubject, TutorCourseSubjectTargetAudience } from '../models';
import { getFilteredTutorCourses } from '../service';

type ChooseCourseStepProps = {
  tutorID: number;
  selectedSubject: TutorCourseSubject | null;
  selectedTargetAudience: TutorCourseSubjectTargetAudience | null;
  setTitle: React.Dispatch<React.SetStateAction<string | null>>;
  onClose: () => void;
};

export function ChooseCourseStep({
  tutorID,
  selectedSubject,
  selectedTargetAudience,
  setTitle,
  onClose,
}: ChooseCourseStepProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.buyCourseLesson.loading);
  const courses = useAppSelector((state) => state.buyCourseLesson.courses);

  useEffect(
    function componentDidMount() {
      setTitle('Изберете обучение');

      async function fetchCoursesData() {
        const response = await dispatch(
          getFilteredTutorCourses({
            tutorID: Number(tutorID),
            subjectID: Number(selectedSubject?.id),
            targetAudienceID: Number(selectedTargetAudience?.id),
          })
        );

        const responsePayload = response.payload as GetFilteredTutorCoursesResponse;

        if (responsePayload?.courses?.length === 0) {
          return onClose();
        }

        if (responsePayload?.courses?.length === 1) {
          const transliteratedSlug = responsePayload.courses[0].transliteratedSlug;
          history.push(`/courses/${transliteratedSlug}/checkout`);
          return;
        }
      }

      if (loading === LoadingState.Idle) {
        fetchCoursesData();
      }
    },
    [history, dispatch, setTitle]
  );

  return (
    <div className="d-flex flex-column mb-n2">
      {courses?.map((course, index) => (
        <Link
          key={index}
          to={`/courses/${course.transliteratedSlug}/checkout`}
          className="btn border border-2 text-dark mb-2"
        >
          {course.name}
        </Link>
      ))}
    </div>
  );
}
