import produce from 'immer';

import { DEFAULT_SEARCH_VALUE, DEFAULT_SORT_BY_FILTER_VALUE } from '@/app/modules/subject/constants';
import { SubjectIndexFilterQueryParams, SubjectIndexFilterValues } from '@/app/modules/subject/models';
import { stringifyQuery } from '@/app/utils/query';

export function transformSubjectIndexFormikValuesToSearchParams(
  formikValues: SubjectIndexFilterValues,
  queryParams: SubjectIndexFilterQueryParams
) {
  const newQueryParams: SubjectIndexFilterQueryParams = produce(queryParams, (draft) => {
    if (formikValues.sortBy !== DEFAULT_SORT_BY_FILTER_VALUE) {
      draft.sortBy = formikValues.sortBy;
    } else {
      delete draft.sortBy;
    }

    if (formikValues.search !== DEFAULT_SEARCH_VALUE) {
      draft.search = formikValues.search;
    } else {
      delete draft.search;
    }
  });

  return stringifyQuery(newQueryParams);
}
