import { useEffect } from 'react';

import { getAvailableEnrolledCoursesCount } from '@/app/modules/auth/service';
import { EnterLessonButton } from '@/app/modules/my-schedule/EnterLesson/EnterLessonButton';
import { getMyScheduleNotificationsCount } from '@/app/modules/my-schedule/service';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { MenuItem } from './MenuItem';

export function Header() {
  const dispatch = useAppDispatch();
  const { availableEnrolledCoursesCountLoading, isAuthenticated, availableEnrolledCoursesCount } = useAppSelector(
    (state) => state.auth
  );
  const { notificationsCount, notificationsCountLoading } = useAppSelector((state) => state.mySchedule);

  useEffect(
    function componentDidMount() {
      if (availableEnrolledCoursesCountLoading === LoadingState.Idle && isAuthenticated) {
        dispatch(getAvailableEnrolledCoursesCount());
      }

      if (notificationsCountLoading === LoadingState.Idle && isAuthenticated) {
        dispatch(getMyScheduleNotificationsCount());
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div
      className="header-menu align-items-stretch"
      data-kt-drawer="true"
      data-kt-drawer-name="header-menu"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
      data-kt-swapper="true"
      data-kt-swapper-mode="prepend"
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div
        className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 my-5 my-lg-0 align-items-stretch"
        id="#kt_header_menu"
        data-kt-menu="true"
      >
        {!isAuthenticated && (
          <MenuItem to="/" isExact>
            За платформата
          </MenuItem>
        )}
        <MenuItem to="/subjects">Предмети</MenuItem>
        {isAuthenticated && (
          <>
            <MenuItem to="/my-schedule" notificationsCount={notificationsCount}>
              Моят график
            </MenuItem>
            <MenuItem to="/enrolled-courses" notificationsCount={availableEnrolledCoursesCount}>
              Моите обучения
            </MenuItem>
          </>
        )}
        {isAuthenticated && <EnterLessonButton />}
      </div>
    </div>
  );
}
