import { useRef } from 'react';
import { Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { StaticPageVideoItem } from '@/app/modules/static-pages/StaticPageVideoItem';
import { useScrollIntoView } from '@/app/utils/useScrollIntoView';

import './StaticPage.scss';

const TIPS_TUTOR_ID = 'tips-tutor-presentation';
const TIPS_COURSE_ID = 'tips-course-presentation';

export function TutorAdvicesPage() {
  const tutorTipsRef = useRef<HTMLHeadingElement | null>(null);
  const courseTipsRef = useRef<HTMLHeadingElement | null>(null);

  useScrollIntoView({
    [TIPS_TUTOR_ID]: tutorTipsRef,
    [TIPS_COURSE_ID]: courseTipsRef,
  });

  return (
    <>
      <Helmet>
        <title>Съвети за Обучители</title>
      </Helmet>
      <Page className="static-page tutor-advices">
        <Content>
          <PageTitle className="mb-9 fw-bolder fs-2x text-center">Съвети за Обучители</PageTitle>
          <Card className="card-custom card-border text-gray-800">
            <Card.Body>
              <h4 ref={tutorTipsRef} id={TIPS_TUTOR_ID} className="text-center mt-8 mb-8 text-gray-800">
                Как да попълните пълноценно профила си?
              </h4>

              <StaticPageVideoItem
                videoURL="https://player.vimeo.com/video/746462787?title=0&byline=0"
                className="mb-8"
              />

              <p>
                Профилът е Вашата визитка, той дава информация на учениците и техните родители какво да очакват от
                уроците при Вас и им помага да изберат Вас сред останалите педагози в платформата. Следващите стъпки ще
                Ви помогнат в оформянето на профил, който да Ви представя, като дава пълноценна информация за опита и
                подходите Ви.
              </p>
              <ol>
                <li>
                  Изберете профилна снимка с добро качество, тип паспортна снимка. Препоръчително за оптимално качество
                  на изображението е да бъде квадратна снимка с размери поне 400х400 пиксела.
                </li>
                <li>
                  Разкажете за себе си в няколко изречения, като може да помислите върху следните въпроси при оформяне
                  на описанието:
                  <ul>
                    <li>Какъв е Вашият опит?</li>
                    <li>Какво Ви мотивира да бъдете обучител?</li>
                    <li>Какви цели си поставяте при работата с учениците?</li>
                    <li>Какво Ви отличава като педагог?</li>
                    <li>Какви предимства и ползи виждате в онлайн обучението?</li>
                  </ul>
                </li>
                <li>
                  Попълнете всички секции пълноценно. Колкото повече информация има за Вас, толкова по-лесно ще бъде за
                  родителите и учениците да Ви изберат.
                </li>
              </ol>

              <h4 ref={courseTipsRef} id={TIPS_COURSE_ID} className="text-center mt-8 mb-8 text-gray-800">
                Как да представите най-добре обученията си?
              </h4>

              <StaticPageVideoItem
                videoURL="https://player.vimeo.com/video/746468960?title=0&byline=0"
                className="mb-8"
              />

              <p>
                Учениците и техните родители търсят информация по различни критерии – обучение, обучители, степен на
                образование. Важно е всички секции с информация за отделните обучения, които предлагате, да са
                достатъчно информативни, за да ориентират учениците дали Вашето обучение е това, от което се нуждаят.
                Много вероятно е те първо да попаднат на страницата на обучението, а след това да разгледат профила Ви,
                затова е важно да попълните всички секции дори те да повтарят част от информацията в профила Ви. При
                създаването на обучения, Ви съветваме да следвате следните стъпки:
              </p>
              <ol>
                <li>
                  Изберете заглавие, което да е интересно, да е ориентирано към крайната цел на обучението.
                  Препоръчително е да не преповтаря предмета или възрастовата група, които така или иначе се виждат в
                  платформата. <br /> Примери за подходящо заглавие: “100 точки на НВО” / “Математиката всъщност е
                  лесна” / “Творчески подходи към материала по БЕЛ” / Подготовка за ниво B1-B2 по английски език /
                  “Говорете свободно немски език!” / “Подготовка за текущо оценяване по химия”
                </li>
                <li>
                  Задайте продължителност на урока, която е удобна за Вас спрямо материята. Имайте предвид, че
                  продължителността на един урок ще бъде еднаква за всички обучения, които предлагате.
                </li>
                <li>
                  Създайте отделни обучения по различните предмети и/или степени на образование, в които преподавате.
                  Така обученията Ви ще се откриват лесно в съответната категория.
                </li>
                <li>Разгледайте добре и добавете всички ключови думи, които се отнасят за обучението Ви. </li>
                <li>
                  Опишете информацията за самото обучение, като попълните всички секции, отнасящи се до методи, подходи
                  и съдържание. Важно е учениците да разберат конкретно какво обхваща обучението и каква е неговата
                  крайна цел.
                </li>
                <li>
                  Попълнете графика си за възможно най-много време напред, за да може и Вие, и учениците Ви да планирате
                  времето си. Графикът показва свободните и вече заетите Ви часове, и се отнася до всички Ваши обучения.
                  Обновявайте го редовно, спрямо промяната на ангажиментите Ви, за да поддържате информацията актуална и
                  да намалите вероятността да се налага да отказвате уроци, заявени от учениците.
                </li>
              </ol>

              <h4 className="text-center mb-8 text-gray-800">Как да определя цените на уроците си? </h4>

              <StaticPageVideoItem
                videoURL="https://player.vimeo.com/video/746470895?title=0&byline=0"
                className="mb-8"
              />

              <h4 className="text-center mb-8 text-gray-800">Как да подобрите качеството при онлайн обучение?</h4>

              <ol>
                <li>
                  В началото и в края на урока, включете камерата си, това помага за изграждането на доверие с ученика.
                </li>
                <li>Обсъдете как ще премине времето Ви заедно, задайте рамка и цели на урока.</li>
                <li>
                  Подгответе визуализации на учебното съдържание, които да споделите с ученика в презентация или като
                  отделни файлове.
                </li>
                <li>
                  Направете обучението по-интерактивно, като ползвате дигитални платформи за създаване учебни материали
                  с безплатен достъп.
                </li>
              </ol>
            </Card.Body>
          </Card>
        </Content>
      </Page>
    </>
  );
}
