import { ReactNode } from 'react';
import { Nav } from 'react-bootstrap';
import clsx from 'clsx';

type SegmentedNavProps = {
  children: ReactNode;
  className?: string;
};

export function SegmentedNav({ children, className }: SegmentedNavProps) {
  return (
    <Nav variant="pills" className={clsx('btn-group', className)} activeKey={location.pathname}>
      {children}
    </Nav>
  );
}
