import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Toastr from 'toastr';
import * as yup from 'yup';

import { CancelButton } from '@/app/components/Button/CancelButton';
import { SaveButton } from '@/app/components/Button/SaveButton';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation } from '@/app/components/Form/utils';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { PROFILE_BANK_DETAILS_SUBTITLE, PROFILE_BANK_DETAILS_TITLE } from '@/app/modules/profile/details/constants';
import { TutorDetailsEditPageBase } from '@/app/modules/profile/details/edit/TutorDetailsEditPageBase';
import { ProfileBankDetails } from '@/app/modules/profile/details/models';
import { getProfileBankDetails, updateProfileProfileBankDetails } from '@/app/modules/profile/details/service';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function TutorBankDetailsEditPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loading = useAppSelector((state) => state.profileDetails.loading);
  const content = useAppSelector((state) => state.profileDetails.bankDetails);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getProfileBankDetails());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //#region Handle form
  const initialValues = useMemo<ProfileBankDetails>(
    () => ({
      bankAccountOwnerName: content.bankAccountOwnerName,
      bankAccountIBAN: content.bankAccountIBAN,
    }),
    [content.bankAccountIBAN, content.bankAccountOwnerName]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        bankAccountOwnerName: yup.string().required(),
        bankAccountIBAN: yup.string().optional().iban(),
      }),
    []
  );

  const formik = useForm({
    initialValues,
    validationSchema,
    initialStatus: true,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<ProfileBankDetails>(async (values) => {
        return await updateProfileProfileBankDetails(values);
      });

      const response = await submitWithValidation(values, formikHelpers);

      if (response) {
        Toastr.success('Успешно запазихте промените!');
        formik.resetForm({ values });
        history.push('/profile/bank-details');
      }
    },
  });
  //#endregion Handle form

  return (
    <>
      <Helmet>
        <title>{PROFILE_BANK_DETAILS_TITLE}</title>
      </Helmet>
      <TutorDetailsEditPageBase
        formik={formik}
        title={PROFILE_BANK_DETAILS_TITLE}
        actions={
          <>
            <CancelButton to="/profile/bank-details" />
            <SaveButton />
          </>
        }
      >
        <CardHeader>
          <CardTitle>{PROFILE_BANK_DETAILS_TITLE}</CardTitle>
          <CardSubTitle>{PROFILE_BANK_DETAILS_SUBTITLE}</CardSubTitle>
        </CardHeader>
        <CardBody>
          <FormGroup controlId="bankAccountOwnerName">
            <Label isRequired>Име на собственика на сметката</Label>
            <FormControl type="text" name="bankAccountOwnerName" />
          </FormGroup>
          <FormGroup controlId="bankAccountIBAN">
            <Label isRequired>IBAN на сметката</Label>
            <FormControl type="text" name="bankAccountIBAN" />
          </FormGroup>
        </CardBody>
        {loading === LoadingState.Pending && <GlobalSpinner />}
      </TutorDetailsEditPageBase>
    </>
  );
}
