import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import {
  ProfileAboutDetailsValues,
  ProfileBankDetails,
  ProfileDetailsValues,
  ProfileInvoiceDetails,
  TutorProfileDetailsShowData,
} from './models';
import {
  getProfileBankDetails,
  getProfileInvoiceDetails,
  getStudentProfileDetailsEditData,
  getStudentProfileDetailsShowData,
  getTutorProfileAboutDetails,
  getTutorProfileDetailsEditData,
  getTutorProfileDetailsShowData,
} from './service';

interface ProfileDetailsState {
  loading: LoadingState;
  error: string | null;
  aboutDetails: ProfileAboutDetailsValues;
  profileDetails: ProfileDetailsValues;
  invoiceDetails: ProfileInvoiceDetails;
  bankDetails: ProfileBankDetails;
  show: {
    profileDetails: TutorProfileDetailsShowData;
  };
}

const initialState: ProfileDetailsState = {
  loading: LoadingState.Idle,
  error: null,
  profileDetails: {
    profileFile: '',
    name: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    about: EMPTY_VALUE,
    username: '',
    attachments: [],
  },
  aboutDetails: {
    educationDegreeID: null,
    university: '',
    hasPedagogicalDegree: false,
    pedagogicalDegree: '',
    teachingExperienceID: null,
    hasStudentPrepareExperience: false,
    technicalExperience: null,
    approach: '',
    motivation: '',
    importance: '',
    pupilAchievement: '',
    attachments: [],
  },
  invoiceDetails: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    birthday: '',
    address: '',
    postCode: '',
  },
  bankDetails: {
    bankAccountOwnerName: '',
    bankAccountIBAN: '',
  },
  show: {
    profileDetails: {
      profileFile: '',
      name: '',
      phone: '',
      email: '',
      about: EMPTY_VALUE,
      username: '',
      attachments: [],
    },
  },
};

const profileDetailsSlice = createSlice({
  name: 'profileDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region GET TUTOR PROFILE DETAILS
    builder
      .addCase(getTutorProfileDetailsShowData.pending, function (state) {
        if (state.loading === LoadingState.Idle) {
          state.loading = LoadingState.Pending;
          state.show = initialState.show;
        }
      })
      .addCase(getTutorProfileDetailsShowData.rejected, sharedRejectedReducer)
      .addCase(getTutorProfileDetailsShowData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.show.profileDetails.profileFile = action.payload.details.profileFile;
          state.show.profileDetails.name = action.payload.details.name;
          state.show.profileDetails.phone = action.payload.details.phone;
          state.show.profileDetails.email = action.payload.details.email;
          state.show.profileDetails.username = action.payload.details.username;
          state.show.profileDetails.about = action.payload.details.about;
          state.show.profileDetails.attachments = action.payload.details.attachments;
        }
      });

    builder
      .addCase(getTutorProfileDetailsEditData.pending, function (state) {
        if (state.loading === LoadingState.Idle) {
          state.loading = LoadingState.Pending;
          state.profileDetails = initialState.profileDetails;
        }
      })
      .addCase(getTutorProfileDetailsEditData.rejected, sharedRejectedReducer)
      .addCase(getTutorProfileDetailsEditData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.profileDetails.profileFile = action.payload.profile.profileFile;
        state.profileDetails.name = action.payload.profile.name;
        state.profileDetails.firstName = action.payload.profile.firstName;
        state.profileDetails.lastName = action.payload.profile.lastName;
        state.profileDetails.phone = action.payload.profile.phone;
        state.profileDetails.email = action.payload.profile.email;
        state.profileDetails.username = action.payload.profile.username;
        state.profileDetails.about = action.payload.profile.about;
        state.profileDetails.attachments = action.payload.profile.attachments;
      });
    //#endregion GET TUTOR PROFILE DETAILS

    //#region GET STUDENT PROFILE DETAILS
    builder
      .addCase(getStudentProfileDetailsShowData.pending, sharedPendingReducer)
      .addCase(getStudentProfileDetailsShowData.rejected, sharedRejectedReducer)
      .addCase(getStudentProfileDetailsShowData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.profileDetails.profileFile = action.payload.details.profileFile;
          state.profileDetails.name = action.payload.details.name;
          state.profileDetails.phone = action.payload.details.phone;
          state.profileDetails.email = action.payload.details.email;
          state.profileDetails.username = action.payload.details.username;
        }
      });

    builder
      .addCase(getStudentProfileDetailsEditData.pending, sharedPendingReducer)
      .addCase(getStudentProfileDetailsEditData.rejected, sharedRejectedReducer)
      .addCase(getStudentProfileDetailsEditData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.profileDetails.profileFile = action.payload.profile.profileFile;
        state.profileDetails.firstName = action.payload.profile.firstName;
        state.profileDetails.lastName = action.payload.profile.lastName;
        state.profileDetails.phone = action.payload.profile.phone;
        state.profileDetails.email = action.payload.profile.email;
        state.profileDetails.username = action.payload.profile.username;
      });
    //#endregion GET STUDENT PROFILE DETAILS

    //#region GET PROFILE INVOICE DETAILS
    builder
      .addCase(getProfileInvoiceDetails.pending, sharedPendingReducer)
      .addCase(getProfileInvoiceDetails.rejected, sharedRejectedReducer)
      .addCase(getProfileInvoiceDetails.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.invoiceDetails.firstName = action.payload.invoiceDetails.firstName;
          state.invoiceDetails.lastName = action.payload.invoiceDetails.lastName;
          state.invoiceDetails.phone = action.payload.invoiceDetails.phone;
          state.invoiceDetails.email = action.payload.invoiceDetails.email;
          state.invoiceDetails.birthday = action.payload.invoiceDetails.birthday;
          state.invoiceDetails.address = action.payload.invoiceDetails.address;
          state.invoiceDetails.city = action.payload.invoiceDetails.city;
          state.invoiceDetails.postCode = action.payload.invoiceDetails.postCode;
        }
      });
    //#endregion GET PROFILE INVOICE DETAILS

    //#region GET PROFILE BANK DETAILS
    builder
      .addCase(getProfileBankDetails.pending, sharedPendingReducer)
      .addCase(getProfileBankDetails.rejected, sharedRejectedReducer)
      .addCase(getProfileBankDetails.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.bankDetails.bankAccountOwnerName = action.payload.bankDetails.bankAccountOwnerName;
          state.bankDetails.bankAccountIBAN = action.payload.bankDetails.bankAccountIBAN;
        }
      });
    //#endregion GET PROFILE BANK DETAILS

    //#region GET TUTOR PROFILE ABOUT DETAILS
    builder
      .addCase(getTutorProfileAboutDetails.pending, sharedPendingReducer)
      .addCase(getTutorProfileAboutDetails.rejected, sharedRejectedReducer)
      .addCase(getTutorProfileAboutDetails.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.aboutDetails.educationDegreeID = action.payload.aboutDetails.educationDegreeID;
          state.aboutDetails.university = action.payload.aboutDetails.university;
          state.aboutDetails.hasPedagogicalDegree = action.payload.aboutDetails.hasPedagogicalDegree;
          state.aboutDetails.pedagogicalDegree = action.payload.aboutDetails.pedagogicalDegree;
          state.aboutDetails.teachingExperienceID = action.payload.aboutDetails.teachingExperienceID;
          state.aboutDetails.hasStudentPrepareExperience = action.payload.aboutDetails.hasStudentPrepareExperience;
          state.aboutDetails.technicalExperience = action.payload.aboutDetails.technicalExperience;
          state.aboutDetails.approach = action.payload.aboutDetails.approach;
          state.aboutDetails.motivation = action.payload.aboutDetails.motivation;
          state.aboutDetails.importance = action.payload.aboutDetails.importance;
          state.aboutDetails.pupilAchievement = action.payload.aboutDetails.pupilAchievement;
          state.aboutDetails.attachments = action.payload.attachments;
        }
      });
    //#endregion GET TUTOR PROFILE ABOUT DETAILS
  },
});

export default profileDetailsSlice;
