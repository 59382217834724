import React, { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

import './InformationPopover.scss';

type InformationPopoverProps = {
  helperMessage: string;
  children?: React.ReactNode;
};

export function InformationPopover({ helperMessage, children }: InformationPopoverProps) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  function handleClick() {
    setShow((prevState) => !prevState);
  }

  function handleHide() {
    setShow(false);
  }

  return (
    <div>
      {children}
      <i className="fal fa-info-circle info-button ms-2" onClick={handleClick} role="button" ref={target} />

      <Overlay show={show} target={target.current} rootClose onHide={handleHide}>
        <Tooltip className="tooltip--info">{helperMessage}</Tooltip>
      </Overlay>
    </div>
  );
}
