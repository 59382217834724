import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { ConsultUs } from '@/_metronic/layout/components/consult-us/ConsultUs';
import { useAppSelector } from '@/redux/store';

import {
  DrawerComponent,
  MenuComponent,
  // ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
} from '../../assets/ts/components';
import { KTSVG } from '../../helpers';
import { ConsultUsContext } from './consult-us/ConsultUsContext';
import { BottomStickyVariant } from './consult-us/models';

export function ScrollTop() {
  const { consultUs } = useAppSelector((state) => state.platformSettings.settings);
  const { pathname } = useLocation();
  const isFirstRun = useRef(true);
  const { bottomStickyVariant } = useContext(ConsultUsContext);

  const pluginsReinitialization = () => {
    setTimeout(() => {
      // ScrollTopComponent.reinitialization()
      MenuComponent.reinitialization();
      StickyComponent.reInitialization();
      setTimeout(() => {
        ToggleComponent.reinitialization();
        DrawerComponent.reinitialization();
        // ScrollComponent.reinitialization()
      }, 70);
    }, 140);
  };

  const scrollTop = () => {
    ScrollTopComponent.goTop();
  };

  const updateHeaderSticky = () => {
    const stickyHeader = document.body.querySelectorAll(`[data-kt-sticky-name="header"]`);
    if (stickyHeader && stickyHeader.length > 0) {
      const sticky = StickyComponent.getInstance(stickyHeader[0] as HTMLElement);
      if (sticky) {
        sticky.update();
      }
    }
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      pluginsReinitialization();
    }

    updateHeaderSticky();
    setTimeout(() => {
      scrollTop();
    }, 0);
  }, [pathname]);

  return (
    <div
      className={clsx('sticky-container', {
        'sticky-container--bottom-cta': bottomStickyVariant === BottomStickyVariant.BottomCTA,
      })}
    >
      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <KTSVG path="/img/svg/icons/Navigation/Up-2.svg" />
      </div>
      {consultUs.isActive && <ConsultUs text={consultUs.buttonText} link={consultUs.link} />}
    </div>
  );
}
