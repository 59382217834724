import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useHiddenConsultUs } from '@/_metronic/layout/components/consult-us/useHiddenConsultUs';
import { WeekDay } from '@/app/components/Calendar/models';
import { Card } from '@/app/components/Card/Card';
import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { DatePickerControl } from '@/app/components/Form/DatePickerControl/DatePickerControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useHiddenFooter } from '@/app/layout/Footer/useHiddenFooter';

import { COURSE_INDEX_FILTER_TIME_RANGE, SHOW_FREE_LESSONS_AT_LEAST_ONE_HOUR } from '../../../constants';
import { CourseIndexPageFilterMobile } from '../../../models';
import { HideFiltersButtonMobile } from './components/HideFiltersButtonMobile';

type TimeIntervalsFilterMobileProps = {
  handleFilterToggle: () => void;
  setCurrentFilter: React.Dispatch<React.SetStateAction<CourseIndexPageFilterMobile | null>>;
};

export function TimeIntervalsFilterMobile({ handleFilterToggle, setCurrentFilter }: TimeIntervalsFilterMobileProps) {
  const now = useMemo(() => new Date(), []);

  function handleClickClose() {
    handleFilterToggle();
    setCurrentFilter(null);
  }

  function handleClickBack() {
    setCurrentFilter(null);
  }

  useHiddenConsultUs();
  useHiddenFooter();

  return (
    <div className="pb-1">
      <HideFiltersButtonMobile onClickHide={handleClickClose} />

      <div className="d-flex justify-content-center mt-2">
        <button className="btn ps-0 pe-0" onClick={handleClickBack}>
          <i className="far fa-arrow-left text-primary fs-4" />
        </button>
        <span className="w-100 fs-2 d-flex justify-content-center align-items-center">Дата/час</span>
      </div>

      <div className="d-flex align-items-center p-2 mb-5 rounded non-default-filter">
        <CheckboxControl
          name="hasFutureTimeSlots"
          label={SHOW_FREE_LESSONS_AT_LEAST_ONE_HOUR}
          value={1}
          hasSolidBackground={false}
        />
      </div>
      <Row>
        <Col xs={12} sm={6}>
          <FormGroup className="mb-5" controlId="fromDate">
            <Label>От</Label>
            <DatePickerControl name="fromDate" minDate={now} maxDateFieldName="toDate" hasSolidBackground={false} />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup className="mb-5" controlId="toDate">
            <Label>До</Label>
            <DatePickerControl name="toDate" minDate={now} minDateFieldName="fromDate" hasSolidBackground={false} />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup controlId="timeRanges mb-5">
        <Label>Часови интервали</Label>
        {COURSE_INDEX_FILTER_TIME_RANGE.map((timeRange) => (
          <Card key={timeRange.id} className="rounded w-100 mb-3">
            <CheckboxControl
              className="d-flex justify-content-between p-4 cursor-pointer"
              name="timeRanges"
              label={timeRange.text}
              value={timeRange.id as string}
              labelFirst
            />
          </Card>
        ))}
      </FormGroup>

      <FormGroup controlId="daysOfWeek">
        <Label>Ден от седмицата</Label>
        <div className="mt-1">
          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="daysOfWeek"
              className="d-flex justify-content-between p-4 cursor-pointer"
              label="Понеделник"
              value={WeekDay.Monday}
              labelFirst
            />
          </Card>

          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="daysOfWeek"
              className="d-flex justify-content-between p-4 cursor-pointer"
              label="Вторник"
              value={WeekDay.Tuesday}
              labelFirst
            />
          </Card>

          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="daysOfWeek"
              className="d-flex justify-content-between p-4 cursor-pointer"
              label="Сряда"
              value={WeekDay.Wednesday}
              labelFirst
            />
          </Card>

          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="daysOfWeek"
              className="d-flex justify-content-between p-4 cursor-pointer"
              label="Четвъртък"
              value={WeekDay.Thursday}
              labelFirst
            />
          </Card>

          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="daysOfWeek"
              className="d-flex justify-content-between p-4 cursor-pointer"
              label="Петък"
              value={WeekDay.Friday}
              labelFirst
            />
          </Card>

          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="daysOfWeek"
              className="d-flex justify-content-between p-4 cursor-pointer"
              label="Събота"
              value={WeekDay.Saturday}
              labelFirst
            />
          </Card>

          <Card className="rounded w-100">
            <CheckboxControl
              name="daysOfWeek"
              className="d-flex justify-content-between p-4 cursor-pointer"
              label="Неделя"
              value={WeekDay.Sunday}
              labelFirst
            />
          </Card>
        </div>
      </FormGroup>
    </div>
  );
}
