import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { useHiddenConsultUs } from '@/_metronic/layout/components/consult-us/useHiddenConsultUs';
import { Card } from '@/app/components/Card/Card';
import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { Form } from '@/app/components/Form/Form';
import { SearchControl } from '@/app/components/Form/SearchControl/SearchControl';
import { useForm } from '@/app/components/Form/useForm';
import { Content } from '@/app/components/Page/Content/Content';
import { useHiddenFooter } from '@/app/layout/Footer/useHiddenFooter';
import { KEYWORD_SEARCH_PLACEHOLDER, NO_KEYWORDS_FOUND_MESSAGE } from '@/app/modules/course/index/filters/constants';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';

import { CourseIndexPageFilterMobile } from '../../../models';
import { HideFiltersButtonMobile } from './components/HideFiltersButtonMobile';

type KeywordsFilterPageMobileProps = {
  handleFilterToggle: () => void;
  keywords: SubjectKeyword[];
  setCurrentFilter: Dispatch<SetStateAction<CourseIndexPageFilterMobile | null>>;
};

export function KeywordsFilterPageMobile({
  handleFilterToggle,
  keywords,
  setCurrentFilter,
}: KeywordsFilterPageMobileProps) {
  const formikKeywords = useForm({ initialValues: { search: '' } });
  const filteredKeywords = useMemo(() => {
    const search = String(formikKeywords.values.search).toLocaleLowerCase();
    return keywords.filter((x) => x.text.toLocaleLowerCase().includes(search));
  }, [formikKeywords.values.search, keywords]);

  function handleClickClose() {
    handleFilterToggle();
    setCurrentFilter(null);
  }

  function handleClickBack() {
    setCurrentFilter(null);
  }

  useHiddenConsultUs();
  useHiddenFooter();

  return (
    <div className="pb-1">
      <HideFiltersButtonMobile onClickHide={handleClickClose} />

      <div className="d-flex justify-content-center mt-2">
        <button className="btn ps-0 pe-0" onClick={handleClickBack}>
          <i className="far fa-arrow-left text-primary fs-4" />
        </button>
        <span className="w-100 fs-2 d-flex justify-content-center align-items-center">Ключови думи</span>
      </div>

      <div className="flex-wrap justify-content-between mb-n4 overflow-auto">
        <Content className="p-3 ps-0 pe-0">
          <Form formik={formikKeywords} skipDirtyPrompt>
            <SearchControl placeholder={KEYWORD_SEARCH_PLACEHOLDER} />
          </Form>
        </Content>
        <Content className="p-3 ps-0 pe-0">
          {filteredKeywords?.map((keyword) => (
            <Card key={keyword.id} className="rounded w-100 mb-3">
              <CheckboxControl
                name="keywordIds"
                value={keyword.id}
                label={keyword.text}
                className="d-flex align-items-center justify-content-between w-100 p-4 cursor-pointer"
                labelFirst
              />
            </Card>
          ))}
          {filteredKeywords.length === 0 && <p className="mb-0 fw-bold text-center">{NO_KEYWORDS_FOUND_MESSAGE}</p>}
        </Content>
      </div>
    </div>
  );
}
