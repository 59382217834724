import React, { ReactNode } from 'react';

import { DropzoneControl } from '@/app/components/Form/DropzoneControl/DropzoneControl';
import { useDropzoneControl } from '@/app/components/Form/DropzoneControl/useDropzoneControl';
import { DropzoneControlState } from '@/app/components/Form/models';
import {
  MESSENGER_ATTACHMENT_ACCEPT,
  MESSENGER_ATTACHMENT_MAX_FILES,
  MESSENGER_ATTACHMENT_MAX_SIZE,
} from '@/app/modules/messenger/constants';

type MessengerThreadAttachmentProps = {
  name: string;
  children: (dropzoneControlState: DropzoneControlState) => ReactNode;
  className?: string;
};

export function MessengerThreadAttachmentControl({ name, children, className = '' }: MessengerThreadAttachmentProps) {
  const dropzoneControlState = useDropzoneControl({
    name: name,
    accept: MESSENGER_ATTACHMENT_ACCEPT,
    maxFiles: MESSENGER_ATTACHMENT_MAX_FILES,
    maxSize: MESSENGER_ATTACHMENT_MAX_SIZE,
  });

  return (
    <DropzoneControl name={name} dropzoneControlState={dropzoneControlState} className={className} isWholePageDroppable>
      {children(dropzoneControlState)}
    </DropzoneControl>
  );
}
