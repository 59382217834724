import { ReactNode } from 'react';

import './HeaderMenuButtonBadge.scss';

type HeaderMenuButtonBadgeProps = {
  children: ReactNode;
};

export function HeaderMenuButtonBadge({ children }: HeaderMenuButtonBadgeProps) {
  return <span className="header-menu-button-badge position-absolute badge badge-circle badge-danger">{children}</span>;
}
