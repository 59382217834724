import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { ProfilePromoCode } from '@/app/modules/profile/promo-codes/models';

const ENDPOINT_PREFIX = '/ajax/profile/promo-codes';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
};

//#region PROFILE PROMO CODE INDEX
export const getProfilePromoCodes = createAsyncThunk('profilePromoCode/getProfilePromoCodes', async function () {
  const response = await axios.get<GetProfilePromoCodesResponse>(ENDPOINTS.resource);
  return response.data;
});

export interface GetProfilePromoCodesResponse {
  promoCodes: ProfilePromoCode[];
}
//#endregion PROFILE PROMO CODE INDEX
