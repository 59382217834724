import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { ProfilePaymentIndexPage } from '@/app/modules/profile/payments/PaymentIndexPage';

export function ProfilePaymentPage() {
  return (
    <Switch>
      <Route path="/profile/payments" exact>
        <ProfilePaymentIndexPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
