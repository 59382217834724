import { ReactNode } from 'react';

import { Button } from '@/app/components/Button/Button';
import { Card } from '@/app/components/Card/Card';
import { CardFooter } from '@/app/components/Card/CardFooter';
import { Form } from '@/app/components/Form/Form';

import { StepperInstance } from './models';
import { StepperContext } from './StepperContext';

type StepperProps = {
  stepperInstance: StepperInstance;
  nav: ReactNode;
  submitButton: ReactNode;
  children: ReactNode;
};

export function Stepper({ stepperInstance, nav, submitButton, children }: StepperProps) {
  const { currentStep, currentStepInstance, stepCount, isSubmitting, nextStep, previousStep, handleSubmit } =
    stepperInstance;
  const isLastStep = currentStep === stepCount;

  if (stepperInstance.isLoading) {
    return null; //In the future show SKELETON UI
  }

  return (
    <StepperContext.Provider value={stepperInstance}>
      <Form
        formik={currentStepInstance?.formik}
        onSubmit={handleSubmit}
        disabled={isSubmitting}
        className="stepper stepper-pills"
      >
        <div className="stepper-nav flex-nowrap justify-content-start mb-10 overflow-auto">{nav}</div>

        <Card>
          {children}

          <CardFooter className="d-flex justify-content-end gap-3">
            {currentStep !== null && currentStep > 1 && (
              <Button type="button" variant="light-primary" onClick={previousStep} disabled={isSubmitting}>
                Назад
              </Button>
            )}

            {!isLastStep && (
              <Button type="submit" onClick={nextStep} isLoading={isSubmitting}>
                Напред
              </Button>
            )}

            {isLastStep && submitButton}
          </CardFooter>
        </Card>
      </Form>
    </StepperContext.Provider>
  );
}
