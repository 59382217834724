import SVG from 'react-inlinesvg';
import clsx from 'clsx';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { useAppSelector } from '@/redux/store';

import './CoursePageBanner.scss';

export function CoursePageBanner() {
  const { settings } = useAppSelector((state) => state.platformSettings);
  const { coursePageBanner } = settings;
  const isActive = Number(coursePageBanner.isActive) === 1;

  return (
    <div className={clsx('course-banner bg-light-primary', { 'd-none': !isActive })}>
      <SVG src={toAbsoluteUrl('/img/svg/course-page-banner/sparks-up.svg')} className="course-banner__icon-start" />
      <SVG src={toAbsoluteUrl('/img/svg/course-page-banner/sparks-down.svg')} className="course-banner__icon-end" />
      <span className="course-banner__title">{coursePageBanner.title}</span>

      <a href={coursePageBanner.link} className="course-banner__link">
        <span className="course-banner__link-text">{coursePageBanner.buttonText}</span>
        <SVG src={toAbsoluteUrl('/img/svg/course-page-banner/arrow-right.svg')} />
      </a>
    </div>
  );
}
