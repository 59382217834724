import { useState } from 'react';
import { useLocation } from 'react-router';

import { KTSVG } from '@/_metronic/helpers/components/KTSVG';
import { Button } from '@/app/components/Button/Button';
import { TopbarMenuExternalItem } from '@/app/layout/TopbarMenuExternalItem';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformCustomEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagRegistrationEvent } from '@/app/utils/google-analytics/customEvents';
import { useAppSelector } from '@/redux/store';

import { RegistrationModal } from '../modules/auth/RegistrationModal';
import { formatPrice } from '../utils/money';
import { HeaderMessengerMenu } from './HeaderMessengerMenu';
import { HeaderNotificationsMenu } from './HeaderNotificationsMenu';
import { HeaderSupportTicketMenu } from './HeaderSupportTicketMenu';
import { ProfileMenuItem } from './ProfileMenuItem/ProfileMenuItem';
import { TopbarMenuItem } from './TopbarMenuItem';

const COURSE_CREATE_TUTOR_STATUS_IDS = [TutorStatusID.WaitingApproval, TutorStatusID.Approved];

export function Topbar() {
  const location = useLocation();
  const { firstName, profilePicturePath, isAuthenticated, tutorStatusID, balanceAmount, shouldHighlightBalance } =
    useAppSelector((state) => state.auth);
  const canSeeCourseCreateLink = isAuthenticated && COURSE_CREATE_TUTOR_STATUS_IDS.includes(tutorStatusID as number);
  const canSeeBecomeTutorLink = !canSeeCourseCreateLink;
  const canSeeBalanceLink = isAuthenticated && tutorStatusID === TutorStatusID.Approved;
  const [registrationModalIsShown, setRegistrationModalIsShown] = useState<boolean>(false);

  async function handleRegistrationButtonClick() {
    setRegistrationModalIsShown(true);
    const conversionEventValues = await transformCustomEventToConversionEventValues(
      FACEBOOK_PIXEL_CUSTOM_EVENTS.registration
    );
    tagRegistrationEvent(conversionEventValues.eventID);
    await trackAnalyticsEventConversionsAPI(conversionEventValues);
  }

  return (
    <div className="d-flex align-items-center">
      <RegistrationModal isShown={registrationModalIsShown} setIsShown={setRegistrationModalIsShown} />
      <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 my-5 my-lg-0 align-items-center">
        {canSeeBecomeTutorLink && <TopbarMenuItem title="Стани обучител" to="/tutor-home" />}
        {canSeeCourseCreateLink && <TopbarMenuItem title="Създай обучение" to="/courses/create" />}
        {canSeeBalanceLink && (
          <TopbarMenuItem
            key={balanceAmount}
            title={`Баланс: ${formatPrice(balanceAmount)}`}
            to="/profile/profit"
            icon={<KTSVG className="svg-icon-1 svg-icon-success me-2" path="/img/svg/icons/Shopping/Money.svg" />}
            isPulsing={shouldHighlightBalance}
          ></TopbarMenuItem>
        )}
        {!isAuthenticated && (
          <>
            <Button
              as="a"
              variant="light-primary"
              href={`/auth?from=${encodeURIComponent(location.pathname)}`}
              className="mx-lg-1 py-3 px-4 fs-base flex-shrink-0"
            >
              Вход
            </Button>

            <Button
              variant="primary"
              className="mx-lg-1 py-3 px-4 fs-base flex-shrink-0"
              onClick={handleRegistrationButtonClick}
            >
              Регистрация
            </Button>
          </>
        )}

        {isAuthenticated && (
          <>
            <HeaderSupportTicketMenu />
            <HeaderNotificationsMenu />
            <HeaderMessengerMenu />
            <ProfileMenuItem firstName={firstName} profilePicturePath={profilePicturePath} />

            <a
              href="/auth/logout"
              className="btn btn-icon btn-icon-gray-700 btn-active-light-primary flex-shrink-0 ms-lg-1"
            >
              <span className="svg-icon svg-icon-1">
                <KTSVG className="svg-icon-1" path="/img/svg/icons/Navigation/Sign-out.svg" />
              </span>
            </a>
          </>
        )}
      </div>
    </div>
  );
}
