import { PaginationOptions } from 'react-bootstrap-table-next';
import { PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';

import { Pagination } from '@/app/components/Pagination/Pagination';

import { paginationTotalRenderer } from './utils';

type TablePaginationProps = {
  isLoading: boolean;
  paginationProps: PaginationOptions;
};

export function TablePagination({ isLoading, paginationProps }: TablePaginationProps) {
  const { totalSize, sizePerPage, page, onPageChange, paginationSize } = paginationProps;

  function handlePageChange(page: number) {
    if (sizePerPage !== undefined) {
      onPageChange?.(page, sizePerPage);
    }
  }

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <PaginationTotalStandalone {...paginationProps} paginationTotalRenderer={paginationTotalRenderer} />

      <Pagination
        total={totalSize ?? 0}
        perPage={sizePerPage ?? 0}
        currentPage={page ?? 1}
        onChange={handlePageChange}
        isLoading={isLoading}
        shownPagesCount={paginationSize}
      />
    </div>
  );
}
