import axios from 'axios';

import { CourseSearchHistoryFormValues } from '@/app/modules/course-search-history/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

const COURSE_SEARCH_HISTORY_ENDPOINT = '/ajax/course-search-history';

export async function createCourseSearchHistory(values: CourseSearchHistoryFormValues) {
  const formData = serializeFormData(values);
  const response = await axios.post(COURSE_SEARCH_HISTORY_ENDPOINT, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
