import { Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { LinkButton } from '@/app/components/Button/LinkButton';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { TutorHelp } from '@/app/modules/home/components/TutorHelp';

import './TutorHomePage.scss';

export function TutorHomePage() {
  return (
    <>
      <Helmet>
        <title>Стани обучител</title>
        <meta name="keywords" content="Стани обучеител, Преподавай онлайн" />
        <meta
          name="description"
          content="Стани обучеител. Обучавай онлайн, достигни до хиляди ученици, в удобно за теб време."
        />
      </Helmet>
      <Page className="p-0 bg-white">
        <div className="tutor-header py-10 py-md-0 px-0 px-md-2 mb-20">
          <Row className="mx-auto h-100 align-items-center">
            <Col xs={12} md={7}>
              <div className="mx-auto tutor-header__left-banner">
                <div>
                  <Row>
                    <h1 className="text-center text-md-start mb-3 mb-md-15 text-gray-800 fs-1x fs-md-2x fs-lg-3x">
                      Обучавай онлайн, достигни до хиляди ученици, в удобно за теб време.
                    </h1>
                  </Row>
                  <div className="d-flex mx-auto justify-content-center justify-content-md-start align-items-center">
                    <Row>
                      <Col sm={12} md={7}>
                        <div className="d-flex h-100 justify-content-center justify-content-md-start align-items-center text-nowrap mb-3 me-md-7">
                          <LinkButton to="/tutor/become" variant="primary" className="d-flex align-items-center">
                            Създай обучителски профил
                            <i className="fas fa-arrow-right ms-2 text-white" />
                          </LinkButton>
                        </div>
                      </Col>
                      <Col sm={12} md={5}>
                        <div className="d-flex justify-content-center justify-content-md-start h-100 align-items-center text-nowrap">
                          <h5 className="text-gray-600 fw-normal mb-0">(Напълно безплатно)</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={5}>
              <div className="ms-5 d-flex justify-content-center">
                <SVG src={toAbsoluteUrl('/img/svg/home-pages/0.svg')} />
              </div>
            </Col>
          </Row>
        </div>
        <Content className="mb-15">
          <PageTitle className="mb-10 fw-bolder fs-2x text-center">Защо да обучаваш в платформата</PageTitle>
          <Row className="py-9 mb-9 card-wrapper rounded border border-secondary mx-auto align-items-center">
            <Col md="6">
              <div className="ms-5 me-5">
                <h2 className="text-gray-700">График в реално време</h2>
                <p className="text-gray-600">Организирайте всичките си уроци лесно, на едно място.</p>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex overflow-hidden me-5">
                <picture>
                  <source
                    srcSet={toAbsoluteUrl('/img/home-pages/tutor/realTimeSchedule.webp?v=1.0.0')}
                    type="image/webp"
                  />
                  <img
                    src={toAbsoluteUrl('/img/home-pages/tutor/realTimeSchedule.png?v=1.0.0')}
                    className="img-fluid"
                    width="400"
                    height="240"
                    alt="График в реално време"
                  />
                </picture>
              </div>
            </Col>
          </Row>
          <Row className="py-9 mb-9 card-wrapper rounded border border-secondary mx-auto align-items-center">
            <Col md="6">
              <div className="d-flex overflow-hidden ms-5 me-5">
                <picture>
                  <source srcSet={toAbsoluteUrl('/img/home-pages/tutor/virtualClassRoom.webp')} type="image/webp" />
                  <img
                    src={toAbsoluteUrl('/img/home-pages/tutor/virtualClassRoom.png')}
                    className="img-fluid"
                    width="400"
                    height="240"
                    alt="Виртуална класна стая"
                  />
                </picture>
              </div>
            </Col>
            <Col md="6">
              <div className="ms-5">
                <h2 className="text-gray-700">Виртуална класна стая</h2>
                <p className="text-gray-600">
                  Интегрирано решение: Видео и аудио връзка, както и функционалности за улеснение на различните
                  предмети.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="py-9 mb-9 card-wrapper rounded border border-secondary mx-auto align-items-center">
            <Col md="6">
              <div className="ms-5 me-5">
                <h2 className="text-gray-700">Свобода в ценообразуването</h2>
                <p className="text-gray-600">Сами определяте цената на един урок, както и неговата продължителност.</p>
                <h3 className="text-gray-700">Индивидуални уроци</h3>
                <p className="text-gray-600">Уроци, насочени към индивидуалните нужди и предпочитания на обучаемия.</p>
                <h3 className="text-gray-700">Пакетни уроци</h3>
                <p className="text-gray-600">
                  Можете лесно да изградите дългосрочна ангажираност на ученика, като предоставите възможност да се
                  закупи серия уроци, подходяща за учебните нужди.
                </p>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex overflow-hidden ms-5 me-5">
                <picture>
                  <source
                    srcSet={toAbsoluteUrl('/img/home-pages/tutor/pricesAndPackagesWithCommission.webp')}
                    type="image/webp"
                  />
                  <img
                    src={toAbsoluteUrl('/img/home-pages/tutor/pricesAndPackagesWithCommission.png')}
                    className="img-fluid"
                    width="399"
                    height="357"
                    alt="Свобода в ценообразуването"
                  />
                </picture>
              </div>
            </Col>
          </Row>
          <TutorHelp />
          <Card className="p-6 px-md-10 pt-md-15 pb-md-1 mt-20 bg-primary action-banner">
            <div className="d-flex justify-content-center justify-content-md-between flex-column flex-md-row align-items-center flex-wrap">
              <div className="d-flex text-center text-md-start mb-2 mb-md-0">
                <h2 className="mb-5 mb-md-0 text-white fw-normal fs-2x action-banner__title">
                  Готов ли си да влезеш в света на знанията?
                </h2>
              </div>
              <div className="d-flex text-nowrap">
                <LinkButton to="/tutor/become" variant="white" className="btn-sm text-primary">
                  Създай обучителски профил
                  <i className="fas fa-arrow-right ms-2 text-primary" />
                </LinkButton>
              </div>
            </div>
            <div className="d-flex justify-content-center justify-content-md-end me-0 me-md-13">
              <h6 className="mt-2 mb-0 mb-md-7 fs-7 fw-normal text-white">(Напълно безплатно)</h6>
            </div>
          </Card>
        </Content>
      </Page>
    </>
  );
}
