import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  DestroySlotValues,
  HolidaysResponse,
  StoreRecurringTimeSlotValues,
  UpdateBlockNewStudentsReservations,
  UpdateScheduleDurationValues,
  UpdateScheduleReservationBufferHoursValues,
  UpdateSlotValues,
} from '@/app/modules/schedule/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

const ENDPOINT_PREFIX = '/ajax/schedule';
export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  update: `${ENDPOINT_PREFIX}/update`,
  duration: `${ENDPOINT_PREFIX}/duration`,
  reservationBufferHours: `${ENDPOINT_PREFIX}/reservation-buffer-hours`,
  blockNewStudentsReservations: `${ENDPOINT_PREFIX}/block-new-students-reservations`,
  holidayTimeSlots: `${ENDPOINT_PREFIX}/holidays`,
  entity: (id: number) => `${ENDPOINT_PREFIX}/${id}`,
};

export async function storeRecurringTimeSlot(values: StoreRecurringTimeSlotValues) {
  const formData = serializeFormData(values);
  const response = await axios.post(ENDPOINTS.resource, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export async function updateSlot(values: UpdateSlotValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.update, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export async function destroySlot(slotID: number, values: DestroySlotValues) {
  const response = await axios.delete(ENDPOINTS.entity(slotID), {
    data: values,
  });

  return response.data;
}

export async function updateScheduleDuration(values: UpdateScheduleDurationValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.duration, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export async function updateScheduleReservationBufferHours(values: UpdateScheduleReservationBufferHoursValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.reservationBufferHours, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export async function updateBlockNewStudentsReservations(values: UpdateBlockNewStudentsReservations) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.blockNewStudentsReservations, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export const getHolidays = createAsyncThunk('mySchedule/getHolidays', async () => {
  const response = await axios.get<HolidaysResponse>(ENDPOINTS.holidayTimeSlots);

  return response.data;
});
