import { MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import * as H from 'history';

import './HeaderMenuContentItem.scss';

type HeaderMenuContentItemProps<S> = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  children: ReactNode;
  createdAt: string;
  onClick?: MouseEventHandler;
  isRead?: boolean;
};

export function HeaderMenuContentItem<S>({ to, children, onClick, isRead = false }: HeaderMenuContentItemProps<S>) {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={clsx('header-menu-content-item d-flex p-5 text-black border-bottom bg-hover-gray-200', {
        'bg-light pe-9': !isRead,
      })}
    >
      <div className="position-relative d-flex align-items-center w-100">
        {children}

        {!isRead && (
          <span className="d-block w-10px h-10px bg-primary rounded position-absolute top-0 end-0 me-n4 mt-1" />
        )}
      </div>
    </Link>
  );
}
