import React, { useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { FormikProvider } from 'formik';
import * as yup from 'yup';

import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { useForm } from '@/app/components/Form/useForm';
import { TimelineIndexFilterInitialValues } from '@/app/components/Timeline/TimelineIndexFilters/constants';
import {
  TimelineIndexFiltersQueryParams,
  TimelineIndexFiltersValues,
} from '@/app/components/Timeline/TimelineIndexFilters/models';
import { transformTimelineIndexFormikValuesToSearchParams } from '@/app/components/Timeline/TimelineIndexFilters/utils';
import { useQueryParams } from '@/app/utils/query';

export function TimelineIndexFilters() {
  const location = useLocation();
  const history = useHistory();
  const { currentSearchParams, queryParams } = useQueryParams<TimelineIndexFiltersQueryParams>();

  //#region Handle filter form
  const initialValues = useMemo<TimelineIndexFiltersValues>(
    () => ({
      search: queryParams.search ?? TimelineIndexFilterInitialValues.search,
    }),
    [queryParams.search]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<TimelineIndexFiltersValues>({
    initialValues,
    validationSchema,
    onSubmit() {
      formik.setSubmitting(false);
    },
  });

  function clearFilters() {
    formik.setValues(TimelineIndexFilterInitialValues);
  }
  //#endregion Handle filter form

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformTimelineIndexFormikValuesToSearchParams(formik.values, queryParams);

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
      }

      if (newSearchParams === '') {
        formik.setValues(TimelineIndexFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  return (
    <FormikProvider value={formik}>
      <div className="d-flex flex-wrap justify-content-end align-items-center w-100 mb-n4">
        <FormGroup controlId="search" className="d-flex align-items-center mb-4 me-4">
          <FormControl
            name="search"
            type="text"
            placeholder="Търси"
            value={formik.values.search}
            hasSolidBackground={false}
            className="border-0"
          />
        </FormGroup>

        <Button onClick={clearFilters} variant="light-danger" className="align-self-start">
          <i className="fas fa-times p-0" />
        </Button>
      </div>
    </FormikProvider>
  );
}
