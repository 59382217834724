import { useEffect, useMemo, useState } from 'react';

import { useHiddenConsultUs } from '@/_metronic/layout/components/consult-us/useHiddenConsultUs';
import { Form } from '@/app/components/Form/Form';
import { SearchControl } from '@/app/components/Form/SearchControl/SearchControl';
import { useForm } from '@/app/components/Form/useForm';
import { Content } from '@/app/components/Page/Content/Content';
import { useHiddenFooter } from '@/app/layout/Footer/useHiddenFooter';
import { SubjectItemMobile } from '@/app/modules/subject/index/SubjectItem/SubjectItemMobile';
import { LoadingState } from '@/redux/constants';

import { Subject } from '../../../models';
import {
  ALL_SUBJECTS,
  NO_SUBJECTS_FOUND_MESSAGE,
  SUBJECT_SEARCH_PLACEHOLDER,
  TRANSLITERATED_SLUG_ALL_SUBJECTS,
} from '../constants';
import { HideFiltersButtonMobile } from './components/HideFiltersButtonMobile';

type SubjectsIndexPageFilterMobileProps = {
  loadingState: LoadingState;
  subjects: Subject[];
  hideFilters: () => void;
  handleAllFiltersToggle: () => void;
};

export function SubjectsIndexPageFilterMobile({
  loadingState,
  subjects,
  hideFilters,
  handleAllFiltersToggle,
}: SubjectsIndexPageFilterMobileProps) {
  const formikSubjects = useForm({ initialValues: { search: '' } });
  const filteredSubjects = useMemo(() => {
    const search = String(formikSubjects.values.search).toLocaleLowerCase();
    return subjects.filter((x) => x.text.toLocaleLowerCase().includes(search));
  }, [formikSubjects.values.search, subjects]);
  const [isEmpty, setIsEmpty] = useState(false);

  function handleClickBack() {
    hideFilters();
    handleAllFiltersToggle();
  }

  useHiddenConsultUs();
  useHiddenFooter();

  useEffect(
    () => {
      setIsEmpty(loadingState === LoadingState.Idle && filteredSubjects.length === 0);
    },
    [filteredSubjects] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className="pb-1">
      <HideFiltersButtonMobile onClickHide={hideFilters} />

      <div className="d-flex justify-content-center mt-2">
        <button className="btn ps-0 pe-0" onClick={handleClickBack}>
          <i className="far fa-arrow-left text-primary target-audience-icon fs-4" />
        </button>
        <span className="w-100 fs-2 d-flex justify-content-center align-items-center">Предмет</span>
      </div>

      <div className="flex-wrap justify-content-between mb-n4 overflow-auto">
        <Content className="p-3 ps-0 pe-0">
          <Form formik={formikSubjects} skipDirtyPrompt>
            <SearchControl placeholder={SUBJECT_SEARCH_PLACEHOLDER} />
          </Form>
        </Content>
        <Content className="p-3 ps-0 pe-0">
          <SubjectItemMobile
            subject={{
              id: -1,
              text: ALL_SUBJECTS,
              transliteratedSlug: TRANSLITERATED_SLUG_ALL_SUBJECTS,
              imagePath: null,
              targetAudiencesIDs: [],
            }}
            onClick={() => hideFilters()}
          />
          {filteredSubjects?.map((subject) => (
            <SubjectItemMobile key={subject.id} subject={subject} onClick={() => hideFilters()} />
          ))}

          {isEmpty && <p className="mb-0 fw-bold text-center">{NO_SUBJECTS_FOUND_MESSAGE}</p>}
        </Content>
      </div>
    </div>
  );
}
