// eslint-disable-next-line import/no-duplicates
import { format, parse } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { bg } from 'date-fns/locale';

import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { Reservation } from '@/app/models/Reservation';
import { createReservationWithTime, ReservationWithTime } from '@/app/modules/reservation-group/models';

export function transformReservationToReservationWithTime(reservation: Reservation): ReservationWithTime {
  const startDateTime = parse(reservation.startDateTime, DEFAULT_DATETIME_FORMAT, new Date());
  const endDateTime = parse(reservation.endDateTime, DEFAULT_DATETIME_FORMAT, new Date());
  const date = format(startDateTime, 'dd LLLL yyyy', { locale: bg });
  const dayOfWeek = format(startDateTime, 'cccc', { locale: bg });

  return createReservationWithTime(
    reservation.id,
    date,
    dayOfWeek,
    startDateTime,
    endDateTime,
    reservation.reservationStatusID
  );
}
