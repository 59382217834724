import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Alert } from '@/app/components/Alert/Alert';
import { AlertBody } from '@/app/components/Alert/AlertBody';
import { AlertIcon } from '@/app/components/Alert/AlertIcon';
import { Card } from '@/app/components/Card/Card';
import { BasePageProps } from '@/app/components/Page/models';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { TabItem } from '@/app/components/Tabs/TabItem';
import { TabNav } from '@/app/components/Tabs/TabNav';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { getTutorStatus } from '@/app/modules/auth/service';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

import { StickyHeader } from '../../StickyHeader';

type ProfileInvoicesShowPageBaseProps = BasePageProps & {
  tabNav?: ReactNode;
};

export function TutorDetailsShowPageBase({ title, actions, children }: ProfileInvoicesShowPageBaseProps) {
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);
  const tutorLoading = useAppSelector((state) => state.auth.tutorLoading);
  const dispatch = useDispatch();

  useEffect(function componentDidMount() {
    if (tutorLoading === LoadingState.Idle) {
      dispatch(getTutorStatus());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StickyHeader className="pt-5" containerClassName="mb-7">
        <PageTop className="mb-5">
          <PageTitle className="my-2">{title}</PageTitle>
          <PageActions>{actions}</PageActions>
        </PageTop>
        <TabNav>
          <TabItem to="/profile/details">Лични данни</TabItem>
          <TabItem to="/profile/about">За мен</TabItem>
          <TabItem to="/profile/bank-details">Банкова сметка</TabItem>
          <TabItem to="/profile/invoice-details">Фактуриране</TabItem>
        </TabNav>
      </StickyHeader>

      {tutorStatusID === TutorStatusID.WaitingApproval && (
        <Alert className="mb-7" variant="primary">
          <AlertIcon path="/img/svg/icons/Home/Timer.svg" variant="primary" />
          <AlertBody>
            Профилът Ви чака одобрение от служител на Школо. Докато преглеждаме профила Ви, можете да правите корекции
            при нужда.
          </AlertBody>
        </Alert>
      )}

      {tutorStatusID === TutorStatusID.Rejected && (
        <Alert className="mb-7 mt-7" variant="danger">
          <AlertIcon path="/img/svg/icons/Code/Stop.svg" variant="danger" />
          <AlertBody>
            <div>
              Вашата заявка е отказана. Можете да редактирате профила си и да изпратите отново за одобрение. Изпращането
              за одобрение можете да направите от
              <Link to="/tutor/become" className="ms-1">
                тук
              </Link>
              .
            </div>
          </AlertBody>
        </Alert>
      )}

      <Card>{children}</Card>
    </>
  );
}
