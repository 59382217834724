import React from 'react';

import './TimelineItem.scss';

type TimelineItemProps = {
  children: React.ReactNode;
};

export function TimelineItem({ children }: TimelineItemProps) {
  return <div className="timeline-item">{children} </div>;
}
