type SecurityTextProps = {
  className?: string;
};

export function SecurityText({ className }: SecurityTextProps) {
  return (
    <div className={className}>
      <i className="me-2 fas fa-lock-alt" />
      Всички Ваши данни са криптирани и преминават през SHA-256 протокол за сигурност.{' '}
      <a
        className="text-decoration-underline"
        href="https://support.google.com/google-ads/answer/9004655?hl=bg"
        target="_blank"
        rel="noopener noreferrer"
      >
        Разберете повече
      </a>
    </div>
  );
}
