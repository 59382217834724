import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@/app/components/Button/Button';
import { LinkButton } from '@/app/components/Button/LinkButton';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import {
  KEYWORD_COURSE_CARD_MAX_LENGTH,
  KEYWORD_COURSE_CARD_MIN_LENGTH,
} from '@/app/modules/subject-keywords/constants';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';
import { formatInfoRating } from '@/app/utils/formatInfoRating';
import { formatPrice } from '@/app/utils/money';
import { useAppSelector } from '@/redux/store';

import { MAX_RATING } from '../../course/constants';
import { handlePlDeprecatedPurchaseAction } from '../../course/utils';
import { CourseDetails, CourseItemSubject } from '../models';

import './TutorShowPage.scss';

type CourseItemProps = {
  course: CourseDetails;
  tutorName: string;
  tutorIdentityID: number | null;
  hasAvailableFutureTimeSlots?: boolean;
};

export function CourseItem({
  course,
  tutorName,
  tutorIdentityID,
  hasAvailableFutureTimeSlots = true,
}: CourseItemProps) {
  const identityID = useAppSelector((state) => state.auth.identityID);
  const showBuyLessonButton = identityID !== tutorIdentityID;
  const [showAllKeywords, setShowAllKeywords] = useState(false);

  const courseDetailsURL = `/courses/${course.transliteratedSlug}`;
  const courseCheckoutURL = `/courses/${course?.transliteratedSlug}/checkout`;

  function handleKeywordsExpandToggle(e: React.MouseEvent) {
    e.preventDefault();
    setShowAllKeywords(!showAllKeywords);
  }

  return (
    <Card className="p-0">
      <CardBody className="d-flex flex-column flex-sm-row justify-content-between">
        <Link to={courseDetailsURL} className="d-flex flex-column flex-grow-1 w-100 w-md-650px me-2 mb-2">
          <h3>{course.name}</h3>
          <div>
            <span className="badge bg-gray-200 text-dark mb-2 me-2">
              {formatInfoRating(course.currentRating)} / {MAX_RATING}
            </span>
            <span className="badge bg-gray-200 text-dark mb-2 me-2">{course.targetAudienceName}</span>
            {course.subjects?.map((subject: CourseItemSubject, index: number) => (
              <span key={index} className="badge bg-gray-200 text-dark mb-2">
                {subject.subjectName}
              </span>
            ))}
          </div>

          <div>
            {course?.keywords
              .filter(
                (keyword: SubjectKeyword, index: number) =>
                  index < (showAllKeywords ? KEYWORD_COURSE_CARD_MAX_LENGTH : KEYWORD_COURSE_CARD_MIN_LENGTH)
              )
              .map((keyword) => {
                return (
                  <span key={keyword.id} className="badge badge-light-primary me-2 mb-2">
                    {keyword.text}
                  </span>
                );
              })}

            {course?.keywords.length > KEYWORD_COURSE_CARD_MIN_LENGTH && (
              <span className="badge badge-light-primary mb-2 fs-8" onClick={handleKeywordsExpandToggle}>
                {showAllKeywords ? '-' : '+'}
              </span>
            )}
          </div>
          <h4 className="fw-bold">{tutorName}</h4>
        </Link>

        {hasAvailableFutureTimeSlots && (
          <Link
            to="#"
            className="d-flex flex-column justify-content-center mx-auto mx-sm-0 w-100 w-md-200px"
            onClick={handlePlDeprecatedPurchaseAction}
          >
            {showBuyLessonButton && (
              <Button className="mb-4" variant="primary" size="lg" disabled={!hasAvailableFutureTimeSlots}>
                Закупи
              </Button>
            )}
            <div className="text-center">
              <p className="m-0 text-black">
                от <strong className="fs-3">{formatPrice(course.startingPrice)}</strong>
              </p>
              <p className="m-0 text-gray-500">(за {course.lessonDuration} мин.)</p>
            </div>
          </Link>
        )}

        {!hasAvailableFutureTimeSlots && showBuyLessonButton && (
          <div className="d-flex flex-column justify-content-center mx-auto mx-sm-0 w-100 w-md-200px">
            <p className="text-center mx-auto text-black fs-7 mw-150px">Няма свободни часове за това обучение.</p>
            <LinkButton
              className="btn btn-sm"
              variant="light-primary"
              to={`/courses/${course.id}/reservation/free-student-proposal`}
            >
              Изпратете запитване за час
            </LinkButton>
          </div>
        )}
      </CardBody>
    </Card>
  );
}
