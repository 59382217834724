import { useMemo } from 'react';
import { useFormikContext } from 'formik';

import { ExceptionalFormikKeys } from '@/app/modules/course/constants';
import { CourseIndexFilterValues, LabelItem } from '@/app/modules/course/models';
import { transformCourseIndexFormikValuesToLabels } from '@/app/modules/course/utils';
import { useAppSelector } from '@/redux/store';

import { ALL_SUBJECTS } from '../../constants';

import './LabelsFilters.scss';

type LabelsFiltersProps = {
  setIsModalShown: React.Dispatch<React.SetStateAction<boolean>>;
};

export function LabelsFilters({ setIsModalShown }: LabelsFiltersProps) {
  const formik = useFormikContext<CourseIndexFilterValues>();
  const store = useAppSelector((state) => state.course);
  const subjectName = store.index.subjectName ?? ALL_SUBJECTS;
  const targetAudiences = store.index.allTargetAudiences;
  const subjectKeywords = store.index.allSubjectKeywords;

  // Memoize the labels using useMemo to avoid unnecessary re-computations
  const labels = useMemo(() => {
    return transformCourseIndexFormikValuesToLabels(formik.values, subjectName, targetAudiences, subjectKeywords);
  }, [formik.values, subjectName, targetAudiences, subjectKeywords]);

  // Remove a label based on the provided label key
  function removeLabel(labelKey: string) {
    switch (labelKey) {
      case ExceptionalFormikKeys.subjectName:
        setIsModalShown(true);
        return;
      case ExceptionalFormikKeys.fromDate:
        formik.setFieldValue(ExceptionalFormikKeys.fromDate, undefined);
        return;
      case ExceptionalFormikKeys.toDate:
        formik.setFieldValue(ExceptionalFormikKeys.toDate, undefined);
        return;
      case ExceptionalFormikKeys.hasFutureTimeSlots:
        formik.setFieldValue(ExceptionalFormikKeys.hasFutureTimeSlots, 0);
        return;
    }

    const entry = Object.entries(formik.values).find(([, value]) => {
      if (Array.isArray(value)) {
        return value.includes(labelKey);
      }

      return value === labelKey;
    });

    if (entry) {
      const key = entry[0];

      for (const formikKey in formik.values) {
        const formikValue = formik.values[formikKey];

        if (formikKey === key) {
          if (Array.isArray(formikValue)) {
            const newArrayValues = formikValue.filter((value) => value !== labelKey);
            formik.setFieldValue(formikKey, newArrayValues);
          } else {
            formik.setFieldValue(formikKey, '');
          }
        }
      }
    }
  }

  // Handle the click event when closing a label
  function onCloseClick(label: LabelItem) {
    const key = Object.keys(label)[0];
    removeLabel(key);
  }

  return (
    <ul className="tags-list">
      {labels.map((label, index) => (
        <li key={index} className="tag">
          <span className="tag-title">{Object.values(label)[0]}</span>
          {Object.values(label)[0] !== ALL_SUBJECTS && (
            <i onClick={() => onCloseClick(label)} className="fa fa-close tag-close-icon" />
          )}
        </li>
      ))}
    </ul>
  );
}
