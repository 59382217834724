import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { parse } from 'date-fns';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { getIntervalFormat } from '@/app/components/Calendar/utils';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardFooter } from '@/app/components/Card/CardFooter';
import { CardSection } from '@/app/components/Card/CardSection';
import { CardSectionContent } from '@/app/components/Card/CardSectionContent';
import { CardSectionDescription } from '@/app/components/Card/CardSectionDescription';
import { CardSectionTitle } from '@/app/components/Card/CardSectionTitle';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { Feedback } from '@/app/components/Feedback/Feedback';
import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { TextareaControl } from '@/app/components/Form/TextareaControl/TextareaControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformEcommerceEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { getAvailableEnrolledCoursesCount } from '@/app/modules/auth/service';
import { CourseDetailsCard } from '@/app/modules/course/components/CourseDetailsCard';
import { ConnectedStudentFormControl } from '@/app/modules/course/forms/ConnectedStudentFormControl';
import { ConnectedStudentSelectControl } from '@/app/modules/course/forms/ConnectedStudentSelectControl';
import { formatDateFromDateTimeFormat, formatWeekDayFromDateTimeFormat } from '@/app/modules/my-schedule/utils';
import { ConnectedStudentValues } from '@/app/modules/profile/connected-student/models';
import {
  ReservationProposalApprovalValues,
  ReservationProposalQueryParams,
} from '@/app/modules/reservation-proposal/models';
import {
  approveReservationProposal,
  ApproveReservationProposalResponse,
  getReservationProposalData,
} from '@/app/modules/reservation-proposal/service';
import { getEcommerceItemFromFreeReservationProposal } from '@/app/modules/reservation-proposal/utils';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagNewCustomerEvent } from '@/app/utils/google-analytics/customEvents';
import { useQueryParams } from '@/app/utils/query';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function ReservationProposalApprovalPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { queryParams } = useQueryParams<ReservationProposalQueryParams>();
  const { id } = useParams<{ id: string }>();

  //#region Handle data
  const loading = useAppSelector((state) => state.reservationProposal.loading);
  const data = useAppSelector((state) => state.reservationProposal.reservationProposal);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getReservationProposalData(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  //#region Handle form
  const initialValues = useMemo<ReservationProposalApprovalValues>(() => {
    const values = {
      timeSlotID: Number(data.reservation?.timeSlotID) ?? null,
      connectedStudentID: data.connectedStudents[0]?.id ?? -1,
      connectedStudentName: data.defaultConnectedStudent?.name ?? '',
      connectedStudentPhone: data.defaultConnectedStudent?.phone ?? '',
      connectedStudentEmail: data.defaultConnectedStudent?.email ?? '',
      connectedStudentLevelID: data.defaultConnectedStudent?.connectedStudentLevelID ?? null,
      expectations: '',
    };

    if (data.connectedStudents[0]?.id) {
      values['connectedStudentName'] = data.connectedStudents[0]?.name ?? '';
      values['connectedStudentPhone'] = data.connectedStudents[0]?.phone ?? '';
      values['connectedStudentEmail'] = data.connectedStudents[0]?.email ?? '';
      values['connectedStudentLevelID'] = data.connectedStudents[0]?.connectedStudentLevelID ?? null;
    }

    return values;
  }, [data]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        timeSlotID: yup.number().required(),
        connectedStudentID: yup.mixed().required('Избор на обучаем е задължително.'),
        connectedStudentName: yup.string().required().max(192),
        connectedStudentPhone: yup.mixed().required().phone(),
        connectedStudentEmail: yup.string().required().email(),
        connectedStudentLevelID: yup.string().required(),
        expectations: yup.string().when('connectedStudentID', {
          is: (value: string) => data.reservationConnectedStudentIds.includes(Number(value)),
          then: yup.string().max(2000),
          otherwise: yup.string().required().max(2000),
        }),
      }),
    [data.reservationConnectedStudentIds]
  );

  const formik = useForm<ReservationProposalApprovalValues>({
    initialValues,
    validationSchema,
    initialStatus: loading,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<ReservationProposalApprovalValues>(async (values) => {
        return await approveReservationProposal(Number(id), values);
      });

      const response = (await submitWithValidation(values, formikHelpers)) as ApproveReservationProposalResponse;
      if (response) {
        await dispatch(getAvailableEnrolledCoursesCount());

        formik.resetForm({ values });

        if (response.isStudentsFirstOrder && response.freeReservationProposalOrderID) {
          const ecommerceItem = getEcommerceItemFromFreeReservationProposal(data);
          const newCustomerConversionEventValues = await transformEcommerceEventToConversionEventValues(
            FACEBOOK_PIXEL_CUSTOM_EVENTS.newCustomer,
            ecommerceItem
          );
          tagNewCustomerEvent(
            ecommerceItem,
            response.freeReservationProposalOrderID,
            newCustomerConversionEventValues.eventID
          );
          await trackAnalyticsEventConversionsAPI(newCustomerConversionEventValues);
        }

        const orderID = Number(queryParams.orderID) ?? null;

        if (orderID) {
          history.push(`/reservation-proposals/${response.reservationID}/summary?orderID=${orderID}`);
          return;
        }
        history.push(`/reservation-proposals/${response.reservationID}/summary`);
      }
    },
  });
  //#endregion Handle form

  const expectationsFieldRequired = !data.reservationConnectedStudentIds.includes(
    Number(formik?.values?.connectedStudentID)
  );

  useEffect(
    function handleConnectedStudentChange() {
      if (data.connectedStudents.length > 0) {
        const connectedStudent = data.connectedStudents.find(
          (student: ConnectedStudentValues) => student.id == formik.values.connectedStudentID
        );
        if (connectedStudent) {
          setConnectedStudentValues(
            connectedStudent.name,
            connectedStudent.phone,
            connectedStudent.email,
            connectedStudent.connectedStudentLevelID
          );
        } else {
          setConnectedStudentValues();
        }
      }
      formik.setErrors({});
    },
    [formik.values.connectedStudentID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function setConnectedStudentValues(name = '', phone = '', email = '', connectedStudentLevelID: number | null = null) {
    formik.setFieldValue('connectedStudentName', name);
    formik.setFieldValue('connectedStudentPhone', phone);
    formik.setFieldValue('connectedStudentEmail', email);
    formik.setFieldValue('connectedStudentLevelID', connectedStudentLevelID);
  }

  return (
    <>
      <Helmet>
        <title>Резервирайте час</title>
      </Helmet>
      <Page
        renderContent={(props) => (
          <Form
            {...props}
            formik={formik}
            disabled={formik.isSubmitting}
            onSubmit={handleFrontEndValidations(formik)}
          />
        )}
      >
        <Content>
          <PageTitle className="mb-9 fw-bolder fs-2x text-center">Одобрете и резервирайте час</PageTitle>

          <Card>
            <CardBody>
              <CardSection>
                <CardSectionDescription>
                  <CardSectionTitle>Обучение</CardSectionTitle>
                </CardSectionDescription>
                <CardSectionContent>
                  <CourseDetailsCard course={data.course} />
                </CardSectionContent>
              </CardSection>
              <CardSection>
                <CardSectionDescription>
                  <CardSectionTitle>Обучаем</CardSectionTitle>
                  <CardSubTitle>
                    Попълнете информация за обучаемия, за да може обучителят да се подготви за предстоящия урок
                  </CardSubTitle>
                </CardSectionDescription>
                <CardSectionContent>
                  <ConnectedStudentSelectControl
                    connectedStudents={data.connectedStudents}
                    connectedStudentLevels={data.connectedStudentLevels}
                  />
                  <ConnectedStudentFormControl
                    connectedStudents={data.connectedStudents}
                    connectedStudentLevels={data.connectedStudentLevels}
                  />
                  {Boolean(formik.errors.connectedStudentID) && (
                    <Feedback type="invalid">{formik.errors.connectedStudentID}</Feedback>
                  )}
                  <FormGroup controlId="expectations">
                    <Label isRequired={expectationsFieldRequired}>Очаквания и коментари</Label>
                    <TextareaControl name="expectations" rows={3} />
                  </FormGroup>
                </CardSectionContent>
              </CardSection>

              <CardSection>
                <CardSectionDescription>
                  <h2>Избрани часове</h2>
                </CardSectionDescription>

                {data?.reservation && (
                  <CardSectionContent>
                    <div className="reservation-group-item border-bottom text-danger">
                      <h3 className="fw-normal">
                        {formatDateFromDateTimeFormat(data?.reservation?.startDateTime as string)}
                        <span className="opacity-50">
                          {' '}
                          ({formatWeekDayFromDateTimeFormat(data?.reservation?.startDateTime as string)})
                        </span>
                      </h3>
                      <h4 className="mb-0 fw-normal">
                        {getIntervalFormat(
                          parse(data?.reservation?.startDateTime as string, DEFAULT_DATETIME_FORMAT, new Date()),
                          parse(data?.reservation?.endDateTime as string, DEFAULT_DATETIME_FORMAT, new Date())
                        )}
                      </h4>
                      {Boolean(formik.errors.timeSlotID) && (
                        <Feedback type="invalid">{formik.errors.timeSlotID}</Feedback>
                      )}
                    </div>
                  </CardSectionContent>
                )}
              </CardSection>
            </CardBody>

            <CardFooter className="d-flex justify-content-end gap-3">
              <Button type="submit" isLoading={formik.isSubmitting}>
                Одобри и резервирай
              </Button>
            </CardFooter>
          </Card>
          {loading === LoadingState.Pending && <GlobalSpinner />}
        </Content>
      </Page>
    </>
  );
}
