import React from 'react';
import clsx from 'clsx';

type FilterDesktopProps = {
  name: string;
  children: React.ReactNode;
  className?: string;
};

export function FilterDesktop({ name, children, className }: FilterDesktopProps) {
  return (
    <div className="w-100 p-5 pb-0">
      <span className="fs-5 fw-bold">{name}</span>
      <hr className="bg-gray-500 hr-filter-name m-0 mb-4" />
      <div className={clsx(className)}>{children}</div>
    </div>
  );
}
