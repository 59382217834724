import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  ConnectedStudent,
  ConnectedStudentLevel,
  ConnectedStudentValues,
} from '@/app/modules/profile/connected-student/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

const ENDPOINT_PREFIX = '/ajax/profile/connected-students';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  create: `${ENDPOINT_PREFIX}/create`,
  entity: (id: number) => `${ENDPOINT_PREFIX}/${id}`,
  edit: (id: number) => `${ENDPOINT_PREFIX}/${id}/edit`,
};

//#region PROFILE CONNECTED STUDENT INDEX
export const getProfileConnectedStudents = createAsyncThunk('profile/getProfileConnectedStudents', async function () {
  const response = await axios.get<GetProfileConnectedStudentsResponse>(ENDPOINTS.resource);
  return response.data;
});

export interface GetProfileConnectedStudentsResponse {
  connectedStudents: ConnectedStudent[];
}
//#endregion PROFILE CONNECTED STUDENT INDEX

//#region PROFILE CONNECTED STUDENT CREATE
export async function createProfileConnectedStudent(values: ConnectedStudentValues) {
  const formData = serializeFormData(values);
  const response = await axios.post(ENDPOINTS.resource, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion PROFILE CONNECTED STUDENT CREATE

//#region PROFILE CONNECTED STUDENT CREATE
interface GetProfileConnectedStudentCreateDataResponse {
  defaultConnectedStudent: ConnectedStudent;
  connectedStudentLevels: ConnectedStudentLevel[];
}

export const getProfileConnectedStudentCreateData = createAsyncThunk(
  'profile/getProfileConnectedStudentCreateData',
  async function () {
    const response = await axios.get<GetProfileConnectedStudentCreateDataResponse>(ENDPOINTS.create);
    return response.data;
  }
);
//#endregion PROFILE CONNECTED STUDENT CREATE

//#region PROFILE CONNECTED STUDENT SHOW
interface GetProfileConnectedStudentShowDataResponse {
  connectedStudent: ConnectedStudent;
}

export const getProfileConnectedStudentShowData = createAsyncThunk(
  'profile/getProfileConnectedStudentShowData',
  async function (id: number) {
    const response = await axios.get<GetProfileConnectedStudentShowDataResponse>(ENDPOINTS.entity(id));
    return response.data;
  }
);
//#endregion PROFILE CONNECTED STUDENT SHOW

//#region PROFILE CONNECTED STUDENT EDIT
interface GetProfileConnectedStudentEditDataResponse {
  connectedStudent: ConnectedStudent;
  connectedStudentLevels: ConnectedStudentLevel[];
}

export const getProfileConnectedStudentEditData = createAsyncThunk(
  'profile/getProfileConnectedStudentEditData',
  async function (id: number) {
    const response = await axios.get<GetProfileConnectedStudentEditDataResponse>(ENDPOINTS.edit(id));
    return response.data;
  }
);
//#endregion PROFILE CONNECTED STUDENT EDIT

//#region PROFILE CONNECTED STUDENT UPDATE
export async function updateProfileConnectedStudent(id: number, values: ConnectedStudentValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.entity(id), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion PROFILE CONNECTED STUDENT UPDATE
