import clsx from 'clsx';

import { Button } from '@/app/components/Button/Button';

type MessengerSendButtonProps = {
  isLoading?: boolean;
  buttonClassName?: string;
  iconClassName?: string;
  buttonText?: string;
  isDisabled?: boolean;
};

export function MessengerSendButton({
  isLoading = false,
  buttonClassName = '',
  iconClassName = '',
  buttonText = '',
  isDisabled = false,
}: MessengerSendButtonProps) {
  return (
    <Button
      type="submit"
      isLoading={isLoading}
      isIndicatorOnlyLoading
      className={clsx('messenger-reply-form__button', buttonClassName)}
      disabled={isDisabled}
    >
      <i className={clsx('fas fa-paper-plane p-0', iconClassName)} />
      {buttonText && <span>{buttonText}</span>}
    </Button>
  );
}
