import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { PlayVideoModal } from '@/app/components/PlayVideoModal/PlayVideoModal';

import './TutorAvatar.scss';

type TutorAvatarProps = {
  profileImagePath?: string | null;
  tutorVideoFileUrl?: string | null;
  className?: string;
  playBtnClassName?: string;
};

export function TutorAvatar({ profileImagePath, tutorVideoFileUrl, className, playBtnClassName }: TutorAvatarProps) {
  const [isShown, setIsShown] = useState(false);

  function handleOpen() {
    if (!tutorVideoFileUrl) {
      return;
    }

    setIsShown(true);
  }

  function handleClose() {
    setIsShown(false);
  }

  return (
    <div className="position-relative mw-300px">
      <div
        className={clsx('tutor-avatar', className)}
        style={{
          backgroundImage: profileImagePath ? `url(${profileImagePath})` : `url(${NO_AVATAR_PATH})`,
        }}
      >
        <div className={clsx('rounded', { 'tutor-avatar__linear-overlay': tutorVideoFileUrl })} onClick={handleOpen}>
          {tutorVideoFileUrl && (
            <SVG
              src={toAbsoluteUrl('/img/svg/icons/Play-button.svg')}
              className={clsx('tutor-avatar__play-btn', playBtnClassName)}
            />
          )}
        </div>
      </div>
      <PlayVideoModal isShown={isShown} onHide={handleClose} videoFileURL={tutorVideoFileUrl} />
    </div>
  );
}
