import { Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';

import './StaticPage.scss';

export function AdditionalTermsPage() {
  return (
    <>
      <Helmet>
        <title>Допълнителни условия за обучители</title>
      </Helmet>
      <Page className="static-page">
        <Content>
          <PageTitle className="mb-9 fw-bolder fs-2x text-center">
            ДОПЪЛНИТЕЛНО СПОРАЗУМЕНИЕ КЪМ ДОГОВОР ПРИ ОБЩИ УСЛОВИЯ ЗА ПРИСЪЕДИНЯВАНЕ НА ОБУЧИТЕЛ КЪМ ОНЛАЙН ПЛАТФОРМАТА
            НА „ШКОЛО“ ООД ЗА ПРЕДОСТАВЯНЕ НА „УРОЦИ“
          </PageTitle>
          <Card className="card-custom card-border text-gray-800">
            <Card.Body>
              <p>
                Дружеството и Обучителят заедно наричани по-долу за краткост <strong>„Страните“</strong>, а поотделно{' '}
                <strong>„Страната“</strong>
              </p>
              <p>Като взеха предвид следното:</p>
              <ol>
                <li>
                  С регистрацията на Обучителя в уеб сайта на Дружеството -{' '}
                  <a href="https://lessons.shkolo.bg" target="_blank" rel="noreferrer">
                    https://lessons.shkolo.bg
                  </a>{' '}
                  и натискането на бутона „Приеми Общите условия“, между Дружеството и Обучителят е сключен договор при
                  общи условия за присъединяване на обучител към онлайн платформата на „Школо“ ООД с цел предоставяне на
                  „уроци“.
                </li>
                <li>
                  По силата на приетите от Обучителя Общи условия Дружеството се е задължило да предоставя
                  посредничество при свързване на Обучители и потребители за предоставяне на услугата „Уроци“ срещу
                  уговорен между Страните процент от получените плащания от потребители заявили такава услуга.
                </li>
                <li>
                  Съгласно чл. 24 от Общите условия на Дружеството Страните са се съгласили, че въвеждането на
                  електронен пощенски адрес в неговата цялост и парола при достъп до Профила на Обучителя за целите на
                  ползване на услугите на Платформата представлява способ за уникално идентифициране на потребителя като
                  автор на изпращането на електронни изявления посредством Платформата. Страните са се съгласили, че
                  получените по този ред електронни изявления се считат за подписани с обикновен електронен подпис със
                  силата на саморъчен подпис в отношенията между страните по смисъла на Регламент (ЕС) №910/2014 г.
                  относно електронната идентификация и удостоверителните услуги при електронни трансакции на вътрешния
                  пазар.
                </li>
                <li>
                  Страните желаят сключването на настоящото споразумение с цел уговаряне на размера на посредничеството
                  за Дружеството и се договориха за следното:
                </li>
              </ol>
              <h5 className="fs-4 text-gray-800">I. Размер на възнаграждението за посредничество</h5>
              <p>
                <strong>Чл. 1.</strong> За услугата за предоставяне на посредничество при свързване на Обучители и
                потребители за предоставяне на услугата „Уроци“, Обучителят дължи на Дружеството възнаграждение в размер
                на 25% (двадесет и пет процента) за всяка заплатена от потребител услуга „Уроци“ предлагана от Обучителя
                чрез платформата на „Школо“ ООД –{' '}
                <a href="https://lessons.shkolo.bg" target="_blank" rel="noreferrer">
                  https://lessons.shkolo.bg
                </a>
                .
              </p>
              <p className="mb-0">
                <strong>Чл. 2. (1)</strong> В случай, че Дружеството инициира промоционални/специални условия върху цена
                за индивидуален урок или пакет от уроци, предлагани от Обучителя, Дружеството се задължава да изплати на
                Обучителя пълната нетна сума, предмет на промоционалните/специалните условия. Цената е определена от
                Обучителя в описанието на урока в платформата Школо Уроци.
              </p>
              <p className="mb-0">
                <strong>(2)</strong> Всички данъци и осигуровки, дължими върху сумата, предмет на промоционалните
                условия, са за сметка на Дружеството.
              </p>
              <p className="mb-0">
                <strong>(3)</strong> Обучителят е длъжен до 5 число на месеца, следващ месеца на провеждане на
                промоционален урок, да предостави на Дружеството Декларация относно осигурителния си доход на друго
                основание.
              </p>
            </Card.Body>
          </Card>
        </Content>
      </Page>
    </>
  );
}
