import { Form } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import clsx from 'clsx';
import { useField } from 'formik';

interface RadioButtonControlProps {
  name: string;
  value: string | number | undefined;
  label?: string;
  className?: string;
  isInline?: boolean;
  isDisabled?: boolean;
}

export function RadioButtonControl({
  name,
  value,
  label,
  className,
  isInline = false,
  isDisabled = false,
}: RadioButtonControlProps) {
  const [field, fieldMeta] = useField({ name, value, type: 'radio' });
  const isInvalid = Boolean(fieldMeta.error);

  return (
    <>
      <Form.Check
        type="radio"
        inline={isInline}
        label={label}
        {...field}
        checked={String(fieldMeta.value) === String(value)}
        className={clsx('me-0', className)}
        disabled={isDisabled}
      />

      {isInvalid && <Feedback type="invalid">{fieldMeta.error}</Feedback>}
    </>
  );
}
