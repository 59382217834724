import { useMemo } from 'react';
import * as yup from 'yup';

import { CoursePricingStrategyID } from '@/app/modules/course/create/models';

import {
  CoursePackageLessonCount,
  MINIMUM_COURSE_PACKAGE_LESSON_PRICE,
  MINIMUM_INDIVIDUAL_LESSON_PRICE,
  SINGLE_PACKAGE_AVAILABLE_LESSON_COUNT,
} from '../constants';

export function useCoursePackageValidationSchema() {
  return useMemo(
    () =>
      yup.object().shape({
        coursePricingStrategyID: yup
          .string()
          .required()
          .oneOf([String(CoursePricingStrategyID.MultiplePackages), String(CoursePricingStrategyID.SinglePackage)]),
        individualLesson: yup.object().when('coursePricingStrategyID', {
          is: String(CoursePricingStrategyID.MultiplePackages),
          then: yup.object().shape({
            taxedPrice: yup.number().required().min(MINIMUM_INDIVIDUAL_LESSON_PRICE),
            lessonCount: yup.number().required().max(1),
          }),
        }),
        coursePackages: yup.array().when('coursePricingStrategyID', {
          is: String(CoursePricingStrategyID.MultiplePackages),
          then: yup
            .array()
            .of(
              yup.object({
                lessonCount: yup
                  .number()
                  .required()
                  .oneOf(
                    [CoursePackageLessonCount.Four, CoursePackageLessonCount.Eight, CoursePackageLessonCount.Sixteen],
                    'Пакетът е с невалиден брой уроци. Валиден брой уроци са 4, 8 и 16. Изтрийте или архивирайте пакета, за да запазите промените.'
                  ),
                taxedPrice: yup
                  .number()
                  .required()
                  .test('coursePackageTaxedPriceTest', function (value) {
                    const minimalTaxedPrice = Number(this.parent.lessonCount) * MINIMUM_COURSE_PACKAGE_LESSON_PRICE;
                    if (Number(value) >= minimalTaxedPrice) {
                      return true;
                    }
                    return this.createError({
                      message: `Полето приема само стойности по-големи от или равни на ${minimalTaxedPrice}.`,
                    });
                  }),
              })
            )
            .length(3, 'Невалиден брой пакети. Изтрийте или архивирайте невалидните пакети, за да запазите промените.'),
        }),
        singlePackage: yup.object().when('coursePricingStrategyID', {
          is: String(CoursePricingStrategyID.SinglePackage),
          then: yup.object().shape({
            lessonCount: yup
              .number()
              .required()
              .oneOf(
                SINGLE_PACKAGE_AVAILABLE_LESSON_COUNT,
                'Пакетът е с невалиден брой уроци. За да запазите промените, изберете валиден брой между 4, 8, 16 и 32 урока.'
              ),
            taxedPrice: yup
              .number()
              .required()
              .test('singlePackageTaxedPriceTest', function (value) {
                const minimalTaxedPrice = Number(this.parent.lessonCount) * MINIMUM_COURSE_PACKAGE_LESSON_PRICE;
                if (Number(value) >= minimalTaxedPrice) {
                  return true;
                }
                return this.createError({
                  message: `Полето приема само стойности по-големи от или равни на ${minimalTaxedPrice}.`,
                });
              }),
          }),
        }),
      }),
    []
  );
}
