import { ReactNode, useMemo } from 'react';
import ReactDOM from 'react-dom';

import './BottomNavigationMenu.scss';

type BottomNavigationMenuProps = {
  children: ReactNode;
};

export function BottomNavigationMenu({ children }: BottomNavigationMenuProps) {
  const portalEl = useMemo<HTMLDivElement | null>(() => document.querySelector('#layout-portal'), []);

  return (
    <div className="bottom-navigation-placeholder">
      {portalEl &&
        ReactDOM.createPortal(<div className="bottom-navigation-menu fixed-bottom">{children}</div>, portalEl)}
    </div>
  );
}
