import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

type CardFooterProps = {
  children: ReactNode;
  className?: string;
};

export function CardFooter({ children, className }: CardFooterProps) {
  return <Card.Footer className={className}>{children}</Card.Footer>;
}
