import Dinero from 'dinero.js';

const DEFAULT_CURRENCY = 'BGN';
const DEFAULT_CURRENCY_LOCALE = 'bg';
const DEFAULT_CURRENCY_FORMAT = '$0.00';
export const DEFAULT_ROUNDING_MODE = 'HALF_AWAY_FROM_ZERO';
const DISCOUNT_TYPE_AMOUNT = 'amount';
const DISCOUNT_TYPE_PERCENTAGE = 'percentage';
const DEFAULT_PRECISION = 2;
export const TAX_PERCENT = 0.25;
export const TAX_PERCENT_FORMATTED = `${Math.round(TAX_PERCENT * 100)}%`;
const ONE_HUNDRED_PERCENT = 1;

/**
 * Converts string or number to a Dinero instance.
 *
 * @param price String or number to be converted to Dinero instance
 */
export function parsePrice(price: string | number) {
  // STEP 1: Make sure price is of type number.
  let priceAsNumber = price as number;

  if (price === undefined || price === null) {
    priceAsNumber = 0;
  }

  // STEP 2: Calculate the factor of the precision.
  const factor = Math.pow(10, DEFAULT_PRECISION);

  // STEP 3: Create a new Dinero instance.
  return Dinero({
    amount: Math.round(priceAsNumber * factor),
    currency: DEFAULT_CURRENCY,
  });
}

/**
 * Formats string or number as a currency.
 *
 * @param price String or number to be formatted
 *
 * @returns Formatted currency
 */
export function formatPrice(price?: string | number) {
  let dinero = parsePrice(0);
  if (price) {
    dinero = parsePrice(price);
  }
  return dinero.setLocale(DEFAULT_CURRENCY_LOCALE).toFormat(DEFAULT_CURRENCY_FORMAT);
}

/**
 * Formats a string or number as a currency or percentage.
 *
 * @param price String or number to be formatted
 * @param discountType  The types of promotional codes are percentage or amount
 *
 * @returns Formatted currency
 */
export function formatPromoCodeValue(price?: string | number, discountType?: string | null) {
  if (!price) {
    return '';
  }

  const dinero = parsePrice(price);

  switch (discountType) {
    case DISCOUNT_TYPE_PERCENTAGE:
      return dinero.getAmount() + '%';
    case DISCOUNT_TYPE_AMOUNT:
      return dinero.setLocale(DEFAULT_CURRENCY_LOCALE).toFormat(DEFAULT_CURRENCY_FORMAT);
    default:
      return '';
  }
}

/**
 * Remove surplus charge from given taxedPrice and formats as currency.
 *
 * @param taxedPrice Initial taxedPrice
 * @param surchargePercent Tax percent to substract
 *
 * @returns The price without the surcharge percent formatted in default currency format.
 */
export function calculatePriceWithoutSurcharge(taxedPrice: string | number, surchargePercent: number) {
  const dinero = parsePrice(taxedPrice);

  return dinero
    .setLocale(DEFAULT_CURRENCY_LOCALE)
    .multiply(1 - surchargePercent, DEFAULT_ROUNDING_MODE)
    .toFormat(DEFAULT_CURRENCY_FORMAT);
}

/**
 * Remove the discount (from promo code) from given taxedPrice and formats as currency.
 *
 * @param taxedPrice Initial taxedPrice
 * @param discount The discount from the promo code, percentage or amount
 * @param discountType The types of promotional codes are percentage or amount
 * @param lessonCount Number of lessons
 * @param applicableLessonCounts CoursePackages where the promo code is applicable
 *
 * @returns The price without the surcharge percent.
 */
export function calculatePriceAfterPromoCode(
  taxedPrice: string | number,
  discount: number | null,
  discountType: string | null,
  lessonCount: number,
  applicableLessonCounts: number[] | null
) {
  if (
    Array.isArray(applicableLessonCounts) &&
    applicableLessonCounts.length !== 0 &&
    !applicableLessonCounts?.includes(lessonCount)
  ) {
    return;
  }

  const dineroTaxedPrice = parsePrice(taxedPrice);
  const discountAmount = parsePrice(discount ?? 0);

  switch (discountType) {
    case DISCOUNT_TYPE_PERCENTAGE:
      return dineroTaxedPrice
        .setLocale(DEFAULT_CURRENCY_LOCALE)
        .multiply(1 - (discount ?? 0), DEFAULT_ROUNDING_MODE)
        .toFormat(DEFAULT_CURRENCY_FORMAT);
    case DISCOUNT_TYPE_AMOUNT:
      if (dineroTaxedPrice.getAmount() > discountAmount.getAmount()) {
        return dineroTaxedPrice
          .setLocale(DEFAULT_CURRENCY_LOCALE)
          .subtract(discountAmount)
          .toFormat(DEFAULT_CURRENCY_FORMAT);
      }

      return dineroTaxedPrice
        .setLocale(DEFAULT_CURRENCY_LOCALE)
        .multiply(0, DEFAULT_ROUNDING_MODE)
        .toFormat(DEFAULT_CURRENCY_FORMAT);

    default:
      return dineroTaxedPrice.setLocale(DEFAULT_CURRENCY_LOCALE).toFormat(DEFAULT_CURRENCY_FORMAT);
  }
}

/**
 * Remove surplus charge from given taxedPrice and formats as currency.
 *
 * @param taxedPrice Initial taxedPrice
 * @param surchargePercent Tax percent to substract
 *
 * @returns The price without the surcharge percent without format and as number.
 */
export function calculatePriceWithoutSurchargeWithoutFormat(taxedPrice: string | number, surchargePercent: number) {
  const dinero = parsePrice(taxedPrice);

  return dinero
    .setLocale(DEFAULT_CURRENCY_LOCALE)
    .multiply(1 - surchargePercent, DEFAULT_ROUNDING_MODE)
    .toRoundedUnit(2, DEFAULT_ROUNDING_MODE);
}

/**
 * Returns discount from % to amount
 *
 * @param taxedPrice Initial taxedPrice
 * @param surchargePercent Tax percent to substract
 *
 * @returns The discount without format and as number.
 */
export function calculateDiscountWithoutFormat(taxedPrice: string | number, surchargePercent: number) {
  const dinero = parsePrice(taxedPrice);

  return dinero
    .setLocale(DEFAULT_CURRENCY_LOCALE)
    .multiply(surchargePercent, DEFAULT_ROUNDING_MODE)
    .toRoundedUnit(2, DEFAULT_ROUNDING_MODE);
}

export function singleLessonPrice(price: string | number, lessonCount: number) {
  const dinero = parsePrice(price);

  return dinero.setLocale(DEFAULT_CURRENCY_LOCALE).divide(lessonCount, DEFAULT_ROUNDING_MODE);
}

/**
 * Divide lessons price by its lessons count and formats as currency.
 *
 * @param price Lessons price
 * @param lessonCount number of lessons
 *
 * @returns formatted price for single lesson
 */
export function singleLessonPriceString(price: string | number, lessonCount: number) {
  return singleLessonPrice(price, lessonCount).toFormat(DEFAULT_CURRENCY_FORMAT);
}

/**
 * Divide lessons price after promo code by its lessons count and formats as currency.
 *
 * @param price Lessons price
 * @param discount
 * @param discountType
 * @param lessonCount number of lessons
 *
 * @returns formatted price for single lesson
 */
export function singleLessonPriceStringAfterPromoCode(
  price: string | number,
  discount: number | number,
  discountType: string | null,
  lessonCount: number,
  applicableLessonCounts: number[] | null
) {
  if (
    Array.isArray(applicableLessonCounts) &&
    applicableLessonCounts.length !== 0 &&
    !applicableLessonCounts?.includes(lessonCount)
  ) {
    return singleLessonPrice(price, lessonCount).toFormat(DEFAULT_CURRENCY_FORMAT);
  }

  const packagePrice = parsePrice(price);
  const discountAmount = parsePrice(discount);

  switch (discountType) {
    case DISCOUNT_TYPE_PERCENTAGE:
      return singleLessonPrice(
        packagePrice.multiply(1 - discount, DEFAULT_ROUNDING_MODE).toFormat(DISCOUNT_TYPE_AMOUNT),
        lessonCount
      ).toFormat(DEFAULT_CURRENCY_FORMAT);
    case DISCOUNT_TYPE_AMOUNT:
      if (packagePrice.getAmount() <= discountAmount.getAmount()) {
        return singleLessonPrice(packagePrice.multiply(0).toFormat(DISCOUNT_TYPE_AMOUNT), lessonCount).toFormat(
          DEFAULT_CURRENCY_FORMAT
        );
      }

      return singleLessonPrice(
        packagePrice.subtract(discountAmount).toFormat(DISCOUNT_TYPE_AMOUNT),
        lessonCount
      ).toFormat(DEFAULT_CURRENCY_FORMAT);
    default:
      return singleLessonPrice(price, lessonCount).toFormat(DEFAULT_CURRENCY_FORMAT);
  }
}

/**
 * Do not allow entering more than 2 digits
 * after the decimal point, removes them.
 *
 * @param price Initial price
 *
 * @returns the adjusted price
 */
export function adjustPrice(price: string) {
  if (isNaN(Number(price)) || price === '.') {
    return '';
  }

  const length = price.split('.').length;
  let adjustedPrice;

  if (length > 2) {
    adjustedPrice = price.substr(0, price.length - 1);
  }

  adjustedPrice = price.replace(/[^0-9.-]/, '');

  if (length === 2) {
    adjustedPrice = price.substr(0, price.indexOf('.') + 3);
  }

  return adjustedPrice;
}

/**
 * Returns whether the course package is free.
 *
 * @param taxedPrice
 * @param discountType
 * @param discount
 *
 * @returns boolean
 */
export function isFreeCoursePackageAfterPromoCode(taxedPrice: number, discountType: string | null, discount: number) {
  const packageTaxedPrice = parsePrice(taxedPrice);
  const discountAmount = parsePrice(discount);

  switch (discountType) {
    case DISCOUNT_TYPE_PERCENTAGE:
      return discount === ONE_HUNDRED_PERCENT;
    case DISCOUNT_TYPE_AMOUNT:
      return packageTaxedPrice.getAmount() <= discountAmount.getAmount();
    default:
      return false;
  }
}
