import { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import { Button } from '@/app/components/Button/Button';
import { CourseIndexFilterValues } from '@/app/modules/course/models';

import { ALL_SUBJECTS, TRANSLITERATED_SLUG_ALL_SUBJECTS } from '../../constants';

type RedirectToSubjectsPageProps = {
  subjectName: string | null;
  isModalShown: boolean;
  setIsModalShown: React.Dispatch<React.SetStateAction<boolean>>;
};

export function RedirectToSubjectsPageModal({
  isModalShown,
  subjectName,
  setIsModalShown,
}: RedirectToSubjectsPageProps) {
  const formik = useFormikContext<CourseIndexFilterValues>();
  const handleClose = useCallback(() => {
    setIsModalShown(false);
  }, [setIsModalShown]);

  function handleRemove() {
    formik.setFieldValue('subjectSlug', TRANSLITERATED_SLUG_ALL_SUBJECTS);
    setIsModalShown(false);
  }

  return (
    <Modal show={isModalShown} onEscapeKeyDown={handleClose}>
      <Modal.Header closeButton onHide={handleClose}>
        <Modal.Title>Премахване на филтъра за предмет</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Сигурни ли сте, че искате да премахнете филтъра за предмет <strong>{subjectName}</strong>? Ако го премахнете, ще
        бъдат показани резултати за <strong>{ALL_SUBJECTS}</strong>.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Отказ
        </Button>
        <Button onClick={handleRemove} variant="primary">
          Премахни
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
