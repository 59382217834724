import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Subject, TargetAudience } from '@/app/modules/course/models';
import { SubjectIndexFilterQueryParams } from '@/app/modules/subject/models';

const ENDPOINT_PREFIX = '/ajax/subjects';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  top: `${ENDPOINT_PREFIX}/top`,
};

//region Get subjects
export interface GetSubjectsResponse {
  subjects: Subject[];
  targetAudiences: TargetAudience[];
}

export const getSubjects = createAsyncThunk(
  'subject/getSubjects',
  async function (queryParams: SubjectIndexFilterQueryParams) {
    const response = await axios.get<GetSubjectsResponse>(`${ENDPOINTS.resource}`, {
      params: queryParams,
    });
    return response.data;
  }
);

export interface GetTopSubjectsResponse {
  topSubjects: Subject[];
}

export const getTopSubjects = createAsyncThunk('subject/getTopSubjects', async function () {
  const response = await axios.get<GetTopSubjectsResponse>(`${ENDPOINTS.top}`);
  return response.data;
});
//#endregion Get subjects
