import { ReactNode } from 'react';
import { ToastContainer } from 'react-bootstrap';
import Sticky from 'react-stickynode';

import { MEDIUM_BREAKPOINTS, useBreakpoint } from '@/app/utils/useBreakpoint';

type FeaturedPromoCodeToastContainerProps = {
  children: ReactNode;
};

export function FeaturedPromoCodeToastContainer({ children }: FeaturedPromoCodeToastContainerProps) {
  const breakpoint = useBreakpoint();
  const isSmallScreen = MEDIUM_BREAKPOINTS.includes(breakpoint);

  if (isSmallScreen) {
    return (
      <Sticky top="#kt_header" bottomBoundary="#kt_content" className="fixed-top" enabled>
        <ToastContainer className="p-3" position="top-center" style={{ zIndex: 9999 }}>
          {children}
        </ToastContainer>
      </Sticky>
    );
  }

  return (
    <ToastContainer className="p-3 position-fixed bottom-0" style={{ zIndex: 9999 }}>
      {children}
    </ToastContainer>
  );
}
