import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { AttachmentPreviewContainer } from '@/app/components/AttachmentPreview/AttachmentPreviewContainer';
import { AttachmentPreviewItem } from '@/app/components/AttachmentPreview/AttachmentPreviewItem';
import { Button } from '@/app/components/Button/Button';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { serialize, serializeToHtml } from '@/app/components/RichTextControl/utils';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { TutorAdditionalInfo } from '@/app/components/TutorAdditionalInfo';
import { CourseFeedbackSection } from '@/app/modules/course-feedback/CourseFeedbackSection';
import tutorSlice from '@/app/modules/tutor/store';
import { getEducationDegree, getTeachingExperience } from '@/app/modules/tutor/utils';
import { formatInfoCount } from '@/app/utils/formatInfoCount';
import { formatInfoRating } from '@/app/utils/formatInfoRating';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { BuyCourseLessonsForTutorModal } from '../../buy-course-lesson/BuyCourseLessonsForTutorModal';
import { MAX_RATING } from '../../course/constants';
import { handlePlDeprecatedPurchaseAction } from '../../course/utils';
import { CourseDetails, TutorEducationalDegreeID } from '../models';
import { getTutorCourseFeedbacks, getTutorShowData, updateTutorVisitors } from '../service';
import { CourseItem } from './CourseItem';

import './TutorShowPage.scss';

export function TutorShowPage() {
  const dispatch = useAppDispatch();
  const { tutorID } = useAppSelector((state) => state.auth);
  const store = useAppSelector((state) => state.tutor);
  const showData = store.show;
  const { courseFeedbacks } = showData;
  const { transliteratedSlug } = useParams<{ transliteratedSlug: string }>();
  const tutorIdentityID = store.show.identityID;
  const [loadingState, isInitialDataLoaded] = useLoading(store.loading);
  const [isBuyLessonsModalShown, setIsBuyLessonsModalShown] = useState<boolean>(false);
  const courseFeedbacksSectionRef = useRef<HTMLDivElement | null>(null);
  const serializedAbout = useMemo(() => serializeToHtml(showData.about ?? EMPTY_VALUE), [showData.about]);
  const approvedUserInfo =
    'Този обучител е преминал прецизен подбор от екипа на Школо Уроци. Има богат опит като учител и очаква първия си ученик в платформата.';

  const tutorCourseFeedbacksSectionTitle = 'Мнения на потребители, закупили обучение от обучителя';

  useEffect(
    function componentDidMount() {
      dispatch(getTutorShowData(transliteratedSlug));
      updateTutorVisitors(transliteratedSlug);

      if (courseFeedbacks.loading === LoadingState.Idle && showData.tutorID) {
        dispatch(getTutorCourseFeedbacks({ id: showData.tutorID }));
      }

      return function componentWillUnmount() {
        dispatch(tutorSlice.actions.resetCourseFeedbacksSection());
      };
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const educationDegree = useMemo(() => getEducationDegree(showData?.educationDegreeID), [showData?.educationDegreeID]);

  const teachingExperience = useMemo(
    () => getTeachingExperience(showData?.teachingExperienceID),
    [showData?.teachingExperienceID]
  );

  let keywordsContent = showData.name;

  if (showData.courses?.length > 0) {
    keywordsContent += ', ';
    keywordsContent += showData.courses?.map?.((course: CourseDetails) => course.subjects[0].subjectName)?.join?.(', ');
  }

  const setCurrentPage = tutorSlice.actions.setCourseFeedbacksSectionCurrentPage;

  const handlePreviousPageClick = useCallback(() => {
    if (showData?.tutorID && courseFeedbacks.currentPage > 1) {
      dispatch(getTutorCourseFeedbacks({ id: showData?.tutorID, page: courseFeedbacks.currentPage - 1 }));
      dispatch(setCurrentPage(courseFeedbacks.currentPage - 1));
    }
  }, [courseFeedbacks.currentPage, tutorID]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNextPageClick = useCallback(() => {
    if (showData?.tutorID && courseFeedbacks.hasMore) {
      dispatch(getTutorCourseFeedbacks({ id: showData?.tutorID, page: courseFeedbacks.currentPage + 1 }));
      dispatch(setCurrentPage(courseFeedbacks.currentPage + 1));
    }
  }, [courseFeedbacks.currentPage, courseFeedbacks.hasMore, showData.tutorID]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRatingClick = useCallback(() => {
    courseFeedbacksSectionRef?.current?.scrollIntoView?.({
      behavior: 'smooth',
    });
  }, [courseFeedbacksSectionRef]);

  return (
    <>
      <Helmet>
        <title>{showData.name}</title>
        <meta name="keywords" content={keywordsContent} />
        <meta name="description" content={serialize(showData.about)} />
      </Helmet>
      <Page>
        {!isInitialDataLoaded && <PageTitle className="my-2 text-center">Зарежда се...</PageTitle>}
        {isInitialDataLoaded && (
          <Content className="tutor-show-page-container mx-auto text-break">
            <div className="mt-n5 d-md-flex flex-column flex-sm-row justify-content-between align-items-stretch align-items-sm-center">
              <div className="mt-5 d-flex align-items-center me-sm-10">
                <img
                  src={store.show.profileFile ?? NO_AVATAR_PATH}
                  alt="Снимка на обучител"
                  className="w-150px h-150px w-sm-200px h-sm-200px rounded"
                />
                <div className="d-flex flex-column align-items-start ms-5 ms-sm-10">
                  <h1 className="text-gray-700">{showData.name}</h1>
                  {formatInfoRating(showData.currentRating) === '-' ? (
                    <div className="d-flex mb-5">
                      <span className="badge badge-success align-items-center d-flex fs-base">
                        <i className="fa-solid fa-check fs-base text-white me-1" /> Одобрен от Школо Уроци
                      </span>
                      <OverlayTrigger overlay={<Tooltip>{approvedUserInfo}</Tooltip>}>
                        <i className="fa-solid fa-square-info fs-2hx ms-3" />
                      </OverlayTrigger>
                    </div>
                  ) : (
                    <Button className="btn-sm mb-5" onClick={handleRatingClick}>
                      {formatInfoRating(showData.currentRating)} / {MAX_RATING} ({formatInfoCount(showData.ratingCount)}{' '}
                      оценки)
                    </Button>
                  )}

                  <TutorAdditionalInfo
                    firstAvailableTutorTimeSlotTime={showData.firstAvailableTutorTimeSlotTime}
                    numberStudents={showData.numberStudents}
                    numberReservedLessons={showData.numberReservedLessons}
                    reactionTime={showData.reactionTime}
                    uniqueVisitsCount={showData.uniqueVisitsCount}
                  />
                </div>
              </div>
              {showData.videoFileUrl && (
                <div className="d-flex align-items-center">
                  <iframe
                    className="min-w-100 min-h-250px m-auto mt-5 min-w-md-400px min-h-md-200px my-md-0"
                    id="video"
                    src={showData.videoFileUrl}
                    frameBorder="0"
                    allowFullScreen
                  />
                </div>
              )}
            </div>

            {tutorID !== showData.tutorID && (
              <div className="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-start gap-2 gap-sm-3 mt-5">
                <div className="w-100 mw-sm-200px text-center">
                  <Button
                    onClick={handlePlDeprecatedPurchaseAction}
                    className="btn btn-primary w-100"
                    disabled={!showData.hasAvailableFutureTimeSlots}
                  >
                    <span>Закупи уроци</span>
                  </Button>
                  {!showData.hasAvailableFutureTimeSlots && (
                    <>
                      <p className="mt-1 mb-0 fs-7">Този обучител няма свободни часове в графика в момента.</p>
                      <Link to="/subjects">
                        Избери друг обучител <i className="fa-regular fa-arrow-right text-primary" />
                      </Link>
                    </>
                  )}
                </div>

                <Link
                  to={`/messenger/create?recipientIdentityID=${tutorIdentityID}`}
                  className="btn btn-light-primary min-w-lg-200px"
                >
                  <i className="fa fa-comments fs-1" />
                  <span>Изпрати съобщение</span>
                </Link>
              </div>
            )}

            <CourseFeedbackSection
              sectionTitle={tutorCourseFeedbacksSectionTitle}
              feedbacks={showData.courseFeedbacks}
              onPreviousPageClick={handlePreviousPageClick}
              onNextPageClick={handleNextPageClick}
              ref={courseFeedbacksSectionRef}
            />

            <div className="mt-10">
              <h2 className="text-gray-700">За обучителя</h2>
              <p dangerouslySetInnerHTML={{ __html: serializedAbout }} />
            </div>
            {showData.profileDetailsAttachments.length > 0 && (
              <div className="mt-10">
                <h2 className="text-gray-700">Педагогически постижения</h2>
                <AttachmentPreviewContainer>
                  {showData.profileDetailsAttachments.map((attachment, idx) => {
                    return <AttachmentPreviewItem key={idx} attachment={attachment} />;
                  })}
                </AttachmentPreviewContainer>
              </div>
            )}
            {educationDegree && (
              <div className="mt-10">
                <h2 className="text-gray-700 mb-5">Моето образование е</h2>
                <div className="tutor-preview-card__field-value">{educationDegree}</div>
              </div>
            )}
            {Number(showData?.educationDegreeID) === TutorEducationalDegreeID.University && showData?.university && (
              <div className="mt-10">
                <h2 className="text-gray-700 mb-5">Университет/Специалност</h2>
                <div className="tutor-preview-card__field-value">{showData?.university}</div>
              </div>
            )}
            {teachingExperience && (
              <div className="mt-10">
                <h2 className="text-gray-700 mb-5">Опитът ми като обучител е</h2>
                <div className="tutor-preview-card__field-value">{teachingExperience}</div>
              </div>
            )}
            {showData?.approach && (
              <div className="mt-10">
                <h2 className="text-gray-700 mb-5">Подходът ми в преподаването е</h2>
                <div className="tutor-preview-card__field-value">{showData?.approach}</div>
              </div>
            )}
            {showData?.motivation && (
              <div className="mt-10">
                <h2 className="text-gray-700 mb-5">Мотивацията ми като обучител е</h2>
                <div className="tutor-preview-card__field-value">{showData?.motivation}</div>
              </div>
            )}
            {showData?.importance && (
              <div className="mt-10">
                <h2 className="text-gray-700 mb-5">Най-важното за мен в работата с учениците е</h2>
                <div className="tutor-preview-card__field-value">{showData?.importance}</div>
              </div>
            )}
            {showData?.pupilAchievement && (
              <div className="mt-10">
                <h2 className="text-gray-700 mb-5">Ученическо постижение с което се гордея</h2>
                <div className="tutor-preview-card__field-value">{showData?.pupilAchievement}</div>
              </div>
            )}
            <div className="mt-10">
              <h2 className="text-gray-700 mb-5">Обучения</h2>
              <Row className="g-5 mx-0">
                {showData.courses.length === 0 && <h4 className="text-gray-600">Няма добавени обучения.</h4>}
                {showData.courses.map((course: CourseDetails) => (
                  <CourseItem
                    key={course.id}
                    course={course}
                    tutorName={showData.name}
                    tutorIdentityID={showData.identityID}
                    hasAvailableFutureTimeSlots={showData.hasAvailableFutureTimeSlots}
                  />
                ))}
              </Row>
            </div>
          </Content>
        )}

        {showData.tutorID && tutorID !== showData.tutorID && (
          <BuyCourseLessonsForTutorModal
            tutorID={showData.tutorID}
            isShown={isBuyLessonsModalShown}
            setIsShown={setIsBuyLessonsModalShown}
          />
        )}

        {loadingState === LoadingState.Pending && <GlobalSpinner />}
      </Page>
    </>
  );
}
