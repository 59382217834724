import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { wrappedReactLazy } from '@/app/utils/wrapped-react-lazy';

import { MyScheduleIndexPage } from './MyScheduleIndexPage';

const MyScheduleCalendarPage = wrappedReactLazy(() => import('./MyScheduleCalendarPage'));
const MyScheduleCalendarEditPage = wrappedReactLazy(() => import('./MyScheduleCalendarEditPage'));

export function MySchedulePage() {
  return (
    <Switch>
      <Route path="/my-schedule" exact>
        <MyScheduleIndexPage />
      </Route>

      <Route path="/my-schedule/calendar" exact>
        <MyScheduleCalendarPage />
      </Route>

      <Route path="/my-schedule/calendar/edit" exact>
        <MyScheduleCalendarEditPage />
      </Route>

      <Route component={NoMatch} />
    </Switch>
  );
}
