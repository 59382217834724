import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '@/_metronic/helpers';

import './PlayVideoModal.scss';

export function useVideoPlayerModal() {
  const [isVideoPlayerModalShown, setIsVideoPlayerModalShown] = useState(false);

  function showVideoPlayerModal(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    setIsVideoPlayerModalShown(true);
  }

  function hideVideoPlayerModal() {
    setIsVideoPlayerModalShown(false);
  }

  return { isVideoPlayerModalShown, showVideoPlayerModal, hideVideoPlayerModal };
}

type PlayVideoModalProps = {
  isShown: boolean;
  onHide: () => void;
  videoFileURL?: string | null;
};

export function PlayVideoModal({ isShown, onHide, videoFileURL }: PlayVideoModalProps) {
  if (!videoFileURL) {
    return null;
  }

  return (
    <Modal
      show={isShown}
      onEscapeKeyDown={onHide}
      centered
      size="lg"
      contentClassName="play-video-modal rounded"
      dialogClassName="position-relative rounded d-flex justify-content-center align-items-center"
    >
      <iframe className="w-100 h-100" id="video" src={videoFileURL} frameBorder="0" allowFullScreen />
      <button className="btn play-video-modal__close-btn" onClick={onHide}>
        <SVG src={toAbsoluteUrl('/img/svg/icons/Close-btn.svg')} />
      </button>
    </Modal>
  );
}
