import { useParams } from 'react-router-dom';

import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { CourseDetailsContent } from '@/app/modules/course/components/CourseDetailsContent';
import { formatInfoRating } from '@/app/utils/formatInfoRating';

import { MAX_RATING } from '../constants';

type CourseDetailsCardProps = {
  course: {
    name: string | null;
    currentRating: number | null;
    tutorName?: string;
    tutorProfileImagePath?: string | null;
  };
};

export function CourseDetailsCard({ course }: CourseDetailsCardProps) {
  const { transliteratedSlug } = useParams<{ transliteratedSlug?: string }>();

  return (
    <Card variant="bordered" className="flex-sm-row text-dark w-100">
      <CourseDetailsContent transliteratedSlug={transliteratedSlug}>
        <CardBody className="p-6">
          <h3 className="text-break">{course.name}</h3>
          <div className="d-flex flex-row flex-wrap justify-content-between align-items-end mb-n2">
            <span className="badge bg-gray-200 text-dark mb-2">
              {formatInfoRating(course.currentRating)} / {MAX_RATING}
            </span>

            <div className="d-flex flex-wrap align-items-center mb-2">
              <img
                src={course.tutorProfileImagePath ?? NO_AVATAR_PATH}
                alt="Профилна снимка"
                className="w-30px h-30px rounded me-2"
              />
              <h4 className="mb-0 fw-bold">{course.tutorName}</h4>
            </div>
          </div>
        </CardBody>
      </CourseDetailsContent>
    </Card>
  );
}
