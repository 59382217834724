import React from 'react';
import clsx from 'clsx';

import { WeekDay } from '@/app/components/Calendar/models';
import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';

type DaysOfWeekControlProps = {
  name: string;
  formGroupClassName?: string;
  containerClassName?: string;
  checkboxOptionClassName?: string;
};

export function DaysOfWeekControl({
  name,
  formGroupClassName,
  containerClassName,
  checkboxOptionClassName,
}: DaysOfWeekControlProps) {
  return (
    <FormGroup className={clsx(formGroupClassName)} controlId={name}>
      <Label>Дни от седмицата</Label>
      <div className={clsx('d-flex flex-wrap', containerClassName)}>
        <CheckboxControl
          name={name}
          className={clsx('me-3 mb-2 mb-md-0', checkboxOptionClassName)}
          label="пон"
          value={WeekDay.Monday}
        />
        <CheckboxControl
          name={name}
          className={clsx('me-3 mb-2 mb-md-0', checkboxOptionClassName)}
          label="вто"
          value={WeekDay.Tuesday}
        />
        <CheckboxControl
          name={name}
          className={clsx('me-3 mb-2 mb-md-0', checkboxOptionClassName)}
          label="сря"
          value={WeekDay.Wednesday}
        />
        <CheckboxControl
          name={name}
          className={clsx('me-3 mb-2 mb-md-0', checkboxOptionClassName)}
          label="чет"
          value={WeekDay.Thursday}
        />
        <CheckboxControl
          name={name}
          className={clsx('me-3 mb-2 mb-md-0', checkboxOptionClassName)}
          label="пет"
          value={WeekDay.Friday}
        />
        <CheckboxControl
          name={name}
          className={clsx('me-3 mb-2 mb-md-0', checkboxOptionClassName)}
          label="съб"
          value={WeekDay.Saturday}
        />
        <CheckboxControl name={name} className="mb-md-0" label="нед" value={WeekDay.Sunday} />
      </div>
    </FormGroup>
  );
}
