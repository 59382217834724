import { useHiddenConsultUs } from '@/_metronic/layout/components/consult-us/useHiddenConsultUs';
import { Card } from '@/app/components/Card/Card';
import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { Content } from '@/app/components/Page/Content/Content';
import { useHiddenFooter } from '@/app/layout/Footer/useHiddenFooter';

import {
  CourseIndexFilterPriceRange,
  CourseIndexFilterPriceRangeLabel,
  CourseIndexPageFilterMobile,
} from '../../../models';
import { HideFiltersButtonMobile } from './components/HideFiltersButtonMobile';

type PriceFilterPageMobileProps = {
  handleFilterToggle: () => void;
  setCurrentFilter: React.Dispatch<React.SetStateAction<CourseIndexPageFilterMobile | null>>;
};

export function PriceFilterPageMobile({ handleFilterToggle, setCurrentFilter }: PriceFilterPageMobileProps) {
  function handleClickClose() {
    handleFilterToggle();
    setCurrentFilter(null);
  }

  function handleClickBack() {
    setCurrentFilter(null);
  }

  useHiddenConsultUs();
  useHiddenFooter();

  return (
    <div className="pb-1">
      <HideFiltersButtonMobile onClickHide={handleClickClose} />

      <div className="d-flex justify-content-center mt-2">
        <button className="btn ps-0 pe-0" onClick={handleClickBack}>
          <i className="far fa-arrow-left text-primary fs-4" />
        </button>
        <span className="w-100 fs-2 d-flex justify-content-center align-items-center">Цена</span>
      </div>

      <div className="flex-wrap justify-content-between">
        <Content className="p-3 ps-0 pe-0">
          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="priceRanges"
              value={CourseIndexFilterPriceRange.From20To30}
              label={CourseIndexFilterPriceRangeLabel.From20To30}
              labelFirst
              className="d-flex align-items-center justify-content-between w-100 p-4 cursor-pointer"
            />
          </Card>

          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="priceRanges"
              value={CourseIndexFilterPriceRange.From30To40}
              label={CourseIndexFilterPriceRangeLabel.From30To40}
              labelFirst
              className="d-flex align-items-center justify-content-between w-100 p-4 cursor-pointer"
            />
          </Card>

          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="priceRanges"
              value={CourseIndexFilterPriceRange.From40To50}
              label={CourseIndexFilterPriceRangeLabel.From40To50}
              labelFirst
              className="d-flex align-items-center justify-content-between w-100 p-4 cursor-pointer"
            />
          </Card>

          <Card className="rounded w-100 mb-3">
            <CheckboxControl
              name="priceRanges"
              value={CourseIndexFilterPriceRange.Over50}
              label={CourseIndexFilterPriceRangeLabel.Over50}
              labelFirst
              className="d-flex align-items-center justify-content-between w-100 p-4 cursor-pointer"
            />
          </Card>
        </Content>
      </div>
    </div>
  );
}
