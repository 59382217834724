import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { format, parse } from 'date-fns';
import produce from 'immer';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardFooter } from '@/app/components/Card/CardFooter';
import { CardSection } from '@/app/components/Card/CardSection';
import { CardSectionContent } from '@/app/components/Card/CardSectionContent';
import { CardSectionDescription } from '@/app/components/Card/CardSectionDescription';
import { CardSectionTitle } from '@/app/components/Card/CardSectionTitle';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { Form } from '@/app/components/Form/Form';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { CourseDetailsCard } from '@/app/modules/course/components/CourseDetailsCard';
import {
  STUDENT_PROPOSAL_DAY_TIME_INTERVAL_QUERY_PARAMETER,
  STUDENT_PROPOSAL_START_DATE_QUERY_PARAMETER,
} from '@/app/modules/course/constants';
import { StudentProposalForm } from '@/app/modules/course/forms/StudentProposalForm';
import {
  createCourseFreeStudentProposal,
  CreateCourseStudentProposalResponse,
  getCourseFreeStudentProposalData,
} from '@/app/modules/course/service';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { CourseFreeStudentProposalValues } from '../models';

export function CourseFreeStudentProposal() {
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const queryParams = new URLSearchParams(search);
  const startDateQueryParameter = queryParams.get(STUDENT_PROPOSAL_START_DATE_QUERY_PARAMETER);
  const dayTimeIntervalQueryParameter = queryParams.get(STUDENT_PROPOSAL_DAY_TIME_INTERVAL_QUERY_PARAMETER);

  const loading = useAppSelector((state) => state.course.loading);
  const course = useAppSelector((state) => state.course.courseFreeStudentProposalData);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getCourseFreeStudentProposalData(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initialValues = useMemo<CourseFreeStudentProposalValues>(
    () => ({
      daysOfWeek: [],
      comment: '',
      dayTimeIntervals: dayTimeIntervalQueryParameter ? [Number(dayTimeIntervalQueryParameter)] : [],
      startDate: startDateQueryParameter
        ? new Date(parse(startDateQueryParameter, DEFAULT_DATE_FORMAT, new Date()))
        : null,
    }),
    []
  );
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        comment: yup.string().required().max(2000),
      }),
    []
  );
  const formik = useForm<CourseFreeStudentProposalValues>({
    initialValues,
    validationSchema,
    initialStatus: loading,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<CourseFreeStudentProposalValues>(async (values) => {
        const newValues = produce(values, (draft) => {
          if (draft.startDate) {
            draft.startDate = format(draft.startDate as Date, DEFAULT_DATETIME_FORMAT);
          }

          if (draft.endDate) {
            draft.endDate = format(draft.endDate as Date, DEFAULT_DATETIME_FORMAT);
          }
        });
        return await createCourseFreeStudentProposal(Number(id), newValues);
      });
      const response = (await submitWithValidation(values, formikHelpers)) as CreateCourseStudentProposalResponse;
      if (response) {
        formik.resetForm({ values });
        history.push(`/messenger/${response.threadID}`);
      }
      return;
    },
  });

  return (
    <>
      <Helmet>
        <title>Предложете час</title>
      </Helmet>
      <Page
        renderContent={(props) => (
          <Form
            {...props}
            formik={formik}
            disabled={formik.isSubmitting}
            onSubmit={handleFrontEndValidations(formik)}
          />
        )}
      >
        <Content>
          <PageTitle className="mb-9 fw-bolder fs-2x text-center">Предложете час</PageTitle>
          <Card>
            <CardBody>
              <CardSection>
                <CardSectionDescription>
                  <CardSectionTitle>Обучение</CardSectionTitle>
                </CardSectionDescription>
                <CardSectionContent>
                  <CourseDetailsCard course={course} />
                </CardSectionContent>
              </CardSection>
              <CardSection>
                <CardSectionDescription>
                  <CardSectionTitle>Предложение</CardSectionTitle>
                  <CardSubTitle>
                    Въведете интервала от време, в което желаете да проведете уроци. По този начин ще направите
                    предложение към обучителя и ще имате възможност да дискутирате най-подходящото време.
                  </CardSubTitle>
                </CardSectionDescription>
                <CardSectionContent>
                  <StudentProposalForm />
                </CardSectionContent>
              </CardSection>
            </CardBody>
            <CardFooter className="d-flex justify-content-end gap-3">
              <Button type="submit" isLoading={formik.isSubmitting}>
                Изпрати предложение
              </Button>
            </CardFooter>
          </Card>
        </Content>
      </Page>
    </>
  );
}
