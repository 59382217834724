import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { matchPath, useHistory } from 'react-router';

import { DEFAULT_RATING } from '@/app/components/Form/RatingControl/constants';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation } from '@/app/components/Form/utils';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { CourseFeedbackForm } from './CourseFeedbackForm';
import { CourseFeedbackFormValues } from './models';
import { getCourseFeedbackReminder, updateCourseFeedback } from './service';
import { useCourseFeedbackValidationSchema } from './useCourseFeedbackValidationSchema';

const EXCLUDE_ROUTES = ['/lessons/:id/end'];

export function CourseFeedbackReminderModal() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const authStore = useAppSelector((state) => state.auth);
  const loading = useAppSelector((state) => state.courseFeedback.loading);
  const content = useAppSelector((state) => state.courseFeedback.courseFeedbackReminder);
  const [isShown, setIsShown] = useState(false);

  useEffect(
    function handleShouldGiveFeedbackChange() {
      if (loading === LoadingState.Idle) {
        const isExcludedRoute = EXCLUDE_ROUTES.every((route) => matchPath(history.location.pathname, { path: route }));
        setIsShown(!isExcludedRoute && content !== null);
      }
    },
    [content] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#region Handle data
  useEffect(
    function componentDidMount() {
      if (authStore.shouldLeaveCourseFeedback && loading === LoadingState.Idle) {
        dispatch(getCourseFeedbackReminder());
      }
    },
    [authStore.shouldLeaveCourseFeedback] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle data

  //#region Handle form

  const initialValues = useMemo<CourseFeedbackFormValues>(
    () => ({
      courseRating: DEFAULT_RATING,
      courseComment: '',
    }),
    []
  );

  const { validationSchema } = useCourseFeedbackValidationSchema();

  const formik = useForm<CourseFeedbackFormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<CourseFeedbackFormValues>(async (values) => {
        return await updateCourseFeedback(Number(content?.courseID), values);
      });
      const response = await submitWithValidation(values, formikHelpers);
      if (response) {
        setIsShown(false);
        formik.resetForm({ values });
      }
    },
  });
  //#endregion Handle form

  function handleOnHide() {
    formik.resetForm();
    setIsShown(false);
  }

  return (
    <Modal show={isShown} onHide={handleOnHide}>
      <Modal.Body>
        <div className="mb-4 text-center">
          На{' '}
          <strong>
            {content?.date} от {content?.hour}
          </strong>{' '}
          присъствахте на урок по <strong>{content?.courseName}</strong> при обучител{' '}
          <strong>{content?.tutorName}</strong>. Моля, оценете обучението.
        </div>
        <CourseFeedbackForm formik={formik} onSectionHide={handleOnHide} />
      </Modal.Body>
    </Modal>
  );
}
