import produce from 'immer';

import { stringifyQuery } from '@/app/utils/query';

import { DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE, DEFAULT_PAGE_VALUE } from './constants';
import { CardIndexFilterQueryParams, CardIndexFilterValues } from './models';

export function transformCardIndexFormikValuesToSearchParams(
  formikValues: CardIndexFilterValues,
  queryParams: CardIndexFilterQueryParams
) {
  const newQueryParams: CardIndexFilterQueryParams = produce(queryParams, (draft) => {
    if (Number(formikValues.page) !== DEFAULT_PAGE_VALUE) {
      draft.page = formikValues.page;
    } else {
      delete draft.page;
    }
    if (Number(formikValues.itemsPerPage) !== DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE) {
      draft.itemsPerPage = formikValues.itemsPerPage;
    } else {
      delete draft.itemsPerPage;
    }
  });

  return stringifyQuery(newQueryParams);
}
