import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Masonry from 'react-masonry-css';
import { useHistory, useParams } from 'react-router-dom';
import Sticky from 'react-stickynode';
import clsx from 'clsx';
import * as yup from 'yup';

import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { Content } from '@/app/components/Page/Content/Content';
import { GoBackLink } from '@/app/components/Page/GoBackLink/GoBackLink';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { ITEMS_PER_PAGE_SHORT_DATA } from '@/app/components/Pagination/constants';
import { Pagination } from '@/app/components/Pagination/Pagination';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { usePromotionDescription } from '@/app/layout/Footer/usePromotionDescription';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformEcommerceEventToMultipleConversionEventValues } from '@/app/modules/analytics/utils';
import { CoursePageBanner } from '@/app/modules/course/index/CourseBanner/CoursePageBanner';
import { TutorWithCoursesItem } from '@/app/modules/course/index/CourseItem/TutorWithCoursesItem';
import courseSlice from '@/app/modules/course/store';
import { CourseSearchHistoryFormValues } from '@/app/modules/course-search-history/models';
import { createCourseSearchHistory } from '@/app/modules/course-search-history/services';
import { FACEBOOK_PIXEL_ECOMMERCE_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagProductImpressionEvent } from '@/app/utils/google-analytics/ecommerceEvents';
import { useQueryParams } from '@/app/utils/query';
import { GRID_BREAKPOINTS, LARGE_BREAKPOINTS, useBreakpoint } from '@/app/utils/useBreakpoint';
import { useCollapsableFilter } from '@/app/utils/useCollapsableFilter';
import { useDebounce } from '@/app/utils/useDebounce';
import { useLoading } from '@/app/utils/useLoading';
import { useScrollTopOnHistoryChange } from '@/app/utils/useScrollTopOnHistoryChange';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { SupportTicketContactUs } from '../../support-ticket/contact-us/SupportTicketContactUs';
import {
  COURSE_INDEX_FILTER_SORT_BY_DATA,
  COURSE_INDEX_PAGE_DESCRIPTION,
  COURSE_INDEX_PAGE_TITLE,
  CourseIndexFilterInitialValues,
} from '../constants';
import { Course, CourseIndexFilterQueryParams, CourseIndexFilterValues, TutorWithCourse } from '../models';
import { getCourses } from '../service';
import { getEcommerceItemsFromCourseIndex, transformCourseIndexFormikValuesToSearchParams } from '../utils';
import { CourseIndexPageDesktopFilters } from './filters/CourseIndexPageDesktopFilters';
import { CourseIndexPageMobileFilters } from './filters/CourseIndexPageMobileFilters';

import './CourseIndexPage.scss';

export function CourseIndexPage() {
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isLargeScreen = LARGE_BREAKPOINTS.includes(breakpoint);
  const { subjectSlug, targetAudienceSlug } = useParams<{ subjectSlug: string; targetAudienceSlug: string }>();
  const { currentSearchParams, queryParams } = useQueryParams<CourseIndexFilterQueryParams>();

  useScrollTopOnHistoryChange();
  usePromotionDescription();

  //#region Handle data
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.course);
  const auth = useAppSelector((state) => state.auth);
  const [loadingState, isInitialDataLoaded, setLoadingState, canRefetch] = useLoading(
    store.loading,
    store.index.isPreloaded
  );
  const selectedTargetAudienceSlug = store.index.allTargetAudiences
    .find((targetAudience) => targetAudience.text === store.index.targetAudienceName)
    ?.id?.toString?.();
  const memoizedQueryParams = useMemo(
    () => ({
      ...queryParams,
      subjectTransliteratedSlug: subjectSlug ?? null,
      targetAudienceTransliteratedSlug: targetAudienceSlug ?? null,
    }),
    [queryParams, subjectSlug, targetAudienceSlug]
  );
  const debouncedQueryParams = useDebounce<CourseIndexFilterQueryParams>(memoizedQueryParams, 600);
  const isEmpty = loadingState === LoadingState.Idle && store.index.tutorsWithCourses.length === 0;
  const shouldShowSimilarCourses = isEmpty && store.index.similarCourses.length > 0;
  const [isCleared, setIsCleared] = useState(false);

  useEffect(
    function handleFetchingData() {
      if (store.loading === LoadingState.Idle && canRefetch) {
        dispatch(getCourses(debouncedQueryParams));
        setIsCleared(false);
      }
    },
    [debouncedQueryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function componentDidMount() {
      return function componentDidUnmount() {
        dispatch(courseSlice.actions.resetCourseIndexPage());
      };
    },
    [isCleared] // eslint-disable-line react-hooks/exhaustive-deps
  );

  let pageTitle = COURSE_INDEX_PAGE_TITLE;
  let pageDescription: string | null = COURSE_INDEX_PAGE_DESCRIPTION;

  if (store.index.subjectName) {
    pageTitle = [store.index.subjectName, store.index.targetAudienceName].filter((el) => el).join(', ');
    pageDescription = store.index.subjectDescription;
  }
  //#endregion Handle data

  //#region Handle filter form
  const initialValues = useMemo<CourseIndexFilterValues>(
    () => ({
      page: queryParams.page ?? CourseIndexFilterInitialValues.page,
      sortBy: queryParams.sortBy ?? CourseIndexFilterInitialValues.sortBy,
      itemsPerPage: queryParams.itemsPerPage ?? CourseIndexFilterInitialValues.itemsPerPage,
      targetAudienceSlug: selectedTargetAudienceSlug ?? CourseIndexFilterInitialValues.targetAudienceSlug,
      keywordIds: queryParams.keywordIds ?? CourseIndexFilterInitialValues.keywordIds,
      priceRanges: queryParams.priceRanges ?? CourseIndexFilterInitialValues.priceRanges,
      numberOfLessons: queryParams.numberOfLessons ?? CourseIndexFilterInitialValues.numberOfLessons,
      fromDate: queryParams.fromDate ?? CourseIndexFilterInitialValues.fromDate,
      toDate: queryParams.toDate ?? CourseIndexFilterInitialValues.toDate,
      timeRanges: queryParams.timeRanges ?? CourseIndexFilterInitialValues.timeRanges,
      daysOfWeek: queryParams.daysOfWeek ?? CourseIndexFilterInitialValues.daysOfWeek,
      hasFutureTimeSlots: Number(queryParams.hasFutureTimeSlots ?? CourseIndexFilterInitialValues.hasFutureTimeSlots),
      subjectSlug: subjectSlug ?? CourseIndexFilterInitialValues.subjectSlug,
    }),
    [
      queryParams.page,
      queryParams.sortBy,
      queryParams.itemsPerPage,
      queryParams.keywordIds,
      queryParams.priceRanges,
      queryParams.numberOfLessons,
      queryParams.fromDate,
      queryParams.toDate,
      queryParams.timeRanges,
      queryParams.daysOfWeek,
      queryParams.hasFutureTimeSlots,
      selectedTargetAudienceSlug,
      subjectSlug,
    ]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<CourseIndexFilterValues>({
    initialValues,
    validationSchema,
    initialStatus: loadingState,
  });

  const handlePageChange = useCallback(
    (page: number) => {
      formik.setFieldValue('page', page);
    },
    [formik]
  );
  //#endregion Handle filter form

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      if (isLargeScreen && subjectSlug !== formik.values.subjectSlug) {
        const previousSearchParams = transformCourseIndexFormikValuesToSearchParams(formik.values, queryParams);

        history.replace({ pathname: `/${formik.values.subjectSlug}`, search: previousSearchParams });
        setLoadingState(LoadingState.Pending);
        return;
      }

      const newSearchParams = transformCourseIndexFormikValuesToSearchParams(formik.values, queryParams);

      if (newSearchParams !== currentSearchParams || (targetAudienceSlug ?? '') !== formik.values.targetAudienceSlug) {
        let pathname = `/${subjectSlug}`;

        if (formik.values.targetAudienceSlug) {
          pathname += `/${formik.values.targetAudienceSlug}`;
        }

        history.replace({ pathname: pathname, search: newSearchParams });
        setLoadingState(LoadingState.Pending);
      }
    },
    [currentSearchParams, formik.values, history, subjectSlug, targetAudienceSlug, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#endregion Handle query params

  //#region Handle filter toggle
  const [isSubjectFilterToggled, setIsSubjectFilterToggled] = useState(false);
  const [stickyStateStatusCode, setStickyStateStatusCode] = useState<Sticky.StatusCode>(Sticky.STATUS_ORIGINAL);
  const contentContainerRef = useRef<HTMLDivElement>(null);

  function handleStickyStateChange(state: Sticky.Status) {
    setStickyStateStatusCode(state.status);
  }

  const { isFilterToggled, handleFilterToggle } = useCollapsableFilter({
    stickyStateStatusCode: stickyStateStatusCode,
    contentContainerRef: contentContainerRef,
  });

  function clearFilters() {
    const currentSubjectSlug = formik.values.subjectSlug;
    formik.setValues(CourseIndexFilterInitialValues);
    formik.setFieldValue('subjectSlug', currentSubjectSlug);

    if (targetAudienceSlug) {
      setIsCleared(true);
    }
  }
  //#endregion Handle filter toggle

  //#region Filters state utilities
  function isAnyTimeFilterSet() {
    return formik.values.timeRanges.length > 0 || formik.values.fromDate || formik.values.toDate;
  }

  function isOnlyFutureTimeSlotsChecked() {
    if (isAnyTimeFilterSet()) {
      return false;
    }

    return Boolean(formik.values.hasFutureTimeSlots);
  }
  //#endregion Filters state utilities

  useEffect(() => {
    async function handleViewCategory() {
      const courses = [] as Course[];
      store.index?.tutorsWithCourses?.map((tutorWithCourses) => {
        tutorWithCourses?.courses?.map((course) => {
          courses.push({ ...course, tutorName: tutorWithCourses?.tutorName });
        });
      });

      if (subjectSlug && courses.length > 0) {
        const ecommerceItems = getEcommerceItemsFromCourseIndex(courses, store.index.subjectName as string);
        const conversionEventValues = await transformEcommerceEventToMultipleConversionEventValues(
          FACEBOOK_PIXEL_ECOMMERCE_EVENTS.viewCategory,
          ecommerceItems
        );
        tagProductImpressionEvent(ecommerceItems, conversionEventValues.eventID);
        await trackAnalyticsEventConversionsAPI(conversionEventValues);
      }
    }
    handleViewCategory();
  }, [subjectSlug, store.index.tutorsWithCourses, store.index.subjectName]);

  //#region handle course search history change
  useEffect(
    function handleCourseSearchHistoryFiltersChange() {
      if (auth.isAuthenticated && auth.studentID) {
        const courseSearchHistory: CourseSearchHistoryFormValues = {
          subjectSlug: formik.values.subjectSlug,
          targetAudienceSlug: formik.values.targetAudienceSlug,
          daysOfWeek: formik.values.daysOfWeek,
          priceRanges: formik.values.priceRanges,
          numberOfLessons: formik.values.numberOfLessons,
          timeRanges: formik.values.timeRanges,
          fromDate: formik.values.fromDate,
          toDate: formik.values.toDate,
          hasFutureTimeSlots: formik.values.hasFutureTimeSlots,
          keywordIds: formik.values.keywordIds,
        };

        if (formik.values !== formik.initialValues) {
          createCourseSearchHistory(courseSearchHistory);
        }
      }
    },
    [
      formik.values.subjectSlug,
      formik.values.targetAudienceSlug,
      formik.values.daysOfWeek,
      formik.values.priceRanges,
      formik.values.numberOfLessons,
      formik.values.timeRanges,
      formik.values.fromDate,
      formik.values.toDate,
      formik.values.hasFutureTimeSlots,
      formik.values.keywordIds,
      formik.values,
      formik.initialValues,
      auth.isAuthenticated,
      auth.studentID,
    ]
  );
  //#endregion handle course search history change

  //#region meta tag content
  let keywordsContent = 'Частни уроци, Онлайн уроци, Уроци';
  let descriptionContent = 'Онлайн уроци';

  if (store.index.subjectName) {
    descriptionContent += ` по ${store.index.subjectName}`;
    keywordsContent = `${store.index.subjectName}, ${keywordsContent}`;
  }

  descriptionContent += ' с най-добрите учители';
  //#endregion meta tag content

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="keywords" content={keywordsContent} />
        <meta name="description" content={pageDescription ? pageDescription : descriptionContent} />
      </Helmet>
      <Page
        className="bg-white p-0"
        renderContent={(props) => <Form {...props} formik={formik} disabled={formik.isSubmitting} skipDirtyPrompt />}
      >
        <div className="d-flex">
          {isLargeScreen && (
            <CourseIndexPageDesktopFilters
              clearFilters={clearFilters}
              handleStickyStateChange={handleStickyStateChange}
            />
          )}

          <div className="w-100">
            {!isSubjectFilterToggled && !isFilterToggled && (
              <div>
                <Content isContainerContent className={clsx({ 'pb-0': isLargeScreen })}>
                  <PageTop isVertical>
                    <GoBackLink to="/subjects">Назад към всички предмети</GoBackLink>
                    <CoursePageBanner />

                    <div
                      className={clsx('w-100', {
                        'd-flex justify-content-between': !pageDescription,
                      })}
                    >
                      <PageTitle
                        className={clsx('d-flex align-items-center my-2 fw-bolder fs-2x', {
                          'w-100': pageDescription,
                        })}
                      >
                        {isInitialDataLoaded ? pageTitle : 'Зарежда се...'}
                      </PageTitle>

                      <div
                        className={clsx('d-flex justify-content-between align-items-end', {
                          'w-100': pageDescription,
                        })}
                      >
                        {pageDescription && <p className="w-lg-50 mt-4 mt-lg-2 mb-0 fs-5">{pageDescription}</p>}

                        {isLargeScreen && (
                          <div className="d-flex flex-wrap align-items-center justify-content-end me-n4 flex-gap">
                            <FormGroup className="d-flex align-items-center mb-0 me-4 mb-4 mb-xxl-0">
                              <Label htmlFor="sortBy" className="mb-0 me-3">
                                Подреди по:
                              </Label>
                              <div
                                className={clsx({
                                  'non-default-select-filter':
                                    formik.values.sortBy !== CourseIndexFilterInitialValues.sortBy,
                                })}
                              >
                                <SelectControl
                                  name="sortBy"
                                  options={COURSE_INDEX_FILTER_SORT_BY_DATA}
                                  isSearchable={false}
                                  hasSolidBackground={false}
                                  className="w-150px"
                                />
                              </div>
                            </FormGroup>

                            <FormGroup className="d-flex align-items-center mb-0 me-4">
                              <Label htmlFor="itemsPerPage" className="mb-0 me-3">
                                Обучители на страница:
                              </Label>
                              <div
                                className={clsx({
                                  'non-default-select-filter':
                                    formik.values.itemsPerPage != CourseIndexFilterInitialValues.itemsPerPage,
                                })}
                              >
                                <SelectControl
                                  name="itemsPerPage"
                                  options={ITEMS_PER_PAGE_SHORT_DATA}
                                  isSearchable={false}
                                  hasSolidBackground={false}
                                  className="w-80px"
                                />
                              </div>
                            </FormGroup>
                          </div>
                        )}
                      </div>
                    </div>
                  </PageTop>
                  {isLargeScreen && <hr className="bg-gray-500 hr-subject-description" />}
                </Content>
              </div>
            )}

            {!isLargeScreen && (
              <CourseIndexPageMobileFilters
                subjectSlug={subjectSlug}
                handleStickyStateChange={handleStickyStateChange}
                handleAllFiltersToggle={handleFilterToggle}
                isFilterToggled={isFilterToggled}
                isSubjectFilterToggled={isSubjectFilterToggled}
                setIsSubjectFilterToggled={setIsSubjectFilterToggled}
              />
            )}

            <div id="content-container-wrapper" ref={contentContainerRef}>
              <Content isContainerContent className={clsx({ 'mb-10': shouldShowSimilarCourses })}>
                {Boolean(shouldShowSimilarCourses) && (
                  <>
                    <p className="mb-9 fw-bold text-center">Няма намерени резултати по зададените от Вас критерии.</p>

                    <hr className="bg-gray-600" />

                    <h1 className="text-gray-800 my-9 fw-bolder">Подобни търсения</h1>

                    <Masonry
                      className="d-flex mt-8 me-n3"
                      breakpointCols={{ default: 2, [GRID_BREAKPOINTS.SIZE_PX_XXL]: 1 }}
                    >
                      {store.index.similarCourses.map((tutorWithCourse: TutorWithCourse) => (
                        <TutorWithCoursesItem key={tutorWithCourse.id} tutorWithCourses={tutorWithCourse} />
                      ))}
                    </Masonry>
                  </>
                )}

                <Row className="g-9">
                  {Boolean(isEmpty && store.index.similarCourses.length === 0) && (
                    <p className="mb-0 fw-bold text-center">Няма намерени обучения.</p>
                  )}
                </Row>
                <Masonry
                  className="d-flex mt-8 me-n3"
                  breakpointCols={{ default: 2, [GRID_BREAKPOINTS.SIZE_PX_XXL]: 1 }}
                >
                  {store.index.tutorsWithCourses.map((tutorWithCourse: TutorWithCourse) => (
                    <TutorWithCoursesItem key={tutorWithCourse.id} tutorWithCourses={tutorWithCourse} />
                  ))}
                </Masonry>
              </Content>
              <Content isContainerContent className={clsx('pt-0', { 'p-0': isEmpty })}>
                <div className="d-flex justify-content-center">
                  <Pagination
                    total={store.index.tutorsWithCoursesTotal}
                    perPage={Number(formik.values.itemsPerPage)}
                    currentPage={Number(formik.values.page)}
                    onChange={handlePageChange}
                    isLoading={loadingState === LoadingState.Pending}
                  />
                </div>
              </Content>
            </div>

            <SupportTicketContactUs />
          </div>
        </div>
        {loadingState === LoadingState.Pending && <GlobalSpinner />}
      </Page>
    </>
  );
}
