import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Select2Option } from '@/app/components/SelectControl/models';
import {
  MessengerMessage,
  MessengerMessageReadAt,
  MessengerNotificationItemValues,
  MessengerThread,
  MessengerThreadAttachmentValues,
  MessengerThreadPayload,
  MessengerThreadPreviousMessagesPayload,
  MessengerThreadValues,
  MessengerThreadVideoConference,
} from '@/app/modules/messenger/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

const ENDPOINT_PREFIX = '/ajax/messenger';

export const ENDPOINTS = {
  threadsResource: `${ENDPOINT_PREFIX}/threads`,
  threadsIndexPaginated: `${ENDPOINT_PREFIX}/threads/paginated`,
  entity: (id: number) => `${ENDPOINT_PREFIX}/threads/${id}`,
  threadMessages: (id: number) => `${ENDPOINT_PREFIX}/threads/${id}/messages`,
  threadAttachments: (id: number) => `${ENDPOINT_PREFIX}/threads/${id}/attachments`,
  recipients: (id: number) => `${ENDPOINT_PREFIX}/recipients/${id}`,
  lastMessages: (id: number) => `${ENDPOINT_PREFIX}/threads/${id}/last-messages`,
  lastMessagesReadAt: (id: number) => `${ENDPOINT_PREFIX}/threads/${id}/last-messages-read-at`,
  previousMessages: (id: number, lastMessageID: number) =>
    `${ENDPOINT_PREFIX}/threads/${id}/previous-messages/${lastMessageID}`,
  testRoomThreads: `${ENDPOINT_PREFIX}/test-room-threads`,
  threadVideoMessage: (id: number) => `${ENDPOINT_PREFIX}/threads/${id}/video-message`,
  videoConference: (id: number) => `${ENDPOINT_PREFIX}/threads/${id}/video`,
};

//#region Get messenger message recipient
export interface GetRecipientResponse {
  recipient: Select2Option;
}

export const getRecipient = createAsyncThunk('messenger/getRecipient', async function (id: number) {
  const response = await axios.get<GetRecipientResponse>(ENDPOINTS.recipients(id));
  return response.data;
});
//#endregion Get messenger message recipient

//#region Get messenger threads
export const getMessengerThreads = createAsyncThunk('messenger/getMessengerThreads', async function () {
  const response = await axios.get<GetMessengerThreadIndexResponse>(ENDPOINTS.threadsResource);
  return response.data;
});

export interface GetMessengerThreadIndexResponse {
  threads: MessengerThread[];
}
//#endregion Get messenger threads

//#region Get messenger messages
export const getMessengerThreadsPaginated = createAsyncThunk<GetMessengerThreadsPaginatedResponse, { page?: number }>(
  'messenger/getMessengerThreadsPaginated',
  async ({ page = 1 }) => {
    const response = await axios.get<GetMessengerThreadsPaginatedResponse>(ENDPOINTS.threadsIndexPaginated, {
      params: { page },
    });
    return response.data;
  }
);

export interface GetMessengerThreadsPaginatedResponse {
  threads: MessengerNotificationItemValues[];
  unreadThreadCount: number;
  hasMore: boolean;
  currentPage: number;
}
//#endregion Get messenger messages

//#region Get messenger thread last 20 messages
export const getMessengerThreadLastMessages = createAsyncThunk(
  'messenger/getMessengerThreadMessages',
  async function (id: number) {
    const response = await axios.get<GetMessengerThreadMessagesResponse>(ENDPOINTS.lastMessages(id));

    return response.data;
  }
);

export interface GetMessengerThreadMessagesResponse {
  messages: MessengerMessage[];
}
//#endregion Get messenger thread last 20 messages

//#region Get messenger thread last 20 messages with read at statuses
export const getMessengerThreadLastMessagesReadAt = createAsyncThunk(
  'messenger/getMessengerThreadLastMessagesReadAt',
  async function (id: number) {
    const response = await axios.get<GetMessengerThreadMessagesReadAtResponse>(ENDPOINTS.lastMessagesReadAt(id));

    return response.data;
  }
);

export interface GetMessengerThreadMessagesReadAtResponse {
  messages: MessengerMessageReadAt[];
}
//#endregion Get messenger thread last 20 messages with read at statuses

//#region Get messenger thread previous messages
export const getMessengerThreadPreviousMessages = createAsyncThunk(
  'messenger/getMessengerPreviousMessages',
  async function ({ id, lastMessageID }: MessengerThreadPreviousMessagesPayload) {
    const response = await axios.get<GetMessengerThreadPreviousMessagesResponse>(
      ENDPOINTS.previousMessages(id, lastMessageID)
    );

    return response.data;
  }
);

export interface GetMessengerThreadPreviousMessagesResponse {
  messages: MessengerMessage[];
  hasMore: boolean;
  totalMessages: number;
}
//#endregion Get messenger previous messages

//#region Get messenger thread
export const getMessengerThread = createAsyncThunk(
  'messenger/getMessengerThread',
  async function ({ id, lastMessageID }: MessengerThreadPayload) {
    const response = await axios.get<GetMessengerThreadShowResponse>(ENDPOINTS.threadMessages(id), {
      params: { lastMessageID },
    });
    return response.data;
  }
);

export interface GetMessengerThreadShowResponse {
  thread: MessengerThread;
  threads: MessengerThread[];
  messages: MessengerMessage[];
  hasMore: boolean;
  totalMessages?: number;
  messagesStartIndex?: number;
}
//#endregion Get messenger thread

//#region Store message reply
export const storeMessengerThreadReply = createAsyncThunk(
  'messenger/storeMessengerThreadReply',
  async function ({ id, values }: { id: number; values: MessengerThreadValues }) {
    const formData = serializeFormData(values);
    const response = await axios.post<StoreMessengerThreadReplyResponse>(ENDPOINTS.threadMessages(id), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  }
);

export interface StoreMessengerThreadReplyResponse extends Record<string, unknown> {
  message: MessengerMessage;
  messageCreatedAt: string;
  isFirstMessageFromStudentToTutor: boolean;
  hasAnyPurchasesForTutorCourses: boolean;
  purchasesTotalAmount: number;
}
//#endregion Store message reply

//#region Store message attachments
export const storeMessengerThreadAttachments = createAsyncThunk(
  'messenger/storeMessengerThreadAttachments',
  async function ({ id, values }: { id: number; values: MessengerThreadAttachmentValues }) {
    const formData = serializeFormData(values);
    const response = await axios.post<StoreMessengerThreadAttachmentsResponse>(
      ENDPOINTS.threadAttachments(id),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data;
  }
);

export interface StoreMessengerThreadAttachmentsResponse extends Record<string, unknown> {
  messages: MessengerMessage[];
}
//#endregion Store message attachments

//#region Create message
export async function createMessengerThread(values: MessengerThreadValues) {
  const formData = serializeFormData(values);
  const response = await axios.post<CreateMessengerThreadResponse>(ENDPOINTS.threadsResource, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export interface CreateMessengerThreadResponse extends Record<string, unknown> {
  threadID: number;
  messageCreatedAt: string;
  isFirstMessageFromStudentToTutor: boolean;
  hasAnyPurchasesForTutorCourses: boolean;
  purchasesTotalAmount: number;
  externalContactsWarning?: boolean;
}
//#endregion Create message

//#region Create test room message
export async function createTestRoomMessengerThread(values: MessengerThreadValues) {
  const formData = serializeFormData(values);
  const response = await axios.post<CreateTestRoomMessengerThreadResponse>(ENDPOINTS.testRoomThreads, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export interface CreateTestRoomMessengerThreadResponse extends Record<string, unknown> {
  threadID: number;
  messageCreatedAt: string;
}
//#endregion Create test room message

//#region Send Video Conference Message
export interface SendVideoConferenceMessageResponse extends Record<string, unknown> {
  message: MessengerMessage;
}

export const sendVideoConferenceMessage = createAsyncThunk(
  'messenger/sendVideoConferenceMessage',
  async function (id: number) {
    const response = await axios.post<SendVideoConferenceMessageResponse>(ENDPOINTS.threadVideoMessage(id));

    return response.data;
  }
);
//#endregion Send Video Conference Message

//#region Get Messenger Thread Video Conference Data
export type GetMessengerThreadVideoConferenceResponse = MessengerThreadVideoConference;

export const getMessengerThreadVideoConference = createAsyncThunk(
  'messenger/getMessengerThreadVideoConference',
  async function (threadID: number) {
    const response = await axios.get<GetMessengerThreadVideoConferenceResponse>(ENDPOINTS.videoConference(threadID));

    return response.data;
  }
);
//#endregion Get Messenger Thread Video Conference Data
