import { ChangeEvent, useState } from 'react';
import Feedback from 'react-bootstrap/Feedback';
import clsx from 'clsx';
import { useFormikContext } from 'formik';

import './FileControl.scss';

type FileControlProps = {
  name: string;
  accept?: string;
  maxSize?: number;
};

export function FileControl({ name, accept = '.png, .jpg, .jpeg', maxSize = 5242880 }: FileControlProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<File | null>(name);
  const [fileName, setFileName] = useState(field.value?.name ?? field.initialValue?.name);
  const fieldHelpers = formik.getFieldHelpers<File | null>(name);
  const isInvalid = Boolean(field.error);

  function resetFileField(error: string) {
    fieldHelpers.setError(error);
    fieldHelpers.setValue(field.initialValue ?? null);
    setFileName(field.initialValue?.name ?? '');
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    const newFile = event?.currentTarget?.files?.item(0);

    if (!newFile) {
      return;
    }

    if (!(newFile.type.includes('image') || (accept.includes('.pdf') && newFile.type.includes('application/pdf')))) {
      const error = 'Типът на избрания файл не се поддържа';
      resetFileField(error);
      return;
    }

    if (newFile?.size > maxSize) {
      const maxSizeKB = maxSize / 1024;
      const error = `Избрания файл не беше добавен поради повишаването на лимита за размер от ${maxSizeKB}KB: `;
      resetFileField(error);
      return;
    }

    setFileName(newFile?.name ?? '');
    formik.setFieldValue(name, newFile);
  }

  return (
    <>
      <div className={clsx('d-flex align-items-center', { 'is-invalid': isInvalid })}>
        <div
          className={clsx('form-control-lg form-control-solid form-control rounded-end-0 overflow-hidden', {
            'is-invalid': isInvalid,
          })}
        >
          <div className="file-name">{fileName}</div>
        </div>
        <label className="btn btn-lg btn-light-primary rounded-start-0">
          Прикачи
          <input className="d-none" name={name} type="file" onChange={handleFileChange} accept={accept} />
        </label>
      </div>
      {isInvalid && <Feedback type="invalid">{field.error}</Feedback>}
    </>
  );
}
