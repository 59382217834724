import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Select2Option } from '@/app/components/SelectControl/models';
import { TimelineIndexFiltersQueryParams } from '@/app/components/Timeline/TimelineIndexFilters/models';
import { ProfileTimelinePayload } from '@/app/models/ProfileTimeline';
import { ProfileTimeline, TimelineEvent } from '@/app/models/Timeline';

import { MyStudent } from './models';

const ENDPOINT_PREFIX = '/ajax/profile/my-students';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  timelineEntity: (id: number) => `${ENDPOINT_PREFIX}/timelines/${id}`,
};

//#region PROFILE MY STUDENT INDEX
export const getProfileMyStudents = createAsyncThunk(
  'profileMyStudent/getProfileMyStudents',
  async function (filters: TimelineIndexFiltersQueryParams) {
    const response = await axios.get<GetProfileMyStudentsResponse>(ENDPOINTS.resource, {
      params: filters,
    });
    return response.data;
  }
);

export interface GetProfileMyStudentsResponse {
  myStudents: MyStudent[];
}
//#endregion PROFILE MY STUDENT INDEX

//#region PROFILE MY STUDENT TIMELINE SHOW
export interface GetProfileTimelineResponse {
  timeline: ProfileTimeline;
  timelineEvents: TimelineEvent[];
  timelineEventTypes: Select2Option[];
  timeSlots: Select2Option[];
  hasMore: boolean;
  currentPage: number;
}

export const getProfileMyStudentTimelineShowData = createAsyncThunk(
  'profileMyStudent/getProfileMyStudentTimelineShowData',
  async function ({ timelineID, page = 1, filters = {} }: ProfileTimelinePayload) {
    const response = await axios.get<GetProfileTimelineResponse>(ENDPOINTS.timelineEntity(timelineID), {
      params: { page, filters },
    });
    return response.data;
  }
);

//#endregion PROFILE MY STUDENT TIMELINE SHOW
