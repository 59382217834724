import { ReactNode } from 'react';
import clsx from 'clsx';

type PaginationButtonProps = {
  children: ReactNode;
  isLight?: boolean;
  onClick?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
};

export function PaginationButton({
  children,
  onClick,
  isLight = true,
  isActive = false,
  isDisabled = false,
}: PaginationButtonProps) {
  return (
    <button
      onClick={onClick}
      className={clsx('btn btn-sm btn-icon ms-2 my-1', {
        'btn-light-primary': isLight,
        active: isActive,
      })}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}
