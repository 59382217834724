import { addDays, format, formatDistance, isAfter, isEqual, isToday, parse, subMinutes } from 'date-fns'; // eslint-disable-line import/no-duplicates
import bg from 'date-fns/locale/bg'; // eslint-disable-line import/no-duplicates
import produce from 'immer';

import { CalendarEvent, createNotRepeatingCalendarEvent } from '@/app/components/Calendar/models';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_MONTH_FORMAT,
  DEFAULT_DATETIME_FORMAT,
} from '@/app/components/Form/DatePickerControl/constants';
import {
  MyScheduleFilterQueryParams,
  MyScheduleFilterValues,
  ReservationTimeSlotEntity,
} from '@/app/modules/my-schedule/models';
import { stringifyQuery } from '@/app/utils/query';

import { OPEN_LESSON_BUFFER_MINUTES } from './constants';

export function transformTimeSlotEntityToCalendarEvent(timeSlotEntity: ReservationTimeSlotEntity): CalendarEvent {
  return createNotRepeatingCalendarEvent(
    String(timeSlotEntity.id),
    parse(timeSlotEntity.startDateTime as string, DEFAULT_DATETIME_FORMAT, new Date()),
    parse(timeSlotEntity.endDateTime as string, DEFAULT_DATETIME_FORMAT, new Date()),
    undefined,
    null,
    {
      timeSlotStatusID: timeSlotEntity.timeSlotStatusID,
      reservationStatusID: timeSlotEntity.reservationStatusID,
      reservationStudentID: timeSlotEntity.reservationStudentID,
      reservationCourseID: timeSlotEntity.reservationCourseID,
      reservationCourseName: timeSlotEntity.reservationCourseName,
      reservationID: timeSlotEntity.reservationID,
      lessonID: timeSlotEntity.lessonID,
      participantIdentityID: timeSlotEntity.participantIdentityID,
      participantName: timeSlotEntity.participantName,
      participantProfilePicturePath: timeSlotEntity.participantProfilePicturePath,
      participantID: timeSlotEntity.tutorTransliteratedSlug,
      isTutor: timeSlotEntity.isTutor,
      connectedStudentName: timeSlotEntity.connectedStudentName,
      reservationGroupID: timeSlotEntity.reservationGroupID,
      subjectName: timeSlotEntity.subjectName,
      targetAudienceName: timeSlotEntity.targetAudienceName,
      tutorName: timeSlotEntity.tutorName,
      canCancel: timeSlotEntity.canCancel,
      courseTransliteratedSlug: timeSlotEntity.courseTransliteratedSlug,
      hasProposal: timeSlotEntity.hasProposal ?? 0,
      timeSlotID: timeSlotEntity.timeSlotID,
      isFreeProposal: timeSlotEntity.isFreeProposal,
      isSpecialProposal: timeSlotEntity.isSpecialProposal,
    }
  );
}

export function isCurrentDay(reservationDate: string) {
  const reservationTime = parse(reservationDate as string, DEFAULT_DATE_FORMAT, new Date());
  return isToday(reservationTime);
}

export function formatDateFromDateFormat(reservationDate: string) {
  const date = parse(reservationDate as string, DEFAULT_DATE_FORMAT, new Date());
  return format(date, DEFAULT_DATE_MONTH_FORMAT, { locale: bg });
}

export function formatWeekDayFromDateFormat(reservationDate: string) {
  const date = parse(reservationDate as string, DEFAULT_DATE_FORMAT, new Date());
  return format(date, 'EEEE', { locale: bg });
}

export function formatDateFromDateTimeFormat(reservationDate: string) {
  const date = parse(reservationDate as string, DEFAULT_DATETIME_FORMAT, new Date());
  return format(date, DEFAULT_DATE_MONTH_FORMAT, { locale: bg });
}

export function formatWeekDayFromDateTimeFormat(reservationDate: string) {
  const date = parse(reservationDate as string, DEFAULT_DATETIME_FORMAT, new Date());
  return format(date, 'EEEE', { locale: bg });
}

export function formatDistanceFromDateTimeString(dateTime: string) {
  const date = parse(dateTime as string, DEFAULT_DATETIME_FORMAT, new Date());
  return formatDistance(date, new Date(), { locale: bg });
}

export function transformMyScheduleFormikValuesToSearchParams(
  formikValues: MyScheduleFilterValues,
  queryParams: MyScheduleFilterQueryParams
) {
  const newQueryParams: MyScheduleFilterQueryParams = produce(queryParams, (draft) => {
    if (formikValues.course.text !== '') {
      draft.course = formikValues.course;
    } else {
      delete draft.course;
    }

    if (formikValues.reservationStatusID.length > 0) {
      draft.reservationStatusID = formikValues.reservationStatusID;
    } else {
      delete draft.reservationStatusID;
    }
  });

  return stringifyQuery(newQueryParams);
}

export function areFiltersApplied(formikValues: MyScheduleFilterValues) {
  return formikValues.course.text !== '' || formikValues.reservationStatusID.length !== 0;
}

export function isLessonRoomAvailable(lessonStartDateTime: Date) {
  const now = new Date();
  const lessonAvailableDateTime = subMinutes(lessonStartDateTime, OPEN_LESSON_BUFFER_MINUTES);

  return isEqual(now, lessonAvailableDateTime) || isAfter(now, lessonAvailableDateTime);
}

export function isAfterOneDay(startDateTime: Date | undefined) {
  if (!startDateTime) {
    return false;
  }

  const tomorow = addDays(new Date(), 1);

  return isAfter(startDateTime, tomorow);
}
