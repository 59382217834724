import React, { useMemo } from 'react';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { MultipleFileControl } from '@/app/components/Form/MultipleFileControl/MultipleFileControl';
import { TextareaControl } from '@/app/components/Form/TextareaControl/TextareaControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { LessonFeedbackFormValues } from '@/app/modules/lesson/models';
import { createLessonFeedback } from '@/app/modules/lesson/service';

type LessonFeedbackFormProps = {
  lessonID: number;
  onSuccess?: () => void;
  onSectionHide?: () => void;
};

const LESSON_FEEDBACK_PLACEHOLDER =
  'Напишете коментар към обучаемия за проведения урок.\nТой ще бъде видим както за него, така и за Вас, за да Ви помогне да проследявате историята на обучението.';

export function LessonEndFeedbackForm({ lessonID, onSuccess, onSectionHide }: LessonFeedbackFormProps) {
  const initialValues = useMemo<LessonFeedbackFormValues>(
    () => ({
      comment: '',
      attachments: [],
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        comment: yup.string().required().max(512),
      }),
    []
  );

  const formik = useForm<LessonFeedbackFormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<LessonFeedbackFormValues>(async (values) => {
        return await createLessonFeedback(lessonID, values);
      });
      const response = await submitWithValidation(values, formikHelpers);
      if (response) {
        onSuccess?.();
      }
    },
  });

  function handleCancelBtnClick() {
    formik.resetForm();
    onSectionHide?.();
  }

  return (
    <Form formik={formik} onSubmit={handleFrontEndValidations(formik)} skipDirtyPrompt>
      <FormGroup className="mb-4 text-start" controlId="comment">
        <Label>Коментар</Label>
        <TextareaControl
          name="comment"
          rows={10}
          placeholder={LESSON_FEEDBACK_PLACEHOLDER}
          hasSolidBackground={false}
        />
      </FormGroup>

      <FormGroup className="mb-4 text-start" controlId="attachments">
        <Label>Файлове</Label>
        <MultipleFileControl name="attachments" accept=".pdf, .png, .jpg, .jpeg, .doc, .docx" />
      </FormGroup>
      <div className="d-flex justify-content-center ">
        <Button type="button" variant="secondary" onClick={handleCancelBtnClick}>
          Отказ
        </Button>

        <Button type="submit" variant="primary" className="ms-4" isLoading={formik.isSubmitting}>
          Запази
        </Button>
      </div>
    </Form>
  );
}
