import React from 'react';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';
import { useFormikContext } from 'formik';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { useAppSelector } from '@/redux/store';

import { DropzoneControlState, FileControlValue } from '../models';

import './DropzoneControl.scss';

type DropzoneControlProps = {
  name: string;
  children: React.ReactNode;
  dropzoneControlState: DropzoneControlState;
  className?: string;
  isReadOnly?: boolean;
  isWholePageDroppable?: boolean;
};

export function DropzoneControl({
  name,
  children,
  dropzoneControlState,
  className = '',
  isReadOnly = false,
  isWholePageDroppable = false,
}: DropzoneControlProps) {
  const isGlobalDragging = useAppSelector((state) => state.drag.isGlobalDragging);
  const { isDragActive, getRootProps, getInputProps } = dropzoneControlState;
  const formik = useFormikContext();
  const field = formik.getFieldMeta<FileControlValue[]>(name);
  const isInvalid = Boolean(field.error);

  return (
    <>
      <div
        className={clsx('dropzone-control', className, {
          'dropzone-control--global-dragging': !isReadOnly && isGlobalDragging,
          'dropzone-control--whole-page': !isReadOnly && isGlobalDragging && isWholePageDroppable,
          'is-invalid': isInvalid,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} className={clsx({ 'is-invalid': isInvalid })} name={name} />

        {children}

        <div
          className={clsx('dropzone-control__container', {
            'dropzone-control__container--global-dragging': !isReadOnly && isGlobalDragging,
            'dropzone-control__container--whole-page': !isReadOnly && isGlobalDragging && isWholePageDroppable,
            'dropzone-control__container--active': !isReadOnly && isDragActive,
          })}
        >
          <div
            className={clsx('dropzone-control__content', {
              'dropzone-control__content--global-dragging': !isReadOnly && isGlobalDragging,
              'dropzone-control__content--active': !isReadOnly && isDragActive,
              'dropzone-control__content--whole-page': !isReadOnly && isGlobalDragging && isWholePageDroppable,
            })}
          >
            <SVG
              src={toAbsoluteUrl('/img/svg/icons/Files/Upload.svg')}
              className={clsx('dropzone-control__icon', {
                'dropzone-control__icon--active': !isReadOnly && isDragActive,
              })}
            />
            <p className="dropzone-control__text">Оставете файловете за качване тука</p>
          </div>
        </div>
      </div>
    </>
  );
}
