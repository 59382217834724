import { ReactNode } from 'react';
import clsx from 'clsx';

type CardSubTitleProps = {
  children: ReactNode;
  className?: string;
};

export function CardSubTitle({ children, className }: CardSubTitleProps) {
  return <p className={clsx('text-gray-500 fs-5 mt-2 mb-0', className)}>{children}</p>;
}
