import React, { useEffect, useRef } from 'react';
import Feedback from 'react-bootstrap/Feedback';
import { useFormikContext } from 'formik';

import { SUPPORT_TICKET_ATTACHMENT_ACCEPT } from '@/app/modules/support-ticket/constants';

import { DropzoneControl } from '../DropzoneControl/DropzoneControl';
import { useDropzoneControl } from '../DropzoneControl/useDropzoneControl';
import { IconButton } from '../IconButton/IconButton';
import { FileControlError, FileControlValue } from '../models';
import { ReorderableFiles } from './ReorderableFiles';
import { reorderFiles } from './utils';

import './MultipleFileControl.scss';

type MultipleFileControlProps = {
  name: string;
  accept?: string;
  maxFiles?: number;
  maxSize?: number;
  isReadOnly?: boolean;
  group?: string | null;
};

export function MultipleFileControl({
  name,
  accept,
  maxFiles,
  maxSize,
  isReadOnly = false,
  group = null,
}: MultipleFileControlProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<FileControlValue[]>(name);
  const fieldHelpers = formik.getFieldHelpers<FileControlValue[]>(name);
  const fieldError = field.error as unknown as FileControlError[];
  const dropzoneControlState = useDropzoneControl({ name: name, accept, maxFiles, maxSize, isReadOnly, group });
  const isReadOnlyFinal = isReadOnly;
  const initialValue = useRef(field.initialValue);

  useEffect(
    function handleInitialOrder() {
      if (initialValue.current) {
        fieldHelpers.setValue(reorderFiles(initialValue.current));
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <DropzoneControl name={name} dropzoneControlState={dropzoneControlState} isReadOnly={isReadOnlyFinal}>
      <ReorderableFiles name={name} />

      {!isReadOnlyFinal && (
        <>
          <button type="button" className="upload-button" onClick={dropzoneControlState.open}>
            <IconButton as="span" className="upload-button__icon-container">
              <i className="fas fa-plus" />
            </IconButton>
            <span className="upload-button__text">Добави файл</span>
          </button>
          <p className="upload-button__sub-text">{SUPPORT_TICKET_ATTACHMENT_ACCEPT}</p>
        </>
      )}

      {Boolean(fieldError) &&
        fieldError.map((error, idx) => (
          <Feedback type="invalid" key={idx}>
            {error?.file}
          </Feedback>
        ))}
    </DropzoneControl>
  );
}
