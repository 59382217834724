import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';

import { TutorInvoiceDetailsEditPage } from './edit/TutorInvoiceDetailsEditPage';
import { TutorInvoiceDetailsShowPage } from './show/TutorInvoiceDetailsShowPage';

export function ProfileInvoiceDetailsPage() {
  return (
    <Switch>
      <Route path="/profile/invoice-details" exact>
        <TutorInvoiceDetailsShowPage />
      </Route>
      <Route path="/profile/invoice-details/edit" exact>
        <TutorInvoiceDetailsEditPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
