import { ItemsPerPage } from '@/app/components/Pagination/models';

import {
  TutorEducationalDegreeID,
  TutorEducationalDegreeText,
  TutorIndexFilterSortBy,
  TutorIndexFilterValues,
  TutorTeachingExperienceID,
  TutorTeachingExperienceText,
} from './models';

export const IBAN_REGEX =
  '^AL\\d{10}[0-9A-Z]{16}$|^AD\\d{10}[0-9A-Z]{12}$|^AT\\d{18}$|^BH\\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\\d{14}$|^BA\\d{18}$|^BG\\d{2}[A-Z]{4}\\d{6}[0-9A-Z]{8}$|^HR\\d{19}$|^CY\\d{10}[0-9A-Z]{16}$|^CZ\\d{22}$|^DK\\d{16}$|^FO\\d{16}$|^GL\\d{16}$|^DO\\d{2}[0-9A-Z]{4}\\d{20}$|^EE\\d{18}$|^FI\\d{16}$|^FR\\d{12}[0-9A-Z]{11}\\d{2}$|^GE\\d{2}[A-Z]{2}\\d{16}$|^DE\\d{20}$|^GI\\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\\d{9}[0-9A-Z]{16}$|^HU\\d{26}$|^IS\\d{24}$|^IE\\d{2}[A-Z]{4}\\d{14}$|^IL\\d{21}$|^IT\\d{2}[A-Z]\\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\\d{5}[0-9A-Z]{13}$|^KW\\d{2}[A-Z]{4}22!$|^LV\\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\\d{6}[0-9A-Z]{20}$|^LI\\d{7}[0-9A-Z]{12}$|^LT\\d{18}$|^LU\\d{5}[0-9A-Z]{13}$|^MK\\d{5}[0-9A-Z]{10}\\d{2}$|^MT\\d{2}[A-Z]{4}\\d{5}[0-9A-Z]{18}$|^MR13\\d{23}$|^MU\\d{2}[A-Z]{4}\\d{19}[A-Z]{3}$|^MC\\d{12}[0-9A-Z]{11}\\d{2}$|^ME\\d{20}$|^NL\\d{2}[A-Z]{4}\\d{10}$|^NO\\d{13}$|^PL\\d{10}[0-9A-Z]{,16}n$|^PT\\d{23}$|^RO\\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\\d{2}[A-Z]\\d{10}[0-9A-Z]{12}$|^SA\\d{4}[0-9A-Z]{18}$|^RS\\d{20}$|^SK\\d{22}$|^SI\\d{17}$|^ES\\d{22}$|^SE\\d{22}$|^CH\\d{7}[0-9A-Z]{12}$|^TN59\\d{20}$|^TR\\d{7}[0-9A-Z]{17}$|^AE\\d{21}$|^GB\\d{2}[A-Z]{4}\\d{14}$';

export const NOT_VALID_IBAN_MESSAGE = 'IBAN трябва да бъде валиден международен номер на банкова сметка.';

export const DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE: ItemsPerPage = 20;
export const DEFAULT_PAGE_VALUE = 1;
export const MAX_RATING = '5.0';
export const DEFAULT_SEARCH_VALUE = '';
export const MAX_NUMBER_OF_SUBJECTS_IN_CARD = 3;

export const TUTOR_INDEX_FILTER_SORT_BY_DATA = [
  {
    id: TutorIndexFilterSortBy.Rating,
    text: 'Рейтинг',
  },
  {
    id: TutorIndexFilterSortBy.Popularity,
    text: 'Брой покупки',
  },
];

export const DEFAULT_SORT_BY_FILTER_VALUE = TutorIndexFilterSortBy.Rating;

export const TUTOR_EDUCATIONAL_DEGREE_SELECT_OPTIONS = [
  {
    id: TutorEducationalDegreeID.Pupil,
    text: TutorEducationalDegreeText.Pupil,
  },
  {
    id: TutorEducationalDegreeID.HighSchool,
    text: TutorEducationalDegreeText.HighSchool,
  },
  {
    id: TutorEducationalDegreeID.Student,
    text: TutorEducationalDegreeText.Student,
  },
  {
    id: TutorEducationalDegreeID.University,
    text: TutorEducationalDegreeText.University,
  },
];

export const TUTOR_TEACHING_EXPERIENCE_OPTIONS = [
  {
    id: TutorTeachingExperienceID.None,
    name: TutorTeachingExperienceText.None,
  },
  {
    id: TutorTeachingExperienceID.UpToFiveYears,
    name: TutorTeachingExperienceText.UpToFiveYears,
  },
  {
    id: TutorTeachingExperienceID.MoreThanFiveYears,
    name: TutorTeachingExperienceText.MoreThanFiveYears,
  },
];

export const TutorIndexFilterInitialValues: TutorIndexFilterValues = {
  page: DEFAULT_PAGE_VALUE,
  sortBy: DEFAULT_SORT_BY_FILTER_VALUE,
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
  search: DEFAULT_SEARCH_VALUE,
};
