import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { serializeFormData } from '@/app/utils/serializeFormData';

import { CourseFeedback, CourseFeedbackFormValues, CourseFeedbackReminder } from './models';

const ENDPOINT_PREFIX = '/ajax/courses';

export const ENDPOINTS = {
  feedback: (id: number) => `${ENDPOINT_PREFIX}/${id}/feedback`,
  reminder: `${ENDPOINT_PREFIX}/feedback-reminder`,
};

//#region Get course feedback
export interface GetCourseFeedbackResponse {
  courseFeedback: CourseFeedback;
}

export const getCourseFeedback = createAsyncThunk(
  'courseFeedback/getCourseFeedback',
  async function (courseID: number) {
    const response = await axios.get<GetCourseFeedbackResponse>(ENDPOINTS.feedback(courseID));
    return response.data;
  }
);
//#endregion Get course feedback

//#region Update course feedback
export async function updateCourseFeedback(courseID: number, values: CourseFeedbackFormValues) {
  const formData = serializeFormData(values);
  const response = await axios.put<number>(ENDPOINTS.feedback(courseID), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion Update course feedback

//#region Get course feedback reminder
export interface GetCourseFeedbackReminderResponse {
  courseFeedbackReminder: CourseFeedbackReminder;
}

export const getCourseFeedbackReminder = createAsyncThunk('courseFeedback/getCourseFeedbackReminder', async () => {
  const response = await axios.get<GetCourseFeedbackReminderResponse>(ENDPOINTS.reminder);
  return response.data;
});
//#endregion Get course feedback reminder
