import React, { useCallback, useEffect, useMemo } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';
import * as yup from 'yup';

import { Form } from '@/app/components/Form/Form';
import { DropzoneControlState } from '@/app/components/Form/models';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformNewMessageEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { Recipient } from '@/app/modules/lesson/models';
import { VIRTUAL_ROOM_MESSENGER_REPLY_PLACEHOLDER } from '@/app/modules/messenger/constants';
import { MessengerContainer } from '@/app/modules/messenger/MessengerContainer';
import { MessengerListSection } from '@/app/modules/messenger/MessengerListSection';
import { MessengerReplyControl } from '@/app/modules/messenger/MessengerReplyControl';
import { MessengerReplySection } from '@/app/modules/messenger/MessengerReplySection';
import { MessengerSendButton } from '@/app/modules/messenger/MessengerSendButton';
import { MessengerThreadAttachmentModal } from '@/app/modules/messenger/MessengerThreadAttachmentModal';
import { MessengerUploadButton } from '@/app/modules/messenger/MessengerUploadButton';
import { MessengerThreadValues } from '@/app/modules/messenger/models';
import {
  CreateMessengerThreadResponse,
  getMessengerThread,
  StoreMessengerThreadReplyResponse,
} from '@/app/modules/messenger/service';
import messengerSlice from '@/app/modules/messenger/store';
import { getNewMessageItemFromMessageCreatePage } from '@/app/modules/messenger/utils';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagNewMessageEvent } from '@/app/utils/google-analytics/customEvents';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import './MessengerThread.scss';

type VirtualRoomMessengerProps = {
  id: number | null;
  virtuosoRef: React.MutableRefObject<VirtuosoHandle | null>;
  handleChatToggle: () => void;
  onSubmit: (values: MessengerThreadValues) => Promise<unknown>;
  recipient?: Recipient | null;
  isStudent?: boolean;
};

export function VirtualRoomMessenger({
  id,
  virtuosoRef,
  handleChatToggle,
  onSubmit,
  recipient = null,
  isStudent = false,
}: VirtualRoomMessengerProps) {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const hasMore = useAppSelector((state) => state.messenger.hasMore);
  const lastMessageID = useAppSelector((state) => state.messenger.lastMessageID);
  const messenger = useAppSelector((state) => state.messenger);

  //#region Handle Load Data
  useEffect(function componentDidMount() {
    return function componentDidUnmount() {
      dispatch(messengerSlice.actions.resetMessages());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function handleLoadData() {
      if (id && hasMore) {
        await dispatch(getMessengerThread({ id: Number(id), lastMessageID: lastMessageID }));
      }
    }
    handleLoadData();
  }, [id, hasMore, lastMessageID]); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle Load Data

  //#region Setup Formik
  const initialValues = useMemo<MessengerThreadValues>(
    () => ({
      body: EMPTY_VALUE,
      attachments: [],
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        body: yup.array().requiredRichText(),
      }),
    []
  );

  async function trackNewMessageEvent(
    submitResponse: CreateMessengerThreadResponse | StoreMessengerThreadReplyResponse
  ) {
    let response = submitResponse;
    if (response.payload) {
      response = response.payload as StoreMessengerThreadReplyResponse;
    }

    if (response.isFirstMessageFromStudentToTutor) {
      const newMessageItem = getNewMessageItemFromMessageCreatePage(response, auth.twoNames, recipient?.name as string);

      const conversionEventValues = await transformNewMessageEventToConversionEventValues(
        FACEBOOK_PIXEL_CUSTOM_EVENTS.newMessage,
        newMessageItem
      );
      tagNewMessageEvent(newMessageItem, conversionEventValues.eventID);
      await trackAnalyticsEventConversionsAPI(conversionEventValues);
    }
  }

  async function handleSubmit(values: MessengerThreadValues) {
    const response = await onSubmit(values);
    if (isStudent && recipient) {
      await trackNewMessageEvent(response as CreateMessengerThreadResponse | StoreMessengerThreadReplyResponse);
    }

    if (response) {
      virtuosoRef?.current?.scrollToIndex?.({
        index: messenger.messengerMessages.length - 1,
        behavior: 'smooth',
      });

      formik.resetForm();
    }
  }

  const formik = useForm<MessengerThreadValues>({
    initialValues,
    validationSchema,
    initialStatus: messenger.loading,
    onSubmit: handleBackEndValidation<MessengerThreadValues>(handleSubmit),
  });
  //#endregion Setup Formik

  //#region Utility Methods
  const handleLoadMore = useCallback(() => {
    dispatch(messengerSlice.actions.setLastMessageID());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Utility Methods

  return (
    <MessengerContainer className="virtual-room-messenger">
      <div className="d-flex p-5 align-items-center">
        <h1 className="m-0">Съобщения</h1>
        <button
          type="button"
          className="virtual-room-messenger__close-btn btn ms-auto me-n3"
          onClick={handleChatToggle}
        >
          <i className="fas fa-times" />
        </button>
      </div>
      <MessengerListSection
        threadID={id}
        handleLoadMore={handleLoadMore}
        messengerMessages={messenger.messengerMessages}
        virtuosoRef={virtuosoRef}
      />
      <Form
        formik={formik}
        disabled={formik.isSubmitting}
        onSubmit={handleFrontEndValidations(formik)}
        className="messenger-reply-form border-top"
      >
        <MessengerReplySection>
          {(dropzoneControlState: DropzoneControlState) => {
            return (
              <>
                <MessengerThreadAttachmentModal
                  id={Number(id)}
                  name="attachments"
                  dropzoneControlState={dropzoneControlState}
                  virtuosoRef={virtuosoRef}
                  recipientIdentityID={recipient?.id ?? null}
                />
                <MessengerUploadButton onClick={dropzoneControlState.open} isLoading={formik.isSubmitting} />
                <MessengerReplyControl
                  messengerReplyPlaceholder={VIRTUAL_ROOM_MESSENGER_REPLY_PLACEHOLDER}
                  className="messenger-reply-form__body--virtual-room"
                  richTextClassName="me-3"
                />
                <MessengerSendButton isLoading={formik.isSubmitting} buttonClassName="ms-auto me-3" />
              </>
            );
          }}
        </MessengerReplySection>
      </Form>
    </MessengerContainer>
  );
}
