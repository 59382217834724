export const MIN_REQUIRED_AGE = 13;

export const ABOUT_FIELD_PLACEHOLDER = 'Напишете персонално обръщение към обучаемите...';

export enum FileTypes {
  Image = 'file_types.image',
  Video = 'file_types.video',
  PDF = 'file_types.pdf',
  WordDocument = 'file_types.doc',
  ExcelDocument = 'file_types.xls',
  PowerPointPresentation = 'file_types.presentation',
}
