import { ChangeEvent } from 'react';
import { useFormikContext } from 'formik';

import { adjustPrice } from '@/app/utils/money';

import { FormControl } from '../FormControl/FormControl';
import { FormControlProps } from '../models';

export function PriceControl(props: FormControlProps) {
  const formik = useFormikContext();

  function handlePriceChange(event: ChangeEvent<HTMLInputElement>) {
    const newPrice = event.target.value;
    formik.setFieldValue(props.name, adjustPrice(newPrice));
  }

  return (
    <>
      <FormControl {...props} onChange={handlePriceChange} />
    </>
  );
}
