import { KTSVG } from '@/_metronic/helpers';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { Size } from '@/app/components/Form/models';

interface SearchControlProps {
  name?: string;
  placeholder?: string;
  size?: Size;
}

export function SearchControl({ name = 'search', placeholder = 'Търсене', size = 'lg' }: SearchControlProps) {
  return (
    <div className="position-relative">
      <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
        <KTSVG path="/img/svg/icons/General/Search.svg" className="svg-icon-1 svg-icon-primary" />
      </span>
      <FormControl name={name} placeholder={placeholder} className="px-16" autoComplete="off" size={size} />
    </div>
  );
}
