import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { TextareaControl } from '@/app/components/Form/TextareaControl/TextareaControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformCustomEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { rejectReservation } from '@/app/modules/reservation/service';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagBookingConfirmedEvent } from '@/app/utils/google-analytics/customEvents';
import { CustomEventItem } from '@/app/utils/google-analytics/models';

import { ReservationRejectionValues } from '../../reservation/models';

const DESCRIPTION_FIELD_PLACEHOLDER = 'Напишете причината за отказ';

type RejectReservationModalProps = {
  isModalShown: boolean;
  reservationID: number;
  handleModalClose: () => void;
  analyticsCustomEventItem?: CustomEventItem;
};

export function RejectReservationModal({
  isModalShown,
  reservationID,
  handleModalClose,
  analyticsCustomEventItem,
}: RejectReservationModalProps) {
  const history = useHistory();

  const initialValues = useMemo<ReservationRejectionValues>(
    () => ({
      reasonForRejection: '',
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        reasonForRejection: yup.string().required().max(256),
      }),
    []
  );

  async function handleAnalyticsCustomEvent() {
    if (analyticsCustomEventItem) {
      const conversionEventValues = await transformCustomEventToConversionEventValues(
        FACEBOOK_PIXEL_CUSTOM_EVENTS.bookingCancellation,
        analyticsCustomEventItem
      );
      tagBookingConfirmedEvent(analyticsCustomEventItem, conversionEventValues.eventID);
      await trackAnalyticsEventConversionsAPI(conversionEventValues);
    }
  }

  const formik = useForm<ReservationRejectionValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const isConfirmed = confirm('Сигурни ли сте, че искате да откажете резервацията?');
      if (!isConfirmed) {
        handleModalClose();
        return;
      }

      const submitWithValidation = handleBackEndValidation<ReservationRejectionValues>(async (values) => {
        return await rejectReservation(reservationID, values);
      });

      const response = await submitWithValidation(values, formikHelpers);
      if (response) {
        await handleAnalyticsCustomEvent();
        history.push(`/courses/reservations/${reservationID}`);
      }
    },
  });

  return (
    <Modal show={isModalShown} onHide={handleModalClose} contentClassName="text-center" centered>
      <Modal.Header closeButton className="border-0 flex-wrap position-relative">
        <Modal.Title className="ms-auto flex-grow-1 px-10 fs-2x fw-bold">Отказ от час</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-md-10 pt-md-0">
        <Form
          formik={formik}
          disabled={formik.isSubmitting}
          onSubmit={handleFrontEndValidations(formik)}
          skipDirtyPrompt
        >
          <FormGroup controlId="reasonForRejection">
            <TextareaControl name="reasonForRejection" rows={4} placeholder={DESCRIPTION_FIELD_PLACEHOLDER} />
          </FormGroup>

          <div className="d-flex justify-content-center flex-wrap mt-7 font-weight-bold font-size-h6">
            <Button type="submit" variant="light-danger" className="me-5 mb-3 mb-sm-0">
              Откажи часа
            </Button>

            <Button onClick={handleModalClose} variant="primary">
              Затвори
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
