import { FREE_INDIVIDUAL_LESSON_COUPON_TITLE, GOOGLE_ANALYTICS_CUSTOM_EVENTS } from './constants';
import { CustomEventItem, EcommerceEventItem, NewMessageEventItem } from './models';

export function tagRegistrationEvent(pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.registration,
      pixelEventID: pixelEventID,
    });
  }
}

export function tagTutorRegistrationEvent(pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.registrationTutor,
      pixelEventID: pixelEventID,
    });
  }
}

export function tagStudentRegistrationEvent(pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.registrationStudent,
      pixelEventID: pixelEventID,
    });
  }
}

export function tagShkoloConsultationEvent(pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.shkoloConsultation,
      pixelEventID: pixelEventID,
    });
  }
}

export function tagConsultationCompleteEvent(pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.consultationComplete,
      pixelEventID: pixelEventID,
    });
  }
}

export function tagBookingCompletedEvent(item: CustomEventItem, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.bookingCompleted,
      pixelEventID: pixelEventID,
      item_name: item.item_name,
      item_id: item.item_id,
      item_brand: item.item_brand,
      item_category: item.item_category,
      item_category2: item.item_category2,
    });
  }
}

export function tagBookingConfirmedEvent(item: CustomEventItem, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.bookingConfirmation,
      pixelEventID: pixelEventID,
      item_name: item.item_name,
      item_id: item.item_id,
      item_brand: item.item_brand,
      item_category: item.item_category,
      item_category2: item.item_category2,
    });
  }
}

export function tagBookingCancellationEvent(item: CustomEventItem, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.bookingCancellation,
      pixelEventID: pixelEventID,
      item_name: item.item_name,
      item_id: item.item_id,
      item_brand: item.item_brand,
      item_category: item.item_category,
      item_category2: item.item_category2,
    });
  }
}

export function tagLessonCompletedEvent(item: CustomEventItem, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.lessonCompleted,
      pixelEventID: pixelEventID,
      item_name: item.item_name,
      item_id: item.item_id,
      item_brand: item.item_brand,
      item_category: item.item_category,
      item_category2: item.item_category2,
    });
  }
}

export function tagNewCustomerEvent(item: EcommerceEventItem, orderID: number, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.newCustomer,
      pixelEventID: pixelEventID,
      currency: item.currency,
      transactionId: orderID,
      transactionTotal: item.price,
      item_id: item.id,
      item_name: item.name,
      item_brand: item.brand,
      item_category: item.category,
      ecommerce: {
        purchase: {
          actionField: {
            coupon: FREE_INDIVIDUAL_LESSON_COUPON_TITLE,
          },
        },
      },
    });
  }
}

export function tagNewMessageEvent(item: NewMessageEventItem, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.newMessage,
      pixelEventID: pixelEventID,
      sender: item.sender,
      recipient: item.recipient,
      date: item.date,
      has_purchases: item.has_purchases,
      purchases_value: item.purchases_value,
    });
  }
}

export function tagLeadEvent(item: EcommerceEventItem, orderID: number, pixelEventID: string) {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GOOGLE_ANALYTICS_CUSTOM_EVENTS.lead,
      pixelEventID: pixelEventID,
      currency: item.currency,
      transactionId: orderID,
      transactionTotal: item.price,
      item_id: item.id,
      item_name: item.name,
      item_brand: item.brand,
      item_category: item.category,
      ecommerce: {
        purchase: {
          actionField: {
            coupon: FREE_INDIVIDUAL_LESSON_COUPON_TITLE,
          },
        },
      },
    });
  }
}
