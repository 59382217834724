import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { getActivePromotion, GetPromotionDataResponse } from './service';

interface PromotionState {
  isPreloaded: boolean;
  loading: LoadingState;
  error?: string | null;
  canUseLessonPromotion: boolean;
  canUseBirthdayPromoCode: boolean;
  canUseAlphabetPromoCode: boolean;
  activePromotionSlug: string | null;
  discount: number | null;
  discountFormatted: string | null;
}

declare global {
  interface SSRStore {
    promotion: GetPromotionDataResponse;
  }
}

function setPromotionData(state: PromotionState, payload: GetPromotionDataResponse) {
  state.canUseLessonPromotion = payload.canUseLessonPromotion;
  state.canUseBirthdayPromoCode = payload.canUseBirthdayPromoCode;
  state.canUseAlphabetPromoCode = payload.canUseAlphabetPromoCode;
  state.activePromotionSlug = payload.activePromotionSlug;
  state.discount = payload.discount;

  if (payload.discount) {
    state.discountFormatted = `${Math.round(payload.discount * 100)}%`;
  }
}

export function createInitialState(): PromotionState {
  const initialState: PromotionState = {
    isPreloaded: false,
    loading: LoadingState.Idle,
    error: null,
    canUseLessonPromotion: true,
    canUseBirthdayPromoCode: false,
    canUseAlphabetPromoCode: false,
    activePromotionSlug: null,
    discount: null,
    discountFormatted: null,
  };

  if (import.meta.env.SSR && window?.context?.store?.promotion) {
    initialState.isPreloaded = true;
    setPromotionData(initialState, window?.context?.store?.promotion);
  }

  return initialState;
}

const promotionSlice = createSlice({
  name: 'promotion',
  initialState: createInitialState(),
  reducers: {
    setCanUseLessonPromotion(state, action: PayloadAction<boolean>) {
      state.canUseLessonPromotion = action.payload;
    },
  },
  extraReducers: (builder) => {
    //#region Get active promotion data
    builder
      .addCase(getActivePromotion.pending, sharedPendingReducer)
      .addCase(getActivePromotion.rejected, sharedRejectedReducer)
      .addCase(getActivePromotion.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          setPromotionData(state, action.payload);
        }
      });
    //#endregion Get active promotion data
  },
});

export default promotionSlice;
