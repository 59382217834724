import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

import { TutorStatusID } from '@/app/models/TutorStatusID';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer } from '@/redux/utils';

import { UpdateBalanceAmountAction } from './models';
import {
  getAuthData,
  GetAuthDataResponse,
  getAvailableEnrolledCoursesCount,
  getExternalID,
  getFeedbackSurveyData,
  getTutorHasSchedule,
  getTutorStatus,
  getUpdatedIdentityAvatar,
  sendTutorForApproval,
  SharedBecomeTutorResponse,
  storeFeedbackSurveyVisit,
  storeStudent,
} from './service';

interface AuthState {
  isPreloaded: boolean;
  loading: LoadingState;
  tutorLoading: LoadingState;
  getUpdatedIdentityAvatarLoading: LoadingState;
  getExternalIDLoading: LoadingState;
  storeStudentLoading: LoadingState;
  canSeeFeedbackSurveyLoading: LoadingState;
  availableEnrolledCoursesCountLoading: LoadingState;
  hasScheduleLoading: LoadingState;
  error?: string | null;
  identityID?: number;
  firstName: string;
  twoNames: string;
  email: string;
  profilePicturePath: string | null;
  tutorStatusID?: TutorStatusID;
  balanceAmount: string;
  shouldHighlightBalance: boolean;
  studentID: number | null;
  tutorID: number | null;
  isDiaryUser: boolean;
  isAuthenticated: boolean;
  shouldLeaveCourseFeedback: boolean;
  privacyAcceptedAt: string | null;
  termsAcceptedAt: string | null;
  overFourteenYearsOldAgreedAt: string | null;
  externalID: string | null;
  canSeeFeedbackSurvey: boolean;
  feedbackSurveyVisitID: number | null;
  availableEnrolledCoursesCount: number | null;
  hasSchedule: boolean;

  apps: {
    mainWebAppUrl: string;
    vsWebAppUrl: string;
    portfolioWebAppUrl: string;
    plWebAppUrl: string;
  };
}

declare global {
  interface SSRStore {
    auth: GetAuthDataResponse;
  }
}

function setAuthData(state: AuthState, payload: GetAuthDataResponse) {
  state.identityID = payload.identityID;
  state.twoNames = payload.twoNames ?? '';
  state.firstName = payload.firstName ?? '';
  state.email = payload.email ?? '';
  state.profilePicturePath = payload.profilePicturePath;
  state.tutorStatusID = payload.tutorStatusID;
  state.balanceAmount = payload.balanceAmount;
  state.studentID = payload.studentID;
  state.tutorID = payload.tutorID;
  state.isDiaryUser = payload.isDiaryUser;
  state.apps = payload.apps;
  state.shouldLeaveCourseFeedback = payload.shouldLeaveCourseFeedback;
  state.privacyAcceptedAt = payload.privacyAcceptedAt;
  state.termsAcceptedAt = payload.termsAcceptedAt;
  state.overFourteenYearsOldAgreedAt = payload.overFourteenYearsOldAgreedAt;

  if (payload.identityID) {
    state.isAuthenticated = true;
  }
}

export function createInitialState(): AuthState {
  const initialState: AuthState = {
    isPreloaded: false,
    loading: LoadingState.Idle,
    tutorLoading: LoadingState.Idle,
    getUpdatedIdentityAvatarLoading: LoadingState.Idle,
    getExternalIDLoading: LoadingState.Idle,
    storeStudentLoading: LoadingState.Idle,
    canSeeFeedbackSurveyLoading: LoadingState.Idle,
    availableEnrolledCoursesCountLoading: LoadingState.Idle,
    hasScheduleLoading: LoadingState.Idle,
    error: null,
    identityID: undefined,
    firstName: '',
    twoNames: '',
    email: '',
    profilePicturePath: null,
    tutorStatusID: undefined,
    balanceAmount: '',
    shouldHighlightBalance: false,
    studentID: null,
    tutorID: null,
    isDiaryUser: false,
    isAuthenticated: false,
    shouldLeaveCourseFeedback: false,
    privacyAcceptedAt: null,
    termsAcceptedAt: null,
    overFourteenYearsOldAgreedAt: null,
    externalID: null,
    canSeeFeedbackSurvey: false,
    feedbackSurveyVisitID: null,
    availableEnrolledCoursesCount: null,
    hasSchedule: true,
    apps: {
      mainWebAppUrl: '',
      vsWebAppUrl: '',
      portfolioWebAppUrl: '',
      plWebAppUrl: '',
    },
  };

  if (import.meta.env.SSR && window?.context?.store?.auth) {
    initialState.isPreloaded = true;
    setAuthData(initialState, window?.context?.store?.auth);
  }

  return initialState;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: createInitialState(),
  reducers: {
    updateBalanceAmount(state, action: PayloadAction<UpdateBalanceAmountAction>) {
      state.balanceAmount = action.payload.balanceAmount;
      state.shouldHighlightBalance = Boolean(action.payload.shouldHighlightBalance);
    },
    resetAuthentication(state) {
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    //#region AUTH

    //#region Get auth data
    builder
      .addCase(getAuthData.pending, sharedPendingReducer)
      .addCase(getAuthData.rejected, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.error = action.error.message;
          state.isAuthenticated = false;
        }
      })
      .addCase(getAuthData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          setAuthData(state, action.payload);
        }
      });
    //#endregion Get auth data

    //#region Get External ID
    builder
      .addCase(getExternalID.pending, (state) => {
        if (state.getExternalIDLoading === LoadingState.Idle) {
          state.getExternalIDLoading = LoadingState.Pending;
        }
      })
      .addCase(getExternalID.rejected, (state, action) => {
        if (state.getExternalIDLoading === LoadingState.Idle) {
          state.getExternalIDLoading = LoadingState.Pending;
          state.error = action.error.message;
        }
      })
      .addCase(getExternalID.fulfilled, (state, action) => {
        if (state.getExternalIDLoading === LoadingState.Pending) {
          state.getExternalIDLoading = LoadingState.Idle;
          state.externalID = action.payload.externalID;
        }
      });
    //#endregion Get External ID

    //#region GET UPDATED IDENTITY AVATAR
    builder
      .addCase(getUpdatedIdentityAvatar.pending, (state) => {
        if (state.getUpdatedIdentityAvatarLoading === LoadingState.Idle) {
          state.getUpdatedIdentityAvatarLoading = LoadingState.Pending;
        }
      })
      .addCase(getUpdatedIdentityAvatar.rejected, (state, action) => {
        if (state.getUpdatedIdentityAvatarLoading === LoadingState.Idle) {
          state.getUpdatedIdentityAvatarLoading = LoadingState.Pending;
          state.error = action.error.message;
        }
      })
      .addCase(getUpdatedIdentityAvatar.fulfilled, (state, action) => {
        if (state.getUpdatedIdentityAvatarLoading === LoadingState.Pending) {
          state.getUpdatedIdentityAvatarLoading = LoadingState.Idle;
          state.profilePicturePath = action.payload.identityAvatar;
        }
      });
    //#endregion GET UPDATED IDENTITY AVATAR

    //#endregion AUTH

    //#region BECOME TUTOR

    function sharedBecomeTutorPendingReducer(state: WritableDraft<AuthState>) {
      if (state.tutorLoading === LoadingState.Idle) {
        state.tutorLoading = LoadingState.Pending;
      }
    }

    function sharedBecomeTutorRejectedReducer(
      state: WritableDraft<AuthState>,
      action: PayloadAction<unknown, string, unknown, SerializedError>
    ) {
      if (state.tutorLoading === LoadingState.Idle) {
        state.tutorLoading = LoadingState.Pending;
        state.error = action.error.message;
      }
    }

    function sharedBecomeTutorFulfilledReducer(
      state: WritableDraft<AuthState>,
      action: PayloadAction<SharedBecomeTutorResponse>
    ) {
      if (state.tutorLoading === LoadingState.Pending) {
        state.tutorLoading = LoadingState.Idle;
        state.tutorStatusID = action.payload.tutorStatusID;
        state.tutorID = action.payload.tutorID;
      }
    }

    builder
      .addCase(getTutorStatus.pending, sharedBecomeTutorPendingReducer)
      .addCase(getTutorStatus.rejected, sharedBecomeTutorRejectedReducer)
      .addCase(getTutorStatus.fulfilled, sharedBecomeTutorFulfilledReducer);

    builder
      .addCase(sendTutorForApproval.pending, sharedBecomeTutorPendingReducer)
      .addCase(sendTutorForApproval.rejected, sharedBecomeTutorRejectedReducer)
      .addCase(sendTutorForApproval.fulfilled, sharedBecomeTutorFulfilledReducer);

    //#endregion BECOME TUTOR

    //#region STORE STUDENT
    builder
      .addCase(storeStudent.pending, (state) => {
        if (state.storeStudentLoading === LoadingState.Idle) {
          state.storeStudentLoading = LoadingState.Pending;
        }
      })
      .addCase(storeStudent.rejected, (state, action) => {
        if (state.storeStudentLoading === LoadingState.Idle) {
          state.storeStudentLoading = LoadingState.Pending;
          state.error = action.error.message;
        }
      })
      .addCase(storeStudent.fulfilled, (state, action) => {
        if (state.storeStudentLoading === LoadingState.Pending) {
          state.storeStudentLoading = LoadingState.Idle;
          state.studentID = action.payload.studentID;
        }
      });
    //#endregion STORE STUDENT

    //#region GET FEEDBACK SURVEY DATA
    builder
      .addCase(getFeedbackSurveyData.pending, (state) => {
        if (state.canSeeFeedbackSurveyLoading === LoadingState.Idle) {
          state.canSeeFeedbackSurveyLoading = LoadingState.Pending;
        }
      })
      .addCase(getFeedbackSurveyData.rejected, (state, action) => {
        if (state.canSeeFeedbackSurveyLoading === LoadingState.Idle) {
          state.canSeeFeedbackSurveyLoading = LoadingState.Pending;
          state.error = action.error.message;
        }
      })
      .addCase(getFeedbackSurveyData.fulfilled, (state, action) => {
        if (state.canSeeFeedbackSurveyLoading === LoadingState.Pending) {
          state.canSeeFeedbackSurveyLoading = LoadingState.Idle;
          state.canSeeFeedbackSurvey = action.payload.canSeeFeedbackSurvey;
        }
      });
    //#endregion GET FEEDBACK SURVEY DATA

    //#region STORE FEEDBACK SURVEY VISIT
    builder
      .addCase(storeFeedbackSurveyVisit.pending, (state) => {
        if (state.canSeeFeedbackSurveyLoading === LoadingState.Idle) {
          state.canSeeFeedbackSurveyLoading = LoadingState.Pending;
        }
      })
      .addCase(storeFeedbackSurveyVisit.rejected, (state, action) => {
        if (state.canSeeFeedbackSurveyLoading === LoadingState.Idle) {
          state.canSeeFeedbackSurveyLoading = LoadingState.Pending;
          state.error = action.error.message;
        }
      })
      .addCase(storeFeedbackSurveyVisit.fulfilled, (state, action) => {
        if (state.canSeeFeedbackSurveyLoading === LoadingState.Pending) {
          state.canSeeFeedbackSurveyLoading = LoadingState.Idle;
          state.feedbackSurveyVisitID = action.payload.feedbackSurveyVisitID;
        }
      });
    //#endregion STORE FEEDBACK SURVEY VISIT
    //# region Get not booked enrolled courses quantity
    builder
      .addCase(getAvailableEnrolledCoursesCount.pending, (state) => {
        if (state.loading === LoadingState.Idle) {
          state.availableEnrolledCoursesCountLoading = LoadingState.Pending;
        }
      })
      .addCase(getAvailableEnrolledCoursesCount.rejected, (state, action) => {
        if (state.availableEnrolledCoursesCountLoading === LoadingState.Idle) {
          state.availableEnrolledCoursesCountLoading = LoadingState.Pending;
          state.error = action.error.message;
        }
      })
      .addCase(getAvailableEnrolledCoursesCount.fulfilled, (state, action) => {
        if (state.availableEnrolledCoursesCountLoading === LoadingState.Pending) {
          state.availableEnrolledCoursesCountLoading = LoadingState.Idle;
          state.availableEnrolledCoursesCount = action.payload.availableEnrolledCoursesCount;
        }
      });
    //# endregion Get not booked enrolled courses quantity
    //# region Get tutor has schedule
    builder
      .addCase(getTutorHasSchedule.pending, (state) => {
        if (state.loading === LoadingState.Idle) {
          state.hasScheduleLoading = LoadingState.Pending;
        }
      })
      .addCase(getTutorHasSchedule.rejected, (state, action) => {
        if (state.hasScheduleLoading === LoadingState.Idle) {
          state.hasScheduleLoading = LoadingState.Pending;
          state.error = action.error.message;
        }
      })
      .addCase(getTutorHasSchedule.fulfilled, (state, action) => {
        if (state.hasScheduleLoading === LoadingState.Pending) {
          state.hasScheduleLoading = LoadingState.Idle;
          state.hasSchedule = action.payload.tutorHasSchedule;
        }
      });
    //# endregion Get tutor has schedule
  },
});

export default authSlice;
