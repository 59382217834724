import { BuyLessonStepNumber } from '../models';
import { calculateProgressValue } from '../utils';
import { StepperButton } from './StepperButton';

import './StepperProgressBar.scss';

type StepperProgressBarProps = {
  buyLessonStep: number;
};

export function StepperProgressBar({ buyLessonStep }: StepperProgressBarProps) {
  return (
    <div className="buy-lesson-steps">
      <progress className="buy-lesson-stepper-progress" value={calculateProgressValue(buyLessonStep)} max={100} />
      <StepperButton value={BuyLessonStepNumber.Subject} buyLessonStep={buyLessonStep} stepTitle="Предмет" />

      <StepperButton
        value={BuyLessonStepNumber.TargetAudience}
        buyLessonStep={buyLessonStep}
        stepTitle="Клас/възраст"
      />

      <StepperButton value={BuyLessonStepNumber.Course} buyLessonStep={buyLessonStep} stepTitle="Обучение" />
    </div>
  );
}
