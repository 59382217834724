import produce from 'immer';

import { stringifyQuery } from '@/app/utils/query';

import { DEFAULT_SEARCH_VALUE } from './constants';
import { TimelineIndexFiltersQueryParams, TimelineIndexFiltersValues } from './models';

export function transformTimelineIndexFormikValuesToSearchParams(
  formikValues: TimelineIndexFiltersValues,
  queryParams: TimelineIndexFiltersQueryParams
) {
  const newQueryParams: TimelineIndexFiltersQueryParams = produce(queryParams, (draft) => {
    if (formikValues.search !== DEFAULT_SEARCH_VALUE) {
      draft.search = formikValues.search;
    } else {
      delete draft.search;
    }
  });

  return stringifyQuery(newQueryParams);
}
