import { createSlice } from '@reduxjs/toolkit';

import { Subject, TargetAudience } from '@/app/modules/course/models';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { getSubjects, GetSubjectsResponse, getTopSubjects, GetTopSubjectsResponse } from './service';

interface SubjectState {
  isPreloaded: boolean;
  isTopSubjectsPreloaded: boolean;
  loading: LoadingState;
  error?: string | null;
  subjects: Subject[];
  targetAudiences: TargetAudience[];
  topSubjects: Subject[];
}

declare global {
  interface SSRStore {
    subjects: GetSubjectsResponse;
    topSubjects: GetTopSubjectsResponse;
  }
}

function setSubjectsData(state: SubjectState, payload: GetSubjectsResponse) {
  state.subjects = payload.subjects ?? [];
  state.targetAudiences = payload.targetAudiences ?? [];
}

function setTopSubjectsData(state: SubjectState, payload: GetTopSubjectsResponse) {
  state.topSubjects = payload.topSubjects ?? [];
}

export function createInitialState(): SubjectState {
  const initialState: SubjectState = {
    isPreloaded: false,
    isTopSubjectsPreloaded: false,
    loading: LoadingState.Idle,
    error: null,
    subjects: [],
    targetAudiences: [],
    topSubjects: [],
  };

  if (import.meta.env.SSR && window?.context?.store?.subjects) {
    initialState.isPreloaded = true;
    setSubjectsData(initialState, window?.context?.store?.subjects);
  }

  if (import.meta.env.SSR && window?.context?.store?.topSubjects) {
    initialState.isTopSubjectsPreloaded = true;
    setTopSubjectsData(initialState, window?.context?.store?.topSubjects);
  }

  return initialState;
}

const subjectSlice = createSlice({
  name: 'subject',
  initialState: createInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    //#region Get subjects
    builder
      .addCase(getSubjects.pending, sharedPendingReducer)
      .addCase(getSubjects.rejected, sharedRejectedReducer)
      .addCase(getSubjects.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        setSubjectsData(state, action.payload);
      });
    //#endregion Get subjects

    //#region Get Top subjects
    builder
      .addCase(getTopSubjects.pending, sharedPendingReducer)
      .addCase(getTopSubjects.rejected, sharedRejectedReducer)
      .addCase(getTopSubjects.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        setTopSubjectsData(state, action.payload);
      });
    //#endregion Get Top subjects
  },
});

export default subjectSlice;
