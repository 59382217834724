import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';

import { RatingIcon } from './RatingIcon';

type RatingRadioInputProps = {
  name: string;
  value: number;
};

export function RatingRadioInput({ name, value }: RatingRadioInputProps) {
  const formik = useFormikContext();
  const [field, fieldMeta] = useField({ name, value, type: 'radio' });
  const id = `${name}_${value}`;

  if (formik.isSubmitting) {
    return (
      <div className={clsx('rating-label opacity-50', { checked: Number(fieldMeta.value) >= Number(value) })}>
        <span className="svg-icon svg-icon-3x">
          <RatingIcon />
        </span>
      </div>
    );
  }

  return (
    <>
      <label className={clsx('rating-label', { checked: Number(value) <= Number(fieldMeta.value) })} htmlFor={id}>
        <span className="svg-icon svg-icon-3x">
          <RatingIcon />
        </span>
      </label>
      <input
        className="rating-input"
        type="radio"
        id={id}
        {...field}
        checked={Number(fieldMeta.value) === Number(value)}
        disabled={formik.isSubmitting}
      />
    </>
  );
}
