import { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from '@/app/components/Button/Button';

type ArchiveCoursePackageModalConfig = {
  onConfirm: (coursePackageID: number) => void | Promise<unknown>;
};

type ArchiveCoursePackageModalInstance = {
  isShown: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  openConfirmModal: (coursePackageID: number) => void;
};

export function useArchiveCoursePackageModal({
  onConfirm,
}: ArchiveCoursePackageModalConfig): ArchiveCoursePackageModalInstance {
  const [isShown, setIsShown] = useState(false);
  const [coursePackageID, setCoursePackageID] = useState<number | null>(null);

  const openConfirmModal = useCallback((coursePackageID: number) => {
    setCoursePackageID(coursePackageID);
    setIsShown(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsShown(false);
  }, [setIsShown]);

  const handleConfirm = useCallback(() => {
    // Sanity check.
    if (coursePackageID === null) {
      return;
    }

    onConfirm(coursePackageID);
    setCoursePackageID(null);
    setIsShown(false);
  }, [coursePackageID, onConfirm]);

  return { isShown, handleClose, handleConfirm, openConfirmModal };
}

type ArchiveCoursePackageModalProps = {
  instance: ArchiveCoursePackageModalInstance;
};

export function ArchiveCoursePackageModal({
  instance: { isShown, handleClose, handleConfirm },
}: ArchiveCoursePackageModalProps) {
  return (
    <Modal show={isShown} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Архивирай пакет</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4>Какво се случва с текущите уроци</h4>
        <p>Ако има текущи закупени уроци с този пакет, те ще продължат да съществуват до изразходване на часовете им</p>

        <h4>Какво се случва с нови уроци</h4>
        <ul>
          <li>Този пакет ще бъде архивиран и няма да се вижда в платформата.</li>
          <li>Потребителите ще могат да закупуват уроци от останалите опции за покупка на обучението.</li>
          <li>Може да създадете нов пакет със същото име и променени параметри.</li>
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Откажи
        </Button>
        <Button variant="primary" type="button" onClick={handleConfirm}>
          Архивирай
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
