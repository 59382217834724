import { Button } from '@/app/components/Button/Button';

type MessengerUploadButtonProps = {
  onClick: () => void;
  isLoading?: boolean;
};

export function MessengerUploadButton({ onClick, isLoading = false }: MessengerUploadButtonProps) {
  return (
    <Button
      onClick={onClick}
      isLoading={isLoading}
      className="messenger-reply-form__button ms-2 ms-md-3"
      isIndicatorOnlyLoading
    >
      <i className="fas fa-paperclip p-0" />
    </Button>
  );
}
