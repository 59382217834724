import { useContext, useEffect } from 'react';

import { FooterContext } from './FooterContext';

export function useHiddenFooter() {
  const { show, hide } = useContext(FooterContext);

  useEffect(() => {
    hide();

    return () => show();
  }, [hide, show]);
}
