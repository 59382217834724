import { format } from 'date-fns';

import { DEFAULT_SHORT_DATE_FORMAT } from '../Form/DatePickerControl/constants';

type DayHeaderContentProps = {
  date: Date;
  text: string;
};

export function DayHeaderContent({ date, text }: DayHeaderContentProps) {
  const shortDateText = format(date, DEFAULT_SHORT_DATE_FORMAT);

  return (
    <>
      <span className="d-block">{text}</span>
      <span className="fw-normal">{shortDateText}</span>
    </>
  );
}
