import { Dropdown } from 'react-bootstrap';

import { CheckboxControl } from '../Form/CheckboxControl/CheckboxControl';
import { CheckboxControlProps } from '../Form/CheckboxControl/models';

import './DropdownCheckboxControlItem.scss';

type DropdownCheckboxControlItem = CheckboxControlProps;

export function DropdownCheckboxControlItem({ ...props }: DropdownCheckboxControlItem) {
  return <Dropdown.Item as={CheckboxControl} {...props} className="dropdown-item--checkbox-control" />;
}
