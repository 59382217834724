import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { MessengerThreadVideoConferencePage } from '@/app/modules/messenger/MessengerThreadVideoConferencePage';
import { wrappedReactLazy } from '@/app/utils/wrapped-react-lazy';

import { MessengerThreadIndexPage } from './MessengerThreadIndexPage';

const MessengerThreadCreatePage = wrappedReactLazy(() => import('./MessengerThreadCreatePage'));
const MessengerThreadShowPage = wrappedReactLazy(() => import('./MessengerThreadShowPage'));

export function MessengerPage() {
  return (
    <Switch>
      <Route path="/messenger/create" exact>
        <MessengerThreadCreatePage />
      </Route>
      <Route path="/messenger" exact>
        <MessengerThreadIndexPage />
      </Route>
      <Route path="/messenger/:id" exact>
        <MessengerThreadShowPage />
      </Route>
      <Route path="/messenger/:id/video" exact>
        <MessengerThreadVideoConferencePage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
