import { TimelineAvatar } from '@/app/components/Timeline/TimelineAvatar';
import { TimelineContent } from '@/app/components/Timeline/TimelineContent';
import { TimelineItem } from '@/app/components/Timeline/TimelineItem';
import { TimelineMedia } from '@/app/components/Timeline/TimelineMedia';
import { TimelineEvent, TimelineEventType } from '@/app/models/Timeline';

type ProfileTimelineItemProps = {
  event: TimelineEvent;
};

export function ProfileTimelineItem({ event }: ProfileTimelineItemProps) {
  switch (event.type) {
    case TimelineEventType.ReservationRequestSent:
    case TimelineEventType.ReservationRequestApprove:
    case TimelineEventType.ReservationRequestReject:
    case TimelineEventType.ReservationProposalApprove:
    case TimelineEventType.ReservationCancelation:
    case TimelineEventType.LessonFeedbackSent: {
      return (
        <TimelineItem>
          <TimelineAvatar avatarPath={event.identityAvatarPath} />
          <TimelineContent title={event.data.title} datetime={event.eventDateTime} attachments={event.data.attachments}>
            {event.data.text}
          </TimelineContent>
        </TimelineItem>
      );
    }
    case TimelineEventType.LessonEnded: {
      return (
        <TimelineItem>
          <TimelineMedia>
            <i className="fas fa-bell fa-3x text-primary" />
          </TimelineMedia>
          <TimelineContent title={event.data.title} datetime={event.eventDateTime}>
            {event.data.text}
          </TimelineContent>
        </TimelineItem>
      );
    }
    default: {
      return null;
    }
  }
}
