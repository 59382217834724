import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Feedback from 'react-bootstrap/Feedback';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import isHotkey from 'is-hotkey';
import { createEditor, Descendant, Point, Transforms } from 'slate';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import Toastr from 'toastr';

import { LoadingState } from '@/redux/constants';

import { BlockButton } from './BlockButton';
import { EMPTY_VALUE, HOTKEYS } from './constants';
import { Element } from './Element';
import { Leaf } from './Leaf';
import { InsertLinkButton } from './LinkButtons/InsertLinkButton';
import { RemoveLinkButton } from './LinkButtons/RemoveLinkButton';
import { MarkButton } from './MarkButton';
import { withLinks } from './plugins/withLinks';
import { withLists } from './plugins/withLists';
import { getMaxLengthErrorMessage, getMaxLengthWarningMessage, getTextLength, toggleMark } from './utils';

import './RichTextControl.scss';

type RichTextControlProps = {
  name: string;
  className?: string;
  textLimit?: number;
  isToolbarShown?: boolean;
  isCharacterCounterShown?: boolean;
  isErrorShown?: boolean;
  placeholder?: string;
  submitAfterPressEnter?: boolean;
};

const LAST_SELECTIONS_LIMIT = 5;

export function RichTextControl({
  name,
  className,
  textLimit = 3000,
  isToolbarShown = true,
  isCharacterCounterShown = true,
  isErrorShown = true,
  placeholder = '',
  submitAfterPressEnter = false,
}: RichTextControlProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<Descendant[]>(name);
  const fieldHelpers = formik.getFieldHelpers<Descendant[]>(name);
  const isInvalid = isErrorShown && Boolean(field.error);

  //#region Slate
  const editor = useMemo(() => withLinks(withLists(withReact(createEditor() as ReactEditor))), []);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const lastSelections = useRef<Point[]>([
    { path: [0, 0], offset: 0 },
    { path: [0, 0], offset: 0 },
  ]);
  //#endregion

  //#region Handle prop value
  const isInternalChangeRef = useRef(false);
  const [value, setValue] = useState<Descendant[]>(field.value ?? EMPTY_VALUE);

  useEffect(
    function handleFieldValueChange() {
      setValue(field.value ?? EMPTY_VALUE);

      if (isInternalChangeRef.current === false) {
        Transforms.select(editor, { path: [0, 0], offset: 0 });
      }

      isInternalChangeRef.current = false;
    },
    [editor, field.value]
  );
  //#endregion

  useEffect(
    function () {
      if (!editor?.selection?.anchor) {
        return;
      }

      lastSelections.current.push(editor.selection.anchor);

      if (lastSelections.current.length > LAST_SELECTIONS_LIMIT) {
        lastSelections.current.shift();
      }
    },
    [editor?.selection?.anchor]
  );

  useEffect(
    function () {
      const textLength = getTextLength(value);

      if (textLength > textLimit) {
        const charactersToRemove = textLength - textLimit;
        Transforms.delete(editor, { distance: charactersToRemove, unit: 'character', reverse: true });
        Toastr.error(getMaxLengthErrorMessage(textLimit));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textLimit, value]
  );

  function handleChange(newValue: Descendant[]) {
    setValue(newValue);
    fieldHelpers.setValue(newValue);
    isInternalChangeRef.current = true;
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event.nativeEvent)) {
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }

    if (submitAfterPressEnter && event.key === 'Enter') {
      event.preventDefault();

      if (formik.isSubmitting) {
        return;
      }

      if (event.shiftKey || event.ctrlKey) {
        editor.insertBreak();
        return;
      }

      formik.submitForm();
    }
  }

  function handlePaste(event: React.ClipboardEvent<HTMLDivElement>) {
    const currentTextLength = getTextLength(value);
    const pastedText = event.clipboardData.getData('text');
    const newTextLength = currentTextLength + pastedText.length;

    if (newTextLength > textLimit) {
      //Prevent event bubble so that the handleChange callback is called after we insert the cut out text
      event.preventDefault();

      const freeCharacters = textLimit - currentTextLength;
      const pasteCharacters = pastedText.substring(0, freeCharacters);

      Transforms.insertText(editor, pasteCharacters, { voids: true });

      Toastr.warning(getMaxLengthWarningMessage(textLimit));
    }
  }

  const isDisabled = formik.status === LoadingState.Pending;

  return (
    <>
      {
        <Slate editor={editor} value={value} onChange={handleChange}>
          <div
            className={clsx('form-control form-control-solid form-control rich-text-control', className, {
              'is-invalid': isInvalid,
              'min-h-auto': !isToolbarShown || !isCharacterCounterShown,
            })}
          >
            {isToolbarShown && (
              <div className="rich-text-control__toolbar">
                <div className="rich-text-control__toolbar-group">
                  <MarkButton tooltip="Удебеляване" format="bold" isDisabled={isDisabled}>
                    <i className="fas fa-bold" />
                  </MarkButton>
                  <MarkButton tooltip="Курсив" format="italic" isDisabled={isDisabled}>
                    <i className="fas fa-italic" />
                  </MarkButton>
                  <MarkButton tooltip="Подчертаване" format="underline" isDisabled={isDisabled}>
                    <i className="fas fa-underline" />
                  </MarkButton>
                </div>

                <div className="rich-text-control__toolbar-group">
                  <BlockButton tooltip="Подравняване вляво" format="align-left">
                    <i className="fas fa-align-left" />
                  </BlockButton>
                  <BlockButton tooltip="Центриране" format="align-center">
                    <i className="fas fa-align-center" />
                  </BlockButton>
                  <BlockButton tooltip="Подравняване вдясно" format="align-right">
                    <i className="fas fa-align-right" />
                  </BlockButton>
                  <BlockButton tooltip="Двустранно подравняване" format="align-justify">
                    <i className="fas fa-align-justify" />
                  </BlockButton>
                </div>

                <div className="rich-text-control__toolbar-group">
                  <BlockButton tooltip="Номериран списък" format="numbered-list">
                    <i className="fas fa-list-ol" />
                  </BlockButton>
                  <BlockButton tooltip="Неномериран списък" format="bulleted-list">
                    <i className="fas fa-list-ul" />
                  </BlockButton>
                </div>

                <div className="rich-text-control__toolbar-group me-0">
                  <InsertLinkButton />
                  <RemoveLinkButton />
                </div>
              </div>
            )}
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onKeyDown={handleKeyDown}
              readOnly={isDisabled}
              onPaste={handlePaste}
              placeholder={placeholder}
            />
            {isCharacterCounterShown && (
              <div className="rich-text-control__characters">
                {getTextLength(value)} / {textLimit}
              </div>
            )}
          </div>
          {isInvalid && <Feedback type="invalid">{field.error}</Feedback>}
        </Slate>
      }
    </>
  );
}
