//#region Approve reservation proposal
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  ReservationProposalApprovalValues,
  ReservationProposalData,
  ReservationProposalSuggestionData,
  ReservationProposalSuggestionSummaryData,
  ReservationProposalSummaryData,
  ReservationProposalSummaryPayload,
  ReservationProposalTimeSlotsData,
  ReservationProposalValues,
} from '@/app/modules/reservation-proposal/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

const ENDPOINT_PREFIX = '/ajax/reservation-proposals';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  entity: (reservationID: number) => `${ENDPOINT_PREFIX}/${reservationID}`,
  summary: (reservationID: number) => `${ENDPOINT_PREFIX}/${reservationID}/summary`,
  availableLessons: (id: number) => `${ENDPOINT_PREFIX}/${id}/available-lessons`,
  suggestion: (reservationID: number) => `${ENDPOINT_PREFIX}/${reservationID}/suggestion`,
  timeSlots: (reservationID: number) => `${ENDPOINT_PREFIX}/${reservationID}/time-slots`,
  suggestionSummary: (reservationID: number) => `${ENDPOINT_PREFIX}/${reservationID}/suggestion-summary`,
};

//#region Get reservation proposal data
export type GetReservationProposalDataResponse = ReservationProposalData;

export const getReservationProposalData = createAsyncThunk(
  'reservationProposal/getReservationProposalData',
  async function (id: number) {
    const response = await axios.get<GetReservationProposalDataResponse>(ENDPOINTS.entity(id));
    return response.data;
  }
);
//#endregion Get reservation proposal data

//#region Get reservation proposal summary data
export type GetReservationProposalSummaryDataResponse = ReservationProposalSummaryData;

export const getReservationProposalSummaryData = createAsyncThunk(
  'reservationProposal/getReservationProposalSummaryData',
  async function ({ reservationID, orderID }: ReservationProposalSummaryPayload) {
    const response = await axios.get<GetReservationProposalSummaryDataResponse>(ENDPOINTS.summary(reservationID), {
      params: { orderID },
    });
    return response.data;
  }
);
//#endregion Get reservation proposal summary data

//#region Approve reservation proposal
export type ApproveReservationProposalResponse = {
  reservationID: number;
  isStudentsFirstOrder: boolean;
  freeReservationProposalOrderID: number | null;
};

export async function approveReservationProposal(id: number, values: ReservationProposalApprovalValues) {
  const formData = serializeFormData(values);
  const response = await axios.put<ApproveReservationProposalResponse>(ENDPOINTS.entity(id), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion Approve reservation proposal

//#region Get student's available lessons count for a course by reservation id
export interface GetCourseAvailableLessonsCountResponse {
  availableLessonsCount: number;
  shouldStudentPurchaseSpecialLessonForCourse: boolean;
  isFreeProposal: boolean;
}

export async function getCourseAvailableLessonsCount(id: number) {
  const response = await axios.get<GetCourseAvailableLessonsCountResponse>(ENDPOINTS.availableLessons(id));

  return response.data;
}
//#endregion Get student's available lessons count for a course by reservation id

//#region Get details of the reservation proposal
export const getReservationProposalSuggestionData = createAsyncThunk(
  'reservationProposal/getReservationProposalSuggestionData',
  async function (reservationID: number) {
    const response = await axios.get<ReservationProposalSuggestionData>(ENDPOINTS.suggestion(reservationID));
    return response.data;
  }
);
//#endregion Get details of the reservation proposal

//#region Get time slots of the reservation proposal
export const getReservationProposalTimeSlots = createAsyncThunk(
  'reservationProposal/getReservationProposalTimeSlots',
  async function ({ id, startDate, endDate }: { id: number; startDate?: string; endDate?: string }) {
    const response = await axios.get<ReservationProposalTimeSlotsData>(ENDPOINTS.timeSlots(id), {
      params: { startDate, endDate },
    });
    return response.data;
  }
);
//#endregion Get time slots of the reservation proposal

//#region Create reservation proposal
export async function createReservationProposal(reservationID: number, values: ReservationProposalValues) {
  const formData = serializeFormData(values);
  const response = await axios.post(ENDPOINTS.entity(reservationID), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion Create reservation proposal

//#region Get summary details of the reservation proposal
export type ReservationProposalSuggestionSummaryResponse = {
  summary: ReservationProposalSuggestionSummaryData;
};

export const getReservationProposalSuggestionSummaryData = createAsyncThunk(
  'reservationProposal/getReservationProposalSuggestionSummaryData',
  async function (reservationID: number) {
    const response = await axios.get<ReservationProposalSuggestionSummaryResponse>(
      ENDPOINTS.suggestionSummary(reservationID)
    );
    return response.data;
  }
);
//#endregion Get summary details of the reservation proposal
