import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DragState {
  isGlobalDragging: boolean;
}

const initialState: DragState = {
  isGlobalDragging: false,
};

const dragSlice = createSlice({
  name: 'drag',
  initialState,
  reducers: {
    setIsGlobalDragging(state, action: PayloadAction<boolean>) {
      state.isGlobalDragging = action.payload;
    },
  },
});

export default dragSlice;
