import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { TutorLessonFeedbackCreatePage } from '@/app/modules/profile/lesson-feedback/TutorLessonFeedbackCreatePage';

import { ProfileMyStudentIndexPage } from './ProfileMyStudentIndexPage';
import { ProfileMyStudentShowPage } from './ProfileMyStudentShowPage';

export function ProfileMyStudentPage() {
  return (
    <Switch>
      <Route path="/profile/my-students" exact>
        <ProfileMyStudentIndexPage />
      </Route>
      <Route path="/profile/my-students/lesson-feedback" exact>
        <TutorLessonFeedbackCreatePage />
      </Route>
      <Route path="/profile/my-students/timelines/:id">
        <ProfileMyStudentShowPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
