import { useContext, useEffect, useRef, useState } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';

import { Recipient } from '@/app/modules/lesson/models';
import lessonSlice from '@/app/modules/lesson/store';
import { MessengerThreadValues } from '@/app/modules/messenger/models';
import {
  createMessengerThread,
  getMessengerThreadLastMessages,
  getMessengerThreadLastMessagesReadAt,
  storeMessengerThreadReply,
} from '@/app/modules/messenger/service';
import { VirtualRoomMessenger } from '@/app/modules/messenger/VirtualRoomMessenger';
import { identityChannelName, messengerThreadChannelName } from '@/app/utils/real-time/constants';
import { EchoContext } from '@/app/utils/real-time/EchoContext';
import {
  MessengerMessageUpdated,
  MessengerSeenStatusUpdated,
  MessengerThreadCreated,
} from '@/app/utils/real-time/models';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { useMessagesLengthChange } from './useMessagesLengthChange';

import './MessengerThread.scss';

type VirtualRoomMessengerThreadProps = {
  id: number | null;
  recipient: Recipient | null;
  handleChatToggle: () => void;
  isStudent?: boolean;
};

export function VirtualRoomMessengerThread({
  id,
  recipient,
  handleChatToggle,
  isStudent = false,
}: VirtualRoomMessengerThreadProps) {
  const dispatch = useAppDispatch();
  const identityID = useAppSelector((state) => state.auth.identityID);
  const echoInstance = useContext(EchoContext);
  const virtuosoRef = useRef<VirtuosoHandle | null>(null);
  const messenger = useAppSelector((state) => state.messenger);

  async function handleSubmit(values: MessengerThreadValues) {
    const messengerThreadValues = {
      recipient: { id: recipient?.id ?? null, text: recipient?.name ?? '' },
      ...values,
    };
    if (!id) {
      const response = await createMessengerThread(messengerThreadValues);
      dispatch(lessonSlice.actions.updateMessengerThread(response.threadID));
      return response;
    }

    return await dispatch(
      storeMessengerThreadReply({ id: Number(id), values: { isStudent: isStudent, ...messengerThreadValues } })
    );
  }

  //#region Echo listeners
  useEffect(
    function handleEchoInit() {
      if (identityID === undefined || id === undefined || id === null) {
        return;
      }

      const messengerThreadChannel = `${messengerThreadChannelName}.${id}`;

      echoInstance
        ?.private?.(messengerThreadChannel)
        ?.listen?.('.messenger_message.updated', (event: MessengerMessageUpdated) => {
          dispatch(getMessengerThreadLastMessages(event.threadID));
          isAddedNewMessageToThread.current = true;
          setForceRerunMessagesLengthChange((prevValue) => prevValue + 1);
        });

      echoInstance
        ?.private?.(messengerThreadChannel)
        ?.listen?.('.messenger_seen_status.updated', async (event: MessengerSeenStatusUpdated) => {
          await dispatch?.(getMessengerThreadLastMessagesReadAt(event.threadID));
        });

      return () => {
        echoInstance?.leave?.(messengerThreadChannel);
      };
    },
    [id, identityID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function handleEchoListenForNewThreadCreate() {
      if (identityID === undefined || id !== null) {
        return;
      }

      const identityChannel = `${identityChannelName}.${identityID}`;

      echoInstance
        ?.private?.(identityChannel)
        ?.listen?.('.messenger_thread.created', (event: MessengerThreadCreated) => {
          if (event.threadID) {
            dispatch(lessonSlice.actions.updateMessengerThread(event.threadID));
          }
        });

      return () => {
        echoInstance?.leave?.(identityChannel);
      };
    },
    [id, identityID] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Echo listeners

  //#region Handle messages length change
  const isAddedNewMessageToThread = useRef(false);
  const [forceRerunMessagesLengthChange, setForceRerunMessagesLengthChange] = useState(0);
  const messengerMessagesLength = messenger.messengerMessages.length;

  useMessagesLengthChange({
    isAddedNewMessageToThread,
    virtuosoRef,
    messengerMessagesLength,
    forceRerunMessagesLengthChange,
  });
  //#endregion Handle messages length change

  return (
    <VirtualRoomMessenger
      id={id}
      virtuosoRef={virtuosoRef}
      handleChatToggle={handleChatToggle}
      onSubmit={handleSubmit}
      recipient={recipient}
      isStudent={isStudent}
    />
  );
}
