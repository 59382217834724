import { AxiosError } from 'axios';
import { FormikContextType, FormikHelpers, FormikValues } from 'formik';
import Toastr from 'toastr';

import { VALIDATION_ERROR_TOAST_MESSAGE, VALIDATION_SUCCESS_TOAST_MESSAGE } from './constants';

export function handleFrontEndValidations<T = FormikValues>(formik: FormikContextType<T>) {
  return async () => {
    const validationErrors = await formik.validateForm();
    const hasErrors = Object.keys(validationErrors).length > 0;

    if (hasErrors) {
      Toastr.error(VALIDATION_ERROR_TOAST_MESSAGE);
      formik.setSubmitting(false);
      return;
    }

    formik.handleSubmit();
  };
}

export function handleBackEndValidation<T = FormikValues>(
  onSubmit: (values: T) => Promise<unknown>
): (values: T, formikHelpers: FormikHelpers<T>) => Promise<unknown> {
  return async (values: T, formikHelpers: FormikHelpers<T>) => {
    try {
      return await onSubmit(values);
    } catch (error) {
      // Handle back-end validation.
      const internalError = error as AxiosError;

      if (internalError?.response?.status === 422 && internalError.response.data?.errors) {
        for (const fieldName in internalError.response.data.errors) {
          if (!Object.prototype.hasOwnProperty.call(internalError.response.data.errors, fieldName)) {
            continue;
          }

          const errors = internalError.response.data.errors[fieldName];
          const errorMessage = errors.join(' ');

          formikHelpers.setFieldError(fieldName, errorMessage);
        }

        Toastr.error(VALIDATION_ERROR_TOAST_MESSAGE);
      }
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
}

export function handleValidation<T>(onSubmit: (values: T) => Promise<unknown>) {
  return async (values: T) => {
    try {
      await onSubmit(values);
      Toastr.success(VALIDATION_SUCCESS_TOAST_MESSAGE);
    } catch (error) {
      // Handle back-end validation.
      const internalError = error as AxiosError;

      if (internalError?.response?.status === 422 && internalError.response.data?.errors) {
        for (const fieldName in internalError.response.data.errors) {
          if (!Object.prototype.hasOwnProperty.call(internalError.response.data.errors, fieldName)) {
            continue;
          }

          const errors = internalError.response.data.errors[fieldName];
          const errorMessage = errors.join(' ');
          Toastr.error(errorMessage);
        }
      }
    }
  };
}
