import { useEffect, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useHistory, useLocation } from 'react-router-dom';
import { addMonths, isAfter, isWithinInterval, parse } from 'date-fns';
import * as yup from 'yup';

import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { CardToolbar } from '@/app/components/Card/CardToolbar';
import { DEFAULT_DATE_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { InformationPopover } from '@/app/components/InformationPopover/InformationPopover';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { Table } from '@/app/components/Table/Table';
import { formatPrice } from '@/app/utils/money';
import { useQueryParams } from '@/app/utils/query';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import {
  DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
  DEFAULT_PAGE_VALUE,
  ITEMS_PER_PAGE_OPTIONS,
  ProfileProfitFilterInitialValues,
} from './constants';
import { SoldCoursesFilterQueryParams, SoldCoursesFilterValues } from './models';
import { ProfileProfitBasePage } from './ProfileProfitBasePage';
import { getProfileProfitSoldCoursesData } from './service';
import profileProfitSlice from './store';
import { transformProfileProfitFormikValuesToSearchParams } from './utils';

const columns: ColumnDescription[] = [
  {
    dataField: 'createdAt',
    text: 'Дата',
  },
  {
    dataField: 'expiresAt',
    text: 'Изтича на ',
    formatter: (date: string) => {
      const currentDate = parse(date, DEFAULT_DATE_FORMAT, new Date());
      const isExpired = isAfter(new Date(), currentDate);

      if (isExpired) {
        return (
          <>
            {date} <span className="text-danger">(изтекъл)</span>
          </>
        );
      }

      const isAboutToExpire = isWithinInterval(currentDate, { start: new Date(), end: addMonths(new Date(), 1) });

      if (isAboutToExpire) {
        return <span className="text-warning">{date}</span>;
      }

      return date;
    },
    headerFormatter: function fortmatHeader(column) {
      return (
        <div className="d-flex align-items-center">
          {column.text}
          <InformationPopover helperMessage="Всяко закупено обучение в платформата има срок за използване от 365 дни от датата на закупуване." />
        </div>
      );
    },
  },
  {
    dataField: 'buyerName',
    text: 'Закупил',
  },
  {
    dataField: 'courseName',
    text: 'Обучение',
  },
  {
    dataField: 'coursePackageName',
    text: 'Име на пакет',
  },
  {
    dataField: 'lessonCount',
    text: 'Брой уроци',
  },
  {
    dataField: 'amount',
    text: 'Сума',
    formatter: formatPrice,
  },
];

export function ProfileProfitSoldCoursesPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const content = useAppSelector((state) => state.profileProfit);
  const [loadingState, isInitialDataLoaded, setLoadingState] = useLoading(content.tabsLoading);
  const isLoading = loadingState === LoadingState.Pending;
  const { currentSearchParams, queryParams } = useQueryParams<SoldCoursesFilterQueryParams>();

  useEffect(
    function componentDidMount() {
      dispatch(getProfileProfitSoldCoursesData(queryParams));
    },
    [queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#region Handle pagination form
  const initialValues = useMemo<SoldCoursesFilterValues>(
    () => ({
      page: queryParams.page ?? DEFAULT_PAGE_VALUE,
      itemsPerPage: queryParams?.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
    }),
    [queryParams]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<SoldCoursesFilterValues>({
    initialValues,
    validationSchema,
    initialStatus: loadingState,
  });
  //#endregion Handle pagination form

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformProfileProfitFormikValuesToSearchParams(
        formik.values,
        formik.initialValues.itemsPerPage,
        queryParams
      );

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
        setLoadingState(LoadingState.Pending);
      }

      if (newSearchParams === '') {
        formik.setValues(ProfileProfitFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  //#region Handle page change
  useEffect(
    function handlePageChange() {
      formik.setFieldValue('page', content.currentSoldCoursesPage);
    },
    [content.currentSoldCoursesPage] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle page change

  return (
    <ProfileProfitBasePage title="Продадени обучения">
      <CardHeader isRow>
        <div>
          <CardTitle>Продадени обучения</CardTitle>
          <CardSubTitle>Закупени Ваши обучения.</CardSubTitle>
        </div>
        <CardToolbar>
          <Form formik={formik} skipDirtyPrompt>
            <FormGroup>
              <Label htmlFor="itemsPerPage" className="mb-0 me-3">
                Покажи по:
              </Label>
              <SelectControl
                name="itemsPerPage"
                options={ITEMS_PER_PAGE_OPTIONS}
                isSearchable={false}
                hasSolidBackground={false}
                className="w-175px"
              />
            </FormGroup>
          </Form>
        </CardToolbar>
      </CardHeader>
      <CardBody>
        {!isInitialDataLoaded && <Spinner animation="border" variant="primary" className="d-block mx-auto" />}
        {isInitialDataLoaded && (
          <Table
            columns={columns}
            data={content.soldCourses}
            isLoading={isLoading}
            setCurrentPageAction={profileProfitSlice.actions.setCurrentSoldCoursesPage}
            noDataIndication={
              <>
                <p className="mb-0 text-center">Все още нямате продадени обучения.</p>
              </>
            }
          />
        )}
      </CardBody>
      {isLoading && <GlobalSpinner />}
    </ProfileProfitBasePage>
  );
}
