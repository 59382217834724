import { ReactNode } from 'react';
import { RouteProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import * as H from 'history';

import { HeaderMenuButtonBadge } from '@/app/layout/HeaderMenu/HeaderMenuButtonBadge';

import { isMenuActive } from '../utils/isMenuActive';

type MenuItemProps<S> = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  children: ReactNode;
  additionalActive?: string | string[] | RouteProps | RouteProps[];
  isExact?: boolean;
  notificationsCount?: number | null;
};

export function MenuItem<S>({
  to,
  children,
  additionalActive,
  isExact = false,
  notificationsCount = null,
}: MenuItemProps<S>) {
  const isActive = isMenuActive(additionalActive);

  return (
    <div className="menu-item me-lg-3">
      <NavLink
        to={to}
        className={clsx('menu-link', 'py-3 ', 'position-relative', {
          ['pe-6']: notificationsCount,
        })}
        activeClassName="active"
        exact={isExact}
        isActive={isActive}
      >
        <span className="menu-title">{children}</span>
        {Number(notificationsCount) > 0 && <HeaderMenuButtonBadge>{notificationsCount}</HeaderMenuButtonBadge>}
      </NavLink>
    </div>
  );
}
