import { createSlice } from '@reduxjs/toolkit';

import { ConnectedStudent, ConnectedStudentLevel } from '@/app/modules/profile/connected-student/models';
import {
  getProfileConnectedStudentCreateData,
  getProfileConnectedStudentEditData,
  getProfileConnectedStudents,
  getProfileConnectedStudentShowData,
} from '@/app/modules/profile/connected-student/service';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

interface ProfileConnectedStudentsState {
  loading: LoadingState;
  error?: string | null;
  connectedStudent: ConnectedStudent;
  connectedStudents: ConnectedStudent[];
  connectedStudentLevels: ConnectedStudentLevel[];
}

const initialState: ProfileConnectedStudentsState = {
  loading: LoadingState.Idle,
  error: null,
  connectedStudent: {
    name: '',
    email: '',
    phone: '',
    connectedStudentLevelID: null,
  },
  connectedStudents: [],
  connectedStudentLevels: [],
};

const profileConnectedStudentSlice = createSlice({
  name: 'profileConnectedStudent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region GET PROFILE CONNECTED STUDENT INDEX
    builder
      .addCase(getProfileConnectedStudents.pending, sharedPendingReducer)
      .addCase(getProfileConnectedStudents.rejected, sharedRejectedReducer)
      .addCase(getProfileConnectedStudents.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.connectedStudents = action.payload.connectedStudents;
        }
      });
    //#endregion GET CONNECTED STUDENT INDEX

    //#region GET PROFILE CONNECTED STUDENT CREATE DATA
    builder
      .addCase(getProfileConnectedStudentCreateData.pending, sharedPendingReducer)
      .addCase(getProfileConnectedStudentCreateData.rejected, sharedRejectedReducer)
      .addCase(getProfileConnectedStudentCreateData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.connectedStudent = action.payload.defaultConnectedStudent;
          state.connectedStudentLevels = action.payload.connectedStudentLevels;
        }
      });
    //#endregion GET CONNECTED STUDENT CREATE DATA

    //#region GET PROFILE CONNECTED STUDENT SHOW DATA
    builder
      .addCase(getProfileConnectedStudentShowData.pending, sharedPendingReducer)
      .addCase(getProfileConnectedStudentShowData.rejected, sharedRejectedReducer)
      .addCase(getProfileConnectedStudentShowData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.connectedStudent = action.payload.connectedStudent;
        }
      });
    //#endregion GET CONNECTED STUDENT SHOW DATA

    //#region GET PROFILE CONNECTED STUDENT EDIT DATA
    builder
      .addCase(getProfileConnectedStudentEditData.pending, sharedPendingReducer)
      .addCase(getProfileConnectedStudentEditData.rejected, sharedRejectedReducer)
      .addCase(getProfileConnectedStudentEditData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.connectedStudent = action.payload.connectedStudent;
          state.connectedStudentLevels = action.payload.connectedStudentLevels;
        }
      });
    //#endregion GET CONNECTED STUDENT EDIT DATA
  },
});

export default profileConnectedStudentSlice;
