import produce from 'immer';

import { stringifyQuery } from '@/app/utils/query';

import { TimelineShowFilterQueryParams, TimelineShowFiltersValues } from './models';

export function transformTimelineShowFiltersFormikValuesToSearchParams(
  formikValues: TimelineShowFiltersValues,
  queryParams: TimelineShowFilterQueryParams
) {
  const newQueryParams: TimelineShowFilterQueryParams = produce(queryParams, (draft) => {
    if (formikValues.timelineEventTypeIds && formikValues.timelineEventTypeIds.length > 0) {
      draft.timelineEventTypeIds = formikValues.timelineEventTypeIds;
    } else {
      delete draft.timelineEventTypeIds;
    }

    if (formikValues.timeSlotIds && formikValues.timeSlotIds.length > 0) {
      draft.timeSlotIds = formikValues.timeSlotIds;
    } else {
      delete draft.timeSlotIds;
    }
  });

  return stringifyQuery(newQueryParams);
}

export function areFiltersApplied(formikValues: TimelineShowFiltersValues) {
  return formikValues.timelineEventTypeIds?.length > 0 || formikValues.timeSlotIds?.length > 0;
}
