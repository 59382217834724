import { Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { PhoneControl } from '@/app/components/Form/PhoneControl/PhoneControl';
import { RadioButtonControl } from '@/app/components/Form/RadioButtonControl/RadioButtonControl';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { CourseReservationValues } from '@/app/modules/course/models';
import { ConnectedStudentLevel, ConnectedStudentValues } from '@/app/modules/profile/connected-student/models';

type ConnectedStudentFormControlProps = {
  connectedStudents: ConnectedStudentValues[];
  connectedStudentLevels: ConnectedStudentLevel[];
};

export function ConnectedStudentFormControl({
  connectedStudents,
  connectedStudentLevels,
}: ConnectedStudentFormControlProps) {
  const formik = useFormikContext<CourseReservationValues>();
  const isActive = formik?.values?.connectedStudentID == -1 || connectedStudents.length === 0;
  return (
    <>
      <Card variant="bordered" className="p-0 mb-5">
        <Label className="mb-0">
          <CardHeader className="border-bottom-0">
            <div className="d-flex justify-content-start align-content-center">
              <RadioButtonControl name="connectedStudentID" value={-1} />
              <h4 className="ms-4 mb-0">Нов обучаем</h4>
            </div>
          </CardHeader>
        </Label>
        {isActive && (
          <CardBody>
            <Row className="g-8 mt-n8">
              <Col md={6}>
                <FormGroup controlId="connectedStudentName">
                  <Label isRequired>Имена на обучаемия</Label>
                  <FormControl type="text" name="connectedStudentName" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup controlId="connectedStudentLevelID">
                  <Label isRequired>Клас/възраст на обучаемия</Label>
                  <SelectControl
                    name="connectedStudentLevelID"
                    options={connectedStudentLevels}
                    placeholder="Изберете"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup controlId="connectedStudentPhone">
                  <Label isRequired>Телефон</Label>
                  <PhoneControl name="connectedStudentPhone" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup controlId="connectedStudentEmail">
                  <Label isRequired>Имейл адрес</Label>
                  <FormControl type="email" name="connectedStudentEmail" />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </>
  );
}
