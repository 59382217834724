import { CreateMessengerThreadResponse, StoreMessengerThreadReplyResponse } from '@/app/modules/messenger/service';
import { NewMessageEventItem } from '@/app/utils/google-analytics/models';

export function getNewMessageItemFromMessageCreatePage(
  data: CreateMessengerThreadResponse | StoreMessengerThreadReplyResponse,
  sender: string,
  recipient: string
): NewMessageEventItem {
  return {
    sender: sender,
    recipient: recipient,
    date: data.messageCreatedAt,
    has_purchases: data.hasAnyPurchasesForTutorCourses,
    purchases_value: data.purchasesTotalAmount,
  };
}
