import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import * as H from 'history';

type SideNavItemLinkProps<S> = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  children: ReactNode;
  exact?: boolean;
};

export function SideNavItemLink<S>({ to, children, exact = false }: SideNavItemLinkProps<S>) {
  return (
    <li className="nav-item">
      <NavLink to={to} exact={exact} className="nav-link side-nav-link" activeClassName="active">
        {children}
      </NavLink>
    </li>
  );
}
