import { KTSVG } from '@/_metronic/helpers';

export default function CourseArchivedListPermissions() {
  return (
    <ul className="list-group">
      <li className="bg-transparent list-group-item border-0 d-flex p-0 text-gray-700 fs-5 align-items-center mb-5">
        <KTSVG path="/img/svg/icons/ExclamationCircle.svg" className="me-3 svg-icon-2" />
        <span>Видим само от потребителите, закупили обучението</span>
      </li>

      <li className="bg-transparent list-group-item border-0 d-flex p-0 text-gray-700 fs-5 align-items-center mb-5">
        <KTSVG path="/img/svg/icons/xCircle.svg" className="me-3 svg-icon-2" />
        <span>Могат да се закупуват нови уроци</span>
      </li>

      <li className="bg-transparent list-group-item border-0 d-flex p-0 text-gray-700 fs-5 align-items-center mb-5">
        <KTSVG path="/img/svg/icons/CheckCircle.svg" className="me-3 svg-icon-2" />
        <span>Одобрявате или отказвате нови заявки за урок</span>
      </li>

      <li className="bg-transparent list-group-item border-0 d-flex p-0 text-gray-700 fs-5 align-items-center">
        <KTSVG path="/img/svg/icons/CheckCircle.svg" className="me-3 svg-icon-2" />
        <span>Провеждате уроци, за които сте одобрили заявката</span>
      </li>
    </ul>
  );
}
