import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { parse } from 'date-fns';

import { KTSVG } from '@/_metronic/helpers';
import { getIntervalFormat } from '@/app/components/Calendar/utils';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { formatDateFromDateTimeFormat, formatWeekDayFromDateTimeFormat } from '@/app/modules/my-schedule/utils';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getReservationProposalSuggestionSummaryData } from './service';

export function ReservationProposalSuggestionSummaryPage() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  //#region Handle data
  const loading = useAppSelector((state) => state.reservationProposal.loading);
  const data = useAppSelector((state) => state.reservationProposal.reservationProposalSuggestionSummary);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getReservationProposalSuggestionSummaryData(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  return (
    <>
      <Helmet>
        <title>Детайли за покана</title>
      </Helmet>
      <Page>
        <Content>
          <div className="mx-auto text-center mw-800px">
            <div className="my-10">
              <KTSVG path="/img/svg/icons/CheckCircle.svg" className="svg-icon-6tx" />
            </div>

            <h1 className="text-gray-800 fs-2tx mb-10">Успешно изпратихте покана за урок</h1>

            <Card className="text-start mb-10">
              <CardBody>
                <Row>
                  <Col className="mw-md-200px pb-6 pb-md-0">
                    <h2 className="h1 fw-bold mb-0">Обучение</h2>
                  </Col>
                  <Col className="d-flex align-items-center">
                    <h3 className="fw-bold mb-0">{data?.courseName}</h3>
                  </Col>
                </Row>

                <hr className="bg-gray-200 opacity-100 my-8" />

                <Row>
                  <Col className="mw-md-200px pb-6 pb-md-0">
                    <h2 className="h1 fw-bold mb-0">Дата</h2>
                  </Col>

                  {data && (
                    <Col xs={12} md={{ span: true }} className="my-n5">
                      <div className="reservation-group-item border-bottom text-danger">
                        <h3 className="fw-normal">
                          {formatDateFromDateTimeFormat(data.startDateTime as string)}
                          <span className="opacity-50">
                            {' '}
                            {data.startDateTime}({formatWeekDayFromDateTimeFormat(data.startDateTime as string)})
                          </span>
                        </h3>
                        <h4 className="mb-0 fw-normal">
                          {getIntervalFormat(
                            parse(data.startDateTime as string, DEFAULT_DATETIME_FORMAT, new Date()),
                            parse(data.endDateTime as string, DEFAULT_DATETIME_FORMAT, new Date())
                          )}
                        </h4>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>

            <Link to="/my-schedule" className="btn btn-light-primary">
              Kъм Моят график
            </Link>
          </div>

          {loading === LoadingState.Pending && <GlobalSpinner />}
        </Content>
      </Page>
    </>
  );
}
