import { ReactNode } from 'react';

type HeaderMenuDropdownProps = {
  children: ReactNode;
};

export function HeaderMenuDropdown({ children }: HeaderMenuDropdownProps) {
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column mw-350px mw-lg-375px overflow-hidden"
      data-kt-menu="true"
    >
      {children}
    </div>
  );
}
