import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableDataModel } from '@/app/components/Table/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

import {
  SupportTicket,
  SupportTicketAttachmentResponse,
  SupportTicketComment,
  SupportTicketCommentValues,
  SupportTicketShowModel,
  SupportTicketValues,
} from './models';

const ENDPOINT_PREFIX = '/ajax/support-tickets';

export const ENDPOINTS = {
  notifications: `${ENDPOINT_PREFIX}/notifications`,
  resource: ENDPOINT_PREFIX,
  entity: (id: number) => `${ENDPOINT_PREFIX}/${id}`,
  comments: (id: number) => `${ENDPOINT_PREFIX}/${id}/comments`,
  contact: `${ENDPOINT_PREFIX}/contact-us`,
};

//#region Get support tickets
export const getSupportTickets = createAsyncThunk<GetSupportTicketsResponse, { page?: number }>(
  'supportTickets/getSupportTickets',
  async ({ page = 1 }) => {
    const response = await axios.get<GetSupportTicketsResponse>(ENDPOINTS.resource, {
      params: { page },
    });
    return response.data;
  }
);

export interface GetSupportTicketsResponse {
  supportTickets: TableDataModel<SupportTicket[]>;
}
//#endregion Get support tickets

//#region Get support tickets notifications
export const getSupportTicketsNotifications = createAsyncThunk<
  GetSupportTicketsNotificationsResponse,
  { page?: number }
>('supportTickets/getSupportTicketsNotifications', async ({ page = 1 }) => {
  const response = await axios.get<GetSupportTicketsNotificationsResponse>(ENDPOINTS.notifications, {
    params: { page },
  });
  return response.data;
});

export interface GetSupportTicketsNotificationsResponse {
  supportTickets: SupportTicket[];
  unreadSupportTicketsCount: number;
  hasMore: boolean;
  currentPage: number;
}
//#endregion Get support tickets notifications

//#region Create support ticket
export async function createSupportTicket(values: SupportTicketValues) {
  const formData = serializeFormData(values);
  const response = await axios.post<CreateSupportTicketResponse>(ENDPOINTS.resource, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export async function createSupportTicketFromCantactUsForm(values: SupportTicketValues) {
  const formData = serializeFormData(values);
  const response = await axios.post<CreateSupportTicketResponse>(ENDPOINTS.contact, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export interface CreateSupportTicketResponse extends Record<string, unknown> {
  supportTicketID: number;
}
//#endregion Create support ticket

//#region Get support ticket
export const getSupportTicket = createAsyncThunk('supportTickets/getSupportTicket', async function (id: number) {
  const response = await axios.get<GetSupportTicketShowResponse>(ENDPOINTS.entity(id));
  return response.data;
});

export interface GetSupportTicketShowResponse {
  supportTicket: SupportTicketShowModel<SupportTicketAttachmentResponse>;
}
//#endregion Get support ticket

//#region Store support ticket comment
export async function storeSupportTicketComment({ id, values }: { id: number; values: SupportTicketCommentValues }) {
  const formData = serializeFormData(values);
  const response = await axios.post<StoreSupportTicketCommentResponse>(ENDPOINTS.comments(id), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export interface StoreSupportTicketCommentResponse extends Record<string, unknown> {
  comment: SupportTicketComment<SupportTicketAttachmentResponse>;
}
//#endregion Store support ticket comment
