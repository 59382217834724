import { produce } from 'immer';

import { stringifyQuery } from '@/app/utils/query';

import { DEFAULT_PAGE_VALUE } from './../course/constants';
import {
  DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
  DEFAULT_SEARCH_VALUE,
  DEFAULT_SORT_BY_FILTER_VALUE,
  TUTOR_EDUCATIONAL_DEGREE_SELECT_OPTIONS,
  TUTOR_TEACHING_EXPERIENCE_OPTIONS,
  TutorIndexFilterInitialValues,
} from './constants';
import { TutorIndexFilterQueryParams, TutorIndexFilterValues } from './models';

export function transformTutorIndexFormikValuesToSearchParams(
  formikValues: TutorIndexFilterValues,
  queryParams: TutorIndexFilterQueryParams
) {
  const newQueryParams: TutorIndexFilterQueryParams = produce(queryParams, (draft) => {
    if (Number(formikValues.page) !== DEFAULT_PAGE_VALUE) {
      draft.page = formikValues.page;
    } else {
      delete draft.page;
    }

    if (formikValues.sortBy !== DEFAULT_SORT_BY_FILTER_VALUE) {
      draft.sortBy = formikValues.sortBy;
    } else {
      delete draft.sortBy;
    }

    if (Number(formikValues.itemsPerPage) !== DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE) {
      draft.itemsPerPage = formikValues.itemsPerPage;
    } else {
      delete draft.itemsPerPage;
    }

    if (formikValues.search !== DEFAULT_SEARCH_VALUE) {
      draft.search = formikValues.search;
    } else {
      delete draft.search;
    }

    if ((formikValues.subject ?? '') !== (TutorIndexFilterInitialValues.subject ?? '')) {
      draft.subject = formikValues.subject;
    } else {
      delete draft.subject;
    }
  });

  return stringifyQuery(newQueryParams);
}

export function getEducationDegree(value?: string | null) {
  if (!value) {
    return null;
  }
  return TUTOR_EDUCATIONAL_DEGREE_SELECT_OPTIONS.find((degree) => degree.id == Number(value))?.text;
}

export function getTeachingExperience(value?: string | null) {
  if (!value) {
    return null;
  }
  return TUTOR_TEACHING_EXPERIENCE_OPTIONS.find((experience) => experience.id == Number(value))?.name;
}
