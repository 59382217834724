export class DataUtil {
  static store: Map<HTMLElement, Map<string, unknown>> = new Map();

  public static set(instance: HTMLElement | undefined, key: string, data: unknown): void {
    if (!instance) {
      return;
    }

    const instanceData = DataUtil.store.get(instance);
    if (!instanceData) {
      const newMap = new Map().set(key, data);
      DataUtil.store.set(instance, newMap);
      return;
    }

    instanceData.set(key, data);
  }

  public static get<T = unknown>(instance: HTMLElement, key: string): T | null {
    const instanceData = DataUtil.store.get(instance);
    if (!instanceData) {
      return null;
    }

    return instanceData.get(key) as T;
  }

  public static remove(instance: HTMLElement, key: string): void {
    const instanceData = DataUtil.store.get(instance);
    if (!instanceData) {
      return;
    }

    instanceData.delete(key);
  }

  public static has(instance: HTMLElement, key: string): boolean {
    const instanceData = DataUtil.store.get(instance);
    if (instanceData) {
      return instanceData.has(key);
    }

    return false;
  }

  public static getAllInstancesByKey<T = unknown>(key: string): T[] {
    const result: T[] = [];
    DataUtil.store.forEach((val) => {
      val.forEach((v, k) => {
        if (k === key) {
          result.push(v as T);
        }
      });
    });
    return result;
  }
}
