import { ReactNode } from 'react';
import clsx from 'clsx';

type MessengerContainerProps = {
  children: ReactNode;
  className?: string;
};

export function MessengerContainer({ children, className = '' }: MessengerContainerProps) {
  return <div className={clsx('d-flex flex-column h-100', className)}>{children}</div>;
}
