import { FileAttachment } from '../components/AttachmentPreview/models';

export interface ProfileTimeline {
  timelineID: number | null;
  courseName: string;
  connectedStudentName: string;
  tutorName: string;
  tutorIdentityID: number | null;
  studentName: string;
  studentIdentityID: number | null;
}

export type TimelineAttachment = FileAttachment;

export interface TimelineEvent {
  id: string;
  type: TimelineEventType;
  identityID: number;
  identityName: string;
  identityAvatarPath: string;
  createdAt: string;
  eventDateTime: string;
  data: {
    title: string;
    text: string;
    attachments?: TimelineAttachment[];
  };
}

export enum TimelineEventType {
  ReservationRequestSent = 'Shkolo\\PL\\Web\\Events\\ReservationRequestSent',
  ReservationRequestApprove = 'Shkolo\\PL\\Web\\Events\\ReservationRequestApprove',
  ReservationRequestReject = 'Shkolo\\PL\\Web\\Events\\ReservationRequestReject',
  LessonFeedbackSent = 'Shkolo\\PL\\Web\\Events\\LessonFeedbackSent',
  ReservationProposalApprove = 'Shkolo\\PL\\Web\\Events\\ReservationProposalApprove',
  ReservationCancelation = 'Shkolo\\PL\\Web\\Events\\ReservationCancelation',
  LessonEnded = 'Shkolo\\PL\\Web\\Events\\LessonEnded',
}
