import { RecurrenceTypeID, ReservationStatusID, TimeSlotProposalPriceTypeID } from '@/app/components/Calendar/models';

export const RECURRENCE_TYPE_IDS = [
  RecurrenceTypeID.Never,
  RecurrenceTypeID.Daily,
  RecurrenceTypeID.Weekly,
  RecurrenceTypeID.DailyInterval,
  RecurrenceTypeID.WeeklyInterval,
];

export const INTERVAL_RECURRENCE_TYPE_IDS = [RecurrenceTypeID.DailyInterval, RecurrenceTypeID.WeeklyInterval];
export const WEEKLY_RECURRENCE_TYPE_IDS = [RecurrenceTypeID.Weekly, RecurrenceTypeID.WeeklyInterval];

export const AVAILABLE_TIME_GROUP_ID = 'available-time';

export const DEFAULT_DURATION = '45';

export const DEFAULT_RESERVATION_BUFFER_HOURS = '24';

export const EVENT_OVERLAP_ERROR_MESSAGE = 'Едно или повече свободни времена се препокриват с току-що въведените.';
export const EVENT_EDIT_INTERVAL_VALIDATION_ERROR_MESSAGE =
  'Зададените от Вас повторения и интервали не са валидни спрямо типа на редакция на събитие, която сте избрали';

export const SLOT_MIN_TIME = '06:00:00';
export const SLOT_MAX_TIME = '22:00:00';

export const TIME_SLOT_PROPOSAL_PRICE_TYPE_OPTIONS = [
  {
    id: TimeSlotProposalPriceTypeID.Standard,
    text: 'Стандартна цена',
  },
  {
    id: TimeSlotProposalPriceTypeID.Special,
    text: 'Извънредна цена',
  },
  {
    id: TimeSlotProposalPriceTypeID.Free,
    text: 'Безплатен урок',
  },
];

export const PROPOSED_RESERVATION_STATUS_IDS = [ReservationStatusID.Proposed, ReservationStatusID.ProposedFree];
