import { useEffect } from 'react';

import errors from '@/app/modules/errors/store';
import { useAppDispatch } from '@/redux/store';

export function NoMatch() {
  const dispatch = useAppDispatch();

  useEffect(
    function componentDidMount() {
      dispatch(
        errors.actions.setHttpGetError({
          httpStatusCode: 404,
        })
      );
    },
    [dispatch]
  );

  return null;
}
