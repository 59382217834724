import { useCallback } from 'react';

import { MenuComponent } from '@/_metronic/assets/ts/components';
import { InfiniteScroll } from '@/app/components/InfiniteScroll/InfiniteScroll';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { NotificationItem } from './NotificationItem';
import { markNotificationRead } from './service';
import notificationSlice from './store';

export function NotificationsList() {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.notification);
  const handleLoadMore = useCallback(() => {
    dispatch(notificationSlice.actions.loadMore());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNotificationItemClick = useCallback((id: string, isRead: boolean) => {
    return async () => {
      if (isRead) {
        return MenuComponent.hideDropdowns();
      }

      await dispatch(markNotificationRead(id));
      MenuComponent.hideDropdowns();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {data.notifications?.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onClick={handleNotificationItemClick(notification.id, Boolean(notification.isRead))}
        />
      ))}

      {data.loading === LoadingState.Idle && data.notifications?.length === 0 && (
        <p className="m-5 text-center">Нямате известия.</p>
      )}

      <InfiniteScroll hasMore={data.hasMore} onLoadMore={handleLoadMore} />
    </>
  );
}
