import { FormControlProps as BaseFormControlProps } from 'react-bootstrap';
import { DropzoneState } from 'react-dropzone';

export type FormControlProps = BaseFormControlProps & {
  name: string;
  className?: string;
  isReadOnly?: boolean;
  hasSolidBackground?: boolean;
  disabled?: boolean;
};

//#region FILE CONTROL VALUE
export interface FileControlValue {
  id: number | string;
  fileName: string;
  file?: File | null;
  order?: number;
  group?: string | null;
  url?: string;
}

export function createFileControlValue(
  id: number | string,
  fileName: string,
  file: File | null = null,
  order = 0,
  group: string | null
): FileControlValue {
  if (file) {
    fileName = file.name;
  }

  return { id, fileName, file, order, group };
}
//#endregion FILE CONTROL VALUE

//#region ERRORS
export interface FileControlError {
  file: string;
}
//#endregion ERRORS

//#region DROPZONE
export type DropzoneControlState = DropzoneState;
//#endregion DROPZONE

//#region SIZES
export type Size = 'sm' | 'md' | 'lg';
//#endregion SIZES
