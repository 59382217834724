export interface TutorCourseSubject {
  id: number;
  name: string;
}

export interface TutorCourseSubjectTargetAudience {
  id: number;
  name: string;
}

export interface FilteredTutorCourse {
  name: string;
  transliteratedSlug: string;
}

export interface TargetAudiencePayload {
  tutorID: number;
  subjectID: number;
}

export interface CoursePayload {
  tutorID: number;
  subjectID: number;
  targetAudienceID: number;
}

export interface GetTutorSubjectsResponse {
  subjects: TutorCourseSubject[];
}

export interface GetTutorCourseSubjectTargetAudiencesResponse {
  targetAudiences: TutorCourseSubjectTargetAudience[];
}

export interface GetFilteredTutorCoursesResponse {
  courses: FilteredTutorCourse[];
}

export enum BuyLessonStepNumber {
  Subject = 1,
  TargetAudience = 2,
  Course = 3,
}
