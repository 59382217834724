import { addMinutes, isEqual, set, subMinutes } from 'date-fns';

import { CLOSE_LESSON_BUFFER_MINUTES } from '@/app/modules/my-schedule/constants';

export function getJitsiOptions(
  roomName: string,
  jwt: string,
  parentNode: Element,
  lessonEndDateTime?: Date,
  shouldSeparateTimerFromSubject?: boolean,
  onLoad?: () => void
) {
  return {
    roomName,
    jwt,
    width: '100%',
    height: '100%',
    parentNode,
    configOverwrite: {
      defaultLanguage: 'bg',
      channelLastN: 2,
      disableAudioLevels: true,
      resolution: 1080,
      maxFps: 20,
      enableWelcomePage: false,
      disableReactions: true,
      disablePolls: true,
      constraints: {
        video: {
          aspectRatio: 16 / 9,
          height: {
            ideal: 1080,
            max: 1080,
            min: 240,
          },
        },
      },
      prejoinPageEnabled: true,
      remoteVideoMenu: {
        disableKick: true,
        disableGrantModerator: true,
      },
      disableSelfViewSettings: true,
      disableInternalChat: true,
      disableInternalFullScreen: true,
      shouldSeparateTimerFromSubject,
      lessonEndTimestamp: lessonEndDateTime
        ? subMinutes(lessonEndDateTime, CLOSE_LESSON_BUFFER_MINUTES).getTime()
        : null,
      lessonEndWithBufferTimestamp: lessonEndDateTime ? lessonEndDateTime.getTime() : null,
    },
    noSSL: false,
    interfaceConfigOverwrite: {
      INITIAL_TOOLBAR_TIMEOUT: 0,
      TOOLBAR_TIMEOUT: 3600000,
      DEFAULT_REMOTE_DISPLAY_NAME: 'Участник',
      DEFAULT_LOCAL_DISPLAY_NAME: 'аз',
      TOOLBAR_ALWAYS_VISIBLE: true,
      SHOW_JITSI_WATERMARK: false,
      JITSI_WATERMARK_LINK: '',
      SHOW_WATERMARK_FOR_GUESTS: false,
      APP_NAME: 'Школо Уроци',
      NATIVE_APP_NAME: 'Школо Уроци',
      PROVIDER_NAME: 'Школо Уроци',
      TOOLBAR_BUTTONS: [
        'microphone',
        'camera',
        'closedcaptions',
        'desktop',
        'fullscreen',
        'fodeviceselection',
        'chat',
        'raisehand',
        'videoquality',
        'filmstrip',
        'hangup',
        'whiteboard',
        'settings',
        'select-background',
        'pinLocalParticipant',
        'tileview',
      ],
      SETTINGS_SECTIONS: ['devices', 'sounds'],
      filmStripOnly: false,
      VERTICAL_FILMSTRIP: true,
      LIVE_STREAMING_HELP_LINK: '',
      SUPPORT_URL: 'tel:+359999996995',
      SHOW_DEEP_LINKING_IMAGE: false,
      HIDE_DEEP_LINKING_LOGO: true,
    },
    onload: onLoad,
  };
}

export function willLessonEndAfter(endDateTime: Date, minutes: number) {
  return isEqual(endDateTime, set(addMinutes(new Date(), minutes), { seconds: 0, milliseconds: 0 }));
}
