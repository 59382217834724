import { useEffect, useMemo } from 'react';
import { FormikProvider } from 'formik';

import { DEFAULT_RESERVATION_BUFFER_HOURS } from '@/app/components/Calendar/constants';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { handleValidation } from '@/app/components/Form/utils';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { RESERVATION_BUFFER_HOURS_OPTIONS } from '@/app/modules/course/components/steps/constants';
import { UpdateScheduleReservationBufferHoursValues } from '@/app/modules/schedule/models';
import { updateScheduleReservationBufferHours } from '@/app/modules/schedule/service';
import { useDebounce } from '@/app/utils/useDebounce';
import { LoadingState } from '@/redux/constants';

type ReservationBufferHoursFieldProps = {
  initialValue?: number | string;
};

export function ReservationBufferHoursField({ initialValue }: ReservationBufferHoursFieldProps) {
  const initialValues = useMemo<UpdateScheduleReservationBufferHoursValues>(
    () => ({
      reservationBufferHours: initialValue ?? DEFAULT_RESERVATION_BUFFER_HOURS,
    }),
    [initialValue]
  );

  const formik = useForm<UpdateScheduleReservationBufferHoursValues>({
    initialValues,
    initialStatus: LoadingState.Idle,
  });

  const debouncedReservationBufferHours = useDebounce<number>(formik.values.reservationBufferHours);

  useEffect(() => {
    if (Number(debouncedReservationBufferHours) !== Number(formik.initialValues.reservationBufferHours)) {
      const handleReservationBufferHoursUpdate = handleValidation<UpdateScheduleReservationBufferHoursValues>(
        async (values) => {
          await updateScheduleReservationBufferHours(values);
        }
      );
      handleReservationBufferHoursUpdate({ reservationBufferHours: debouncedReservationBufferHours });
    }
  }, [debouncedReservationBufferHours]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormikProvider value={formik}>
      <FormGroup controlId="reservationBufferHours">
        <Label>Минимално време за резервация преди началото на урок</Label>
        <SelectControl name="reservationBufferHours" options={RESERVATION_BUFFER_HOURS_OPTIONS} />
      </FormGroup>
    </FormikProvider>
  );
}
