import clsx from 'clsx';

import { MAX_RATING } from '@/app/modules/course/constants';

import './CourseRatingStars.scss';

type CourseRatingStarsProps = {
  currentRating: number;
  starsClassName?: string;
  isCurved?: boolean;
};

export function CourseRatingStars({ currentRating, starsClassName, isCurved = false }: CourseRatingStarsProps) {
  return (
    <div className="rating-container position-relative">
      <div className="rating">
        <div className={clsx('rating-label fs-2 m-1 fas fa-star star-empty mb-0', starsClassName)}></div>
        <div
          className={clsx('rating-label fs-2 m-1 fas fa-star star-empty mb-0', starsClassName, { 'mb-3': isCurved })}
        ></div>
        <div
          className={clsx('rating-label fs-2 m-1 fas fa-star star-empty mb-0', starsClassName, { 'mb-5': isCurved })}
        ></div>
        <div
          className={clsx('rating-label fs-2 m-1 fas fa-star star-empty mb-0', starsClassName, { 'mb-3': isCurved })}
        ></div>
        <div className={clsx('rating-label fs-2 m-1 fas fa-star star-empty mb-0', starsClassName)}></div>
      </div>
      <div
        className="rating rating--filled position-absolute overflow-hidden"
        style={{ width: `${(100 * Number(currentRating ?? 0)) / Number(MAX_RATING)}%` }}
      >
        <div className={clsx('rating-label fs-2 m-1 fas fa-star mb-0', starsClassName)}></div>
        <div className={clsx('rating-label fs-2 m-1 fas fa-star mb-0', starsClassName, { 'mb-3': isCurved })}></div>
        <div className={clsx('rating-label fs-2 m-1 fas fa-star mb-0', starsClassName, { 'mb-5': isCurved })}></div>
        <div className={clsx('rating-label fs-2 m-1 fas fa-star mb-0', starsClassName, { 'mb-3': isCurved })}></div>
        <div className={clsx('rating-label fs-2 m-1 fas fa-star mb-0', starsClassName)}></div>
      </div>
    </div>
  );
}
