import { KTSVG } from '@/_metronic/helpers/components/KTSVG';
import { MINIMUM__AMOUNT_AVAILABLE_FOR_WITHDRAWAL } from '@/app/layout/constants';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { EnterLessonButton } from '@/app/modules/my-schedule/EnterLesson/EnterLessonButton';
import { formatPrice } from '@/app/utils/money';
import { useAppSelector } from '@/redux/store';

import { AsideMenuExternalItem } from './AsideMenuExternalItem';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemTitle } from './AsideMenuItemTitle';

export function AsideMenu() {
  const { isAuthenticated, tutorStatusID, balanceAmount } = useAppSelector((state) => state.auth);

  const isAuthenticatedButNotApprovedTutor = isAuthenticated && tutorStatusID !== TutorStatusID.Approved;

  return (
    <div
      id="kt_aside"
      className="aside card"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      <div className="aside-menu flex-column-fluid">
        <div id="kt_aside_menu_wrapper" className="hover-scroll-overlay-y my-5 my-lg-5">
          <div
            id="#kt_aside_menu"
            data-kt-menu="true"
            className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
          >
            {isAuthenticated && (
              <>
                <AsideMenuItem to="/enrolled-courses">
                  <AsideMenuItemTitle>Записани обучения</AsideMenuItemTitle>
                </AsideMenuItem>
                <AsideMenuItem to="/favorite-courses">
                  <AsideMenuItemTitle>Любими обучения</AsideMenuItemTitle>
                </AsideMenuItem>
                <EnterLessonButton />
              </>
            )}

            <hr className="text-gray-400" />

            {(!isAuthenticated || isAuthenticatedButNotApprovedTutor) && (
              <AsideMenuItem to="/tutor-home">
                <AsideMenuItemTitle>Стани обучител</AsideMenuItemTitle>
              </AsideMenuItem>
            )}

            {isAuthenticated && tutorStatusID === TutorStatusID.Approved && (
              <>
                <AsideMenuItem to="/courses/create">
                  <AsideMenuItemTitle>Създай обучение</AsideMenuItemTitle>
                </AsideMenuItem>

                <AsideMenuItem
                  to="/profile/profit"
                  isPulsing={Number(balanceAmount) >= MINIMUM__AMOUNT_AVAILABLE_FOR_WITHDRAWAL}
                >
                  <KTSVG className="svg-icon-1 svg-icon-success me-4" path="/img/svg/icons/Shopping/Money.svg" />
                  <AsideMenuItemTitle>Баланс: {formatPrice(balanceAmount)}</AsideMenuItemTitle>
                </AsideMenuItem>
              </>
            )}

            {isAuthenticated && (
              <AsideMenuExternalItem to="/auth/logout">
                <KTSVG className="svg-icon-1 svg-icon-primary me-4" path="/img/svg/icons/Navigation/Sign-out.svg" />
                <AsideMenuItemTitle>Изход от профила</AsideMenuItemTitle>
              </AsideMenuExternalItem>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
