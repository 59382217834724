import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { CancelButton } from '@/app/components/Button/CancelButton';
import { SaveButton } from '@/app/components/Button/SaveButton';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { MultipleFileControl } from '@/app/components/Form/MultipleFileControl/MultipleFileControl';
import { TextareaControl } from '@/app/components/Form/TextareaControl/TextareaControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation } from '@/app/components/Form/utils';
import { GoBackLink } from '@/app/components/Page/GoBackLink/GoBackLink';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { LessonFeedbackFormValues } from '@/app/modules/lesson/models';
import { createLessonFeedback, getLessonFeedbackCreateData } from '@/app/modules/lesson/service';
import { StickyHeader } from '@/app/modules/profile/StickyHeader';
import { useQueryParams } from '@/app/utils/query';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

type LessonFeedbackFormProps = {
  onSuccess?: () => void;
  previousLocation: string;
};

export function LessonFeedbackForm({ onSuccess, previousLocation }: LessonFeedbackFormProps) {
  const dispatch = useDispatch();
  const { queryParams } = useQueryParams<{ timelineID: number }>();
  const loading = useAppSelector((state) => state.lesson.loading);
  const lessons = useAppSelector((state) => state.lesson.comment.lessons);

  useEffect(
    function handleTimelineIDQueryChange() {
      if (loading === LoadingState.Idle) {
        dispatch(getLessonFeedbackCreateData(queryParams.timelineID));
      }
    },
    [queryParams.timelineID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#region Handle form
  const initialValues = useMemo<LessonFeedbackFormValues>(() => {
    return {
      lessonID: lessons[0]?.id ?? null,
      comment: '',
      attachments: [],
    };
  }, [lessons]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        lessonID: yup.number().required(),
        comment: yup.string().required().max(10000),
        attachments: yup.array().optional(),
      }),
    []
  );

  const formik = useForm<LessonFeedbackFormValues>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<LessonFeedbackFormValues>(async (values) => {
        return await createLessonFeedback(Number(values.lessonID), {
          comment: values.comment,
          attachments: values.attachments,
        });
      });

      const response = await submitWithValidation(values, formikHelpers);

      if (response) {
        formik.resetForm({ values });
        onSuccess?.();
      }
    },
  });
  //#endregion Handle form

  return (
    <Form formik={formik} disabled={formik.isSubmitting} skipDirtyPrompt>
      <StickyHeader className="py-5" containerClassName="mb-0">
        <PageTop>
          <GoBackLink to={previousLocation}>Назад към история на обучението</GoBackLink>
          <PageTitle className="my-2 fw-bolder fs-2x text-center">Добави коментар</PageTitle>
          <PageActions>
            <CancelButton to={previousLocation} />
            <SaveButton>Добави</SaveButton>
          </PageActions>
        </PageTop>
      </StickyHeader>
      <Card>
        <CardBody>
          <FormGroup className="mb-4" controlId="lessonID">
            <Label isRequired>За урок</Label>
            <SelectControl name="lessonID" options={lessons} />
          </FormGroup>
          <FormGroup className="mb-4" controlId="comment">
            <Label isRequired>Вашият коментар</Label>
            <TextareaControl name="comment" placeholder="" />
          </FormGroup>
          <FormGroup controlId="attachments">
            <Label>Файлове</Label>
            <MultipleFileControl name="attachments" accept=".pdf, .png, .jpg, .jpeg, .doc, .docx" />
          </FormGroup>
        </CardBody>
      </Card>
    </Form>
  );
}
