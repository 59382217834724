import { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { ConsultUsContext } from '@/_metronic/layout/components/consult-us/ConsultUsContext';
import { areElementsNotCollided, isElementInsideElement } from '@/_metronic/layout/components/consult-us/utils';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformCustomEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { debounce } from '@/app/utils/debounce';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagShkoloConsultationEvent } from '@/app/utils/google-analytics/customEvents';

import './ConsultUs.scss';

type ConsultUsProps = {
  text: string;
  link: string;
};

export function ConsultUs({ text, link }: ConsultUsProps) {
  const { isVisible, isPageLoaded } = useContext(ConsultUsContext);
  const consultUsEl = useRef(null);
  const [isOverlapping, setIsOverlapping] = useState(false);

  function handleOverlapping() {
    if (isVisible && consultUsEl.current) {
      const primaryElements = [...document.getElementsByClassName('btn-primary')];
      const consultUsElCurrent = consultUsEl?.current as unknown as HTMLElement;

      for (const primaryElement of primaryElements) {
        if (
          primaryElement !== consultUsElCurrent &&
          primaryElement?.getBoundingClientRect().top !== 0 &&
          (!areElementsNotCollided(primaryElement, consultUsElCurrent) ||
            isElementInsideElement(primaryElement, consultUsElCurrent))
        ) {
          setIsOverlapping(true);
          return;
        }
      }

      setIsOverlapping(false);
    }
  }

  useEffect(
    function componentDidMount() {
      handleOverlapping();
      document.addEventListener('scroll', debounce(handleOverlapping, 100));
      window.addEventListener('resize', debounce(handleOverlapping, 100));

      return function componentDidUnmount() {
        document.removeEventListener('scroll', debounce(handleOverlapping, 100));
        window.removeEventListener('resize', debounce(handleOverlapping, 100));
      };
    },
    [isPageLoaded] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!isVisible || !isPageLoaded) {
    return null;
  }

  async function handleShkoloConsultation() {
    const conversionEventValues = await transformCustomEventToConversionEventValues(
      FACEBOOK_PIXEL_CUSTOM_EVENTS.shkoloConsultation
    );
    tagShkoloConsultationEvent(conversionEventValues.eventID);
    await trackAnalyticsEventConversionsAPI(conversionEventValues);
  }

  return (
    <a
      ref={consultUsEl}
      className={clsx('btn btn-primary shadow consult-us', { 'consult-us__hidden': isOverlapping })}
      href={link}
      target="_blank"
      rel="noreferrer"
      onClick={handleShkoloConsultation}
    >
      <i className="far fa-calendar me-4 fs-1" />
      <span dangerouslySetInnerHTML={{ __html: text }}></span>
    </a>
  );
}
