// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/ban-types */

import React from 'react';
import ReactDom from 'react-dom';

export type ReactComponentChild = React.ReactNode | object | string | number | boolean | null | undefined;

declare global {
  namespace FullCalendarVDom {
    export import Ref = React.Ref;
    export import RefObject = React.RefObject;
    export import ComponentType = React.ComponentType;
    export import VNode = React.ReactNode;
    export import Context = React.Context;
    export import Component = React.Component;
    export type ComponentChild = ReactComponentChild;
    export type ComponentChildren = ReactComponentChild | ReactComponentChild[];
    export import createElement = React.createElement;
    export import render = ReactDom.render;
    export import createRef = React.createRef;
    export import Fragment = React.Fragment;
    export import createContext = React.createContext;
    export import createPortal = ReactDom.createPortal;
    export type VUIEvent = React.UIEvent;
    export function flushToDom(): void;
    export function unmountComponentAtNode(node: HTMLElement): void;
  }
  namespace createElement {}
}

// TODO: streamline when killing IE11 support
(typeof globalThis !== 'undefined' ? globalThis : window).FullCalendarVDom = {
  Component: React.Component,
  createElement: React.createElement,
  render: ReactDom.render,
  createRef: React.createRef,
  Fragment: React.Fragment,
  createContext: React.createContext,
  createPortal: ReactDom.createPortal,
  flushToDom,
  unmountComponentAtNode: ReactDom.unmountComponentAtNode,
};

export function flushToDom() {
  // always sync from top-level
}
