import { produce } from 'immer';

import { deleteData, getData, putData } from '@/app/utils/IndexedDB';

import { STEPPER_OBJECT_STORE_NAME } from './constants';

export function getDefaultStepperItemTitle(number: number) {
  return `Стъпка ${number}`;
}

export function getStepValidationErrorMessage(number: number) {
  return `Имате грешка във въведените данни на Стъпка ${number}. Оправете ги и опитайте отново.`;
}

//#region IndexedDB
export function getStepperCacheKey(key: string) {
  return `${key}_cache`;
}

export async function getStepperData<T>(stepperDB: IDBDatabase, stepperSlug: string, key: string): Promise<T | null> {
  const currentData = await getData<Record<string, unknown>>(stepperDB, STEPPER_OBJECT_STORE_NAME, stepperSlug);

  return (currentData?.[key] as T) ?? null;
}

export async function persistStepperData<T>(stepperDB: IDBDatabase, stepperSlug: string, key: string, value: T) {
  const currentData = await getData<Record<string, unknown>>(stepperDB, STEPPER_OBJECT_STORE_NAME, stepperSlug);
  const newData = produce(currentData ?? {}, (draft) => {
    draft[key] = value;
  });

  await putData<T>(stepperDB, STEPPER_OBJECT_STORE_NAME, stepperSlug, newData as T);
}

export async function clearStepperData(stepperDB: IDBDatabase, stepperSlug: string) {
  await deleteData(stepperDB, STEPPER_OBJECT_STORE_NAME, stepperSlug);
}
//#endregion IndexedDB
