import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const ENDPOINT_PREFIX = '/ajax/promotions';

export const ENDPOINTS = {
  active: `${ENDPOINT_PREFIX}/active`,
};

//#region Get active promotion data
export const getActivePromotion = createAsyncThunk('promotion/getPromotionData', async () => {
  const response = await axios.get<GetPromotionDataResponse>(ENDPOINTS.active);
  return response.data;
});

export interface GetPromotionDataResponse {
  canUseBirthdayPromoCode: boolean;
  canUseLessonPromotion: boolean;
  canUseAlphabetPromoCode: boolean;
  activePromotionSlug: string;
  discount: number | null;
}
//#endregion Get active promotion data
