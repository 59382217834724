import { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import clsx from 'clsx';
import { useFormikContext } from 'formik';

import { ReservationStatusID } from '@/app/components/Calendar/models';
import { DropdownCheckboxControlItem } from '@/app/components/Dropdown/DropdownCheckboxControlItem';
import { DropdownMenu } from '@/app/components/Dropdown/DropdownMenu';
import { DropdownToggle } from '@/app/components/Dropdown/DropdownToggle';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { AsyncSelectControl } from '@/app/components/SelectControl/AsyncSelectControl';
import { MyScheduleFilterInitialValues } from '@/app/modules/my-schedule/constants';
import { MyScheduleFilterQueryParams, MyScheduleFilterValues } from '@/app/modules/my-schedule/models';
import { areFiltersApplied } from '@/app/modules/my-schedule/utils';
import { useQueryParams } from '@/app/utils/query';
import { useDebounce } from '@/app/utils/useDebounce';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch } from '@/redux/store';

import { getFutureLessonsCalendarView, getFutureLessonsListView } from './service';

type MyScheduleFiltersProps = {
  loading: LoadingState;
  onLoad: typeof getFutureLessonsListView | typeof getFutureLessonsCalendarView;
  onCalendarDateChange?: (start: Date, end: Date) => void | undefined;
  resetPagination?: () => void;
  page?: number;
  startDate?: string | null;
  endDate?: string | null;
};

export function MyScheduleFilters({ loading, onLoad, page = 1, startDate, endDate }: MyScheduleFiltersProps) {
  const dispatch = useAppDispatch();
  const formik = useFormikContext<MyScheduleFilterValues>();
  const { queryParams } = useQueryParams<MyScheduleFilterQueryParams>();
  const debouncedFilters = useDebounce<MyScheduleFilterQueryParams>(queryParams, 300);

  useEffect(() => {
    if (loading === LoadingState.Idle) {
      dispatch(
        (onLoad as typeof getFutureLessonsListView)({
          page: page,
          filters: formik.values,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
  }, [page, debouncedFilters, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  function clearFilters() {
    if (areFiltersApplied(formik.values)) {
      formik.setValues(MyScheduleFilterInitialValues);
    }
  }

  //#region Handle filter toggle
  const [isFilterToggled, setIsFilterToggled] = useState(false);

  function handleFilterToggle() {
    setIsFilterToggled((prev) => !prev);
  }
  //#endregion Handle filter toggle

  return (
    <div className="d-flex flex-column w-100 w-md-auto ms-auto mb-4">
      <button
        type="button"
        className="btn btn-bg-white d-flex align-items-center border border-gray-300 w-100 px-3 d-md-none"
        onClick={handleFilterToggle}
      >
        <span className="d-block mx-auto">{!isFilterToggled ? 'Покажи филтри' : 'Скрий филтри'}</span>
        <i className={clsx('far', { 'fa-chevron-up': isFilterToggled, 'fa-chevron-down': !isFilterToggled })} />
      </button>
      <div
        className={clsx('d-md-flex flex-wrap align-items-center mt-4 mt-md-0 mb-n4 me-n4', {
          'd-flex': isFilterToggled,
          'd-none': !isFilterToggled,
        })}
      >
        <FormGroup className="mb-4 me-4">
          <Label htmlFor="course" className="me-3">
            Филтрация по:
          </Label>
          <AsyncSelectControl
            className="w-150px bg-white"
            placeholder="Обучение"
            name="course"
            endpoint="/ajax/my-schedule/courses"
          />
        </FormGroup>
        <Dropdown align="end" autoClose="outside">
          <DropdownToggle variant="white-bordered" className="mb-4 me-4">
            Статус
          </DropdownToggle>

          <DropdownMenu className="py-4">
            <DropdownCheckboxControlItem
              name="reservationStatusID"
              label="Чака одобрение"
              value={ReservationStatusID.WaitingApproval}
            />
            <DropdownCheckboxControlItem
              name="reservationStatusID"
              label="Одобрен"
              value={ReservationStatusID.Approved}
            />
            <DropdownCheckboxControlItem
              name="reservationStatusID"
              label="Отказан"
              value={ReservationStatusID.Rejected}
            />
            <DropdownCheckboxControlItem
              name="reservationStatusID"
              label="Покана от обучител"
              value={ReservationStatusID.Proposed}
            />
          </DropdownMenu>
        </Dropdown>

        <Button onClick={clearFilters} variant="light-danger" className="align-self-start">
          <i className="fas fa-times p-0" />
        </Button>
      </div>
    </div>
  );
}
