import { ReactNode } from 'react';

import './Event.scss';

type EventProps = {
  children: ReactNode;
};

export function Event({ children }: EventProps) {
  return <div className="fc-event-main-frame">{children}</div>;
}
