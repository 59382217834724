import { ReactNode } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import * as H from 'history';

import { checkIsActive } from '@/_metronic/helpers/RouterHelpers';
import { HeaderButton } from '@/app/components/Button/HeaderButton';
import { LARGE_BREAKPOINTS, useBreakpoint } from '@/app/utils/useBreakpoint';

type HeaderMenuButtonProps<S> = {
  children: ReactNode;
  activePath?: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  isActiveExact?: boolean;
};

export function HeaderMenuButton<S>({ children, activePath, isActiveExact = true }: HeaderMenuButtonProps<S>) {
  const { pathname } = useLocation();
  const breakpoint = useBreakpoint();
  const isActive = checkIsActive(pathname, String(activePath), isActiveExact);
  const isLargeScreen = LARGE_BREAKPOINTS.includes(breakpoint);

  return (
    <HeaderButton
      className={clsx('position-relative', {
        'btn-white btn-active-light-primary mx-lg-1': isLargeScreen,
        'btn-icon-light': !isLargeScreen,
        'btn-icon-primary btn-bg-active-menu-item': isLargeScreen && isActive,
        'btn-icon-gray-700': isLargeScreen && !isActive,
      })}
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="bottom"
    >
      {children}
    </HeaderButton>
  );
}
