import { Modal } from 'react-bootstrap';

import { KTSVG } from '@/_metronic/helpers';
import { Button } from '@/app/components/Button/Button';

type RefundPromoCodeModalProps = {
  isModalShown: boolean;
  promoCodeRefundAmount: string | null | unknown;
  generatedPromoCode: string | null | unknown;
  onModalClose: (() => void) | undefined;
  onModalExited?: ((node: HTMLElement) => void) | undefined;
};

export function RefundPromoCodeModal({
  isModalShown,
  promoCodeRefundAmount,
  generatedPromoCode,
  onModalClose,
  onModalExited,
}: RefundPromoCodeModalProps) {
  return (
    <Modal show={isModalShown} onHide={onModalClose} onExited={onModalExited} centered>
      <Modal.Body className="p-md-10 text-center">
        <div className="mx-auto mb-10">
          <KTSVG path="/img/svg/icons/CheckCircle.svg" className="svg-icon-6tx" />
        </div>

        <h2 className="fw-bold mb-md-10">
          Успешно възстановихме сумата под формата на промокод на стойност {promoCodeRefundAmount} Вашият промокод е{' '}
          {generatedPromoCode}
        </h2>

        <div className="d-flex justify-content-center flex-wrap mt-7 font-weight-bold font-size-h6">
          <Button variant="primary" onClick={onModalClose}>
            Добре
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
