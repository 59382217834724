import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import Toastr from 'toastr';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

import { Calendar } from '@/app/components/Calendar/Calendar';
import { EVENT_OVERLAP_ERROR_MESSAGE } from '@/app/components/Calendar/constants';
import { Event } from '@/app/components/Calendar/Event/Event';
import { EventTime } from '@/app/components/Calendar/Event/EventTime';
import { EventTitle } from '@/app/components/Calendar/Event/EventTitle';
import {
  CalendarEvent,
  EditableTimeSlotEntity,
  RecurrenceTypeID,
  ReservationStatusID,
  TimeSlotStatusID,
} from '@/app/components/Calendar/models';
import {
  eventClassNameGenerator,
  getIntervalFormat,
  hasOverlappingCalendarEventsOnDurationChange,
  hasOverlappingCalendarEventsOnIntervalIndividualTimeSlotChange,
} from '@/app/components/Calendar/utils';
import { Feedback } from '@/app/components/Feedback/Feedback';
import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { handleValidation } from '@/app/components/Form/utils';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import {
  TIME_SLOT_DURATION_LEGACY_OPTIONS,
  TIME_SLOT_DURATION_OPTIONS,
} from '@/app/modules/course/components/steps/constants';
import { transformCalendarEventToTimeSlot } from '@/app/modules/course/components/steps/utils';
import { CalendarEventType, ScheduleStepModel } from '@/app/modules/course/create/models';
import { DurationOverlapModal } from '@/app/modules/course/forms/DurationOverlapModal';
import { ReservationBufferHoursField } from '@/app/modules/course/forms/ReservationBufferHoursField';
import { RecurrenceType } from '@/app/modules/course/models';
import { transformTimeSlotEntityToEditableCalendarEvent } from '@/app/modules/course/utils';
import {
  DestroySlotValues,
  StoreRecurringTimeSlotValues,
  UpdateScheduleDurationValues,
  UpdateSlotValues,
} from '@/app/modules/schedule/models';
import {
  destroySlot,
  storeRecurringTimeSlot,
  updateBlockNewStudentsReservations,
  updateScheduleDuration,
  updateSlot,
} from '@/app/modules/schedule/service';
import { isString } from '@/app/utils/isString';
import { useAppSelector } from '@/redux/store';

export function useScheduleValidationSchema(objectShape: ObjectShape = {}) {
  const validationSchema = useMemo(() => yup.object().shape(objectShape), [objectShape]);
  return { validationSchema };
}

type ScheduleFormProps = {
  timeSlots: EditableTimeSlotEntity[];
  recurrenceTypeOptions?: RecurrenceType[];
  isLoading?: boolean;
  areTimeSlotsLoading?: boolean;
  onCalendarDateChange: (start?: Date, end?: Date) => void | undefined;
};

export function ScheduleForm({
  timeSlots = [],
  recurrenceTypeOptions = [],
  isLoading = false,
  areTimeSlotsLoading = false,
  onCalendarDateChange,
}: ScheduleFormProps) {
  const formik = useFormikContext<ScheduleStepModel>();
  const studentID = useAppSelector((state) => state.auth.studentID);
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);
  const canChangeCourseDuration = formik.values.canChangeCourseDuration;
  const [isDurationOverlapModalShown, setIsDurationOverlapModalShown] = useState<boolean>(false);
  const canUseLegacyCourseDuration = formik.values.canUseLegacyCourseDuration;
  const courseDurationOptions = canUseLegacyCourseDuration
    ? TIME_SLOT_DURATION_LEGACY_OPTIONS
    : TIME_SLOT_DURATION_OPTIONS;

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  function handleCalendarDateChange(start: Date, end: Date) {
    setStartDate(start);
    setEndDate(end);
    onCalendarDateChange(start, end);
  }

  function handleDurationOverlapModalClose() {
    setIsDurationOverlapModalShown(false);
    formik.setFieldValue('duration', formik.initialValues.duration);
  }

  function getInitialTimeSlot(changedCalendarEvent: CalendarEvent): EditableTimeSlotEntity | undefined {
    return timeSlots.find((x) => x.id === changedCalendarEvent.id || x.id === Number(changedCalendarEvent.id));
  }

  function resetScheduleForm() {
    onCalendarDateChange(startDate, endDate);
    formik.setFieldValue('calendarEventType', null);
  }

  const events = useMemo(() => {
    return timeSlots.map((timeSlotEntity) => transformTimeSlotEntityToEditableCalendarEvent(timeSlotEntity));
  }, [timeSlots]);

  async function handleSlotStore(values: StoreRecurringTimeSlotValues) {
    const handleSubmit = handleValidation<StoreRecurringTimeSlotValues>(async (values) => {
      return await storeRecurringTimeSlot(values);
    });
    await handleSubmit(values);
    resetScheduleForm();
  }

  async function handleSlotUpdate(values: UpdateSlotValues) {
    const handleSubmit = handleValidation<UpdateSlotValues>(async (values) => {
      return await updateSlot(values);
    });
    await handleSubmit(values);
    resetScheduleForm();
  }

  async function handleSlotDestroy(slotID: number, values: DestroySlotValues) {
    const handleSubmit = handleValidation<DestroySlotValues>(async (values) => {
      return await destroySlot(slotID, values);
    });
    await handleSubmit(values);
    resetScheduleForm();
  }

  async function handleScheduleDurationUpdate(values: UpdateScheduleDurationValues) {
    const handleSubmit = handleValidation<UpdateScheduleDurationValues>(async (values) => {
      return await updateScheduleDuration(values);
    });
    await handleSubmit(values);

    resetScheduleForm();
  }

  async function handleAddEvent(calendarEvent: CalendarEvent) {
    // Transform calendar event to recurring time slot, set it in persist request values and submit the form
    const recurringTimeSlot = transformCalendarEventToTimeSlot(calendarEvent);
    await handleSlotStore({ recurringTimeSlot: recurringTimeSlot });
  }

  async function handleDeleteEvent(deletedCalendarEvent: CalendarEvent) {
    if (!deletedCalendarEvent) {
      return;
    }

    let eventType = formik.values.calendarEventType;
    let deletedSlotID = Number(deletedCalendarEvent.id);

    const isNeverRecurring = deletedCalendarEvent.recurrenceTypeID === RecurrenceTypeID.Never;
    if (!eventType && !isNeverRecurring) {
      return;
    }
    if (isNeverRecurring || eventType === CalendarEventType.RecurringEvent) {
      deletedSlotID = Number(deletedCalendarEvent.recurringTimeSlotID);
      eventType = CalendarEventType.RecurringEvent;
    }
    await handleSlotDestroy(Number(deletedSlotID), {
      calendarEventType: eventType,
    });
  }

  async function handleChangeEvent(changedCalendarEvent: CalendarEvent) {
    if (!changedCalendarEvent) {
      return;
    }

    // Step 1. Find initial editable time slot entity
    const initialTimeSlot = timeSlots.find(
      (x) => x.id === changedCalendarEvent.id || x.id === Number(changedCalendarEvent.id)
    );

    if (!initialTimeSlot) {
      return;
    }
    let eventType = formik.values.calendarEventType;

    if (
      eventType === CalendarEventType.IndividualEvent &&
      initialTimeSlot.recurrenceTypeID !== RecurrenceTypeID.Never &&
      hasOverlappingCalendarEventsOnIntervalIndividualTimeSlotChange(initialTimeSlot, changedCalendarEvent)
    ) {
      Toastr.error(EVENT_OVERLAP_ERROR_MESSAGE);
      resetScheduleForm();
      return;
    }

    if (initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.Never) {
      eventType = CalendarEventType.RecurringEvent;
    }
    const updatedRecurringTimeSlot = transformCalendarEventToTimeSlot(
      changedCalendarEvent,
      initialTimeSlot.recurringTimeSlotID
    );

    await handleSlotUpdate({
      recurringTimeSlot: updatedRecurringTimeSlot,
      individualTimeSlot: initialTimeSlot,
      calendarEventType: eventType,
    });
  }

  useEffect(() => {
    // Create an scoped async function in the hook
    async function handleDurationChange() {
      // Step 1. Check if there are any changes to the duration
      if (formik.values.duration && formik.values.duration !== formik.initialValues.duration) {
        const durationDifference = Number(formik.values.duration) - Number(formik.initialValues.duration);
        const hasOverlappingEvents = hasOverlappingCalendarEventsOnDurationChange(events, durationDifference);
        if (hasOverlappingEvents) {
          setIsDurationOverlapModalShown(true);
          return;
        }
        // Step 2. Show a warning confirmation window to user that duration change will be applied to all of his time slots
        const confirmDurationChange = confirm(
          'Смяната на продължителността на обучението ще бъде отразена за всички часове, които сте създали. Сигурни ли сте, че искате да продължите и да промените продължителността на урок?'
        );

        // Step 2.1. If user doesn't confirm => reset duration to initial duration and return
        if (!confirmDurationChange) {
          formik.setFieldValue('duration', formik.initialValues.duration);
          return;
        }
        // Step 2.2. If user confirms => submit the form
        await handleScheduleDurationUpdate({ duration: Number(formik.values.duration) });
      }
    }
    // Execute the created function directly
    handleDurationChange();
  }, [formik.values.duration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function handleBlockNewStudentsReservations() {
      if (formik.values.blockNewStudentsReservations !== formik.initialValues.blockNewStudentsReservations) {
        if (formik.values.blockNewStudentsReservations) {
          const confirmed = confirm(
            'Само обучаеми, които вече са провели урок при Вас, ще имат възможност да резервират час. За всички останали потребители свободните часове във Вашия график ще се визуализират като заети. Сигурни ли сте, че искате да активирате тази настройка?'
          );

          if (!confirmed) {
            formik.setFieldValue('blockNewStudentsReservations', formik.initialValues.blockNewStudentsReservations);
            return;
          }
        }

        await updateBlockNewStudentsReservations({
          blockNewStudentsReservations: formik.values.blockNewStudentsReservations,
        });

        resetScheduleForm();
      }
    }

    handleBlockNewStudentsReservations();
  }, [formik.values.blockNewStudentsReservations]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row className="align-items-end">
        <FormGroup controlId="duration" className="col-md-6 col-lg-4">
          <Label>Продължителност на един урок</Label>
          {!canChangeCourseDuration && (
            <div className="mb-1">
              <i className="far fa-exclamation-triangle text-gray-700 me-1" />
              <span>
                Не може да променяте продължителността на урок. Направени са едно или повече плащания към Ваше обучение.
              </span>
            </div>
          )}
          <SelectControl name="duration" options={courseDurationOptions} isDisabled={!canChangeCourseDuration} />
        </FormGroup>
        <div className="col-md-6 col-lg-4 mb-10">
          <ReservationBufferHoursField initialValue={formik.initialValues.reservationBufferHours} />
        </div>
        <FormGroup controlId="blockNewStudentsReservations" className="col-md-12 col-lg-4 mb-10 mb-lg-11">
          <CheckboxControl
            name="blockNewStudentsReservations"
            label="Позволи нови резервации само за настоящи обучаеми"
            value={1}
            isInline
            hasSolidBackground={false}
            tooltipTitle="Само обучаеми, които вече са провели урок при Вас, ще имат възможност 
              да резервират час. За всички останали потребители свободните часове във Вашия график 
              ще се визуализират като заети."
          />
        </FormGroup>
      </Row>
      <div>
        <Calendar
          duration={Number(formik.values.duration)}
          calendarEventType={formik.values.calendarEventType}
          resetScheduleForm={resetScheduleForm}
          recurrenceTypeOptions={recurrenceTypeOptions}
          events={events}
          onAddEvent={handleAddEvent}
          onChangeEvent={handleChangeEvent}
          onDeleteEvent={handleDeleteEvent}
          getInitialTimeSlot={getInitialTimeSlot}
          scheduleFormik={formik}
          isEditable
          eventContent={({ event }) => {
            if (event.start === null || event.end === null) {
              return;
            }

            return (
              <Event>
                <EventTime>{getIntervalFormat(event.start, event.end)}</EventTime>

                {Boolean(event.extendedProps.reservationCourseName) &&
                  event.extendedProps.reservationStatusID !== ReservationStatusID.Canceled && (
                    <EventTitle>{event.extendedProps.reservationCourseName}</EventTitle>
                  )}

                {(event.extendedProps.timeSlotStatusID === TimeSlotStatusID.Available ||
                  event.extendedProps.reservationStatusID === ReservationStatusID.Canceled) &&
                  event.extendedProps.reservationStatusID !== ReservationStatusID.Rejected && (
                    <EventTitle>Свободен час</EventTitle>
                  )}

                {!event.extendedProps.reservationStudentID &&
                  event.extendedProps.timeSlotStatusID === TimeSlotStatusID.Unavailable && (
                    <EventTitle>Зает час</EventTitle>
                  )}
              </Event>
            );
          }}
          eventClassNames={eventClassNameGenerator({ studentID })}
          onDateChange={handleCalendarDateChange}
          isLoading={isLoading}
          areTimeSlotsLoading={areTimeSlotsLoading}
        />
        {(tutorStatusID === TutorStatusID.ProfileDetailsReady || tutorStatusID === TutorStatusID.FirstCourseReady) &&
          isString(formik.errors?.hasValidTimeSlotsForApproval) && (
            <Feedback>{formik.errors?.hasValidTimeSlotsForApproval}</Feedback>
          )}
      </div>
      <DurationOverlapModal
        isShown={isDurationOverlapModalShown}
        duration={formik.values.duration}
        onHide={handleDurationOverlapModalClose}
      />
    </>
  );
}
