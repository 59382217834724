import { Form } from 'react-bootstrap';
import clsx from 'clsx';

import { LabelRequired } from './LabelRequired';

import './Label.scss';

type LabelProps = {
  children: React.ReactNode;
  htmlFor?: string;
  className?: string;
  isRequired?: boolean;
};

export function Label({ children, htmlFor, className, isRequired = false }: LabelProps) {
  return (
    <Form.Label htmlFor={htmlFor} className={clsx(className)}>
      {children}
      {isRequired && <LabelRequired />}
    </Form.Label>
  );
}
