import { Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';

export function QualityCriteriaPage() {
  return (
    <>
      <Helmet>
        <title>Критерии за качество</title>
      </Helmet>
      <Page className="static-page terms">
        <Content>
          <PageTitle className="mb-9 fw-bolder fs-2x text-center">КРИТЕРИИ ЗА КАЧЕСТВО</PageTitle>
          <Card className="card-custom card-border">
            <Card.Body>
              <p>Обучителите в Школо Уроци отговарят на редица критерии за качество на обучението. </p>
              <ol>
                <li>Професионалисти, които владеят материята.</li>
                <li>Отлично подготвени за всеки урок.</li>
                <li>Адаптират се към индивидуалните нужди на ученика.</li>
                <li>Ясно задават целите и търсените резултати от всеки урок.</li>
                <li>Поставят дългосрочни цели на обучението, заедно с обучаемия.</li>
                <li>Поднасят информацията разбираемо.</li>
                <li>Използват разнообразни и интерактивни методи на обучение.</li>
                <li>Отнасят се уважително към обучаемия.</li>
                <li>Отговарят спокойно на всички въпроси на обучаемия.</li>
                <li>Дават своевременна обратна връзка за прогреса на обучаемия.</li>
                <li>Спазват уговорените часове и оползотворяват времето пълноценно.</li>
                <li>Осигуряват допълнителни материали за самостоятелна работа на обучаемите.</li>
              </ol>

              <p>
                Обучителите ни са отлични специалисти в различни области. Сред тях има както експерти с над 20 години
                опит, така и обучители, които са в началото на професионалния си път, но имат необходимите знания и
                лични постижения в дадена област. Преминават през прецизен процес на одобрение, след като създадат
                профила си.
              </p>
              <p>Ето кои са критериите за подбор на обучители в платформата:</p>
              <ol>
                <li>Мотивация да допринесат за обучението и развитието на учениците.</li>
                <li>Отлично владеене на материята, която преподават.</li>
                <li>Опит в подготовката на ученици и възрастни.</li>
                <li>Положително отношение към учениците, техните индивидуални потребности, ритъм на учене и цели.</li>
                <li>Грамотно и ясно разписани обучения и информация за обучителя в профила му.</li>
                <li>Педагогическото образование е предимство.</li>
                <li>Добри технически умения за работа в онлайн среда.</li>
              </ol>
            </Card.Body>
          </Card>
        </Content>
      </Page>
    </>
  );
}
