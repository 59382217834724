import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { serializeFormData } from '@/app/utils/serializeFormData';

import { Notification } from './models';

const ENDPOINT_PREFIX = '/ajax/notifications';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  markNotificationRead: (id: string) => `${ENDPOINT_PREFIX}/${id}/read`,
  markAllNotificationsRead: `${ENDPOINT_PREFIX}/markAllNotificationsRead`,
};

//#region Get notifications
export const getNotifications = createAsyncThunk<GetNotificationsResponse, { page?: number }>(
  'notification/getNotifications',
  async ({ page = 1 }) => {
    const response = await axios.get<GetNotificationsResponse>(ENDPOINTS.resource, {
      params: { page },
    });
    return response.data;
  }
);

export interface GetNotificationsResponse {
  notifications: Notification[];
  unreadNotificationsCount: number;
  hasMore: boolean;
  currentPage: number;
}
//#endregion Get notifications

//#region Mark notification as read
export const markNotificationRead = createAsyncThunk<MarkNotificationReadResponse, string>(
  'notification/markNotificationRead',
  async (id) => {
    const response = await axios.put<MarkNotificationReadResponse>(ENDPOINTS.markNotificationRead(id));
    return response.data;
  }
);

export interface MarkNotificationReadResponse {
  readNotificationID: string;
  unreadNotificationsCount: number;
}
//#endregion Mark notification as read

//#region Mark all notifications as read
export const markAllNotificationsRead = createAsyncThunk<null, string>(
  'notification/markAllNotificationsRead',
  async (lastNotificationCreatedAt) => {
    const formData = serializeFormData({
      lastNotificationCreatedAt: lastNotificationCreatedAt,
    });
    const response = await axios.put(ENDPOINTS.markAllNotificationsRead, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }
);
//#endregion Mark all notifications as read
