import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { ConnectedStudentCreatePage } from '@/app/modules/profile/connected-student/ConnectedStudentCreatePage';
import { ConnectedStudentEditPage } from '@/app/modules/profile/connected-student/ConnectedStudentEditPage';
import { ConnectedStudentIndexPage } from '@/app/modules/profile/connected-student/ConnectedStudentIndexPage';
import { ConnectedStudentShowPage } from '@/app/modules/profile/connected-student/ConnectedStudentShowPage';

export function ConnectedStudentPage() {
  return (
    <Switch>
      <Route path="/profile/connected-students" exact>
        <ConnectedStudentIndexPage />
      </Route>
      <Route path="/profile/connected-students/create">
        <ConnectedStudentCreatePage />
      </Route>
      <Route path="/profile/connected-students/:id/edit">
        <ConnectedStudentEditPage />
      </Route>
      <Route path="/profile/connected-students/:id">
        <ConnectedStudentShowPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
