import { ReactNode } from 'react';
import clsx from 'clsx';

import { PageContentProps } from './models';

type PageProps = {
  children: ReactNode;
  className?: string;
  renderContent?: (props: PageContentProps) => JSX.Element;
};

export function Page({ children, className, renderContent = (props) => <div {...props} /> }: PageProps) {
  return renderContent({
    id: 'kt_content',
    className: clsx('content d-flex flex-column flex-column-fluid', className),
    children,
  });
}
