import { Col, Row } from 'react-bootstrap';

import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { PhoneControl } from '@/app/components/Form/PhoneControl/PhoneControl';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { useAppSelector } from '@/redux/store';

export function ConnectedStudentForm() {
  const connectedStudentLevels = useAppSelector((state) => state.profileConnectedStudent.connectedStudentLevels);

  return (
    <Row className="g-8 mt-n8">
      <Col md={6}>
        <FormGroup controlId="name">
          <Label isRequired>Имена на обучаемия</Label>
          <FormControl type="text" name="name" />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup controlId="connectedStudentLevelID">
          <Label>Клас/възраст на обучаемия</Label>
          <SelectControl name="connectedStudentLevelID" options={connectedStudentLevels} />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup controlId="phone">
          <Label isRequired>Телефон</Label>
          <PhoneControl name="phone" />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup controlId="email">
          <Label isRequired>Имейл адрес</Label>
          <FormControl type="email" name="email" />
        </FormGroup>
      </Col>
    </Row>
  );
}
