import { ReactNode } from 'react';
import BaseFeedback from 'react-bootstrap/Feedback';

type FeedbackProps = {
  children: ReactNode;
  type?: 'valid' | 'invalid';
};

export function Feedback({ children, type = 'invalid' }: FeedbackProps) {
  return (
    <BaseFeedback type={type} className="d-block">
      {children}
    </BaseFeedback>
  );
}
