import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { TestRoom } from './models';
import { getOrCreateTestRoom } from './service';

interface TestRoomState {
  loading: LoadingState;
  error?: string | null;
  show: TestRoom;
}

const initialState: TestRoomState = {
  loading: LoadingState.Idle,
  error: null,
  show: {
    jitsiDomain: '',
    virtualRoomUUID: '',
    jwt: '',
    messengerThreadID: null,
  },
};

const testRoomSlice = createSlice({
  name: 'testRoom',
  initialState,
  reducers: {
    updateMessengerThread(state, action: PayloadAction<number>) {
      state.show.messengerThreadID = action.payload;
    },
  },
  extraReducers: (builder) => {
    //#region Get or create test room
    builder
      .addCase(getOrCreateTestRoom.pending, sharedPendingReducer)
      .addCase(getOrCreateTestRoom.rejected, sharedRejectedReducer)
      .addCase(getOrCreateTestRoom.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.show.jitsiDomain = action.payload.jitsiDomain;
        state.show.virtualRoomUUID = action.payload.virtualRoomUUID;
        state.show.jwt = action.payload.jwt;
        state.show.messengerThreadID = action.payload.messengerThreadID;
      });
    //#endregion Get or create test room
  },
});

export default testRoomSlice;
