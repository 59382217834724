import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { checkIsActive } from '@/_metronic/helpers';

import './TopbarMenuItem.scss';

type TopbarMenuItemProps = {
  to: string;
  title: string;
  icon?: React.ReactNode | undefined;
  isPulsing?: boolean;
};

export function TopbarMenuItem({ to, title, icon, isPulsing = false }: TopbarMenuItemProps) {
  const { pathname } = useLocation();

  return (
    <div className="menu-item mx-lg-1">
      <Link
        className={clsx('menu-link py-3', {
          active: checkIsActive(pathname, to),
          pulse: isPulsing,
        })}
        to={to}
      >
        {icon}
        <span className="menu-title">{title}</span>
      </Link>
    </div>
  );
}
