import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { Card } from '@/app/components/Card/Card';

import './SideNav.scss';

type SideNavProps = {
  children: ReactNode;
};

export function SideNav({ children }: SideNavProps) {
  const history = useHistory();
  const [isShown, setIsShown] = useState(false);

  function toggleIsShown() {
    setIsShown((prev) => !prev);
  }

  useEffect(
    function handleRouteChanges() {
      return history.listen(() => setIsShown(false));
    },
    [history]
  );

  return (
    <Card>
      <button
        type="button"
        className="btn btn-bg-white d-flex align-items-center w-100 px-3 d-md-none"
        onClick={toggleIsShown}
      >
        <span className="d-block mx-auto">{!isShown ? 'Покажи навигацията' : 'Скрий навигацията'}</span>
        <i className={clsx('far', { 'fa-chevron-up': isShown, 'fa-chevron-down': !isShown })} />
      </button>

      <nav
        className={clsx('nav side-nav flex-column d-md-flex', {
          'd-flex': isShown,
          'd-none': !isShown,
        })}
      >
        {children}
      </nav>
    </Card>
  );
}
