import { createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { FilteredTutorCourse, TutorCourseSubject, TutorCourseSubjectTargetAudience } from './models';
import { getFilteredTutorCourses, getTutorCourseSubjects, getTutorCourseSubjectTargetAudiences } from './service';

interface BuyCourseLessonState {
  loading: LoadingState;
  error?: string | null;
  subjects: TutorCourseSubject[] | null;
  targetAudences: TutorCourseSubjectTargetAudience[] | null;
  courses: FilteredTutorCourse[] | null;
}

const initialState: BuyCourseLessonState = {
  loading: LoadingState.Idle,
  error: null,
  subjects: null,
  targetAudences: null,
  courses: null,
};

const buyCourseLessonSlice = createSlice({
  name: 'buyCourseLesson',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region Get tutor subjects
    builder
      .addCase(getTutorCourseSubjects.pending, sharedPendingReducer)
      .addCase(getTutorCourseSubjects.rejected, sharedRejectedReducer)
      .addCase(getTutorCourseSubjects.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.subjects = action.payload.subjects;
      });
    //#endregion Get tutor subjects

    //#region Get tutor target audiences
    builder
      .addCase(getTutorCourseSubjectTargetAudiences.pending, sharedPendingReducer)
      .addCase(getTutorCourseSubjectTargetAudiences.rejected, sharedRejectedReducer)
      .addCase(getTutorCourseSubjectTargetAudiences.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.targetAudences = action.payload.targetAudiences;
      });
    //#endregion Get tutor target audiences

    //#region Get tutor courses
    builder
      .addCase(getFilteredTutorCourses.pending, sharedPendingReducer)
      .addCase(getFilteredTutorCourses.rejected, sharedRejectedReducer)
      .addCase(getFilteredTutorCourses.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courses = action.payload.courses;
      });
    //#endregion Get tutor courses
  },
});

export default buyCourseLessonSlice;
