import { forwardRef } from 'react';
import { Col, Row } from 'react-bootstrap';

import { CourseFeedbacksPaginatedData } from '@/app/modules/course/models';
import { CourseFeedbackComment } from '@/app/modules/course-feedback/CourseFeedbackComment';

type CourseFeedbackSectionProps = {
  sectionTitle: string;
  feedbacks: CourseFeedbacksPaginatedData;
  onPreviousPageClick: () => void;
  onNextPageClick: () => void;
};

export const CourseFeedbackSection = forwardRef<HTMLDivElement, CourseFeedbackSectionProps>(
  function CourseFeedbackSection(
    { sectionTitle, feedbacks, onPreviousPageClick, onNextPageClick }: CourseFeedbackSectionProps,
    ref
  ) {
    if (feedbacks.data.length === 0) {
      return null;
    }

    return (
      <div ref={ref}>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <h4 className="fs-3 mb-5 mt-8">{sectionTitle}</h4>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm btn-icon bg-white border ms-2 my-1 border-gray-300"
              onClick={onPreviousPageClick}
              disabled={feedbacks.currentPage === 1}
            >
              <i className="fas fa-chevron-left text-dark" />
            </button>
            <button
              type="button"
              className="btn btn-sm btn-icon bg-white border ms-2 my-1 border-gray-300"
              onClick={onNextPageClick}
              disabled={!feedbacks.hasMore}
            >
              <i className="fas fa-chevron-right text-dark" />
            </button>
          </div>
        </div>
        <Row className="g-5">
          {feedbacks.data.map((comment) => {
            return (
              <Col key={comment.id} md={4}>
                <CourseFeedbackComment
                  authorName={comment.authorName}
                  courseComment={comment.courseComment}
                  courseRating={comment.courseRating}
                  createdAt={comment.createdAt}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
);
