import { createSlice } from '@reduxjs/toolkit';

import { PlatformSettingsData } from '@/app/modules/platform-settings/models';
import { getPlatformSettings } from '@/app/modules/platform-settings/service';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

interface PlatformSettingsState {
  loading: LoadingState;
  error?: string | null;
  settings: PlatformSettingsData;
}

const initialState: PlatformSettingsState = {
  loading: LoadingState.Idle,
  error: null,
  settings: {
    coursePageBanner: {
      isActive: false,
      title: '',
      buttonText: '',
      link: '',
    },
    consultUs: {
      isActive: false,
      buttonText: '',
      link: '',
    },
    mainPageBanner: {
      isActive: false,
      title: '',
      buttonText1: '',
      link1: '',
      buttonText2: '',
      link2: '',
    },
  },
};

const platformSettingsSlice = createSlice({
  name: 'platformSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region GET PLATFORM SETTINGS DATA
    builder
      .addCase(getPlatformSettings.pending, sharedPendingReducer)
      .addCase(getPlatformSettings.rejected, sharedRejectedReducer)
      .addCase(getPlatformSettings.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.settings.coursePageBanner.isActive = Number(action.payload.coursePageBanner.isActive) === 1;
          state.settings.coursePageBanner.title = action.payload.coursePageBanner.title;
          state.settings.coursePageBanner.buttonText = action.payload.coursePageBanner.buttonText;
          state.settings.coursePageBanner.link = action.payload.coursePageBanner.link;
          state.settings.consultUs.isActive = Number(action.payload.consultUs.isActive) === 1;
          state.settings.consultUs.buttonText = action.payload.consultUs.buttonText;
          state.settings.consultUs.link = action.payload.consultUs.link;
          state.settings.mainPageBanner.isActive = Number(action.payload.mainPageBanner.isActive) === 1;
          state.settings.mainPageBanner.title = action.payload.mainPageBanner.title;
          state.settings.mainPageBanner.buttonText1 = action.payload.mainPageBanner.buttonText1;
          state.settings.mainPageBanner.link1 = action.payload.mainPageBanner.link1;
          state.settings.mainPageBanner.buttonText2 = action.payload.mainPageBanner.buttonText2;
          state.settings.mainPageBanner.link2 = action.payload.mainPageBanner.link2;
        }
      });
    //#endregion GET PLATFORM SETTINGS DATA
  },
});

export default platformSettingsSlice;
