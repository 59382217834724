import { ReactNode } from 'react';

import { childrenToString } from '@/app/utils/childrenToString';

type EventTitleProps = {
  children: ReactNode;
};

export function EventTitle({ children }: EventTitleProps) {
  return (
    <div className="fc-event-title-container">
      <div className="fc-event-title fc-sticky pt-0">
        <h6 className="fw-normal text-truncate mb-0" title={childrenToString(children)}>
          {children}
        </h6>
      </div>
    </div>
  );
}
