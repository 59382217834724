import React, { useCallback, useEffect } from 'react';

import { Button } from '@/app/components/Button/Button';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { BuyLessonStepNumber, GetTutorSubjectsResponse, TutorCourseSubject } from '../models';
import { getTutorCourseSubjects } from '../service';

type ChooseSubjectStepProps = {
  tutorID: number;
  setTitle: React.Dispatch<React.SetStateAction<string | null>>;
  setBuyLessonStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedSubject: React.Dispatch<React.SetStateAction<TutorCourseSubject | null>>;
  onClose: () => void;
};

export function ChooseSubjectStep({
  tutorID,
  setTitle,
  setBuyLessonStep,
  setSelectedSubject,
  onClose,
}: ChooseSubjectStepProps) {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.buyCourseLesson.loading);
  const subjects = useAppSelector((state) => state.buyCourseLesson.subjects);

  useEffect(
    function componentDidMount() {
      setTitle('Изберете предмет на обучението');

      async function fetchSubjectsData() {
        const response = await dispatch(getTutorCourseSubjects(Number(tutorID)));

        const responsePayload = response.payload as GetTutorSubjectsResponse;

        if (responsePayload?.subjects?.length === 0) {
          return onClose();
        }

        if (responsePayload?.subjects?.length === 1) {
          setSelectedSubject(responsePayload?.subjects[0]);
          setBuyLessonStep(BuyLessonStepNumber.TargetAudience);
          return;
        }
      }

      if (loading === LoadingState.Idle) {
        fetchSubjectsData();
      }
    },
    [dispatch, setBuyLessonStep, setSelectedSubject, setTitle]
  );

  const handleSelectSubject = useCallback(
    (event) => {
      const subjectID = event.target.value;
      const subject = subjects?.find((s) => s.id === Number(subjectID));

      if (subject) {
        setSelectedSubject(subject);
        setBuyLessonStep(BuyLessonStepNumber.TargetAudience);
      }
    },
    [setBuyLessonStep, setSelectedSubject, subjects]
  );

  return (
    <div className="d-flex flex-column mb-n2">
      {subjects?.map((subject, index) => (
        <Button
          key={index}
          value={subject.id}
          variant="white"
          className="border border-2 text-dark mb-2"
          onClick={handleSelectSubject}
        >
          {subject.name}
        </Button>
      ))}
    </div>
  );
}
