import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';

import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { LabelRequired } from '@/app/components/Form/Label/LabelRequired';
import { MultipleFileControl } from '@/app/components/Form/MultipleFileControl/MultipleFileControl';
import { PhoneControl } from '@/app/components/Form/PhoneControl/PhoneControl';
import { PictureControl } from '@/app/components/Form/PictureControl/PictureControl';
import { useForm } from '@/app/components/Form/useForm';
import { HELPER_MESSAGES } from '@/app/components/InformationPopover/constants';
import { InformationPopover } from '@/app/components/InformationPopover/InformationPopover';
import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { RichTextControl } from '@/app/components/RichTextControl/RichTextControl';
import { StepInstance } from '@/app/components/Stepper/models';
import { PROFILE_FILE_DIMENSION_ERROR } from '@/app/modules/profile/details/constants';
import { ABOUT_FIELD_PLACEHOLDER } from '@/app/utils/constants';
import { LoadingState } from '@/redux/constants';
import { RootState, useAppSelector } from '@/redux/store';

import { TutorState } from '../../models';
import { TUTOR_PROFILE_DETAILS_STEP_TITLE } from '../constants';
import { TutorProfileDetailsFormModel, TutorProfileDetailsStepModel } from '../models';

const contentSelectorCreate = (state: RootState) => state.tutor;
const contentSelectorEdit = (state: RootState) => state.tutor.edit.tutorProfileDetails;

export function useTutorProfileDetailsInitialValues(
  content: TutorState | TutorProfileDetailsStepModel,
  isEditMode = false
) {
  const initialValues = useMemo<TutorProfileDetailsFormModel>(
    () => ({
      profileFile: null,
      firstName: content.firstName ?? '',
      lastName: content.lastName ?? '',
      phone: content.phone ?? '',
      email: content.email ?? '',
      username: content.username ?? '',
      about: content.about ?? EMPTY_VALUE,
      generalTermsChecked: isEditMode ? 1 : 0,
      specificTermsChecked: isEditMode ? 1 : 0,
      childProtectionPolicyChecked: isEditMode ? 1 : 0,
      qualityCriteriaChecked: isEditMode ? 1 : 0,
      attachments: content?.profileDetailsAttachments ?? [],
    }),
    [content, isEditMode]
  );

  return { initialValues };
}

export function useTutorProfileDetailsValidationSchema(picture: string | null) {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        profileFile: yup
          .mixed()
          .test('requiredPicture', 'Полето е задължително.', (value) => {
            if (picture) {
              return true;
            }

            return value !== null;
          })
          .profileFileDimensions(200, 200, PROFILE_FILE_DIMENSION_ERROR),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phone: yup.mixed().required().phone(),
        email: yup.string().required().email(),
        username: yup.string().required(),
        about: yup.array().requiredRichText(),
        generalTermsChecked: yup.boolean().oneOf([true], 'Трябва да се съгласите с общите условия.'),
        specificTermsChecked: yup.boolean().oneOf([true], 'Трябва да се съгласите с допълнителните условия.'),
        childProtectionPolicyChecked: yup
          .boolean()
          .oneOf([true], 'Трябва да се съгласите с политиката за закрила на детето.'),
        qualityCriteriaChecked: yup.boolean().oneOf([true], 'Трябва да се съгласите с критериите за качество.'),
        attachments: yup.array().optional(),
      }),
    [picture]
  );
  return { validationSchema };
}

export function useTutorProfileDetailsCreateStep(stepNumber: number): StepInstance<TutorProfileDetailsFormModel> {
  const content = useAppSelector(contentSelectorCreate);

  const { initialValues } = useTutorProfileDetailsInitialValues(content);

  const { validationSchema } = useTutorProfileDetailsValidationSchema(content.picture);

  const formik = useForm<TutorProfileDetailsFormModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });
  return { formik, stepNumber };
}

export function useTutorProfileDetailsEditStep(stepNumber: number): StepInstance<TutorProfileDetailsFormModel> {
  const content = useAppSelector(contentSelectorEdit);

  const { initialValues } = useTutorProfileDetailsInitialValues(content, true);

  const { validationSchema } = useTutorProfileDetailsValidationSchema(content.picture);

  const formik = useForm<TutorProfileDetailsFormModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });
  return { formik, stepNumber };
}

type TutorProfileDetailsStepProps = {
  isEditMode?: boolean;
};

export function TutorProfileDetailsStep({ isEditMode = false }: TutorProfileDetailsStepProps) {
  const content = useAppSelector(contentSelectorCreate);
  const initialPicture = !isEditMode ? content.picture : content.edit.tutorProfileDetails.picture;

  return (
    <>
      <CardHeader>
        <CardTitle>{TUTOR_PROFILE_DETAILS_STEP_TITLE}</CardTitle>
        <CardSubTitle>
          Вашият профил е Вашата визитна картичка. Подберете подходяща снимка и се уверете, че данните Ви са валидни.
        </CardSubTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <FormGroup controlId="profileFile" className="col-md-2 offset-md-1">
            <PictureControl name="profileFile" initialPicture={initialPicture} isRequired={content.picture === null} />
          </FormGroup>
          <Col md={6}>
            <Row>
              <FormGroup controlId="firstName" className="col-md-6">
                <Label>Име</Label>
                <FormControl type="text" name="firstName" disabled />
              </FormGroup>
              <FormGroup controlId="lastName" className="col-md-6">
                <Label>Фамилия</Label>
                <FormControl type="text" name="lastName" disabled />
              </FormGroup>
            </Row>
            <FormGroup controlId="phone">
              <Label>Телефон</Label>
              <PhoneControl name="phone" disabled />
            </FormGroup>
            <FormGroup controlId="email">
              <Label>Имейл адрес</Label>
              <FormControl type="email" name="email" disabled />
            </FormGroup>
            <FormGroup controlId="username">
              <Label>Потребителско име</Label>
              <FormControl type="text" name="username" disabled />
            </FormGroup>
            <FormGroup controlId="about">
              <Label isRequired>За мен</Label>
              <RichTextControl name="about" placeholder={ABOUT_FIELD_PLACEHOLDER} />
              <a href="/tutor-advices#tips-tutor-presentation" target="_blank" className="d-block">
                Съвети за пълноценно представяне
              </a>
            </FormGroup>
            <FormGroup className="mb-4" controlId="attachments">
              <InformationPopover helperMessage={HELPER_MESSAGES.profile.attachments}>
                <Label>Педагогически постижения</Label>
              </InformationPopover>
              <MultipleFileControl name="attachments" accept=".pdf, .png, .jpg, .jpeg, .doc, .docx" />
            </FormGroup>
            <FormGroup className="mb-4" controlId="generalTermsChecked">
              <CheckboxControl
                name="generalTermsChecked"
                label={
                  <>
                    Съгласявам се с{' '}
                    <a href="/tutor-terms" target="_blank">
                      общите условия
                    </a>
                    <LabelRequired />.
                  </>
                }
                value={1}
                isInline
              />
            </FormGroup>
            <FormGroup className="mb-4" controlId="specificTermsChecked">
              <CheckboxControl
                name="specificTermsChecked"
                label={
                  <>
                    Съгласявам се с{' '}
                    <a href="/additional-tutor-terms" target="_blank">
                      допълнителните условия
                    </a>
                    <LabelRequired />.
                  </>
                }
                value={1}
                isInline
              />
            </FormGroup>
            <FormGroup className="mb-4" controlId="childProtectionPolicyChecked">
              <CheckboxControl
                name="childProtectionPolicyChecked"
                label={
                  <>
                    Съгласявам се с{' '}
                    <a href="/child-protection-policy" target="_blank">
                      политиката за закрила на детето
                    </a>
                    <LabelRequired />.
                  </>
                }
                value={1}
                isInline
              />
            </FormGroup>
            <FormGroup className="mb-4" controlId="qualityCriteriaChecked">
              <CheckboxControl
                name="qualityCriteriaChecked"
                label={
                  <>
                    Съгласявам се с{' '}
                    <a href="/quality-criteria" target="_blank">
                      критериите за качество
                    </a>
                    <LabelRequired />.
                  </>
                }
                value={1}
                isInline
              />
            </FormGroup>
            <FormGroup>
              <Label>
                За да провеждате по-добри онлайн обучения, вижте нашите{' '}
                <a target="_blank" href="/tutor-advices">
                  Съвети за обучители
                </a>
                .
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </>
  );
}
