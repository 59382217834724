import { Link } from 'react-router-dom';

import { DEFAULT_PROFILE_PICTURE_PATH } from '../constants';

import './ProfileMenuItem.scss';

const PROFILE_PATH = '/profile';

type ProfileMenuItemProps = {
  firstName: string;
  profilePicturePath: string | null;
};

export function ProfileMenuItem({ firstName, profilePicturePath = null }: ProfileMenuItemProps) {
  return (
    <Link className="btn profile-menu-link d-flex align-items-center mx-lg-1 px-4" to={PROFILE_PATH} title="Профил">
      <div className="symbol rounded profile-image">
        <img
          src={profilePicturePath ?? DEFAULT_PROFILE_PICTURE_PATH}
          alt="Профилна снимка"
          className="profile-image me-3"
        />
      </div>
      <span className="fw-normal">{firstName}</span>
    </Link>
  );
}
