import clsx from 'clsx';
import { useFormikContext } from 'formik';

import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';

import { Feedback } from '../../Feedback/Feedback';
import { RatingRadioInput } from './RatingRadioInput';

import './RatingControl.scss';

type RatingControlProps = {
  name: string;
};

export function RatingControl({ name }: RatingControlProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<number>(name);
  const isInvalid = Boolean(field.error);

  return (
    <FormGroup className="d-flex flex-column align-items-center mb-4" controlId={name}>
      <div className={clsx('rating position-relative', { 'is-invalid': isInvalid })}>
        <RatingRadioInput name={name} value={1} />
        <RatingRadioInput name={name} value={2} />
        <RatingRadioInput name={name} value={3} />
        <RatingRadioInput name={name} value={4} />
        <RatingRadioInput name={name} value={5} />
      </div>

      {isInvalid && <Feedback type="invalid">{field.error}</Feedback>}
    </FormGroup>
  );
}
