import { ReactNode } from 'react';
import { Col } from 'react-bootstrap';

type CardSectionContentProps = {
  children: ReactNode;
  className?: string;
};

export function CardSectionContent({ children, className }: CardSectionContentProps) {
  return (
    <Col className={className} xs={12} xl={{ span: true }}>
      {children}
    </Col>
  );
}
