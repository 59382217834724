import { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from '@/app/components/Button/Button';
import { ModalCloseButton } from '@/app/components/Modal/ModalCloseButton';

import { BuyLessonStepNumber, TutorCourseSubject, TutorCourseSubjectTargetAudience } from './models';
import { StepperProgressBar } from './StepperProgressBar/StepperProgressBar';
import { ChooseCourseStep } from './steps/ChooseCourseStep';
import { ChooseSubjectStep } from './steps/ChooseSubjectStep';
import { ChooseTargetAudienceStep } from './steps/ChooseTargetAudienceStep';

type BuyCourseLessonsForTutorModalProps = {
  tutorID: number;
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
};

export function BuyCourseLessonsForTutorModal({ tutorID, isShown, setIsShown }: BuyCourseLessonsForTutorModalProps) {
  const [buyLessonStep, setBuyLessonStep] = useState<number>(BuyLessonStepNumber.Subject);
  const [title, setTitle] = useState<string | null>(null);
  const [selectedSubject, setSelectedSubject] = useState<TutorCourseSubject | null>(null);
  const [selectedTargetAudience, setSelectedTargetAudience] = useState<TutorCourseSubjectTargetAudience | null>(null);

  function handleClickClose() {
    setIsShown(false);
    setBuyLessonStep(BuyLessonStepNumber.Subject);
    setSelectedSubject(null);
    setSelectedTargetAudience(null);
  }

  return (
    <Modal show={isShown} onHide={handleClickClose} contentClassName="text-center" centered>
      <Modal.Header className="border-0 flex-wrap position-relative">
        <Modal.Title className="flex-grow-1 px-10">{title}</Modal.Title>
        <ModalCloseButton onClose={handleClickClose} />
      </Modal.Header>
      <Modal.Body className="pt-md-0 pb-5">
        <StepperProgressBar buyLessonStep={buyLessonStep} />

        <div className="ms-1 my-5 d-flex flex-column text-start">
          {selectedSubject && (
            <span>
              <strong>Предмет: </strong>
              {selectedSubject.name}
            </span>
          )}
          {selectedTargetAudience && (
            <span>
              <strong>Клас/възраст на обучаемия: </strong>
              {selectedTargetAudience.name}
            </span>
          )}
        </div>

        {buyLessonStep === BuyLessonStepNumber.Subject && (
          <ChooseSubjectStep
            tutorID={tutorID}
            setTitle={setTitle}
            setBuyLessonStep={setBuyLessonStep}
            setSelectedSubject={setSelectedSubject}
            onClose={handleClickClose}
          />
        )}

        {buyLessonStep === BuyLessonStepNumber.TargetAudience && (
          <ChooseTargetAudienceStep
            tutorID={tutorID}
            selectedSubject={selectedSubject}
            setTitle={setTitle}
            setBuyLessonStep={setBuyLessonStep}
            setSelectedTargetAudience={setSelectedTargetAudience}
            onClose={handleClickClose}
          />
        )}

        {buyLessonStep === BuyLessonStepNumber.Course && (
          <ChooseCourseStep
            tutorID={tutorID}
            selectedSubject={selectedSubject}
            selectedTargetAudience={selectedTargetAudience}
            setTitle={setTitle}
            onClose={handleClickClose}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center pt-0 border-top-0">
        <Button variant="secondary" onClick={handleClickClose}>
          Отказ
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
