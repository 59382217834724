import { useContext } from 'react';
import { Col } from 'react-bootstrap';

import { Button } from '@/app/components/Button/Button';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardFooter } from '@/app/components/Card/CardFooter';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { StepperContext } from '@/app/components/Stepper/StepperContext';

type TutorProfilePreviewCardProps = {
  header: string;
  editStep: number;
  children: React.ReactNode;
};

export function TutorProfilePreviewCard({ header, editStep, children }: TutorProfilePreviewCardProps) {
  const stepper = useContext(StepperContext);

  function handleClick() {
    stepper.goToStep(editStep);
  }

  return (
    <Col md={4}>
      <Card variant="solid" className="h-100">
        <CardHeader>
          <CardTitle>{header}</CardTitle>
        </CardHeader>

        <CardBody>{children}</CardBody>

        <CardFooter className="d-flex justify-content-center">
          <Button
            size="sm"
            type="button"
            variant="light-primary"
            className="w-100"
            onClick={handleClick}
            disabled={stepper.isSubmitting}
          >
            <i className="stepper-check fas fa-pen"></i> Редактирай
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
}
