import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isAfter, isEqual, parse, set, subMinutes } from 'date-fns';

import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { VirtualRoom } from '@/app/components/VirtualRoom/VirtualRoom';
import { VirtualRoomMessengerThread } from '@/app/modules/messenger/VirtualRoomMessengerThread';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { CLOSE_LESSON_BUFFER_MINUTES } from '../my-schedule/constants';
import { UpcomingLesson } from '../my-schedule/models';
import { EnterLessonReminder } from './EnterLessonReminder/EnterLessonReminder';
import { getLessonShow } from './service';
import { isLessonOver } from './utils';

export function LessonShowPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const loading = useAppSelector((state) => state.lesson.loading);
  const data = useAppSelector((state) => state.lesson.show);
  const upcomingLessons = useAppSelector((state) => state.mySchedule.upcomingLessons);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const lessonEndIntervalID = useRef<NodeJS.Timer | number | null>(null);
  const upcomingLessonIntervalID = useRef<NodeJS.Timer | number | null>(null);
  const [isChatShown, setIsChatShown] = useState<boolean>(false);
  const [upcomingLesson, setUpcomingLesson] = useState<UpcomingLesson | null>(null);

  function handleChatToggle() {
    setIsChatShown((prev) => !prev);
  }

  const handleLessonEnd = useCallback(() => {
    if (lessonEndIntervalID.current) {
      clearInterval(lessonEndIntervalID.current as number);
    }

    history.replace(`/lessons/${id}/end`);
  }, [history, id]);

  useEffect(
    function loadInitialLessonData() {
      async function fetchInitialData() {
        try {
          // STEP 1: Get lesson data.
          const data = await dispatch(getLessonShow(Number(id))).unwrap();
          const endDateTime = parse(data.endDateTime, DEFAULT_DATETIME_FORMAT, new Date());

          // STEP 2: Make sure lessons is not over.
          if (isLessonOver(endDateTime)) {
            return handleLessonEnd();
          }

          // STEP 3: Data loading is done.
          setIsDataLoading(false);

          // STEP 4: Setup timer to close the VirtualRoom when Lesson ends.
          lessonEndIntervalID.current = setInterval(() => {
            if (isLessonOver(endDateTime)) {
              clearInterval(lessonEndIntervalID.current as number);
              clearInterval(upcomingLessonIntervalID.current as number);
              handleLessonEnd();
            }
          }, 1000);
        } catch (error) {
          // Errors are handled by axios interceptors.
          setIsDataLoading(false);
        }
      }

      if (loading === LoadingState.Idle) {
        fetchInitialData();
      }

      return function leaveCurrentLesson() {
        if (lessonEndIntervalID.current) {
          clearInterval(lessonEndIntervalID.current as number);
        }
      };
    },
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    upcomingLessonIntervalID.current = setInterval(() => {
      const lesson = upcomingLessons.find((lesson: UpcomingLesson) => {
        const now = set(new Date(), { seconds: 0, milliseconds: 0 });
        const upcomingLessonStartDateTime = parse(lesson.startDateTime, DEFAULT_DATETIME_FORMAT, new Date());
        const currentLessonEndDateTime = subMinutes(
          parse(data.endDateTime, DEFAULT_DATETIME_FORMAT, new Date()),
          CLOSE_LESSON_BUFFER_MINUTES
        );

        return (
          data.id !== Number(lesson.lessonID) &&
          isAfter(upcomingLessonStartDateTime, currentLessonEndDateTime) &&
          (isAfter(now, upcomingLessonStartDateTime) || isEqual(upcomingLessonStartDateTime, now))
        );
      });

      if (lesson) {
        setUpcomingLesson(lesson);
        return;
      }

      setUpcomingLesson(null);
    }, 1000);

    return function componentWillUnmount() {
      if (upcomingLessonIntervalID.current) {
        clearInterval(upcomingLessonIntervalID.current as number);
      }
    };
  }, [data, upcomingLessons]);

  return (
    <>
      <div className="d-flex">
        {isChatShown && (
          <VirtualRoomMessengerThread
            id={data.messengerThreadID}
            recipient={data.recipient}
            handleChatToggle={handleChatToggle}
            isStudent={data.isStudent}
          />
        )}
        <VirtualRoom
          domain={data.jitsiDomain}
          uuid={data.virtualRoomUUID}
          jwt={data.jwt}
          onReadyToClose={handleLessonEnd}
          isDataLoading={isDataLoading}
          onChatToggle={handleChatToggle}
          shouldSeparateTimerFromSubject
          endDateTime={data.endDateTime}
        />
      </div>

      {upcomingLesson && upcomingLesson.participantName && upcomingLesson.lessonID !== data.id && (
        <EnterLessonReminder
          name={upcomingLesson?.participantName}
          time={upcomingLesson.startTime}
          lessonID={upcomingLesson.lessonID}
        />
      )}
    </>
  );
}
