import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { getTutorHasSchedule } from '@/app/modules/auth/service';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function TutorHasScheduleNotificationModal() {
  const { hasSchedule, hasScheduleLoading, tutorID } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    if (tutorID) {
      dispatch(getTutorHasSchedule());
    }
  }, [dispatch, tutorID]);

  useEffect(() => {
    if (!hasSchedule && hasScheduleLoading === LoadingState.Idle && tutorID) {
      setIsShown(true);
    }
  }, [hasSchedule, hasScheduleLoading, tutorID]);

  function handleOnHide() {
    setIsShown(false);
  }

  return (
    <Modal show={isShown} onHide={handleOnHide} backdrop="static" keyboard={false} className="d-block">
      <Modal.Header className="border-0 pb-0" closeButton />
      <Modal.Body>
        Профилът Ви временно е скрит за потребителите, поради липсата на въведен график. Посочете минимум 5 свободни
        часа, за да активираме профила Ви и потребителите да могат да закупят уроци при Вас.
      </Modal.Body>
      <LinkButton
        to="/my-schedule/calendar"
        variant="primary"
        className="mx-auto mb-7 mw-200px d-block w-100"
        onClick={handleOnHide}
      >
        Моят график
      </LinkButton>
    </Modal>
  );
}
