import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { KTSVG } from '@/_metronic/helpers';
import { Button } from '@/app/components/Button/Button';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { sendTutorForApproval } from '../auth/service';

const REDIRECT_TO_BECOME_PAGE = '/tutor/become';

export function TutorRejectedPage() {
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tutorStatusID !== TutorStatusID.Rejected) {
      history.push(REDIRECT_TO_BECOME_PAGE);
    }
  }, [history, tutorStatusID]);

  async function handleSendTutorForApproval() {
    await dispatch(sendTutorForApproval());
  }

  return (
    <Page>
      <Content>
        <div className="mx-auto text-center tutor-waiting-approval-page-container">
          <div className="my-10">
            <KTSVG path="/img/svg/BookmarkX.svg" className="svg-icon-5x" />
          </div>

          <h1 className="text-gray-800 fs-2tx">Вашата заявка е отказана.</h1>

          <p className="text-gray-700 fs-4 mb-10">
            Ако искате да я изпратите отново, натиснете бутон <q>Изпрати за одобрение</q>.
          </p>

          <Button type="button" onClick={handleSendTutorForApproval}>
            Изпрати за одобрение
          </Button>
        </div>
      </Content>
    </Page>
  );
}
