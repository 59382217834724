import { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { parse } from 'date-fns';

import { useHiddenConsultUs } from '@/_metronic/layout/components/consult-us/useHiddenConsultUs';
import { FooterContext } from '@/app/layout/Footer/FooterContext';
import { HeaderContext } from '@/app/layout/HeaderContext';
import { LoadingState } from '@/redux/constants';

import { DEFAULT_DATETIME_FORMAT } from '../Form/DatePickerControl/constants';
import { GlobalSpinner } from '../Spinner/GlobalSpinner';
import { getJitsiOptions, willLessonEndAfter } from './utils';

import '@/app/jitsi/external_api.min';
import './VirtualRoom.scss';

type VirtualRoomProps = {
  domain: string;
  uuid: string;
  jwt: string;
  onChatToggle: () => void;
  onReadyToClose?: (event?: Record<string, unknown> | undefined) => void;
  isDataLoading?: boolean;
  shouldSeparateTimerFromSubject?: boolean;
  endDateTime?: string;
};

interface JitsiFullScreenEvent extends Record<string, unknown> {
  shouldEnableFullScreen?: boolean;
}

export function VirtualRoom({
  domain,
  uuid,
  jwt,
  onReadyToClose,
  onChatToggle,
  isDataLoading = false,
  shouldSeparateTimerFromSubject = false,
  endDateTime,
}: VirtualRoomProps) {
  const [internalLoading, setInternalLoading] = useState(LoadingState.Idle);
  const containerElRef = useRef<HTMLDivElement | null>(null);
  const jitsiAPIRef = useRef<JitsiMeetExternalAPI | null>(null);
  const lessonEndDateTime = endDateTime && parse(endDateTime, DEFAULT_DATETIME_FORMAT, new Date());
  const lessonEndsReminderIntervalID = useRef<NodeJS.Timer | number | null>(null);
  const fiveMinutesReminderIsShown = useRef<boolean>(false);
  const headerContext = useContext(HeaderContext);
  const footerContext = useContext(FooterContext);

  function hideHeaderAndFooter() {
    headerContext.hide();
    footerContext.hide();
  }

  function showHeaderAndFooter() {
    headerContext.show();
    footerContext.show();
  }

  async function handleFullScreenToggle(e: JitsiFullScreenEvent | undefined) {
    if (!e) {
      return;
    }

    if (e.shouldEnableFullScreen) {
      hideHeaderAndFooter();
      await document.body.requestFullscreen();
    }

    if (!e.shouldEnableFullScreen && document.fullscreenElement) {
      showHeaderAndFooter();
      await document.exitFullscreen();
    }
  }

  function handleFullScreenEvent() {
    if (!document.fullscreenElement) {
      showHeaderAndFooter();
    }
  }

  function handleReloadNow() {
    location.reload();
  }

  useEffect(
    function initJitsi() {
      if (containerElRef.current && !isDataLoading && domain !== '' && uuid !== '' && jwt !== '') {
        setInternalLoading(LoadingState.Pending);

        const options = getJitsiOptions(
          uuid,
          jwt,
          containerElRef.current,
          lessonEndDateTime as Date,
          shouldSeparateTimerFromSubject,
          () => {
            setInternalLoading(LoadingState.Idle);
          }
        );

        jitsiAPIRef.current = new JitsiMeetExternalAPI(domain, options);

        if (onReadyToClose) {
          jitsiAPIRef.current?.addListener?.('readyToClose', onReadyToClose);
        }

        jitsiAPIRef.current?.addListener('reloadNow', handleReloadNow);
      }
      document.addEventListener('fullscreenchange', handleFullScreenEvent);

      return function destroyJitsi() {
        if (onReadyToClose) {
          jitsiAPIRef.current?.removeListener?.('readyToClose', onReadyToClose);
        }

        jitsiAPIRef.current?.removeListener?.('reloadNow', handleReloadNow);

        document.removeEventListener('fullscreenchange', handleFullScreenEvent);
        jitsiAPIRef.current?.dispose?.();
      };
    },
    [domain, uuid, jwt, onReadyToClose, isDataLoading] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if (internalLoading === LoadingState.Pending) {
        return;
      }

      jitsiAPIRef.current?.addListener?.('chatToggleChanged', onChatToggle);
      jitsiAPIRef.current?.addListener?.('fullScreenToggleChanged', handleFullScreenToggle);

      if (lessonEndDateTime) {
        lessonEndsReminderIntervalID.current = setInterval(() => {
          if (willLessonEndAfter(lessonEndDateTime, 1)) {
            jitsiAPIRef.current?.executeCommand?.(
              'showNotification',
              'Стаята ще се затвори автоматично след 1 минута.'
            );
            clearInterval(lessonEndsReminderIntervalID.current as number);
            return;
          }

          if (willLessonEndAfter(lessonEndDateTime, 5) && !fiveMinutesReminderIsShown.current) {
            jitsiAPIRef.current?.executeCommand?.(
              'showNotification',
              'Стаята ще се затвори автоматично след 5 минути.'
            );
            fiveMinutesReminderIsShown.current = true;
          }
        }, 1000);
      }

      return () => {
        jitsiAPIRef.current?.removeListener?.('chatToggleChanged', onChatToggle);
        jitsiAPIRef.current?.removeListener?.('fullScreenToggleChanged', handleFullScreenToggle);

        if (lessonEndDateTime && lessonEndsReminderIntervalID.current) {
          clearInterval(lessonEndsReminderIntervalID.current as number);
        }
      };
    },
    [internalLoading, onChatToggle, handleFullScreenToggle] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useHiddenConsultUs();

  return (
    <>
      <div
        ref={containerElRef}
        className={clsx('virtual-room__container w-100', { 'full-screen-height': !headerContext.isVisible })}
      />

      {(isDataLoading || internalLoading === LoadingState.Pending) && <GlobalSpinner />}
    </>
  );
}
