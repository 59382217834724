export const MAIN_HOME_SECTIONS = {
  findCourse: {
    header: 'Намерете обучение',
    paragraph:
      'Открийте подходящото за Вас обучение от над 20 предмета в платформата. Разгледайте описанието и се запознайте с обучителя.',
    svg: '/img/svg/home-pages/15.svg?v=1.0.0',
  },
  reserveTime: {
    header: 'Запазете час',
    paragraph:
      'Проверете графика на обучението, ако отговаря на Вашите нужди, може да закупите един урок или пакет от уроци за това обучение.',
    svg: '/img/svg/home-pages/106.svg?v=1.0.0',
  },
  studyRemote: {
    header: 'Учете онлайн',
    paragraph:
      'Посетете урока от където и да е чрез нашата виртуална класна стая. Интегрирано решение, което предлага видео и аудио връзка, както и набор от дигитални образователни инструменти за улеснение по различните предмети.',
    svg: '/img/svg/home-pages/16.svg?v=1.0.0',
  },
  intuitiveSearch: {
    header: 'Интуитивно търсене',
    paragraph:
      'Откриване на обучение по критерии като "Предмет", "Възраст", "Цена" и "Свободни часове". Модерен дизайн, съобразен с потребителското изживяване. Всички дисциплини на едно място.',
    image: '/img/home-pages/main/intuitiveSearch.png?v=1.0.0',
    webp: '/img/home-pages/main/intuitiveSearch.webp?v=1.0.0',
    imageDimensions: {
      width: 400,
      height: 240,
    },
  },
  participantsRating: {
    header: 'Прозрачен рейтинг на участниците',
    paragraph: 'Оценка, базирана на реални потребители.',
    image: '/img/home-pages/main/participantsRating.jpg?v=1.0.1',
    webp: '/img/home-pages/main/participantsRating.webp?v=1.0.2',
    imageDimensions: {
      width: 473,
      height: 240,
    },
  },
  virtualClassRoom: {
    header: 'Виртуална класна стая',
    paragraph:
      'Посетете урока от където и да е чрез нашата виртуална класна стая. Интегрирано решение, което предлага видео и аудио връзка, както и функционалности за улеснение на различните предмети.',
    image: '/img/home-pages/main/virtualClassRoom.png?v=1.0.0',
    webp: '/img/home-pages/main/virtualClassRoom.webp?v=1.0.0',
    imageDimensions: {
      width: 400,
      height: 240,
    },
  },
  interactiveBoard: {
    header: 'Интерактивна дъска за упражнения',
    paragraph: 'Упражненията на бялата дъска се осъществяват в реално време, с помощта на дигитални инстументи.',
    image: '/img/home-pages/main/interactiveBoard.jpg?v=1.0.1',
    webp: '/img/home-pages/main/interactiveBoard.webp?v=1.0.1',
    imageDimensions: {
      width: 400,
      height: 240,
    },
  },
  realTimeSchedule: {
    header: 'График в реално време',
    paragraph: 'Без излишни, повтарящи се обаждания за намиране на удобен свободен час.',
    image: '/img/home-pages/main/realTimeSchedule.png?v=1.0.1',
    webp: '/img/home-pages/main/realTimeSchedule.webp?v=1.0.1',
    imageDimensions: {
      width: 400,
      height: 240,
    },
  },
  cardPayments: {
    header: 'Картови разплащания',
    paragraph:
      'Лесна и бърза обработка на плащания за улеснение на потребителите. Сигурност и защита на средствата им.',
    image: '/img/home-pages/main/cardPayments.png?v=1.0.0',
    webp: '/img/home-pages/main/cardPayments.webp?v=1.0.0',
    imageDimensions: {
      width: 400,
      height: 240,
    },
  },
};
