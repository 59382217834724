import { useEffect, useMemo, useRef } from 'react';

import authStore from '@/app/modules/auth/store';
import { getMessengerThreadsPaginated } from '@/app/modules/messenger/service';
import { getMyScheduleNotificationsCount, getUpcomingLessons } from '@/app/modules/my-schedule/service';
import notificationStore from '@/app/modules/notification/store';
import { identityChannelName } from '@/app/utils/real-time/constants';
import { TutorBalanceUpdateEvent } from '@/app/utils/real-time/models';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getNotifications } from '../../modules/notification/service';
import { initEcho } from './initEcho';

export function useRealTimeEvents() {
  const identityID = useAppSelector((state) => state.auth.identityID);
  const notifications = useAppSelector((state) => state.messenger?.notifications ?? []);
  const dispatch = useAppDispatch();
  const isSubscribed = useRef<boolean>(false);
  const echo = useMemo(() => initEcho(), []);

  useEffect(
    function handleEchoInit() {
      if (!identityID) {
        return;
      }

      const identityChannel = `${identityChannelName}.${identityID}`;
      echo
        ?.private?.(identityChannel)
        ?.notification?.(async () => {
          await dispatch(notificationStore.actions.reset());
          dispatch(getNotifications({ page: 1 }));
        })
        ?.subscribed?.(async () => {
          /* If already subscribed and this functions is called again, this means the connection was reestablished
          /* after some network issues(lost internet etc.)
          */
          if (isSubscribed.current) {
            await dispatch(notificationStore.actions.reset());
            dispatch(getNotifications({ page: 1 }));
          }

          isSubscribed.current = true;
        })
        ?.listen?.('.schedule.updated', () => {
          dispatch(getUpcomingLessons());
        })
        ?.listen?.('.schedule_notifications_count.updated', () => {
          dispatch(getMyScheduleNotificationsCount());
        })
        ?.listen?.('.messenger_notification.updated', () => {
          dispatch(getMessengerThreadsPaginated({ page: notifications?.currentPage }));
        })
        ?.listen?.('.tutor_balance.updated', (event: TutorBalanceUpdateEvent) => {
          dispatch(
            authStore.actions.updateBalanceAmount({
              balanceAmount: event.balanceAmount,
              shouldHighlightBalance: true,
            })
          );
        });

      return () => {
        echo?.leave?.(identityChannel);
      };
    },
    [identityID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return echo;
}
