import { FormEvent } from 'react';
import { Button, Form, Row } from 'react-bootstrap';

import { Label } from '../Form/Label/Label';

type EditLinkFormProps = {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleHide: () => void;
  defaultText?: string;
  defaultUrl?: string;
};

export function EditLinkForm({ handleSubmit, handleHide, defaultText = '', defaultUrl = '' }: EditLinkFormProps) {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="text">
        <Label>Текст за показване</Label>
        <Form.Control type="text" placeholder="Въведете текст" defaultValue={defaultText} />
      </Form.Group>
      <Form.Group controlId="url">
        <Label>URL Връзка</Label>
        <Form.Control type="text" placeholder="Въведете валидна URL връзка" defaultValue={defaultUrl} />
      </Form.Group>
      <Row className="mx-auto justify-content-center">
        <Button variant="outline-secondary" className="mr-3" onClick={handleHide}>
          Отмени
        </Button>
        <Button variant="primary" type="submit">
          Запази
        </Button>
      </Row>
    </Form>
  );
}
