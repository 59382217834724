import produce from 'immer';

import { formatPrice } from '@/app/utils/money';
import { stringifyQuery } from '@/app/utils/query';

import { DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE, DEFAULT_PAGE_VALUE } from './constants';
import {
  Income,
  ProfileProfitFilterQueryParams,
  ProfileProfitFilterValues,
  TutorBalanceTransferStatusID,
} from './models';

export function transformProfileProfitFormikValuesToSearchParams(
  formikValues: ProfileProfitFilterValues,
  initialItemsPerPage: number,
  queryParams: ProfileProfitFilterQueryParams
) {
  const newQueryParams: ProfileProfitFilterQueryParams = produce(queryParams, (draft) => {
    if (Number(formikValues.page) !== DEFAULT_PAGE_VALUE) {
      draft.page = formikValues.page;
    } else {
      delete draft.page;
    }
    if (Number(formikValues.itemsPerPage) !== DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE) {
      draft.itemsPerPage = formikValues.itemsPerPage;
    } else {
      delete draft.itemsPerPage;
    }

    //If items per page is changed, go to first page to prevent pagination bugs
    if (Number(formikValues.itemsPerPage) !== Number(initialItemsPerPage)) {
      delete draft.page;
    }
  });

  return stringifyQuery(newQueryParams);
}

export function formatIncomeAmount(price: string | number, income: Income) {
  let formatted = formatPrice(price);

  if (income.tutorBalanceTransferStatusID === TutorBalanceTransferStatusID.BonusBlocked) {
    formatted = `${formatted} (блокирани)`;
  }

  return formatted;
}
