import { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Button } from '@/app/components/Button/Button';
import { LinkButton } from '@/app/components/Button/LinkButton';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { useAppSelector } from '@/redux/store';

export function EnterLessonButton() {
  const lessonsToEnter = useAppSelector((state) => state.mySchedule.lessonsToEnter);
  const [isModalShown, setIsModalShown] = useState(false);

  function handleModalClose() {
    setIsModalShown(false);
  }

  function handleModalOpen() {
    setIsModalShown(true);
  }

  if (lessonsToEnter.length === 0) {
    return null;
  }

  if (lessonsToEnter.length === 1) {
    return (
      <div className="menu-item me-lg-1 px-8 py-3 p-md-0 text-nowrap">
        <LinkButton to={`/lessons/${lessonsToEnter[0].lessonID}`} variant="success" className="btn-sm">
          <i className="fas fa-play"></i> Влез в урок
        </LinkButton>
      </div>
    );
  }

  return (
    <>
      <div className="menu-item me-lg-1 px-8 py-3 p-md-0 text-nowrap">
        <Button variant="success" onClick={handleModalOpen} size="sm">
          <i className="fas fa-play"></i> Влез в урок
        </Button>
      </div>
      <Modal show={isModalShown} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Имате повече от една налични стаи. Изберете стаята, в която желаете да влезете.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lessonsToEnter.map((lesson) => (
            <Card key={lesson.lessonID} className="future-lesson-card border-primary shadow">
              <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between p-4">
                <div className="d-flex">
                  <h6 className="my-5">
                    {lesson.startTime} - {lesson.endTime}
                  </h6>
                  <div className="mt-5">
                    <h6 className="ms-5">{lesson.courseName}</h6>
                    <Link
                      className="btn profile-menu-link d-flex align-items-center mx-lg-1 px-4"
                      to={`/tutors/${lesson.tutorTransliteratedSlug}`}
                      onClick={handleModalClose}
                    >
                      <div className="symbol rounded profile-image">
                        <img
                          src={lesson.participantProfilePicturePath ?? NO_AVATAR_PATH}
                          alt="Профилна снимка"
                          className="profile-image me-3"
                        />
                      </div>
                      <span className="fw-normal">{lesson.participantName}</span>
                    </Link>
                  </div>
                </div>
                <LinkButton to={`/lessons/${lesson.lessonID}`} variant="success" onClick={handleModalClose}>
                  <i className="fas fa-play"></i> Влез в урок
                </LinkButton>
              </div>
            </Card>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Отказ
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
