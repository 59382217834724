export const FACEBOOK_PIXEL_ECOMMERCE_EVENTS = {
  viewContent: 'ViewContent',
  viewCategory: 'ViewCategory',
  addToCart: 'AddToCart',
  initiateCheckout: 'InitiateCheckout',
  addPaymentInfo: 'AddPaymentInfo',
  purchase: 'Purchase',
  lead: 'Lead',
};

export const FACEBOOK_PIXEL_CUSTOM_EVENTS = {
  registration: 'Registration',
  registrationTutor: 'Registration tutor',
  registrationStudent: 'Registration student',
  bookingCompleted: 'Booking completed',
  bookingConfirmation: 'Booking confirmation',
  bookingCancellation: 'Booking cancellation',
  lessonCompleted: 'Lesson completed',
  newCustomer: 'New customer',
  newMessage: 'New Message',
  shkoloConsultation: 'Shkolo consultation',
  consultationComplete: 'Consultation complete',
};
