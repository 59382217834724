import { GoBackToDashboardLinkButton } from '@/app/modules/errors/ErrorBasePage/GoBackToDashboardLinkButton';
import { useAppSelector } from '@/redux/store';

import { UNAUTHORIZED_ERROR_MESSAGE } from './constants';
import { ErrorBasePage } from './ErrorBasePage/ErrorBasePage';

export function UnauthorizedPage() {
  const errorMessage = useAppSelector((state) => state.errors.errorMessage);

  return (
    <ErrorBasePage code={401} actions={<GoBackToDashboardLinkButton />}>
      {errorMessage ?? UNAUTHORIZED_ERROR_MESSAGE}
    </ErrorBasePage>
  );
}
