import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { EditButton } from '@/app/components/Button/EditButton';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { CourseStatusID } from '@/app/models/CourseStatusID';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import CourseApprovedListPermissions from '../components/CourseApprovedListPermissions';
import CourseArchivedListPermissions from '../components/CourseArchivedListPermissions';
import CourseInReviewListPermissions from '../components/CourseInReviewListPermissions';
import { getProfileCourseStatusShowData } from '../service';
import { ProfileCourseShowPageBase } from './ProfileCourseShowPageBase';

export function CourseStatusShowPage() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const profileCourse = useAppSelector((state) => state.profileCourse);

  useEffect(function componentDidMount() {
    if (profileCourse.loading === LoadingState.Idle) {
      dispatch(getProfileCourseStatusShowData(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProfileCourseShowPageBase
      title={profileCourse.courseName}
      actions={<EditButton to={`/profile/courses/${id}/edit/status`} />}
    >
      <CardHeader>
        <CardTitle>Статус на обучението</CardTitle>
      </CardHeader>
      <CardBody>
        {profileCourse.courseStatusID === CourseStatusID.InReview && (
          <Row>
            <Col md={4}>
              <h2 className="fw-normal mb-5 mb-md-0">Чака одобрение</h2>
            </Col>
            <Col>
              <CourseInReviewListPermissions />
            </Col>
          </Row>
        )}
        {profileCourse.courseStatusID === CourseStatusID.Approved && (
          <Row>
            <Col md={4}>
              <h2 className="fw-normal mb-5 mb-md-0">Публичен</h2>
            </Col>
            <Col>
              <CourseApprovedListPermissions />
            </Col>
          </Row>
        )}
        {profileCourse.courseStatusID === CourseStatusID.Archived && (
          <Row>
            <Col md={4}>
              <h2 className="fw-normal mb-5 mb-md-0">Архивиран</h2>
            </Col>
            <Col>
              <CourseArchivedListPermissions />
            </Col>
          </Row>
        )}
      </CardBody>
    </ProfileCourseShowPageBase>
  );
}
