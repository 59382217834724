import { Redirect, Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { wrappedReactLazy } from '@/app/utils/wrapped-react-lazy';

import { CoursePackageShowPage } from './CoursePackageShowPage';
import { CourseStatusShowPage } from './CourseStatusShowPage';

const CourseDetailsShowPage = wrappedReactLazy(() => import('./CourseDetailsShowPage'));
const ScheduleShowPage = wrappedReactLazy(() => import('./ScheduleShowPage'));

export function ProfileCourseShowPage() {
  return (
    <Switch>
      <Redirect from="/profile/courses/:id" to="/profile/courses/:id/status" exact />

      <Route path="/profile/courses/:id/status" exact>
        <CourseStatusShowPage />
      </Route>

      <Route path="/profile/courses/:id/details" exact>
        <CourseDetailsShowPage />
      </Route>

      <Route path="/profile/courses/:id/schedule" exact>
        <ScheduleShowPage />
      </Route>

      <Route path="/profile/courses/:id/packages" exact>
        <CoursePackageShowPage />
      </Route>

      <Route component={NoMatch} />
    </Switch>
  );
}
