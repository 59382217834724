import { ReactNode, useCallback, useMemo, useState } from 'react';

import { HeaderContext } from './HeaderContext';

type HeaderProviderProps = {
  children: ReactNode;
};

export function HeaderProvider({ children }: HeaderProviderProps) {
  const [isVisible, setIsVisible] = useState(true);
  const show = useCallback(() => setIsVisible(true), []);
  const hide = useCallback(() => setIsVisible(false), []);
  const contextValue = useMemo(() => ({ isVisible, show, hide }), [isVisible, show, hide]);

  return <HeaderContext.Provider value={contextValue}>{children}</HeaderContext.Provider>;
}
