import { LinkButton } from '@/app/components/Button/LinkButton';

import './EnterLessonReminder.scss';

type EnterLessonReminderProps = {
  name: string;
  time: string;
  lessonID: number;
};

export function EnterLessonReminder({ name, time, lessonID }: EnterLessonReminderProps) {
  return (
    <div className="position-absolute w-100 mt-20">
      <div className="text-center m-auto enter-lesson-reminder-content mw-550px">
        <strong className="text-white p-1 py-5 px-3">
          Вашият урок с {name} започва в {time}
        </strong>
        <LinkButton to={`/lessons/${lessonID}`} variant="success" className="w-100 w-md-auto text-nowrap">
          <i className="fas fa-play" /> Влез в урок
        </LinkButton>
      </div>
    </div>
  );
}
