import { ReactNode, useEffect, useState } from 'react';
import { Toast, ToastBody, ToastHeader } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import clsx from 'clsx';

import { FeaturedPromoCodeToastContainer } from './FeaturedPromoCodeToastContainer';

import './FeaturedPromoCodeToast.scss';

type FeaturedPromoCodeToastProps = {
  title: string;
  children: ReactNode;
  cookieName: string;
  className?: string;
};

export function FeaturedPromoCodeToast({ title, children, cookieName, className = '' }: FeaturedPromoCodeToastProps) {
  const [cookie, setCookie] = useCookies([cookieName]);

  const [isShown, setIsShown] = useState(false);

  useEffect(
    function onCookieChange() {
      setIsShown(!cookie[cookieName]);
    },

    [cookie, cookieName]
  );

  function handleToastCloseClick() {
    setCookie(cookieName, true);

    setIsShown(false);
  }

  return (
    <FeaturedPromoCodeToastContainer>
      <Toast
        bg="white"
        className={clsx('toast--promo-code', className)}
        onClose={handleToastCloseClick}
        show={!cookie[cookieName] && isShown}
      >
        <ToastHeader className="pb-6" closeButton>
          <h4 className="me-auto mb-0">{title}</h4>
        </ToastHeader>

        <ToastBody className="fs-5">{children}</ToastBody>
      </Toast>
    </FeaturedPromoCodeToastContainer>
  );
}
