import { MouseEventHandler } from 'react';
import { Col, FormText, Row } from 'react-bootstrap';
import clsx from 'clsx';

import { Button } from '@/app/components/Button/Button';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardFooter } from '@/app/components/Card/CardFooter';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { PriceControl } from '@/app/components/Form/Price/PriceControl';
import { MINIMUM_COURSE_PACKAGE_LESSON_PRICE } from '@/app/modules/course/constants';

import { CoursePackageModel } from '../create/models';
import { PlatformTaxFormText } from './PlatformTaxFormText';

type CoursePackageItemProps = {
  coursePackage: CoursePackageModel;
  fieldNamePrefix: string;
  onArchive?: (coursePackageID: number) => MouseEventHandler;
  onRemove?: (coursePackageID: number) => MouseEventHandler;
  isEditable?: boolean;
  isInvalid?: boolean;
};

export function CoursePackageItem({
  coursePackage,
  fieldNamePrefix,
  onArchive,
  onRemove,
  isEditable = true,
  isInvalid = false,
}: CoursePackageItemProps) {
  return (
    <Col md={12}>
      <Card variant="bordered" className={clsx({ 'border-danger': isInvalid })}>
        <CardBody>
          <Row className="g-5">
            <Col xl={6}>
              <FormGroup controlId={`${fieldNamePrefix}.lessonCount`}>
                <Label>Брой уроци в пакета</Label>
                <FormControl
                  type="text"
                  name={`${fieldNamePrefix}.lessonCount`}
                  value={coursePackage.lessonCount}
                  hasSolidBackground={true}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col xl={6}>
              <FormGroup controlId={`${fieldNamePrefix}.packagePrice`}>
                <Label isRequired={isEditable}>Крайна цена на пакета</Label>

                {isEditable && <PriceControl name={`${fieldNamePrefix}.taxedPrice`} hasSolidBackground={false} />}

                {!isEditable && <p className="fs-4 mb-1">{coursePackage.taxedPrice}</p>}

                <PlatformTaxFormText taxedPrice={coursePackage.taxedPrice} />
                <br />
                <FormText>Минималната цена на урок в пакет е {MINIMUM_COURSE_PACKAGE_LESSON_PRICE} лв.</FormText>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>

        {isInvalid && (
          <CardFooter className="d-flex flex-wrap justify-content-between align-items-center p-6">
            <p className="fw-bold text-danger mb-0">Невалиден пакет</p>
            {Boolean(onArchive) && Boolean(coursePackage.isOrdered) && (
              <Button onClick={onArchive?.(Number(coursePackage.id))} variant="light-primary">
                Архивирай
              </Button>
            )}

            {Boolean(onRemove) && Boolean(!coursePackage.isOrdered) && (
              <Button onClick={onRemove?.(Number(coursePackage.id))} variant="light-danger">
                Изтрий
              </Button>
            )}
          </CardFooter>
        )}
      </Card>
    </Col>
  );
}
