import { useContext, useEffect, useRef } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';

import { MessengerThreadValues } from '@/app/modules/messenger/models';
import { createTestRoomMessengerThread, storeMessengerThreadReply } from '@/app/modules/messenger/service';
import { VirtualRoomMessenger } from '@/app/modules/messenger/VirtualRoomMessenger';
import testRoomSlice from '@/app/modules/test-room/store';
import { identityChannelName } from '@/app/utils/real-time/constants';
import { EchoContext } from '@/app/utils/real-time/EchoContext';
import { MessengerThreadCreated } from '@/app/utils/real-time/models';
import { useAppDispatch, useAppSelector } from '@/redux/store';

type TestRoomMessengerThreadProps = {
  id: number | null;
  handleChatToggle: () => void;
};

export function TestRoomMessengerThread({ id, handleChatToggle }: TestRoomMessengerThreadProps) {
  const dispatch = useAppDispatch();
  const identityID = useAppSelector((state) => state.auth.identityID);
  const echoInstance = useContext(EchoContext);
  const virtuosoRef = useRef<VirtuosoHandle | null>(null);

  async function handleSubmit(values: MessengerThreadValues) {
    if (!id) {
      const response = await createTestRoomMessengerThread(values);

      dispatch(testRoomSlice.actions.updateMessengerThread(response.threadID));
      return response;
    }

    return await dispatch(storeMessengerThreadReply({ id: Number(id), values }));
  }

  useEffect(
    function handleEchoListenForNewThreadCreate() {
      if (identityID === undefined || id !== null) {
        return;
      }

      const identityChannel = `${identityChannelName}.${identityID}`;

      echoInstance?.private?.(identityChannel).listen('.messenger_thread.created', (event: MessengerThreadCreated) => {
        if (event.threadID) {
          dispatch(testRoomSlice.actions.updateMessengerThread(event.threadID));
        }
      });

      return () => {
        echoInstance?.leave?.(identityChannel);
      };
    },
    [id, identityID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <VirtualRoomMessenger
      id={id}
      virtuosoRef={virtuosoRef}
      handleChatToggle={handleChatToggle}
      onSubmit={handleSubmit}
    />
  );
}
