import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Echo from 'laravel-echo';

import { FeaturedPromoCodeToast } from '@/app/components/FeaturedPromoCodeToast/FeaturedPromoCodeToast';
import { HeaderProvider } from '@/app/layout/HeaderProvider';
import { LinkIsPersonalizedForOtherUserModal } from '@/app/modules/auth/LinkIsPersonalizedForOtherUserModal';
import { TermAgreementModal } from '@/app/modules/auth/TermAgreementModal';
import { CourseFeedbackReminderModal } from '@/app/modules/course-feedback/CourseFeedbackReminderModal';
import { useEnterLesson } from '@/app/modules/my-schedule/EnterLesson/useEnterLesson';
import { getPlatformSettings } from '@/app/modules/platform-settings/service';
import { TutorHasScheduleNotificationModal } from '@/app/modules/tutor/modal/TutorHasScheduleNotificationModal';
import { Routes } from '@/app/Routes';
import { EchoProvider } from '@/app/utils/real-time/EchoProvider';
import { useRealTimeEvents } from '@/app/utils/real-time/useRealTimeEvents';
import { LoadingState } from '@/redux/constants';
import { setupAxios } from '@/redux/setupAxios';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { Layout } from './layout/Layout';
import { getAuthData, getExternalID, storeStudent } from './modules/auth/service';
import { SessionTimeoutModal } from './modules/auth/SessionTimeoutModal';
import { Errors } from './modules/errors/Errors';
import { useMessenger } from './modules/messenger/useMessenger';
import { useNotifications } from './modules/notification/useNotifications';
import { getActivePromotion } from './modules/promotion/service';
import { useSupportTickets } from './modules/support-ticket/useSupportTickets';
import { useLoading } from './utils/useLoading';

export function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const promotion = useAppSelector((state) => state.promotion);
  const [loading] = useLoading(auth.loading, auth.isPreloaded);
  const isSplashScreenShown = loading === LoadingState.Pending && !import.meta.env.SSR;
  const echoInstance = useRealTimeEvents();

  useSupportTickets();
  useNotifications();
  useMessenger();
  useEnterLesson();

  useEffect(function componentDidMount() {
    /**
     * Inject metronic interceptors for axios.
     *
     * @see https://github.com/axios/axios#interceptors
     */
    setupAxios(axios, dispatch);

    if (!auth.isPreloaded) {
      dispatch(getAuthData());
    }

    dispatch(getExternalID());
    dispatch(getPlatformSettings());

    const tagManagerArgs = {
      gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.dataLayer && auth.isAuthenticated) {
      window.dataLayer.push({
        user_id: String(auth.identityID),
        crm_id: String(auth.identityID),
      });
    }

    if (auth.studentID === null && auth.isAuthenticated) {
      dispatch(storeStudent());
    }

    if (!auth.isPreloaded) {
      dispatch(getActivePromotion());
    }
  }, [auth.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.dataLayer && auth.externalID) {
      window.dataLayer.push({
        external_id: String(auth.externalID),
      });
    }
  }, [auth.externalID]);

  useEffect(
    function handleLoading() {
      const splashScreen = document.getElementById('splash-screen');

      if (isSplashScreenShown) {
        splashScreen?.classList?.remove?.('hidden');
      } else {
        splashScreen?.classList?.add?.('hidden');
      }
    },
    [isSplashScreenShown]
  );

  // useEffect(
  //   function handleExternalIDChange() {
  //     if (auth.getExternalIDLoading === LoadingState.Idle && auth.externalID) {
  //       dispatch(getFeedbackSurveyData());
  //     }
  //   },
  //   [auth.externalID] // eslint-disable-line react-hooks/exhaustive-deps
  // );

  if (isSplashScreenShown) {
    return <SessionTimeoutModal />;
  }

  return (
    <>
      <Helmet titleTemplate="%s | Школо Уроци" defaultTitle="Школо Уроци"></Helmet>
      <HeaderProvider>
        <Layout>
          <Errors>
            <EchoProvider echoInstance={echoInstance as Echo}>
              <Routes />
            </EchoProvider>
          </Errors>
        </Layout>
      </HeaderProvider>
      <SessionTimeoutModal />
      <TermAgreementModal />
      <CourseFeedbackReminderModal />
      <TutorHasScheduleNotificationModal />
      <LinkIsPersonalizedForOtherUserModal />
      {promotion.canUseBirthdayPromoCode && (
        <FeaturedPromoCodeToast title="Школо Уроци става на 1 година!" cookieName="birthday-promocode-closed">
          Вземи подарък 10% отстъпка от индивидуален урок или пакет уроци с промокод <strong>ЧРД</strong>
        </FeaturedPromoCodeToast>
      )}

      {promotion.canUseAlphabetPromoCode && (
        <FeaturedPromoCodeToast
          title="Отпразнувай 24. май с Школо Уроци!"
          cookieName="alphabet-promo-code-closed"
          className="w-375px"
        >
          Вземи 5% еднократна отстъпка за покупка през месец май с промокод <strong>АЗБУКА</strong>
        </FeaturedPromoCodeToast>
      )}
      {/* {auth.canSeeFeedbackSurvey && <FeedbackSurveyModal />} */}
    </>
  );
}
