import { Node } from 'slate';

import { FileAttachment } from '@/app/components/AttachmentPreview/models';
import { EditableTimeSlotEntity, TimeSlotEntity } from '@/app/components/Calendar/models';
import { FileControlValue } from '@/app/components/Form/models';
import { ItemsPerPage } from '@/app/components/Pagination/models';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';
import { LoadingState } from '@/redux/constants';

import { CourseFeedbacksPaginatedData, Subject } from '../course/models';
import { TutorProfileEditValues } from './profile/models';

export interface CourseDetails {
  id: number;
  name: string;
  startingPrice: string;
  currentRating: string;
  lessonDuration: number;
  keywords: SubjectKeyword[];
  transliteratedSlug: string;
  targetAudienceName: string;
  subjects: CourseItemSubject[];
}

export interface CourseItemSubject {
  subjectName: string;
}

export type TutorAttachment = FileAttachment;

export interface TutorShowData {
  name: string;
  about: Node[];
  identityID: number | null;
  currentRating: string;
  ratingCount: number | null;
  profileFile: string;
  courses: CourseDetails[];
  educationDegreeID: string | null;
  university: string;
  hasPedagogicalDegree: boolean;
  pedagogicalDegree: string;
  teachingExperienceID: string | null;
  hasStudentPrepareExperience: boolean;
  technicalExperience: number | null;
  approach: string;
  motivation: string;
  importance: string;
  pupilAchievement: string;
  profileDetailsAttachments: TutorAttachment[];
  aboutDetailsAttachments: TutorAttachment[];
  subjects: CourseItemSubject[];
  videoFileUrl: string | null;
  reactionTime: string | null;
  numberStudents: number | null;
  numberReservedLessons: number | null;
  uniqueVisitsCount: number | null;
  firstAvailableTutorTimeSlotTime: string | null;
  tutorID?: number | null;
  hasAvailableFutureTimeSlots: boolean;
  courseFeedbacks: CourseFeedbacksPaginatedData;
}

export interface TutorSubject {
  subjectName: string;
}

export interface TutorIndexData {
  id: number;
  name: string;
  profileFile: string;
  currentRating: string;
  ratingCount: number;
  startingPrice: string;
  lessonDuration: number;
  subjects: TutorSubject[];
  transliteratedSlug?: string;
}

export enum TutorIndexFilterSortBy {
  Rating = 'rating',
  Popularity = 'popularity',
}

export interface TutorIndexFilterValues {
  search: string;
  sortBy: TutorIndexFilterSortBy;
  itemsPerPage: ItemsPerPage;
  page?: number;
  subject?: string;
}

export type TutorIndexFilterQueryParams = Partial<TutorIndexFilterValues>;

export enum TutorEducationalDegreeID {
  HighSchool,
  Student,
  University,
  Pupil,
}

export enum TutorEducationalDegreeText {
  HighSchool = 'Средно',
  Student = 'Студент съм',
  University = 'Висше',
  Pupil = 'Ученик съм',
}

export enum TutorTeachingExperienceID {
  None,
  UpToFiveYears,
  MoreThanFiveYears,
}

export enum TutorTeachingExperienceText {
  None = 'Нямам',
  UpToFiveYears = 'До 5 г.',
  MoreThanFiveYears = 'Над 5 г.',
}

export interface TutorState {
  loading: LoadingState;
  timeSlotsLoading: LoadingState;
  firstCourseTransliteratedSlugLoading: LoadingState;
  error?: string;
  address: string;
  birthday: string;
  bankAccountOwnerName: string;
  bankAccountIBAN: string;
  postCode: string;
  picture: string | null;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  username: string;
  about: Node[];
  educationDegreeID: string | null;
  university: string;
  hasPedagogicalDegree: boolean | null;
  pedagogicalDegree: string;
  teachingExperienceID: string | null;
  hasStudentPrepareExperience: boolean | null;
  technicalExperience: string | null;
  approach: string;
  motivation: string;
  importance: string;
  pupilAchievement: string;
  profileDetailsAttachments: FileControlValue[];
  aboutDetailsAttachments: FileControlValue[];
  show: TutorShowData;
  edit: TutorProfileEditValues;
  index: {
    tutorsTotal: number;
    tutors: TutorIndexData[];
    subjects: Subject[];
  };
  timeSlots: TimeSlotEntity[] | EditableTimeSlotEntity[];
  firstCourseTransliteratedSlug: string | null;
  city?: {
    id: number;
    text: string;
  };
}
