import { useHiddenConsultUs } from '@/_metronic/layout/components/consult-us/useHiddenConsultUs';
import { Card } from '@/app/components/Card/Card';
import { Label } from '@/app/components/Form/Label/Label';
import { RadioButtonControl } from '@/app/components/Form/RadioButtonControl/RadioButtonControl';
import { Content } from '@/app/components/Page/Content/Content';
import { useHiddenFooter } from '@/app/layout/Footer/useHiddenFooter';

import { COURSE_INDEX_FILTER_SORT_BY_DATA } from '../../../constants';
import { CourseIndexPageFilterMobile } from '../../../models';
import { HideFiltersButtonMobile } from './components/HideFiltersButtonMobile';

type SortByFilterPageMobileProps = {
  handleFilterToggle: () => void;
  setCurrentFilter: React.Dispatch<React.SetStateAction<CourseIndexPageFilterMobile | null>>;
};

export function SortByFilterPageMobile({ handleFilterToggle, setCurrentFilter }: SortByFilterPageMobileProps) {
  function handleClickClose() {
    handleFilterToggle();
    setCurrentFilter(null);
  }

  function handleClickBack() {
    setCurrentFilter(null);
  }

  useHiddenConsultUs();
  useHiddenFooter();

  return (
    <div className="pb-1">
      <HideFiltersButtonMobile onClickHide={handleClickClose} />

      <div className="d-flex justify-content-center mt-2">
        <button className="btn ps-0 pe-0" onClick={handleClickBack}>
          <i className="far fa-arrow-left text-primary fs-4" />
        </button>
        <span className="w-100 fs-2 d-flex justify-content-center align-items-center">Подреди по</span>
      </div>

      <div className="flex-wrap justify-content-between">
        <Content className="p-3 ps-0 pe-0">
          {COURSE_INDEX_FILTER_SORT_BY_DATA?.map((item) => (
            <Card key={item.id} className="rounded w-100 mb-3">
              <Label className="d-flex align-items-center p-4 mb-0 cursor-pointer">
                <RadioButtonControl name="sortBy" value={item.id} label={item.text} className="fw-normal" />
              </Label>
            </Card>
          ))}
        </Content>
      </div>
    </div>
  );
}
