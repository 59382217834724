import React, { MouseEvent } from 'react';
import { useSlate } from 'slate-react';

import { RichTextButton } from './RichTextButton/RichTextButton';
import { isBlockActive, toggleBlock } from './utils';

type BlockButtonProps = {
  format: string;
  tooltip: string;
  children: React.ReactNode;
  isDisabled?: boolean;
};

export function BlockButton({ format, tooltip, children, isDisabled = false }: BlockButtonProps) {
  const editor = useSlate();

  function handleMouseDown(event: MouseEvent) {
    event.preventDefault();
    toggleBlock(editor, format);
  }

  return (
    <RichTextButton
      tooltip={tooltip}
      isDisabled={isDisabled}
      isActive={isBlockActive(editor, format)}
      onMouseDown={handleMouseDown}
    >
      {children}
    </RichTextButton>
  );
}
