import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { KTSVG } from '@/_metronic/helpers';
import { ReservationStatusID } from '@/app/components/Calendar/models';
import { getIntervalFormat } from '@/app/components/Calendar/utils';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { OrderDetailsData } from '@/app/modules/order/models';
import { OrderDetails } from '@/app/modules/order/OrderDetails';
import { useQueryParams } from '@/app/utils/query';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { ReservationGroupSummaryQueryParams, ReservationWithTime } from './models';
import { getReservationGroupSummary } from './services';
import { transformReservationToReservationWithTime } from './utils';

import './CourseReservationDetailsPage.scss';

export function ReservationGroupSummaryPage() {
  const dispatch = useAppDispatch();
  const { queryParams } = useQueryParams<ReservationGroupSummaryQueryParams>();
  const { id } = useParams<{ id: string }>();

  //#region Handle data
  const loading = useAppSelector((state) => state.reservationGroup.loading);
  const data = useAppSelector((state) => state.reservationGroup.details);
  const order = useAppSelector((state) => state.reservationGroup.order);

  const isFailed = useMemo<boolean>(() => {
    return data.reservations.some((reservation) => reservation.reservationStatusID === ReservationStatusID.Failed);
  }, [data.reservations]);

  const reservations = useMemo<ReservationWithTime[]>(() => {
    return data.reservations.map(transformReservationToReservationWithTime);
  }, [data.reservations]);

  const shouldShowOrderDetails = Boolean(queryParams?.orderID);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getReservationGroupSummary({ reservationGroupID: Number(id), orderID: queryParams?.orderID }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  return (
    <>
      <Helmet>
        <title>Детайли за резервация</title>
      </Helmet>
      <Page>
        <Content>
          <div className="mx-auto text-center mw-800px">
            <div className="my-10">
              {!isFailed && <KTSVG path="/img/svg/icons/CheckCircle.svg" className="svg-icon-6tx" />}
              {isFailed && (
                <KTSVG path="/img/svg/icons/ExclamationCircle.svg" className="svg-icon-6tx svg-icon-danger" />
              )}
            </div>

            {shouldShowOrderDetails && (
              <OrderDetails data={order as OrderDetailsData} isSuccessful={Boolean(order?.isSuccessful)} />
            )}

            <h1 className="text-gray-800 fs-2tx">
              {!isFailed && 'Вашата заявка е изпратена успешно'}
              {isFailed && 'Вашата заявка е неуспешна'}
            </h1>

            <p className="text-gray-700 fs-4 mb-10 px-9">
              {!isFailed &&
                'Обучителят трябва да потвърди резервацията за часа. След като я потвърди или откаже, Вие ще получите нотификация и ще видите часа с обновения статус в "Моят график".'}
              {isFailed &&
                'Заявката Ви e неуспешна, защото някои от часовете, които сте избрали, са вече невалидни. Изберете нови часове за резервация.'}
            </p>

            <Card className="text-start mb-10">
              <CardBody>
                {!shouldShowOrderDetails && (
                  <>
                    <Row>
                      <Col className="mw-md-150px pb-6 pb-md-0">
                        <h2>Обучение</h2>
                      </Col>
                      <Col xs={12} md={{ span: true }}>
                        <h3 className="fw-bold">{data.course.name}</h3>
                        <div className="d-flex flex-wrap align-items-center">
                          <img
                            src={data.course.tutorProfileImagePath ?? NO_AVATAR_PATH}
                            alt="Профилна снимка"
                            className="w-20px h-20px rounded me-2"
                          />
                          <h4 className="mb-0 fs-6 fw-normal">{data.course.tutorName}</h4>
                        </div>
                      </Col>
                    </Row>

                    <hr className="bg-gray-200 opacity-100 my-8" />
                  </>
                )}

                <Row>
                  <Col className="mw-md-150px pb-6 pb-md-0">
                    <h2>За обучаемия</h2>
                  </Col>
                  <Col xs={12} md={{ span: true }}>
                    <div>
                      <span>Имена на обучаемия: </span>
                      <span className="fw-bold">{data.connectedStudent.name}</span>
                    </div>
                    <div>
                      <span>Клас/възраст на обучаемия: </span>
                      <span className="fw-bold">{data.connectedStudent.connectedStudentLevelText}</span>
                    </div>
                    <div>
                      <span>Телефон: </span>
                      <span className="fw-bold">{data.connectedStudent.phone}</span>
                    </div>
                    <div>
                      <span>Имейл адрес: </span>
                      <span className="fw-bold">{data.connectedStudent.email}</span>
                    </div>
                    {data.connectedStudent.expectations && (
                      <div>
                        <span>Очаквания и коментари: </span>
                        <span className="fw-bold">{data.connectedStudent.expectations}</span>
                      </div>
                    )}
                  </Col>
                </Row>

                <hr className="bg-gray-200 opacity-100 my-8" />

                <Row>
                  <Col className="mw-md-150px pb-6 pb-md-0">
                    <h2>Избрани часове</h2>
                  </Col>

                  <Col xs={12} md={{ span: true }} className="my-n5">
                    {reservations.map((reservation) => {
                      const isFailed = reservation.reservationStatusID === ReservationStatusID.Failed;

                      return (
                        <div key={reservation.id} className="reservation-group-item border-bottom text-danger">
                          <h3 className={clsx('fw-normal', { 'text-danger': isFailed })}>
                            {isFailed && (
                              <i className={clsx('far fa-exclamation-circle fs-3 me-2', { 'text-danger': isFailed })} />
                            )}
                            {reservation.date}{' '}
                            <span className={clsx('opacity-50', { 'text-danger': isFailed })}>
                              ({reservation.dayOfWeek})
                            </span>
                          </h3>
                          <h4 className={clsx('mb-0 fw-normal', { 'text-danger': isFailed })}>
                            {getIntervalFormat(reservation.startTime, reservation.endTime)}
                          </h4>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {!isFailed && (
              <Link to="/my-schedule" className="btn btn-light-primary">
                Kъм Моят график
              </Link>
            )}

            {isFailed && (
              <Link to={`/courses/${data.course.id}/reservation`} className="btn btn-light-primary">
                Резервирай отново
              </Link>
            )}
          </div>

          {loading === LoadingState.Pending && <GlobalSpinner />}
        </Content>
      </Page>
    </>
  );
}
