import { useAppSelector } from '@/redux/store';

import { EDITABLE_TUTOR_STATUS_IDS } from './constants';
import { StudentProfileDetails } from './show/StudentProfileDetails';
import { TutorProfileDetailsShowPage } from './show/TutorProfileDetailsShowPage';

export function ProfileDetailsShowPage() {
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);

  if (!tutorStatusID) {
    return null;
  }

  if (!EDITABLE_TUTOR_STATUS_IDS.includes(tutorStatusID)) {
    return <StudentProfileDetails />;
  }

  return <TutorProfileDetailsShowPage />;
}
