import { Link } from 'react-router-dom';

import { MenuComponent } from '@/_metronic/assets/ts/components';
import { HeaderMenuButtonBadge } from '@/app/layout/HeaderMenu/HeaderMenuButtonBadge';
import { HeaderMenuContent } from '@/app/layout/HeaderMenu/HeaderMenuContent';
import { MessengerNotificationsList } from '@/app/modules/messenger/MessengerNotificationsList';
import { useAppSelector } from '@/redux/store';

import { HeaderMenu } from './HeaderMenu/HeaderMenu';
import { HeaderMenuButton } from './HeaderMenu/HeaderMenuButton';
import { HeaderMenuButtonIcon } from './HeaderMenu/HeaderMenuButtonIcon';
import { HeaderMenuDropdown } from './HeaderMenu/HeaderMenuDropdown';
import { HeaderMenuTitle } from './HeaderMenu/HeaderMenuTitle';

type HeaderMessengerMenuProps = {
  className?: string;
};

export function HeaderMessengerMenu({ className }: HeaderMessengerMenuProps) {
  const unreadThreadCount = useAppSelector((state) => state.messenger?.notifications?.unreadThreadCount);

  function handleMessengerThreadCreateClick() {
    MenuComponent.hideDropdowns();
  }

  return (
    <HeaderMenu className={className}>
      <HeaderMenuButton activePath="/messenger" isActiveExact={false}>
        <HeaderMenuButtonIcon path="/img/svg/icons/Messenger.svg" />
        {unreadThreadCount > 0 && <HeaderMenuButtonBadge>{unreadThreadCount}</HeaderMenuButtonBadge>}
      </HeaderMenuButton>

      <HeaderMenuDropdown>
        <div className="d-flex justify-content-between align-items-center">
          <HeaderMenuTitle>Съобщения</HeaderMenuTitle>
          <Link
            to="/messenger/create"
            className="btn btn-icon btn-light-primary m-2"
            onClick={handleMessengerThreadCreateClick}
          >
            <i className="fas fa-plus fa-2x" />
          </Link>
        </div>
        <HeaderMenuContent>
          <MessengerNotificationsList />
        </HeaderMenuContent>
      </HeaderMenuDropdown>
    </HeaderMenu>
  );
}
