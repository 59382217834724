import { MenuComponent } from '@/_metronic/assets/ts/components';
import { LinkButton } from '@/app/components/Button/LinkButton';
import { useAppSelector } from '@/redux/store';

import { SupportTicketNotificationsList } from '../modules/support-ticket/SupportTicketNotificationsList';
import { HeaderMenu } from './HeaderMenu/HeaderMenu';
import { HeaderMenuButton } from './HeaderMenu/HeaderMenuButton';
import { HeaderMenuButtonBadge } from './HeaderMenu/HeaderMenuButtonBadge';
import { HeaderMenuButtonIcon } from './HeaderMenu/HeaderMenuButtonIcon';
import { HeaderMenuContent } from './HeaderMenu/HeaderMenuContent';
import { HeaderMenuDropdown } from './HeaderMenu/HeaderMenuDropdown';

type HeaderSupportTicketMenuProps = {
  className?: string;
};

export function HeaderSupportTicketMenu({ className }: HeaderSupportTicketMenuProps) {
  const unreadSupportTicketsCount = useAppSelector(
    (state) => state.supportTicket.notifications.unreadSupportTicketsCount
  );

  function handleDropdownClose() {
    MenuComponent.hideDropdowns();
  }

  return (
    <HeaderMenu className={className}>
      <HeaderMenuButton>
        <HeaderMenuButtonIcon path="/img/svg/icons/General/Message-question.svg" />
        {unreadSupportTicketsCount > 0 && <HeaderMenuButtonBadge>{unreadSupportTicketsCount}</HeaderMenuButtonBadge>}
      </HeaderMenuButton>

      <HeaderMenuDropdown>
        <div className="d-flex justify-content-center p-5">
          <LinkButton to="/support-tickets/create" className="btn-sm w-100" onClick={handleDropdownClose}>
            <i className="fas fa-plus fa-2x me-2" />
            <span className="fw-bolder">Изпрати запитване до ШКОЛО</span>
          </LinkButton>
        </div>
        <div className="d-flex justify-content-between align-items-center px-7 pb-5">
          <span className="fw-bolder">{unreadSupportTicketsCount} непрочетени</span>
          <LinkButton to="/support-tickets/" variant="link" className="p-0" onClick={handleDropdownClose}>
            Виж всички
          </LinkButton>
        </div>
        <HeaderMenuContent>
          <SupportTicketNotificationsList />
        </HeaderMenuContent>
      </HeaderMenuDropdown>
    </HeaderMenu>
  );
}
