import { useMemo } from 'react';
import { FormText } from 'react-bootstrap';

import { calculatePriceWithoutSurcharge, TAX_PERCENT, TAX_PERCENT_FORMATTED } from '@/app/utils/money';

type PlatformTaxFormTextProps = {
  taxedPrice: number | string;
  className?: string;
};

export function PlatformTaxFormText({ taxedPrice, className }: PlatformTaxFormTextProps) {
  const price = useMemo(() => calculatePriceWithoutSurcharge(taxedPrice, TAX_PERCENT), [taxedPrice]);

  return (
    <FormText className={className}>
      <strong>Сума, която ще получите: {price}</strong>
      <br />
      Комисионна на платформата: {TAX_PERCENT_FORMATTED}
    </FormText>
  );
}
