import { useCallback, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import type { DataFormat, OptionData } from 'select2';

import { Feedback } from '@/app/components/Feedback/Feedback';

import { BaseSelectControl } from './BaseSelectControl';
import { ExtendedSelect2Options, SharedSelectControlProps } from './models';

import './SelectControl.scss';

type SelectControlProps = SharedSelectControlProps & {
  options?: DataFormat[];
  onChange?: (value: string) => void | undefined;
};

export function SelectControl({ options, onChange, ...props }: SelectControlProps) {
  const isInternalChange = useRef(false);
  const formik = useFormikContext();
  const field = formik.getFieldMeta<string>(props.name);
  const fieldHelpers = formik.getFieldHelpers<string>(props.name);
  const internalOptions = useMemo<ExtendedSelect2Options>(() => ({ data: options }), [options]);

  const handleFormikChange = useCallback(
    ($selectEl: JQuery<HTMLSelectElement>, fieldValue: string, shouldReevaluate = false) => {
      if (isInternalChange.current && !shouldReevaluate) {
        return;
      }

      if (fieldValue === undefined || fieldValue === null) {
        $selectEl.trigger({
          type: 'select2:select',
          params: {
            data: {
              id: '',
              text: '',
            },
          },
        });

        return;
      }

      const selectedOption = options?.find((option) => option.id === String(fieldValue));

      $selectEl.trigger({
        type: 'select2:select',
        params: {
          data: {
            id: String(fieldValue),
            text: selectedOption?.text ?? '',
          },
        },
      });
    },
    [options]
  );

  function internalHandleChange(optionData: OptionData) {
    fieldHelpers?.setTouched?.(true);
    fieldHelpers?.setValue?.(optionData.id);
    onChange?.(optionData.id);

    isInternalChange.current = true;
  }

  return (
    <>
      <BaseSelectControl
        options={internalOptions}
        onChange={internalHandleChange}
        onFormikChange={handleFormikChange}
        {...props}
      />

      {Boolean(field.error) && <Feedback>{field.error}</Feedback>}
    </>
  );
}
