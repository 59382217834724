import { ReactNode } from 'react';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import * as H from 'history';

type LinkButtonProps<S> = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  children?: ReactNode;
  variant?: ButtonVariant;
  className?: string;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export function LinkButton<S>({
  to,
  children,
  variant = 'primary',
  className = '',
  isDisabled = false,
  onClick,
}: LinkButtonProps<S>) {
  return (
    <Link className={clsx(`btn btn-${variant}`, className, { disabled: isDisabled })} to={to} onClick={onClick}>
      {children}
    </Link>
  );
}
