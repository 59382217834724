import { createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { OrderDetailsData } from './models';
import { getOrderDetails } from './service';

interface OrderState {
  loading: LoadingState;
  error?: string | null;
  details: OrderDetailsData;
}

const initialState: OrderState = {
  loading: LoadingState.Idle,
  error: null,
  details: {
    course: {
      id: null,
      name: '',
      tutorName: '',
      tutorIdentityID: null,
      tutorProfileImagePath: null,
      courseTransliteratedSlug: '',
    },
    coursePackage: null,
    isSuccessful: true,
    bonusLessonCount: 0,
  },
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region Get order details
    builder
      .addCase(getOrderDetails.pending, sharedPendingReducer)
      .addCase(getOrderDetails.rejected, sharedRejectedReducer)
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.details = action.payload;
      });
    //#endregion Get order details
  },
});

export default orderSlice;
