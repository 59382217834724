import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from '@/app/modules/auth/PrivateRoute';
import { NoMatch } from '@/app/modules/errors/NoMatch';

import { LessonEndPage } from './LessonEndPage';
import { LessonShowPage } from './LessonShowPage';

export function LessonPage() {
  return (
    <Switch>
      <PrivateRoute path="/lessons/:id" exact>
        <LessonShowPage />
      </PrivateRoute>
      <PrivateRoute path="/lessons/:id/end" exact>
        <LessonEndPage />
      </PrivateRoute>
      <Route component={NoMatch} />
    </Switch>
  );
}
