import { useMemo } from 'react';
import * as yup from 'yup';

const COURSE_RATING_VALIDATION_MESSAGE = 'Оценете курса преди да оставите коментар.';

export function useCourseFeedbackValidationSchema() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        courseRating: yup
          .number()
          .required()
          .min(1, COURSE_RATING_VALIDATION_MESSAGE)
          .max(5, COURSE_RATING_VALIDATION_MESSAGE),
        courseComment: yup.string().optional().max(512),
      }),
    []
  );

  return { validationSchema };
}
