import { ReactNode } from 'react';

type SideNavItemTitleProps = {
  children: ReactNode;
};

export function SideNavItemTitle({ children }: SideNavItemTitleProps) {
  return (
    <li className="nav-item side-nav-item-title">
      <h4 className="fw-bold mb-0">{children}</h4>
    </li>
  );
}
