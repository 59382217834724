import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { EnrolledCourse, EnrolledCourseFilterQueryParams } from '@/app/modules/enrolled-course/models';

const ENDPOINT_PREFIX = '/ajax/enrolled-courses';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
};

export interface GetEnrolledCoursesResponse {
  enrolledCourses: EnrolledCourse[];
}

export const getEnrolledCourses = createAsyncThunk(
  'enrolledCourse/getEnrolledCourses',
  async function (filters: EnrolledCourseFilterQueryParams) {
    const response = await axios.get<GetEnrolledCoursesResponse>(ENDPOINTS.resource, {
      params: { filters },
    });
    return response.data;
  }
);
