import { ReactNode } from 'react';

type AttachmentPreviewContentProps = {
  url: string;
  title?: string;
  children: ReactNode;
};

export function AttachmentPreviewContent({ url, title, children }: AttachmentPreviewContentProps) {
  return (
    <a className="d-block mw-75px m-2" href={url} target="_blank" rel="noreferrer" title={title}>
      {children}
    </a>
  );
}
