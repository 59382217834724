import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  CoursePayload,
  GetFilteredTutorCoursesResponse,
  GetTutorCourseSubjectTargetAudiencesResponse,
  GetTutorSubjectsResponse,
  TargetAudiencePayload,
} from './models';

const ENDPOINT_PREFIX = '/ajax/tutors';

export const ENDPOINTS = {
  subjects: (tutorID: number) => `${ENDPOINT_PREFIX}/${tutorID}/subjects`,
  targetAudiences: (tutorID: number, subjectID: number) =>
    `${ENDPOINT_PREFIX}/${tutorID}/target-audiences?subjectID=${subjectID}`,
  courses: (tutorID: number, subjectID: number, targetAudienceID: number) =>
    `${ENDPOINT_PREFIX}/${tutorID}/courses?subjectID=${subjectID}&targetAudienceID=${targetAudienceID}`,
};

//#region Get subjects
export const getTutorCourseSubjects = createAsyncThunk(
  'buyLesson/getTutorCourseSubjects',
  async function (tutorID: number) {
    const response = await axios.get<GetTutorSubjectsResponse>(ENDPOINTS.subjects(tutorID));
    return response.data;
  }
);
//#endregion Get subjects

//#region Get target audiences
export const getTutorCourseSubjectTargetAudiences = createAsyncThunk(
  'buyLesson/getTutorCourseSubjectTargetAudiences',
  async function ({ tutorID, subjectID }: TargetAudiencePayload) {
    const response = await axios.get<GetTutorCourseSubjectTargetAudiencesResponse>(
      ENDPOINTS.targetAudiences(tutorID, subjectID)
    );
    return response.data;
  }
);
//#endregion Get target audiences

//#region Get filtered tutor courses
export const getFilteredTutorCourses = createAsyncThunk(
  'buyLesson/getFilteredTutorCourses',
  async function ({ tutorID, subjectID, targetAudienceID }: CoursePayload) {
    const response = await axios.get<GetFilteredTutorCoursesResponse>(
      ENDPOINTS.courses(tutorID, subjectID, targetAudienceID)
    );
    return response.data;
  }
);
//#endregion Get filtered tutor courses
