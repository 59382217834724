import {
  END_OF_DAY_TIME_IN_HOURS,
  START_OF_DAY_TIME_IN_HOURS,
} from '@/app/components/Form/DatePickerControl/constants';
import { ItemsPerPage } from '@/app/components/Pagination/models';

import { CheckboxFilterValue, CourseIndexFilterSortBy, CourseIndexFilterValues } from './models';
import { generateTimeRanges } from './utils';

export const PL_DEPRECATION_MESSAGE =
  'Платформата Shkolo Уроци преустановява своята дейност считано от 04.11.2024 г. \n' +
  'Вече не е достъпна опцията за закупуване на нови уроци/пакети от уроци.\n' +
  'Благодарим Ви за разбирането.';

export const COURSE_INDEX_FILTER_SORT_BY_DATA = [
  {
    id: CourseIndexFilterSortBy.Rating,
    text: 'Рейтинг',
  },
  {
    id: CourseIndexFilterSortBy.Popularity,
    text: 'Брой покупки',
  },
  {
    id: CourseIndexFilterSortBy.PriceAsc,
    text: 'Цена възх.',
  },
  {
    id: CourseIndexFilterSortBy.PriceDesc,
    text: 'Цена низх.',
  },
];

export const COURSE_INDEX_FILTER_TIME_RANGE = generateTimeRanges(START_OF_DAY_TIME_IN_HOURS, END_OF_DAY_TIME_IN_HOURS);

export const DEFAULT_SORT_BY_FILTER_VALUE = CourseIndexFilterSortBy.Rating;
export const DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE: ItemsPerPage = 20;
export const DEFAULT_PAGE_VALUE = 1;

export const INDIVIDUAL_TYPE_ID = 1;
export const PACKAGE_TYPE_ID = 2;
export const SPECIAL_TYPE_ID = 3;

export const MAX_RATING = '5.0';

export const MINIMUM_INDIVIDUAL_LESSON_PRICE = 25.0;
export const MINIMUM_COURSE_PACKAGE_LESSON_PRICE = 20.0;
export const MINIMUM_SPECIAL_LESSON_PRICE = 25.0;

export const CourseIndexFilterInitialValues: CourseIndexFilterValues = {
  page: DEFAULT_PAGE_VALUE,
  sortBy: DEFAULT_SORT_BY_FILTER_VALUE,
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
  priceRanges: [],
  numberOfLessons: [],
  timeRanges: [],
  daysOfWeek: [],
  keywordIds: [],
  hasFutureTimeSlots: CheckboxFilterValue.Unchecked,
  targetAudienceSlug: '',
  subjectSlug: '',
};

export enum CoursePackageLessonCount {
  One = 1,
  Four = 4,
  Eight = 8,
  Sixteen = 16,
  ThirtyTwo = 32,
}

export const COURSE_PACKAGES_LESSON_AMOUNTS = [
  CoursePackageLessonCount.Four,
  CoursePackageLessonCount.Eight,
  CoursePackageLessonCount.Sixteen,
];

const MAX_REPEATS_PER_WEEK = 10;
const REPEATS_PER_WEEK = Array.from({ length: MAX_REPEATS_PER_WEEK }, (_, i) => i + 1);
export const REPEATS_PER_WEEK_OPTIONS = REPEATS_PER_WEEK.map((repeat) => ({
  id: repeat,
  text: `${repeat} ${repeat === 1 ? 'път' : 'пъти'}`,
}));

export const SINGLE_PACKAGE_AVAILABLE_LESSON_COUNT = [
  CoursePackageLessonCount.Four,
  CoursePackageLessonCount.Eight,
  CoursePackageLessonCount.Sixteen,
  CoursePackageLessonCount.ThirtyTwo,
];

export const SHOW_FREE_LESSONS_AT_LEAST_ONE_HOUR = 'Покажи свободни обучения (поне 1 св. час)';
export const COURSE_INDEX_PAGE_TITLE = 'Всички предмети';
export const COURSE_INDEX_PAGE_DESCRIPTION = 'Изберете от 20+ предмета в Школо Уроци';

export enum ExceptionalFormikKeys {
  subjectName = 'subjectName',
  fromDate = 'fromDate',
  toDate = 'toDate',
  hasFutureTimeSlots = 'hasFutureTimeSlots',
}

export const STUDENT_PROPOSAL_START_DATE_QUERY_PARAMETER = 'startDate';
export const STUDENT_PROPOSAL_DAY_TIME_INTERVAL_QUERY_PARAMETER = 'dayTimeInterval';
