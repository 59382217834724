import { useCallback, useEffect, useMemo } from 'react';
import { Accordion, Col, FormText, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import produce from 'immer';
import Toastr from 'toastr';

import { CancelButton } from '@/app/components/Button/CancelButton';
import { SaveButton } from '@/app/components/Button/SaveButton';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { Feedback } from '@/app/components/Feedback/Feedback';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { PriceControl } from '@/app/components/Form/Price/PriceControl';
import { RadioButtonControl } from '@/app/components/Form/RadioButtonControl/RadioButtonControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation } from '@/app/components/Form/utils';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { CoursePackageStatusID } from '@/app/models/CoursePackageStatusID';
import { CoursePackageTypeID } from '@/app/models/CoursePackageTypeID';
import { CoursePackageItem } from '@/app/modules/course/components/CoursePackageItem';
import { PlatformTaxFormText } from '@/app/modules/course/components/PlatformTaxFormText';
import { SingleCoursePackageItem } from '@/app/modules/course/components/SingleCoursePackageItem';
import { INITIAL_COURSE_PACKAGES } from '@/app/modules/course/components/steps/constants';
import {
  COURSE_PACKAGES_LESSON_AMOUNTS,
  CoursePackageLessonCount,
  MINIMUM_INDIVIDUAL_LESSON_PRICE,
} from '@/app/modules/course/constants';
import { CoursePackageModel, CoursePricingStrategyID, createCoursePackage } from '@/app/modules/course/create/models';
import { useCoursePackageValidationSchema } from '@/app/modules/course/forms/useCoursePackageValidationSchema';
import { useEqualizePackagePrices } from '@/app/modules/profile/course/useEqualizePackagePrices';
import { getInitialCoursePackages } from '@/app/utils/coursePackage';
import { isString } from '@/app/utils/isString';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { ProfileCoursePackageValues } from '../models';
import { getProfileCoursePackagesEditData, updateProfileCoursePackages } from '../service';
import { ArchiveCoursePackageModal, useArchiveCoursePackageModal } from './modals/ArchiveCoursePackageModal';
import { IndividualLessonEditModal, useIndividualLessonEditModal } from './modals/IndividualLessonEditModal';
import { ProfileCourseEditPageBase } from './ProfileCourseEditPageBase';

export function CoursePackageEditPage() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  //#region Handle data
  const dispatch = useAppDispatch();
  const profileCourse = useAppSelector((state) => state.profileCourse);

  useEffect(function componentDidMount() {
    if (profileCourse.loading === LoadingState.Idle) {
      dispatch(getProfileCoursePackagesEditData(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  //#region Handle edit form
  const initialCoursePackages = useMemo(() => {
    return getInitialCoursePackages(profileCourse?.coursePackages);
  }, [profileCourse?.coursePackages]);

  const initialValues = useMemo<ProfileCoursePackageValues>(
    () => ({
      individualLesson:
        profileCourse.individualLesson ??
        createCoursePackage({
          lessonCount: CoursePackageLessonCount.One,
        }),
      coursePackages: profileCourse.coursePackages.length > 0 ? initialCoursePackages : INITIAL_COURSE_PACKAGES,
      archivedCoursePackages: profileCourse.archivedCoursePackages ?? [],
      removedCoursePackageIDs: [],
      coursePricingStrategyID: profileCourse.coursePricingStrategyID ?? CoursePricingStrategyID.MultiplePackages,
      singlePackage:
        profileCourse.singlePackage ??
        createCoursePackage({
          lessonCount: CoursePackageLessonCount.Sixteen,
        }),
    }),
    [
      initialCoursePackages,
      profileCourse.archivedCoursePackages,
      profileCourse.coursePackages.length,
      profileCourse.individualLesson,
      profileCourse.singlePackage,
      profileCourse.coursePricingStrategyID,
    ]
  );

  const validationSchema = useCoursePackageValidationSchema();

  const formik = useForm<ProfileCoursePackageValues>({
    initialValues,
    validationSchema,
    initialStatus: profileCourse.loading,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<ProfileCoursePackageValues>(async (values) => {
        return await updateProfileCoursePackages(Number(id), values);
      });

      const response = await submitWithValidation(values, formikHelpers);

      if (response) {
        Toastr.success('Успешно запазихте промените!');
        formik.resetForm({ values });
        history.push(`/profile/courses/${id}/packages`);
      }
    },
  });
  //#endregion Handle edit form

  const archivedCoursePackages = useMemo(
    () => formik.values.archivedCoursePackages.filter((x) => Number(x.typeID) === CoursePackageTypeID.Package),
    [formik.values.archivedCoursePackages]
  );

  //#region Handle packages
  function removeCoursePackage(coursePackageID: number) {
    const newCoursePackagesValue = produce(formik.values.coursePackages, (draft) => {
      const idx = formik.values.coursePackages.findIndex((coursePackage) => coursePackage.id == coursePackageID);
      draft.splice(idx, 1);
    });
    formik.setFieldValue('coursePackages', newCoursePackagesValue);
    formik.setErrors({});
  }

  function archiveCoursePackage(coursePackage: CoursePackageModel) {
    formik.setFieldValue(
      'archivedCoursePackages',
      produce(formik.values.archivedCoursePackages, (draft) => {
        draft.push(coursePackage);
      })
    );
  }

  function handleRemovePackageClick(coursePackageID: number) {
    return () => {
      // STEP 1: Confirm.
      const isConfirmed = confirm('Сигурни ли сте, че искате да изтриете този пакет?');

      if (!isConfirmed) {
        return;
      }

      // STEP 2: Make sure coursePackage package is not ordered yet.
      const coursePackage = formik.values.coursePackages.find((coursePackage) => coursePackage.id === coursePackageID);

      if (!coursePackage || Boolean(coursePackage?.isOrdered)) {
        return;
      }

      // STEP 3: Remove coursePackage package form active coursePackage packages list.
      removeCoursePackage(coursePackageID);

      formik.setFieldValue(
        'removedCoursePackageIDs',
        produce(formik.values.removedCoursePackageIDs, (draft) => {
          draft.push(coursePackageID);
        })
      );

      formik.setErrors({});
    };
  }

  //#endregion Handle packages

  //#region Handle individual lesson edit
  const individualLessonModal = useIndividualLessonEditModal({
    taxedPrice: formik.values.individualLesson.taxedPrice,
    onSubmit: ({ taxedPrice }) => {
      // STEP 1: Archive old individual lesson package.
      if (formik.values.individualLesson.id > 0) {
        archiveCoursePackage(formik.values.individualLesson);
      }

      // STEP 2: Create a new individual lesson package.
      formik.setFieldValue(
        'individualLesson',
        createCoursePackage({
          lessonCount: CoursePackageLessonCount.One,
          taxedPrice,
        })
      );
    },
  });

  const { setIsShown: setIsIndividualLessonEditModalShown, formik: individualLessonEditFormik } = individualLessonModal;

  const handleIndividualLessonPriceClick = useCallback(() => {
    // STEP 1: Make sure coursePackage package is not ordered yet.
    if (!formik.values.individualLesson.isOrdered) {
      return;
    }

    // STEP 2: Open IndividualLessonEditModal.
    setIsIndividualLessonEditModalShown(true);
    individualLessonEditFormik.resetForm();
  }, [formik.values.individualLesson.isOrdered, setIsIndividualLessonEditModalShown, individualLessonEditFormik]);
  //#endregion Handle individual lesson edit

  //#region Handle archive course package
  const archiveCoursePackageModal = useArchiveCoursePackageModal({
    onConfirm: (coursePackageID) => {
      // STEP 1: Make sure coursePackageID belongs to existing active coursePackage package.
      const coursePackage = formik.values.coursePackages.find((coursePackage) => coursePackage.id === coursePackageID);

      if (!coursePackage) {
        return;
      }

      // STEP 2: Archive coursePackage package.
      archiveCoursePackage({
        id: coursePackage.id,
        lessonCount: coursePackage.lessonCount,
        taxedPrice: coursePackage.taxedPrice,
        typeID: coursePackage.typeID,
        statusID: CoursePackageStatusID.Archived,
        isOrdered: Boolean(coursePackage.isOrdered),
      });

      // STEP 3: Remove coursePackage package form active coursePackage packages list.
      removeCoursePackage(coursePackageID);

      // STEP 4: Reset formik errors to avoid misleading errors.
      formik.setErrors({});
    },
  });

  function handleArchivePackageClick(coursePackageID: number) {
    return () => {
      archiveCoursePackageModal.openConfirmModal(coursePackageID);
    };
  }
  //#endregion Handle archive course package

  const isMultiplePackagesCoursePricingStrategySelected = useMemo(() => {
    return Number(formik.values.coursePricingStrategyID) === CoursePricingStrategyID.MultiplePackages;
  }, [formik.values.coursePricingStrategyID]);

  const isSinglePackageCoursePricingStrategySelected = useMemo(() => {
    return Number(formik.values.coursePricingStrategyID) === CoursePricingStrategyID.SinglePackage;
  }, [formik.values.coursePricingStrategyID]);

  useEqualizePackagePrices(formik);

  return (
    <>
      <ProfileCourseEditPageBase
        formik={formik}
        title={profileCourse.courseName}
        actions={
          <>
            <CancelButton to={`/profile/courses/${id}/packages`} />
            <SaveButton />
          </>
        }
      >
        <CardHeader>
          <CardTitle>Цени</CardTitle>
        </CardHeader>
        <CardBody>
          <Card variant="solid">
            <CardHeader isRow className={clsx({ 'border-0': isSinglePackageCoursePricingStrategySelected })}>
              <FormGroup controlId="coursePricingStrategyID">
                <label className="py-3 d-flex flex-row align-items-center cursor-pointer">
                  <RadioButtonControl
                    name="coursePricingStrategyID"
                    value={CoursePricingStrategyID.MultiplePackages}
                    isInline
                    className="me-3"
                  />

                  <CardTitle>Пакет от уроци</CardTitle>
                </label>
              </FormGroup>
            </CardHeader>

            {isMultiplePackagesCoursePricingStrategySelected && (
              <CardBody>
                <Accordion defaultActiveKey="coursePackages" className="accordion-icon-toggle">
                  <Accordion.Item eventKey="coursePackages" className="bg-transparent border-0">
                    <Accordion.Button className="accordion-header bg-transparent shadow-none p-0 pb-5">
                      <h3 className="fw-normal fs-2 mb-0">Активни пакети</h3>
                    </Accordion.Button>

                    <Accordion.Body className="p-0 pb-10">
                      <Row className="g-5">
                        <Card variant="bordered">
                          <CardBody>
                            <Row className="g-5">
                              <Col xl={6}>
                                <FormGroup controlId="individualLesson.lessonCount">
                                  <Label>Брой уроци в пакета</Label>
                                  <FormControl
                                    type="text"
                                    name="individualLesson.lessonCount"
                                    value={profileCourse.individualLesson?.lessonCount}
                                    hasSolidBackground={true}
                                    disabled
                                  />
                                </FormGroup>
                              </Col>

                              <Col xl={6}>
                                <FormGroup controlId="individualLesson.taxedPrice">
                                  <Label isRequired>Крайна цена за урок</Label>
                                  <PriceControl
                                    name="individualLesson.taxedPrice"
                                    onClick={handleIndividualLessonPriceClick}
                                    isReadOnly={Boolean(formik.values.individualLesson.isOrdered)}
                                    hasSolidBackground={false}
                                  />
                                  <PlatformTaxFormText
                                    taxedPrice={Number(formik.values.individualLesson?.taxedPrice)}
                                  />
                                  <br />
                                  <FormText>
                                    Минималната цена на урок в пакет е {MINIMUM_INDIVIDUAL_LESSON_PRICE} лв.
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        {formik.values.coursePackages.map((coursePackage, idx) => {
                          const isInvalid = !COURSE_PACKAGES_LESSON_AMOUNTS.includes(coursePackage.lessonCount);
                          const isDuplicated = formik.values.coursePackages.find((searchedCoursePackage) => {
                            return (
                              coursePackage.lessonCount === searchedCoursePackage.lessonCount &&
                              coursePackage.id !== searchedCoursePackage.id
                            );
                          });

                          return (
                            <>
                              <CoursePackageItem
                                key={coursePackage.id}
                                coursePackage={coursePackage}
                                fieldNamePrefix={`coursePackages[${idx}]`}
                                onArchive={handleArchivePackageClick}
                                onRemove={handleRemovePackageClick}
                                isInvalid={isInvalid || Boolean(isDuplicated)}
                              />
                            </>
                          );
                        })}

                        {isString(formik.errors?.coursePackages) && (
                          <Feedback>{formik.errors?.coursePackages}</Feedback>
                        )}

                        {formik.values.coursePackages.length === 0 && (
                          <p className="mb-0 fw-bold text-center">Нямате пакети от уроци към това обучение.</p>
                        )}
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <hr className="bg-gray-300 opacity-100 m-0" />

                <Accordion className="accordion-icon-toggle mb-n8">
                  <Accordion.Item eventKey="archivedCoursePackages" className="bg-transparent border-0">
                    <Accordion.Button className="accordion-header bg-transparent shadow-none p-0 py-5">
                      <h3 className="fw-normal fs-2 mb-0">Архивирани пакети</h3>
                    </Accordion.Button>

                    <Accordion.Body className="p-0 pb-10">
                      <Row className="g-5">
                        {archivedCoursePackages.map((archivedCoursePackage, idx) => (
                          <CoursePackageItem
                            key={archivedCoursePackage.id}
                            coursePackage={archivedCoursePackage}
                            fieldNamePrefix={`archivedCoursePackages[${idx}]`}
                            isEditable={false}
                          />
                        ))}

                        {archivedCoursePackages.length === 0 && (
                          <p className="mb-0 fw-bold text-center">Нямате архивирани пакети към това обучение.</p>
                        )}
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </CardBody>
            )}
          </Card>

          <Card variant="solid" className="mt-5">
            <CardHeader isRow className={clsx({ 'border-0': isMultiplePackagesCoursePricingStrategySelected })}>
              <FormGroup controlId="coursePricingStrategyID">
                <label className="py-3 d-flex flex-row align-items-center cursor-pointer">
                  <RadioButtonControl
                    name="coursePricingStrategyID"
                    value={CoursePricingStrategyID.SinglePackage}
                    isInline
                    className="me-3"
                  />

                  <CardTitle>Единичен пакет от уроци</CardTitle>
                </label>
              </FormGroup>
            </CardHeader>

            {isSinglePackageCoursePricingStrategySelected && (
              <CardBody>
                <Row className="g-5">
                  <SingleCoursePackageItem coursePackage={formik.values.singlePackage} />
                </Row>
              </CardBody>
            )}
          </Card>
        </CardBody>
      </ProfileCourseEditPageBase>

      <IndividualLessonEditModal instance={individualLessonModal} />
      <ArchiveCoursePackageModal instance={archiveCoursePackageModal} />
      {profileCourse.loading !== LoadingState.Idle && <GlobalSpinner />}
    </>
  );
}
