export enum NotificationType {
  TutorLessonBoughtCount = 'Shkolo\\PL\\Web\\Notifications\\TutorLessonBoughtCount',
  TutorLowTimeSlotCount = 'Shkolo\\PL\\Web\\Notifications\\TutorLowTimeSlotCount',
  CourseReservationRequest = 'Shkolo\\PL\\Web\\Notifications\\CourseReservationRequest',
  CourseReservationResponse = 'Shkolo\\PL\\Web\\Notifications\\CourseReservationResponse',
  CancelReservation = 'Shkolo\\PL\\Web\\Notifications\\CancelReservation',
  LessonStarts = 'Shkolo\\PL\\Web\\Notifications\\LessonStarts',
  TutorApprovalResponse = 'Shkolo\\PL\\Web\\Notifications\\TutorApprovalResponse',
  StudentCancelFreeLesson = 'Shkolo\\PL\\Web\\Notifications\\StudentCancelFreeLesson',
  LessonFeedbackSent = 'Shkolo\\PL\\Web\\Notifications\\LessonFeedbackSent',
  ReservationProposalSent = 'Shkolo\\PL\\Web\\Notifications\\ReservationProposalSent',
  ReservationProposalApprovalResponse = 'Shkolo\\PL\\Web\\Notifications\\ReservationProposalApprovalResponse',
  AutomaticPayoutSent = 'Shkolo\\PL\\Web\\Notifications\\AutomaticPayoutSent',
  TutorCancelProposedReservation = 'Shkolo\\PL\\Web\\Notifications\\TutorCancelProposedReservation',
  StudentCancelProposedReservation = 'Shkolo\\PL\\Web\\Notifications\\StudentCancelProposedReservation',
  TutorLessonFeedbackReminder = 'Shkolo\\PL\\Web\\Notifications\\TutorLessonFeedbackReminder',
  BlockedTutorBalanceTransfer = 'Shkolo\\PL\\Web\\Notifications\\BlockedTutorBalanceTransfer',
  ReleasedTutorBalanceTransfer = 'Shkolo\\PL\\Web\\Notifications\\ReleasedTutorBalanceTransfer',
  ExpiredPaymentTransactionReminder = 'Shkolo\\PL\\Web\\Notifications\\ExpiredPaymentTransactionReminder',
}
