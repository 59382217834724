import { CoursePackageModel } from '@/app/modules/course/create/models';
import { CourseSubject } from '@/app/modules/course/models';

export interface EnrolledCourse {
  id: number;
  courseID: number;
  courseName: string;
  tutorID: number;
  tutorName: string;
  tutorProfileImagePath: string;
  rating: string;
  targetAudience: string;
  availableLessonCount: number;
  courseStatusID: number;
  courseTransliteratedSlug: string;
  tutorTransliteratedSlug: string;
  subjects: CourseSubject[];
  coursePackage: CoursePackageModel;
  hasAvailableFutureTimeSlots: boolean;
}

export interface EnrolledCourseFilterValues {
  search: string;
  availableLessonsRange: string[];
}

export interface EnrolledCourseFilterQueryParams
  extends Omit<EnrolledCourseFilterValues, 'search' | 'availableLessonsRange'> {
  search?: string;
  availableLessonsRange?: string[];
}

export enum EnrolledCourseFilterAvailableLessonsRange {
  EqualTo0 = 'equal_to_0',
  From0To5 = 'from_0_to_5',
  From5To10 = 'from_5_to_10',
  Over10 = 'over_10',
}
