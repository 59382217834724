import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as H from 'history';

import { isString } from './isString';

export function useLink(to: H.LocationDescriptor | ((location: H.Location) => H.LocationDescriptor)) {
  const location = useLocation();
  const link = useMemo<string>(() => {
    if (isString(to)) {
      return String(to);
    }

    if (typeof to === 'function') {
      return String(to(location));
    }

    return '';
  }, [location, to]);

  return link;
}
