import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { DataFormat } from 'select2';

import { serializeFormData } from '@/app/utils/serializeFormData';

import { Lesson, LessonEnd, LessonFeedbackFormValues } from './models';

const ENDPOINT_PREFIX = '/ajax/lessons';

export const ENDPOINTS = {
  show: (id: number) => `${ENDPOINT_PREFIX}/${id}`,
  end: (id: number) => `${ENDPOINT_PREFIX}/${id}/end`,
  feedback: (id: number) => `${ENDPOINT_PREFIX}/${id}/feedback`,
  resource: (timelineID: number | string) => `${ENDPOINT_PREFIX}?timelineID=${timelineID}`,
};

//#region Get lesson show data
export type GetLessonShowResponse = Lesson;

export const getLessonShow = createAsyncThunk('lesson/getLessonShow', async function (lessonID: number) {
  const response = await axios.get<GetLessonShowResponse>(ENDPOINTS.show(lessonID));

  return response.data;
});
//#endregion Get lesson show data

//#region Get lesson end data
export type GetLessonEndResponse = LessonEnd;

export const getLessonEnd = createAsyncThunk('lesson/getLessonEnd', async function (lessonID: number) {
  const response = await axios.get<GetLessonEndResponse>(ENDPOINTS.end(lessonID));

  return response.data;
});
//#endregion Get lesson end data

//#region create lesson feedback
export async function createLessonFeedback(id: number, values: LessonFeedbackFormValues) {
  const formData = serializeFormData(values);
  const response = await axios.post(ENDPOINTS.feedback(id), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

//#endregion create lesson feedback

//#region Get lesson feedback create data
export type GetLessonFeedbackCreateDataResponse = {
  lessons: DataFormat[];
};

export const getLessonFeedbackCreateData = createAsyncThunk(
  'lesson/getLessonFeedbackCreateData',
  async function (timelineID: number | string) {
    const response = await axios.get<GetLessonFeedbackCreateDataResponse>(ENDPOINTS.resource(timelineID));

    return response.data;
  }
);
//#endregion Get lesson feedback create data
