import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { wrappedReactLazy } from '@/app/utils/wrapped-react-lazy';

const SupportTicketIndexPage = wrappedReactLazy(() => import('./SupportTicketIndexPage'));
const SupportTicketShowPage = wrappedReactLazy(() => import('./SupportTicketShowPage'));
const SupportTicketCreatePage = wrappedReactLazy(() => import('./SupportTicketCreatePage'));

export function SupportTicketPage() {
  return (
    <Switch>
      <Route path="/support-tickets/create" exact>
        <SupportTicketCreatePage />
      </Route>
      <Route path="/support-tickets" exact>
        <SupportTicketIndexPage />
      </Route>
      <Route path="/support-tickets/:id" exact>
        <SupportTicketShowPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
