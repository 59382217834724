import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { parse } from 'date-fns';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { PROPOSED_RESERVATION_STATUS_IDS } from '@/app/components/Calendar/constants';
import { ReservationStatusID } from '@/app/components/Calendar/models';
import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformCustomEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { Reservation } from '@/app/modules/my-schedule/models';
import { RejectReservationModal } from '@/app/modules/my-schedule/MyScheduleActions/RejectReservationModal';
import { isAfterOneDay, isLessonRoomAvailable } from '@/app/modules/my-schedule/utils';
import { getCourseAvailableLessonsCount } from '@/app/modules/reservation-proposal/service';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagBookingConfirmedEvent } from '@/app/utils/google-analytics/customEvents';

import { handlePlDeprecatedPurchaseAction } from '../../course/utils';
import {
  OPEN_LESSON_BUFFER_MINUTES,
  PROPOSAL_APPROVAL_NO_AVAILABLE_LESSONS_CONFIRM_MESSAGE,
  PROPOSAL_APPROVAL_SPECIAL_PACKAGE_PURCHASE_CONFIRM_MESSAGE,
} from '../constants';
import { CancelReservationModal } from './CancelReservationModal';

type ScheduleItemProps = {
  reservation: Reservation;
  handleReservationApproval: (reservationID: number) => void;
};

export function ScheduleItem({ reservation, handleReservationApproval }: ScheduleItemProps) {
  const history = useHistory();
  const reservationStatus = reservation.reservationStatusID;
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  let canCancel = reservation.canCancel;
  // Show the CANCEL button if you approve a reservation, and it starts after more than 24 hours.
  if (
    (reservationStatus === ReservationStatusID.Approved &&
      isAfterOneDay(parse(reservation.fullDate as string, DEFAULT_DATETIME_FORMAT, new Date()))) ||
    (!reservation.isTutor && reservationStatus === ReservationStatusID.WaitingApproval)
  ) {
    canCancel = true;
  }

  function handleModalOpen() {
    setIsModalShown(true);
  }

  function handleModalClose() {
    setIsModalShown(false);
  }

  const isEventSoon = useMemo(() => {
    const lessonStartDateTime = parse(reservation.fullDate as string, DEFAULT_DATETIME_FORMAT, new Date());

    return isLessonRoomAvailable(lessonStartDateTime);
  }, [reservation]);

  const analyticsCustomEventItem = useMemo(() => {
    return {
      item_name: `${reservation.subjectName} ${reservation.targetAudienceName} ${reservation.tutorName}`,
      item_id: Number(reservation.courseID),
      item_brand: reservation.tutorName as string,
      item_category: reservation.subjectName as string,
      item_category2: reservation.targetAudienceName as string,
    };
  }, [reservation]);

  async function approveReservation() {
    await handleReservationApproval(reservation.id);
    const conversionEventValues = await transformCustomEventToConversionEventValues(
      FACEBOOK_PIXEL_CUSTOM_EVENTS.bookingConfirmation,
      analyticsCustomEventItem
    );
    tagBookingConfirmedEvent(analyticsCustomEventItem, conversionEventValues.eventID);
    await trackAnalyticsEventConversionsAPI(conversionEventValues);
  }

  async function approveProposal() {
    if (!PROPOSED_RESERVATION_STATUS_IDS.includes(reservationStatus)) {
      return;
    }

    const response = await getCourseAvailableLessonsCount(reservation.id);

    if (
      response.isFreeProposal ||
      (reservation.isSpecialProposal && !response.shouldStudentPurchaseSpecialLessonForCourse) ||
      (!reservation.isSpecialProposal && response.availableLessonsCount > 0)
    ) {
      history.push(`/reservation-proposals/${reservation.id}`);
      return;
    }

    handlePlDeprecatedPurchaseAction();
    return;

    //#region Deprecated purchase action

    // let confirmMessage = PROPOSAL_APPROVAL_NO_AVAILABLE_LESSONS_CONFIRM_MESSAGE;
    // if (response.shouldStudentPurchaseSpecialLessonForCourse) {
    //   confirmMessage = PROPOSAL_APPROVAL_SPECIAL_PACKAGE_PURCHASE_CONFIRM_MESSAGE;
    // }

    // const confirmCheckout = confirm(confirmMessage);

    // if (!confirmCheckout) {
    //   return;
    // }

    // history.push(`/courses/${reservation.courseTransliteratedSlug}/checkout?reservationID=${reservation.id}`);

    //#endregion Deprecated purchase action
  }

  return (
    <div className="d-flex flex-column flex-sm-row align-items-center p-5 me-n5 text-center text-sm-end fs-7">
      {reservationStatus === ReservationStatusID.WaitingApproval && (
        <>
          {Boolean(reservation.isTutor) && (
            <>
              <Button onClick={handleModalOpen} variant="light-danger" className="mb-5 mb-md-0 me-5 text-nowrap">
                Откажи часа
              </Button>
              <Button onClick={approveReservation} variant="primary" className="mb-5 mb-md-0 me-5 text-nowrap">
                Одобри часа
              </Button>
              <RejectReservationModal
                isModalShown={isModalShown}
                reservationID={reservation.id}
                handleModalClose={handleModalClose}
                analyticsCustomEventItem={analyticsCustomEventItem}
              />
            </>
          )}

          {!reservation.isTutor && <span className="my-3 me-5">Чака одобрение от обучител</span>}

          {Boolean(!reservation.isTutor && canCancel) && (
            <>
              <Button onClick={handleModalOpen} variant="light-danger" className="me-5 text-nowrap">
                Откажи часа
              </Button>

              <CancelReservationModal
                isModalShown={isModalShown}
                reservationID={reservation.id}
                onClose={handleModalClose}
              />
            </>
          )}
        </>
      )}
      {reservationStatus === ReservationStatusID.Approved && isEventSoon && (
        <>
          {canCancel && (
            <>
              <Button onClick={handleModalOpen} variant="light-danger" className="me-5 text-nowrap">
                Откажи часа
              </Button>
              <CancelReservationModal
                isModalShown={isModalShown}
                reservationID={reservation.id}
                onClose={handleModalClose}
              />
            </>
          )}

          <LinkButton
            to={`/lessons/${reservation.lessonID}`}
            variant="success"
            className="w-100 w-md-auto me-5 text-nowrap"
          >
            <i className="fas fa-play" /> Влез в урок
          </LinkButton>
        </>
      )}
      {reservationStatus === ReservationStatusID.Approved && !isEventSoon && (
        <>
          <span className="my-3 me-5">
            Стаята ще бъде активна {OPEN_LESSON_BUFFER_MINUTES} минути преди започването на часа.
          </span>

          {canCancel && (
            <>
              <Button onClick={handleModalOpen} variant="light-danger" className="me-5 text-nowrap">
                Откажи часа
              </Button>

              <CancelReservationModal
                isModalShown={isModalShown}
                reservationID={reservation.id}
                onClose={handleModalClose}
              />
            </>
          )}
        </>
      )}
      {reservation.reservationStatusID === ReservationStatusID.Rejected && (
        <>
          <span className="my-3 me-5">Отказан урок</span>

          <LinkButton
            to={`/courses/${reservation.courseID}/reservation`}
            variant="primary"
            className="me-5 text-nowrap"
          >
            Резервирайте нов час
          </LinkButton>
        </>
      )}
      {Boolean(
        PROPOSED_RESERVATION_STATUS_IDS.includes(reservation.reservationStatusID) && reservation.hasProposal
      ) && (
        <>
          {Boolean(!reservation.isTutor) && (
            <>
              <span className="my-3 me-5">
                Покана за {reservation.isFreeProposal && 'безплатен'} час от обучител. Желаете ли да я приемете?
              </span>
              <Button onClick={handleModalOpen} variant="light-danger" className="mb-5 mb-md-0 me-5 text-nowrap">
                Откажи часа
              </Button>
              <Button onClick={approveProposal} variant="primary" className="mb-5 mb-md-0 me-5 text-nowrap">
                Одобри часа
              </Button>
              <CancelReservationModal
                isModalShown={isModalShown}
                reservationID={reservation.id}
                onClose={handleModalClose}
              />
            </>
          )}
          {Boolean(reservation.isTutor) && (
            <>
              <span className="my-3 me-5">Чака одобрение от обучаем</span>
              <Button onClick={handleModalOpen} variant="light-danger" className="mb-5 mb-md-0 me-5 text-nowrap">
                Откажи часа
              </Button>
              <CancelReservationModal
                isModalShown={isModalShown}
                reservationID={reservation.id}
                onClose={handleModalClose}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
