import { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { useVideoPlayerModal } from '@/app/components/PlayVideoModal/PlayVideoModal';
import { VirtualClassDemoModal } from '@/app/modules/home/components/VirtualClassDemoModal';
import { Promotion } from '@/app/modules/promotion/constants';
import { useAppSelector } from '@/redux/store';

import { FooterContext } from './FooterContext';

export function Footer() {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const promotionStore = useAppSelector((state) => state.promotion);
  const appleStoreAppUrl = import.meta.env.VITE_APPLE_STORE_APP_URL;
  const googlePlayAppUrl = import.meta.env.VITE_GOOGLE_STORE_APP_URL;
  const { isVisible, isPromotionDescriptionVisible } = useContext(FooterContext);
  const { isVideoPlayerModalShown, showVideoPlayerModal, hideVideoPlayerModal } = useVideoPlayerModal();

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="footer py-10 d-flex flex-lg-column border-top border-secondary" id="kt_footer">
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-center">
          <Container>
            <Row className="mb-n4 g-5">
              <Col sm="6" lg="3">
                <h6 className="fw-bold mb-4">Условия и политики</h6>
                <p>
                  <Link to="/tutor-terms" className="text-reset">
                    Общи условия за обучители
                  </Link>
                </p>
                <p>
                  <Link to="/terms" className="text-reset">
                    Общи условия за потребители
                  </Link>
                </p>
                <p>
                  <Link to="/additional-tutor-terms" className="text-reset">
                    Допълнителни условия за обучители
                  </Link>
                </p>
                <p>
                  <Link to="/child-protection-policy" className="text-reset">
                    Политика за закрила на детето
                  </Link>
                </p>
                <p>
                  <a href="https://www.shkolo.bg/cookiepolicy/" className="text-reset" target="_blank" rel="noreferrer">
                    Бисквитки
                  </a>
                </p>
              </Col>
              <Col sm="6" lg="2">
                <h6 className="fw-bold mb-4">За платформата</h6>
                <p>
                  <Link to="/subjects" className="text-reset">
                    Предмети
                  </Link>
                </p>
                <p>
                  <Link to="/tutors" className="text-reset">
                    Обучители
                  </Link>
                </p>
                <p>
                  <Link to="/tutor/become" className="text-reset">
                    Стани обучител
                  </Link>
                </p>
              </Col>
              <Col sm="6" lg="2">
                <h6 className="fw-bold mb-4">Полезни връзки</h6>
                <p>
                  <a
                    href="https://www.shkolo.bg/blog/lessons-help/"
                    className="text-reset"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Помощ
                  </a>
                </p>
                {isAuthenticated && (
                  <p>
                    <Link to="/test-room" className="text-reset">
                      Тестова стая
                    </Link>
                  </p>
                )}
                <p>
                  <a
                    href="https://www.shkolo.bg/blog/shkolo-uroci-chesto-zadavani-vaprosi/"
                    className="text-reset"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ЧЗВ
                  </a>
                </p>
                <p>
                  <Link to="" className="text-reset" onClick={showVideoPlayerModal}>
                    Демо на виртуална стая
                  </Link>
                </p>
                <p>
                  <Link to="/tutor-advices" className="text-reset">
                    Съвети за Обучители
                  </Link>
                </p>
                <p>
                  <Link to="/quality-criteria" className="text-reset">
                    Критерии за качество
                  </Link>
                </p>
              </Col>
              <Col sm="6" lg="2">
                <h6 className="fw-bold mb-4">Контакти с платформата</h6>
                <p>
                  Имейл:
                  <a href="mailto: support@shkolo.com" className="ms-1">
                    support@shkolo.com
                  </a>
                </p>
                <p>
                  <em>Работно време: 09:00 - 18:00 ч. (понеделник - петък)</em>
                </p>
                <p>
                  <a
                    href="https://www.facebook.com/shkolo.lessons"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center"
                  >
                    <i className="fab fa-facebook fa-2x text-primary" />
                    <span className="ms-2">Shkolo Уроци</span>
                  </a>
                </p>
              </Col>
              <Col sm="6" lg="3" className="d-flex flex-column justify-content-lg align-items-start">
                <h6 className="fw-bold mb-4">Изтегли приложението на Shkolo Уроци</h6>
                <div className="d-flex flex-row">
                  <a href={appleStoreAppUrl} className="me-2" target="_blank" rel="noreferrer">
                    <img
                      alt="Свали от Apple Store"
                      src={toAbsoluteUrl('/img/app_store_badge.png')}
                      className="h-30px"
                    />
                  </a>
                  <a href={googlePlayAppUrl} target="_blank" rel="noreferrer">
                    <img
                      alt="Свали от Google Play"
                      src={toAbsoluteUrl('/img/google-play-badge.png')}
                      className="h-30px"
                    />
                  </a>
                </div>
              </Col>
            </Row>

            <div className="text-dark text-center order-2 order-md-1 mt-10">
              ШКОЛО ООД е администратор на{' '}
              <a
                href="https://www.shkolo.bg/privacy/"
                target="_blank"
                rel="noreferrer"
                className="text-dark-75 font-weight-extrabold text-hover-primary"
              >
                лични данни
              </a>{' '}
              и притежава сертификати за{' '}
              <a
                href="https://www.shkolo.bg/security/"
                target="_blank"
                rel="noreferrer"
                className="text-dark-75 font-weight-extrabold text-hover-primary"
              >
                информационна сигурност
              </a>
              .
            </div>

            {isPromotionDescriptionVisible &&
              promotionStore.canUseLessonPromotion &&
              promotionStore.activePromotionSlug === Promotion.Discount25Percentage && (
                <div className="text-dark text-center order-3 order-md-1 mt-5" id="promotion-description">
                  * Промоцията важи от 1 до 30 Септември за първа покупка на индивидуален урок от всики потребители и не
                  важи за пакетни предложения.
                </div>
              )}
          </Container>
        </div>
      </div>
      <VirtualClassDemoModal isShown={isVideoPlayerModalShown} onHide={hideVideoPlayerModal} />
    </>
  );
}
