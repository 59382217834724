import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_RATING } from '@/app/components/Form/RatingControl/constants';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { CourseFeedback, CourseFeedbackReminder } from './models';
import { getCourseFeedback, getCourseFeedbackReminder } from './service';

interface CourseFeedbackState {
  loading: LoadingState;
  error?: string | null;
  courseFeedback: CourseFeedback;
  courseFeedbackReminder: CourseFeedbackReminder | null;
}

const initialState: CourseFeedbackState = {
  loading: LoadingState.Idle,
  error: null,
  courseFeedback: {
    courseRating: DEFAULT_RATING,
    courseComment: '',
  },
  courseFeedbackReminder: null,
};

const courseFeedbackSlice = createSlice({
  name: 'courseFeedback',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region Get user feedback for selected course
    builder
      .addCase(getCourseFeedback.pending, sharedPendingReducer)
      .addCase(getCourseFeedback.rejected, sharedRejectedReducer)
      .addCase(getCourseFeedback.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseFeedback = action.payload.courseFeedback;
      });
    //#endregion Get user feedback for selected course

    //#region Get course feedbacks for course show page
    builder
      .addCase(getCourseFeedbackReminder.pending, sharedPendingReducer)
      .addCase(getCourseFeedbackReminder.rejected, sharedRejectedReducer)
      .addCase(getCourseFeedbackReminder.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseFeedbackReminder = action.payload.courseFeedbackReminder;
      });
    //#endregion Get course feedback for course show page
  },
});

export default courseFeedbackSlice;
