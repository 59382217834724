import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';

import { CourseIndexFilterPriceRange, CourseIndexFilterPriceRangeLabel } from '../../../models';
import { FilterDesktop } from './components/FilterDesktop';

export function PriceFilterPageDesktop() {
  return (
    <FilterDesktop name="Цена" className="overflow-scroll mh-100px">
      <CheckboxControl
        name="priceRanges"
        value={CourseIndexFilterPriceRange.From20To30}
        label={CourseIndexFilterPriceRangeLabel.From20To30}
        className="d-flex align-items-center justify-content-between w-100 mb-3 cursor-pointer"
      />

      <CheckboxControl
        name="priceRanges"
        value={CourseIndexFilterPriceRange.From30To40}
        label={CourseIndexFilterPriceRangeLabel.From30To40}
        className="d-flex align-items-center justify-content-between w-100 mb-3 cursor-pointer"
      />

      <CheckboxControl
        name="priceRanges"
        value={CourseIndexFilterPriceRange.From40To50}
        label={CourseIndexFilterPriceRangeLabel.From40To50}
        className="d-flex align-items-center justify-content-between w-100 mb-3 cursor-pointer"
      />

      <CheckboxControl
        name="priceRanges"
        value={CourseIndexFilterPriceRange.Over50}
        label={CourseIndexFilterPriceRangeLabel.Over50}
        className="d-flex align-items-center justify-content-between w-100 mb-3 cursor-pointer"
      />
    </FilterDesktop>
  );
}
