import React, { useMemo } from 'react';
import { Col, FormText, Row } from 'react-bootstrap';

import { DatePickerControl } from '@/app/components/Form/DatePickerControl/DatePickerControl';
import { DaysOfWeekControl } from '@/app/components/Form/DaysOfWeekControl/DaysOfWeekControl';
import { DayTimeIntervalControl } from '@/app/components/Form/DayTimeIntervalControl/DayTimeIntervalControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { TextareaControl } from '@/app/components/Form/TextareaControl/TextareaControl';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { REPEATS_PER_WEEK_OPTIONS } from '@/app/modules/course/constants';

export function StudentProposalForm() {
  const now = useMemo(() => new Date(), []);

  return (
    <>
      <DayTimeIntervalControl name="dayTimeIntervals" formGroupClassName="mb-0" hasBorder />
      <Row className="mt-6 mt-md-8">
        <Col md={6} className="pe-md-5 mb-8 mb-md-0">
          <FormGroup>
            <Label>Колко пъти в седмицата искате да посещавате обучението?</Label>
            <SelectControl name="repeatsPerWeek" options={REPEATS_PER_WEEK_OPTIONS} />
          </FormGroup>
        </Col>
        <Col md={6} className="ps-md-5">
          <DaysOfWeekControl
            name="daysOfWeek"
            containerClassName="mt-0 mt-md-3"
            checkboxOptionClassName="mb-md-2 mb-xl-0"
          />
        </Col>
      </Row>
      <Row className="mt-6 mt-md-10">
        <Col md={6} className="pe-md-5 mb-8 mb-md-0">
          <FormGroup controlId="startDate">
            <Label>Начална дата</Label>
            <DatePickerControl name="startDate" maxDateFieldName="endDate" isValueDateObject minDate={now} />
          </FormGroup>
        </Col>
        <Col md={6} className="ps-md-5">
          <FormGroup controlId="endDate">
            <Label>Крайна дата</Label>
            <DatePickerControl name="endDate" minDateFieldName="startDate" isValueDateObject minDate={now} />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="mb-4 mt-8 mt-md-10" controlId="comment">
        <Label isRequired>Коментар</Label>
        <TextareaControl name="comment" rows={4} />
        <FormText>Отбележете детайлите на Вашето предложение за часове.</FormText>
      </FormGroup>
    </>
  );
}
