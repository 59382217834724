import { useState } from 'react';

import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformCustomEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { RegistrationModal } from '@/app/modules/auth/RegistrationModal';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagRegistrationEvent } from '@/app/utils/google-analytics/customEvents';

import './HeaderAuthenticationMenu.scss';

type HeaderAuthenticationMenuProps = {
  className?: string;
};

export function HeaderAuthenticationMenu({ className }: HeaderAuthenticationMenuProps) {
  const from = encodeURIComponent(window.location.pathname + window.location.search);
  const [registrationModalIsShown, setRegistrationModalIsShown] = useState<boolean>(false);

  async function handleRegistrationButtonClick() {
    setRegistrationModalIsShown(true);
    const conversionEventValues = await transformCustomEventToConversionEventValues(
      FACEBOOK_PIXEL_CUSTOM_EVENTS.registration
    );
    tagRegistrationEvent(conversionEventValues.eventID);
    await trackAnalyticsEventConversionsAPI(conversionEventValues);
  }

  return (
    <div className={className}>
      <a href={`/auth?from=${from}`} className="btn header-auth-button btn-icon-white btn-text-white p-2 me-2">
        <i className="fas fa-arrow-right-to-bracket" />
        <span>Вход</span>
      </a>

      <button
        onClick={handleRegistrationButtonClick}
        className="btn header-auth-button btn-icon-white btn-text-white p-2"
      >
        <i className="fas fa-user-plus" />
        <span>Регистрация</span>
      </button>
      <RegistrationModal isShown={registrationModalIsShown} setIsShown={setRegistrationModalIsShown} />
    </div>
  );
}
