import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Alert } from '@/app/components/Alert/Alert';
import { AlertBody } from '@/app/components/Alert/AlertBody';
import { AlertIcon } from '@/app/components/Alert/AlertIcon';
import { EditButton } from '@/app/components/Button/EditButton';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { DEFAULT_PROFILE_PICTURE_PATH } from '@/app/layout/constants';
import { IN_PROGRESS_TUTOR_STATUS_IDS } from '@/app/modules/profile/details/constants';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

import { StickyHeader } from '../../StickyHeader';
import { getStudentProfileDetailsShowData } from '../service';

export function StudentProfileDetails() {
  const profileDetails = useAppSelector((state) => state.profileDetails);
  const studentDetails = profileDetails.profileDetails;
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);
  const tutorProfileInProgress = tutorStatusID && IN_PROGRESS_TUTOR_STATUS_IDS.includes(tutorStatusID);
  const dispatch = useDispatch();

  useEffect(function componentDidMount() {
    if (profileDetails.loading === LoadingState.Idle) {
      dispatch(getStudentProfileDetailsShowData());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>Данни за профила</title>
      </Helmet>
      <StickyHeader className="py-5">
        <PageTop className="mb-5">
          <PageTitle>Данни за профила</PageTitle>
          <PageActions>
            <EditButton to="/profile/details/edit" disabled={tutorProfileInProgress} />
          </PageActions>
        </PageTop>
      </StickyHeader>

      {tutorProfileInProgress && (
        <Alert className="mb-7" variant="primary">
          <AlertIcon path="/img/svg/icons/Code/Warning-1-circle.svg" variant="primary" />
          <AlertBody>
            В момента сте в процес на създаване на обучителски профил. Трябва да завършите стъпките и да изпратите
            профила си за одобрение, за да можете да редактирате профила си.
            <Link to="/tutor/become" className="mt-2">
              Продължи създаването на обучителския си профил
            </Link>
          </AlertBody>
        </Alert>
      )}
      <Card>
        <CardHeader>
          <CardTitle>Лични данни</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={{ span: 'auto', offset: 1 }} className="mb-4">
              <div
                style={{
                  backgroundImage: `url(${studentDetails?.profileFile ?? DEFAULT_PROFILE_PICTURE_PATH})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
                className="profile-image me-3 w-125px h-125px rounded"
              />
            </Col>
            <Col md={{ span: 12, offset: 1 }} xl={{ span: 8, offset: 0 }}>
              <Row>
                <p className="mb-0 fw-bold mb-1">Име</p>
                <p>{studentDetails?.name ?? ''}</p>
              </Row>
              <Row>
                <p className="mb-0 fw-bold mb-1">Телефон</p>
                <p>{studentDetails?.phone ?? ''}</p>
              </Row>
              <Row>
                <p className="mb-0 fw-bold mb-1">Имейл адрес</p>
                <p>{studentDetails?.email ?? ''}</p>
              </Row>
              <Row>
                <p className="mb-0 fw-bold mb-1">Потребителско име</p>
                <p>{studentDetails?.username ?? ''}</p>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
