import React from 'react';

import { ErrorBasePage } from './ErrorBasePage/ErrorBasePage';

export function BlockedStudentPage() {
  return (
    <ErrorBasePage
      code={423}
      actions={
        <a href="/auth/logout" className="font-size-h3 font-weight-light">
          Изход
        </a>
      }
    />
  );
}
