import { ReactNode } from 'react';
import clsx from 'clsx';

import { DropzoneControlState } from '@/app/components/Form/models';
import { MessengerThreadAttachmentControl } from '@/app/modules/messenger/MessengerThreadAttachmentControl';

type MessengerReplySectionProps = {
  children: (dropzoneControlState: DropzoneControlState) => ReactNode;
  className?: string;
};

export function MessengerReplySection({ children, className }: MessengerReplySectionProps) {
  return (
    <MessengerThreadAttachmentControl
      name="attachments"
      className={clsx('d-flex align-items-end overflow-hidden py-2 py-md-3', className)}
    >
      {children}
    </MessengerThreadAttachmentControl>
  );
}
