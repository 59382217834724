export interface SubjectIndexFilterValues {
  sortBy: string;
  search: string;
}

export type SubjectIndexFilterQueryParams = Partial<SubjectIndexFilterValues>;

export enum SubjectIndexFilterSortBy {
  Name = 'name',
  Popularity = 'popularity',
}
