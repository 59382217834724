import { useEffect, useState } from 'react';
import { FormikContextType } from 'formik';

import { CoursePackageStepModel, CoursePricingStrategyID } from '@/app/modules/course/create/models';
import { ProfileCoursePackageValues } from '@/app/modules/profile/course/models';

export function useEqualizePackagePrices(
  formik: FormikContextType<ProfileCoursePackageValues> | FormikContextType<CoursePackageStepModel>
) {
  const [haveEqualizedPackages, setHaveEqualizedPackages] = useState(false);

  useEffect(() => {
    const singlePackage = formik.values.singlePackage;
    const coursePackages = formik.values.coursePackages;
    const coursePricingStrategyID = Number(formik.values.coursePricingStrategyID);

    if (haveEqualizedPackages) {
      return;
    }

    if (coursePricingStrategyID === CoursePricingStrategyID.SinglePackage && singlePackage.taxedPrice === '') {
      const coursePackage = coursePackages?.find((x) => Number(x.lessonCount) === Number(singlePackage?.lessonCount));
      if (coursePackage && coursePackage.taxedPrice !== '') {
        formik.setFieldValue('singlePackage.taxedPrice', coursePackage.taxedPrice);
        setHaveEqualizedPackages(true);
      }
    }
    if (coursePricingStrategyID === CoursePricingStrategyID.MultiplePackages && singlePackage?.taxedPrice !== '') {
      const coursePackageID = coursePackages?.findIndex(
        (x) => Number(x.lessonCount) === Number(singlePackage?.lessonCount)
      );
      if (coursePackageID && coursePackages?.[coursePackageID]?.taxedPrice === '') {
        formik.setFieldValue(`coursePackages[${coursePackageID}].taxedPrice`, singlePackage?.taxedPrice);
        setHaveEqualizedPackages(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    haveEqualizedPackages,
    formik.values.coursePackages,
    formik.values.singlePackage,
    formik.values.coursePricingStrategyID,
  ]);
}
