import { GoBackToDashboardLinkButton } from '@/app/modules/errors/ErrorBasePage/GoBackToDashboardLinkButton';

import { ErrorBasePage } from './ErrorBasePage/ErrorBasePage';

export function NotFoundPage() {
  return (
    <ErrorBasePage code={404} actions={<GoBackToDashboardLinkButton />}>
      Тази страница не съществува.
    </ErrorBasePage>
  );
}
