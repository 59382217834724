import { createSlice } from '@reduxjs/toolkit';
import { parse } from 'date-fns';
import produce from 'immer';

import { DEFAULT_DURATION, DEFAULT_RESERVATION_BUFFER_HOURS } from '@/app/components/Calendar/constants';
import {
  CalendarEvent,
  EditableTimeSlotEntity,
  RecurrenceTypeID,
  ReservationStatusID,
  TimeSlotStatusID,
} from '@/app/components/Calendar/models';
import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { Reservation, ReservationTimeSlotEntity, UpcomingLesson } from '@/app/modules/my-schedule/models';
import { approveReservation } from '@/app/modules/reservation/service';
import { getHolidays } from '@/app/modules/schedule/service';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { RecurrenceType } from '../course/models';
import { isLessonOver } from '../lesson/utils';
import {
  getFutureLessonsCalendarView,
  getFutureLessonsListView,
  getMyScheduleNotificationsCount,
  getScheduleCalendarEditData,
  getUpcomingLessons,
} from './service';
import { isLessonRoomAvailable } from './utils';

interface MyScheduleState {
  loading: LoadingState;
  notificationsCountLoading: LoadingState;
  error?: string | null;
  futureLessons: Reservation[];
  calendarReservations: ReservationTimeSlotEntity[];
  currentPage: number;
  hasMore: boolean;
  duration: number | string;
  reservationBufferHours: number | string;
  canChangeCourseDuration: boolean;
  canUseLegacyCourseDuration: boolean;
  timeSlots: EditableTimeSlotEntity[];
  recurrenceTypes: RecurrenceType[];
  upcomingLessons: UpcomingLesson[];
  lessonsToEnter: UpcomingLesson[];
  holidays: CalendarEvent[];
  blockNewStudentsReservations: 0 | 1;
  notificationsCount: number;
}

const initialState: MyScheduleState = {
  loading: LoadingState.Idle,
  notificationsCountLoading: LoadingState.Idle,
  error: null,
  futureLessons: [],
  calendarReservations: [],
  currentPage: 1,
  hasMore: true,
  duration: DEFAULT_DURATION,
  reservationBufferHours: DEFAULT_RESERVATION_BUFFER_HOURS,
  canChangeCourseDuration: true,
  canUseLegacyCourseDuration: false,
  timeSlots: [],
  recurrenceTypes: [],
  upcomingLessons: [],
  lessonsToEnter: [],
  holidays: [],
  blockNewStudentsReservations: 0,
  notificationsCount: 0,
};

const myScheduleSlice = createSlice({
  name: 'mySchedule',
  initialState,
  reducers: {
    loadMore(state) {
      if (state.loading === LoadingState.Idle && state.hasMore) {
        state.currentPage++;
      }
    },
    resetFutureLessons(state) {
      state.futureLessons = [];
      state.currentPage = 1;
      state.hasMore = true;
    },
    updateLessonsToEnter(state) {
      state.lessonsToEnter = state.upcomingLessons.filter((lesson) => {
        const lessonStartDateTime = parse(lesson.startDateTime as string, DEFAULT_DATETIME_FORMAT, new Date());
        const lessonEndDateTime = parse(lesson.endDateTime as string, DEFAULT_DATETIME_FORMAT, new Date());

        return isLessonRoomAvailable(lessonStartDateTime) && !isLessonOver(lessonEndDateTime);
      });
    },
  },
  extraReducers: (builder) => {
    //#region Get future lessons list view
    builder
      .addCase(getFutureLessonsListView.pending, sharedPendingReducer)
      .addCase(getFutureLessonsListView.rejected, sharedRejectedReducer)
      .addCase(getFutureLessonsListView.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.hasMore = action.payload.hasMore;

        if (action.payload.currentPage >= state.currentPage) {
          for (const lesson of action.payload.futureLessons) {
            const idx = state.futureLessons.findIndex((x) => x.id === lesson.id);

            if (idx === -1) {
              state.futureLessons.push(lesson);
            }
          }
        }
        state.currentPage = action.payload.currentPage;
      });
    //#endregion Get future lessons list view
    //#region Get future lessons calendar view
    builder
      .addCase(getFutureLessonsCalendarView.pending, sharedPendingReducer)
      .addCase(getFutureLessonsCalendarView.rejected, sharedRejectedReducer)
      .addCase(getFutureLessonsCalendarView.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.currentPage = 1;
        state.hasMore = true;
        state.calendarReservations = action.payload.calendarReservations;
      });
    //#endregion Get future lessons calendar view

    //#region Get notifications count
    builder
      .addCase(getMyScheduleNotificationsCount.pending, (state) => {
        if (state.loading === LoadingState.Idle) {
          state.notificationsCountLoading = LoadingState.Pending;
        }
      })
      .addCase(getMyScheduleNotificationsCount.rejected, (state, action) => {
        if (state.notificationsCountLoading === LoadingState.Idle) {
          state.notificationsCountLoading = LoadingState.Pending;
          state.error = action.error.message;
        }
      })
      .addCase(getMyScheduleNotificationsCount.fulfilled, (state, action) => {
        if (state.notificationsCountLoading === LoadingState.Pending) {
          state.notificationsCountLoading = LoadingState.Idle;
          state.notificationsCount = action.payload.myScheduleNotificationsCount;
        }
      });
    //#endregion Getn notifications count
    builder
      .addCase(approveReservation.pending, sharedPendingReducer)
      .addCase(approveReservation.rejected, sharedRejectedReducer)
      .addCase(approveReservation.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.calendarReservations = produce(state.calendarReservations, (draft) => {
          const idx = draft.findIndex((x) => x.reservationID === action.payload.id);
          if (idx !== -1) {
            if (
              (action.payload.reservationStatusID === ReservationStatusID.Rejected ||
                action.payload.reservationStatusID === ReservationStatusID.Canceled) &&
              draft[idx].isTutor
            ) {
              draft[idx].reservationID = null;
              draft[idx].reservationCourseID = null;
              draft[idx].reservationCourseName = null;
              draft[idx].reservationStudentID = null;
              draft[idx].participantIdentityID = null;
              draft[idx].participantName = null;
              draft[idx].participantProfilePicturePath = null;
              draft[idx].reservationStatusID = null;
              draft[idx].lessonID = null;
              draft[idx].timeSlotStatusID = TimeSlotStatusID.Available;
            } else {
              draft[idx].reservationStatusID = action.payload.reservationStatusID;
              draft[idx].timeSlotStatusID = action.payload.timeSlotStatusID;
              draft[idx].lessonID = action.payload.lessonID;
            }
          }
        });

        state.futureLessons = produce(state.futureLessons, (draft) => {
          const idx = draft.findIndex((x) => x.id === action.payload.id);
          if (idx !== -1) {
            if (
              (action.payload.reservationStatusID === ReservationStatusID.Rejected ||
                action.payload.reservationStatusID === ReservationStatusID.Canceled) &&
              draft[idx].isTutor
            ) {
              draft.splice(idx, 1);
            } else {
              draft[idx].reservationStatusID = action.payload.reservationStatusID;
              draft[idx].lessonID = action.payload.lessonID;
            }
          }
        });
      });

    builder
      .addCase(getScheduleCalendarEditData.pending, sharedPendingReducer)
      .addCase(getScheduleCalendarEditData.rejected, sharedRejectedReducer)
      .addCase(getScheduleCalendarEditData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.duration = action.payload.duration;
        state.reservationBufferHours = action.payload.reservationBufferHours;
        state.canChangeCourseDuration = action.payload.canChangeCourseDuration;
        state.canUseLegacyCourseDuration = action.payload.canUseLegacyCourseDuration;
        state.timeSlots = action.payload.timeSlots;
        state.recurrenceTypes = action.payload.recurrenceTypes;
        state.blockNewStudentsReservations = action.payload.hasBlockedNewStudentsReservations ? 1 : 0;
      });

    builder.addCase(getUpcomingLessons.fulfilled, (state, action) => {
      state.upcomingLessons = action.payload.upcomingLessons;
    });

    builder
      .addCase(getHolidays.pending, sharedPendingReducer)
      .addCase(getHolidays.rejected, sharedRejectedReducer)
      .addCase(getHolidays.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.holidays = action.payload.holidays.map((holidayTimeSlots) => {
          return {
            id: holidayTimeSlots.id,
            title: holidayTimeSlots.name,
            start: new Date(holidayTimeSlots.date),
            allDay: true,
            recurrenceTypeID: RecurrenceTypeID.Never,
            disable: true,
            extendedProps: {
              isHoliday: true,
            },
          };
        });
      });
  },
});

export default myScheduleSlice;
