import './Skeleton.scss';

type SkeletonProps = {
  width?: number | null;
  height?: number | null;
};

export function Skeleton({ width = null, height = null }: SkeletonProps) {
  return (
    <div
      className="skeleton-pulse rounded"
      style={{
        width: width ? `${height}px` : 'auto',
        height: height ? `${height}px` : 'auto',
      }}
    />
  );
}
