import { GoBackToDashboardLinkButton } from '@/app/modules/errors/ErrorBasePage/GoBackToDashboardLinkButton';
import { useAppSelector } from '@/redux/store';

import { ErrorBasePage } from './ErrorBasePage/ErrorBasePage';

export function ForbiddenPage() {
  const errorMessage = useAppSelector((state) => state.errors.errorMessage);

  return (
    <ErrorBasePage code={403} actions={<GoBackToDashboardLinkButton />}>
      {errorMessage ?? 'Нямате права да достъпвате тази страница.'}
    </ErrorBasePage>
  );
}
