import { FileRejection, useDropzone } from 'react-dropzone';
import cuid from 'cuid';
import { useFormikContext } from 'formik';
import Toastr from 'toastr';

import { createFileControlValue, DropzoneControlState, FileControlValue } from '../models';
import { reorderFiles } from '../MultipleFileControl/utils';

type DropzoneControlOptions = {
  name: string;
  accept?: string;
  maxFiles?: number;
  maxSize?: number;
  multiple?: boolean;
  isReadOnly?: boolean;
  group?: string | null;
  isOnlyFile?: boolean;
};

export function useDropzoneControl({
  name,
  accept = '.pdf, .png, .jpg, .jpeg',
  maxFiles = 10,
  maxSize = 5242880, // 5MB
  multiple = true,
  isReadOnly = false,
  group = null,
  isOnlyFile = false,
}: DropzoneControlOptions): DropzoneControlState {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<FileControlValue[]>(name);
  const fieldHelpers = formik.getFieldHelpers<FileControlValue[]>(name);

  //#region DROPZONE STATE
  function handleDropAccepted(acceptedFiles: File[]) {
    // Sanity check.
    if (isReadOnly || acceptedFiles.length === 0) {
      return;
    }

    // STEP 2: Max files limit validation.
    let allFilesLength = acceptedFiles.length;

    if (multiple) {
      allFilesLength += field.value?.length ?? 0;
    }

    if (allFilesLength > maxFiles) {
      const fileNames = acceptedFiles.map((file) => file.name);
      for (const fileName of fileNames) {
        const message = `${fileName} не беше добавен поради повишаването на лимита от ${maxFiles} броя.`;
        Toastr.error(message);
      }
      return;
    }

    const rejectedFileNames = [];

    // STEP 3: Handle new accepted files.
    const internalAcceptedFiles = [];

    for (const file of acceptedFiles) {
      // CASE 1: File is too large -> reject it.
      if (file.size > maxSize) {
        rejectedFileNames.push(file.name);
        continue;
      }

      // CASE 2: Everything is okay -> add it as accepted file.
      if (multiple || !isOnlyFile) {
        internalAcceptedFiles.push(createFileControlValue(cuid(), file.name, file, field.value.length, group));
      } else {
        internalAcceptedFiles.push(file);
      }
    }

    if (multiple) {
      const fileControlValue = [...(field.value ?? []), ...internalAcceptedFiles] as FileControlValue[];
      const internalAllFiles = reorderFiles(fileControlValue);
      fieldHelpers.setValue(internalAllFiles);
    } else if (isOnlyFile) {
      fieldHelpers.setValue([internalAcceptedFiles[0]] as FileControlValue[]);
    } else {
      fieldHelpers.setValue(internalAcceptedFiles as FileControlValue[]);
    }

    // STEP 4: Show file is too large error for rejected files.
    if (rejectedFileNames.length > 0) {
      const maxSizeKB = maxSize / 1024;
      for (const rejectedFileName of rejectedFileNames) {
        const message = `${rejectedFileName} не беше добавен поради повишаването на лимита за размер от ${maxSizeKB}KB.`;
        Toastr.error(message);
      }
    }
  }

  function handleDropRejected(fileRejections: FileRejection[]) {
    // Sanity check.
    if (isReadOnly) {
      return;
    }

    const rejectedFileNames = [];

    for (const fileRejection of fileRejections) {
      for (const error of fileRejection.errors) {
        if (error.code === 'file-invalid-type') {
          rejectedFileNames.push(fileRejection.file.name);
        }
      }
    }

    if (rejectedFileNames.length > 0) {
      for (const rejectedFileName of rejectedFileNames) {
        const message = `${rejectedFileName} не беше добавен, защото не e от тип ${accept}.`;
        Toastr.error(message);
      }
    }
  }

  const state = useDropzone({
    accept,
    multiple,
    minSize: 0,
    noClick: true,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
  });
  //#endregion DROPZONE STATE

  return { ...state };
}
