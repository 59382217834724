import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  NotificationUnsubscribeQueryParams,
  ProfileNotificationFormValues,
  ProfileNotificationSetting,
  ProfileNotificationType,
} from '@/app/modules/profile/notifications/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

const ENDPOINT_PREFIX = '/ajax/profile/notifications';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  tutor: `${ENDPOINT_PREFIX}/tutors`,
  unsubscribe: '/ajax/profile/notifications/unsubscribe',
};

//#region PROFILE GENERAL NOTIFICATIONS INDEX
export const getProfileGeneralNotifications = createAsyncThunk(
  'profileNotifications/getProfileGeneralNotifications',
  async function () {
    const response = await axios.get<GetProfileGeneralNotificationsResponse>(ENDPOINTS.resource);
    return response.data;
  }
);

export interface GetProfileGeneralNotificationsResponse {
  types: ProfileNotificationType[];
  settings: ProfileNotificationSetting[];
}

//#endregion PROFILE GENERAL NOTIFICATIONS INDEX

//#region PROFILE TUTOR NOTIFICATIONS INDEX
export const getProfileTutorNotifications = createAsyncThunk(
  'profileNotifications/getProfileTutorNotifications',
  async function () {
    const response = await axios.get<GetProfileTutorNotificationsResponse>(ENDPOINTS.tutor);
    return response.data;
  }
);

export interface GetProfileTutorNotificationsResponse {
  types: ProfileNotificationType[];
  settings: ProfileNotificationSetting[];
}

//#endregion PROFILE TUTOR NOTIFICATIONS INDEX

//#region UPDATE NOTIFICATION SETTING
export async function updateProfileNotificationSetting(values: ProfileNotificationFormValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.resource, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

//#endregion UPDATE NOTIFICATION SETTING

//#region GET NOTIFICATION UNSUBSCRIBE DATA
export const getNotificationUnsubscribeData = createAsyncThunk(
  'profileNotifications/getNotificationUnsubscribeData',
  async function (queryParams: NotificationUnsubscribeQueryParams) {
    const response = await axios.get<GetNotificationUnsubscribeData>(ENDPOINTS.unsubscribe, {
      params: queryParams,
    });
    return response.data;
  }
);

export interface GetNotificationUnsubscribeData {
  identityEmail: string;
  notificationTypeName: string;
  notificationTypeChannelID: number;
}

//#endregion GET NOTIFICATION UNSUBSCRIBE DATA
