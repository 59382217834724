import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { LoadingState } from '@/redux/constants';

export function useLoading(
  loadingState: LoadingState,
  isPreloaded = false
): [LoadingState, boolean, Dispatch<SetStateAction<LoadingState>>, boolean] {
  const previousStoreLoadingState = useRef(loadingState);
  const [internalLoadingState, setInternalLoadingState] = useState(
    isPreloaded ? LoadingState.Idle : LoadingState.Pending
  );
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(isPreloaded);
  const [canRefetch, setCanRefetch] = useState(!isPreloaded);

  useEffect(
    function handleLoadingState() {
      // Make sure to set LoadingState.Idle only if the previous store LoadingState was Pending.
      if (previousStoreLoadingState.current === LoadingState.Pending && loadingState === LoadingState.Idle) {
        setInternalLoadingState(LoadingState.Idle);
      }

      if (loadingState === LoadingState.Pending) {
        setInternalLoadingState(LoadingState.Pending);
      }
    },
    [loadingState]
  );

  useEffect(
    function handleInitialDataLoadedState() {
      if (
        previousStoreLoadingState.current === LoadingState.Pending &&
        loadingState === LoadingState.Idle &&
        !isInitialDataLoaded
      ) {
        setIsInitialDataLoaded(true);
      }

      previousStoreLoadingState.current = loadingState;
    },
    [isInitialDataLoaded, loadingState]
  );

  useEffect(function handleCanRefetch() {
    setCanRefetch(true);
  }, []);

  return [internalLoadingState, isInitialDataLoaded, setInternalLoadingState, canRefetch];
}
