import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData } from '@stripe/stripe-js';
import Toastr from 'toastr';
import * as yup from 'yup';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { Button } from '@/app/components/Button/Button';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardFooter } from '@/app/components/Card/CardFooter';
import { CardSection } from '@/app/components/Card/CardSection';
import { CardSectionContent } from '@/app/components/Card/CardSectionContent';
import { CardSectionDescription } from '@/app/components/Card/CardSectionDescription';
import { CardSectionTitle } from '@/app/components/Card/CardSectionTitle';
import { CardControl } from '@/app/components/Form/CardControl/CardControl';
import { Form } from '@/app/components/Form/Form';
import { RadioButtonControl } from '@/app/components/Form/RadioButtonControl/RadioButtonControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { CurrentBulgarianTime } from '@/app/modules/analytics/models';
import { ENDPOINTS, trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformEcommerceEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { getAuthData, getAvailableEnrolledCoursesCount } from '@/app/modules/auth/service';
import { getEcommerceItemFromCourseCheckout } from '@/app/modules/course/checkout/utils';
import { RefundPromoCodeModal } from '@/app/modules/course/components/RefundPromoCodeModal';
import { CoursePackageModel } from '@/app/modules/course/create/models';
import courseSlice from '@/app/modules/course/store';
import { Promotion } from '@/app/modules/promotion/constants';
import promotionStore from '@/app/modules/promotion/store';
import { isDiscountPromotion } from '@/app/modules/promotion/utils';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS, FACEBOOK_PIXEL_ECOMMERCE_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagNewCustomerEvent } from '@/app/utils/google-analytics/customEvents';
import {
  tagAddPaymentInfoEvent,
  tagBeginCheckoutEvent,
  tagPurchaseEvent,
  tagRemoveFromCartEvent,
} from '@/app/utils/google-analytics/ecommerceEvents';
import {
  calculatePriceAfterPromoCode,
  calculatePriceWithoutSurcharge,
  formatPrice,
  formatPromoCodeValue,
  isFreeCoursePackageAfterPromoCode,
  singleLessonPriceString,
  singleLessonPriceStringAfterPromoCode,
} from '@/app/utils/money';
import { useQueryParams } from '@/app/utils/query';
import { sleep } from '@/app/utils/sleep';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { CourseDetailsCard } from '../components/CourseDetailsCard';
import { INDIVIDUAL_TYPE_ID, PACKAGE_TYPE_ID } from '../constants';
import { CompleteCheckoutResponse, FreeCourseLessonUsingPromoCodeDataResponse, PromoCodeData } from '../models';
import {
  completeCheckout,
  getCourseCheckoutData,
  getFreeCourseLessonUsingPromoCode,
  GetPaymentIntent,
  getPaymentIntent,
} from '../service';
import { handlePlDeprecatedPurchaseAction } from '../utils';
import {
  DEFAULT_STRIPE_ERROR_CODE_MESSAGE,
  MAXIMUM_LENGTH_PROMOCODE,
  PaymentMethodBrandID,
  STRIPE_ALREADY_CONFIRMED_PAYMENT_METHOD_CODE,
  STRIPE_CARD_ERROR_FIELD_NAME,
  STRIPE_DECLINE_CODE_MESSAGE,
  STRIPE_ERROR_CODE_MESSAGES,
} from './constants';
import { CourseCheckoutPaymentState, CourseCheckoutQueryParams, CourseCheckoutValues } from './models';
import { PromoCodeSection } from './PromoCodeSection';

const NEW_PAYMENT_METHOD_VALUE = -1;

function checkApplicableLessonCounts(applicableLessonCounts: number[] | null, lessonCount: number) {
  if (!applicableLessonCounts || applicableLessonCounts.length === 0) {
    return true;
  }

  return applicableLessonCounts.includes(Number(lessonCount));
}

export function CourseCheckoutForm() {
  const history = useHistory();
  const { transliteratedSlug } = useParams<{ transliteratedSlug: string }>();
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.course);
  const authState = useAppSelector((state) => state.auth);
  const promotionState = useAppSelector((state) => state.promotion);
  const paymentIntentLoading = useAppSelector((state) => state.course.paymentIntentLoading);
  const courseCheckoutData = store.checkout;
  const stripe = useStripe();
  const elements = useElements();
  const { queryParams } = useQueryParams<CourseCheckoutQueryParams>();
  const [isNewPaymentMethodSelected, setIsNewPaymentMethodSelected] = useState<boolean>(false);
  const [isEmptyTutorScheduleModalShown, setIsEmptyTutorScheduleModalShown] = useState<boolean>(false);
  const [isAttachedPromoCode, setIsAttachedPromoCode] = useState<boolean>(false);
  const [promoCodeData, setPromoCodeData] = useState<PromoCodeData | null>(null);
  const canUseDiscount =
    promotionState.canUseLessonPromotion && isDiscountPromotion(promotionState.activePromotionSlug as Promotion);
  const [isConfirmPromoCodeModalShown, setIsConfirmPromoCodeModalShown] = useState<boolean>(false);
  const [generatedPromoCode, setGeneratedPromoCode] = useState<string | null | unknown>(null);
  const [promoCodeRefundAmount, setPromoCodeRefundAmount] = useState<string | null | unknown>(null);
  const [responseOrderID, setResponseOrderID] = useState<number | null>(null);
  const initiatedPayment = useRef<GetPaymentIntent | null>(null);
  const paymentState = useRef<CourseCheckoutPaymentState>(CourseCheckoutPaymentState.paymentIntentNotStarted);

  function handleGeneratedPromoCodeModalOpen() {
    setIsConfirmPromoCodeModalShown(true);
  }

  function handleGeneratedPromoCodeModalClose() {
    setIsConfirmPromoCodeModalShown(false);
    handleRedirect();
  }

  async function handlePaymentIntent(coursePackageID: number, saveCard: boolean, promoCode: string) {
    if (initiatedPayment.current) {
      return initiatedPayment.current;
    }

    await dispatch(courseSlice.actions.changePaymentIntentLoadingState(LoadingState.Pending));
    const intentResponse = await getPaymentIntent(coursePackageID, saveCard, promoCode);
    initiatedPayment.current = intentResponse;

    return intentResponse;
  }

  useEffect(function componentDidMount() {
    if (store.loading === LoadingState.Idle) {
      dispatch(getCourseCheckoutData({ transliteratedSlug, reservationID: queryParams?.reservationID }));
    }
    return function componentDidUnmount() {
      formik.setFieldValue('coursePackageID', undefined);
      dispatch(courseSlice.actions.resetCourseCheckoutPage());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkIsFreeCoursePackage = useCallback(
    (coursePackageID: number) => {
      const coursePackage = courseCheckoutData?.coursePackages.find(
        (coursePackage) => coursePackage.id === Number(coursePackageID)
      );

      return isFreeCoursePackageAfterPromoCode(
        Number(coursePackage?.taxedPrice),
        promoCodeData?.discountType ?? null,
        Number(promoCodeData?.value ?? 0)
      );
    },
    [courseCheckoutData?.coursePackages, promoCodeData?.discountType, promoCodeData?.value]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape(
        {
          coursePackageID: yup.number().required(),
          cardHolderName: yup.string().when(['paymentMethod', 'saveCard', 'coursePackageID'], {
            is: (paymentMethod: number, saveCard: boolean, coursePackageID: number) => {
              if (!saveCard && checkIsFreeCoursePackage(coursePackageID)) {
                return false;
              }

              return Number(paymentMethod) === NEW_PAYMENT_METHOD_VALUE;
            },
            then: yup.string().required(),
          }),
          [STRIPE_CARD_ERROR_FIELD_NAME]: yup.object().when(['paymentMethod', 'saveCard', 'coursePackageID'], {
            is: (paymentMethod: number, saveCard: boolean, coursePackageID: number) => {
              if (!saveCard && checkIsFreeCoursePackage(coursePackageID)) {
                return false;
              }

              return Number(paymentMethod) === NEW_PAYMENT_METHOD_VALUE;
            },
            then: yup.object().shape({
              cardNumber: yup.mixed().cardNumber(elements),
              cardExpiry: yup.mixed().cardExpiry(elements),
              cardCVC: yup.mixed().cardCVC(elements),
            }),
          }),
          promoCode: yup
            .string()
            .notRequired()
            .when('promoCode', {
              is: (value: string) => value?.length,
              then: (rule) => rule.max(MAXIMUM_LENGTH_PROMOCODE),
            }),
        },
        [['promoCode', 'promoCode']]
      ),
    [checkIsFreeCoursePackage, elements]
  );

  async function handleSubmit(values: CourseCheckoutValues) {
    // Sanity check.
    if (!stripe || !elements) {
      return;
    }

    if (paymentState.current === CourseCheckoutPaymentState.paymentIntentNotStarted) {
      paymentState.current = CourseCheckoutPaymentState.paymentIntentInitiated;
    }

    // STEP 1: Find selected CoursePackage.
    const coursePackage = courseCheckoutData?.coursePackages.find(
      (coursePackage) => coursePackage.id === Number(values.coursePackageID)
    );

    // STEP 2: Handle analytics for payment info.
    const ecommerceItem = getEcommerceItemFromCourseCheckout(
      courseCheckoutData,
      coursePackage as CoursePackageModel,
      canUseDiscount,
      promotionState.discount as number
    );
    const paymentConversionEventValues = await transformEcommerceEventToConversionEventValues(
      FACEBOOK_PIXEL_ECOMMERCE_EVENTS.addPaymentInfo,
      ecommerceItem
    );
    tagAddPaymentInfoEvent(ecommerceItem, paymentConversionEventValues.eventID);
    await trackAnalyticsEventConversionsAPI(paymentConversionEventValues);

    let intentResponse = initiatedPayment.current;

    // STEP 3: Create PaymentIntent.
    if (paymentState.current < CourseCheckoutPaymentState.paymentIntentSucceeded) {
      intentResponse = await handlePaymentIntent(values.coursePackageID, Boolean(values.saveCard), values.promoCode);

      paymentState.current = CourseCheckoutPaymentState.paymentIntentSucceeded;
    }

    // STEP 4: Get PaymentMethod.
    const cardElement = elements.getElement(CardNumberElement);
    const isNewCard = Number(values.paymentMethod) === NEW_PAYMENT_METHOD_VALUE;

    let paymentMethod: string | Omit<CreatePaymentMethodCardData, 'type'> | undefined = undefined;

    if (cardElement) {
      paymentMethod = { card: cardElement };
    }

    if (hasPaymentMethods && !isNewCard) {
      const selectedPaymentMethod = courseCheckoutData.paymentMethods.find(
        (paymentMethod) => paymentMethod.id === Number(values.paymentMethod)
      );

      paymentMethod = selectedPaymentMethod?.stripeID;
    }

    // STEP 5: Payment.
    let paymentMethodID;
    let confirmPaymentResponse;

    // CASE 5.1: Student needs to pay -> confirm card payment.
    if (intentResponse?.paymentIntent) {
      confirmPaymentResponse = await stripe.confirmCardPayment(intentResponse?.paymentIntent?.client_secret, {
        payment_method: paymentMethod,
      });

      if (
        confirmPaymentResponse.error &&
        confirmPaymentResponse.error.code !== STRIPE_ALREADY_CONFIRMED_PAYMENT_METHOD_CODE
      ) {
        const errorMessage = `${
          STRIPE_ERROR_CODE_MESSAGES[confirmPaymentResponse.error.code as string] ?? DEFAULT_STRIPE_ERROR_CODE_MESSAGE
        } ${STRIPE_DECLINE_CODE_MESSAGE[confirmPaymentResponse.error.decline_code as string] ?? ''}`;
        Toastr.error(errorMessage);
        initiatedPayment.current = null;
        paymentState.current = CourseCheckoutPaymentState.paymentIntentNotStarted;
        dispatch(courseSlice.actions.changePaymentIntentLoadingState(LoadingState.Idle));
      }

      paymentState.current = CourseCheckoutPaymentState.confirmPaymentSucceeded;
    }
    // CASE 5.2: No need to pay but student still wants to persist PaymentMethod.
    else if (
      !intentResponse?.paymentIntent &&
      cardElement &&
      (values.saveCard ||
        !isFreeCoursePackageAfterPromoCode(
          Number(coursePackage?.taxedPrice),
          promoCodeData?.discountType ?? null,
          Number(promoCodeData?.value ?? 0)
        ))
    ) {
      const createPaymentMethodResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: values.cardHolderName,
        },
      });

      if (createPaymentMethodResponse.error?.message) {
        Toastr.error(createPaymentMethodResponse.error.message);
      }

      paymentMethodID = createPaymentMethodResponse.paymentMethod?.id;
    }

    // STEP 6: Complete checkout.
    const response: CompleteCheckoutResponse | FreeCourseLessonUsingPromoCodeDataResponse =
      intentResponse?.paymentIntent
        ? await completeCheckout(
            values.coursePackageID,
            intentResponse?.paymentIntent.id,
            values.cardHolderName,
            isNewCard,
            values.promoCode
          )
        : await getFreeCourseLessonUsingPromoCode(
            values.coursePackageID,
            values.promoCode,
            values.cardHolderName,
            Boolean(values.saveCard),
            isNewCard,
            paymentMethodID
          );

    // Sanity check -> don't continue if there is payment error.
    if (
      intentResponse?.paymentIntent &&
      confirmPaymentResponse?.error &&
      confirmPaymentResponse?.error.code !== STRIPE_ALREADY_CONFIRMED_PAYMENT_METHOD_CODE
    ) {
      initiatedPayment.current = null;
      paymentState.current = CourseCheckoutPaymentState.paymentIntentNotStarted;
      dispatch(courseSlice.actions.changePaymentIntentLoadingState(LoadingState.Idle));
      return;
    }

    paymentState.current = CourseCheckoutPaymentState.confirmCheckoutSucceeded;

    // STEP 7: Handle analytics for purchase.
    const purchaseConversionEventValues = await transformEcommerceEventToConversionEventValues(
      FACEBOOK_PIXEL_ECOMMERCE_EVENTS.purchase,
      ecommerceItem
    );
    tagPurchaseEvent(ecommerceItem, response.orderID, purchaseConversionEventValues.eventID);
    await trackAnalyticsEventConversionsAPI(purchaseConversionEventValues);

    if (response.isStudentsFirstOrder) {
      const newCustomerConversionEventValues = await transformEcommerceEventToConversionEventValues(
        FACEBOOK_PIXEL_CUSTOM_EVENTS.newCustomer,
        ecommerceItem
      );
      tagNewCustomerEvent(ecommerceItem, response.orderID, newCustomerConversionEventValues.eventID);
      await trackAnalyticsEventConversionsAPI(newCustomerConversionEventValues);
    }

    // STEP 8: Update store for the new Student and promotion data.
    await dispatch(promotionStore.actions.setCanUseLessonPromotion(response.canUseLessonPromotion));
    await dispatch(getAvailableEnrolledCoursesCount());

    if (!authState.studentID) {
      dispatch(getAuthData());
    }

    // STEP 9: Show RefundPromoCodeModal if needed.
    setResponseOrderID(response.orderID);
    initiatedPayment.current = null;
    paymentState.current = CourseCheckoutPaymentState.paymentIntentNotStarted;
    dispatch(courseSlice.actions.changePaymentIntentLoadingState(LoadingState.Idle));
    if (
      (response as FreeCourseLessonUsingPromoCodeDataResponse).promoCodeRefundAmount &&
      (response as FreeCourseLessonUsingPromoCodeDataResponse).generatedPromoCode
    ) {
      setGeneratedPromoCode((response as FreeCourseLessonUsingPromoCodeDataResponse).generatedPromoCode);
      setPromoCodeRefundAmount((response as FreeCourseLessonUsingPromoCodeDataResponse).promoCodeRefundAmount);
      handleGeneratedPromoCodeModalOpen();
      return;
    }

    // STEP 10: Handle redirects.
    handleRedirect(response.orderID);
  }

  function handleRedirect(orderID?: number) {
    const reservationID = Number(queryParams?.reservationID) ?? null;

    if (reservationID) {
      history.push(`/reservation-proposals/${Number(queryParams.reservationID)}?orderID=${responseOrderID ?? orderID}`);
      return;
    }

    history.push(`/courses/${Number(courseCheckoutData.course.id)}/reservation?orderID=${responseOrderID ?? orderID}`);
  }

  const initialValues = useMemo<CourseCheckoutValues>(() => {
    let coursePackageID = courseCheckoutData?.coursePackages[0]?.id;
    if (
      courseCheckoutData?.coursePackages.find(
        (coursePackage) => coursePackage.id === Number(queryParams.coursePackageID)
      )
    ) {
      coursePackageID = Number(queryParams.coursePackageID);
    }

    return {
      coursePackageID,
      cardHolderName: '',
      paymentMethod:
        courseCheckoutData?.paymentMethods.length > 0
          ? courseCheckoutData?.paymentMethods[0]?.id
          : NEW_PAYMENT_METHOD_VALUE,
      saveCard: 0,
      stripeToken: null,
      promoCode: '',
    };
  }, [courseCheckoutData, queryParams]);

  const formik = useForm<CourseCheckoutValues>({
    initialValues,
    validationSchema,
    onSubmit: handleBackEndValidation(handleSubmit),
  });

  useEffect(() => {
    return history.listen((location) => {
      if (!location.pathname.includes('orders')) {
        const coursePackage = courseCheckoutData?.coursePackages.find(
          (coursePackage) => coursePackage.id === Number(formik.values.coursePackageID)
        );

        tagRemoveFromCartEvent(
          getEcommerceItemFromCourseCheckout(courseCheckoutData, coursePackage as CoursePackageModel)
        );
      }
    });
  }, [history, courseCheckoutData, formik.values.coursePackageID]);

  useEffect(() => {
    async function handleInitiateCheckout() {
      if (
        courseCheckoutData.course.id &&
        courseCheckoutData.coursePackages?.length > 0 &&
        formik.values.coursePackageID
      ) {
        const coursePackage = courseCheckoutData?.coursePackages.find(
          (coursePackage) => coursePackage.id === Number(formik.values.coursePackageID)
        );

        const ecommerceItem = getEcommerceItemFromCourseCheckout(
          courseCheckoutData,
          coursePackage as CoursePackageModel
        );
        const conversionEventValues = await transformEcommerceEventToConversionEventValues(
          FACEBOOK_PIXEL_ECOMMERCE_EVENTS.initiateCheckout,
          ecommerceItem
        );
        tagBeginCheckoutEvent(ecommerceItem, conversionEventValues.eventID);
        trackAnalyticsEventConversionsAPI(conversionEventValues);
      }
    }
    handleInitiateCheckout();
  }, [courseCheckoutData, formik.values.coursePackageID]);

  useEffect(
    function handleSelectedPaymentMethod() {
      setIsNewPaymentMethodSelected(Number(formik.values.paymentMethod) === NEW_PAYMENT_METHOD_VALUE);
    },
    [formik.values.paymentMethod]
  );

  //#region Empty Tutor Schedule Warning Modal
  function handleEmptyTutorScheduleModalClose() {
    setIsEmptyTutorScheduleModalShown(false);
  }

  function handlePurchaseAction() {
    if (store.checkout?.timeSlotsCount === 0) {
      setIsEmptyTutorScheduleModalShown(true);
    }
  }
  //#endregion Empty Tutor Schedule Warning Modal

  const hasPaymentMethods = courseCheckoutData.paymentMethods.length > 0;

  async function handleReconnection() {
    try {
      const response = await fetch(ENDPOINTS.currentBGTime);
      const data = await response.json();
      if ((data as CurrentBulgarianTime).time) {
        await sleep(1000);
        Toastr.success(
          'Успешно възстановяване на връзката! Започнали сте плащане, което ще бъде продължено автоматично! Моля, останете в страницата, докато плащането Ви не бъде завършено успешно!'
        );
        await formik.handleSubmit();
      }
    } catch (err) {
      await handleReconnection();
    }
  }

  function onOffline(e: Event) {
    Toastr.error('Внимание! Връзката Ви с мрежата беше прекъсната!');
  }

  async function onOnline(e: Event) {
    if (paymentState.current === CourseCheckoutPaymentState.paymentIntentNotStarted) {
      await dispatch(courseSlice.actions.changePaymentIntentLoadingState(LoadingState.Idle));

      return;
    }

    if (paymentState.current === CourseCheckoutPaymentState.paymentIntentInitiated) {
      await dispatch(courseSlice.actions.changePaymentIntentLoadingState(LoadingState.Idle));
      Toastr.success(
        'Успешно възстановяване на връзката! За да довършите плащането, което сте започнали, изберете бутона Закупи!'
      );

      return;
    }

    if (initiatedPayment.current) {
      await handleReconnection();
    }
  }

  useEffect(() => {
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  function handleFormSubmit(e: React.FormEvent) {
    handlePlDeprecatedPurchaseAction(e);
    // handleFrontEndValidations(formik);
  }

  return (
    <>
      <Form formik={formik} disabled={formik.isSubmitting} onSubmit={handleFormSubmit} skipDirtyPrompt>
        <Card>
          <CardBody>
            <CardSection>
              <CardSectionDescription>
                <CardSectionTitle>Обучение</CardSectionTitle>
              </CardSectionDescription>

              <CardSectionContent>
                <CourseDetailsCard course={courseCheckoutData.course} />
              </CardSectionContent>
            </CardSection>

            <CardSection>
              <CardSectionDescription>
                <CardSectionTitle>Пакет</CardSectionTitle>
              </CardSectionDescription>
              <CardSectionContent>
                {courseCheckoutData.coursePackages.map((coursePackage) => (
                  <label
                    className="border rounded d-flex align-items-center mb-4 px-4 cursor-pointer"
                    key={coursePackage.id}
                  >
                    <RadioButtonControl
                      name="coursePackageID"
                      value={coursePackage.id}
                      isInline
                      isDisabled={
                        paymentIntentLoading === LoadingState.Pending ||
                        paymentState.current !== CourseCheckoutPaymentState.paymentIntentNotStarted
                      }
                    />
                    <div className="d-flex justify-content-between align-items-center flex-grow-1 p-4">
                      <div className="d-flex flex-column fw-bolder">
                        <span>
                          {coursePackage.typeID === INDIVIDUAL_TYPE_ID ? 'Индивидуален урок' : coursePackage?.name}
                        </span>{' '}
                        {isAttachedPromoCode &&
                          promoCodeData &&
                          (checkApplicableLessonCounts(
                            promoCodeData.applicableLessonCounts,
                            coursePackage.lessonCount
                          ) ? (
                            <div>
                              <span className="fs-7 fw-light text-gray-400 text-decoration-line-through">
                                {formatPrice(coursePackage.taxedPrice)}
                              </span>
                              <span className="fs-7 fw-light text-gray-400">{` (-${formatPromoCodeValue(
                                Number(promoCodeData.value),
                                promoCodeData.discountType
                              )}) `}</span>
                              <span className="fs-7 fw-bolder text-danger">
                                {calculatePriceAfterPromoCode(
                                  coursePackage.taxedPrice,
                                  promoCodeData.value as number,
                                  promoCodeData.discountType,
                                  Number(coursePackage.lessonCount),
                                  promoCodeData.applicableLessonCounts
                                )}
                              </span>
                            </div>
                          ) : (
                            <span>{formatPrice(coursePackage.taxedPrice)}</span>
                          ))}
                        {!isAttachedPromoCode && (!canUseDiscount || coursePackage.typeID !== INDIVIDUAL_TYPE_ID) && (
                          <span>{formatPrice(coursePackage.taxedPrice)}</span>
                        )}
                        {!isAttachedPromoCode && canUseDiscount && coursePackage.typeID === INDIVIDUAL_TYPE_ID && (
                          <div>
                            <span className="fs-7 fw-light text-gray-400 text-decoration-line-through">
                              {formatPrice(coursePackage.taxedPrice)}
                            </span>
                            <span className="fs-7 fw-light text-gray-400">{` (-${promotionState.discountFormatted}) `}</span>
                            <span className="fs-7 fw-bolder text-danger">
                              {calculatePriceWithoutSurcharge(
                                coursePackage.taxedPrice,
                                promotionState.discount as number
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column text-end">
                        {coursePackage.typeID === INDIVIDUAL_TYPE_ID && '1 урок'}
                        {coursePackage.typeID === PACKAGE_TYPE_ID && (
                          <>
                            <span>{coursePackage.lessonCount} урока</span>
                            <span>
                              (
                              {isAttachedPromoCode && promoCodeData
                                ? singleLessonPriceStringAfterPromoCode(
                                    coursePackage.taxedPrice,
                                    Number(promoCodeData.value),
                                    promoCodeData.discountType,
                                    Number(coursePackage.lessonCount),
                                    promoCodeData.applicableLessonCounts
                                  )
                                : singleLessonPriceString(
                                    coursePackage.taxedPrice,
                                    Number(coursePackage.lessonCount)
                                  )}{' '}
                              на урок)
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                ))}
              </CardSectionContent>
            </CardSection>

            <PromoCodeSection
              name="promoCode"
              isAttachedPromoCode={isAttachedPromoCode}
              setIsAttachedPromoCode={setIsAttachedPromoCode}
              promoCodeData={promoCodeData}
              setPromoCodeData={setPromoCodeData}
              coursePackages={courseCheckoutData.coursePackages}
            />

            <CardSection>
              <CardSectionDescription>
                <CardSectionTitle>Плащане с карта</CardSectionTitle>
              </CardSectionDescription>
              <CardSectionContent>
                {!hasPaymentMethods && (
                  <div className="pt-md-6">
                    <CardControl
                      name={STRIPE_CARD_ERROR_FIELD_NAME}
                      isDisabled={
                        paymentIntentLoading === LoadingState.Pending ||
                        paymentState.current !== CourseCheckoutPaymentState.paymentIntentNotStarted
                      }
                    />
                  </div>
                )}
                {hasPaymentMethods &&
                  courseCheckoutData.paymentMethods.map((paymentMethod) => (
                    <label
                      className="border rounded d-flex align-items-center mb-4 px-4 cursor-pointer"
                      key={paymentMethod.id}
                    >
                      <RadioButtonControl
                        name="paymentMethod"
                        value={paymentMethod.id}
                        isInline
                        isDisabled={
                          paymentIntentLoading === LoadingState.Pending ||
                          paymentState.current !== CourseCheckoutPaymentState.paymentIntentNotStarted
                        }
                      />
                      <div className="d-flex justify-content-between align-items-center flex-grow-1 p-4">
                        <div className="d-flex align-items-center">
                          <span>
                            {paymentMethod.brandID === PaymentMethodBrandID.VISA && (
                              <SVG className="w-45px h-45px" src={toAbsoluteUrl('/img/svg/visa_brand_logo.svg')} />
                            )}
                            {paymentMethod.brandID === PaymentMethodBrandID.MASTERCARD && (
                              <SVG
                                className="w-45px h-45px"
                                src={toAbsoluteUrl('/img/svg/mastercard_brand_logo.svg')}
                              />
                            )}
                          </span>
                          <div className="d-flex flex-column ms-2">
                            <span>**** {paymentMethod.lastFourDigits}</span>
                            <span>
                              {paymentMethod.expiryMonth}/{paymentMethod.expiryYear}
                            </span>
                          </div>
                        </div>
                        <div className="fw-bolder">{paymentMethod.holderName}</div>
                      </div>
                    </label>
                  ))}
                {hasPaymentMethods && (
                  <div className="border rounded px-4 w-100">
                    <label className="d-flex align-items-center cursor-pointer">
                      <RadioButtonControl
                        name="paymentMethod"
                        value={NEW_PAYMENT_METHOD_VALUE}
                        isInline
                        isDisabled={
                          paymentIntentLoading === LoadingState.Pending ||
                          paymentState.current !== CourseCheckoutPaymentState.paymentIntentNotStarted
                        }
                      />
                      <div className="d-flex justify-content-between align-items-center flex-grow-1 fw-bold p-4">
                        Друга карта
                      </div>
                    </label>

                    {isNewPaymentMethodSelected && (
                      <div className="my-4">
                        <CardControl
                          name={STRIPE_CARD_ERROR_FIELD_NAME}
                          isDisabled={
                            paymentIntentLoading === LoadingState.Pending ||
                            paymentState.current !== CourseCheckoutPaymentState.paymentIntentNotStarted
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </CardSectionContent>
            </CardSection>
          </CardBody>

          <CardFooter className="d-flex justify-content-end gap-3">
            {/* EmptyTutorScheduleWarningModal related code - comment in case it is needed again in the future
            store.checkout?.timeSlotsCount === 0 && (
              <Button onClick={handlePurchaseAction} isLoading={formik.isSubmitting}>
                Закупи
              </Button>
            )}
            {store.checkout?.timeSlotsCount > 0 && <SaveButton>Закупи</SaveButton>*/}
            <Button type="submit" isLoading={formik.isSubmitting || paymentIntentLoading === LoadingState.Pending}>
              Закупи
            </Button>
          </CardFooter>
        </Card>
        {/*<EmptyTutorScheduleWarningModal
          isShown={isEmptyTutorScheduleModalShown}
          tutorIdentityID={courseCheckoutData.course.tutorIdentityID}
          onClose={handleEmptyTutorScheduleModalClose}
          onConfirm={handleFrontEndValidations(formik)}
          shouldSubmit={true}
        />*/}
      </Form>

      <RefundPromoCodeModal
        isModalShown={isConfirmPromoCodeModalShown}
        onModalClose={handleGeneratedPromoCodeModalClose}
        promoCodeRefundAmount={promoCodeRefundAmount}
        generatedPromoCode={generatedPromoCode}
      />
    </>
  );
}
