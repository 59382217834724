import { Form, FormControlProps as BaseFormControlProps } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import { BsPrefixRefForwardingComponent } from 'react-bootstrap/helpers'; // eslint-disable-line import/no-unresolved
import clsx from 'clsx';
import { useFormikContext } from 'formik';

import { LoadingState } from '@/redux/constants';

type TextareaControlProps = BaseFormControlProps & {
  name: string;
  hasSolidBackground?: boolean;
};

export const TextareaControl: BsPrefixRefForwardingComponent<'textarea', TextareaControlProps> = ({
  name,
  hasSolidBackground = true,
  disabled,
  ...props
}: TextareaControlProps) => {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<string>(name);
  const fieldProps = formik.getFieldProps<string>(name);
  const isInvalid = Boolean(field.error);

  return (
    <>
      <Form.Control
        {...props}
        {...fieldProps}
        name={name}
        as="textarea"
        value={field.value ?? ''}
        className={clsx('form-control-lg', { 'form-control-solid': hasSolidBackground, 'is-invalid': isInvalid })}
        disabled={disabled || formik.status === LoadingState.Pending}
      />

      {isInvalid && <Feedback type="invalid">{field.error}</Feedback>}
    </>
  );
};
