import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { Card } from '@/app/components/Card/Card';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { getProfileCourses } from '@/app/modules/profile/course/service';
import { formatInfoRating } from '@/app/utils/formatInfoRating';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { MAX_RATING } from '../../course/constants';
import { StickyHeader } from '../StickyHeader';

export function ProfileCourseIndexPage() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.profileCourse.loading);
  const courses = useAppSelector((state) => state.profileCourse.courses);

  useEffect(() => {
    if (loading === LoadingState.Idle) {
      dispatch(getProfileCourses());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>Обучения</title>
      </Helmet>
      <StickyHeader className="py-5">
        <PageTop>
          <PageTitle className="fw-bolder fs-2x text-center">Обучения</PageTitle>
          <PageActions>
            <LinkButton to="/courses/create" variant="primary" className="ms-auto">
              Създай обучение
            </LinkButton>
          </PageActions>
        </PageTop>
      </StickyHeader>

      {courses?.length === 0 && (
        <h2 className="mt-15 d-flex justify-content-center">Все още нямате създадени обучения.</h2>
      )}

      {courses?.map((course, idx) => (
        <Card
          key={course.id}
          className={clsx(
            'p-8 shadow-sm d-flex flex-column flex-sm-row align-items-sm-center justify-content-between',
            { 'mb-5': idx < courses?.length - 1 }
          )}
        >
          <div className="me-sm-5">
            <div className="d-flex justify-content-center justify-content-sm-start align-items-center flex-wrap mb-5">
              <span className="me-2 h2 my-0 overflow-hidden text-break">{course.name}</span>
              <span>{course.status}</span>
            </div>
            <div className="d-flex justify-content-center justify-content-sm-start flex-wrap m-n1">
              <span className="badge bg-gray-200 text-gray-800 fs-7 fw-normal px-3 py-2 m-1 text-wrap">
                {formatInfoRating(course.rating)} / {MAX_RATING}
              </span>
              <span className="badge bg-gray-200 text-gray-800 fs-7 fw-normal px-3 py-2 m-1 text-wrap">
                {course.targetAudienceName}
              </span>
              {course?.subjects?.map((subject) => (
                <span
                  key={subject.name}
                  className="badge bg-gray-200 text-gray-800 fs-7 fw-normal px-3 py-2 m-1 text-wrap"
                >
                  {subject.name}
                </span>
              ))}
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center justify-content-sm-end flex-nowrap mt-5 mt-sm-0 me-n2 mb-n2">
            <LinkButton to={`/profile/courses/${course.id}`} className="me-2 mb-2" variant="light-primary">
              Виж
            </LinkButton>
            <LinkButton to={`/profile/courses/${course.id}/edit`} className="me-2 mb-2" variant="primary">
              Редактирай
            </LinkButton>
          </div>
        </Card>
      ))}
    </>
  );
}
