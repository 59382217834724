import { Route, RouteProps, useLocation } from 'react-router-dom';

import { EMAIL_NOTIFICATION_QUERY_PARAMETER } from '@/app/modules/auth/constants';
import { useAppSelector } from '@/redux/store';

import { AuthenticationRequiredModal } from './AuthenticationRequiredModal';

type PrivateRouteProps = RouteProps;

export function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const queryEmail = queryParams.get(EMAIL_NOTIFICATION_QUERY_PARAMETER);

  return (
    <Route
      {...rest}
      render={() => {
        if (!isAuthenticated && !queryEmail) {
          return (
            <>
              {children}
              <AuthenticationRequiredModal />
            </>
          );
        }

        return children;
      }}
    />
  );
}
