import produce from 'immer';

import { DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE, DEFAULT_PAGE_VALUE } from '@/app/modules/profile/payments/constants';
import { PaymentIndexFilterQueryParams, PaymentIndexFilterValues } from '@/app/modules/profile/payments/models';
import { stringifyQuery } from '@/app/utils/query';

export function transformPaymentIndexFormikValuesToSearchParams(
  formikValues: PaymentIndexFilterValues,
  initialItemsPerPage: number,
  queryParams: PaymentIndexFilterQueryParams
) {
  const newQueryParams: PaymentIndexFilterQueryParams = produce(queryParams, (draft) => {
    if (Number(formikValues.page) !== DEFAULT_PAGE_VALUE) {
      draft.page = formikValues.page;
    } else {
      delete draft.page;
    }
    if (Number(formikValues.itemsPerPage) !== DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE) {
      draft.itemsPerPage = formikValues.itemsPerPage;
    } else {
      delete draft.itemsPerPage;
    }

    //If items per page is changed, go to first page to prevent pagination bugs
    if (Number(formikValues.itemsPerPage) !== Number(initialItemsPerPage)) {
      delete draft.page;
    }
  });

  return stringifyQuery(newQueryParams);
}
