import React, { MouseEventHandler, useCallback, useState } from 'react';

import { HeaderMenuContentItem } from '@/app/layout/HeaderMenu/HeaderMenuContentItem';
import { HeaderMenuContentItemBody } from '@/app/layout/HeaderMenu/HeaderMenuContentItemBody';
import { HeaderMenuContentItemIcon } from '@/app/layout/HeaderMenu/HeaderMenuContentItemIcon';
import { HeaderMenuContentItemText } from '@/app/layout/HeaderMenu/HeaderMenuContentItemText';
import { HeaderMenuContentItemTime } from '@/app/layout/HeaderMenu/HeaderMenuContentItemTime';
import { LessonFeedbackModal } from '@/app/modules/lesson-feedback/LessonFeedbackAddModal';

import { TutorLessonFeedbackReminderNotification } from './models';
import { NotificationSystemIcon } from './NotificationSystemIcon';

type LessonFeedbackReminderNotificationProps = {
  notification: TutorLessonFeedbackReminderNotification;
  onClick: MouseEventHandler;
};

export function LessonFeedbackReminderNotification({ notification, onClick }: LessonFeedbackReminderNotificationProps) {
  const [isModalShown, setIsModalShown] = useState(false);

  const handleLessonFeedbackNotificationClick = useCallback((e: React.MouseEvent) => {
    onClick(e);
    setIsModalShown(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleLessonFeedbackModalClose() {
    setIsModalShown(false);
  }

  return (
    <>
      <HeaderMenuContentItem
        to=""
        createdAt={notification.createdAt}
        isRead={Boolean(notification.isRead)}
        onClick={handleLessonFeedbackNotificationClick}
      >
        <HeaderMenuContentItemIcon>
          <NotificationSystemIcon />
        </HeaderMenuContentItemIcon>
        <HeaderMenuContentItemBody>
          <HeaderMenuContentItemText>{notification.data.text}</HeaderMenuContentItemText>
          <HeaderMenuContentItemTime createdAt={notification.createdAt} />
        </HeaderMenuContentItemBody>
      </HeaderMenuContentItem>
      <LessonFeedbackModal
        isShown={isModalShown}
        notification={notification}
        onClose={handleLessonFeedbackModalClose}
      />
    </>
  );
}
