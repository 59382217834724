// eslint-disable-next-line simple-import-sort/imports
import './fonts/fonts.scss';
import './main.scss';

import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from '@/app/App';
import { GlobalDragging } from '@/app/modules/drag/GlobalDragging';
import { createStore } from '@/redux/store';

import { LayoutProvider, LayoutSplashScreen } from './_metronic/layout/core';

import './app/utils/setupValidator';
import './app/utils/setupSentry';
import './app/utils/setupToastr';

const store = createStore(window.__PRELOADED_STATE__);

const container = document.getElementById('root');
const element = (
  <Provider store={store}>
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <GlobalDragging>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GlobalDragging>
      </LayoutProvider>
    </Suspense>
  </Provider>
);

if (window.__PRELOADED_STATE__) {
  ReactDOM.hydrate(element, container);
} else {
  ReactDOM.render(element, container);
}

delete window.__PRELOADED_STATE__; // Allow the passed state to be garbage-collected
