import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { MultipleFileControl } from '@/app/components/Form/MultipleFileControl/MultipleFileControl';
import { TextareaControl } from '@/app/components/Form/TextareaControl/TextareaControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { LessonFeedbackFormValues } from '@/app/modules/lesson/models';
import { createLessonFeedback } from '@/app/modules/lesson/service';
import { LESSON_FEEDBACK_PLACEHOLDER } from '@/app/modules/lesson-feedback/constants';
import { TutorLessonFeedbackReminderNotification } from '@/app/modules/notification/models';

type LessonFeedbackModalProps = {
  notification: TutorLessonFeedbackReminderNotification;
  onClose: () => void;
  isShown: boolean;
};

export function LessonFeedbackModal({ notification, onClose, isShown = false }: LessonFeedbackModalProps) {
  //#region Handle form
  const initialValues = useMemo<LessonFeedbackFormValues>(() => {
    return {
      comment: '',
      attachments: [],
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        comment: yup.string().required().max(10000),
        attachments: yup.array().optional(),
      }),
    []
  );

  const formik = useForm<LessonFeedbackFormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<LessonFeedbackFormValues>(async (values) => {
        return await createLessonFeedback(Number(notification.data.lessonID), {
          comment: values.comment,
          attachments: values.attachments,
        });
      });

      const response = await submitWithValidation(values, formikHelpers);

      if (response) {
        handleModalClose();
      }
    },
  });

  //#endregion Handle form

  function handleModalClose() {
    formik.resetForm();
    onClose();
  }

  return (
    <Modal show={isShown} onHide={handleModalClose}>
      <Card>
        <CardBody>
          <div className="mb-4 text-center">
            На{' '}
            <strong>
              {notification.data.date} от {notification.data.time}ч.
            </strong>{' '}
            проведохте урок по <strong>{notification.data.courseName}</strong> с обучаем{' '}
            <strong>{notification.data.connectedStudentName}</strong>. Моля, оставете коментар.
          </div>
          <Form formik={formik} onSubmit={handleFrontEndValidations(formik)} disabled={formik.isSubmitting}>
            <FormGroup className="mb-4" controlId="comment">
              <Label isRequired>Вашият коментар</Label>
              <TextareaControl
                hasSolidBackground={false}
                name="comment"
                placeholder={LESSON_FEEDBACK_PLACEHOLDER}
                rows={10}
              />
            </FormGroup>
            <FormGroup controlId="attachments">
              <Label>Файлове</Label>
              <MultipleFileControl name="attachments" accept=".pdf, .png, .jpg, .jpeg, .doc, .docx" />
            </FormGroup>
            <div className="d-flex justify-content-center">
              <Button variant="secondary" onClick={handleModalClose}>
                Откажи
              </Button>
              <Button variant="primary" type="submit" className="ms-4" isLoading={formik.isSubmitting}>
                Изпрати
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
}
