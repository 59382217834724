import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Toastr from 'toastr';

import { CancelButton } from '@/app/components/Button/CancelButton';
import { SaveButton } from '@/app/components/Button/SaveButton';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { Form } from '@/app/components/Form/Form';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { GoBackLink } from '@/app/components/Page/GoBackLink/GoBackLink';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { ConnectedStudentForm } from '@/app/modules/profile/connected-student/ConnectedStudentForm';
import { ConnectedStudentValues } from '@/app/modules/profile/connected-student/models';
import {
  createProfileConnectedStudent,
  getProfileConnectedStudentCreateData,
} from '@/app/modules/profile/connected-student/service';
import { useConnectedStudentValidationSchema } from '@/app/modules/profile/connected-student/useConnectedStudentValidationSchema';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

import { StickyHeader } from '../StickyHeader';

export function ConnectedStudentCreatePage() {
  const content = useAppSelector((state) => state.profileConnectedStudent);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(function componentDidMount() {
    if (content.loading === LoadingState.Idle) {
      dispatch(getProfileConnectedStudentCreateData());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //#region Handle form
  const initialValues = useMemo<ConnectedStudentValues>(
    () => ({
      name: content.connectedStudent.name ?? '',
      phone: content.connectedStudent.phone ?? '',
      email: content.connectedStudent.email ?? '',
      connectedStudentLevelID: content.connectedStudent.connectedStudentLevelID ?? null,
    }),
    [content.connectedStudent]
  );

  const validationSchema = useConnectedStudentValidationSchema();

  const formik = useForm<ConnectedStudentValues>({
    initialValues,
    validationSchema,
    initialStatus: content.loading,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<ConnectedStudentValues>(async (values) => {
        return await createProfileConnectedStudent(values);
      });
      const response = await submitWithValidation(values, formikHelpers);
      if (response) {
        Toastr.success('Успешно запазихте промените!');
        formik.resetForm({ values });
        history.push('/profile/connected-students');
      }
    },
  });
  //#endregion Handle form

  return (
    <Form formik={formik} disabled={formik.isSubmitting} onSubmit={handleFrontEndValidations(formik)}>
      <StickyHeader className="py-5" containerClassName="mb-0">
        <PageTop>
          <GoBackLink to="/profile/connected-students">Назад към всички обучаеми</GoBackLink>
          <PageTitle className="my-2 fw-bolder fs-2x text-center">Добави обучаем</PageTitle>
          <PageActions>
            <CancelButton to="/profile/connected-students" />
            <SaveButton>Добави</SaveButton>
          </PageActions>
        </PageTop>
      </StickyHeader>
      <Card>
        <CardBody>
          <ConnectedStudentForm />
        </CardBody>
      </Card>
    </Form>
  );
}
