import { BuyLessonStepNumber } from './models';

export function calculateProgressValue(buyLessonStep: number) {
  if (buyLessonStep === BuyLessonStepNumber.TargetAudience) {
    return 50;
  }

  if (buyLessonStep === BuyLessonStepNumber.Course) {
    return 100;
  }

  return 0;
}
