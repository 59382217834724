import { ReactNode } from 'react';
import clsx from 'clsx';

type PageTopProps = {
  children: ReactNode;
  className?: string;
  isVertical?: boolean;
};

export function PageTop({ children, className, isVertical = false }: PageTopProps) {
  return (
    <div
      className={clsx(
        'page-top d-flex flex-wrap',
        {
          'flex-column align-items-start': isVertical,
          'flex-row align-items-center justify-content-between': !isVertical,
        },
        className
      )}
    >
      {children}
    </div>
  );
}
