import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { EditableTimeSlotEntity } from '@/app/components/Calendar/models';
import {
  MyScheduleFilterValues,
  Reservation,
  ReservationTimeSlotEntity,
  UpcomingLesson,
} from '@/app/modules/my-schedule/models';

const ENDPOINT_PREFIX = '/ajax/my-schedule';

export const ENDPOINTS = {
  view: {
    list: ENDPOINT_PREFIX,
    calendar: `${ENDPOINT_PREFIX}/calendar`,
  },
  edit: {
    calendar: `${ENDPOINT_PREFIX}/calendar/edit`,
  },
  upcoming: `${ENDPOINT_PREFIX}/lessons`,
  notificationsCount: `${ENDPOINT_PREFIX}/notifications-count`,
};

export interface FutureLessonsPayload {
  page?: number;
  filters?: MyScheduleFilterValues;
  startDate?: string | null;
  endDate?: string | null;
}

//region Get future lessons list view
export interface GetFutureLessonsListViewResponse {
  futureLessons: Reservation[];
  hasMore: boolean;
  currentPage: number;
}

export const getFutureLessonsListView = createAsyncThunk(
  'mySchedule/getFutureLessonsListView',
  async function ({ page, filters }: FutureLessonsPayload) {
    const response = await axios.get<GetFutureLessonsListViewResponse>(ENDPOINTS.view.list, {
      params: { page, filters },
    });
    return response.data;
  }
);
//#endregion Get future lessons list view

//region Get future lessons calendar view
export interface GetFutureLessonsCalendarViewResponse {
  calendarReservations: ReservationTimeSlotEntity[];
}

export const getFutureLessonsCalendarView = createAsyncThunk(
  'mySchedule/getFutureLessonsCalendarView',
  async function ({ page, filters, startDate, endDate }: FutureLessonsPayload) {
    const response = await axios.get<GetFutureLessonsCalendarViewResponse>(ENDPOINTS.view.calendar, {
      params: { page, filters, startDate, endDate },
    });
    return response.data;
  }
);
//#endregion Get future lessons calendar view

//region Get edit calendar
export interface GetScheduleCalendarEditDataResponse {
  reservationBufferHours: number;
  duration: number;
  canChangeCourseDuration: boolean;
  canUseLegacyCourseDuration: boolean;
  timeSlots: EditableTimeSlotEntity[];
  recurrenceTypes: [];
  hasBlockedNewStudentsReservations: boolean;
}

export const getScheduleCalendarEditData = createAsyncThunk(
  'mySchedule/getScheduleCalendarEditData',
  async function (params: { startDate: string; endDate: string }) {
    const response = await axios.get<GetScheduleCalendarEditDataResponse>(ENDPOINTS.edit.calendar, { params });

    return response.data;
  }
);
//#endregion Get edit calendar

//region Get future lessons list view
export interface GetUpcomingLessonsResponse {
  upcomingLessons: UpcomingLesson[];
}

export const getUpcomingLessons = createAsyncThunk('mySchedule/getUpcomingLessons', async function () {
  const response = await axios.get<GetUpcomingLessonsResponse>(ENDPOINTS.upcoming);
  return response.data;
});
//#endregion Get future lessons list view

//#region Get notifications count
export interface GetMyScheduleNotificationsCountResponse {
  myScheduleNotificationsCount: number;
}
export const getMyScheduleNotificationsCount = createAsyncThunk(
  'mySchedule/getMyScheduleNotificationsCount',
  async () => {
    const response = await axios.get<GetMyScheduleNotificationsCountResponse>(ENDPOINTS.notificationsCount);
    return response.data;
  }
);
//#endregion Get notifications count
