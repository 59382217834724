import { Col } from 'react-bootstrap';

import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { RadioButtonControl } from '@/app/components/Form/RadioButtonControl/RadioButtonControl';

type CourseStatusCardProps = {
  header: string;
  children: React.ReactNode;
  md?: number;
  courseStatus?: number | null;
};

export function CourseStatusCard({ header, children, md = 0, courseStatus = null }: CourseStatusCardProps) {
  return (
    <Col md={md}>
      <Card variant="solid" className="h-100">
        <CardHeader isRow className="align-items-center">
          <CardTitle>{header}</CardTitle>
          {courseStatus ? <RadioButtonControl name="courseStatusID" value={courseStatus} isInline /> : null}
        </CardHeader>

        <CardBody>{children}</CardBody>
      </Card>
    </Col>
  );
}
