import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { useStepper } from '@/app/components/Stepper/useStepper';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getEditTutorProfileData, updateTutorProfile } from '../service';
import { TutorProfileStepper } from './components/TutorProfileStepper';
import { TutorProfileCreateValues } from './models';
import { useBankDetailsEditStep } from './steps/BankDetailsStep';
import { useIdentityDocumentsEditStep } from './steps/IdentityDocumentsStep';
import { useInvoiceDetailsEditStep } from './steps/InvoiceDetailsStep';
import { useTutorAboutDetailsEditStep } from './steps/TutorAboutDetailsStep';
import { useTutorProfileDetailsEditStep } from './steps/TutorProfileDetailsStep';
import { useTutorProfilePreviewStep } from './steps/TutorProfilePreviewStep';

const REDIRECT_ROUTE = '/tutor/become';

export function TutorProfileEditPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.tutor.loading);
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);
  const tutorID = useAppSelector((state) => state.auth.tutorID);

  useEffect(
    function componentDidMount() {
      if (tutorStatusID !== TutorStatusID.NotStarted && tutorID) {
        dispatch(getEditTutorProfileData(tutorID));
      }
    },
    [tutorStatusID, tutorID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  async function handleUpdateTutorProfile(values: TutorProfileCreateValues) {
    return await updateTutorProfile(tutorID, values);
  }

  const stepInstances = {
    tutorProfileDetails: useTutorProfileDetailsEditStep(1),
    tutorAboutDetails: useTutorAboutDetailsEditStep(2),
    invoiceDetails: useInvoiceDetailsEditStep(3),
    bankDetails: useBankDetailsEditStep(4),
    identityDocuments: useIdentityDocumentsEditStep(5),
    tutorProfilePreview: useTutorProfilePreviewStep(6),
  };

  const stepperInstance = useStepper({
    slug: 'tutor_profile_edit',
    isLoading: loading === LoadingState.Pending,
    stepInstances,
    handleSubmit: handleUpdateTutorProfile,
    handleAfterSubmit() {
      history.push(REDIRECT_ROUTE);
    },
  });
  const { currentStep, isSubmitting } = stepperInstance;

  return (
    <>
      <Helmet>
        <title>Редактирайте Вашия профил</title>
      </Helmet>
      <Page>
        <Content>
          <PageTitle className="mb-9 fw-bolder fs-2x text-center">Редактирайте Вашия профил</PageTitle>
          <TutorProfileStepper
            currentStep={currentStep}
            stepperInstance={stepperInstance}
            isSubmitting={isSubmitting}
            isEditMode
          />
        </Content>
      </Page>
    </>
  );
}

export { TutorProfileEditPage as default };
