import { useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import * as yup from 'yup';

import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { Feedback } from '@/app/components/Feedback/Feedback';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { MultipleFileControl } from '@/app/components/Form/MultipleFileControl/MultipleFileControl';
import { RadioButtonControl } from '@/app/components/Form/RadioButtonControl/RadioButtonControl';
import { TextareaControl } from '@/app/components/Form/TextareaControl/TextareaControl';
import { useForm } from '@/app/components/Form/useForm';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { StepInstance } from '@/app/components/Stepper/models';
import {
  TUTOR_EDUCATIONAL_DEGREE_SELECT_OPTIONS,
  TUTOR_TEACHING_EXPERIENCE_OPTIONS,
} from '@/app/modules/tutor/constants';
import { TutorEducationalDegreeID, TutorState } from '@/app/modules/tutor/models';
import { LoadingState } from '@/redux/constants';
import { RootState, useAppSelector } from '@/redux/store';

import { TutorAboutDetailsFormModel, TutorAboutDetailsModel } from '../models';

export const TUTOR_ABOUT_DETAILS_PAGE_TITLE = 'За мен';

const contentSelectorCreate = (state: RootState) => state.tutor;
const contentSelectorEdit = (state: RootState) => state.tutor.edit.tutorAboutDetails;

export function useTutorAboutDetailsInitialValues(content: TutorState | TutorAboutDetailsModel) {
  const initialValues = useMemo<TutorAboutDetailsFormModel>(
    () => ({
      educationDegreeID: content.educationDegreeID ?? null,
      university: content.university ?? '',
      hasPedagogicalDegree: content.hasPedagogicalDegree ?? null,
      pedagogicalDegree: content.pedagogicalDegree ?? '',
      teachingExperienceID: content.teachingExperienceID ?? null,
      hasStudentPrepareExperience: content.hasStudentPrepareExperience ?? null,
      technicalExperience: content.technicalExperience ?? null,
      approach: content.approach ?? '',
      motivation: content.motivation ?? '',
      importance: content.importance ?? '',
      pupilAchievement: content.pupilAchievement ?? '',
      attachments: content.aboutDetailsAttachments ?? [],
    }),
    [content]
  );

  return { initialValues };
}

export function useTutorAboutDetailsValidationSchema() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        educationDegreeID: yup.string().required(),
        university: yup.string().when('educationDegreeID', {
          is: (value: string) => TutorEducationalDegreeID.University === Number(value),
          then: yup.string().required(),
        }),
        hasPedagogicalDegree: yup.mixed().required(),
        pedagogicalDegree: yup.string().when('hasPedagogicalDegree', {
          is: '1',
          then: yup.string().required(),
        }),
        teachingExperienceID: yup.mixed().required(),
        hasStudentPrepareExperience: yup.mixed().required(),
        technicalExperience: yup.mixed().required(),
        approach: yup.string().required(),
        motivation: yup.string().required(),
        importance: yup.string().required(),
        pupilAchievement: yup.string().optional(),
        attachments: yup.array().optional(),
      }),
    []
  );
  return { validationSchema };
}

export function useTutorAboutDetailsCreateStep(stepNumber: number): StepInstance<TutorAboutDetailsFormModel> {
  const content = useAppSelector(contentSelectorCreate);

  const { initialValues } = useTutorAboutDetailsInitialValues(content);

  const { validationSchema } = useTutorAboutDetailsValidationSchema();

  const formik = useForm<TutorAboutDetailsFormModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });
  return { formik, stepNumber };
}

export function useTutorAboutDetailsEditStep(stepNumber: number): StepInstance<TutorAboutDetailsFormModel> {
  const content = useAppSelector(contentSelectorEdit);

  const { initialValues } = useTutorAboutDetailsInitialValues(content);

  const { validationSchema } = useTutorAboutDetailsValidationSchema();

  const formik = useForm<TutorAboutDetailsFormModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });
  return { formik, stepNumber };
}

export function TutorAboutDetailsStep() {
  const formik = useFormikContext<TutorAboutDetailsFormModel>();

  return (
    <>
      <CardHeader>
        <CardTitle>{TUTOR_ABOUT_DETAILS_PAGE_TITLE}</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={6}>
            <FormGroup className="mb-4" controlId="educationDegreeID">
              <Label isRequired>Какво образование имате?</Label>
              <SelectControl name="educationDegreeID" options={TUTOR_EDUCATIONAL_DEGREE_SELECT_OPTIONS} />
            </FormGroup>

            {Number(formik.values.educationDegreeID) === TutorEducationalDegreeID.University && (
              <FormGroup className="mb-4" controlId="university">
                <Label isRequired>Напишете Университет / Специалност </Label>
                <FormControl name="university" />
              </FormGroup>
            )}

            <FormGroup className="mb-4" controlId="hasPedagogicalDegree">
              <Label isRequired>Имате ли педагогическа степен?</Label>
              <label className="d-flex align-items-center mb-1 px-4 cursor-pointer">
                <RadioButtonControl name="hasPedagogicalDegree" value="1" isInline />
                <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                  <div className="d-flex flex-column fw-bolder">
                    <span>Да</span>
                  </div>
                </div>
              </label>
              <label className="d-flex align-items-center mb-4 px-4 cursor-pointer">
                <RadioButtonControl name="hasPedagogicalDegree" value="0" isInline />
                <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                  <div className="d-flex flex-column fw-bolder">
                    <span>Не</span>
                  </div>
                </div>
              </label>
              <Form.Text>Видимо само за Школо админ</Form.Text>
              {formik.errors.hasPedagogicalDegree && <Feedback>{formik.errors.hasPedagogicalDegree}</Feedback>}
            </FormGroup>

            {Number(formik.values.hasPedagogicalDegree) === 1 && (
              <FormGroup className="mb-4" controlId="pedagogicalDegree">
                <Label isRequired>Напишете степен</Label>
                <FormControl name="pedagogicalDegree" />
              </FormGroup>
            )}

            <FormGroup className="mb-4" controlId="teachingExperienceID">
              <Label isRequired>Какъв опит имате като обучител?</Label>
              {TUTOR_TEACHING_EXPERIENCE_OPTIONS.map((experience) => {
                return (
                  <label key={experience.id} className="d-flex align-items-center mb-1 px-4 cursor-pointer">
                    <RadioButtonControl name="teachingExperienceID" value={experience.id} isInline />
                    <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                      <div className="d-flex flex-column fw-bolder">
                        <span>{experience.name}</span>
                      </div>
                    </div>
                  </label>
                );
              })}
              {formik.errors.teachingExperienceID && <Feedback>{formik.errors.teachingExperienceID}</Feedback>}
              <Form.Text>Видимо само за Школо админ</Form.Text>
            </FormGroup>

            <FormGroup className="mb-4" controlId="attachments">
              <Label>Прикачете Ваши сертификати, дипломи или награди</Label>
              <MultipleFileControl name="attachments" />
              <Form.Text>Видимо само за Школо админ</Form.Text>
            </FormGroup>

            <FormGroup className="mb-4" controlId="hasStudentPrepareExperience">
              <Label isRequired>Имате ли опит в подготовка на ученици за НВО и ДЗИ?</Label>
              <label className="d-flex align-items-center mb-1 px-4 cursor-pointer">
                <RadioButtonControl name="hasStudentPrepareExperience" value="1" isInline />
                <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                  <div className="d-flex flex-column fw-bolder">
                    <span>Да</span>
                  </div>
                </div>
              </label>
              <label className="d-flex align-items-center mb-4 px-4 cursor-pointer">
                <RadioButtonControl name="hasStudentPrepareExperience" value="0" isInline />
                <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                  <div className="d-flex flex-column fw-bolder">
                    <span>Не</span>
                  </div>
                </div>
              </label>
              <Form.Text>Видимо само за Школо админ</Form.Text>
              {formik.errors.hasStudentPrepareExperience && (
                <Feedback>{formik.errors.hasStudentPrepareExperience}</Feedback>
              )}
            </FormGroup>

            <FormGroup className="mb-4" controlId="technicalExperience">
              <Label isRequired>Как се справяте с технологиите при онлайн обучението?</Label>
              <label className="d-flex align-items-center mb-1 px-4 cursor-pointer">
                <RadioButtonControl name="technicalExperience" value="1" isInline />
                <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                  <div className="d-flex flex-column fw-bolder">
                    <span>1</span>
                  </div>
                </div>
              </label>
              <label className="d-flex align-items-center mb-1 px-4 cursor-pointer">
                <RadioButtonControl name="technicalExperience" value="2" isInline />
                <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                  <div className="d-flex flex-column fw-bolder">
                    <span>2</span>
                  </div>
                </div>
              </label>
              <label className="d-flex align-items-center mb-1 px-4 cursor-pointer">
                <RadioButtonControl name="technicalExperience" value="3" isInline />
                <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                  <div className="d-flex flex-column fw-bolder">
                    <span>3</span>
                  </div>
                </div>
              </label>
              <label className="d-flex align-items-center mb-1 px-4 cursor-pointer">
                <RadioButtonControl name="technicalExperience" value="4" isInline />
                <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                  <div className="d-flex flex-column fw-bolder">
                    <span>4</span>
                  </div>
                </div>
              </label>
              <label className="d-flex align-items-center mb-4 px-4 cursor-pointer">
                <RadioButtonControl name="technicalExperience" value="5" isInline />
                <div className="d-flex justify-content-between align-items-center flex-grow-1 p-2">
                  <div className="d-flex flex-column fw-bolder">
                    <span>5</span>
                  </div>
                </div>
              </label>
              <Form.Text>Скала: 1 = трудно, 5 = отлично</Form.Text>
              <br />
              <Form.Text>Видимо само за Школо админ</Form.Text>
              {formik.errors.technicalExperience && <Feedback>{formik.errors.technicalExperience}</Feedback>}
            </FormGroup>

            <FormGroup className="mb-4" controlId="approach">
              <Label isRequired>Какъв е подходът Ви в преподаването?</Label>
              <TextareaControl name="approach" rows={5} />
            </FormGroup>

            <FormGroup className="mb-4" controlId="motivation">
              <Label isRequired>Какво Ви мотивира като обучител?</Label>
              <TextareaControl name="motivation" rows={5} />
            </FormGroup>

            <FormGroup className="mb-4" controlId="importance">
              <Label isRequired>Кое е най-важното за Вас в работата с учениците?</Label>
              <TextareaControl name="importance" rows={5} />
            </FormGroup>

            <FormGroup className="mb-4" controlId="pupilAchievement">
              <Label>Дайте пример за постижение на Ваш ученик, с което се гордеете?</Label>
              <TextareaControl name="pupilAchievement" rows={5} />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </>
  );
}
