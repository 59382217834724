import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import { Card } from '@/app/components/Card/Card';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { TabItem } from '@/app/components/Tabs/TabItem';
import { TabNav } from '@/app/components/Tabs/TabNav';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { StickyHeader } from '@/app/modules/profile/StickyHeader';
import { useAppSelector } from '@/redux/store';

type NotificationBasePageProps = {
  title: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
};

export function NotificationBasePage({ title, actions, children }: NotificationBasePageProps) {
  const { tutorStatusID } = useAppSelector((state) => state.auth);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <StickyHeader className="pt-5" containerClassName="mb-7">
        <PageTop className="mb-5">
          <PageTitle className="my-2">{title}</PageTitle>
          <PageActions>{actions}</PageActions>
        </PageTop>
        <TabNav>
          <TabItem to="/profile/notifications">Основни</TabItem>
          {tutorStatusID === TutorStatusID.Approved && (
            <TabItem to="/profile/notifications/tutor">За обучители</TabItem>
          )}
        </TabNav>
      </StickyHeader>
      <Card>{children}</Card>
    </>
  );
}
