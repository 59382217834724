import { useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import * as yup from 'yup';

import { KTSVG } from '@/_metronic/helpers';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { DatePickerControl } from '@/app/components/Form/DatePickerControl/DatePickerControl';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { DEFAULT_GLOBAL_PHONE_PREFIX } from '@/app/components/Form/PhoneControl/constants';
import { PhoneControl } from '@/app/components/Form/PhoneControl/PhoneControl';
import { useForm } from '@/app/components/Form/useForm';
import { SecurityText } from '@/app/components/SecurityText/SecurityText';
import { AsyncSelectControl } from '@/app/components/SelectControl/AsyncSelectControl';
import { StepInstance } from '@/app/components/Stepper/models';
import { CityOption } from '@/app/models/City';
import { MIN_REQUIRED_AGE } from '@/app/utils/constants';
import { LoadingState } from '@/redux/constants';
import { RootState, useAppSelector } from '@/redux/store';

import { TutorState } from '../../models';
import { InvoiceDetailsStepModel } from '../models';

const INVOICE_DETAILS_STEP_TITLE = 'Издаване на фактура';

const contentSelectorCreate = (state: RootState) => state.tutor;
const contentSelectorEdit = (state: RootState) => state.tutor.edit.invoiceDetails;

export function useInvoiceDetailsInitialValues(content: TutorState | InvoiceDetailsStepModel) {
  const initialValues = useMemo<InvoiceDetailsStepModel>(
    () => ({
      firstName: content?.firstName ?? '',
      lastName: content?.lastName ?? '',
      phone: content?.phone ?? DEFAULT_GLOBAL_PHONE_PREFIX,
      email: content?.email ?? '',
      birthday: content?.birthday ?? '',
      address: content?.address ?? '',
      postCode: content?.postCode ?? '',
      city: content?.city ?? {
        id: 0,
        text: '',
      },
    }),
    [content]
  );

  return { initialValues };
}

export function useInvoiceDetailsValidationSchema() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phone: yup.mixed().required().phone(),
        email: yup.string().required().email(),
        birthday: yup.string().date().minAge(MIN_REQUIRED_AGE).required(),
        address: yup.string().required(),
        city: yup.object().shape({
          id: yup.number().required().moreThan(0, 'Полето е задължително.'),
        }),
        postCode: yup.string().required(),
      }),
    []
  );
  return { validationSchema };
}

export function useInvoiceDetailsCreateStep(stepNumber: number): StepInstance<InvoiceDetailsStepModel> {
  const content = useAppSelector(contentSelectorCreate);

  const { initialValues } = useInvoiceDetailsInitialValues(content);

  const { validationSchema } = useInvoiceDetailsValidationSchema();

  const formik = useForm<InvoiceDetailsStepModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });

  return { formik, stepNumber };
}

export function useInvoiceDetailsEditStep(stepNumber: number): StepInstance<InvoiceDetailsStepModel> {
  const content = useAppSelector(contentSelectorEdit);

  const { initialValues } = useInvoiceDetailsInitialValues(content);

  const { validationSchema } = useInvoiceDetailsValidationSchema();

  const formik = useForm<InvoiceDetailsStepModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });

  return { formik, stepNumber };
}

export function InvoiceDetailsStep() {
  const formik = useFormikContext();

  function handleChangeSelect(selectedCity: CityOption) {
    if (selectedCity?.postCode) {
      formik.setFieldValue('postCode', selectedCity?.postCode);
    }
  }

  return (
    <>
      <CardHeader>
        <CardTitle>{INVOICE_DETAILS_STEP_TITLE}</CardTitle>
        <CardSubTitle>Когато потребител закупи Ваше обучение ще получи фактура с тези данни.</CardSubTitle>
      </CardHeader>
      <CardBody>
        <SecurityText className="mb-10" />

        <Row>
          <FormGroup controlId="firstName" className="col-md-6">
            <Label isRequired>Име</Label>
            <FormControl type="text" name="firstName" />
          </FormGroup>
          <FormGroup controlId="lastName" className="col-md-6 mb-10">
            <Label isRequired>Фамилия</Label>
            <FormControl type="text" name="lastName" />
          </FormGroup>
        </Row>

        <FormGroup controlId="phone">
          <Label isRequired>Телефон</Label>
          <PhoneControl name="phone" />
        </FormGroup>

        <FormGroup controlId="email">
          <Label isRequired>Email адрес</Label>
          <FormControl type="email" name="email" />
        </FormGroup>

        <FormGroup controlId="birthday">
          <Label isRequired>Дата на раждане</Label>
          <DatePickerControl name="birthday" />
          <div className="d-flex mt-5">
            <KTSVG className="w-15px h-15px" path="/img/svg/icons/EyeSlash.svg" />
            <p className="text-gray-500 fs-7 px-1 mb-0">
              Видимо само за вас. Необходимо е за да потвърдим, че не сте малолетен.
            </p>
          </div>
        </FormGroup>

        <FormGroup controlId="address">
          <Label isRequired>Адрес</Label>
          <FormControl type="text" name="address" />
        </FormGroup>

        <Row className="mb-n10">
          <FormGroup controlId="city" className="col-md-6">
            <Label isRequired>Град</Label>
            <AsyncSelectControl name="city" endpoint="/ajax/cities" onChange={handleChangeSelect} />
          </FormGroup>
          <FormGroup controlId="postCode" className="col-md-6 mb-10">
            <Label isRequired>Пощенски код</Label>
            <FormControl type="text" name="postCode" />
          </FormGroup>
        </Row>
      </CardBody>
    </>
  );
}
