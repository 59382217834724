import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { FormikContextType, FormikValues } from 'formik';

import { Card } from '@/app/components/Card/Card';
import { Form } from '@/app/components/Form/Form';
import { handleFrontEndValidations } from '@/app/components/Form/utils';
import { GoBackLink } from '@/app/components/Page/GoBackLink/GoBackLink';
import { BasePageProps } from '@/app/components/Page/models';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { TabItem } from '@/app/components/Tabs/TabItem';
import { TabNav } from '@/app/components/Tabs/TabNav';

import { StickyHeader } from '../../StickyHeader';

type ProfileCourseEditPageBaseProps<T> = BasePageProps & {
  formik: FormikContextType<T>;
};

export function ProfileCourseEditPageBase<T = FormikValues>({
  title,
  actions,
  children,
  formik,
}: ProfileCourseEditPageBaseProps<T>) {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Form formik={formik} disabled={formik.isSubmitting} onSubmit={handleFrontEndValidations(formik)}>
        <StickyHeader className="pt-5" containerClassName="mb-7">
          <GoBackLink to="/profile/courses">Назад към всички обучения</GoBackLink>
          <div className="d-flex justify-content-between align-items-start mb-5">
            <PageTitle className="my-2 fw-bolder fs-2x text-break">{title}</PageTitle>
            <PageActions className="flex-shrink-0 ms-2">{actions}</PageActions>
          </div>

          <TabNav>
            <TabItem to={`/profile/courses/${id}/edit/status`}>Статус на обучението</TabItem>
            <TabItem to={`/profile/courses/${id}/edit/details`}>Детайли за обучението</TabItem>
            <TabItem to={`/profile/courses/${id}/edit/schedule`}>График</TabItem>
            <TabItem to={`/profile/courses/${id}/edit/packages`}>Цени</TabItem>
          </TabNav>
        </StickyHeader>

        <Card>{children}</Card>
      </Form>
    </>
  );
}
