export interface PaginationModel<T extends unknown[]> {
  data: T;
  perPage: number;
  total: number;
  currentPage: number;
}

export interface SortField {
  id: string;
  direction: string;
}

export type TableDataModel<T extends unknown[]> = PaginationModel<T>;

export function createTableData<T extends unknown[]>(
  data = [] as unknown as T,
  perPage = 0,
  total = 0,
  currentPage = 1
): TableDataModel<T> {
  return { data, perPage, total, currentPage };
}
