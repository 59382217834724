import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getMessengerThreadsPaginated } from './service';

export function useMessenger() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const notifications = useAppSelector((state) => state.messenger?.notifications ?? []);

  useEffect(
    function fetchMessengerThreads() {
      if (auth.isAuthenticated) {
        dispatch(getMessengerThreadsPaginated({ page: notifications?.currentPage }));
      }
    },
    [auth.isAuthenticated, notifications?.currentPage] // eslint-disable-line react-hooks/exhaustive-deps
  );
}
