import { ProfileProfitFilterValues, ProfileProfitItemPerPageOption, ProfileProfitItemsPerPage } from './models';

export const DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE: ProfileProfitItemsPerPage = 10;
export const DEFAULT_PAGE_VALUE = 1;
export const ProfileProfitFilterInitialValues: ProfileProfitFilterValues = {
  page: DEFAULT_PAGE_VALUE,
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
};

export const ITEMS_PER_PAGE_OPTIONS: ProfileProfitItemPerPageOption[] = [
  {
    id: 10,
    text: '10 на страница',
  },
  {
    id: 20,
    text: '20 на страница',
  },
  {
    id: 30,
    text: '30 на страница',
  },
  {
    id: 40,
    text: '40 на страница',
  },
  {
    id: 50,
    text: '50 на страница',
  },
  {
    id: 60,
    text: '60 на страница',
  },
];
