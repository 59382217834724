import { Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';

import './StaticPage.scss';

export function ChildProtectionPolicyPage() {
  return (
    <>
      <Helmet>
        <title>Политика за закрила на детето</title>
      </Helmet>
      <Page className="static-page terms">
        <Content>
          <PageTitle className="mb-9 fw-bolder fs-2x text-center">
            ПОЛИТИКА ЗА ЗАКРИЛА НА ДЕТЕТО НА „ШКОЛО“ ООД
          </PageTitle>
          <Card className="card-custom card-border">
            <Card.Body>
              <p>
                <strong>1.</strong> С настоящата политика се цели да се постигне максимална прозрачност и откритост при
                работа с деца с оглед повишаване качеството на образование в училищата. Благодарение на създадената
                организация, партньорите и екипа на „Школо“ ООД се развива и надгражда електронната среда, в която
                децата по-лесно и достъпно учат и развиват своя потенциал. За сигурността и спокойствието на децата и
                родителите „Школо“ ООД е предприело необходимите мерки и защити, които допринасят за изграждане на
                доверие в дигиталната среда като средство за обмен на информация.
              </p>

              <h4 className="fs-4 mb-10 mt-10 text-gray-800">I. Дефиниции:</h4>
              <p className="mb-0">
                <strong>2.1.</strong> „Дете“ означава всяко човешко същество на възраст под осемнадесет години;
              </p>
              <p className="mb-0">
                <strong>2.2.</strong> „Служител“ означава физическо лице, което е на трудов с „Школо“ ООД;
              </p>
              <p className="mb-0">
                <strong>2.3.</strong> „Партньор“ означава лице, което се намира в граждански или търговски отношения с
                „Школо“ ООД;
              </p>
              <p>
                <strong>2.4.</strong> „Нередност“ означава действие, бездействие или поведение на служител или партньор
                на „Школо“ ООД, което е в противоречие с настоящата политика;
              </p>

              <h4 className="fs-4 mb-10 mt-10 text-gray-800">II. Ангажимент на „Школо“ ООД:</h4>
              <p>
                <strong>3.</strong> В своята работа „Школо“ ООД се ръководи от убеждението, че всички деца имат равни
                права и еднакво право на закрила и че всяка форма на злоупотреба с децата включва нарушение на правата
                на децата.
              </p>
              <p>
                <strong>4.</strong> С цел да се гарантират правата на децата и да бъдат предотвратени рисковете, стоящи
                пред децата, „Школо“ ООД разработи тази политика за закрила. Тя изразява ангажимента към конкретните
                мерки, които организацията ще изпълнява при предоставяне на услуги на децата.
              </p>
              <p>
                <strong>5.</strong> Децата и родителите разполагат с възможността по всяко време да сигнализират на
                „Школо“ ООД за всякакви нередности или съмнения за нередности, което се взима под внимание и се
                проверява от служители на „Школо“ ООД. Също така „Школо“ ООД запознава и обучава всички свои служители
                относно работата с информация, отнасяща се за деца, което следва да се извършва в съответствие с
                приложимото законодателство и при пълна конфиденциалност. Служителите на „Школо“ ООД подписват
                декларации за неразпространение на лични данни, респективно биват информирани за последиците от
                неспазването на закона, правилата и процедурите за защита на личните данни.
              </p>
              <p>
                <strong>6.</strong> „Школо“ ООД избира да работи с хора, които имат опит в работата с деца. „Школо“ ООД
                предприема необходимите мерки да запознае партньорите си за важността на диалога за децата. Стреми се да
                гарантира, че партньорите разбират своята отговорност в общите усилия за благосъстоянието на децата и че
                също поемат ангажимент за спазването на стандарти за закрила на децата.
              </p>

              <h4 className="fs-4 mb-10 mt-10 text-gray-800">III. Цели на политиката:</h4>
              <p>
                <strong>7.</strong> Тази политика за закрила на детето се основава на националното законодателство,
                международните стандарти и добри практики за закрила на детето и Конвенцията за правата на детето на
                ООН.
              </p>
              <p>
                <strong>8.</strong> С изработването и прилагането на мерки за закрила на децата се свеждат до минимум
                потенциалните рискове от насилие над и експлоатация на децата. Също така, мерките служат и за
                утвърждаване на базови стандарти при общуване с децата, които гарантират спазването на добрите практики
                в тази насока.
              </p>
              <p>
                <strong>9.</strong> Тази политика очертава за служителите на „Школо“ ООД и неговите партньори
                допустимите граници в общуването и подпочитаното поведение при работа с деца. Подпомага служителите да
                идентифицират потенциалните рискове при съвместната работа с деца в и извън работното място и/или
                местоживеенето, като същевременно очертава възможните начини, по които служителите могат да действат в
                случаи на доказана опасност от насилие над или злоупотреба с деца или в случаи на претърпяно насилие или
                злупотреба.
              </p>

              <h4 className="fs-4 mb-10 mt-10 text-gray-800">IV. Обхват на политиката за закрила:</h4>
              <p>
                <strong>10.</strong> Тази политика се отнася за всички служители на „Школо“ ООД и неговите партньори.
                Също така дава информация на децата и техните родители за правата им и действията, които могат да
                предприемат с цел защита на детето.
              </p>

              <h4 className="fs-4 mb-10 mt-10 text-gray-800">V. Ценности и принципи на закрила:</h4>
              <p>
                <strong>11.</strong> „Школо“ ООД се ръководи от заложените в Закона за закрила на детето и Конвенцията
                за правата на детето на ООН ценности и принципи, отнасящи се до него:
              </p>
              <p className="ms-5 mb-0">
                <strong>а)</strong> зачитане и уважение на личността на детето;
              </p>
              <p className="ms-5 mb-0">
                <strong>б)</strong> осигуряване най-добрия интерес на детето;
              </p>
              <p className="ms-5 mb-0">
                <strong>в)</strong> подбор на лицата, пряко ангажирани в дейностите по закрила на детето, съобразно
                техните личностни и социални качества и грижа за тяхната професионална квалификация;
              </p>
              <p className="ms-5 mb-0">
                <strong>г)</strong> незабавност на действията по закрила на детето;
              </p>
              <p className="ms-5 mb-0">
                <strong>д)</strong> превантивни мерки за сигурност и закрила на детето;
              </p>
              <p className="ms-5 mb-0">
                <strong>е)</strong> недискриминация;
              </p>
              <p className="ms-5">
                <strong>ж)</strong> свобода на изразяване.
              </p>

              <h4 className="fs-4 mb-10 mt-10 text-gray-800">VI. Правила и процедури за закрила на децата:</h4>
              <p>
                <strong>12.</strong> Децата могат да подадат сигнал чрез родителите си за нередности на следната
                електронна поща:
                <a href="mailto:info@shkolo.com" className="ms-1 me-1">
                  info@shkolo.com
                </a>
                „Школо“ ООД ангажира служители, които са запознати с настоящата политика, да получават и разглеждат
                получените сигнали за нередности. В сигнала се описват обстоятелствата, които пострадалото лице счита за
                нередност. Лицето, което подава сигнала, попълва данните си и се подписва, след което го изпраща на
                посочената по-горе електронна поща.
              </p>
              <p>
                <strong>13.</strong> Служителите и партньорите на „Школо“ ООД, които работят с деца, са длъжни:
              </p>
              <p className="ms-5 mb-0">
                <strong>а)</strong> да са запознати с възможните ситуации, които могат да изложат децата на риск;
              </p>
              <p className="ms-5 mb-0">
                <strong>б)</strong> да се стремят да поддържат култура на откритост, така че всички проблеми или
                опасения да бъдат изказвани и обсъждани свободно и в защитена среда;
              </p>
              <p className="ms-5 mb-0">
                <strong>в)</strong> да са сигурни, че помежду им съществува чувство на отговорност и атмосфера на
                откритост, така че на всяка зловредна или потенциално рискова практика или поведение да се реагира
                своевременно и в съответствие с разпоредбите на настоящата политика;
              </p>
              <p className="ms-5 mb-0">
                <strong>г)</strong> да насърчават формирането и споделянето на мнение от страна на децата по въпросите,
                които ги засягат;
              </p>
              <p className="ms-5 mb-0">
                <strong>д)</strong> да изслушват и да не игнорират мнението на децата;
              </p>
              <p className="ms-5">
                <strong>е)</strong> да спазват настоящата политика и правилата за конфиденциалност;
              </p>
              <p>
                <strong>14.</strong> Служителите и партньорите на „Школо“ ООД не трябва:
              </p>
              <p className="ms-5 mb-0">
                <strong>а)</strong> да обиждат и използват вулгарни думи по адрес на децата или на други хора в
                присъствието на децата;
              </p>
              <p className="ms-5 mb-0">
                <strong>б)</strong> да използват думи, изрази, намеци или начин на поведение, свързани с каквато и да е
                форма на сексуални или други неприемливи взаимноотношения;
              </p>
              <p className="ms-5 mb-0">
                <strong>в)</strong> да поощряват или да участват в действия на децата, които са насочени към
                неблагоприятни за тях последици или които са в противоречие с общоприетите норми на поведение;
              </p>
              <p className="ms-5 mb-0">
                <strong>г)</strong> да дискриминират по какъвто и да е било признак, и да показват избирателно
                отношение, изключвайки едни или други деца от даден процес на обучение или общуване;
              </p>
              <p className="ms-5 mb-0">
                <strong>д)</strong> да използват изрази, да дават предложения или да предлагат съвети, които са
                неподходящи или оскърбителни за децата;
              </p>
              <p className="ms-5">
                <strong>е)</strong> да се държат и/или да са облечени по сексуално провокативен начин с или в
                присъствието на децата;
              </p>
              <p>
                <strong>15.</strong> Правила за провеждане на онлайн обучения:
              </p>
              <p className="ms-5 mb-0">
                <strong>а)</strong> При провеждането на онлайн обучения с ученици, същите трябва да бъдат подходящо
                облечени, според разбиранията за приличие в своето населено място или общност;
              </p>
              <p className="ms-5">
                <strong>б)</strong> Обучителят следва да спазва изискванията по т. 13 и т. 14 от настоящата политика;
              </p>
              <p>
                <strong>16.</strong> Правила за комуникация:
              </p>
              <p className="ms-5 mb-0">
                <strong>а)</strong> всяка комуникация между служители/партньори на „Школо“ ООД и деца и техните родители
                се извършва, съблюдавайки максимална откритост, яснота, прозрачност за всички, включени в нея и с
                подходящ тон;
              </p>
              <p className="ms-5 mb-0">
                <strong>б)</strong> съобщенията между служители/партньори на „Школо“ ООД и деца и техните родители не
                трябва да съдържат обиден или увреждащ език;
              </p>
              <p className="ms-5">
                <strong>в)</strong> забранява се огласяването или разпространяването на лична и/или конфиденциална
                информация, отнасяща се до децата и/или служители/партньори на „Школо“ ООД.
              </p>
              <p>
                <strong>17.</strong> Сигнали за нередности се подават по начина посочен в т. 12 от настоящата политика.
                Отговорен служител на „Школо“ ООД разглежда подадения сигнал, включително когато са налични записи от
                провеждани обучения. При констатирана нередност се уведомяват заинтересованите страни и за правата им и
                начините, по които могат да ги упражнят. При констатирано нарушение на Закона за закрила на детето
                „Школо“ ООД следва да сигнализира Държавна агенция за закрила на детето и/или органите на реда.
              </p>
              <p>
                <strong>18.</strong> Сигналът се подава чрез попълване на
                <a
                  href="/files/Формуляр за сигнал.docx"
                  download
                  rel="noopener noreferrer"
                  target="_blank"
                  className="ms-1"
                >
                  електронен формуляр
                </a>
                , утвърден от „Школо“ ООД. Сигналите се отразяват в общ регистър, който се поддържа от служители на
                „Школо“ ООД.
              </p>
              <p>
                <strong>19.</strong> „Школо“ ООД гарантира, че прилага най-високите стандарти при набирането на персонал
                и прилага тази политика във всеки аспект от дейността си. Всеки служител се запознава с настоящата
                политика и се задължава да я спазва. Партньорите на „Школо“ ООД, които работят с деца, приемат и се
                съгласяват да спазват тази политика.
              </p>
            </Card.Body>
          </Card>
        </Content>
      </Page>
    </>
  );
}
