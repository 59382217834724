import { FormikContextType, FormikHelpers, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';

type FormConfig<T = FormikValues> = {
  initialValues: T;
  initialStatus?: unknown;
  validationSchema?: yup.ObjectSchema<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  onSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<void | undefined | unknown>;
};

export function useForm<T>({
  initialValues,
  initialStatus,
  validationSchema,
  onSubmit,
}: FormConfig<T>): FormikContextType<T> {
  const formik = useFormik({
    initialValues,
    initialStatus,
    validationSchema: validationSchema ?? yup.object().shape({}),
    onSubmit: onSubmit ?? (() => undefined),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  }) as unknown as FormikContextType<T>;

  return formik;
}
