import { useCallback, useEffect, useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Helmet } from 'react-helmet';
import SVG from 'react-inlinesvg';
import { useHistory, useLocation } from 'react-router-dom';
import Toastr from 'toastr';
import * as yup from 'yup';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { CardToolbar } from '@/app/components/Card/CardToolbar';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { Table } from '@/app/components/Table/Table';
import { PaymentMethodBrandID } from '@/app/modules/course/checkout/constants';
import { destroyCard, getCards } from '@/app/modules/profile/card/service';
import cardSlice from '@/app/modules/profile/card/store';
import { useQueryParams } from '@/app/utils/query';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { CardIndexFilterInitialValues, ITEMS_PER_PAGE_OPTIONS } from './constants';
import { CardIndexFilterQueryParams, CardIndexFilterValues } from './models';
import { transformCardIndexFormikValuesToSearchParams } from './utils';

const columns: ColumnDescription[] = [
  {
    dataField: 'lastFourDigits',
    text: 'Карта',
    formatter: function StatusFormatter(_, card) {
      return (
        <div className="d-flex align-items-center">
          <span className="me-2">
            {card.brandID === PaymentMethodBrandID.VISA && (
              <SVG className="w-45px h-45px" src={toAbsoluteUrl('/img/svg/visa_brand_logo.svg')} />
            )}
            {card.brandID === PaymentMethodBrandID.MASTERCARD && (
              <SVG className="w-45px h-45px" src={toAbsoluteUrl('/img/svg/mastercard_brand_logo.svg')} />
            )}
          </span>
          **** {card.lastFourDigits}
        </div>
      );
    },
    style: {
      paddingTop: '0.35rem',
      paddingBottom: '0.35rem',
    },
  },
  {
    dataField: 'expiryDate',
    text: 'Валидна до',
  },
  {
    dataField: 'holderName',
    text: 'Притежател',
  },
];

export function CardIndexPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const content = useAppSelector((state) => state.card);
  const [loadingState, isInitialDataLoaded, setLoadingState] = useLoading(content.loading);
  const { currentSearchParams, queryParams } = useQueryParams<CardIndexFilterQueryParams>();

  useEffect(() => {
    if (content.loading === LoadingState.Idle) {
      dispatch(getCards(queryParams));
    }
  }, [queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  //#region Handle pagination form
  const initialValues = useMemo<CardIndexFilterValues>(
    () => ({
      page: queryParams.page ?? CardIndexFilterInitialValues.page,
      itemsPerPage: queryParams?.itemsPerPage ?? CardIndexFilterInitialValues.itemsPerPage,
    }),
    [queryParams]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<CardIndexFilterValues>({
    initialValues,
    validationSchema,
    initialStatus: loadingState,
  });
  //#endregion Handle pagination form

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformCardIndexFormikValuesToSearchParams(formik.values, queryParams);

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
        setLoadingState(LoadingState.Pending);
      }

      if (newSearchParams === '') {
        formik.setValues(CardIndexFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  //#region Handle page change
  useEffect(
    function handlePageChange() {
      formik.setFieldValue('page', content.currentPage);
    },
    [content.currentPage] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle page change

  const actions = useCallback(
    (_: unknown, row: Record<string, any>) => {
      async function handleRemoveCardClick() {
        const confirmText = `Сигурни ли сте, че искате да изтриете запаметена карта **** ${row.lastFourDigits}?`;

        if (!confirm(confirmText)) {
          return;
        }

        const response = await destroyCard(row.id);

        if (response) {
          dispatch(getCards(queryParams));
          Toastr.success('Успешно запазихте промените!');
        }
      }

      return (
        <div className="d-flex justify-content-end">
          <button className="btn btn-sm btn-light-primary btn-icon" onClick={handleRemoveCardClick}>
            <i className="fa fa-trash" />
          </button>
        </div>
      );
    },
    [queryParams]
  );

  return (
    <>
      {!isInitialDataLoaded && <PageTitle className="my-2">Зарежда се...</PageTitle>}
      {isInitialDataLoaded && (
        <>
          <Helmet>
            <title>Карти</title>
          </Helmet>
          <PageTop className="mb-9">
            <PageTitle>Карти</PageTitle>
          </PageTop>
          <Card>
            <CardHeader isRow>
              <CardTitle>Запазени карти</CardTitle>
              <CardToolbar>
                <Form formik={formik} skipDirtyPrompt>
                  <FormGroup>
                    <Label htmlFor="itemsPerPage" className="mb-0 me-3">
                      Резултати на страница:
                    </Label>
                    <SelectControl
                      name="itemsPerPage"
                      options={ITEMS_PER_PAGE_OPTIONS}
                      isSearchable={false}
                      hasSolidBackground={false}
                      className="w-175px"
                    />
                  </FormGroup>
                </Form>
              </CardToolbar>
            </CardHeader>
            <CardBody>
              <Table
                columns={columns}
                data={content.cards}
                isLoading={loadingState === LoadingState.Pending}
                setCurrentPageAction={cardSlice.actions.setCurrentPage}
                noDataIndication={
                  <>
                    <p className="mb-0 text-center">Все още нямате запазена карта за разплащания</p>
                  </>
                }
                actions={actions}
              />
            </CardBody>
          </Card>
        </>
      )}
      {loadingState === LoadingState.Pending && <GlobalSpinner />}
    </>
  );
}
