import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { add, endOfDay, format, getHours, isBefore, isEqual, isValid, parse, startOfDay, subYears } from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Descendant } from 'slate';
import * as yup from 'yup';

import { INTERVAL_RECURRENCE_TYPE_IDS } from '@/app/components/Calendar/constants';
import { EditableTimeSlotEntity } from '@/app/components/Calendar/models';
import { calculateIntervalCalendarEventDayDuration } from '@/app/components/Calendar/utils';
import {
  InternalStripeCardCvcElement,
  InternalStripeCardExpiryElement,
  InternalStripeCardNumberElement,
} from '@/app/components/Form/CardControl/models';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  END_OF_DAY_TIME_IN_HOURS,
  START_OF_DAY_TIME_IN_HOURS,
} from '@/app/components/Form/DatePickerControl/constants';
import { isRichTextEmpty } from '@/app/components/RichTextControl/utils';
import {
  BECOME_TUTOR_MINIMUM_TIME_SLOTS_NUMBER,
  BECOME_TUTOR_MINIMUM_TIME_SLOTS_TEXT,
  DAYS_FOR_TUTOR_APPROVAL,
} from '@/app/constants';
import { IBAN_REGEX, NOT_VALID_IBAN_MESSAGE } from '@/app/modules/tutor/constants';

import { formatHour } from './formatHour';

yup.setLocale({
  mixed: {
    required: 'Полето е задължително.',
    oneOf: 'Полето съдържа невалидна стойност.',
    notType: ({ type }: { type: string }) => {
      switch (type) {
        case 'number':
          return 'Полето може да съдържа само числа';
        default:
          return 'Полето съдържа невалидна стойност';
      }
    },
  },
  string: {
    email: 'Полето съдържа невалидна стойност. Въведете правилна ел. поща.',
    max: ({ max }: { max: number }) => `Полето приема само съдържание с размер до ${max} символа.`,
  },
  number: {
    min: ({ min }: { min: number }) => `Полето приема само стойности по-големи от или равни на ${min}.`,
    max: ({ max }: { max: number }) => `Полето приема само стойности по-малки от или равни на ${max}.`,
  },
});

const INCOMPLETE_FIELD = 'Полето е непълно.';

//#region MIXED METHODS
yup.addMethod<ReturnType<typeof yup.mixed>>(
  yup.mixed,
  'phone',
  function (message = 'Въведете валиден телефонен номер във формат +359ХХХХХХХХХ.') {
    return this.test('phone', message, (value) => {
      if (!value) {
        return false;
      }
      return isValidPhoneNumber(value as string);
    });
  }
);

yup.addMethod<ReturnType<typeof yup.mixed>>(yup.mixed, 'cardNumber', function (elements: StripeElements | null) {
  return this.test('cardNumber', (value, { createError }) => {
    const cardNumberElement = elements?.getElement?.(CardNumberElement) as InternalStripeCardNumberElement;

    if (cardNumberElement._invalid) {
      return createError({ message: value as string });
    }

    if (!cardNumberElement._complete) {
      return createError({ message: INCOMPLETE_FIELD });
    }

    return true;
  });
});

yup.addMethod<ReturnType<typeof yup.mixed>>(yup.mixed, 'cardExpiry', function (elements: StripeElements | null) {
  return this.test('cardExpiry', (value, { createError }) => {
    const cardExpiryElement = elements?.getElement?.(CardExpiryElement) as InternalStripeCardExpiryElement;

    if (cardExpiryElement._invalid) {
      return createError({ message: value as string });
    }

    if (!cardExpiryElement._complete) {
      return createError({ message: INCOMPLETE_FIELD });
    }

    return true;
  });
});

yup.addMethod<ReturnType<typeof yup.mixed>>(yup.mixed, 'cardCVC', function (elements: StripeElements | null) {
  return this.test('cardCVC', (value, { createError }) => {
    const cardCvcElement = elements?.getElement?.(CardCvcElement) as InternalStripeCardCvcElement;

    if (cardCvcElement._invalid) {
      return createError({ message: value as string });
    }

    if (!cardCvcElement._complete) {
      return createError({ message: INCOMPLETE_FIELD });
    }

    return true;
  });
});

yup.addMethod<ReturnType<typeof yup.mixed>>(
  yup.mixed,
  'profileFileDimensions',
  function (width: number, height: number, message: string) {
    return this.test('profileFileDimensions', message, async function (value) {
      if (!value) {
        return true;
      }

      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(value as Blob);
        reader.onload = function () {
          const img = new Image();
          if (typeof reader.result === 'string') {
            img.src = reader.result;
          }
          img.onload = function () {
            resolve(img.width >= width && img.height >= height);
          };
        };
      });
    });
  }
);
//#endregion MIXED METHODS

//#region STRING METHODS
yup.addMethod<ReturnType<typeof yup.string>>(yup.string, 'date', function (format = DEFAULT_DATE_FORMAT) {
  return this.test('date', 'Полето съдържа невалидна стойност. Въведете валидна дата', (value?: string) => {
    if (!value) {
      return true;
    }

    const date = parse(value, format, new Date());
    return isValid(date);
  });
});

yup.addMethod<ReturnType<typeof yup.string>>(yup.string, 'allowedHours', function () {
  return this.test(
    'allowedHours',
    `Изберете час след ${formatHour(START_OF_DAY_TIME_IN_HOURS)}:00 и преди ${formatHour(END_OF_DAY_TIME_IN_HOURS)}:00.`, // eslint-disable-line prettier/prettier
    (value?: string) => {
      if (!value) {
        return true;
      }

      const date = new Date(value);
      return (
        isValid(date) && date.getHours() >= START_OF_DAY_TIME_IN_HOURS && date.getHours() <= END_OF_DAY_TIME_IN_HOURS
      );
    }
  );
});

yup.addMethod<ReturnType<typeof yup.string>>(yup.string, 'minAge', function (minAge: number) {
  return this.test('date', `Трябва да сте навършили поне ${minAge} години.`, (value?: string) => {
    if (!value) {
      return true;
    }

    const currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);

    const birthdayDate = parse(value, DEFAULT_DATE_FORMAT, currentDate);

    return isBefore(birthdayDate, subYears(currentDate, minAge));
  });
});

yup.addMethod<ReturnType<typeof yup.string>>(yup.string, 'iban', function () {
  return this.test('iban', (value, { createError }) => {
    if (value && value.length > 0 && !value.match(new RegExp(IBAN_REGEX))) {
      return createError({ message: NOT_VALID_IBAN_MESSAGE });
    }

    return true;
  });
});
//#endregion STRING METHODS

//#region ARRAY METHODS
yup.addMethod<ReturnType<typeof yup.array>>(yup.array, 'requiredRichText', function () {
  return this.test('requiredRichText', 'Полето е задължително', (value) => {
    const richTextValue = value as Descendant[];
    return !isRichTextEmpty(richTextValue);
  });
});

//#endregion ARRAY METHODS

yup.addMethod<ReturnType<typeof yup.number>>(yup.number, 'interval', function (duration: number) {
  return this.test('interval', 'Зададените от Вас повторения и интервали не са валидни.', function () {
    const values = this.parent;
    if (!INTERVAL_RECURRENCE_TYPE_IDS.includes(values.recurrenceTypeID)) {
      return true;
    }

    // Calculate duration in minutes for the whole event for a day. Add that value to the start day time of the event to get end date time for the day.
    const minutesToAdd = calculateIntervalCalendarEventDayDuration(duration, values.count, values.interval);
    const startHour = new Date(values.startHour);
    const startDateTime = values.startDateTime as Date;

    startDateTime.setHours(startHour.getHours());
    startDateTime.setMinutes(startHour.getMinutes());

    const endDateTime = add(startDateTime, { minutes: minutesToAdd });
    return isBefore(endDateTime, endOfDay(startDateTime)) && getHours(endDateTime) <= END_OF_DAY_TIME_IN_HOURS;
  });
});

yup.addMethod<ReturnType<typeof yup.array>>(yup.array, 'becomeTutorScheduleTimeSlots', function () {
  const dateAfterDaysOfApproval = startOfDay(add(new Date(), { days: DAYS_FOR_TUTOR_APPROVAL }));
  return this.test(
    'becomeTutorScheduleTimeSlots',
    `Необходимо е да въведете поне ${BECOME_TUTOR_MINIMUM_TIME_SLOTS_TEXT} свободни часа в графика си започващи от ${format(
      dateAfterDaysOfApproval,
      DEFAULT_DATE_FORMAT
    )}, за да продължите напред.`,
    (value) => {
      const becomeTutorScheduleTimeSlots = value as EditableTimeSlotEntity[];
      const validTimeSlots = becomeTutorScheduleTimeSlots.filter((timeSlot) => {
        const startDate = parse(timeSlot.startDateTime, DEFAULT_DATETIME_FORMAT, new Date());

        if (isEqual(dateAfterDaysOfApproval, startOfDay(startDate))) {
          return true;
        }

        return isBefore(dateAfterDaysOfApproval, startOfDay(startDate));
      });

      return validTimeSlots.length >= BECOME_TUTOR_MINIMUM_TIME_SLOTS_NUMBER;
    }
  );
});

yup.addMethod<ReturnType<typeof yup.boolean>>(yup.boolean, 'hasValidTimeSlotsForApproval', function () {
  const dateAfterDaysOfApproval = startOfDay(add(new Date(), { days: DAYS_FOR_TUTOR_APPROVAL }));
  return this.test(
    'hasValidTimeSlotsForApproval',
    `Необходимо е да въведете поне ${BECOME_TUTOR_MINIMUM_TIME_SLOTS_TEXT} свободни часа в графика си започващи от ${format(
      dateAfterDaysOfApproval,
      DEFAULT_DATE_FORMAT
    )}, за да продължите напред.`,
    (value) => Boolean(value)
  );
});

yup.addMethod<ReturnType<typeof yup.object>>(yup.object, 'requiredProposalStudent', function () {
  return this.test('requiredProposalStudent', function () {
    const values = this.parent;

    if (!values.hasProposal) {
      return true;
    }

    if (!values.proposalStudent || Number(values.proposalStudent.id) === 0) {
      return this.createError({ path: `${this.path}.id`, message: 'Полето е задължително.' });
    }

    return true;
  });
});

yup.addMethod<ReturnType<typeof yup.object>>(yup.object, 'requiredProposalCourse', function () {
  return this.test('requiredProposalCourse', function () {
    const values = this.parent;

    if (!values.hasProposal) {
      return true;
    }

    if (!values.proposalCourse || Number(values.proposalCourse.id) === 0) {
      return this.createError({ path: `${this.path}.id`, message: 'Полето е задължително.' });
    }

    return true;
  });
});
