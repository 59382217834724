import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import IntervalTree from '@flatten-js/interval-tree';
import { format, isBefore, isEqual } from 'date-fns';
import { FormikContextType, FormikHelpers } from 'formik';
import Toastr from 'toastr';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { useForm } from '@/app/components/Form/useForm';
import { MINIMUM_SPECIAL_LESSON_PRICE } from '@/app/modules/course/constants';
import { CalendarEventType, ScheduleStepModel } from '@/app/modules/course/create/models';
import { EventTypeActionModal } from '@/app/modules/course/forms/EventTypeActionModal';
import { RecurrenceType } from '@/app/modules/course/models';
import { hasIntervalValidationErrors, isRecurringChanged } from '@/app/modules/course/utils';

import {
  EVENT_EDIT_INTERVAL_VALIDATION_ERROR_MESSAGE,
  EVENT_OVERLAP_ERROR_MESSAGE,
  RECURRENCE_TYPE_IDS,
} from './constants';
import { EventForm } from './EventForm';
import {
  CalendarEvent,
  CalendarEventFormValues,
  EditableTimeSlotEntity,
  RecurrenceTypeID,
  TimeSlotProposalPriceTypeID,
} from './models';
import {
  getNearestDate,
  isCalendarEventOverlapAnyIntervalTreeNode,
  transformEventFormValuesToCalendarEvent,
} from './utils';

type EditEventModalConfig = {
  duration: number;
  intervalTree: IntervalTree;
  onSubmit?: (calendarEvent: CalendarEvent) => void | unknown;
  onDeleteEvent?: (calendarEvent: CalendarEvent) => void | unknown;
  getInitialTimeSlot?: (calendarEvent: CalendarEvent) => EditableTimeSlotEntity | unknown;
  isEditable?: boolean;
  scheduleFormik?: FormikContextType<ScheduleStepModel>;
};

type EditEventModalInstance = {
  duration: number;
  isShown: boolean;
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  isEditModalOpen: boolean;
  setIsEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formik: FormikContextType<CalendarEventFormValues>;
  onDeleteEvent?: (calendarEvent: CalendarEvent) => void | unknown;
  onSubmit?: (calendarEvent: CalendarEvent) => void | unknown;
  hasIndividualCheckboxOption: boolean;
  setHasIndividualCheckboxOption: React.Dispatch<React.SetStateAction<boolean>>;
  hasThisAndFutureCheckboxOption: boolean;
  setHasThisAndFutureCheckboxOption: React.Dispatch<React.SetStateAction<boolean>>;
  hasRecurringEventCheckboxOption: boolean;
  setHasRecurringEventCheckboxOption: React.Dispatch<React.SetStateAction<boolean>>;
  isEditable?: boolean;
};

export function useEditEventModal({
  duration,
  intervalTree,
  onSubmit,
  onDeleteEvent,
  getInitialTimeSlot,
  isEditable = false,
  scheduleFormik,
}: EditEventModalConfig): EditEventModalInstance {
  const [isShown, setIsShown] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [hasIndividualCheckboxOption, setHasIndividualCheckboxOption] = useState(true);
  const [hasThisAndFutureCheckboxOption, setHasThisAndFutureCheckboxOption] = useState(true);
  const [hasRecurringEventCheckboxOption, setHasRecurringEventCheckboxOption] = useState(true);

  const initialValues = useMemo<CalendarEventFormValues>(
    () => ({
      id: undefined,
      recurrenceTypeID: RecurrenceTypeID.Never,
      startDateTime: getNearestDate(),
      endRecur: null,
      daysOfWeek: [],
      startHour: getNearestDate(),
      recurrenceTypes: [],
      count: null,
      interval: null,
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        recurrenceTypeID: yup.number().oneOf(RECURRENCE_TYPE_IDS),
        startDateTime: yup.date().required(),
        startHour: yup.string().allowedHours().required(),
        endRecur: yup.date().when('recurrenceTypeID', (recurrenceTypeID: RecurrenceTypeID, schema: yup.DateSchema) => {
          if (recurrenceTypeID === RecurrenceTypeID.Never || recurrenceTypeID === RecurrenceTypeID.DailyInterval) {
            return schema.nullable().notRequired();
          }

          return schema.required();
        }),
        proposalStudent: yup.object().requiredProposalStudent(),
        proposalCourse: yup.object().requiredProposalCourse(),
        proposalPrice: yup.number().when('proposalPriceType', (proposalPriceType: string, schema: yup.NumberSchema) => {
          if (Number(proposalPriceType) === TimeSlotProposalPriceTypeID.Special) {
            return schema.required().min(MINIMUM_SPECIAL_LESSON_PRICE);
          }

          return schema.nullable().notRequired();
        }),
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function handleEditModalOptions(
    changedCalendarEvent: CalendarEvent,
    formikHelpers: FormikHelpers<CalendarEventFormValues>
  ) {
    const initialTimeSlot = getInitialTimeSlot?.(changedCalendarEvent) as EditableTimeSlotEntity;

    if (!initialTimeSlot) {
      return;
    }

    const hasRecurringChanged = isRecurringChanged(initialTimeSlot, changedCalendarEvent);
    const hasProposalChanged = Number(initialTimeSlot.hasProposal) !== Number(changedCalendarEvent.hasProposal);

    if (initialTimeSlot?.recurrenceTypeID === RecurrenceTypeID.Never) {
      if (hasRecurringChanged || hasProposalChanged) {
        onSubmit?.(changedCalendarEvent);
      }
      formikHelpers.setSubmitting(false);
      scheduleFormik?.setSubmitting(false);
      setIsShown(false);
      return;
    }

    const initialStartRecur = initialTimeSlot.startRecur;
    const currentStartRecur = format(
      (changedCalendarEvent.startRecur ?? changedCalendarEvent.start) as Date,
      DEFAULT_DATETIME_FORMAT
    );

    if (initialStartRecur === currentStartRecur && !hasRecurringChanged && !hasProposalChanged) {
      formikHelpers.setSubmitting(false);
      scheduleFormik?.setSubmitting(false);
      setIsShown(false);
      return;
    }

    if (hasRecurringChanged) {
      setHasIndividualCheckboxOption(false);
      setHasThisAndFutureCheckboxOption(false);
    }

    if (initialStartRecur !== currentStartRecur && !hasRecurringChanged) {
      setHasIndividualCheckboxOption(true);
      if (initialTimeSlot.startDateTime !== initialStartRecur) {
        setHasRecurringEventCheckboxOption(false);
      }
    }

    if (initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.WeeklyInterval) {
      setHasThisAndFutureCheckboxOption(false);
    }

    setIsEditModalOpen(true);
  }

  const handleInternalSubmit = useCallback(
    (values: CalendarEventFormValues, formikHelpers: FormikHelpers<CalendarEventFormValues>) => {
      // STEP 1: Transform to CalendarEvent.
      const event = transformEventFormValuesToCalendarEvent(values, duration);

      // STEP 2: Validate if overlaps any event.
      if (isCalendarEventOverlapAnyIntervalTreeNode(event, intervalTree)) {
        Toastr.error(EVENT_OVERLAP_ERROR_MESSAGE);
        formikHelpers.setSubmitting(false);
        scheduleFormik?.setSubmitting(false);
        return;
      }
      setIsShown(false);
      handleEditModalOptions(event, formikHelpers);
    },
    [duration, intervalTree, onSubmit] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const formik = useForm<CalendarEventFormValues>({
    initialValues,
    validationSchema,
    onSubmit: handleInternalSubmit,
  });

  return {
    duration,
    isShown,
    setIsShown,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
    formik,
    onDeleteEvent,
    onSubmit,
    hasIndividualCheckboxOption,
    setHasIndividualCheckboxOption,
    hasThisAndFutureCheckboxOption,
    setHasThisAndFutureCheckboxOption,
    hasRecurringEventCheckboxOption,
    setHasRecurringEventCheckboxOption,
    isEditable,
  };
}

type EditEventModalProps = {
  instance: EditEventModalInstance;
  recurrenceTypeOptions: RecurrenceType[];
  scheduleFormik?: FormikContextType<ScheduleStepModel>;
  getInitialTimeSlot?: (calendarEvent: CalendarEvent) => EditableTimeSlotEntity | unknown;
};

export function EditEventModal({
  instance: {
    duration,
    isShown,
    setIsShown,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
    formik,
    onDeleteEvent,
    onSubmit,
    hasIndividualCheckboxOption,
    setHasIndividualCheckboxOption,
    hasThisAndFutureCheckboxOption,
    setHasThisAndFutureCheckboxOption,
    hasRecurringEventCheckboxOption,
    setHasRecurringEventCheckboxOption,
    isEditable,
  },
  recurrenceTypeOptions,
  scheduleFormik,
  getInitialTimeSlot,
}: EditEventModalProps) {
  const isStartDateTimeDisabled = useMemo(
    () => {
      if (formik.values.recurrenceTypeID === RecurrenceTypeID.Never) {
        return false;
      }

      return isBefore(formik.initialValues.startDateTime, new Date());
    },
    [formik.initialValues.startDateTime, formik.values.recurrenceTypeID, formik.values.recurrenceTypes] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleClose = useCallback(() => {
    setIsShown(false);
  }, [setIsShown]);

  function resetModalOptions() {
    setHasIndividualCheckboxOption(true);
    setHasThisAndFutureCheckboxOption(true);
    setHasRecurringEventCheckboxOption(true);
  }

  function closeDeleteModal() {
    resetModalOptions();
    setIsDeleteModalOpen(false);
  }

  function closeEditModal() {
    resetModalOptions();
    setIsEditModalOpen(false);
  }

  const handleDeleteClick = useCallback(() => {
    setIsShown(false);
    if (formik.values.recurrenceTypeID == RecurrenceTypeID.Never) {
      onDeleteEvent?.(formik.values as CalendarEvent);
      return;
    }
    setIsDeleteModalOpen(true);
  }, [formik.values, onDeleteEvent, setIsDeleteModalOpen, setIsShown]);

  function handleDelete() {
    closeDeleteModal();
    onDeleteEvent?.(formik.values as CalendarEvent);
  }

  function handleEdit() {
    closeEditModal();

    const event = transformEventFormValuesToCalendarEvent(formik.values, duration);
    const initialTimeSlot = getInitialTimeSlot?.(event) as EditableTimeSlotEntity;

    const hasIntervalErrors = hasIntervalValidationErrors(
      formik.values,
      duration as number,
      scheduleFormik?.values?.calendarEventType as CalendarEventType,
      initialTimeSlot
    );
    if (hasIntervalErrors) {
      Toastr.error(EVENT_EDIT_INTERVAL_VALIDATION_ERROR_MESSAGE);
      return;
    }
    onSubmit?.(event);
  }

  function handleNoStartRecurChange() {
    if (
      isEqual(formik.values.startDateTime, formik.initialValues.startDateTime) &&
      isEqual(formik.values.startHour, formik.initialValues.startHour) &&
      Number(formik.values.hasProposal) === Number(formik.initialValues.hasInitialProposal)
    ) {
      formik.setFieldValue('startDateTime', formik.values.recurringStartRecur);
      formik.setFieldValue('startHour', formik.values.recurringStartRecur);
    }
  }

  return (
    <>
      <Modal show={isShown} onEscapeKeyDown={handleClose}>
        <Modal.Header closeButton onHide={handleClose}>
          <Modal.Title>Редактирай свободно време</Modal.Title>
        </Modal.Header>
        <EventForm
          formik={formik}
          duration={duration}
          isStartDateTimeDisabled={isStartDateTimeDisabled}
          recurrenceTypeOptions={recurrenceTypeOptions}
        >
          <Modal.Footer>
            <Button variant="light-danger" className="me-auto" onClick={handleDeleteClick}>
              Изтрий
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Отказ
            </Button>
            <Button variant="primary" onClick={handleNoStartRecurChange} type="submit">
              Запази
            </Button>
          </Modal.Footer>
        </EventForm>
      </Modal>
      {isEditable && (
        <>
          <EventTypeActionModal isShown={isDeleteModalOpen} onHide={closeDeleteModal} onSubmit={handleDelete} />
          <EventTypeActionModal
            isShown={isEditModalOpen}
            onHide={closeEditModal}
            onSubmit={handleEdit}
            isEditAction
            hasIndividualCheckboxOption={hasIndividualCheckboxOption}
            hasThisAndFutureCheckboxOption={hasThisAndFutureCheckboxOption}
            hasRecurringEventCheckboxOption={hasRecurringEventCheckboxOption}
          />
        </>
      )}
    </>
  );
}
