import { Button } from '@/app/components/Button/Button';
import { StepperInstance } from '@/app/components/Stepper/models';
import { Stepper } from '@/app/components/Stepper/Stepper';
import { StepperItem } from '@/app/components/Stepper/StepperItem';

import {
  ABOUT_DETAILS_STEP_TITLE,
  BANK_DETAILS_STEP_TITLE,
  IDENTITY_DOCUMENTS_STEP_TITLE,
  INVOICE_DETAILS_STEP_TITLE,
  TUTOR_PROFILE_DETAILS_STEP_TITLE,
  TUTOR_PROFILE_PREVIEW_STEP_TITLE,
} from '../constants';
import { BankDetailsStep } from '../steps/BankDetailsStep';
import { IdentityDocumentsStep } from '../steps/IdentityDocumentsStep';
import { InvoiceDetailsStep } from '../steps/InvoiceDetailsStep';
import { TutorAboutDetailsStep } from '../steps/TutorAboutDetailsStep';
import { TutorProfileDetailsStep } from '../steps/TutorProfileDetailsStep';
import { TutorProfilePreviewStep } from '../steps/TutorProfilePreviewStep';

type TutorProfileStepperProps = {
  currentStep: number | null;
  stepperInstance: StepperInstance;
  isSubmitting: boolean;
  isEditMode?: boolean;
};

export function TutorProfileStepper({
  currentStep,
  stepperInstance,
  isSubmitting,
  isEditMode = false,
}: TutorProfileStepperProps) {
  return (
    <Stepper
      stepperInstance={stepperInstance}
      nav={
        <>
          <StepperItem number={1} description={TUTOR_PROFILE_DETAILS_STEP_TITLE} />
          <StepperItem number={2} description={ABOUT_DETAILS_STEP_TITLE} />
          <StepperItem number={3} description={IDENTITY_DOCUMENTS_STEP_TITLE} />
          <StepperItem number={4} description={INVOICE_DETAILS_STEP_TITLE} />
          <StepperItem number={5} description={BANK_DETAILS_STEP_TITLE} />
          <StepperItem number={6} description={TUTOR_PROFILE_PREVIEW_STEP_TITLE} />
        </>
      }
      submitButton={
        <Button type="submit" isLoading={isSubmitting}>
          {isEditMode ? 'Редактирай' : 'Завърши'}
        </Button>
      }
    >
      {currentStep === 1 && <TutorProfileDetailsStep isEditMode={isEditMode} />}
      {currentStep === 2 && <TutorAboutDetailsStep />}
      {currentStep === 3 && <InvoiceDetailsStep />}
      {currentStep === 4 && <BankDetailsStep />}
      {currentStep === 5 && <IdentityDocumentsStep />}
      {currentStep === 6 && <TutorProfilePreviewStep />}
    </Stepper>
  );
}
