export function getPagesCount(total: number, perPage: number) {
  return Math.ceil(total / perPage);
}

export function getPages(currentPage: number, pagesCount: number, shownPagesCount = 5) {
  const result: number[] = [];

  if (!currentPage) {
    return result;
  }

  if (pagesCount === 1) {
    result.push(1);
    return result;
  }

  if (pagesCount < currentPage) {
    return result;
  }

  if (pagesCount < shownPagesCount + 1) {
    for (let i = 1; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (currentPage === 1) {
    for (let i = 1; i < shownPagesCount + 1; i++) {
      if (i < pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  if (currentPage === pagesCount) {
    for (let i = pagesCount - shownPagesCount + 1; i <= pagesCount; i++) {
      if (i <= pagesCount) {
        result.push(i);
      }
    }
    return result;
  }

  const shiftCount = Math.floor(shownPagesCount / 2);
  if (shiftCount < 1) {
    result.push(currentPage);
    return result;
  }

  if (currentPage < shiftCount + 2) {
    for (let i = 1; i < shownPagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  if (pagesCount - currentPage < shiftCount + 2) {
    for (let i = pagesCount - shownPagesCount; i < pagesCount + 1; i++) {
      result.push(i);
    }
    return result;
  }

  for (let i = currentPage - shiftCount; i < currentPage; i++) {
    if (i > 0) {
      result.push(i);
    }
  }

  result.push(currentPage);

  for (let i = currentPage + 1; i < currentPage + shiftCount + 1; i++) {
    if (i <= pagesCount) {
      result.push(i);
    }
  }

  return result;
}
