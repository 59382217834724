import { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import * as H from 'history';

import { LinkButton } from './LinkButton';

type CancelButtonProps<S> = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  children?: ReactNode;
};

export function CancelButton<S>({ to, children = 'Откажи' }: CancelButtonProps<S>) {
  const formik = useFormikContext();

  return (
    <LinkButton to={to} variant="light-primary" isDisabled={formik.isSubmitting}>
      {children}
    </LinkButton>
  );
}
