import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TestRoom } from './models';

const ENDPOINT_PREFIX = '/ajax/test-room';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
};

//#region Get or create test room
export type GetOrCreateTestRoomResponse = TestRoom;

export const getOrCreateTestRoom = createAsyncThunk('testRoom/getOrCreateTestRoom', async function () {
  const response = await axios.put<GetOrCreateTestRoomResponse>(ENDPOINTS.resource);

  return response.data;
});
//#endregion Get or create test room
