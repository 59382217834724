import { useEffect, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { DropdownCheckboxControlItem } from '@/app/components/Dropdown/DropdownCheckboxControlItem';
import { DropdownMenu } from '@/app/components/Dropdown/DropdownMenu';
import { DropdownToggle } from '@/app/components/Dropdown/DropdownToggle';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { useForm } from '@/app/components/Form/useForm';
import { Select2Option } from '@/app/components/SelectControl/models';
import { getProfileMyStudentTimelineShowData } from '@/app/modules/profile/my-student/service';
import { getProfileTimelineShowData } from '@/app/modules/profile/timeline/service';
import { useQueryParams } from '@/app/utils/query';
import { useDebounce } from '@/app/utils/useDebounce';
import { LoadingState } from '@/redux/constants';

import { TimelineShowFilterInitialValues } from './constants';
import { TimelineShowFilterQueryParams, TimelineShowFiltersValues } from './models';
import { areFiltersApplied, transformTimelineShowFiltersFormikValuesToSearchParams } from './utils';

type TimelineShowFiltersProps = {
  loading: LoadingState;
  timelineEventTypeOptions: Select2Option[];
  timeSlotOptions: Select2Option[];
  page: number;
  onChange: () => void;
  onLoad: typeof getProfileMyStudentTimelineShowData | typeof getProfileTimelineShowData;
};

export function TimelineShowFilters({
  loading,
  timelineEventTypeOptions,
  timeSlotOptions,
  page,
  onChange,
  onLoad,
}: TimelineShowFiltersProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentSearchParams, queryParams } = useQueryParams<TimelineShowFilterQueryParams>();
  const debouncedFilters = useDebounce<TimelineShowFilterQueryParams>(queryParams, 600);

  //#region handle filters form data
  const initialValues = useMemo<TimelineShowFiltersValues>(
    () => ({
      timelineEventTypeIds: queryParams.timelineEventTypeIds ?? TimelineShowFilterInitialValues.timelineEventTypeIds,
      timeSlotIds: queryParams.timeSlotIds ?? TimelineShowFilterInitialValues.timelineEventTypeIds,
    }),
    [queryParams.timeSlotIds, queryParams.timelineEventTypeIds]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<TimelineShowFiltersValues>({
    initialValues,
    validationSchema,
  });

  function clearFilters() {
    if (areFiltersApplied(formik.values)) {
      formik.setValues(TimelineShowFilterInitialValues);
    }
  }
  //#endregion handle filters form data

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformTimelineShowFiltersFormikValuesToSearchParams(formik.values, queryParams);

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
      }

      onChange?.();

      if (newSearchParams === '') {
        formik.setValues(TimelineShowFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  useEffect(() => {
    if (loading === LoadingState.Idle) {
      dispatch(
        onLoad({
          timelineID: Number(id),
          page: page,
          filters: formik.values,
        })
      );
    }
  }, [id, page, debouncedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="d-xl-flex flex-wrap justify-content-end">
      <Form
        formik={formik}
        disabled={formik.isSubmitting}
        skipDirtyPrompt
        className="d-xl-flex flex-wrap justify-content-end"
      >
        <FormGroup className="mb-4 me-2">
          <Dropdown align="start" autoClose="outside">
            <DropdownToggle variant="white-bordered">
              Тип на събитие
              <i className="far fa-chevron-down ms-2" />
            </DropdownToggle>

            <DropdownMenu className="py-4" renderOnMount={false}>
              {timelineEventTypeOptions.map((type) => (
                <div key={type.id} className="d-flex flex-column align-items-start mb-2">
                  <DropdownCheckboxControlItem
                    name="timelineEventTypeIds"
                    label={type.text}
                    value={type.id as string}
                  />
                </div>
              ))}
            </DropdownMenu>
          </Dropdown>
        </FormGroup>

        <FormGroup className="mb-4 me-2">
          <Dropdown align="start" autoClose="outside">
            <DropdownToggle variant="white-bordered">
              Час
              <i className="far fa-chevron-down ms-2" />
            </DropdownToggle>

            <DropdownMenu className="py-4">
              <div className="overflow-scroll mh-200px">
                {timeSlotOptions.map((timeSlot) => (
                  <div key={timeSlot.id} className="d-flex flex-column align-items-start mb-2">
                    <DropdownCheckboxControlItem
                      name="timeSlotIds"
                      label={timeSlot.text}
                      value={timeSlot.id as string}
                    />
                  </div>
                ))}
              </div>
            </DropdownMenu>
          </Dropdown>
        </FormGroup>

        <Button onClick={clearFilters} variant="light-danger" className="align-self-start ms-xl-auto">
          <i className="fas fa-times p-0" />
        </Button>
      </Form>
    </div>
  );
}
