import clsx from 'clsx';

import { RichTextControl } from '@/app/components/RichTextControl/RichTextControl';

type MessengerReplyControlProps = {
  messengerReplyPlaceholder: string;
  className?: string;
  richTextClassName?: string;
};

export function MessengerReplyControl({
  messengerReplyPlaceholder,
  className = '',
  richTextClassName = '',
}: MessengerReplyControlProps) {
  return (
    <div className={clsx(className)}>
      <RichTextControl
        name="body"
        isToolbarShown={false}
        isCharacterCounterShown={false}
        isErrorShown={false}
        placeholder={messengerReplyPlaceholder}
        className={clsx('messenger-reply-form__rich-text border border-gray-400', richTextClassName)}
        submitAfterPressEnter
      />
    </div>
  );
}
