import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

import { CourseCheckoutForm } from './CourseCheckoutForm';

export function CourseCheckoutPage() {
  const isLoading = useAppSelector((state) => state.course.loading) === LoadingState.Pending;

  const stripePromise = useMemo(() => {
    return loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {
      locale: 'bg',
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Покупка на обучение</title>
      </Helmet>
      <Page>
        <Content>
          <PageTitle className="mb-9 text-center">Покупка на обучение</PageTitle>
          <Elements
            stripe={stripePromise}
            options={{
              locale: 'bg',
            }}
          >
            <CourseCheckoutForm />
          </Elements>
        </Content>
        {isLoading && <GlobalSpinner />}
      </Page>
    </>
  );
}
