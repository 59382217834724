import { createSlice } from '@reduxjs/toolkit';

import { OrderDetailsData } from '@/app/modules/order/models';
import { ReservationGroupDetails } from '@/app/modules/reservation-group/models';
import { getReservationGroupDetails, getReservationGroupSummary } from '@/app/modules/reservation-group/services';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

interface ReservationGroupState {
  loading: LoadingState;
  error?: string | null;
  details: ReservationGroupDetails;
  order: OrderDetailsData | null;
}

const initialState: ReservationGroupState = {
  loading: LoadingState.Idle,
  error: null,
  details: {
    course: {
      id: null,
      name: '',
      tutorID: null,
      tutorName: '',
      tutorProfileImagePath: null,
    },
    connectedStudent: {
      name: '',
      phone: '',
      email: '',
      connectedStudentLevelText: '',
      expectations: '',
    },
    reservations: [],
  },
  order: null,
};

const reservationGroupSlice = createSlice({
  name: 'reservationGroup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region Get course reservation group details
    builder
      .addCase(getReservationGroupDetails.pending, sharedPendingReducer)
      .addCase(getReservationGroupDetails.rejected, sharedRejectedReducer)
      .addCase(getReservationGroupDetails.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.details.course = action.payload.course;
        state.details.reservations = action.payload.reservations;
        state.details.connectedStudent = action.payload.connectedStudent;
      });
    //#endregion Get course reservation group details

    //#region Get course reservation group summary
    builder
      .addCase(getReservationGroupSummary.pending, sharedPendingReducer)
      .addCase(getReservationGroupSummary.rejected, sharedRejectedReducer)
      .addCase(getReservationGroupSummary.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.details.course = action.payload.course;
        state.details.reservations = action.payload.reservations;
        state.details.connectedStudent = action.payload.connectedStudent;
        state.order = action.payload.order;
      });
    //#endregion Get course reservation group summary
  },
});
export default reservationGroupSlice;
