import { ReactNode, useMemo } from 'react';
import BootstrapTable, {
  ColumnDescription,
  ColumnFormatter,
  ExpandRowProps,
  RowEventHandler,
  SelectRowProps,
} from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import clsx from 'clsx';
import produce from 'immer';

import { useAppDispatch } from '@/redux/store';

import { SortField, TableDataModel } from './models';
import { TablePagination } from './TablePagination';

type TableProps<T extends Record<string, unknown>> = {
  columns: ColumnDescription<T>[];
  data: TableDataModel<T[]>;
  isLoading: boolean;
  setCurrentPageAction: ActionCreatorWithPayload<number>;
  setSortBy?: ActionCreatorWithPayload<SortField>;
  actions?: ColumnFormatter<T, { isLoading: boolean }>;
  onRowClick?: RowEventHandler<T>;
  expandRow?: ExpandRowProps<T>;
  defaultSortDirection?: 'asc' | 'desc';
  selectRow?: SelectRowProps<T>;
  noDataIndication: ReactNode;
  rowClasses?: string;
  centerRowVertically?: boolean;
};

export function Table<T extends Record<string, unknown>>({
  columns,
  data,
  isLoading,
  setCurrentPageAction,
  setSortBy,
  actions,
  onRowClick = () => null,
  expandRow,
  defaultSortDirection = 'asc',
  selectRow = undefined,
  noDataIndication,
  rowClasses,
  centerRowVertically = false,
}: TableProps<T>) {
  const dispatch = useAppDispatch();
  const internalColumns = useMemo(() => {
    return produce(columns, (draft) => {
      if (actions) {
        draft.push({
          dataField: 'actions',
          text: 'Действия',
          formatter: actions,
          formatExtraData: { isLoading },
          classes: 'pr-0',
          headerClasses: 'pr-3',
          style: {
            minWidth: '100px',
          },
        });
      }
    });
  }, [actions, columns, isLoading]);

  function handleTableChange(
    type: string,
    { page, sortField, sortOrder }: { page: number; sortField: string; sortOrder: string }
  ) {
    switch (type) {
      case 'pagination':
        dispatch(setCurrentPageAction(page));
        break;
      case 'sort':
        if (setSortBy) {
          dispatch(setSortBy({ id: sortField, direction: sortOrder }));
        }
        break;

      default:
        break;
    }
  }

  const paginationOptions = {
    custom: true,
    totalSize: data?.total,
    sizePerPage: data?.perPage,
    page: data.currentPage,
    paginationSize: 5,
  };

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => (
        <>
          <BootstrapTable
            {...paginationTableProps}
            keyField="id"
            data={data?.data ?? []}
            columns={internalColumns}
            wrapperClasses="table-responsive"
            classes="table-striped table-hover table-rounded border border-gray-300 table-row-bordered table-row-gray-300 gy-5 gs-5 bg-white"
            bootstrap4
            bordered={false}
            remote
            onTableChange={handleTableChange}
            defaultSortDirection={defaultSortDirection}
            selectRow={selectRow}
            expandRow={expandRow}
            rowEvents={{ onClick: onRowClick }}
            noDataIndication={() => <>{noDataIndication}</>}
            rowClasses={clsx(rowClasses, {
              'align-middle': centerRowVertically,
            })}
          />
          <TablePagination isLoading={isLoading} paginationProps={paginationProps} />
        </>
      )}
    </PaginationProvider>
  );
}
