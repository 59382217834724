import React, { useEffect, useState } from 'react';
import Feedback from 'react-bootstrap/Feedback';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import Toastr from 'toastr';

import { LoadingState } from '@/redux/constants';

import { acceptedFileTypes, NO_AVATAR_PATH } from './constants';

type PictureControlProps = {
  name: string;
  initialPicture?: string | null;
  defaultPicture?: string;
  accept?: string;
  maxSize?: number;
  isRequired?: boolean;
};

export function PictureControl({
  name,
  initialPicture = null,
  defaultPicture = NO_AVATAR_PATH,
  accept = '.png, .jpg, .jpeg',
  maxSize = 5242880, // 5MB
  isRequired = false,
}: PictureControlProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<File | null>(name);
  const fieldHelpers = formik.getFieldHelpers<File | null>(name);
  const [picture, setPicture] = useState(initialPicture ?? defaultPicture);
  const isInvalid = Boolean(field.error);

  useEffect(
    function handleInitialPicture() {
      if (formik.status === LoadingState.Idle) {
        loadUploadedFile(field?.value);
      }
    },
    [formik.status, initialPicture] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function resetUploadedFile() {
    setPicture(initialPicture ?? defaultPicture);
    fieldHelpers.setValue(null);
  }

  function loadUploadedFile(file: File | null) {
    if (!(file instanceof File)) {
      resetUploadedFile();
      return;
    }

    if (!file.type.includes('image')) {
      resetUploadedFile();
      return;
    }

    const reader = new FileReader();

    reader.addEventListener('loadend', () => {
      setPicture(reader.result as string);
    });

    reader.readAsDataURL(file);
  }

  async function handleValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e?.target?.files ?? [];
    if (files.length === 0) {
      return;
    }
    const [file] = files;
    // File is too large -> reject it.
    if (file.size > maxSize) {
      const maxSizeKB = maxSize / 1024;
      const error = `Избрания файл не беше добавен поради повишаването на лимита за размер от ${maxSizeKB}KB: `;
      fieldHelpers.setError(error);
      resetUploadedFile();
      return;
    }

    if (!acceptedFileTypes.includes(file.type)) {
      Toastr.error(`Избрания файл не беше добавен, защото не e от тип ${accept}.`);
      resetUploadedFile();
      return;
    }

    loadUploadedFile(file);
    fieldHelpers.setValue(file);
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${picture ?? defaultPicture})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
        className={clsx('image-input image-input-empty w-125px h-125px', { 'is-invalid': isInvalid })}
      >
        <label
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
          data-kt-image-input-action="change"
          title="Профилна снимка"
        >
          <i className="fa fa-pencil" />
          <input name={name} type="file" onChange={handleValueChange} accept={accept} />
        </label>
      </div>
      {!isInvalid && isRequired && <p className="mw-125px text-center text-danger">*Задължително</p>}

      {isInvalid && <Feedback type="invalid">{field.error}</Feedback>}
    </>
  );
}
