export const DEFAULT_SHORT_DATE_FORMAT = 'dd.MM';
export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
export const DEFAULT_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';

export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DATE_MONTH_FORMAT = 'dd MMMM yyyy';

export const DEFAULT_START_TIME_DIFFERENCE_IN_MINUTES = 5;

export const START_OF_DAY_TIME_IN_HOURS = 6;
export const END_OF_DAY_TIME_IN_HOURS = 21;

export const CALENDAR_CALLBACK_DATE_FORMAT = 'yyyy-MM-dd';
