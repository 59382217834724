import { useEffect, useRef, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
// eslint-disable-next-line import/no-duplicates
import { format, parse } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { bg } from 'date-fns/locale';

import { Button } from '@/app/components/Button/Button';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { DEFAULT_DATE_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { CourseRatingStars } from '@/app/modules/course/components/CourseRating/CourseRatingStars';

const DEFAULT_ELLIPSE_MAX_LINE = 6;
const DEFAULT_ELLIPSE_MAX_EXPAND_LINE = 100;

type CourseCommentProps = {
  authorName: string;
  courseRating: number;
  courseComment: string;
  createdAt: string;
};

type EllipsisStateProps = {
  clamped: boolean;
  text: string;
};

export function CourseFeedbackComment({ authorName, courseComment, courseRating, createdAt }: CourseCommentProps) {
  const lineEllipsisRef = useRef<CustomLinesEllipsis | null>(null);
  const [isExternallyClamped, setIsExternallyClamped] = useState(false);
  const [maxLines, setMaxLines] = useState<number>(DEFAULT_ELLIPSE_MAX_LINE);
  const [isClamped, setIsClamped] = useState<boolean>(isExternallyClamped);
  const parsedDate = parse(createdAt, DEFAULT_DATE_FORMAT, new Date());
  const date = format(parsedDate, DEFAULT_DATE_FORMAT, { locale: bg });

  function handleReflow({ clamped }: EllipsisStateProps) {
    setIsClamped(clamped);
  }

  function handleCourseCommentCollapseToggle() {
    if (isClamped) {
      setMaxLines(DEFAULT_ELLIPSE_MAX_EXPAND_LINE);
      setIsClamped(false);
    } else {
      setMaxLines(DEFAULT_ELLIPSE_MAX_LINE);
      setIsClamped(true);
    }
  }

  useEffect(function handleInitialIsClamped() {
    if (lineEllipsisRef.current) {
      setIsExternallyClamped(lineEllipsisRef.current?.isClamped?.());
    }
  }, []);

  useEffect(
    function handleCourseCommentChanged() {
      if (lineEllipsisRef.current) {
        setIsExternallyClamped(lineEllipsisRef.current?.isClamped?.());
      }
    },
    [courseComment]
  );

  return (
    <Card className="h-100 border shadow-none ">
      <CardBody className="p-4">
        <div className="d-flex flex-wrap justify-content-between mb-4">
          <CourseRatingStars currentRating={courseRating} />
          <span className="ms-1 text-muted">{date}</span>
        </div>
        <p className="fs-5 fw-bold mb-1">{authorName}</p>
        <LinesEllipsis
          text={courseComment ?? ''}
          maxLine={maxLines}
          basedOn="letters"
          onReflow={handleReflow}
          ref={(node) => (lineEllipsisRef.current = node as unknown as CustomLinesEllipsis)}
          className="text-break"
        />
        <Button variant="link" className="pb-0" onClick={handleCourseCommentCollapseToggle}>
          {isClamped && 'Покажи повече'}
          {!isClamped && isExternallyClamped && 'Покажи по-малко'}
        </Button>
      </CardBody>
    </Card>
  );
}
