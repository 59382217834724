import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import clsx from 'clsx';

type CardHeaderProps = {
  children: ReactNode;
  className?: string;
  isRow?: boolean;
};

export function CardHeader({ children, className, isRow = false }: CardHeaderProps) {
  return (
    <Card.Header
      className={clsx('py-7', className, {
        'flex-row': isRow,
        'flex-column': !isRow,
      })}
    >
      {children}
    </Card.Header>
  );
}
