import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { TutorState } from './models';
import {
  getAllTutors,
  getCreateTutorProfileData,
  getEditTutorProfileData,
  getTutorCourseFeedbacks,
  getTutorFirstCourseTransliteratedSlug,
  getTutorShowData,
  getTutorTimeSlots,
} from './service';

const initialState: TutorState = {
  loading: LoadingState.Idle,
  timeSlotsLoading: LoadingState.Idle,
  firstCourseTransliteratedSlugLoading: LoadingState.Idle,
  error: undefined,
  address: '',
  birthday: '',
  bankAccountIBAN: '',
  bankAccountOwnerName: '',
  postCode: '',
  picture: null,
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  username: '',
  about: EMPTY_VALUE,
  educationDegreeID: null,
  university: '',
  hasPedagogicalDegree: null,
  pedagogicalDegree: '',
  teachingExperienceID: null,
  hasStudentPrepareExperience: null,
  technicalExperience: null,
  approach: '',
  motivation: '',
  importance: '',
  pupilAchievement: '',
  profileDetailsAttachments: [],
  aboutDetailsAttachments: [],
  show: {
    name: '',
    about: EMPTY_VALUE,
    identityID: null,
    currentRating: '',
    ratingCount: 0,
    profileFile: '',
    courses: [],
    educationDegreeID: null,
    university: '',
    hasPedagogicalDegree: false,
    pedagogicalDegree: '',
    teachingExperienceID: null,
    hasStudentPrepareExperience: false,
    technicalExperience: null,
    approach: '',
    motivation: '',
    importance: '',
    pupilAchievement: '',
    profileDetailsAttachments: [],
    aboutDetailsAttachments: [],
    subjects: [],
    videoFileUrl: null,
    reactionTime: null,
    numberStudents: null,
    numberReservedLessons: null,
    uniqueVisitsCount: null,
    firstAvailableTutorTimeSlotTime: null,
    tutorID: null,
    hasAvailableFutureTimeSlots: true,
    courseFeedbacks: {
      loading: LoadingState.Idle,
      data: [],
      currentPage: 1,
      hasMore: true,
    },
  },
  index: {
    tutorsTotal: 0,
    tutors: [],
    subjects: [],
  },
  edit: {
    tutorProfileDetails: {
      picture: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      username: '',
      about: EMPTY_VALUE,
      profileDetailsAttachments: [],
    },
    tutorAboutDetails: {
      educationDegreeID: null,
      university: '',
      hasPedagogicalDegree: false,
      pedagogicalDegree: '',
      teachingExperienceID: null,
      hasStudentPrepareExperience: false,
      technicalExperience: null,
      approach: '',
      motivation: '',
      importance: '',
      pupilAchievement: '',
      aboutDetailsAttachments: [],
    },
    invoiceDetails: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      birthday: '',
      address: '',
      city: undefined,
      postCode: '',
    },
    bankDetails: {
      bankAccountOwnerName: '',
      bankAccountIBAN: '',
    },
    identityDocuments: {
      identityDocumentFrontFile: null,
      identityDocumentBackFile: null,
      addressProofFile: null,
    },
  },
  timeSlots: [],
  firstCourseTransliteratedSlug: null,
};

const tutorSlice = createSlice({
  name: 'tutor',
  initialState,
  reducers: {
    resetTimeSlots(state) {
      state.timeSlots = initialState.timeSlots;
    },
    resetCourseFeedbacksSection(state) {
      state.show.courseFeedbacks = initialState.show.courseFeedbacks;
    },
    setCourseFeedbacksSectionCurrentPage(state, action: PayloadAction<number>) {
      state.show.courseFeedbacks.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    //#region Get create tutor profile
    builder
      .addCase(getCreateTutorProfileData.pending, (state) => {
        if (state.loading === LoadingState.Idle) {
          state.loading = LoadingState.Pending;
        }
      })
      .addCase(getCreateTutorProfileData.rejected, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getCreateTutorProfileData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.birthday = action.payload.birthday;
          state.address = action.payload.address;
          state.bankAccountOwnerName = action.payload.bankAccountOwnerName;
          state.firstName = action.payload.firstName;
          state.lastName = action.payload.lastName;
          state.phone = action.payload.phone;
          state.email = action.payload.email;
          state.username = action.payload.username;
          state.about = action.payload.about;
          state.picture = action.payload.picture;
        }
      });
    //#endregion Get create tutor profile

    //#region Get edit tutor profile
    builder
      .addCase(getEditTutorProfileData.pending, sharedPendingReducer)
      .addCase(getEditTutorProfileData.rejected, sharedRejectedReducer)
      .addCase(getEditTutorProfileData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.edit.tutorProfileDetails.picture = action.payload.tutorProfileDetails.picture;
          state.edit.tutorProfileDetails.firstName = action.payload.tutorProfileDetails.firstName;
          state.edit.tutorProfileDetails.lastName = action.payload.tutorProfileDetails.lastName;
          state.edit.tutorProfileDetails.phone = action.payload.tutorProfileDetails.phone;
          state.edit.tutorProfileDetails.email = action.payload.tutorProfileDetails.email;
          state.edit.tutorProfileDetails.username = action.payload.tutorProfileDetails.username;
          state.edit.tutorProfileDetails.about = action.payload.tutorProfileDetails.about;
          state.edit.tutorProfileDetails.profileDetailsAttachments =
            action.payload.tutorProfileDetails.profileDetailsAttachments;

          state.edit.tutorAboutDetails.educationDegreeID = action.payload.tutorAboutDetails.educationDegreeID;
          state.edit.tutorAboutDetails.university = action.payload.tutorAboutDetails.university;
          state.edit.tutorAboutDetails.hasPedagogicalDegree = action.payload.tutorAboutDetails.hasPedagogicalDegree;
          state.edit.tutorAboutDetails.pedagogicalDegree = action.payload.tutorAboutDetails.pedagogicalDegree;
          state.edit.tutorAboutDetails.teachingExperienceID = action.payload.tutorAboutDetails.teachingExperienceID;
          state.edit.tutorAboutDetails.hasStudentPrepareExperience =
            action.payload.tutorAboutDetails.hasStudentPrepareExperience;
          state.edit.tutorAboutDetails.technicalExperience = action.payload.tutorAboutDetails.technicalExperience;
          state.edit.tutorAboutDetails.approach = action.payload.tutorAboutDetails.approach;
          state.edit.tutorAboutDetails.motivation = action.payload.tutorAboutDetails.motivation;
          state.edit.tutorAboutDetails.importance = action.payload.tutorAboutDetails.importance;
          state.edit.tutorAboutDetails.pupilAchievement = action.payload.tutorAboutDetails.pupilAchievement;
          state.edit.tutorAboutDetails.aboutDetailsAttachments =
            action.payload.tutorAboutDetails.aboutDetailsAttachments;

          state.edit.invoiceDetails.firstName = action.payload.invoiceDetails.firstName;
          state.edit.invoiceDetails.lastName = action.payload.invoiceDetails.lastName;
          state.edit.invoiceDetails.phone = action.payload.invoiceDetails.phone;
          state.edit.invoiceDetails.email = action.payload.invoiceDetails.email;
          state.edit.invoiceDetails.birthday = action.payload.invoiceDetails.birthday;
          state.edit.invoiceDetails.address = action.payload.invoiceDetails.address;
          state.edit.invoiceDetails.city = action.payload.invoiceDetails.city;
          state.edit.invoiceDetails.postCode = action.payload.invoiceDetails.postCode;

          state.edit.bankDetails.bankAccountOwnerName = action.payload.bankDetails.bankAccountOwnerName;
          state.edit.bankDetails.bankAccountIBAN = action.payload.bankDetails.bankAccountIBAN;

          state.edit.identityDocuments.identityDocumentFrontFile =
            action.payload.identityDocuments.identityDocumentFrontFile;
          state.edit.identityDocuments.identityDocumentBackFile =
            action.payload.identityDocuments.identityDocumentBackFile;
          state.edit.identityDocuments.addressProofFile = action.payload.identityDocuments.addressProofFile;
        }
      });
    //#endregion Get edit tutor profile

    //#region Get tutor show data
    builder
      .addCase(getTutorShowData.pending, (state) => {
        if (state.loading === LoadingState.Idle) {
          state.loading = LoadingState.Pending;
        }
      })
      .addCase(getTutorShowData.rejected, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getTutorShowData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.show.name = action.payload.name;
          state.show.about = action.payload.about;
          state.show.identityID = action.payload.identityID;
          state.show.currentRating = action.payload.currentRating;
          state.show.ratingCount = action.payload.ratingCount;
          state.show.profileFile = action.payload.profileFile;
          state.show.courses = action.payload.courses;
          state.show.educationDegreeID = action.payload.educationDegreeID;
          state.show.university = action.payload.university;
          state.show.hasPedagogicalDegree = action.payload.hasPedagogicalDegree;
          state.show.pedagogicalDegree = action.payload.pedagogicalDegree;
          state.show.teachingExperienceID = action.payload.teachingExperienceID;
          state.show.hasStudentPrepareExperience = action.payload.hasStudentPrepareExperience;
          state.show.technicalExperience = action.payload.technicalExperience;
          state.show.approach = action.payload.approach;
          state.show.motivation = action.payload.motivation;
          state.show.importance = action.payload.importance;
          state.show.pupilAchievement = action.payload.pupilAchievement;
          state.show.profileDetailsAttachments = action.payload.profileDetailsAttachments;
          state.show.aboutDetailsAttachments = action.payload.aboutDetailsAttachments;
          state.show.subjects = action.payload.subjects;
          state.show.videoFileUrl = action.payload.videoFileUrl;
          state.show.reactionTime = action.payload.reactionTime;
          state.show.numberStudents = action.payload.numberStudents;
          state.show.numberReservedLessons = action.payload.numberReservedLessons;
          state.show.uniqueVisitsCount = action.payload.uniqueVisitsCount;
          state.show.firstAvailableTutorTimeSlotTime = action.payload.firstAvailableTutorTimeSlotTime;
          state.show.tutorID = action.payload.tutorID;
          state.show.hasAvailableFutureTimeSlots = action.payload.hasAvailableFutureTimeSlots;
          state.show.courseFeedbacks = action.payload.courseFeedbacks;
        }
      });
    //#endregion Get tutor show data

    //#region Get all tutors
    builder
      .addCase(getAllTutors.pending, sharedPendingReducer)
      .addCase(getAllTutors.rejected, sharedRejectedReducer)
      .addCase(getAllTutors.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.index.tutorsTotal = action.payload.tutorsTotal;
          state.index.tutors = action.payload.tutors;
          state.index.subjects = action.payload.subjects;
        }
      });
    //#endregion Get all tutors

    //#region Get tutor time slots index
    builder
      .addCase(getTutorTimeSlots.pending, (state) => {
        if (state.timeSlotsLoading === LoadingState.Idle) {
          state.timeSlotsLoading = LoadingState.Pending;
          state.timeSlots = initialState.timeSlots;
        }
      })
      .addCase(getTutorTimeSlots.rejected, (state, action) => {
        if (state.timeSlotsLoading === LoadingState.Pending) {
          state.timeSlotsLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getTutorTimeSlots.fulfilled, (state, action) => {
        state.timeSlotsLoading = LoadingState.Idle;
        state.timeSlots = action.payload.timeSlots;
      });
    //#endregion Get tutor time slots index

    //#region Get first course transliterated slug
    builder
      .addCase(getTutorFirstCourseTransliteratedSlug.pending, (state) => {
        if (state.firstCourseTransliteratedSlug === LoadingState.Idle) {
          state.firstCourseTransliteratedSlug = LoadingState.Pending;
        }
      })
      .addCase(getTutorFirstCourseTransliteratedSlug.rejected, (state, action) => {
        if (state.firstCourseTransliteratedSlug === LoadingState.Pending) {
          state.firstCourseTransliteratedSlug = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getTutorFirstCourseTransliteratedSlug.fulfilled, (state, action) => {
        state.firstCourseTransliteratedSlugLoading = LoadingState.Idle;
        state.firstCourseTransliteratedSlug = action.payload.firstCourseTransliteratedSlug;
      });
    //#endregion Get first course transliterated slug

    //#region Get tutor course feedbacks index
    builder
      .addCase(getTutorCourseFeedbacks.pending, (state) => {
        if (state.show.courseFeedbacks.loading === LoadingState.Idle) {
          state.show.courseFeedbacks.loading = LoadingState.Pending;
        }
      })
      .addCase(getTutorCourseFeedbacks.rejected, (state, action) => {
        if (state.show.courseFeedbacks.loading === LoadingState.Pending) {
          state.show.courseFeedbacks.loading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getTutorCourseFeedbacks.fulfilled, (state, action) => {
        state.show.courseFeedbacks.loading = LoadingState.Idle;
        state.show.courseFeedbacks.data = action.payload.courseFeedbacks.data;
        state.show.courseFeedbacks.currentPage = action.payload.courseFeedbacks.currentPage;
        state.show.courseFeedbacks.hasMore = action.payload.courseFeedbacks.hasMore;
      });
    //#endregion Get tutor course feedbacks index
  },
});

export default tutorSlice;
