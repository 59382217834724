import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { ReservationProposalApprovalPage } from '@/app/modules/reservation-proposal/ReservationProposalApprovalPage';
import { ReservationProposalSummaryPage } from '@/app/modules/reservation-proposal/ReservationProposalSummaryPage';

import { PrivateRoute } from '../auth/PrivateRoute';
import { ReservationProposalSuggestionCreatePage } from './ReservationProposalSuggestionCreatePage';
import { ReservationProposalSuggestionSummaryPage } from './ReservationProposalSuggestionSummaryPage';

export function ReservationProposalPage() {
  return (
    <Switch>
      <PrivateRoute path="/reservation-proposals/:id" exact>
        <ReservationProposalApprovalPage />
      </PrivateRoute>
      <PrivateRoute path="/reservation-proposals/:id/summary">
        <ReservationProposalSummaryPage />
      </PrivateRoute>
      <PrivateRoute path="/reservation-proposals/:id/suggestion">
        <ReservationProposalSuggestionCreatePage />
      </PrivateRoute>
      <PrivateRoute path="/reservation-proposals/:id/suggestion-summary">
        <ReservationProposalSuggestionSummaryPage />
      </PrivateRoute>
      <Route component={NoMatch} />
    </Switch>
  );
}
