import { useCallback, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import type { OptionData, PlainObject, QueryOptions } from 'select2';

import { Feedback } from '@/app/components/Feedback/Feedback';

import { BaseSelectControl } from './BaseSelectControl';
import { ExtendedSelect2Options, Select2Option, SharedSelectControlProps } from './models';
import { sharedPaginatedAjaxDataCallback } from './utils';

type AsyncSelectControlProps<T extends Select2Option> = SharedSelectControlProps & {
  endpoint: string;
  onChange?: (value: T) => void | undefined;
  dataCallback?: (params: QueryOptions) => string | PlainObject<unknown>;
};

export function AsyncSelectControl<T extends Select2Option>({
  endpoint,
  onChange,
  dataCallback = sharedPaginatedAjaxDataCallback,
  ...props
}: AsyncSelectControlProps<T>) {
  const isInternalChange = useRef(false);
  const formik = useFormikContext();
  const field = formik.getFieldMeta<T>(props.name);
  const fieldHelpers = formik.getFieldHelpers<T>(props.name);
  const fieldError = field.error as unknown as T;
  const internalOptions = useMemo<ExtendedSelect2Options>(() => {
    return {
      ajax: {
        url: endpoint,
        delay: 300,
        data: dataCallback,
      },
    };
  }, [dataCallback, endpoint]);

  const handleFormikChange = useCallback(($selectEl: JQuery<HTMLSelectElement>, fieldValue: T) => {
    if (isInternalChange.current) {
      isInternalChange.current = false;
      return;
    }

    const serializedValue = {
      id: '',
      text: '',
    };

    if (fieldValue !== undefined && fieldValue?.id !== null) {
      serializedValue.id = String(fieldValue?.id);
    }

    if (fieldValue !== undefined && fieldValue?.text !== null) {
      serializedValue.text = String(fieldValue?.text);
    }

    if (serializedValue.id !== '' && serializedValue.text !== '') {
      const option = new Option(serializedValue.text, String(serializedValue.id), true, true);
      $selectEl.append(option).trigger('change');
    }

    $selectEl.trigger({
      type: 'select2:select',
      params: {
        data: serializedValue,
      },
    });
  }, []);

  function internalHandleChange(optionData: OptionData) {
    // STEP 1: Serialize `OptionData`.
    const serializedValue: Record<string, unknown> = {};
    const skipValueKeys = ['disabled', 'element', 'selected', '_resultId', 'title'];

    for (const key in optionData) {
      if (!Object.prototype.hasOwnProperty.call(optionData, key)) {
        continue;
      }

      if (skipValueKeys.includes(key)) {
        continue;
      }

      serializedValue[key] = (optionData as unknown as Record<string, unknown>)?.[key];
    }

    serializedValue.id = Number(optionData.id);

    // Make sure there are no unnecessary assignments.
    if (Number(field.value?.id) === serializedValue.id && field.value?.text === serializedValue.text) {
      return;
    }

    // STEP 2: Set the new serialized value.
    fieldHelpers?.setTouched?.(true);
    fieldHelpers?.setValue?.(serializedValue as T);
    onChange?.(serializedValue as T);

    isInternalChange.current = true;
  }

  return (
    <>
      <BaseSelectControl
        options={internalOptions}
        onChange={internalHandleChange}
        onFormikChange={handleFormikChange}
        {...props}
      />

      {Boolean(fieldError) && <Feedback>{fieldError?.id}</Feedback>}
    </>
  );
}
