/**
 * @author Junaid Atari
 * @link https://github.com/blacksmoke26
 * @since 2020-09-20
 */

import { useEffect, useState } from 'react';
import debounce from 'debounce';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const SIZE_XS = 'xs';
export const SIZE_SM = 'sm';
export const SIZE_MD = 'md';
export const SIZE_LG = 'lg';
export const SIZE_XL = 'xl';
export const SIZE_XXL = 'xxl';

export const SMALL_BREAKPOINTS = [SIZE_XS, SIZE_SM];
export const MEDIUM_BREAKPOINTS = [SIZE_XS, SIZE_SM, SIZE_MD];
export const LARGE_BREAKPOINTS = [SIZE_LG, SIZE_XL, SIZE_XXL];
export const EXTRA_LARGE_BREAKPOINTS = [SIZE_XL, SIZE_XXL];

export const GRID_BREAKPOINTS = {
  SIZE_PX_XS: 0,
  SIZE_PX_SM: 576,
  SIZE_PX_MD: 768,
  SIZE_PX_LG: 992,
  SIZE_PX_XL: 1200,
  SIZE_PX_XXL: 1440,
};

export function resolveBreakpoint(width?: number): Breakpoint {
  if (!width && width !== 0) {
    width = window.innerWidth;
  }

  if (width < GRID_BREAKPOINTS.SIZE_PX_SM) {
    return SIZE_XS;
  } else if (width >= GRID_BREAKPOINTS.SIZE_PX_SM && width < GRID_BREAKPOINTS.SIZE_PX_MD) {
    return SIZE_SM;
  } else if (width >= GRID_BREAKPOINTS.SIZE_PX_MD && width < GRID_BREAKPOINTS.SIZE_PX_LG) {
    return SIZE_MD;
  } else if (width >= GRID_BREAKPOINTS.SIZE_PX_LG && width < GRID_BREAKPOINTS.SIZE_PX_XL) {
    return SIZE_LG;
  } else if (width >= GRID_BREAKPOINTS.SIZE_PX_XL && width < GRID_BREAKPOINTS.SIZE_PX_XXL) {
    return SIZE_XL;
  } else if (width >= GRID_BREAKPOINTS.SIZE_PX_XXL) {
    return SIZE_XXL;
  }

  return SIZE_XS;
}

/** Get Media Query Breakpoints in React */
export function useBreakpoint(): Breakpoint {
  const [size, setSize] = useState(() => resolveBreakpoint());

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setSize(resolveBreakpoint());
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return size;
}
