import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';

import { CourseIndexFilterNumberOfLessons, CourseIndexFilterNumberOfLessonsLabel } from '../../../models';
import { FilterDesktop } from './components/FilterDesktop';

export function NumberOfLessonsFilterPageDesktop() {
  return (
    <FilterDesktop name="Пакет от уроци" className="overflow-scroll mh-100px">
      <CheckboxControl
        name="numberOfLessons"
        value={CourseIndexFilterNumberOfLessons.FourLessons}
        label={CourseIndexFilterNumberOfLessonsLabel.FourLessons}
        className="d-flex align-items-center justify-content-between w-100 mb-3 cursor-pointer"
      />

      <CheckboxControl
        name="numberOfLessons"
        value={CourseIndexFilterNumberOfLessons.EightLessons}
        label={CourseIndexFilterNumberOfLessonsLabel.EightLessons}
        className="d-flex align-items-center justify-content-between w-100 mb-3 cursor-pointer"
      />

      <CheckboxControl
        name="numberOfLessons"
        value={CourseIndexFilterNumberOfLessons.SixteenLessons}
        label={CourseIndexFilterNumberOfLessonsLabel.SixteenLessons}
        className="d-flex align-items-center justify-content-between w-100 mb-3 cursor-pointer"
      />

      <CheckboxControl
        name="numberOfLessons"
        value={CourseIndexFilterNumberOfLessons.ThirtyTwoLessons}
        label={CourseIndexFilterNumberOfLessonsLabel.ThirtyTwoLessons}
        className="d-flex align-items-center justify-content-between w-100 mb-3 cursor-pointer"
      />
    </FilterDesktop>
  );
}
