import { ReactNode, useCallback, useMemo, useState } from 'react';

import { ConsultUsContext } from './ConsultUsContext';
import { BottomStickyVariant } from './models';

type ConsultProviderProps = {
  children: ReactNode;
};

export function ConsultUsProvider({ children }: ConsultProviderProps) {
  const [isVisible, setIsVisible] = useState(true);
  const [isPageLoaded, setIsPageLoaded] = useState(true);
  const [bottomStickyVariant, setBottomStickyVariant] = useState(BottomStickyVariant.Default);

  const show = useCallback(() => setIsVisible(true), []);
  const showOnLoadedPage = useCallback(() => setIsPageLoaded(true), []);

  const hide = useCallback(() => setIsVisible(false), []);
  const hideOnLoadingPage = useCallback(() => setIsPageLoaded(false), []);

  const setBottomSticky = useCallback((value) => setBottomStickyVariant(value), []);

  const contextValue = useMemo(
    () => ({
      isVisible,
      isPageLoaded,
      bottomStickyVariant,
      showOnLoadedPage,
      show,
      hide,
      hideOnLoadingPage,
      setBottomSticky,
    }),
    [isVisible, isPageLoaded, bottomStickyVariant, showOnLoadedPage, show, hide, hideOnLoadingPage, setBottomSticky]
  );

  return <ConsultUsContext.Provider value={contextValue}>{children}</ConsultUsContext.Provider>;
}
