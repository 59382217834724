import { ItemsPerPage } from './models';

interface ItemPerPageOption {
  id: ItemsPerPage;
  text: string;
}

export const ITEMS_PER_PAGE_DATA: ItemPerPageOption[] = [
  {
    id: 20,
    text: '20 на страница',
  },
  {
    id: 40,
    text: '40 на страница',
  },
  {
    id: 60,
    text: '60 на страница',
  },
  {
    id: 80,
    text: '80 на страница',
  },
  {
    id: 100,
    text: '100 на страница',
  },
];

export const ITEMS_PER_PAGE_SHORT_DATA: ItemPerPageOption[] = [
  {
    id: 20,
    text: '20',
  },
  {
    id: 40,
    text: '40',
  },
  {
    id: 60,
    text: '60',
  },
  {
    id: 80,
    text: '80',
  },
  {
    id: 100,
    text: '100',
  },
];
