import { SupportTicketAttachment, SupportTicketAttachmentResponse } from '@/app/modules/support-ticket/models';

/**
 * Normalize attachment.
 *
 * @param attachment SupportTicketAttachmentResponse to be normalized
 *
 * @returns normalized attachment
 */
export function normalizeAttachment(attachment: SupportTicketAttachmentResponse): SupportTicketAttachment {
  return {
    id: attachment.id,
    fileName: attachment.name,
    fileType: attachment.fileType,
    url: attachment.url,
    thumbnailUrl: attachment.thumbnailUrl,
  };
}
