import { useCallback } from 'react';

import { MenuComponent } from '@/_metronic/assets/ts/components';
import { InfiniteScroll } from '@/app/components/InfiniteScroll/InfiniteScroll';
import { MessengerNotificationItem } from '@/app/modules/messenger/MessengerNotificationItem';
import messengerSlice from '@/app/modules/messenger/store';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function MessengerNotificationsList() {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.messenger?.notifications ?? []);
  const authIdentityID = useAppSelector((state) => state.auth.identityID);

  const handleLoadMore = useCallback(() => {
    dispatch(messengerSlice.actions.loadMore());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNotificationItemClick = useCallback(() => {
    MenuComponent.hideDropdowns();
    dispatch(messengerSlice.actions.resetMessages());
  }, []);

  return (
    <>
      {notifications.threads?.map((thread) => (
        <MessengerNotificationItem
          key={thread.threadID}
          notification={thread}
          authIdentityID={authIdentityID}
          onClick={handleNotificationItemClick}
        />
      ))}

      {notifications.loading === LoadingState.Idle && notifications.threads?.length === 0 && (
        <p className="m-5 text-center">Нямате съобщения.</p>
      )}

      <InfiniteScroll hasMore={notifications.hasMore} onLoadMore={handleLoadMore} />
    </>
  );
}
