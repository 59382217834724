import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { useAppSelector } from '@/redux/store';

import './ErrorBasePage.scss';

type ErrorBasePageProps = {
  code: number;
  children?: React.ReactNode;
  actions?: React.ReactNode;
};

export function ErrorBasePage({ code, children, actions }: ErrorBasePageProps) {
  const httpStatusCode = useAppSelector((state) => state.errors.httpStatusCode);

  if (httpStatusCode !== code) {
    return null;
  }

  return (
    <Page>
      <Content>
        <PageTitle className="fw-bolder fs-2x text-center" isVisible={false}>
          Грешка {code}
        </PageTitle>

        <Row>
          <Col className="text-center">
            <h1 className="error-status-code font-weight-boldest text-dark-75">{code}</h1>
            <p className="font-size-h3 font-weight-light">{children}</p>
            {actions}
          </Col>
        </Row>
      </Content>
    </Page>
  );
}
