import { ReactNode } from 'react';
import { Nav } from 'react-bootstrap';
import clsx from 'clsx';

import './TabNav.scss';

type TabNavProps = {
  children: ReactNode;
  className?: string;
};

export function TabNav({ children, className }: TabNavProps) {
  return (
    <Nav
      variant="tabs"
      className={clsx('nav-line-tabs flex-nowrap nav-scroll', className)}
      activeKey={location.pathname}
    >
      {children}
    </Nav>
  );
}
