import { KTSVG } from '@/_metronic/helpers/components/KTSVG';

import { AlertIconVariant } from './models';

type AlertIconProps = {
  path: string;
  variant?: AlertIconVariant;
};

export function AlertIcon({ path, variant = 'info' }: AlertIconProps) {
  return <KTSVG path={path} className={`svg-icon-2hx svg-icon-${variant} me-3`} />;
}
