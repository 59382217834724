import { useMemo } from 'react';
import * as yup from 'yup';

export function useConnectedStudentValidationSchema() {
  return useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required().max(192),
        phone: yup.mixed().required().phone(),
        email: yup.string().required().email(),
        connectedStudentLevelID: yup.mixed().nullable(),
      }),
    []
  );
}
