import React from 'react';
import clsx from 'clsx';

import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { DayTimeInterval, DayTimeIntervalHours } from '@/app/modules/course/models';

type DayTimeIntervalControlProps = {
  name: string;
  formGroupClassName?: string;
  containerClassName?: string;
  checkboxOptionClassName?: string;
  hasBorder?: boolean;
};

export function DayTimeIntervalControl({
  name,
  formGroupClassName,
  containerClassName,
  checkboxOptionClassName,
  hasBorder = false,
}: DayTimeIntervalControlProps) {
  return (
    <FormGroup className={clsx(formGroupClassName)}>
      <Label>Времеви интервали</Label>
      <div className={clsx('d-flex flex-wrap', containerClassName)}>
        <CheckboxControl
          name={name}
          className={clsx(
            'd-block text-center w-125px h-100px rounded cursor-pointer pt-3 pb-2 me-3 mb-2',
            checkboxOptionClassName
          )}
          label={`${DayTimeIntervalHours.MorningStart} - ${DayTimeIntervalHours.LateMorningStart}`}
          labelSubtitle="Сутрин"
          value={DayTimeInterval.Morning}
          hasBorder={hasBorder}
        />
        <CheckboxControl
          name={name}
          className={clsx(
            'd-block text-center w-125px h-100px rounded cursor-pointer pt-3 pb-2 me-3 mb-2',
            checkboxOptionClassName
          )}
          label={`${DayTimeIntervalHours.LateMorningStart} - ${DayTimeIntervalHours.NoonStart}`}
          labelSubtitle="Късна сутрин"
          value={DayTimeInterval.LateMorning}
          hasBorder={hasBorder}
        />
        <CheckboxControl
          name={name}
          className={clsx(
            'd-block text-center w-125px h-100px rounded cursor-pointer pt-3 pb-2 me-3 mb-2',
            checkboxOptionClassName
          )}
          label={`${DayTimeIntervalHours.NoonStart} - ${DayTimeIntervalHours.AfternoonStart}`}
          labelSubtitle="Обяд"
          value={DayTimeInterval.Noon}
          hasBorder={hasBorder}
        />
        <CheckboxControl
          name={name}
          className={clsx(
            'd-block text-center w-125px h-100px rounded cursor-pointer pt-3 pb-2 me-3 mb-2',
            checkboxOptionClassName
          )}
          label={`${DayTimeIntervalHours.AfternoonStart} - ${DayTimeIntervalHours.EveningStart}`}
          labelSubtitle="Късен обяд"
          value={DayTimeInterval.Afternoon}
          hasBorder={hasBorder}
        />
        <CheckboxControl
          name={name}
          className={clsx(
            'd-block text-center w-125px h-100px rounded cursor-pointer pt-3 pb-2 me-3 mb-2',
            checkboxOptionClassName
          )}
          label={`${DayTimeIntervalHours.EveningStart} - ${DayTimeIntervalHours.DayEnd}`}
          labelSubtitle="Вечер"
          value={DayTimeInterval.Evening}
          hasBorder={hasBorder}
        />
      </div>
    </FormGroup>
  );
}
