import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { GeneralNotificationPage } from '@/app/modules/profile/notifications/edit/GeneralNotificationPage';
import { TutorNotificationPage } from '@/app/modules/profile/notifications/edit/TutorNotificationPage';

export function ProfileNotificationPage() {
  return (
    <Switch>
      <Route path="/profile/notifications" exact>
        <GeneralNotificationPage />
      </Route>
      <Route path="/profile/notifications/tutor" exact>
        <TutorNotificationPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
