import { MouseEventHandler } from 'react';

import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { HeaderMenuContentItem } from '@/app/layout/HeaderMenu/HeaderMenuContentItem';
import { HeaderMenuContentItemBody } from '@/app/layout/HeaderMenu/HeaderMenuContentItemBody';
import { HeaderMenuContentItemImg } from '@/app/layout/HeaderMenu/HeaderMenuContentItemImg';
import { HeaderMenuContentItemText } from '@/app/layout/HeaderMenu/HeaderMenuContentItemText';
import { HeaderMenuContentItemTime } from '@/app/layout/HeaderMenu/HeaderMenuContentItemTime';
import { MessengerNotificationItemValues } from '@/app/modules/messenger/models';

type MessengerThreadItemProps = {
  notification: MessengerNotificationItemValues;
  onClick: MouseEventHandler;
  authIdentityID?: number;
};

export function MessengerNotificationItem({ notification, onClick, authIdentityID }: MessengerThreadItemProps) {
  return (
    <HeaderMenuContentItem
      to={`/messenger/${notification.threadID}`}
      createdAt={notification.lastMessageCreatedAt}
      isRead={notification.isRead}
      onClick={onClick}
    >
      <HeaderMenuContentItemImg src={notification.imagePath ?? NO_AVATAR_PATH} alt="Профилна снимка" />
      <HeaderMenuContentItemBody>
        <HeaderMenuContentItemText className="fw-bold">{notification.participantName}</HeaderMenuContentItemText>
        {notification.lastMessageBody && (
          <HeaderMenuContentItemText className="text-truncate">
            {notification.lastMessageCreatorIdentityID === authIdentityID && 'Вие: '}
            {notification.lastMessageBody}
          </HeaderMenuContentItemText>
        )}
        {Boolean(notification.lastMessageCreatedAt) && (
          <HeaderMenuContentItemTime createdAt={notification.lastMessageCreatedAt} />
        )}
      </HeaderMenuContentItemBody>
    </HeaderMenuContentItem>
  );
}
