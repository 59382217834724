import { useCallback } from 'react';

import { InfiniteScroll } from '@/app/components/InfiniteScroll/InfiniteScroll';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import supportTicketSlice from './store';
import { SupportTicketNotificationItem } from './SupportTicketNotificationItem';

export function SupportTicketNotificationsList() {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.supportTicket);
  const handleLoadMore = useCallback(() => {
    dispatch(supportTicketSlice.actions.loadMoreNotifications());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {data.notifications.supportTickets?.map((supportTicket) => (
        <SupportTicketNotificationItem key={supportTicket.id} supportTicket={supportTicket} />
      ))}

      {data.notifications.loading === LoadingState.Idle && data.notifications.supportTickets?.length === 0 && (
        <p className="m-5 text-center">Нямате запитвания.</p>
      )}

      <InfiniteScroll hasMore={data.notifications.hasMore} onLoadMore={handleLoadMore} />
    </>
  );
}
