import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { ReservationStatusID, TimeSlotStatusID } from '@/app/components/Calendar/models';
import { ReservationCancelationValues, ReservationRejectionValues } from '@/app/modules/reservation/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

import { CanceledReservationDetails } from './../course/models';

const ENDPOINT_PREFIX = '/ajax/reservations';

export const ENDPOINTS = {
  approve: (id: number) => `${ENDPOINT_PREFIX}/${id}/approve`,
  cancel: (id: number) => `${ENDPOINT_PREFIX}/${id}/cancel`,
  reject: (id: number) => `${ENDPOINT_PREFIX}/${id}/reject`,
  canceledDetails: (id: number) => `${ENDPOINT_PREFIX}/${id}/canceled`,
};

//#region Update reservation status
export interface UpdateReservationStatusResponse {
  id: number;
  reservationStatusID: ReservationStatusID;
  timeSlotStatusID: TimeSlotStatusID;
  lessonID: number | null;
}
//#endregion Update reservation status

//#region Approve reservation
export const approveReservation = createAsyncThunk('reservation/approveReservation', async ({ id }: { id: number }) => {
  const response = await axios.put<UpdateReservationStatusResponse>(ENDPOINTS.approve(id));

  return response.data;
});
//#endregion Approve reservation

//#region Cancel reservation
export async function cancelReservation(reservationID: number, values: ReservationCancelationValues) {
  const formData = serializeFormData(values);
  const response = await axios.put<UpdateReservationStatusResponse>(ENDPOINTS.cancel(reservationID), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion Cancel reservation

//#region Reject reservation
export async function rejectReservation(reservationID: number, values: ReservationRejectionValues) {
  const formData = serializeFormData(values);
  const response = await axios.put<UpdateReservationStatusResponse>(ENDPOINTS.reject(reservationID), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion Reject reservation

//#region Get canceled reservation details
export type GetCanceledReservationDetailsResponse = CanceledReservationDetails;

export const getCanceledReservationDetails = createAsyncThunk(
  'course/getCanceledCourseReservationDetails',
  async function (reservationID: number) {
    const response = await axios.get<GetCanceledReservationDetailsResponse>(ENDPOINTS.canceledDetails(reservationID));
    return response.data;
  }
);
//#endregion Get canceled reservation details
