import { ReactNode } from 'react';
import clsx from 'clsx';

import './PromoBannerContainer.scss';

type PromoBannerContainerProps = {
  className?: string;
  children: ReactNode;
  contentClassName: string;
};

export function PromoBannerContainer({ className, children, contentClassName }: PromoBannerContainerProps) {
  return (
    <div className={clsx('promo-banner-container mt-2', className)}>
      <div className={clsx('promo-banner-content', contentClassName)}>{children}</div>
    </div>
  );
}
