import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://c7b5e1c25ac242cf9b5f75ada7858ade@o41346.ingest.sentry.io/6008735',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
  });
}
