import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { EditableTimeSlotEntity } from '@/app/components/Calendar/models';
import { CoursePackageModel, CoursePricingStrategyID } from '@/app/modules/course/create/models';
import { ProfileCourseScheduleCalendarEditProps } from '@/app/modules/schedule/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

import { Subject } from '../../course/models';
import {
  ProfileCourse,
  ProfileCourseDetails,
  ProfileCourseDetailsValues,
  ProfileCoursePackageValues,
  ProfileCourseStatusValues,
} from './models';

const ENDPOINT_PREFIX = '/ajax/profile/courses';

export const ENDPOINTS = {
  courses: ENDPOINT_PREFIX,
  status: {
    entity: (id: number) => `${ENDPOINT_PREFIX}/${id}/status`,
    edit: (id: number) => `${ENDPOINT_PREFIX}/${id}/edit/status`,
  },
  details: {
    entity: (id: number) => `${ENDPOINT_PREFIX}/${id}/details`,
    edit: (id: number) => `${ENDPOINT_PREFIX}/${id}/edit/details`,
  },
  schedule: {
    entity: (id: number) => `${ENDPOINT_PREFIX}/${id}/schedule`,
    timeSlots: (id: number) => `${ENDPOINT_PREFIX}/${id}/time-slots`,
    edit: (id: number) => `${ENDPOINT_PREFIX}/${id}/edit/schedule`,
  },
  packages: {
    entity: (id: number) => `${ENDPOINT_PREFIX}/${id}/packages`,
    edit: (id: number) => `${ENDPOINT_PREFIX}/${id}/edit/packages`,
  },
};

//#region PROFILE COURSES
export const getProfileCourses = createAsyncThunk('profile/getProfileCourses', async function () {
  const response = await axios.get<GetProfileCoursesResponse>(ENDPOINTS.courses);
  return response.data;
});

export interface GetProfileCoursesResponse {
  courses: ProfileCourse[];
}
//#endregion PROFILE COURSES

//#region COURSE STATUS

//#region Get profile course status show data
interface GetProfileCourseStatusShowDataResponse {
  courseName: string;
  courseStatusID: number;
}

export const getProfileCourseStatusShowData = createAsyncThunk(
  'profileCourse/getProfileCourseStatusShowData',
  async function (id: number) {
    const response = await axios.get<GetProfileCourseStatusShowDataResponse>(ENDPOINTS.status.entity(id));
    return response.data;
  }
);
//#endregion Get profile course status show data

//#region Get profile course status edit data
interface GetProfileCourseStatusEditDataResponse {
  courseName: string;
  courseStatusID: number;
}

export const getProfileCourseStatusEditData = createAsyncThunk(
  'profileCourse/getProfileCourseStatusEditData',
  async function (id: number) {
    const response = await axios.get<GetProfileCourseStatusEditDataResponse>(ENDPOINTS.status.edit(id));
    return response.data;
  }
);
//#endregion Get profile course status edit data

export async function updateProfileCourseStatus(id: number, values: ProfileCourseStatusValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.status.entity(id), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

//#endregion COURSE STATUS

//#region COURSE DETAILS

//#region Get profile course details show data
interface GetProfileCourseDetailsShowDataResponse {
  course: ProfileCourseDetails;
}

export const getProfileCourseDetailsShowData = createAsyncThunk(
  'profileCourse/getProfileCourseDetailsShowData',
  async function (id: number) {
    const response = await axios.get<GetProfileCourseDetailsShowDataResponse>(ENDPOINTS.details.entity(id));
    return response.data;
  }
);
//#endregion Get profile course details show data

//#region Get profile course details edit data
interface GetProfileCourseDetailsEditDataResponse {
  course: ProfileCourseDetails;
  subjects: Subject[];
}

export const getProfileCourseDetailsEditData = createAsyncThunk(
  'profileCourse/getProfileCourseDetailsEditData',
  async function (id: number) {
    const response = await axios.get<GetProfileCourseDetailsEditDataResponse>(ENDPOINTS.details.edit(id));
    return response.data;
  }
);
//#endregion Get profile course details edit data

export async function updateProfileCourseDetails(id: number, values: ProfileCourseDetailsValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.details.entity(id), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

//#endregion COURSE DETAILS

//#region COURSE SCHEDULE

//#region Get profile course schedule show data
interface GetProfileCourseScheduleShowDataResponse {
  courseName: string;
  reservationBufferHours: number;
  duration: number;
  recurrenceTypes: [];
}

export const getProfileCourseScheduleShowData = createAsyncThunk(
  'profileCourse/getProfileCourseScheduleShowData',
  async function (id: number) {
    const response = await axios.get<GetProfileCourseScheduleShowDataResponse>(ENDPOINTS.schedule.entity(id));
    return response.data;
  }
);
//#endregion Get profile course schedule show data

//#region Get profile course schedule edit data
interface GetProfileCourseScheduleEditDataResponse {
  courseName: string;
  reservationBufferHours: number;
  duration: number;
  canChangeCourseDuration: boolean;
  canUseLegacyCourseDuration: boolean;
  hasBlockedNewStudentsReservations: 0 | 1;
  recurrenceTypes: [];
}

export const getProfileCourseScheduleEditData = createAsyncThunk(
  'profileCourse/getProfileCourseScheduleEditData',
  async function ({ id, startDate, endDate }: ProfileCourseScheduleCalendarEditProps) {
    const response = await axios.get<GetProfileCourseScheduleEditDataResponse>(ENDPOINTS.schedule.edit(id), {
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  }
);
//#endregion Get profile course schedule edit data

//#region Get profile course schedule time slots
interface GetProfileCourseScheduleTimeSlotsResponse {
  timeSlots: EditableTimeSlotEntity[];
}

export const getProfileCourseScheduleTimeSlots = createAsyncThunk(
  'profileCourse/getProfileCourseScheduleTimeSlots',
  async function ({ id, startDate, endDate }: { id: number; startDate: string; endDate: string }) {
    const response = await axios.get<GetProfileCourseScheduleTimeSlotsResponse>(ENDPOINTS.schedule.timeSlots(id), {
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  }
);
//#endregion Get profile course schedule time slots

//#endregion COURSE SCHEDULE

//#region COURSE PACKAGES

//#region Get profile course packages show data
interface GetProfileCoursePackagesShowDataResponse {
  courseName: string;
  individualLesson?: CoursePackageModel;
  coursePackages: CoursePackageModel[];
  isSingleCoursePackageEnabled: boolean;
}

export const getProfileCoursePackagesShowData = createAsyncThunk(
  'profileCourse/getProfileCoursePackagesShowData',
  async function (id: number) {
    const response = await axios.get<GetProfileCoursePackagesShowDataResponse>(ENDPOINTS.packages.entity(id));
    return response.data;
  }
);
//#endregion Get profile course packages show data

//#region Get profile course packages edit data
interface GetProfileCoursePackagesEditDataResponse {
  courseName: string;
  isIndividualLessonEnabled: boolean;
  isCoursePackagesEnabled: boolean;
  individualLesson?: CoursePackageModel;
  coursePackages: CoursePackageModel[];
  archivedCoursePackages: CoursePackageModel[];
  coursePricingStrategyID: CoursePricingStrategyID;
  singlePackage?: CoursePackageModel;
}

export const getProfileCoursePackagesEditData = createAsyncThunk(
  'profileCourse/getProfileCoursePackagesEditData',
  async function (id: number) {
    const response = await axios.get<GetProfileCoursePackagesEditDataResponse>(ENDPOINTS.packages.edit(id));
    return response.data;
  }
);
//#endregion Get profile course packages edit data

export async function updateProfileCoursePackages(id: number, values: ProfileCoursePackageValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.packages.entity(id), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

//#endregion COURSE PACKAGES
