import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { parse } from 'date-fns';

import { DEFAULT_DATETIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { DEFAULT_RATING } from '@/app/components/Form/RatingControl/constants';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation } from '@/app/components/Form/utils';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformCustomEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { CourseFeedbackForm } from '@/app/modules/course-feedback/CourseFeedbackForm';
import { CourseFeedbackFormValues } from '@/app/modules/course-feedback/models';
import { updateCourseFeedback } from '@/app/modules/course-feedback/service';
import { useCourseFeedbackValidationSchema } from '@/app/modules/course-feedback/useCourseFeedbackValidationSchema';
import { LessonEndFeedbackForm } from '@/app/modules/lesson/LessonEndFeedbackForm';
import { FACEBOOK_PIXEL_CUSTOM_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { tagLessonCompletedEvent } from '@/app/utils/google-analytics/customEvents';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getLessonEnd } from './service';
import { isLessonOver } from './utils';

export function LessonEndPage() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const loading = useAppSelector((state) => state.lesson.loading);
  const data = useAppSelector((state) => state.lesson.end);
  const [_, isInitialDataLoaded] = useLoading(loading); // eslint-disable-line @typescript-eslint/no-unused-vars
  const isCurrentLessonOver = useMemo(() => {
    const endDateTime = parse(data.endDateTime, DEFAULT_DATETIME_FORMAT, new Date());

    return isLessonOver(endDateTime);
  }, [data.endDateTime]);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getLessonEnd(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //#region Course feedback
  const [showCourseFeedbackForm, setShowCourseFeedbackForm] = useState(true);
  const [isRated, setIsRated] = useState(false);

  function handleCourseFeedbackSectionHide() {
    setShowCourseFeedbackForm(false);
  }

  //#endregion Course feedback

  //#region Lesson feedback
  const [showLessonFeedbackForm, setShowLessonFeedbackForm] = useState(true);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  function handleLessonFeedbackClose() {
    setShowLessonFeedbackForm(false);
  }

  function handleLessonFeedbackSuccess() {
    setIsFeedbackSent(true);
    setShowLessonFeedbackForm(false);
  }

  //#endregion Lesson feedback

  useEffect(() => {
    async function handleLessonCompleted() {
      if (data.courseID && data.courseID > 0 && !data.lesson.isTutor) {
        const analyticsCustomEventItem = {
          item_name: `${data.lesson.subjectName} ${data.lesson.targetAudienceName} ${data.lesson.tutorName}`,
          item_id: Number(data.lesson.courseID),
          item_brand: data.lesson.tutorName,
          item_category: data.lesson.subjectName as string,
          item_category2: data.lesson.targetAudienceName as string,
        };
        const conversionEventValues = await transformCustomEventToConversionEventValues(
          FACEBOOK_PIXEL_CUSTOM_EVENTS.lessonCompleted,
          analyticsCustomEventItem
        );
        tagLessonCompletedEvent(analyticsCustomEventItem, conversionEventValues.eventID);
        trackAnalyticsEventConversionsAPI(conversionEventValues);
      }
    }

    handleLessonCompleted();
  }, [data]);

  //#region Handle form
  const initialValues = useMemo<CourseFeedbackFormValues>(
    () => ({
      courseRating: DEFAULT_RATING,
      courseComment: '',
    }),
    []
  );

  const { validationSchema } = useCourseFeedbackValidationSchema();

  const formik = useForm<CourseFeedbackFormValues>({
    initialValues,
    validationSchema,
    initialStatus: loading,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<CourseFeedbackFormValues>(async (values) => {
        return await updateCourseFeedback(Number(data.courseID), values);
      });
      const response = await submitWithValidation(values, formikHelpers);
      if (response) {
        setShowCourseFeedbackForm(false);
        setIsRated(true);
        formik.resetForm({ values });
      }
    },
  });
  //#endregion Handle form

  return (
    <Page>
      <Content>
        {isInitialDataLoaded && (
          <div className="mx-auto text-center py-9">
            <h1 className="text-gray-800 fs-2tx mb-9">Вашият урок приключи.</h1>

            {!isCurrentLessonOver && (
              <Link to={`/lessons/${id}`} className="btn btn-light-primary me-5">
                Назад към стаята
              </Link>
            )}

            <Link to="/my-schedule" className="btn btn-primary">
              Kъм Моят график
            </Link>

            {data.shouldLeaveFeedback && (
              <div className="mw-600px mx-auto d-flex flex-column pt-9">
                {isRated && !showCourseFeedbackForm && (
                  <>
                    <hr className="w-100 my-9" />
                    <h2 className="text-gray-800 fs-2x fw-bold mb-5">Благодарим Ви, че оценихте обучението</h2>
                  </>
                )}
                {showCourseFeedbackForm && (
                  <>
                    <hr className="w-100 my-9" />
                    <h2 className="text-gray-800 fs-2x fw-bold mb-5">Оценете обучението, на което присъствахте</h2>
                    <CourseFeedbackForm formik={formik} onSectionHide={handleCourseFeedbackSectionHide} />
                  </>
                )}
              </div>
            )}
            {data.shouldLeaveLessonFeedback && (
              <div className="mw-600px mx-auto pt-9">
                {isFeedbackSent && !showLessonFeedbackForm && (
                  <>
                    <hr className="w-100 my-9" />
                    <h2 className="text-gray-800 fs-2x fw-bold mb-5">Благодарим Ви, че добавихте коментар!</h2>
                  </>
                )}
                {showLessonFeedbackForm && (
                  <>
                    <hr className="w-100 my-9" />
                    <h2 className="text-gray-800 fs-2x fw-bold mb-5">
                      Проведохте урок с{' '}
                      <strong>
                        {data.connectedStudents.map((connectedStudent) => connectedStudent.name).join(', ')}
                      </strong>
                      . Моля, оставете коментар.
                    </h2>
                    <div className="text-gray-800 fs-2x fw-bold mb-5">
                      <LessonEndFeedbackForm
                        lessonID={Number(id)}
                        onSectionHide={handleLessonFeedbackClose}
                        onSuccess={handleLessonFeedbackSuccess}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {loading === LoadingState.Pending && <GlobalSpinner />}
      </Content>
    </Page>
  );
}
