import React from 'react';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';

import './TimelineAvatar.scss';

type TimelineAvatarProps = {
  avatarPath: string;
};

export function TimelineAvatar({ avatarPath }: TimelineAvatarProps) {
  return (
    <div className="timeline-avatar">
      <img alt="Профилна снимка" src={toAbsoluteUrl(avatarPath ?? NO_AVATAR_PATH)} />
    </div>
  );
}
