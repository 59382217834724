import { createContext } from 'react';

import { StepperInstance } from './models';

export const StepperContext = createContext<StepperInstance>({
  stepInstances: {},
  currentStep: null,
  currentStepInstance: null,
  stepCount: 0,
  isSubmitting: false,
  nextStep: () => undefined,
  previousStep: () => undefined,
  goToStep: () => null,
  incrementStepCount: () => undefined,
  decrementStepCount: () => undefined,
  handleSubmit: () => undefined,
  getStepData: () => null,
  isLoading: false,
});
