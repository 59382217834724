import { ReactNode } from 'react';
import * as H from 'history';

import { LinkButton } from './LinkButton';

type EditButtonProps<S> = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  disabled?: boolean;
  children?: ReactNode;
};

export function EditButton<S>({ to, disabled = false, children = 'Редактирай' }: EditButtonProps<S>) {
  return (
    <LinkButton to={to} variant="primary" isDisabled={disabled}>
      {children}
    </LinkButton>
  );
}
