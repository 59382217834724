import { useEffect, useState } from 'react';

import { VirtualRoom } from '@/app/components/VirtualRoom/VirtualRoom';
import { TestRoomMessengerThread } from '@/app/modules/messenger/TestRoomMessengerThread';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getOrCreateTestRoom } from './service';

export function TestRoomShowPage() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.testRoom.loading);
  const data = useAppSelector((state) => state.testRoom.show);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isChatShown, setIsChatShown] = useState<boolean>(false);

  function handleChatToggle() {
    setIsChatShown((prev) => !prev);
  }

  useEffect(function componentDidMount() {
    async function fetchInitialData() {
      await dispatch(getOrCreateTestRoom());
      setIsDataLoading(false);
    }

    if (loading === LoadingState.Idle) {
      fetchInitialData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="d-flex">
      {isChatShown && <TestRoomMessengerThread id={data.messengerThreadID} handleChatToggle={handleChatToggle} />}
      <VirtualRoom
        domain={data.jitsiDomain}
        uuid={data.virtualRoomUUID}
        jwt={data.jwt}
        isDataLoading={isDataLoading}
        onChatToggle={handleChatToggle}
      />
    </div>
  );
}
