import { createSlice } from '@reduxjs/toolkit';

import { ProfileNotificationSetting, ProfileNotificationType } from '@/app/modules/profile/notifications/models';
import {
  getNotificationUnsubscribeData,
  getProfileGeneralNotifications,
  getProfileTutorNotifications,
} from '@/app/modules/profile/notifications/services';
import { LoadingState } from '@/redux/constants';
import { GenericPayloadAction, sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

interface ProfileNotificationState {
  loading: LoadingState;
  error?: string | null;
  notificationGeneralTypes: ProfileNotificationType[];
  notificationGeneralSettings: ProfileNotificationSetting[];
  notificationTutorTypes: ProfileNotificationType[];
  notificationTutorSetting: ProfileNotificationSetting[];
  unsubscribe: {
    loading: LoadingState;
    error?: string | null;
    email: string;
    notificationTypeName: string;
    notificationTypeChannelID: number | null;
  };
}

const initialState: ProfileNotificationState = {
  loading: LoadingState.Idle,
  error: null,
  notificationGeneralTypes: [],
  notificationGeneralSettings: [],
  notificationTutorTypes: [],
  notificationTutorSetting: [],
  unsubscribe: {
    loading: LoadingState.Idle,
    error: null,
    email: '',
    notificationTypeName: '',
    notificationTypeChannelID: null,
  },
};

const profileNotifications = createSlice({
  name: 'profileNotifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region GET PROFILE GENERAL NOTIFICATION INDEX
    builder
      .addCase(getProfileGeneralNotifications.pending, sharedPendingReducer)
      .addCase(getProfileGeneralNotifications.rejected, sharedRejectedReducer)
      .addCase(getProfileGeneralNotifications.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.notificationGeneralTypes = action.payload.types;
          state.notificationGeneralSettings = action.payload.settings;
        }
      });
    //#endregion GET PROFILE GENERAL NOTIFICATION INDEX

    //#region GET PROFILE TUTOR NOTIFICATION INDEX
    builder
      .addCase(getProfileTutorNotifications.pending, sharedPendingReducer)
      .addCase(getProfileTutorNotifications.rejected, sharedRejectedReducer)
      .addCase(getProfileTutorNotifications.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.notificationTutorTypes = action.payload.types;
          state.notificationTutorSetting = action.payload.settings;
        }
      });
    //#endregion GET PROFILE TUTOR NOTIFICATION INDEX

    //#region GET NOTIFICATION UNSUBSCRIBE DATA
    builder
      .addCase(getNotificationUnsubscribeData.pending, function (state: ProfileNotificationState) {
        if (state.unsubscribe.loading === LoadingState.Idle) {
          state.unsubscribe.loading = LoadingState.Pending;
        }
      })
      .addCase(
        getNotificationUnsubscribeData.rejected,
        function (state: ProfileNotificationState, action: GenericPayloadAction) {
          if (state.unsubscribe.loading === LoadingState.Pending) {
            state.unsubscribe.loading = LoadingState.Idle;
            state.unsubscribe.error = action.error.message;
          }
        }
      )
      .addCase(getNotificationUnsubscribeData.fulfilled, (state, action) => {
        if (state.unsubscribe.loading === LoadingState.Pending) {
          state.unsubscribe.loading = LoadingState.Idle;
          state.unsubscribe.email = action.payload.identityEmail;
          state.unsubscribe.notificationTypeName = action.payload.notificationTypeName;
          state.unsubscribe.notificationTypeChannelID = action.payload.notificationTypeChannelID;
        }
      });
    //#endregion GET NOTIFICATION UNSUBSCRIBE DATA
  },
});

export default profileNotifications;
