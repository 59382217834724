import { MouseEventHandler } from 'react';
import clsx from 'clsx';

import './ModalCloseButton.scss';

type ModalCloseButtonProps = {
  onClose: MouseEventHandler;
  isAbsolutePositioned?: boolean;
};

export function ModalCloseButton({ onClose, isAbsolutePositioned }: ModalCloseButtonProps) {
  return (
    <button
      type="button"
      className={clsx('btn-close', { 'btn-close--absolute': isAbsolutePositioned })}
      aria-label="Close"
      onClick={onClose}
    />
  );
}
