import { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { NO_SUBJECT_IMAGE_PATH } from '@/app/components/Form/PictureControl/constants';
import { Subject, TargetAudience } from '@/app/modules/course/models';

import './SubjectItem.scss';

type SubjectDropdownProps = {
  subject: Subject;
  targetAudiences: TargetAudience[];
};

export function SubjectItem({ subject, targetAudiences }: SubjectDropdownProps) {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isHidden, setIsHidden] = useState(true);
  const noTargetAudiences = targetAudiences.length === 0;

  function handleToggle() {
    setIsHidden(!isHidden);
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current?.contains?.(event.target as Node)) {
        setIsHidden(true);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const filteredTargetAudiences = useMemo(() => {
    return targetAudiences.filter((targetAudience) => subject.targetAudiencesIDs?.includes(targetAudience.id));
  }, [targetAudiences, subject.targetAudiencesIDs]);

  //#region Container height
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | undefined>();

  useEffect(function handleContainerHeight() {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.getBoundingClientRect().height);
    }
  }, []);
  //#endregion Container height

  return (
    <div className="subject-container" style={{ height: containerHeight }} ref={containerRef}>
      {noTargetAudiences && (
        <Link to={`/${subject.transliteratedSlug}`}>
          <Card className="rounded subject-card" ref={dropdownRef}>
            <Card.Img
              src={subject.imagePath ?? NO_SUBJECT_IMAGE_PATH}
              width="302"
              height="144"
              alt={subject.text}
              className="w-100 rounded-0 subject-card__img cursor-pointer"
            />
            <div className="d-flex align-items-stretch">
              <button className="subject-card__header-btn w-100" type="button">
                <div className="subject-card__header-btn-content px-3 m-2">
                  <h6 className="subject-card__header-text">{subject.text}</h6>
                  <i className="ms-auto me-1 far fa-arrow-right text-primary target-audience-icon" />
                </div>
              </button>
            </div>
          </Card>
        </Link>
      )}

      {!noTargetAudiences && (
        <Card
          className={clsx('rounded subject-card', {
            'subject-card--expanded': !isHidden,
          })}
          ref={dropdownRef}
        >
          <Card.Img
            src={subject.imagePath ?? NO_SUBJECT_IMAGE_PATH}
            width="302"
            height="144"
            alt={subject.text}
            className={clsx('w-100 rounded-0 subject-card__img cursor-pointer', {
              'subject-card__img--expanded': !isHidden,
            })}
            onClick={handleToggle}
          />
          <div className="d-flex align-items-stretch">
            {!isHidden && (
              <Link
                to={`/${subject.transliteratedSlug}`}
                className="subject-card__header-btn d-flex align-items-center w-100"
              >
                <h6 className="subject-card__header-text subject-card__header-link subject-card__header-btn-content mt-2 mb-2 ms-2">
                  {subject.text}
                </h6>
              </Link>
            )}

            <button
              className={clsx('subject-card__header-btn', { 'w-100': isHidden })}
              type="button"
              onClick={handleToggle}
            >
              <div
                className={clsx('subject-card__header-btn-content px-3', {
                  'mt-2 me-2 mb-2': !isHidden,
                  'm-2': isHidden,
                })}
              >
                {isHidden && <h6 className="subject-card__header-text">{subject.text}</h6>}
                <i
                  className={clsx('subject-card__header-btn-icon far fa-chevron-down', {
                    'subject-card__header-btn-icon--rotate-up': !isHidden,
                  })}
                />
              </div>
            </button>
          </div>
          <div className={clsx('subject-card__content', { 'subject-card__content--expanded': !isHidden })}>
            <div
              className={clsx('bg-white mx-3 px-1 pt-3 mb-3 border-top border-2', {
                'd-none': isHidden,
              })}
            >
              {filteredTargetAudiences.map((targetAudience) => (
                <Link
                  key={targetAudience.id}
                  to={`/${subject.transliteratedSlug}/${targetAudience.transliteratedSlug}`}
                  className="overflow-hidden text-wrap d-flex align-items-center py-2 px-3 text-grey-700 subject-card__target-audience-item"
                >
                  {targetAudience.text}
                  <i className="ms-auto me-1 far fa-arrow-right text-primary target-audience-icon" />
                </Link>
              ))}
            </div>
          </div>
        </Card>
      )}
    </div>
  );
}
