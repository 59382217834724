import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { OrderDetailsData } from '@/app/modules/order/models';
import { ReservationGroupDetails, ReservationGroupSummaryPayload } from '@/app/modules/reservation-group/models';

const ENDPOINT_PREFIX = '/ajax/reservation-groups';
export const ENDPOINTS = {
  details: (id: number) => `${ENDPOINT_PREFIX}/${id}/details`,
  summary: (id: number) => `${ENDPOINT_PREFIX}/${id}/summary`,
};

//#region Get course reservation group details
export type GetReservationGroupDetailsResponse = ReservationGroupDetails;

export const getReservationGroupDetails = createAsyncThunk(
  'course/getReservationGroupDetails',
  async function (id: number) {
    const response = await axios.get<GetReservationGroupDetailsResponse>(ENDPOINTS.details(id));
    return response.data;
  }
);
//#endregion Get course reservation group details

//#region Get course reservation group summary
export type GetReservationGroupSummaryResponse = ReservationGroupDetails & {
  order: OrderDetailsData | null;
};

export const getReservationGroupSummary = createAsyncThunk(
  'course/getReservationGroupSummary',
  async function ({ reservationGroupID, orderID }: ReservationGroupSummaryPayload) {
    const response = await axios.get<GetReservationGroupSummaryResponse>(ENDPOINTS.summary(reservationGroupID), {
      params: { orderID },
    });
    return response.data;
  }
);
//#endregion Get course reservation group summary
