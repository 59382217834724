import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Alert } from '@/app/components/Alert/Alert';
import { AlertBody } from '@/app/components/Alert/AlertBody';
import { AlertIcon } from '@/app/components/Alert/AlertIcon';
import { EditButton } from '@/app/components/Button/EditButton';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { FileControlValue } from '@/app/components/Form/models';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { TabItem } from '@/app/components/Tabs/TabItem';
import { TabNav } from '@/app/components/Tabs/TabNav';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { getTutorStatus } from '@/app/modules/auth/service';
import { EDITABLE_TUTOR_STATUS_IDS } from '@/app/modules/profile/details/constants';
import { getTutorProfileAboutDetails } from '@/app/modules/profile/details/service';
import { StickyHeader } from '@/app/modules/profile/StickyHeader';
import { TutorEducationalDegreeID } from '@/app/modules/tutor/models';
import { getEducationDegree, getTeachingExperience } from '@/app/modules/tutor/utils';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

export function TutorAboutDetailsShowPage() {
  const loading = useAppSelector((state) => state.profileDetails.loading);
  const content = useAppSelector((state) => state.profileDetails.aboutDetails);
  const dispatch = useDispatch();
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);
  const tutorLoading = useAppSelector((state) => state.auth.tutorLoading);

  useEffect(function componentDidMount() {
    if (tutorLoading === LoadingState.Idle) {
      dispatch(getTutorStatus());
    }
    if (loading === LoadingState.Idle) {
      dispatch(getTutorProfileAboutDetails());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const educationDegree = useMemo(() => getEducationDegree(content.educationDegreeID), [content.educationDegreeID]);

  const teachingExperience = useMemo(
    () => getTeachingExperience(content.teachingExperienceID),
    [content.teachingExperienceID]
  );

  if (!tutorStatusID) {
    return null;
  }

  if (!EDITABLE_TUTOR_STATUS_IDS.includes(tutorStatusID)) {
    return null;
  }

  const isEmpty = !(
    'educationDegreeID' in content &&
    'university' in content &&
    'approach' in content &&
    'motivation' in content &&
    'importance' in content &&
    'hasPedagogicalDegree' in content &&
    'pedagogicalDegree' in content &&
    'hasStudentPrepareExperience' in content &&
    'technicalExperience' in content
  );

  return (
    <>
      <Helmet>
        <title>За мен</title>
      </Helmet>
      <StickyHeader className="pt-5" containerClassName="mb-7">
        <PageTop className="mb-5">
          <PageTitle className="my-2">За мен</PageTitle>
          <PageActions>
            <EditButton to="/profile/about/edit" />
          </PageActions>
        </PageTop>
        <TabNav>
          <TabItem to="/profile/details">Лични данни</TabItem>
          <TabItem to="/profile/about">За мен</TabItem>
          <TabItem to="/profile/bank-details">Банкова сметка</TabItem>
          <TabItem to="/profile/invoice-details">Фактуриране</TabItem>
        </TabNav>
      </StickyHeader>

      {tutorStatusID === TutorStatusID.WaitingApproval && (
        <Alert className="mb-7" variant="primary">
          <AlertIcon path="/img/svg/icons/Home/Timer.svg" variant="primary" />
          <AlertBody>
            Профилът Ви чака одобрение от служител на Школо. Докато преглеждаме профила Ви, можете да правите корекции
            при нужда.
          </AlertBody>
        </Alert>
      )}

      {tutorStatusID === TutorStatusID.Rejected && (
        <Alert className="mb-7 mt-7" variant="danger">
          <AlertIcon path="/img/svg/icons/Code/Stop.svg" variant="danger" />
          <AlertBody>
            <div>
              Вашата заявка е отказана. Можете да редактирате профила си и да изпратите отново за одобрение. Изпращането
              за одобрение можете да направите от
              <Link to="/tutor/become" className="ms-1">
                тук
              </Link>
              .
            </div>
          </AlertBody>
        </Alert>
      )}
      <Card>
        <CardHeader>
          <CardTitle>За мен</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              {isEmpty && loading === LoadingState.Idle && <p className="fw-bold mb-0">Нямате въведени данни</p>}
              {educationDegree && (
                <>
                  <p className="fw-bold mb-1">Моето образование е:</p>
                  <p>{educationDegree}</p>
                </>
              )}
              {Number(content?.educationDegreeID) === TutorEducationalDegreeID.University && content?.university && (
                <>
                  <p className="fw-bold mb-1">Университет/Специалност:</p>
                  <p>{content?.university ?? ''}</p>
                </>
              )}
              {teachingExperience && (
                <>
                  <p className="fw-bold mb-1">Опитът ми като обучител е:</p>
                  <p>{teachingExperience}</p>
                </>
              )}
              {content?.approach && (
                <>
                  <p className="fw-bold mb-1">Подходът ми в преподаването е:</p>
                  <p>{content?.approach ?? ''}</p>
                </>
              )}
              {content?.motivation && (
                <>
                  <p className="fw-bold mb-1">Мотивацията ми като обучител е:</p>
                  <p>{content?.motivation ?? ''}</p>
                </>
              )}
              {content?.importance && (
                <>
                  <p className="fw-bold mb-1">Най-важното за мен в работата с учениците е:</p>
                  <p>{content?.importance ?? ''}</p>
                </>
              )}
              {content?.pupilAchievement && (
                <>
                  <p className="fw-bold mb-1">Ученическо постижение с което се гордея</p>
                  <p>{content?.pupilAchievement ?? ''}</p>
                </>
              )}
              {Boolean(content?.hasPedagogicalDegree) && (
                <>
                  <p className="fw-bold mb-1">Имам ли педагогическа степен?</p>
                  <p>{Number(content?.hasPedagogicalDegree) === 1 ? 'Да' : 'Не'}</p>
                </>
              )}
              {Number(content?.hasPedagogicalDegree) === 1 && content?.pedagogicalDegree && (
                <>
                  <p className="fw-bold mb-1">Педагогическа степен</p>
                  <p>{content?.pedagogicalDegree}</p>
                </>
              )}
              {Boolean(content?.hasStudentPrepareExperience) && (
                <>
                  <p className="fw-bold mb-1">Имам ли опит в подготовка на ученици за НВО и ДЗИ?</p>
                  <p>{Number(content?.hasStudentPrepareExperience) === 1 ? 'Да' : 'Не'}</p>
                </>
              )}
              {content?.technicalExperience && (
                <>
                  <p className="fw-bold mb-1">Справяне с технологиите при онлайн обучението</p>
                  <p>{content?.technicalExperience}</p>
                </>
              )}
              {content.attachments.length > 0 && (
                <>
                  <p className="fw-bold mb-1">Сертификати, дипломи или награди</p>
                  {content.attachments.map((attachment: FileControlValue, idx) => (
                    <span key={attachment.id}>
                      <a href={attachment.url} target="_blank" rel="noreferrer">
                        {attachment.fileName}
                      </a>
                      {idx !== content.attachments.length - 1 && ', '}
                    </span>
                  ))}
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {loading === LoadingState.Pending && <GlobalSpinner />}
    </>
  );
}
