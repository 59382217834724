import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { FavoriteCourse, FavoriteCourseFilterQueryParams } from '@/app/modules/favorite-course/models';

const ENDPOINT_PREFIX = '/ajax/favorite-courses';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
};

export interface GetFavoriteCoursesResponse {
  favoriteCourses: FavoriteCourse[];
}

export const getFavoriteCourses = createAsyncThunk(
  'favoriteCourse/getFavoriteCourses',
  async function (filters: FavoriteCourseFilterQueryParams) {
    const response = await axios.get<GetFavoriteCoursesResponse>(ENDPOINTS.resource, {
      params: { filters },
    });
    return response.data;
  }
);
