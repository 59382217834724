import React, { useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import SVG from 'react-inlinesvg';
import composeRefs from '@seznam/compose-react-refs';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import produce from 'immer';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { transformReorderableFilesToFileControlValue } from '@/app/components/Form/MultipleFileControl/utils';

import { FileControlValue } from '../models';
import { createReorderableFilesValue } from './models';

import './ReorderableFiles.scss';

type ReorderableFilesProps = {
  name: string;
  isReadOnly?: boolean;
};

export function ReorderableFiles({ name, isReadOnly = false }: ReorderableFilesProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<FileControlValue[]>(name);
  const fieldHelpers = formik.getFieldHelpers<FileControlValue[]>(name);
  const [reorderableFiles, setReorderableFiles] = useState(createReorderableFilesValue(name, field.value));
  const droppableRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const itemRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const isReorderableFilesChangeInternal = useRef(false);
  const isFieldValueChangeInternal = useRef(false);

  useEffect(
    function handleFieldValueChange() {
      if (isFieldValueChangeInternal.current) {
        isFieldValueChangeInternal.current = false;
      } else {
        setReorderableFiles(createReorderableFilesValue(name, field.value));
      }
    },
    [field.value, name]
  );

  useEffect(
    function handleReorderableFilesChange() {
      if (isReorderableFilesChangeInternal.current) {
        isReorderableFilesChangeInternal.current = false;
      } else {
        const newReorderableFiles = {} as Record<string, FileControlValue[]>;
        let droppableWidth = 0;
        let currentDroppableID = 0;
        let currentDroppableChildrenWidth = 0;

        for (const droppableID in reorderableFiles ?? {}) {
          // STEP 1: Get width of droppable (row).
          const droppableElement = droppableRefs.current?.[droppableID];
          const droppableMeta = droppableElement?.getBoundingClientRect();

          if (droppableMeta) {
            droppableWidth = droppableMeta.width;
          }

          // STEP 2: Loop through file items from this droppable (row).
          const values = reorderableFiles[droppableID];

          for (const value of values ?? []) {
            // STEP 2.1: Sum width with previous file items.
            const child = itemRefs.current?.[value.id];
            const childMeta = child?.getBoundingClientRect();
            currentDroppableChildrenWidth += childMeta?.width ?? 0;

            // STEP 2.2: Handle overflow -> new droppable (row).
            if (currentDroppableChildrenWidth > droppableWidth) {
              currentDroppableID++;
              currentDroppableChildrenWidth = childMeta?.width ?? 0;
            }

            // STEP 2.3: Add file item to current (or new) droppable (row).
            const droppableKey: string = name + '[' + currentDroppableID + ']';

            if (!newReorderableFiles[droppableKey]) {
              newReorderableFiles[droppableKey] = [];
            }

            newReorderableFiles[droppableKey].push(value);
          }
        }

        // STEP 3: Update internal reorderable files.
        isReorderableFilesChangeInternal.current = true; // this prevents infinite loops
        setReorderableFiles(newReorderableFiles);
      }
    },
    [reorderableFiles, name]
  );

  function handleDragEnd({ source, destination }: DropResult) {
    // Sanity check.
    if (!destination || !reorderableFiles[destination?.droppableId]) {
      return;
    }

    // STEP 1: Save dragged item.
    const sourceData = reorderableFiles[source.droppableId][source.index];

    const newReorderableFiles = produce(reorderableFiles, (draft) => {
      // STEP 2: Delete original dragged item.
      draft[source.droppableId].splice(source.index, 1);

      if (draft[source.droppableId].length === 0) {
        delete draft[source.droppableId];
      }

      // STEP 3: Add dragged item to dragged position.
      draft[destination.droppableId]?.splice(destination.index, 0, sourceData);

      if (draft[destination.droppableId]?.length === 0) {
        delete draft[destination.droppableId];
      }
    });

    // STEP 4: Update internal reorderable files.
    setReorderableFiles(newReorderableFiles);

    // STEP 5: Update field value.
    isFieldValueChangeInternal.current = true; // this prevents infinite loops
    fieldHelpers.setValue(transformReorderableFilesToFileControlValue(newReorderableFiles));
  }

  function handleItemRemove(droppableID: string, index: number) {
    // STEP 1: Remove file.
    const newReorderableFiles = produce(reorderableFiles, (draft) => {
      draft?.[droppableID]?.splice?.(index, 1);
    });

    // STEP 2: Update internal reorderable files.
    setReorderableFiles(newReorderableFiles);

    // STEP 3: Update field value.
    isFieldValueChangeInternal.current = true; // this prevents infinite loops
    fieldHelpers.setValue(transformReorderableFilesToFileControlValue(newReorderableFiles));
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {Object.entries(reorderableFiles).map(([droppableID, fieldValue]) => (
        <Droppable key={droppableID} droppableId={droppableID} direction="horizontal">
          {(provided: DroppableProvided) => (
            <div
              ref={composeRefs<HTMLDivElement>(provided.innerRef, (element) => {
                droppableRefs.current[droppableID] = element;
              })}
              {...provided.droppableProps}
              className="d-flex justify-content-lg-start"
            >
              {(fieldValue ?? []).map((value, idx) => (
                <Draggable key={value.id} draggableId={String(value.id)} index={idx} isDragDisabled={isReadOnly}>
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                      ref={composeRefs<HTMLDivElement>(provided.innerRef, (element) => {
                        itemRefs.current[String(value.id)] = element;
                      })}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={clsx('reorderable-file', { 'reorderable-file--dragging': snapshot.isDragging })}
                    >
                      <p className="reorderable-file__text">{value?.fileName}</p>
                      {!isReadOnly && (
                        <button
                          className="reorderable-file__remove-button"
                          type="button"
                          onClick={() => handleItemRemove(droppableID, idx)}
                        >
                          <SVG src={toAbsoluteUrl('/img/svg/Close.svg')} className="reorderable-file__remove-icon" />
                        </button>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  );
}
