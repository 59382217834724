import React from 'react';
import clsx from 'clsx';

import { Button } from '@/app/components/Button/Button';
import { LinkButton } from '@/app/components/Button/LinkButton';
import { serializeToHtml } from '@/app/components/RichTextControl/utils';
import { StudentProposalStatusID } from '@/app/modules/course/models';
import { MessengerMessage } from '@/app/modules/messenger/models';
import { useAppSelector } from '@/redux/store';

type MessengerMessageProps = {
  message: MessengerMessage;
  handleAddEventClick: React.Dispatch<React.SetStateAction<MessengerMessage | null>>;
  className?: string;
  isLast: boolean;
};

export function MessengerMessageItem({
  message,
  handleAddEventClick,
  isLast = false,
  className = '',
}: MessengerMessageProps) {
  const auth = useAppSelector((state) => state.auth);
  const isCreator: boolean = Number(message.identityID) === auth.identityID;

  async function handleSendProposalInviteClick() {
    await handleAddEventClick(message);
  }

  return (
    <div className="pb-3">
      <div
        className={clsx('messenger-messages__message p-3 rounded mw-50 w-fit-content', className, {
          'messenger-messages__message--creator bg-primary text-light':
            isCreator && !message.studentProposalID && !message.isVideoConferenceMessage,
          'bg-gray-200': !isCreator && !message.studentProposalID,
          'ms-auto': isCreator,
          'me-auto': !isCreator,
          'bg-light-primary text-primary border border-primary':
            message.studentProposalID || message.isVideoConferenceMessage,
        })}
      >
        <p className="d-flex flex-wrap">
          <span className="fw-bold me-3">{message.creator}</span>{' '}
          <span className="text-nowrap">{message.createdAt}</span>
        </p>

        {message.body && (
          <>
            <div className="mb-n4" dangerouslySetInnerHTML={{ __html: serializeToHtml(message.body) }} />
            {Boolean(message.isVideoConferenceMessage) && (
              <LinkButton
                to={`/messenger/${message.threadID}/video`}
                variant="primary"
                className="video-btn fw-normal btn-sm py-2 px-6 mt-3"
              >
                <i className="fa-solid fa-video fs-5 me-1" />
                <span>Видео разговор</span>
              </LinkButton>
            )}
            {Boolean(
              message.studentProposalStatusID === StudentProposalStatusID.ReceivedFromTutor &&
                message.studentProposalTutorIdentityID === auth.identityID
            ) && (
              <Button variant="primary" size="sm" className="mt-3" onClick={handleSendProposalInviteClick}>
                Изпрати покани
              </Button>
            )}
            {Boolean(
              message.studentProposalStatusID === StudentProposalStatusID.SentToStudent &&
                message.identityID !== auth.identityID &&
                auth.identityID === message.studentProposalStudentIdentityID
            ) && (
              <LinkButton to="/my-schedule" variant="primary" className="text-nowrap fw-normal btn-sm py-2 px-6 mt-3">
                <span>Към Моят график</span>
              </LinkButton>
            )}
          </>
        )}
        {message.attachmentName && (
          <div className="fw-bold py-1">
            <a href={message.attachmentURL} target="_blank" rel="noreferrer">
              {message.attachmentName}
            </a>
          </div>
        )}
      </div>
      {isCreator && isLast && message.readAt && (
        <div
          className={clsx('p-3 rounded mw-50 w-fit-content', {
            'ms-auto': isCreator,
            'me-auto': !isCreator,
          })}
        >
          <span>Прочетено на:</span> <span className="text-nowrap">{message.readAt}</span>
        </div>
      )}
    </div>
  );
}
