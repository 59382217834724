import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { BlockedStudentPage } from '@/app/modules/errors/BlockedStudentPage';
import errors from '@/app/modules/errors/store';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { ForbiddenPage } from './ForbiddenPage';
import { GeneralErrorPage } from './GeneralErrorPage';
import { NotFoundPage } from './NotFoundPage';
import { UnauthorizedPage } from './UnauthorizedPage';

type ErrorsProps = {
  children: React.ReactNode;
};

export function Errors({ children }: ErrorsProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const httpStatusCode = useAppSelector((state) => state.errors.httpStatusCode);
  const errorType = useAppSelector((state) => state.errors.type);
  const currentLocation = useLocation();

  useEffect(
    () =>
      history.listen((newLocation) => {
        if (currentLocation.pathname === newLocation.pathname) {
          return;
        }

        dispatch(
          errors.actions.setHttpGetError({
            errorMessage: undefined,
            httpStatusCode: undefined,
            type: undefined,
          })
        );
      }),
    [currentLocation, dispatch, errorType, history, httpStatusCode]
  );

  return (
    <>
      <GeneralErrorPage />
      <ForbiddenPage />
      <NotFoundPage />
      <UnauthorizedPage />
      <BlockedStudentPage />

      {httpStatusCode === undefined && children}
    </>
  );
}
