import { useEffect, useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Toastr from 'toastr';
import * as yup from 'yup';

import { KTSVG } from '@/_metronic/helpers';
import { CancelButton } from '@/app/components/Button/CancelButton';
import { SaveButton } from '@/app/components/Button/SaveButton';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { DatePickerControl } from '@/app/components/Form/DatePickerControl/DatePickerControl';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { DEFAULT_GLOBAL_PHONE_PREFIX } from '@/app/components/Form/PhoneControl/constants';
import { PhoneControl } from '@/app/components/Form/PhoneControl/PhoneControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation } from '@/app/components/Form/utils';
import { SecurityText } from '@/app/components/SecurityText/SecurityText';
import { AsyncSelectControl } from '@/app/components/SelectControl/AsyncSelectControl';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { CityOption } from '@/app/models/City';
import {
  PROFILE_INVOICE_DETAILS_SUBTITLE,
  PROFILE_INVOICE_DETAILS_TITLE,
} from '@/app/modules/profile/details/constants';
import { TutorDetailsEditPageBase } from '@/app/modules/profile/details/edit/TutorDetailsEditPageBase';
import { ProfileInvoiceDetails } from '@/app/modules/profile/details/models';
import { getProfileInvoiceDetails, updateProfileInvoiceDetails } from '@/app/modules/profile/details/service';
import { MIN_REQUIRED_AGE } from '@/app/utils/constants';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function TutorInvoiceDetailsEditPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loading = useAppSelector((state) => state.profileDetails.loading);
  const content = useAppSelector((state) => state.profileDetails.invoiceDetails);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getProfileInvoiceDetails());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //#region Handle form
  const initialValues = useMemo<ProfileInvoiceDetails>(
    () => ({
      firstName: content.firstName,
      lastName: content.lastName,
      birthday: content.birthday,
      phone: content.phone ?? DEFAULT_GLOBAL_PHONE_PREFIX,
      email: content.email,
      address: content.address,
      city: content.city,
      postCode: content.postCode ?? '',
    }),
    [content]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phone: yup.mixed().required().phone(),
        email: yup.string().required().email(),
        birthday: yup.string().date().minAge(MIN_REQUIRED_AGE).required(),
        address: yup.string().required(),
        city: yup.object().shape({
          id: yup.number().required(),
        }),
        postCode: yup.string().required(),
      }),
    []
  );

  const formik = useForm({
    initialValues,
    validationSchema,
    initialStatus: true,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<ProfileInvoiceDetails>(async (values) => {
        return await updateProfileInvoiceDetails(values);
      });

      const response = await submitWithValidation(values, formikHelpers);

      if (response) {
        Toastr.success('Успешно запазихте промените!');
        formik.resetForm({ values });
        history.push('/profile/invoice-details');
      }
    },
  });
  //#endregion Handle form

  function handleChangeSelect(selectedCity: CityOption) {
    if (selectedCity?.postCode) {
      formik.setFieldValue('postCode', selectedCity?.postCode);
    }
  }

  return (
    <>
      <Helmet>
        <title>{PROFILE_INVOICE_DETAILS_TITLE}</title>
      </Helmet>
      <TutorDetailsEditPageBase
        formik={formik}
        title={PROFILE_INVOICE_DETAILS_TITLE}
        actions={
          <>
            <CancelButton to="/profile/invoice-details" />
            <SaveButton />
          </>
        }
      >
        <CardHeader>
          <CardTitle>{PROFILE_INVOICE_DETAILS_TITLE}</CardTitle>
          <CardSubTitle>{PROFILE_INVOICE_DETAILS_SUBTITLE}</CardSubTitle>
        </CardHeader>

        <CardBody>
          <SecurityText className="mb-10" />

          <Row>
            <FormGroup controlId="firstName" className="col-md-6">
              <Label isRequired>Име</Label>
              <FormControl type="text" name="firstName" />
            </FormGroup>
            <FormGroup controlId="lastName" className="col-md-6 mb-10">
              <Label isRequired>Фамилия</Label>
              <FormControl type="text" name="lastName" />
            </FormGroup>
          </Row>

          <FormGroup controlId="phone">
            <Label isRequired>Телефон</Label>
            <PhoneControl name="phone" />
          </FormGroup>

          <FormGroup controlId="email">
            <Label isRequired>Email адрес</Label>
            <FormControl type="email" name="email" />
          </FormGroup>

          <FormGroup controlId="birthday">
            <Label isRequired>Дата на раждане</Label>
            <DatePickerControl name="birthday" />
            <div className="d-flex mt-5">
              <KTSVG className="w-15px h-15px" path="/img/svg/icons/EyeSlash.svg" />
              <p className="text-gray-500 fs-7 px-1 mb-0">
                Видимо само за Вас. Необходимо е за да потвърдим, че не сте малолетен.
              </p>
            </div>
          </FormGroup>

          <FormGroup controlId="address">
            <Label isRequired>Адрес</Label>
            <FormControl type="text" name="address" />
          </FormGroup>

          <Row className="mb-n10">
            <FormGroup controlId="city" className="col-md-6">
              <Label isRequired>Град</Label>
              <AsyncSelectControl name="city" endpoint="/ajax/cities" onChange={handleChangeSelect} />
            </FormGroup>
            <FormGroup controlId="postCode" className="col-md-6 mb-10">
              <Label isRequired>Пощенски код</Label>
              <FormControl type="text" name="postCode" />
            </FormGroup>
          </Row>
        </CardBody>
        {loading === LoadingState.Pending && <GlobalSpinner />}
      </TutorDetailsEditPageBase>
    </>
  );
}
