import { SubjectIndexFilterSortBy } from '@/app/modules/subject/models';

export const DEFAULT_SEARCH_VALUE = '';

export const SUBJECT_INDEX_FILTER_SORT_BY_DATA = [
  {
    id: SubjectIndexFilterSortBy.Popularity,
    text: 'Брой покупки',
  },
  {
    id: SubjectIndexFilterSortBy.Name,
    text: 'A-Я',
  },
];

export const DEFAULT_SORT_BY_FILTER_VALUE = SubjectIndexFilterSortBy.Popularity;

export const SubjectIndexFilterInitialValues = {
  sortBy: DEFAULT_SORT_BY_FILTER_VALUE,
  search: DEFAULT_SEARCH_VALUE,
};
