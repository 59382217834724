import { Card, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { LinkButton } from '@/app/components/Button/LinkButton';

type PlatformWorkStepCardComponentProps = {
  header: string;
  paragraph: string;
  svg: string;
  step: number;
};

export function PlatformWorkStepCardComponent({ header, paragraph, svg, step }: PlatformWorkStepCardComponentProps) {
  return (
    <Col md={4} sm={6} xs={12}>
      <Card className="p-7 rounded border border-secondary h-100">
        <div className="d-flex">
          <SVG className="w-75px h-75px" src={toAbsoluteUrl(svg)} />
          <div className="ms-auto">
            <LinkButton
              to="/subjects"
              variant="light-primary"
              className="badge badge-light-primary badge-step py-2 px-4 ms-3"
            >
              Стъпка {step}
            </LinkButton>
          </div>
        </div>
        <h2 className="text-gray-700 mt-5">{header}</h2>
        <p className="mb-0 text-gray-600">{paragraph}</p>
      </Card>
    </Col>
  );
}
