import { useState } from 'react';
import Sticky from 'react-stickynode';
import clsx from 'clsx';

import { Content } from '@/app/components/Page/Content/Content';
import { useLoading } from '@/app/utils/useLoading';
import { useAppSelector } from '@/redux/store';

import { CourseIndexPageFilterMobile } from '../../models';
import { LabelsFilters } from './components/LabelsFilters/LabelsFilters';
import { RedirectToSubjectsPageModal } from './components/RedirectToSubjectsPageModal/RedirectToSubjectsPageModal';
import { ALL_SUBJECTS } from './constants';
import { FiltersIndexPageMobile } from './mobile/FiltersIndexPageMobile';
import { KeywordsFilterPageMobile } from './mobile/KeywordsFilterPageMobile';
import { NumberLessonsFilterPageMobile } from './mobile/NumberLessonsFilterPageMobile';
import { NumberOfLessonsFilterPageMobile } from './mobile/NumberOfLessonsFilterPageMobile';
import { PriceFilterPageMobile } from './mobile/PriceFilterPageMobile';
import { SortByFilterPageMobile } from './mobile/SortByFilterPageMobile';
import { SubjectsIndexPageFilterMobile } from './mobile/SubjectsIndexPageFilterMobile';
import { TargetAudiencesFilterPageMobile } from './mobile/TargetAudiencesFilterPageMobile';
import { TimeIntervalsFilterMobile } from './mobile/TimeIntervalsFilterMobile';

import './CourseIndexPageMobileFilters.scss';

type CourseIndexPageMobileFiltersProps = {
  subjectSlug: string;
  handleStickyStateChange: (state: Sticky.Status) => void;
  handleAllFiltersToggle: () => void;
  isFilterToggled: boolean;
  isSubjectFilterToggled: boolean;
  setIsSubjectFilterToggled: React.Dispatch<React.SetStateAction<boolean>>;
};

export function CourseIndexPageMobileFilters({
  subjectSlug,
  handleStickyStateChange,
  handleAllFiltersToggle,
  isFilterToggled,
  isSubjectFilterToggled,
  setIsSubjectFilterToggled,
}: CourseIndexPageMobileFiltersProps) {
  const store = useAppSelector((state) => state.course);
  const subjectName = store.index.subjectName;
  const subjects = store.index.subjects;
  const targetAudiences = store.index.allTargetAudiences;
  const subjectKeywords = store.index.allSubjectKeywords;
  const [loadingState] = useLoading(store.loading, store.index.isPreloaded);
  const [currentFilter, setCurrentFilter] = useState<CourseIndexPageFilterMobile | null>(null);
  const [isModalShown, setIsModalShown] = useState(false);

  const currentSubject = subjects?.find((subject) => {
    return subject.transliteratedSlug === subjectSlug;
  });

  const isAnyFilterToggled = currentFilter !== null;

  function handleSubjectFilterToggle() {
    setIsSubjectFilterToggled(!isSubjectFilterToggled);
  }

  function handleSubjectFilterToggleFromFilters() {
    setIsSubjectFilterToggled(true);
    handleAllFiltersToggle();
  }

  return (
    <Sticky
      top="#kt_header"
      bottomBoundary="#kt_content"
      className="z-index-3 position-relative"
      onStateChange={handleStickyStateChange}
    >
      <div className="bg-white shadow-sm">
        <Content
          className={clsx('p-2 overflow-auto', {
            'content-container-height': isAnyFilterToggled || isFilterToggled || isSubjectFilterToggled,
          })}
        >
          {isSubjectFilterToggled && (
            <SubjectsIndexPageFilterMobile
              loadingState={loadingState}
              subjects={subjects}
              hideFilters={handleSubjectFilterToggle}
              handleAllFiltersToggle={handleAllFiltersToggle}
            />
          )}

          {isFilterToggled && !isAnyFilterToggled && (
            <FiltersIndexPageMobile
              isAllFiltersToggled={isFilterToggled}
              handleSubjectFilterToggle={handleSubjectFilterToggleFromFilters}
              handleAllFiltersToggle={handleAllFiltersToggle}
              setCurrentFilter={setCurrentFilter}
            />
          )}

          {currentFilter === CourseIndexPageFilterMobile.TargetAudience && (
            <TargetAudiencesFilterPageMobile
              handleFilterToggle={handleAllFiltersToggle}
              targetAudiences={targetAudiences}
              setCurrentFilter={setCurrentFilter}
            />
          )}

          {currentFilter === CourseIndexPageFilterMobile.Keywords && (
            <KeywordsFilterPageMobile
              handleFilterToggle={handleAllFiltersToggle}
              keywords={subjectKeywords}
              setCurrentFilter={setCurrentFilter}
            />
          )}

          {currentFilter === CourseIndexPageFilterMobile.Price && (
            <PriceFilterPageMobile handleFilterToggle={handleAllFiltersToggle} setCurrentFilter={setCurrentFilter} />
          )}

          {currentFilter === CourseIndexPageFilterMobile.NumberOfLessons && (
            <NumberOfLessonsFilterPageMobile
              handleFilterToggle={handleAllFiltersToggle}
              setCurrentFilter={setCurrentFilter}
            />
          )}

          {currentFilter === CourseIndexPageFilterMobile.TimeInterval && (
            <TimeIntervalsFilterMobile
              handleFilterToggle={handleAllFiltersToggle}
              setCurrentFilter={setCurrentFilter}
            />
          )}

          {currentFilter === CourseIndexPageFilterMobile.Order && (
            <SortByFilterPageMobile handleFilterToggle={handleAllFiltersToggle} setCurrentFilter={setCurrentFilter} />
          )}

          {currentFilter === CourseIndexPageFilterMobile.LessonsPerPage && (
            <NumberLessonsFilterPageMobile
              handleFilterToggle={handleAllFiltersToggle}
              setCurrentFilter={setCurrentFilter}
            />
          )}

          {!isSubjectFilterToggled && !isAnyFilterToggled && (
            <div
              className={clsx('d-flex justify-content-between w-100', {
                'd-none': isFilterToggled,
              })}
            >
              <button
                type="button"
                className={clsx(
                  'btn btn-bg-white align-items-center border border-gray-300 me-2 ps-1 pe-2 w-100 overflow-hidden',
                  {
                    'd-none': isFilterToggled,
                    'd-flex': !isFilterToggled,
                  }
                )}
                onClick={handleSubjectFilterToggle}
              >
                <span className="w-100 mobile-filters-subject-name overflow-hidden">
                  {currentSubject?.text ?? ALL_SUBJECTS}
                </span>

                <i
                  className={clsx('far ms-2', {
                    'fa-chevron-up': isSubjectFilterToggled,
                    'fa-chevron-down': !isSubjectFilterToggled,
                  })}
                />
              </button>

              <button
                type="button"
                className="btn btn-bg-white d-flex align-items-center border border-gray-300"
                onClick={handleAllFiltersToggle}
              >
                <span className="d-block mx-auto">Филтри</span>
                <i
                  className={clsx('far ms-2', {
                    'fa-chevron-up': isFilterToggled,
                    'fa-chevron-down': !isFilterToggled,
                  })}
                />
              </button>
            </div>
          )}
        </Content>

        <LabelsFilters setIsModalShown={setIsModalShown} />

        <RedirectToSubjectsPageModal
          subjectName={subjectName}
          isModalShown={isModalShown}
          setIsModalShown={setIsModalShown}
        />
      </div>
    </Sticky>
  );
}
