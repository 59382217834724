import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { VirtuosoHandle } from 'react-virtuoso';
import { useFormikContext } from 'formik';
import produce from 'immer';

import { Button } from '@/app/components/Button/Button';
import { DropzoneControlState, FileControlValue } from '@/app/components/Form/models';
import { MessengerThreadValues } from '@/app/modules/messenger/models';
import { storeMessengerThreadAttachments } from '@/app/modules/messenger/service';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

type MessengerThreadAttachmentModalProps = {
  id: number | null;
  name: string;
  dropzoneControlState: DropzoneControlState;
  virtuosoRef: React.MutableRefObject<VirtuosoHandle | null>;
  recipientIdentityID?: number | null;
};

export function MessengerThreadAttachmentModal({
  id,
  name,
  dropzoneControlState,
  virtuosoRef,
  recipientIdentityID = null,
}: MessengerThreadAttachmentModalProps) {
  const dispatch = useAppDispatch();
  const formik = useFormikContext<MessengerThreadValues>();
  const field = formik.getFieldMeta<FileControlValue[]>(name);
  const fieldHelpers = formik.getFieldHelpers<FileControlValue[]>(name);
  const isModalShown = field.value.length > 0;
  const messages = useAppSelector((state) => state.messenger);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleModalClose() {
    fieldHelpers.setValue([]);
  }

  function handleItemRemove(attachmentID: string | number) {
    return () => {
      // STEP 1: Remove file.
      const newFormikValues = produce(field.value, (draft) => {
        const index = draft?.findIndex((attachment) => attachment.id == attachmentID);
        draft?.splice?.(index, 1);
      });
      // STEP 2: Update field value.
      fieldHelpers.setValue(newFormikValues);
    };
  }

  async function handleSubmit() {
    if (field.value.length === 0) {
      return;
    }

    setIsSubmitting(true);

    const response = await dispatch(
      storeMessengerThreadAttachments({
        id: Number(id),
        values: { attachments: field.value, recipientIdentityID },
      })
    );

    if (response) {
      virtuosoRef?.current?.scrollToIndex?.({
        index: messages.messengerMessages.length - 1,
        behavior: 'smooth',
      });

      fieldHelpers.setValue([]);
    }

    setIsSubmitting(false);
  }

  return (
    <Modal show={isModalShown} onHide={handleModalClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Изпрати файлове</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {field.value.map((attachment: FileControlValue) => {
          return (
            <div key={attachment.id} className="d-flex justify-content-between align-items-center mb-3">
              <span>{attachment.fileName}</span>
              <button className="btn btn-sm btn-light-danger btn-icon" onClick={handleItemRemove(attachment.id)}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        })}

        <Button onClick={dropzoneControlState.open} disabled={isSubmitting} className="btn-icon">
          <i className="fas fa-plus p-0" />
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Откажи
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          isLoading={messages.loading === LoadingState.Pending || isSubmitting}
        >
          Изпрати
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
