import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import { WeekDay } from '@/app/components/Calendar/models';
import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { DatePickerControl } from '@/app/components/Form/DatePickerControl/DatePickerControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';

import { COURSE_INDEX_FILTER_TIME_RANGE, SHOW_FREE_LESSONS_AT_LEAST_ONE_HOUR } from '../../../constants';
import { FilterDesktop } from './components/FilterDesktop';

export function TimeIntervalsFilterDesktop() {
  const now = useMemo(() => new Date(), []);

  return (
    <>
      <FilterDesktop name="Дата/час">
        <CheckboxControl name="hasFutureTimeSlots" label={SHOW_FREE_LESSONS_AT_LEAST_ONE_HOUR} value={1} />

        <Row className="mx-auto p-0">
          <Col xs={12} sm={6} className="ps-0 pe-1">
            <FormGroup className="mb-4" controlId="fromDate">
              <Label>От</Label>
              <DatePickerControl name="fromDate" minDate={now} maxDateFieldName="toDate" hasSolidBackground={false} />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} className="ps-1 pe-0">
            <FormGroup controlId="toDate">
              <Label>До</Label>
              <DatePickerControl
                name="toDate"
                minDate={now}
                minDateFieldName="fromDate"
                hasSolidBackground={false}
                popperClassName="to-date-popper-position"
              />
            </FormGroup>
          </Col>
        </Row>
      </FilterDesktop>

      <FilterDesktop name="Часови интервали" className="overflow-scroll mh-100px">
        {COURSE_INDEX_FILTER_TIME_RANGE.map((timeRange) => (
          <div key={timeRange.id} className="d-flex flex-column align-items-start mb-3">
            <CheckboxControl name="timeRanges" label={timeRange.text} value={timeRange.id as string} />
          </div>
        ))}
      </FilterDesktop>

      <FilterDesktop name="Дни от седмицата" className="d-flex flex-wrap pb-4">
        <Label className="d-flex flex-column">
          <CheckboxControl name="daysOfWeek" label="" value={WeekDay.Monday} />
          <span className="fw-normal">пон</span>
        </Label>
        <Label className="d-flex flex-column">
          <CheckboxControl name="daysOfWeek" label="" value={WeekDay.Tuesday} />
          <span className="fw-normal">вто</span>
        </Label>
        <Label className="d-flex flex-column">
          <CheckboxControl name="daysOfWeek" label="" value={WeekDay.Wednesday} />
          <span className="fw-normal">сря</span>
        </Label>
        <Label className="d-flex flex-column">
          <CheckboxControl name="daysOfWeek" label="" value={WeekDay.Thursday} />
          <span className="fw-normal">чет</span>
        </Label>
        <Label className="d-flex flex-column">
          <CheckboxControl name="daysOfWeek" label="" value={WeekDay.Friday} />
          <span className="fw-normal">пет</span>
        </Label>
        <Label className="d-flex flex-column">
          <CheckboxControl name="daysOfWeek" label="" value={WeekDay.Saturday} />
          <span className="fw-normal">съб</span>
        </Label>
        <Label className="d-flex flex-column">
          <CheckboxControl name="daysOfWeek" label="" value={WeekDay.Sunday} />
          <span className="fw-normal">нед</span>
        </Label>
      </FilterDesktop>
    </>
  );
}
