import { ReactNode } from 'react';

type AsideMenuExternalItemProps = {
  to: string;
  children: ReactNode;
};

export function AsideMenuExternalItem({ to, children }: AsideMenuExternalItemProps) {
  return (
    <div className="menu-item">
      <a href={to} className="menu-link without-sub">
        <span className="menu-title">{children}</span>
      </a>
    </div>
  );
}
