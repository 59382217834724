/**
 * Return serialized FormData.
 *
 * @param value Object or value that has to be serialized to FormData
 * @param prefix Prefix for field names. Mainly used for recursion on nested objects
 * @param existingFormData Will use this FormData instance if provided
 * @returns Serialized FormData ready for AJAX requests
 */
export function serializeFormData<T extends Record<string, unknown>>(
  value: T,
  prefix = '',
  existingFormData?: FormData
): FormData {
  const formData = existingFormData ?? new FormData();

  // CASE 1: Handle undefined.
  if (value === undefined) {
    return formData;
  }

  // CASE 2: Handle null.
  if (value === null) {
    formData.append(prefix, '');
    return formData;
  }

  // CASE 2: Handle booleans.
  if (typeof value === 'boolean') {
    formData.append(prefix, value ? '1' : '0');
    return formData;
  }

  // CASE 3: Handle dates.
  if (value instanceof Date) {
    formData.append(prefix, value.toISOString());
    return formData;
  }

  // CASE 4: Handle arrays.
  if (Array.isArray(value)) {
    // CASE 4.1: Array with items.
    if (value.length > 0) {
      for (const [index, subValue] of value.entries()) {
        serializeFormData(subValue, `${prefix}[${index}]`, formData);
      }

      return formData;
    }

    // CASE 4.2: Empty array.
    formData.append(prefix + '[]', '');
    return formData;
  }

  // CASE 5: Handle files.
  if (isFile(value as unknown as File)) {
    formData.append(prefix, value as unknown as File);
    return formData;
  }

  // CASE 6: Handle blobs.
  if (isBlob(value)) {
    formData.append(prefix, value);
    return formData;
  }

  // CASE 7: Handle objects.
  if (value === Object(value)) {
    Object.keys(value).forEach((propertyName) => {
      const key = prefix ? prefix + '[' + propertyName + ']' : propertyName;

      serializeFormData(value[propertyName] as Record<string, unknown>, key, formData);
    });

    return formData;
  }

  formData.append(prefix, String(value));
  return formData;
}

//#region Internal functions
/**
 * Check if a value is of type Blob
 *
 * @param value Value to check
 * @returns Whether given value is of type Blob or not
 */
function isBlob(value: unknown): value is Blob {
  return value instanceof Blob || Object.prototype.toString.call(value) === '[object Blob]';
}

/**
 * Check if a value is of type File
 *
 * @param value Value to check
 * @returns Whether given value is of type File or not
 */
function isFile(value: File): value is File {
  return isBlob(value) && typeof value['name'] === 'string' && typeof value['lastModified'] === 'number';
}
//#endregion
