import { useCallback } from 'react';
import { Row } from 'react-bootstrap';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { useFormikContext } from 'formik';

import { Feedback } from '@/app/components/Feedback/Feedback';
import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { SecurityText } from '@/app/components/SecurityText/SecurityText';

import { Label } from '../Label/Label';
import { CardControlError } from './models';

import './CardControl.scss';

type CardControlProps = {
  name: string;
  isDisabled?: boolean;
};

export function CardControl({ name, isDisabled = false }: CardControlProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta(name);
  const fieldErrors = field.error as unknown as CardControlError;

  const handleStripeElementChange = useCallback(
    (elementName: string) => {
      return (event: StripeElementChangeEvent) => {
        formik.setFieldValue(`${name}.${elementName}`, event.error?.message);
      };
    },
    [formik, name]
  );

  return (
    <>
      <SecurityText className="mb-10" />

      <FormGroup controlId="cardHolderName">
        <Label>Притежател</Label>
        <FormControl type="text" name="cardHolderName" disabled={isDisabled} />
      </FormGroup>

      <FormGroup controlId="cardNumber">
        <Label>Номер на картата</Label>
        <CardNumberElement
          options={{
            classes: {
              base: 'form-control form-control-solid form-control-lg form-control--stripe',
            },
            showIcon: true,
            disabled: isDisabled,
          }}
          onChange={handleStripeElementChange('cardNumber')}
        />
        {Boolean(fieldErrors?.cardNumber) && <Feedback type="invalid">{fieldErrors?.cardNumber}</Feedback>}
      </FormGroup>

      <Row>
        <FormGroup controlId="cardExpiry" className="col-md-6">
          <Label>Валидна до</Label>
          <CardExpiryElement
            options={{
              classes: {
                base: 'form-control form-control-solid form-control-lg form-control--stripe',
              },
              placeholder: undefined,
              disabled: isDisabled,
            }}
            onChange={handleStripeElementChange('cardExpiry')}
          />
          {Boolean(fieldErrors?.cardExpiry) && <Feedback type="invalid">{fieldErrors?.cardExpiry}</Feedback>}
        </FormGroup>

        <FormGroup controlId="cardCVC" className="col-md-6">
          <Label>CVC код</Label>
          <CardCvcElement
            options={{
              classes: {
                base: 'form-control form-control-solid form-control-lg form-control--stripe',
              },
              disabled: isDisabled,
            }}
            onChange={handleStripeElementChange('cardCVC')}
          />
          {Boolean(fieldErrors?.cardCVC) && <Feedback type="invalid">{fieldErrors?.cardCVC}</Feedback>}
        </FormGroup>

        <FormGroup controlId="saveCard">
          <CheckboxControl
            name="saveCard"
            label="Запази картата за бъдещи покупки."
            value={1}
            isInline
            isDisabled={isDisabled}
          />
        </FormGroup>
      </Row>
    </>
  );
}
