import { useMemo } from 'react';
// eslint-disable-next-line import/no-duplicates
import { formatDistance, parse } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { bg } from 'date-fns/locale';

import { NOTIFICATIONS_DATETIME_FORMAT } from '@/app/layout/constants';

type HeaderMenuContentItemTimeProps = {
  createdAt: string;
};

export function HeaderMenuContentItemTime({ createdAt }: HeaderMenuContentItemTimeProps) {
  const relativeTime = useMemo<string>(() => {
    const date = parse(createdAt, NOTIFICATIONS_DATETIME_FORMAT, new Date());

    return formatDistance(date, new Date(), { addSuffix: true, locale: bg });
  }, [createdAt]);

  return (
    <span className="text-muted" title={createdAt}>
      {relativeTime}
    </span>
  );
}
