import { FormikContextType } from 'formik';

import { Button } from '@/app/components/Button/Button';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { RatingControl } from '@/app/components/Form/RatingControl/RatingControl';
import { TextareaControl } from '@/app/components/Form/TextareaControl/TextareaControl';
import { handleFrontEndValidations } from '@/app/components/Form/utils';

import { FEEDBACK_COMMENT_PLACEHOLDER } from './constants';
import { CourseFeedbackFormValues } from './models';

const COURSE_RATING_FIELD_NAME = 'courseRating';

type CourseRatingFormProps = {
  formik: FormikContextType<CourseFeedbackFormValues>;
  onSectionHide?: () => void;
  isEdit?: boolean;
};

export function CourseFeedbackForm({ formik, onSectionHide, isEdit = false }: CourseRatingFormProps) {
  return (
    <Form formik={formik} onSubmit={handleFrontEndValidations(formik)} skipDirtyPrompt>
      <RatingControl name={COURSE_RATING_FIELD_NAME} />

      <FormGroup className="mb-4 text-start" controlId="courseComment">
        <Label>Коментар</Label>
        <TextareaControl
          hasSolidBackground={false}
          name="courseComment"
          rows={10}
          placeholder={FEEDBACK_COMMENT_PLACEHOLDER}
        />
      </FormGroup>

      <div className="d-flex justify-content-center">
        <Button type="button" variant="secondary" onClick={onSectionHide}>
          Отказ
        </Button>

        <Button type="submit" variant="primary" className="ms-4" isLoading={formik.isSubmitting}>
          {isEdit ? 'Редактирай' : 'Изпрати'}
        </Button>
      </div>
    </Form>
  );
}
