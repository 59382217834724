import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { PlatformSettingsData } from '@/app/modules/platform-settings/models';

const ENDPOINT_PREFIX = '/ajax/platform-settings';

export const ENDPOINTS = {
  platformSettings: ENDPOINT_PREFIX,
};

//#region GET COURSE PAGE BANNER EDIT DATA
export const getPlatformSettings = createAsyncThunk('platformSettings/getPlatformSettings', async function () {
  const response = await axios.get<PlatformSettingsData>(ENDPOINTS.platformSettings);
  return response.data;
});
//#endregion GET COURSE PAGE BANNER EDIT DATA
