import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Alert } from '@/app/components/Alert/Alert';
import { AlertBody } from '@/app/components/Alert/AlertBody';
import { AlertIcon } from '@/app/components/Alert/AlertIcon';
import { EditStepButton } from '@/app/components/BecomeTutor/EditStepButton';
import { GetStartedButton } from '@/app/components/BecomeTutor/GetStartedButton';
import { Button } from '@/app/components/Button/Button';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSmallTitle } from '@/app/components/Card/CardSmallTitle';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getTutorStatus, sendTutorForApproval } from '../auth/service';
import { getTutorFirstCourseTransliteratedSlug } from './service';

export function BecomeTutorPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const tutorLoading = useAppSelector((state) => state.auth.tutorLoading);
  const firstCourseTransliteratedSlugLoading = useAppSelector(
    (state) => state.tutor.firstCourseTransliteratedSlugLoading
  );
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);
  const firstCourseTransliteratedSlug = useAppSelector((state) => state.tutor.firstCourseTransliteratedSlug);
  const [sendIsLoading, setSendIsLoading] = useState(false);

  useEffect(
    function componentDidMount() {
      if (tutorLoading === LoadingState.Idle) {
        dispatch(getTutorStatus());
      }

      if (firstCourseTransliteratedSlugLoading === LoadingState.Idle) {
        dispatch(getTutorFirstCourseTransliteratedSlug());
      }
    },
    [tutorStatusID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function handleTutorStatus() {
      if (tutorStatusID) {
        const becomeTutorStatusIDs = [
          TutorStatusID.NotStarted,
          TutorStatusID.ProfileDetailsReady,
          TutorStatusID.FirstCourseReady,
        ];

        if (tutorStatusID === TutorStatusID.WaitingApproval) {
          history.replace('/tutor/waiting-approval');
        } else if (tutorStatusID === TutorStatusID.Rejected) {
          history.replace('/tutor/rejected');
        } else if (!becomeTutorStatusIDs.includes(tutorStatusID)) {
          history.replace('/profile');
        }
      }
    },
    [history, tutorStatusID]
  );

  async function handleSendTutorForApproval() {
    // Sanity check.
    if (tutorStatusID !== TutorStatusID.FirstCourseReady) {
      return;
    }

    setSendIsLoading(true);

    try {
      await dispatch(sendTutorForApproval());
      history.push('/tutor/waiting-approval');
    } catch (error) {
      setSendIsLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Стани обучител</title>
      </Helmet>
      <Page>
        <Content>
          <PageTitle className="mb-5 mb-lg-9 fw-bolder fs-2x text-center">Стани обучител</PageTitle>

          <Card>
            <CardHeader>
              <CardTitle>Стани обучител</CardTitle>
              <CardSubTitle>
                За да станете обучител е необходимо да допълните Вашият профил и да създадете първото си обучение.
              </CardSubTitle>
            </CardHeader>

            <CardBody className="container">
              <Row className="g-8">
                <Col md={8}>
                  <Card
                    variant="solid"
                    className="p-8 flex-column flex-sm-row align-items-center justify-content-between"
                  >
                    <CardSmallTitle className="mb-5 mb-sm-0 text-center text-sm-start">
                      1. Допълнете Вашия профил
                    </CardSmallTitle>

                    {(tutorStatusID === TutorStatusID.ProfileDetailsReady ||
                      tutorStatusID === TutorStatusID.FirstCourseReady) && <EditStepButton to="/tutor/profile/edit" />}

                    {tutorStatusID === TutorStatusID.NotStarted && <GetStartedButton to="/tutor/profile/create" />}
                  </Card>
                </Col>

                <Col md={8}>
                  <Card
                    variant="solid"
                    className="p-8 flex-column flex-sm-row align-items-center justify-content-between"
                  >
                    <CardSmallTitle className="mb-5 mb-sm-0 text-center text-sm-start">
                      2. Създайте първото си обучение
                    </CardSmallTitle>

                    {tutorStatusID === TutorStatusID.FirstCourseReady && (
                      <EditStepButton to={`/courses/${firstCourseTransliteratedSlug}/edit`} />
                    )}

                    {(tutorStatusID === TutorStatusID.NotStarted ||
                      tutorStatusID === TutorStatusID.ProfileDetailsReady) && (
                      <GetStartedButton
                        to="/courses/create"
                        isDisabled={tutorStatusID !== TutorStatusID.ProfileDetailsReady}
                      />
                    )}
                  </Card>
                </Col>
                <Col md={8}>
                  <Card
                    variant="solid"
                    className="p-8 flex-column flex-sm-row align-items-center justify-content-between"
                  >
                    <CardSmallTitle className="mb-5 mb-sm-0 text-center text-sm-start">
                      3. Изпрати за одобрение
                    </CardSmallTitle>

                    <Button
                      className="w-100 mw-150px w-sm-auto"
                      type="button"
                      onClick={handleSendTutorForApproval}
                      disabled={tutorStatusID !== TutorStatusID.FirstCourseReady}
                      variant={tutorStatusID !== TutorStatusID.FirstCourseReady ? 'light-primary' : 'primary'}
                      isLoading={sendIsLoading}
                    >
                      Изпрати
                    </Button>
                  </Card>
                </Col>

                <Col md={8}>
                  <Alert variant="info" className="mb-0">
                    <AlertIcon path="/img/svg/icons/Code/Info-circle.svg" variant="info" />
                    <AlertBody>
                      В случай, че прекъснете процеса, ние ще запазим Вашият прогрес и може да продължите от същата
                      стъпка.
                    </AlertBody>
                  </Alert>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Content>
      </Page>
    </>
  );
}
