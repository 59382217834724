import { LinkButton } from '../Button/LinkButton';

type EditStepButtonProps = {
  to: string;
};

export function EditStepButton({ to }: EditStepButtonProps) {
  return (
    <div className="d-flex align-items-center">
      <LinkButton className="w-100 mw-150px me-5" variant="light-primary" to={to}>
        Редактирай
      </LinkButton>
      <i className="fas fa-check-circle text-success fs-2x" />
    </div>
  );
}
