import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { VirtualRoom } from '@/app/components/VirtualRoom/VirtualRoom';
import { getMessengerThreadVideoConference } from '@/app/modules/messenger/service';
import { VirtualRoomMessengerThread } from '@/app/modules/messenger/VirtualRoomMessengerThread';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function MessengerThreadVideoConferencePage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const loading = useAppSelector((state) => state.messenger.conferenceLoading);
  const data = useAppSelector((state) => state.messenger.videoConference);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isChatShown, setIsChatShown] = useState<boolean>(false);

  function handleChatToggle() {
    setIsChatShown((prev) => !prev);
  }

  const handleClose = useCallback(() => {
    history.replace(`/messenger/${id}`);
  }, [history, id]);

  useEffect(function componentDidMount() {
    async function fetchInitialData() {
      await dispatch(getMessengerThreadVideoConference(Number(id)));
      setIsDataLoading(false);
    }

    if (loading === LoadingState.Idle) {
      fetchInitialData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="d-flex">
        {isChatShown && (
          <VirtualRoomMessengerThread id={Number(id)} recipient={data.recipient} handleChatToggle={handleChatToggle} />
        )}
        <VirtualRoom
          domain={data?.jitsiDomain}
          uuid={data?.virtualRoomUUID}
          jwt={data?.jwt}
          onReadyToClose={handleClose}
          isDataLoading={isDataLoading}
          onChatToggle={handleChatToggle}
        />
      </div>
    </>
  );
}
