import './HeaderMenuContentItemImg.scss';

type HeaderMenuContentItemImgProps = {
  src: string;
  alt: string;
};

export function HeaderMenuContentItemImg({ src, alt }: HeaderMenuContentItemImgProps) {
  return (
    <div>
      <img className="header-menu-content-item-img w-50px h-50px rounded me-5" src={src} alt={alt} />
    </div>
  );
}
