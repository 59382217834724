import { ReactNode } from 'react';
import { NavLink, RouteProps } from 'react-router-dom';
import clsx from 'clsx';
import * as H from 'history';

import { isMenuActive } from '@/app/utils/isMenuActive';

type AsideMenuItemProps<S> = {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  children: ReactNode;
  additionalActive?: string | string[] | RouteProps | RouteProps[];
  isExact?: boolean;
  isPulsing?: boolean;
};

export function AsideMenuItem<S>({
  to,
  children,
  additionalActive,
  isExact = false,
  isPulsing = false,
}: AsideMenuItemProps<S>) {
  const isActive = isMenuActive(additionalActive);

  return (
    <div className="menu-item">
      <NavLink to={to} className="menu-link without-sub" activeClassName="active" exact={isExact} isActive={isActive}>
        <span className={clsx('menu-title', { pulse: isPulsing })}>{children}</span>
      </NavLink>
    </div>
  );
}
