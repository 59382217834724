import { ReactNode } from 'react';
import { Row } from 'react-bootstrap';
import clsx from 'clsx';

import './CardSection.scss';

type CardSectionProps = {
  children: ReactNode;
  className?: string;
};

export function CardSection({ children, className }: CardSectionProps) {
  return <Row className={clsx('card-section', className)}>{children}</Row>;
}
