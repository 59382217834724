import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { parse, stringify } from 'qs';

export function stringifyQuery(query: unknown) {
  return stringify(query, { arrayFormat: 'brackets', encode: true });
}

export function parseQuery(query: string) {
  return parse(query);
}

export function useQueryParams<T>() {
  const location = useLocation();
  const currentSearchParams = useMemo(() => {
    return location.search.replace('?', '');
  }, [location.search]);
  const queryParams = useMemo<T>(() => {
    const parsedQueryParams = parseQuery(currentSearchParams) as unknown as T;

    for (const key in parsedQueryParams) {
      if (!Object.prototype.hasOwnProperty.call(parsedQueryParams, key)) {
        continue;
      }

      if (String(parsedQueryParams[key]) === '') {
        delete parsedQueryParams[key];
      }
    }

    return parsedQueryParams;
  }, [currentSearchParams]);

  return { currentSearchParams, queryParams };
}
