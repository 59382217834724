import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import $ from 'jquery';
import type { DataParams, Event, OptionData } from 'select2';
import select2, { TranslationArg } from 'select2';

import { LoadingState } from '@/redux/constants';

import { DEFAULT_PLACEHOLDER } from './constants';
import { ExtendedSelect2Options, SharedSelectControlProps } from './models';

select2($);

type BaseSelectControlProps<T = string> = SharedSelectControlProps & {
  options: ExtendedSelect2Options;
  onChange: (optionData: OptionData) => void | undefined;
  onFormikChange: ($selectEl: JQuery<HTMLSelectElement>, fieldValue: T, shouldReevaluate: boolean) => void | undefined;
};

export function BaseSelectControl<T = string>({
  name,
  options,
  onChange,
  onFormikChange,
  id,
  className,
  placeholder = DEFAULT_PLACEHOLDER,
  isDisabled = false,
  hasSolidBackground = true,
  isSearchable = true,
  minimumInputLength = 0,
  ...props
}: BaseSelectControlProps<T>) {
  const selectEl: MutableRefObject<HTMLSelectElement | null> = useRef<HTMLSelectElement>(null);
  const $selectEl: MutableRefObject<JQuery<HTMLSelectElement> | null> = useRef<JQuery<HTMLSelectElement>>(null);

  const formik = useFormikContext();
  const field = formik.getFieldMeta<T>(name);
  const internalOptions = useMemo<ExtendedSelect2Options>(() => {
    return produce(options, (draft) => {
      draft.placeholder = placeholder;
      draft.selectionCssClass = ':all:';
      draft.theme = 'bootstrap5';

      if (!isSearchable) {
        draft.minimumResultsForSearch = -1;
      }

      if (minimumInputLength) {
        draft.minimumInputLength = minimumInputLength;
      }

      draft.language = {
        searching: () => 'Търсене...',
        noResults: () => 'Не са намерени резултати.',
        errorLoading: () => 'Резултатите не могат да бъдат показани.',
        inputTooShort: function (arg: TranslationArg) {
          const symbolsLength = arg.minimum - arg.input.length;
          return `Въведете поне ${symbolsLength} символа.`;
        },
      };
    });
  }, [isSearchable, minimumInputLength, options, placeholder]);

  useEffect(
    function initSelect2() {
      // Find the closest modal element and if such exists, set it as dropdown parent so select control can be auto focused in modals
      let $modalEl: JQuery<HTMLElement> | undefined = $selectEl.current?.closest?.('.modal-content');

      if (Number($modalEl?.length) === 0) {
        $modalEl = $selectEl.current?.closest?.('.modal');
      }

      let options = internalOptions;

      if (Number($modalEl?.length) > 0) {
        options = produce(internalOptions, (draft) => {
          draft.dropdownParent = $modalEl as WritableDraft<JQuery<HTMLElement>>;
        });
      }

      // make sure to reset the previous options data and set the new one
      $selectEl.current?.empty()?.append?.(new Option())?.trigger?.('change');
      $selectEl.current?.select2?.(options);

      function handleFocus() {
        $selectEl.current?.data?.('select2')?.$dropdown?.find?.('.select2-search__field')?.[0]?.focus?.();
      }

      function handleSelect(event: Event<HTMLSelectElement, DataParams>) {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLSelectElement.prototype,
          'value'
        )?.set;
        nativeInputValueSetter?.call?.(selectEl.current, event.params.data.id);

        const newEvent = new Event('change', { bubbles: true });
        selectEl.current?.dispatchEvent?.(newEvent);
      }

      $selectEl.current?.on?.('select2:open', handleFocus);
      $selectEl.current?.on?.('select2:select', handleSelect);

      return function destroySelect2() {
        $selectEl.current?.select2?.('destroy');
        $selectEl.current?.off?.('select2:open', handleFocus);
        $selectEl.current?.off?.('select2:select', handleSelect);
      };
    },
    [internalOptions]
  );

  useEffect(
    function handleFormikChange() {
      if ($selectEl.current) {
        const shouldReevaluate = $selectEl.current.val() !== String(field.value);
        onFormikChange($selectEl.current, field.value, shouldReevaluate);
      }
    },
    [field.value, onFormikChange]
  );

  function internalHandleChange() {
    const data = $selectEl.current?.select2('data');
    const optionData = data?.[0];

    if (optionData) {
      onChange(optionData);
    }
  }

  return (
    <select
      tabIndex={-1}
      ref={(ref) => {
        if (ref !== null) {
          selectEl.current = ref;
          $selectEl.current = $(ref);
        }
      }}
      id={id ?? name}
      name={name}
      className={clsx('form-select form-control', className, {
        'form-control-solid': hasSolidBackground,
      })}
      onChange={internalHandleChange}
      onFocus={formik.handleBlur}
      disabled={isDisabled || formik.status === LoadingState.Pending}
      {...props}
    />
  );
}
