import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { InfiniteScroll } from '@/app/components/InfiniteScroll/InfiniteScroll';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { Timeline } from '@/app/components/Timeline/Timeline';
import { TimelineShowFilters } from '@/app/components/Timeline/TimelineShowFilters/TimelineShowFilters';
import { getProfileMyStudentTimelineShowData } from '@/app/modules/profile/my-student/service';
import profileMyStudentSlice from '@/app/modules/profile/my-student/store';
import { StickyHeader } from '@/app/modules/profile/StickyHeader';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

import { ProfileTimelineItem } from '../components/ProfileTimelineItem';

export function ProfileMyStudentShowPage() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const loading = useAppSelector((state) => state.profileMyStudent.loading);
  const timeline = useAppSelector((state) => state.profileMyStudent.timeline);
  const timelineEvents = useAppSelector((state) => state.profileMyStudent.timelineEvents);
  const timelineEventTypes = useAppSelector((state) => state.profileMyStudent.timelineEventTypes);
  const timeSlots = useAppSelector((state) => state.profileMyStudent.timeSlots);
  const hasMore = useAppSelector((state) => state.profileMyStudent.hasMore);
  const currentPage = useAppSelector((state) => state.profileMyStudent.currentPage);

  useEffect(function componentDidMount() {
    return function componentWillUnmount() {
      dispatch(profileMyStudentSlice.actions.resetTimelineEvents());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoadMore = useCallback(() => {
    dispatch(profileMyStudentSlice.actions.loadMore());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetTimelineEvents = useCallback(() => {
    dispatch(profileMyStudentSlice.actions.resetTimelineEvents());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>История на обучението</title>
      </Helmet>
      <StickyHeader className="py-5">
        <PageTop>
          <PageTitle className="fw-bolder fs-2x text-center">История на обучението</PageTitle>
          <PageActions>
            <LinkButton to={`/profile/my-students/lesson-feedback?timelineID=${id}`}>Добави коментар</LinkButton>
          </PageActions>
        </PageTop>
      </StickyHeader>
      <Card>
        <CardHeader className="text-break">
          <CardTitle>
            {timeline.courseName} при {timeline.tutorName}
          </CardTitle>
          {timeline.connectedStudentName && <CardSubTitle>Обучаем: {timeline.connectedStudentName}</CardSubTitle>}
        </CardHeader>
        <CardBody>
          <TimelineShowFilters
            loading={loading}
            timelineEventTypeOptions={timelineEventTypes}
            timeSlotOptions={timeSlots}
            onChange={handleResetTimelineEvents}
            onLoad={getProfileMyStudentTimelineShowData}
            page={currentPage}
          />
          <Timeline>
            {timelineEvents.map((event) => (
              <ProfileTimelineItem key={event.id} event={event} />
            ))}
            {timelineEvents.length === 0 && (
              <h2 className="mt-10 text-center mb-0">Все още нямате история за това обучение.</h2>
            )}
          </Timeline>
        </CardBody>
      </Card>
      {loading === LoadingState.Pending && <GlobalSpinner />}
      <InfiniteScroll onLoadMore={handleLoadMore} hasMore={hasMore} />
    </>
  );
}
