type HideFiltersButtonMobileProps = {
  onClickHide: () => void;
};

export function HideFiltersButtonMobile({ onClickHide }: HideFiltersButtonMobileProps) {
  return (
    <button
      type="button"
      className="btn btn-bg-white d-flex align-items-center border border-gray-300 w-100"
      onClick={onClickHide}
    >
      <span className="w-100">Скрий филтри</span>
      <i className="far ms-2 fa-chevron-up" />
    </button>
  );
}
