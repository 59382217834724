import { CoursePackageModel } from './../course/create/models';
import { CourseSubject } from './../course/models';

export interface FavoriteCourse {
  id: number;
  courseName: string;
  courseStatusID: number;
  tutorID: number;
  tutorName: string;
  tutorProfileImagePath: string;
  rating: string;
  targetAudience: string;
  courseTransliteratedSlug: string;
  tutorTransliteratedSlug: string;
  availableLessonCount: number;
  subjects: CourseSubject[];
  coursePackage: CoursePackageModel;
  hasAvailableFutureTimeSlots: boolean;
}

export interface FavoriteCourseFilterValues {
  search: string;
  availableLessonsRange: string[];
}

export interface FavoriteCourseFilterQueryParams
  extends Omit<FavoriteCourseFilterValues, 'search' | 'availableLessonsRange'> {
  search?: string;
  availableLessonsRange?: string[];
}

export enum FavoriteCourseFilterAvailableLessonsRange {
  EqualTo0 = 'equal_to_0',
  From0To5 = 'from_0_to_5',
  From5To10 = 'from_5_to_10',
  Over10 = 'over_10',
}
