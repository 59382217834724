import clsx from 'clsx';

type StepperButtonProps = {
  value: number;
  buyLessonStep: number;
  stepTitle: string;
};

export function StepperButton({ value, buyLessonStep, stepTitle }: StepperButtonProps) {
  return (
    <div className="buy-lesson-step-item">
      <button
        type="button"
        value={value}
        className={clsx('buy-lesson-step-button text-center', {
          'buy-lesson-step-done': buyLessonStep > value,
        })}
        aria-expanded={buyLessonStep === value}
      >
        {value}
      </button>
      <div className="step-title">{stepTitle}</div>
    </div>
  );
}
