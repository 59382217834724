import { Col, Row } from 'react-bootstrap';

import { Card } from '@/app/components/Card/Card';
import { CardBody } from '@/app/components/Card/CardBody';
import { NO_AVATAR_PATH } from '@/app/components/Form/PictureControl/constants';
import { INDIVIDUAL_TYPE_ID, PACKAGE_TYPE_ID, SPECIAL_TYPE_ID } from '@/app/modules/course/constants';
import { OrderDetailsData } from '@/app/modules/order/models';
import { formatPrice } from '@/app/utils/money';

type OrderDetailsShowPageProps = {
  data: OrderDetailsData;
  isSuccessful: boolean;
};

export function OrderDetails({ data, isSuccessful }: OrderDetailsShowPageProps) {
  return (
    <>
      <h1 className="text-gray-800 fs-2tx mb-10">
        {isSuccessful && 'Успешно закупихте обучението'}
        {!isSuccessful && 'Възникна грешка при покупката на обучението'}
      </h1>

      {isSuccessful && (
        <>
          <Card className="text-start mb-10">
            <CardBody>
              <Row>
                <Col className="mw-md-200px pb-6 pb-md-0">
                  <h2 className="h1 fw-bold mb-0">Обучение</h2>
                </Col>
                <Col xs={12} md={{ span: true }}>
                  <h3 className="fw-bold">{data.course.name}</h3>
                  <div className="d-flex flex-wrap align-items-center">
                    <img
                      src={data.course.tutorProfileImagePath ?? NO_AVATAR_PATH}
                      alt="Профилна снимка"
                      className="w-20px h-20px rounded me-2"
                    />
                    <h4 className="mb-0 fs-6 fw-normal">{data.course.tutorName}</h4>
                  </div>
                </Col>
              </Row>

              <hr className="bg-gray-200 opacity-100 my-8" />

              <Row>
                <Col className="mw-md-200px pb-6 pb-md-0">
                  <h2 className="h1 fw-bold mb-0">Пакет</h2>
                </Col>

                <Col xs={12} md={{ span: true }}>
                  <div className="d-flex flex-column justify-content-center">
                    <h4 className="mb-0 fs-6 fw-bold">{data.coursePackage?.name}</h4>
                    {Boolean(
                      data.coursePackage?.typeID === INDIVIDUAL_TYPE_ID ||
                        data.coursePackage?.typeID === SPECIAL_TYPE_ID
                    ) && `1 урок${data.bonusLessonCount > 0 ? ` (+${data.bonusLessonCount} бонус)` : ''}`}
                    {data.coursePackage?.typeID === PACKAGE_TYPE_ID && (
                      <p className="mb-0 fs-6 fw-normal">{data.coursePackage?.lessonCount} урока</p>
                    )}
                  </div>
                </Col>
              </Row>

              <hr className="bg-gray-200 opacity-100 my-8" />

              <Row className="align-items-center">
                <Col className="mw-md-200px pb-6 pb-md-0">
                  <h2 className="h1 fw-bold mb-0">Крайна цена</h2>
                </Col>

                <Col xs={12} md={{ span: true }}>
                  <h4 className="fw-normal mb-0">{formatPrice(data.coursePackage?.taxedPrice as string)}</h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
}
