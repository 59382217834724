import { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import { Button } from '@/app/components/Button/Button';
import { ModalCloseButton } from '@/app/components/Modal/ModalCloseButton';
import { formatPromoCodeValue } from '@/app/utils/money';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getProfilePromoCodes } from '../../profile/promo-codes/services';

type PromoCodeListModalProps = {
  isShown: boolean;
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  applyPromoCode: (promoCode: string) => Promise<void>;
};

export function PromoCodeListModal({ isShown, setIsShown, applyPromoCode }: PromoCodeListModalProps) {
  const formik = useFormikContext();
  const dispatch = useAppDispatch();
  const loadingState = useAppSelector((state) => state.profilePromoCode.loading);
  const promoCodes = useAppSelector((state) => state.profilePromoCode.promoCodes);

  useEffect(() => {
    if (!isShown) {
      return;
    }

    if (loadingState === LoadingState.Idle) {
      dispatch(getProfilePromoCodes());
    }
  }, [isShown]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = useCallback(() => {
    setIsShown(false);
  }, [setIsShown]);

  const handlePromoCodeClick = useCallback(
    (event) => {
      formik.setFieldValue('promoCode', event.target.value);
      applyPromoCode(event.target.value);
      handleClose();
    },
    [applyPromoCode, formik, handleClose]
  );

  return (
    <Modal show={isShown} onHide={handleClose} contentClassName="text-center" centered>
      <Modal.Header className="border-0 flex-wrap position-relative">
        <Modal.Title className="flex-grow-1 px-10 fs-2x fw-bold">Изберете промокод</Modal.Title>
        <ModalCloseButton onClose={handleClose} isAbsolutePositioned />
      </Modal.Header>
      <Modal.Body className="pt-md-0 pb-5">
        <div className="d-flex flex-column mb-n2">
          {promoCodes?.length === 0 && 'В момента не разполагате с промокодове'}
          {promoCodes?.map((promoCode, index) => (
            <Button
              key={index}
              value={promoCode.code}
              variant="white"
              className="btn border border-2 text-dark mb-2"
              onClick={handlePromoCodeClick}
            >
              {promoCode.code} - {formatPromoCodeValue(promoCode.value, promoCode.discountType)}
            </Button>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center pt-0 border-top-0">
        <Button variant="secondary" onClick={handleClose}>
          Отказ
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
