import { useEffect, useMemo, useState } from 'react';
import { Dropdown, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import * as yup from 'yup';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { DropdownCheckboxControlItem } from '@/app/components/Dropdown/DropdownCheckboxControlItem';
import { DropdownMenu } from '@/app/components/Dropdown/DropdownMenu';
import { DropdownToggle } from '@/app/components/Dropdown/DropdownToggle';
import { Form } from '@/app/components/Form/Form';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { SegmentedNav } from '@/app/components/SegmentedNav/SegmentedNav';
import { SegmentedNavItem } from '@/app/components/SegmentedNav/SegmentedNavItem';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import {
  EnrolledCourseFilterAvailableLessonsRange,
  EnrolledCourseFilterQueryParams,
  EnrolledCourseFilterValues,
} from '@/app/modules/enrolled-course/models';
import { transformEnrolledCourseFormikValuesToSearchParams } from '@/app/modules/enrolled-course/utils';
import { useQueryParams } from '@/app/utils/query';
import { useDebounce } from '@/app/utils/useDebounce';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { EnrolledCourseFilterInitialValues } from './constants';
import { EnrolledCourseItem } from './EnrolledCourseItem/EnrolledCourseItem';
import { getEnrolledCourses } from './service';

export function EnrolledCourseIndexPage() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.enrolledCourse.loading);
  const enrolledCourses = useAppSelector((state) => state.enrolledCourse.enrolledCourses);
  const [loadingState, isInitialDataLoaded] = useLoading(loading);
  const pageTitle = 'Записани обучения';

  const { currentSearchParams, queryParams } = useQueryParams<EnrolledCourseFilterQueryParams>();

  const initialValues = useMemo<EnrolledCourseFilterValues>(
    () => ({
      search: queryParams?.search ?? EnrolledCourseFilterInitialValues.search,
      availableLessonsRange:
        queryParams?.availableLessonsRange ?? EnrolledCourseFilterInitialValues.availableLessonsRange,
    }),
    [queryParams.search, queryParams.availableLessonsRange]
  );
  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<EnrolledCourseFilterValues>({
    initialValues,
    validationSchema,
  });
  const debouncedQueryParams = useDebounce<EnrolledCourseFilterQueryParams>(queryParams, 600);

  const isEmpty = loadingState === LoadingState.Idle && enrolledCourses.length === 0;

  const areFiltersApplied = useMemo(() => {
    return formik.values.search !== '' || formik.values.availableLessonsRange.length > 0;
  }, [formik.values]);

  const isEmptyWithFilters = useMemo(() => {
    return isEmpty && areFiltersApplied;
  }, [isEmpty, areFiltersApplied]);

  const isEmptyWithoutFilters = useMemo(() => {
    return isEmpty && !areFiltersApplied;
  }, [isEmpty, areFiltersApplied]);

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformEnrolledCourseFormikValuesToSearchParams(formik.values, queryParams);

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
      }

      if (newSearchParams === '') {
        formik.setValues(EnrolledCourseFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  useEffect(
    () => {
      if (loading === LoadingState.Idle) {
        dispatch(getEnrolledCourses(debouncedQueryParams));
      }
    },
    [debouncedQueryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#region Handle filter toggle
  const [isFilterToggled, setIsFilterToggled] = useState(false);

  function handleFilterToggle() {
    setIsFilterToggled((prev) => !prev);
  }
  //#endregion Handle filter toggle

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Page
        className="bg-white p-0"
        renderContent={(props) => <Form {...props} formik={formik} disabled={formik.isSubmitting} skipDirtyPrompt />}
      >
        <div className="bg-gray-200">
          <Content isContainerContent>
            <PageTop isVertical>
              <PageTitle className="my-2">{isInitialDataLoaded ? pageTitle : 'Зарежда се...'}</PageTitle>
              <div className="d-flex flex-column flex-md-row flex-nowrap align-items-start align-items-md-center justify-content-between mb-n4 w-100">
                <SegmentedNav className="d-flex flex-nowrap align-items-center mb-4">
                  <SegmentedNavItem to="/enrolled-courses">Записани обучения</SegmentedNavItem>
                  <SegmentedNavItem to="/favorite-courses">Добавени в любими</SegmentedNavItem>
                </SegmentedNav>
                <div className="d-flex flex-column w-100 w-md-auto ms-auto mb-4">
                  <button
                    type="button"
                    className="btn btn-bg-white d-flex align-items-center border border-gray-300 w-100 px-3 d-md-none"
                    onClick={handleFilterToggle}
                  >
                    <span className="d-block mx-auto">{!isFilterToggled ? 'Покажи филтри' : 'Скрий филтри'}</span>
                    <i
                      className={clsx('far', { 'fa-chevron-up': isFilterToggled, 'fa-chevron-down': !isFilterToggled })}
                    />
                  </button>
                  <div
                    className={clsx('d-md-flex flex-wrap align-items-center mt-4 mt-md-0 mb-n4 me-n4', {
                      'd-flex': isFilterToggled,
                      'd-none': !isFilterToggled,
                    })}
                  >
                    <FormGroup className="d-flex flex-wrap align-items-center mb-4 me-4">
                      <Label htmlFor="search" className="mb-0 me-3">
                        Филтрация по:
                      </Label>
                      <FormControl
                        hasSolidBackground={false}
                        placeholder="Обучител"
                        name="search"
                        type="text"
                        className="w-150px form-control-md"
                        value={formik.values.search}
                      />
                    </FormGroup>

                    <Dropdown align="end" autoClose="outside">
                      <DropdownToggle variant="white-bordered" className="form-control-lg mb-4 me-4">
                        Налични уроци
                      </DropdownToggle>

                      <DropdownMenu className="py-4">
                        <DropdownCheckboxControlItem
                          name="availableLessonsRange"
                          label="0 налични"
                          value={EnrolledCourseFilterAvailableLessonsRange.EqualTo0}
                        />

                        <DropdownCheckboxControlItem
                          name="availableLessonsRange"
                          label="0-5 налични"
                          value={EnrolledCourseFilterAvailableLessonsRange.From0To5}
                        />

                        <DropdownCheckboxControlItem
                          name="availableLessonsRange"
                          label="5-10 налични"
                          value={EnrolledCourseFilterAvailableLessonsRange.From5To10}
                        />

                        <DropdownCheckboxControlItem
                          name="availableLessonsRange"
                          label="Над 10 налични"
                          value={EnrolledCourseFilterAvailableLessonsRange.Over10}
                        />
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </PageTop>
          </Content>
        </div>
        <Content isContainerContent>
          <Row className="g-9">
            {enrolledCourses.map((course) => (
              <EnrolledCourseItem key={course.courseID} course={course} />
            ))}

            {isEmptyWithoutFilters && (
              <>
                <p className="mb-0 fw-bold text-center">В момента нямате закупени уроци.</p>
                <div className="mt-5 d-flex justify-content-center">
                  <LinkButton to="/subjects" variant="primary">
                    Намери подходящо обучение
                  </LinkButton>
                </div>
              </>
            )}
            {isEmptyWithFilters && (
              <p className="mb-0 fw-bold text-center">
                Нямате закупени уроци, които да отговарят на избраните от Вас филтри.
              </p>
            )}
          </Row>
        </Content>
        {loadingState === LoadingState.Pending && <GlobalSpinner />}
      </Page>
    </>
  );
}
