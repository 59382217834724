import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { AgreementFormValues } from '@/app/modules/auth/models';
import { updateIdentityTermAgreement } from '@/app/modules/auth/service';
import { useAppSelector } from '@/redux/store';

const PRIVACY_ACCEPTED_LABEL_TEXT =
  'Известно ми е, че за деклариране на неверни обстоятелства и осъществяване на неправомерен достъп до данни нося отговорност съгласно действащото българско законодателство.';

const IS_OVER_FOURTEEN_YEARS_OLD_LABEL_TEXT = 'Заявявам, че съм над 14-годишна възраст.';

export function TermAgreementModal() {
  const [isAgreementModalShown, setIsAgreementModalShown] = useState(false);
  const { privacyAcceptedAt, termsAcceptedAt, overFourteenYearsOldAgreedAt } = useAppSelector((state) => state.auth);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const shouldShowAgreementModal =
    privacyAcceptedAt === null || termsAcceptedAt === null || overFourteenYearsOldAgreedAt === null;

  useEffect(
    function componentDidMount() {
      if (isAuthenticated) {
        setIsAgreementModalShown(shouldShowAgreementModal);
      }
    },
    [isAuthenticated, shouldShowAgreementModal]
  );

  //#region Handle form
  const initialValues = useMemo<AgreementFormValues>(
    () => ({
      privacyAccepted: privacyAcceptedAt ? 1 : 0,
      termsAccepted: termsAcceptedAt ? 1 : 0,
      overFourteenYearsOldAgreed: overFourteenYearsOldAgreedAt ? 1 : 0,
    }),
    [overFourteenYearsOldAgreedAt, privacyAcceptedAt, termsAcceptedAt]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        privacyAccepted: yup.boolean().oneOf([true], 'Трябва да приемете декларацията.'),
        termsAccepted: yup.boolean().oneOf([true], 'Трябва да приемете общите условия.'),
        overFourteenYearsOldAgreed: yup.boolean().oneOf([true], 'Трябва да заявите, че сте над 14-годишна възраст.'),
      }),
    []
  );

  const formik = useForm<AgreementFormValues>({
    validationSchema,
    initialValues,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<AgreementFormValues>(async (values) => {
        return await updateIdentityTermAgreement(values);
      });

      const response = await submitWithValidation(values, formikHelpers);
      if (response) {
        setIsAgreementModalShown(false);
        formik.resetForm({ values });
      }
    },
  });
  //#endregion Handle form

  function handleOnHide() {
    setIsAgreementModalShown(true);
  }

  const isInvalid =
    !formik.values.privacyAccepted || !formik.values.termsAccepted || !formik.values.overFourteenYearsOldAgreed;

  return (
    <Modal backdrop="static" className="d-block" show={isAgreementModalShown && isAuthenticated} onHide={handleOnHide}>
      <Form formik={formik} onSubmit={handleFrontEndValidations(formik)} skipDirtyPrompt>
        <Modal.Body>
          <FormGroup className="mb-4 text-start" controlId="privacyAccepted">
            <CheckboxControl name="privacyAccepted" label={PRIVACY_ACCEPTED_LABEL_TEXT} value={1} isInline />
          </FormGroup>
          <FormGroup className="mb-4 text-start" controlId="termsAccepted">
            <CheckboxControl
              name="termsAccepted"
              value={1}
              isInline
              label={
                <>
                  Запознах се с{' '}
                  <a href="https://www.shkolo.bg/terms-user/" target="_blank" rel="noreferrer">
                    Общите условия
                  </a>{' '}
                  и{' '}
                  <a href="https://www.shkolo.bg/privacy/" target="_blank" rel="noreferrer">
                    Политикатa за защита на личните данни
                  </a>{' '}
                  и данни и ги приемам.
                </>
              }
            />
          </FormGroup>
          <FormGroup className="mb-4 text-start" controlId="overFourteenYearsOldAgreed">
            <CheckboxControl
              name="overFourteenYearsOldAgreed"
              label={IS_OVER_FOURTEEN_YEARS_OLD_LABEL_TEXT}
              value={1}
              isInline
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" isLoading={formik.isSubmitting} disabled={isInvalid}>
            Запази
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
