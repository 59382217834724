import { useContext, useEffect } from 'react';

import { FooterContext } from './FooterContext';

export function usePromotionDescription() {
  const { showPromotionDescription, hidePromotionDescription } = useContext(FooterContext);

  useEffect(() => {
    showPromotionDescription();

    return () => hidePromotionDescription();
  }, [showPromotionDescription, hidePromotionDescription]);
}
