import React, { useEffect } from 'react';
import clsx from 'clsx';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { Card } from '@/app/components/Card/Card';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { TimelineIndexFiltersQueryParams } from '@/app/components/Timeline/TimelineIndexFilters/models';
import { TimelineIndexFilters } from '@/app/components/Timeline/TimelineIndexFilters/TimelineIndexFilters';
import { StickyHeader } from '@/app/modules/profile/StickyHeader';
import { useQueryParams } from '@/app/utils/query';
import { useDebounce } from '@/app/utils/useDebounce';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { getProfileMyStudents } from './service';

export function ProfileMyStudentIndexPage() {
  const dispatch = useAppDispatch();
  const loadingState = useAppSelector((state) => state.profileMyStudent.loading);
  const myConnectedStudents = useAppSelector((state) => state.profileMyStudent.myStudents);
  const { queryParams } = useQueryParams<TimelineIndexFiltersQueryParams>();
  const debouncedQueryParams = useDebounce<TimelineIndexFiltersQueryParams>(queryParams, 600);

  useEffect(
    function componentDidMount() {
      if (loadingState === LoadingState.Idle) {
        dispatch(getProfileMyStudents(debouncedQueryParams));
      }
    },
    [debouncedQueryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <StickyHeader className="py-5">
        <PageTop>
          <PageTitle className="fw-bolder fs-2x text-center">Мои обучаеми</PageTitle>
          <PageActions>
            <TimelineIndexFilters />
          </PageActions>
        </PageTop>
      </StickyHeader>

      {myConnectedStudents?.length === 0 && (
        <h2 className="mt-15 d-flex justify-content-center">Все още нямате ваши обучаеми.</h2>
      )}

      {myConnectedStudents?.map((connectedStudent, idx) => {
        const isSelf =
          !connectedStudent.connectedStudentName ||
          connectedStudent.connectedStudentName === connectedStudent.studentName;
        return (
          <Card
            key={idx}
            className={clsx('p-8 shadow-sm', {
              'mb-5': idx < myConnectedStudents?.length - 1,
            })}
          >
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div className="text-center text-md-start text-break">
                <h3 className="me-5 h2 overflow-hidden">
                  {connectedStudent.connectedStudentName ?? connectedStudent.studentName}
                </h3>
                <span className="me-5">{connectedStudent.courseName}</span>
              </div>
              <div className="d-flex justify-content-center justify-content-md-end text-nowrap">
                <LinkButton
                  to={`/profile/my-students/timelines/${connectedStudent.timelineID}`}
                  className="ms-2"
                  variant="primary"
                >
                  История на обучението
                </LinkButton>
                <LinkButton
                  to={`/messenger/create?recipientIdentityID=${connectedStudent.identityID}`}
                  className="ms-2"
                  variant="light-primary"
                >
                  Изпрати съобщение
                </LinkButton>
              </div>
            </div>
            {!isSelf && (
              <div className="d-flex justify-content-end align-items-center mt-1 mb-n8">
                <h6 className="fs-7 fw-normal">До {connectedStudent.studentName}</h6>
              </div>
            )}
          </Card>
        );
      })}
      {loadingState === LoadingState.Pending && <GlobalSpinner />}
    </>
  );
}
