import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import clsx from 'clsx';

type CardSmallTitleProps = {
  children: ReactNode;
  className?: string;
};

export function CardSmallTitle({ children, className }: CardSmallTitleProps) {
  return (
    <Card.Title as="h2" className={clsx('fs-2 fw-normal my-0', className)}>
      {children}
    </Card.Title>
  );
}
