import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { DrawerComponent } from '@/_metronic/assets/ts/components/_DrawerComponent';

import './Content.scss';

type ContentProps = {
  children: ReactNode;
  className?: string;
  isContainerContent?: boolean;
};

export function Content({ children, className, isContainerContent = false }: ContentProps) {
  const location = useLocation();

  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return <div className={clsx('container', className, { 'container-content': isContainerContent })}>{children}</div>;
}
