import { Accordion } from 'react-bootstrap';

import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';

export function TutorHelp() {
  return (
    <div className="mx-auto tutor-help">
      <PageTitle className="mt-20 mb-10 text-center fw-bolder fs-2x">Често задавани въпроси</PageTitle>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Button className="fs-4 fw-bold">
            Какви са общите критерии за качество на обучението?
          </Accordion.Button>
          <Accordion.Body>
            <p>Обучителите в Школо Уроци:</p> Професионалисти, които владеят материята. Отлично подготвени за всеки
            урок. Адаптират се към индивидуалните нужди на ученика. Ясно задават целите и търсените резултати от всеки
            урок. Поставят дългосрочни цели на обучението, заедно с обучаемия. Поднасят информацията разбираемо.
            Използват разнообразни и интерактивни методи на обучение. Отнасят се уважително към обучаемия. Отговарят
            спокойно на всички въпроси на обучаемия. Дават своевременна обратна връзка за прогреса на обучаемия. Спазват
            уговорените часове и оползотворяват времето пълноценно. Осигуряват допълнителни материали за самостоятелна
            работа на обучаемите.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Button className="fs-4 fw-bold">Сключвам ли договор с платформата Школо Уроци?</Accordion.Button>
          <Accordion.Body>
            С цел намаляване на административната тежест, съгласието Ви с Общите условия е напълно достатъчно, за да
            уреди взаимоотношенията ни. Цялата необходима информация е в тях.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Button className="fs-4 fw-bold">
            Има ли абонаментна такса, която е необходимо да плащам?
          </Accordion.Button>
          <Accordion.Body>
            Не, в Школо Уроци няма абонамент за месец или година, а има комисионна от 25% от цената за проведен урок.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Button className="fs-4 fw-bold">Кога и как ще получавам своето заплащане?</Accordion.Button>
          <Accordion.Body>
            Може да превеждате натрупаната сума по посочена от Вас сметка във всеки един момент, стига тя да е над 200
            лв. В случай, че натрупаната сума е под 200 лв. и не желаете да работите повече с платформата, може да се
            обърнете към нас и сумата ще Ви бъде преведена в рамките на един работен ден.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Button className="fs-4 fw-bold">
            Как се определя цената на 1 урок, има ли минимална и максимална ставка?
          </Accordion.Button>
          <Accordion.Body>
            Вие определяте цената спрямо Вашите изисквания при минимална цена от 20 лв. за урок.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Button className="fs-4 fw-bold">
            Как да разбера, че някой се е записал за мой урок?
          </Accordion.Button>
          <Accordion.Body>Ще получите известие в профила си при всяка активност по Вашия график.</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Button className="fs-4 fw-bold">
            Какво е времетраенето на 1 урок и кой го определя?
          </Accordion.Button>
          <Accordion.Body>
            Вие определяте времетраенето, като веднъж зададено, то е валидно за всички предстоящи уроци по всички
            предмети, които предлагате.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Button className="fs-4 fw-bold">Кой определя графика на уроците?</Accordion.Button>
          <Accordion.Body>Вие задавате свободните си дни и часове спрямо ангажиментите си.</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Button className="fs-4 fw-bold">
            Има ли задължителен минимум уроци, които трябва да давам на седмица?
          </Accordion.Button>
          <Accordion.Body>Не, това зависи от свободното Ви време.</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Button className="fs-4 fw-bold">Мога ли да отложа или откажа час?</Accordion.Button>
          <Accordion.Body>Можете да отложите или откажете заявен час до 24 часа преди началото му.</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Button className="fs-4 fw-bold">
            Как ще разбера дали има отказан час от страна на обучаемия?
          </Accordion.Button>
          <Accordion.Body>
            Обучаемият може да откаже запазен час до 24 часа преди началото му. Вие ще получите известие за това в
            профила си.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
