import { useHiddenConsultUs } from '@/_metronic/layout/components/consult-us/useHiddenConsultUs';
import { useHiddenFooter } from '@/app/layout/Footer/useHiddenFooter';

import { MessengerThreadList } from './MessengerThreadList';
import { MessengerThreadPageBase } from './MessengerThreadPageBase';

export function MessengerThreadIndexPage() {
  useHiddenFooter();
  useHiddenConsultUs();

  return (
    <MessengerThreadPageBase>
      <div className="w-100 mw-xl-350px">
        <MessengerThreadList />
      </div>

      <div className="d-none d-xl-flex justify-content-center align-items-center w-100 p-10 bg-light">
        <h3 className="fw-normal text-gray-700">Изберете на кого искате да пишете или започнете нов разговор.</h3>
      </div>
    </MessengerThreadPageBase>
  );
}
