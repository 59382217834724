import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { EditableTimeSlotEntity, TimeSlotEntity } from '@/app/components/Calendar/models';
import { CourseFeedbackComment } from '@/app/modules/course-feedback/models';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

import { CourseCreateValues } from './create/models';
import {
  CompleteCheckoutResponse,
  ConnectedStudentSchedulePayload,
  CourseCheckoutData,
  CourseCheckoutDataPayload,
  CourseDetailsData,
  CourseFreeStudentProposalData,
  CourseFreeStudentProposalValues,
  CourseIndexValues,
  CourseReservationData,
  CourseReservationValues,
  CourseReviewData,
  CourseScheduleData,
  CourseStudentProposal,
  CourseStudentProposalDataPayload,
  FirstCourseEditData,
  FreeCourseLessonUsingPromoCodeDataResponse,
  PaymentIntent,
  PromoCodeData,
  RecurrenceType,
  Subject,
  TargetAudience,
  TutorWithCourse,
} from './models';
import { CourseRefundFormValues } from './refund/models';

const ENDPOINT_PREFIX = '/ajax/courses';
const PACKAGE_ENDPOINT_PREFIX = '/ajax/course-packages';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  entity: (transliteratedSlug: string) => `${ENDPOINT_PREFIX}/${transliteratedSlug}`,
  reservation: (id: number) => `${ENDPOINT_PREFIX}/${id}/reservation`,
  reservationTimeSlots: (id: number) => `${ENDPOINT_PREFIX}/${id}/reservation-timeslots`,
  reserveTimeSlotData: (id: number) => `${ENDPOINT_PREFIX}/${id}/reserve-timeslot`,
  schedule: `${ENDPOINT_PREFIX}/schedule`,
  checkout: (transliteratedSlug: string) => `${ENDPOINT_PREFIX}/${transliteratedSlug}/checkout`,
  paymentIntent: (coursePackageID: number) => `${PACKAGE_ENDPOINT_PREFIX}/${coursePackageID}/paymentIntent`,
  completeCheckout: (coursePackageID: number) => `${PACKAGE_ENDPOINT_PREFIX}/${coursePackageID}/checkout`,
  create: `${ENDPOINT_PREFIX}/create`,
  createSchedule: `${ENDPOINT_PREFIX}/course-schedule`,
  subjects: '/ajax/subjects',
  freeLesson: (id: number) => `${ENDPOINT_PREFIX}/${id}/free`,
  freeLessonUsingPromoCode: (coursePackageID: number) => `${PACKAGE_ENDPOINT_PREFIX}/${coursePackageID}/free`,
  addToFavorites: (id: number) => `${ENDPOINT_PREFIX}/${id}/favorites`,
  removeFromFavorites: (id: number) => `${ENDPOINT_PREFIX}/${id}/favorites`,
  reviews: (id: number) => `${ENDPOINT_PREFIX}/${id}/reviews`,
  feedbacks: (id: number) => `${ENDPOINT_PREFIX}/${id}/feedbacks`,
  promoCode: (promoCode: string) => `/ajax/promo-code/${promoCode}`,
  visit: (transliteratedSlug: string) => `${ENDPOINT_PREFIX}/${transliteratedSlug}/visit`,
  refund: (id: number) => `${ENDPOINT_PREFIX}/${id}/refund`,
  refundAmount: (id: number) => `${ENDPOINT_PREFIX}/${id}/refund/amount`,
  refundPromoCode: (id: number) => `${ENDPOINT_PREFIX}/${id}/refund/promo-code`,
  connectedStudentSchedule: (id: number, connectedStudentID: number) =>
    `${ENDPOINT_PREFIX}/${id}/schedule/${connectedStudentID}`,
  createStudentProposal: (id: number) => `${ENDPOINT_PREFIX}/${id}/student-proposal`,
  freeStudentProposal: (id: number) => `${ENDPOINT_PREFIX}/${id}/free-student-proposal`,
  getStudentProposal: (id: number, studentProposalID: number) =>
    `${ENDPOINT_PREFIX}/${id}/student-proposal/${studentProposalID}`,
  editFirstCourse: (firstCourseTransliteratedSlug: string | null) =>
    `${ENDPOINT_PREFIX}/${firstCourseTransliteratedSlug}/edit`,
  updateFirstCourse: (firstCourseTransliteratedSlug: string | null) =>
    `${ENDPOINT_PREFIX}/${firstCourseTransliteratedSlug}`,
};

//#region Get show course
export type GetCourseResponse = CourseDetailsData;

export const getCourse = createAsyncThunk('course/getCourse', async function (transliteratedSlug: string) {
  const response = await axios.get<GetCourseResponse>(ENDPOINTS.entity(transliteratedSlug));
  return response.data;
});
//#endregion Get show course

//#region Get index courses
export interface GetCoursesResponse {
  subjectName: string | null;
  subjectDescription: string | null;
  targetAudienceName: string | null;
  tutorsWithCoursesTotal: number;
  tutorsWithCourses: TutorWithCourse[];
  similarCourses: TutorWithCourse[];
  allTargetAudiences: TargetAudience[];
  allSubjectKeywords: SubjectKeyword[];
  hasVisitedPLBefore: boolean;
  subjects: Subject[];
}

export const getCourses = createAsyncThunk('course/getCourses', async function (queryParams: CourseIndexValues) {
  const response = await axios.get<GetCoursesResponse>(ENDPOINTS.resource, {
    params: queryParams,
  });
  return response.data;
});
//#endregion Get index courses

//#region Create course
export interface CreateCourseResponse extends Record<string, unknown> {
  id: number;
}

export async function createCourse(values: CourseCreateValues) {
  const formData = serializeFormData(values);
  const response = await axios.post<CreateCourseResponse>(ENDPOINTS.resource, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion Create course

//#region Update first course
export interface UpdateFirstCourseResponse extends Record<string, unknown> {
  id: number;
}

export async function updateFirstCourse(firstCourseTransliteratedSlug: string | null, values: CourseCreateValues) {
  const formData = serializeFormData(values);
  const response = await axios.put<CreateCourseResponse>(
    ENDPOINTS.updateFirstCourse(firstCourseTransliteratedSlug),
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );

  return response.data;
}
//#endregion Update first course

//#region Get course create data
interface GetCourseCreateDataResponse {
  duration: number | null;
  reservationBufferHours: number | string;
  canChangeCourseDuration: boolean;
  canUseLegacyCourseDuration: boolean;
  hasBlockedNewStudentsReservations: boolean;
  subjects: Subject[];
  recurrenceTypes: RecurrenceType[];
  lastTimeSlotUpdatedAt: string | null;
}

export const getCourseCreateData = createAsyncThunk('course/getCourseCreateData', async function () {
  const response = await axios.get<GetCourseCreateDataResponse>(ENDPOINTS.create);
  return response.data;
});
//#endregion Get course create data

//#region Get course edit data
export type GetCourseEditDataResponse = FirstCourseEditData;

export const getCourseEditData = createAsyncThunk(
  'course/getCourseEditData',
  async function (firstCourseTransliteratedSlug: string) {
    const response = await axios.get<GetCourseEditDataResponse>(
      ENDPOINTS.editFirstCourse(firstCourseTransliteratedSlug)
    );
    return response.data;
  }
);
//#endregion Get course edit data

//#region Get course reservation data
export type GetCourseReservationDataProps = { id: number; reserveTimeSlotID?: number | null };
export type GetCourseReservationDataResponse = CourseReservationData;

export const getCourseReservationData = createAsyncThunk(
  'course/getCourseReservationData',
  async function ({ id, reserveTimeSlotID }: GetCourseReservationDataProps) {
    const response = await axios.get<GetCourseReservationDataResponse>(ENDPOINTS.reservation(id), {
      params: {
        reserveTimeSlotID,
      },
    });
    return response.data;
  }
);
//#endregion Get course reservation data

//#region Get course reservation time slot data
export type GetCourseReservationTimeSlotsDataProps = {
  id: number;
  startDate: string;
  endDate: string;
};

export type GetCourseReservationTimeSlotsDataResponse = {
  timeSlots: TimeSlotEntity[];
};

export const getCourseReservationTimeSlotsData = createAsyncThunk(
  'course/getCourseReservationTimeSlotsData',
  async function ({ id, startDate, endDate }: GetCourseReservationTimeSlotsDataProps) {
    const response = await axios.get<GetCourseReservationTimeSlotsDataResponse>(ENDPOINTS.reservationTimeSlots(id), {
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  }
);
//#endregion Get course reservation time slot data

//#region Get reserve time slot data
export type GetReserveTimeSlotResponse = {
  timeSlot: TimeSlotEntity;
};

export const getReserveTimeSlotData = async function (id: number) {
  const response = await axios.get<GetReserveTimeSlotResponse>(ENDPOINTS.reserveTimeSlotData(id));

  return response.data;
};
//#endregion Get reserve time slot data

//#region Create course reservations
export type CreateCourseReservationsResponse = {
  reservationGroupID: number;
};

export async function createCourseReservations(courseID: number, values: CourseReservationValues) {
  const formData = serializeFormData(values);
  const response = await axios.post<CreateCourseReservationsResponse>(ENDPOINTS.reservation(courseID), formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion Create course reservations

//#region Create course student proposal
export type CreateCourseStudentProposalResponse = {
  threadID: number;
};

export async function createCourseStudentProposal(courseID: number, values: CourseReservationValues) {
  const formData = serializeFormData(values);
  const response = await axios.post<CreateCourseStudentProposalResponse>(
    ENDPOINTS.createStudentProposal(courseID),
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );

  return response.data;
}
//#endregion Create course student proposal

//#region Create free course student proposal
export async function createCourseFreeStudentProposal(courseID: number, values: CourseFreeStudentProposalValues) {
  const formData = serializeFormData(values);
  const response = await axios.post<CreateCourseStudentProposalResponse>(
    ENDPOINTS.freeStudentProposal(courseID),
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );

  return response.data;
}
//#endregion Create free course student proposal

//#region get free course student proposal data
export type GetCourseFreeStudentProposalDataResponse = { course: CourseFreeStudentProposalData };

export const getCourseFreeStudentProposalData = createAsyncThunk(
  'course/getCourseFreeStudentProposalData',
  async function (id: number) {
    const response = await axios.get<GetCourseFreeStudentProposalDataResponse>(ENDPOINTS.freeStudentProposal(id));
    return response.data;
  }
);
//#endregion get free course student proposal data

//#region Get course student proposal data
export type GetCourseStudentProposalData = {
  duration: number;
  studentProposal: CourseStudentProposal | null;
  timeSlots: EditableTimeSlotEntity[];
};

export const getCourseStudentProposalData = createAsyncThunk(
  'course/getCourseStudentProposalData',
  async function ({ id, studentProposalID }: CourseStudentProposalDataPayload) {
    const response = await axios.get<GetCourseStudentProposalData>(
      ENDPOINTS.getStudentProposal(id, studentProposalID),
      {}
    );

    return response.data;
  }
);
//#endregion Get course student proposal data

//#region Get course checkout data
export type GetCourseCheckoutResponse = CourseCheckoutData;

export const getCourseCheckoutData = createAsyncThunk(
  'course/getCheckoutData',
  async function ({ transliteratedSlug, reservationID }: CourseCheckoutDataPayload) {
    const response = await axios.get<GetCourseCheckoutResponse>(ENDPOINTS.checkout(transliteratedSlug), {
      params: { reservationID },
    });

    return response.data;
  }
);
//#endregion Get course checkout data

//#region Get course payment intent
export interface GetPaymentIntent {
  paymentIntent: PaymentIntent | null;
}

export const getPaymentIntent = async function (coursePackageID: number, saveCard: boolean, promoCode: string) {
  const response = await axios.post<GetPaymentIntent>(ENDPOINTS.paymentIntent(coursePackageID), {
    saveCard: saveCard,
    promoCode: promoCode,
  });

  return response.data;
};
//#endregion Get course payment intent

//#region Course Complete Checkout

export async function completeCheckout(
  coursePackageID: number,
  paymentIntentID: string,
  cardHolderName: string,
  isNewCard: boolean,
  promoCode: string
) {
  const response = await axios.post<CompleteCheckoutResponse>(ENDPOINTS.completeCheckout(coursePackageID), {
    paymentIntentID: paymentIntentID,
    cardHolderName: cardHolderName,
    isNewCard: isNewCard,
    promoCode: promoCode,
  });

  return response.data;
}
//#endregion Course Complete Checkout

//#region Get FREE course lesson using promo code
export async function getFreeCourseLessonUsingPromoCode(
  coursePackageID: number,
  promoCode: string,
  cardHolderName: string,
  saveCard: boolean,
  isNewCard: boolean,
  paymentMethodID: string | undefined
) {
  const response = await axios.post<FreeCourseLessonUsingPromoCodeDataResponse>(
    ENDPOINTS.freeLessonUsingPromoCode(coursePackageID),
    {
      promoCode: promoCode,
      cardHolderName: cardHolderName,
      saveCard: saveCard,
      isNewCard: isNewCard,
      paymentMethodID: paymentMethodID,
    }
  );

  return response.data;
}
//#endregion Get FREE course lesson using promo code

//#region Get course schedule data
export type GetCourseScheduleDataResponse = CourseScheduleData;

export const getCourseScheduleData = createAsyncThunk(
  'course/getCourseScheduleData',
  async function (params: { startDate: string; endDate: string }) {
    const response = await axios.get<GetCourseScheduleDataResponse>(ENDPOINTS.schedule, { params });

    return response.data;
  }
);
//#endregion Get course schedule data

//#region Get free course lesson
type GetFreeCourseLessonDataResponse = {
  orderID: number;
  isStudentsFirstOrder: boolean;
  canUseLessonPromotion: boolean;
};

export async function getFreeCourseLesson(courseID: number) {
  const response = await axios.post<GetFreeCourseLessonDataResponse>(ENDPOINTS.freeLesson(courseID));

  return response.data;
}
//#endregion Get free course lesson

//#region Add to favorites
export async function addToFavorites(courseID: number) {
  const response = await axios.put<null>(ENDPOINTS.addToFavorites(courseID));
  return response.data;
}
//#endregion Add to favorites

//#region Remove from favorites
export async function removeFromFavorites(courseID: number) {
  const response = await axios.delete<null>(ENDPOINTS.removeFromFavorites(courseID));
  return response.data;
}
//#endregion Remove from favorites

//#region Get course review data
export type GetCourseReviewDataResponse = CourseReviewData;

export const getCourseReviewData = createAsyncThunk('course/getCourseReviewData', async function (id: number) {
  const response = await axios.get<GetCourseReviewDataResponse>(ENDPOINTS.reviews(id));

  return response.data;
});
//#endregion Get course review data

//#region Get course feedbacks paginated index result
export interface GetCourseFeedbackIndexResponse {
  data: CourseFeedbackComment[];
  hasMore: boolean;
  currentPage: number;
}

export const getCourseFeedbacks = createAsyncThunk<GetCourseFeedbackIndexResponse, { id: number; page?: number }>(
  'courseFeedback/getCourseFeedbacks',
  async ({ id, page = 1 }) => {
    const response = await axios.get<GetCourseFeedbackIndexResponse>(ENDPOINTS.feedbacks(id), {
      params: { page },
    });
    return response.data;
  }
);
//#endregion Get course feedbacks paginated index result

//#region Return free course lesson
export async function returnFreeCourseLesson(courseID: number) {
  const response = await axios.put<ReturnFreeLessonResponse>(ENDPOINTS.freeLesson(courseID));

  return response.data;
}

export interface ReturnFreeLessonResponse {
  canUseLessonPromotion: boolean;
}
//#endregion Return free course lesson

//#region Check promo code
export const checkPromoCode = async function (promoCode: string) {
  const response = await axios.get<PromoCodeData>(ENDPOINTS.promoCode(promoCode));

  return response.data;
};
//#endregion Check promo code

//#region Update course visitors
export async function updateCourseVisitors(transliteratedSlug: string) {
  const response = await axios.post<null>(ENDPOINTS.visit(transliteratedSlug));

  return response.data;
}
//#endregion Update course visitors

//#region Refund course payments
export async function refundCoursePayments(id: number, values: CourseRefundFormValues) {
  const formData = serializeFormData(values);
  const response = await axios.post(ENDPOINTS.refund(id), formData);

  return response.data;
}
//#endregion Refund course payments

//#region Get a package amount for refund
export const getRefundAmount = async function (id: number) {
  const response = await axios.get(ENDPOINTS.refundAmount(id));

  return response.data;
};
//#endregion Get a package amount for refund

//#region Refund via promo code
export async function generatePromoCode(id: number, values: CourseRefundFormValues) {
  const formData = serializeFormData(values);
  const response = await axios.post(ENDPOINTS.refundPromoCode(id), formData);

  return response.data;
}
//#endregion Refund via promo code

//#region Get connected student schedule
export type GetConnectedStudentScheduleDataResponse = {
  timeSlots: TimeSlotEntity[];
};

export const getConnectedStudentScheduleData = createAsyncThunk(
  'course/getConnectedStudentScheduleData',
  async function ({ id, connectedStudentID }: ConnectedStudentSchedulePayload) {
    const response = await axios.get<GetConnectedStudentScheduleDataResponse>(
      ENDPOINTS.connectedStudentSchedule(id, connectedStudentID)
    );
    return response.data;
  }
);
//#endregion Get connected student schedule
