export const STRIPE_ERROR_CODE_MESSAGES: Record<string, string> = {
  incorrect_number: 'Номерът на картата е грешен.',
  invalid_number: 'Номерът на картата е невалиден.',
  invalid_expiry_month: 'Месецът на изтичане на картата е невалиден.',
  invalid_expiry_year: 'Годината на изтичане на картата е невалиден.',
  invalid_cvc: 'Кодът за сигурност е невалиден.',
  expired_card: 'Избраната карта е изтекла.',
  incorrect_cvc: 'Кодът за сигурнсот е грешен',
  incorrect_zip: 'Пощенският код на картата е грешен.',
  card_declined: 'Избраната карта е отказана.',
  processing_error: 'Възникна грешка при опит за покупка с тази карта. Опитайте по-късно.',
  payment_intent_authentication_failure:
    'Не можем да удостоверим Вашия метод на плащане. Изберете различен метод на плащане и опитайте отново.',
};

export const DEFAULT_STRIPE_ERROR_CODE_MESSAGE = STRIPE_ERROR_CODE_MESSAGES.processing_error;

export const STRIPE_DECLINE_CODE_MESSAGE: Record<string, string> = {
  insufficient_funds: 'Картата Ви няма достатъчно налични средства.',
  pickup_card: 'Свържете се с издателя на вашата карта. Бъдещи повторни опити ще бъдат отново неуспешни.',
  lost_card: 'Свържете се с издателя на вашата карта. Бъдещи повторни опити ще бъдат отново неуспешни.',
  stolen_card: 'Свържете се с издателя на вашата карта. Бъдещи повторни опити ще бъдат отново неуспешни.',
};

export const STRIPE_ALREADY_CONFIRMED_PAYMENT_METHOD_CODE = 'payment_intent_unexpected_state';

export const STRIPE_CARD_ERROR_FIELD_NAME = 'stripeCardError';

export enum PaymentMethodBrandID {
  VISA = 1,
  MASTERCARD = 2,
}

export const EMPTY_VALUE = '';

export const MAXIMUM_LENGTH_PROMOCODE = 16;

export const MAXIMUM_LENGTH_PROMOCODE_ERROR_MESSAGE = 'Полето приема само съдържание с размер до 16 символа.';

export const REQUIRED_FIELD_ERROR_MESSAGE = 'Полето е задължително.';

export const NOT_APPLICABLE_PROMOCODE = 'Този промокод не може да бъде приложен за това обучение.';
