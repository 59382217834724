import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Card } from '@/app/components/Card/Card';
import { GoBackLink } from '@/app/components/Page/GoBackLink/GoBackLink';
import { BasePageProps } from '@/app/components/Page/models';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { TabItem } from '@/app/components/Tabs/TabItem';
import { TabNav } from '@/app/components/Tabs/TabNav';

import { StickyHeader } from '../../StickyHeader';

type ProfileCourseShowPageBaseProps = BasePageProps;

export function ProfileCourseShowPageBase({ title, actions, children }: ProfileCourseShowPageBaseProps) {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <StickyHeader className="pt-5" containerClassName="mb-7">
        <GoBackLink to="/profile/courses">Назад към всички обучения</GoBackLink>
        <div className="d-flex justify-content-between align-items-start mb-5">
          <PageTitle className="my-2 fw-bolder fs-2x">{title}</PageTitle>
          <PageActions className="ms-2">{actions}</PageActions>
        </div>

        <TabNav>
          <TabItem to={`/profile/courses/${id}/status`}>Статус на обучението</TabItem>
          <TabItem to={`/profile/courses/${id}/details`}>Детайли за обучението</TabItem>
          <TabItem to={`/profile/courses/${id}/schedule`}>График</TabItem>
          <TabItem to={`/profile/courses/${id}/packages`}>Цени</TabItem>
        </TabNav>
      </StickyHeader>

      <Card>{children}</Card>
    </>
  );
}
