import { ReactNode, useContext, useEffect } from 'react';

import { Page } from '@/app/components/Page/Page';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { getMessengerThreads } from '@/app/modules/messenger/service';
import { identityChannelName } from '@/app/utils/real-time/constants';
import { EchoContext } from '@/app/utils/real-time/EchoContext';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

type MessengerThreadPageBaseProps = {
  children: ReactNode;
};

export function MessengerThreadPageBase({ children }: MessengerThreadPageBaseProps) {
  const dispatch = useAppDispatch();
  const identityID = useAppSelector((state) => state.auth.identityID);
  const messengerState = useAppSelector((state) => state.messenger);
  const [, isInitialDataLoaded] = useLoading(messengerState.threadsLoading);
  const echoInstance = useContext(EchoContext);

  useEffect(
    function handleDataFetching() {
      if (messengerState.threadsLoading === LoadingState.Idle) {
        dispatch(getMessengerThreads());
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!identityID) {
      return;
    }

    const identityChannel = `${identityChannelName}.${identityID}`;

    echoInstance?.private?.(identityChannel).listen('.messenger_thread.updated', () => {
      dispatch(getMessengerThreads());
    });
  }, [identityID]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page className="bg-white p-0">
      <div className="d-flex flex-row h-100">
        {children}

        {!isInitialDataLoaded && <GlobalSpinner />}
      </div>
    </Page>
  );
}
