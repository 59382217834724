import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { handleValidation } from '@/app/components/Form/utils';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { NotificationChannel } from '@/app/modules/profile/notifications/constants';
import {
  ProfileNotificationFormValues,
  ProfileNotificationSetting,
  ProfileNotificationType,
} from '@/app/modules/profile/notifications/models';
import {
  getProfileGeneralNotifications,
  getProfileTutorNotifications,
  updateProfileNotificationSetting,
} from '@/app/modules/profile/notifications/services';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

export type NotificationEditFormProps = {
  notificationTypes: ProfileNotificationType[];
  notificationSettings: ProfileNotificationSetting[];
  onSubmit: typeof getProfileGeneralNotifications | typeof getProfileTutorNotifications;
};

export function NotificationEditForm({ notificationTypes, notificationSettings, onSubmit }: NotificationEditFormProps) {
  const dispatch = useDispatch();
  const loading = useAppSelector((state) => state.profileNotifications.loading);

  function handleNotificationSettingToggle(typeChannelID: number) {
    return async (e: React.ChangeEvent<HTMLInputElement>) => {
      const handleSubmit = handleValidation<ProfileNotificationFormValues>(
        async (values: ProfileNotificationFormValues) => {
          return await updateProfileNotificationSetting(values);
        }
      );
      await handleSubmit({ typeChannelID, isActive: e.target.checked });
      dispatch(onSubmit());
    };
  }

  return (
    <>
      <Form>
        <table className="table table-striped table-hover table-rounded border border-gray-300 table-row-bordered table-row-gray-300 gy-5 gs-8 bg-white vertical-align-middle mb-0">
          <thead>
            <tr>
              <td>Име</td>
              <td className="w-0 text-center">Имейл</td>
              <td className="w-0 text-center">SMS</td>
            </tr>
          </thead>
          <tbody>
            {notificationTypes.map((type, idx) => {
              const mailNotificationSetting = notificationSettings.find(
                (setting) => setting.typeID === type.id && setting.channelID === NotificationChannel.Mail
              );

              const smsNotificationSetting = notificationSettings.find(
                (setting) => setting.typeID === type.id && setting.channelID === NotificationChannel.SMS
              );

              return (
                <tr key={idx}>
                  <td>{type.name}</td>
                  <td>
                    {mailNotificationSetting && (
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={handleNotificationSettingToggle(mailNotificationSetting.typeChannelID)}
                          checked={mailNotificationSetting.isActive}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    {smsNotificationSetting && (
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={handleNotificationSettingToggle(smsNotificationSetting.typeChannelID)}
                          checked={smsNotificationSetting?.isActive}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Form>
      {loading !== LoadingState.Idle && <GlobalSpinner />}
    </>
  );
}
