import { useState } from 'react';
import Sticky from 'react-stickynode';

import { Button } from '@/app/components/Button/Button';
import { useAppSelector } from '@/redux/store';

import { LabelsFilters } from './components/LabelsFilters/LabelsFilters';
import { RedirectToSubjectsPageModal } from './components/RedirectToSubjectsPageModal/RedirectToSubjectsPageModal';
import { KeywordsFilterPageDesktop } from './desktop/KeywordsFilterPageDesktop';
import { NumberOfLessonsFilterPageDesktop } from './desktop/NumberOfLessonsFilterPageDesktop';
import { PriceFilterPageDesktop } from './desktop/PriceFilterPageDesktop';
import { SubjectsIndexPageFilterDesktop } from './desktop/SubjectsIndexPageFilterDesktop';
import { TargetAudiencesFilterPageDesktop } from './desktop/TargetAudiencesFilterPageDesktop';
import { TimeIntervalsFilterDesktop } from './desktop/TimeIntervalsFilterDesktop';

type CourseIndexPageDesktopFiltersProps = {
  clearFilters: () => void;
  handleStickyStateChange: (state: Sticky.Status) => void;
};

export function CourseIndexPageDesktopFilters({
  clearFilters,
  handleStickyStateChange,
}: CourseIndexPageDesktopFiltersProps) {
  const store = useAppSelector((state) => state.course);
  const subjectName = store.index.subjectName;
  const subjects = store.index.subjects;
  const targetAudiences = store.index.allTargetAudiences;
  const subjectKeywords = store.index.allSubjectKeywords;

  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <Sticky
        top="#kt_header"
        bottomBoundary="#kt_content"
        className="position-relative min-w-350px mw-350px"
        onStateChange={handleStickyStateChange}
      >
        <div className="d-flex justify-content-between align-items-center p-5">
          <span className="fs-3 fw-bold">Филтри</span>
          <Button onClick={clearFilters} variant="light-danger" className="py-2 px-3 fs-7">
            Изчисти
          </Button>
        </div>

        <LabelsFilters setIsModalShown={setIsModalShown} />

        <RedirectToSubjectsPageModal
          subjectName={subjectName}
          isModalShown={isModalShown}
          setIsModalShown={setIsModalShown}
        />

        <SubjectsIndexPageFilterDesktop subjects={subjects} />

        <TargetAudiencesFilterPageDesktop targetAudiences={targetAudiences} />

        <KeywordsFilterPageDesktop keywords={subjectKeywords} />

        <PriceFilterPageDesktop />

        <NumberOfLessonsFilterPageDesktop />

        <TimeIntervalsFilterDesktop />
      </Sticky>
    </>
  );
}
