import React, { useEffect, useRef } from 'react';

import { useAppDispatch } from '@/redux/store';

import drag from './store';

type GlobalDraggingProps = {
  children: React.ReactNode;
};

export function GlobalDragging({ children }: GlobalDraggingProps) {
  const dispatch = useAppDispatch();
  const dragCounter = useRef(0);

  useEffect(() => {
    function handleDragEnter(event: DragEvent) {
      dragCounter.current++;

      if (event.dataTransfer?.types.includes('Files') && dragCounter.current === 1) {
        dispatch(drag.actions.setIsGlobalDragging(true));
      }
    }

    function handleDragLeave() {
      dragCounter.current--;

      if (dragCounter.current === 0) {
        dispatch(drag.actions.setIsGlobalDragging(false));
      }
    }

    function handleDrop() {
      dragCounter.current = 0;
      dispatch(drag.actions.setIsGlobalDragging(false));
    }

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDrop);

    return function componentWillUnmount() {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
  }, [dispatch]);

  return <>{children}</>;
}
