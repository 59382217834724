import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { EditButton } from '@/app/components/Button/EditButton';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import {
  PROFILE_INVOICE_DETAILS_SUBTITLE,
  PROFILE_INVOICE_DETAILS_TITLE,
} from '@/app/modules/profile/details/constants';
import { getProfileInvoiceDetails } from '@/app/modules/profile/details/service';
import { TutorDetailsShowPageBase } from '@/app/modules/profile/details/show/TutorDetailsShowPageBase';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function TutorInvoiceDetailsShowPage() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.profileDetails.loading);
  const invoiceDetails = useAppSelector((state) => state.profileDetails.invoiceDetails);

  useEffect(function componentDidMount() {
    if (loading === LoadingState.Idle) {
      dispatch(getProfileInvoiceDetails());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>{PROFILE_INVOICE_DETAILS_TITLE}</title>
      </Helmet>
      <TutorDetailsShowPageBase
        title={PROFILE_INVOICE_DETAILS_TITLE}
        actions={<EditButton to="/profile/invoice-details/edit" />}
      >
        <CardHeader>
          <CardTitle>{PROFILE_INVOICE_DETAILS_TITLE}</CardTitle>
          <CardSubTitle>{PROFILE_INVOICE_DETAILS_SUBTITLE}</CardSubTitle>
        </CardHeader>

        <CardBody>
          <div className="mb-10">
            <p className="mb-0 fw-bold mb-1">Име</p>
            <span>
              {invoiceDetails.firstName} {invoiceDetails.lastName}
            </span>
          </div>
          <div className="mb-10">
            <p className="mb-0 fw-bold mb-1">Телефон</p>
            <span>{invoiceDetails.phone}</span>
          </div>
          <div className="mb-10">
            <p className="mb-0 fw-bold mb-1">Имейл адрес</p>
            <span>{invoiceDetails.email}</span>
          </div>
          <div className="mb-10">
            <p className="mb-0 fw-bold mb-1">Дата на раждане</p>
            <span>{invoiceDetails.birthday}</span>
          </div>
          <div className="mb-10">
            <p className="mb-0 fw-bold mb-1">Адрес</p>
            <span>{invoiceDetails.address}</span>
          </div>
          <div>
            <p className="mb-0 fw-bold mb-1">Град</p>
            <span>
              {invoiceDetails?.city?.text}, {invoiceDetails?.postCode}
            </span>
          </div>
        </CardBody>
        {loading === LoadingState.Pending && <GlobalSpinner />}
      </TutorDetailsShowPageBase>
    </>
  );
}
