import { ReactNode, useCallback, useMemo, useState } from 'react';

import { FooterContext } from './FooterContext';

type FooterProviderProps = {
  children: ReactNode;
};

export function FooterProvider({ children }: FooterProviderProps) {
  const [isVisible, setIsVisible] = useState(true);
  const [isPromotionDescriptionVisible, setIsPromotionDescriptionVisible] = useState(false);
  const show = useCallback(() => setIsVisible(true), []);
  const hide = useCallback(() => setIsVisible(false), []);
  const showPromotionDescription = useCallback(() => setIsPromotionDescriptionVisible(true), []);
  const hidePromotionDescription = useCallback(() => setIsPromotionDescriptionVisible(false), []);
  const contextValue = useMemo(
    () => ({
      isVisible,
      isPromotionDescriptionVisible,
      show,
      hide,
      showPromotionDescription,
      hidePromotionDescription,
    }),
    [isVisible, isPromotionDescriptionVisible, show, hide, showPromotionDescription, hidePromotionDescription]
  );

  return <FooterContext.Provider value={contextValue}>{children}</FooterContext.Provider>;
}
