import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import * as H from 'history';

type SegmentedNavItemProps = {
  to: H.LocationDescriptor | ((location: H.Location) => H.LocationDescriptor);
  children: ReactNode;
};

export function SegmentedNavItem({ to, children }: SegmentedNavItemProps) {
  return (
    <NavLink
      to={to}
      className="btn btn-sm btn-light-primary z-index-1 fw-normal"
      activeClassName="bg-primary text-white"
      exact
    >
      {children}
    </NavLink>
  );
}
