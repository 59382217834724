import { PromoBannerContainer } from '@/app/components/PromoBannerContainer/PromoBannerContainer';
import { useAppSelector } from '@/redux/store';

type AcceleratedCoursesAlertProps = {
  className?: string;
};

export function AcceleratedCoursesAlert({ className }: AcceleratedCoursesAlertProps) {
  const { settings } = useAppSelector((state) => state.platformSettings);
  const { mainPageBanner } = settings;

  if (!mainPageBanner.isActive) {
    return null;
  }

  return (
    <PromoBannerContainer className={className} contentClassName="justify-content-center flex-column">
      <p className="promo-banner-content__text">{mainPageBanner.title}</p>
      <div className="promo-banner-content__buttons">
        {mainPageBanner.link1 && mainPageBanner.buttonText1.length > 0 && (
          <a
            href={mainPageBanner.link1}
            className="btn btn-light-primary promo-banner-content__buttons__button text-nowrap btn-sm btn-active-light-primary w-225px"
          >
            {mainPageBanner.buttonText1}
          </a>
        )}

        {mainPageBanner.link2 && mainPageBanner.buttonText2.length > 0 && (
          <a
            href={mainPageBanner.link2}
            className="btn btn-light-primary promo-banner-content__buttons__button text-nowrap btn-sm btn-active-light-primary w-225px"
          >
            {mainPageBanner.buttonText2}
          </a>
        )}
      </div>
    </PromoBannerContainer>
  );
}
