import { ReactNode, useEffect, useRef } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import * as H from 'history';

import { checkIsActive } from '@/_metronic/helpers/RouterHelpers';
import { useLink } from '@/app/utils/useLink';

type TabItemProps = {
  to: H.LocationDescriptor | ((location: H.Location) => H.LocationDescriptor);
  children: ReactNode;
};

export function TabItem({ to, children }: TabItemProps) {
  const elRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  const link = useLink(to);
  const isActive = checkIsActive(pathname, link);

  useEffect(
    function handleScroll() {
      if (isActive && elRef.current) {
        setTimeout(() => {
          elRef.current?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }, 1000); // a little hack to make sure re-render is done
      }
    },
    [isActive]
  );

  return (
    <Nav.Item ref={elRef}>
      <Nav.Link as={Link} to={to} eventKey={link} className="text-nowrap m-0 px-3">
        {children}
      </Nav.Link>
    </Nav.Item>
  );
}
