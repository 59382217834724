import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import clsx from 'clsx';

import './RichTextButton.scss';

type RichTextButtonProps = {
  tooltip: string;
  isActive: boolean;
  children: React.ReactNode;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
};

export function RichTextButton({
  tooltip,
  isActive,
  children,
  onMouseDown,
  onClick,
  isDisabled = false,
}: RichTextButtonProps) {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={(props: OverlayInjectedProps) => (
        <Tooltip id="button-tooltip" {...props}>
          {tooltip}
        </Tooltip>
      )}
    >
      <button
        className={clsx('btn btn-xs btn-clean btn-icon', { 'btn-clean--active': isActive })}
        disabled={isDisabled}
        onMouseDown={onMouseDown}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    </OverlayTrigger>
  );
}
