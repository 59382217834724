import { useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import * as yup from 'yup';

import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { FileControl } from '@/app/components/Form/FileControl/FileControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { SecurityText } from '@/app/components/SecurityText/SecurityText';
import { StepInstance } from '@/app/components/Stepper/models';
import { LoadingState } from '@/redux/constants';
import { RootState, useAppSelector } from '@/redux/store';

import { IDENTITY_DOCUMENTS_STEP_TITLE } from '../constants';
import { IdentityDocumentsStepModel } from '../models';

const contentSelectorEdit = (state: RootState) => state.tutor.edit.identityDocuments;

export function useIdentityDocumentsInitialValues(content: IdentityDocumentsStepModel | null) {
  const initialValues = useMemo<IdentityDocumentsStepModel>(
    () => ({
      identityDocumentFrontFile: content?.identityDocumentFrontFile ?? null,
      identityDocumentBackFile: content?.identityDocumentBackFile ?? null,
      addressProofFile: content?.addressProofFile ?? null,
    }),
    [content] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { initialValues };
}

export function useIdentityDocumentsValidationSchema() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        identityDocumentFrontFile: yup.mixed().required('Полето е задължително. Изберете файл.'),
        identityDocumentBackFile: yup.mixed().required('Полето е задължително. Изберете файл.'),
        addressProofFile: yup.mixed().required('Полето е задължително. Изберете файл.'),
      }),
    []
  );
  return { validationSchema };
}

export function useIdentityDocumentsCreateStep(stepNumber: number): StepInstance<IdentityDocumentsStepModel> {
  const { initialValues } = useIdentityDocumentsInitialValues(null);

  const { validationSchema } = useIdentityDocumentsValidationSchema();

  const formik = useForm<IdentityDocumentsStepModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });

  return { formik, stepNumber };
}

export function useIdentityDocumentsEditStep(stepNumber: number): StepInstance<IdentityDocumentsStepModel> {
  const content = useAppSelector(contentSelectorEdit);

  const { initialValues } = useIdentityDocumentsInitialValues(content);

  const { validationSchema } = useIdentityDocumentsValidationSchema();

  const formik = useForm<IdentityDocumentsStepModel>({
    initialValues,
    validationSchema,
    initialStatus: LoadingState.Idle,
  });

  return { formik, stepNumber };
}

export function IdentityDocumentsStep() {
  return (
    <>
      <CardHeader>
        <CardTitle>{IDENTITY_DOCUMENTS_STEP_TITLE}</CardTitle>
        <CardSubTitle>За да извършваме преводи към Вас е необходимо да потвърдим Вашата самоличност.</CardSubTitle>
      </CardHeader>
      <CardBody>
        <SecurityText className="mb-7" />

        <Col md={6}>
          <FormGroup controlId="identityDocumentFrontFile">
            <Label isRequired>Лична карта (лице)</Label>
            <FileControl name="identityDocumentFrontFile" />
          </FormGroup>
          <FormGroup controlId="identityDocumentBackFile">
            <Label isRequired>Лична карта (гръб)</Label>
            <FileControl name="identityDocumentBackFile" />
          </FormGroup>
          <FormGroup controlId="addressProofFile">
            <Label isRequired>Доказателство за адрес</Label>
            <FileControl name="addressProofFile" accept=".pdf, .png, .jpg, .jpeg" />
            <Form.Text>Пример: Фактура от банкова сметка, банково извлечение за наем ...</Form.Text>
          </FormGroup>
        </Col>
      </CardBody>
    </>
  );
}
