import { useHistory, useLocation } from 'react-router-dom';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { Content } from '@/app/components/Page/Content/Content';
import { useAppSelector } from '@/redux/store';

import { SupportTicketContactUsModal } from './SupportTicketContactUsModal';

const CONTACT_US_PATH = 'contact-us';

export function SupportTicketContactUs() {
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const contactUsPath = `${location.pathname}/${CONTACT_US_PATH}${location.search}`;
  const shouldOpenContactUsModal = location.pathname.split('/').pop() === CONTACT_US_PATH && isAuthenticated;

  function handleContactUsModalClose() {
    const pathName = (location.pathname + location.search).replace(`/${CONTACT_US_PATH}`, '');
    history.replace(pathName);
  }

  return (
    <Content isContainerContent className="pt-0">
      <div className="d-flex justify-content-center">
        <LinkButton to={contactUsPath} variant="primary">
          Изпрати запитване
        </LinkButton>
      </div>
      <SupportTicketContactUsModal
        isModalShown={shouldOpenContactUsModal}
        handleModalClose={handleContactUsModalClose}
      />
    </Content>
  );
}
