import escapeHtml from 'escape-html';
import { Descendant, Editor, Element as SlateElement, Location, Node, Path, Range, Text, Transforms } from 'slate';

import { CustomElement, CustomElementType, CustomText } from '@/types/slate';

import { LIST_ITEM, LIST_TYPES, PARAGRAPH } from './constants';

export function createParagraph(text: string) {
  return {
    type: 'paragraph',
    children: [{ text: text }],
  };
}

//#region Mark buttons
export function isMarkActive(editor: Editor, format: string) {
  if (!editor?.selection) {
    return false;
  }

  const {
    anchor: { path },
  } = editor.selection;
  const [node] = Editor.node(editor, path);

  if (node?.children) {
    return false;
  }

  const marks = Editor.marks(editor) as Record<string, unknown>;

  if (format in (marks ?? {})) {
    return Boolean(marks[format]);
  }

  return false;
}

export function toggleMark(editor: Editor, format: string) {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}
//#endregion Mark buttons

//#region Block buttons
export function isBlockActive(editor: Editor, format: string) {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
}

export function toggleBlock(editor: Editor, format: string) {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes((!Editor.isEditor(n) && SlateElement.isElement(n) && n.type) as string),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? PARAGRAPH : isList ? LIST_ITEM : (format as CustomElementType),
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format as CustomElementType, children: [] };
    Transforms.wrapNodes(editor, block);
  }
}
//#endregion Block buttons

export function serialize(nodes: Node[]) {
  return nodes.map((n) => Node.string(n)).join('\n');
}

export function serializeToHtml(node: CustomElement[] | CustomElement | CustomText): string {
  // CASE 1: List of nodes at the start of the tree.
  if (!Node.isNode(node)) {
    return node.map((n) => serializeToHtml(n)).join('');
  }

  // CASE 2: Text node.
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);

    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    if (node.newLine) {
      string = `${string}</br>`;
    }

    return string;
  }

  // CASE 3: Slate node.
  const children: string = node?.children?.map?.((n) => serializeToHtml(n))?.join?.('') ?? '';

  switch (node.type) {
    case 'block-quote':
      return `<blockquote>${children}</blockquote>`;
    case 'heading-one':
      return `<h1>${children}</h1>`;
    case 'heading-two':
      return `<h2>${children}</h2>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}" target='_blank' rel='noopener noreferrer'>${children}</a>`;
    case 'align-left':
      return `<p style="text-align: left;">${children}</p>`;
    case 'align-right':
      return `<p style="text-align: right;">${children}</p>`;
    case 'align-center':
      return `<p style="text-align: center;">${children}</p>`;
    case 'align-justify':
      return `<p style="text-align: justify;">${children}</p>`;
    default:
    case 'paragraph':
      return `<p>${children}</p>`;
  }
}

export function getTextLength(editorValue: Node[]) {
  return editorValue.reduce((currentLength, n) => currentLength + Node.string(n).length, 0);
}

export function getMaxLengthErrorMessage(maxLength: number) {
  return `Достигнахте лимита от ${maxLength} символа.`;
}

export function getMaxLengthWarningMessage(maxLength: number) {
  return `Текстът е съкратен поради надвишаване на максималният брой от ${maxLength} символа.`;
}

//#region Links
export type LinkElementNode = { type: 'link'; url: string; children: Descendant[] };

export function isURL(url: string) {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
}

export const atEnd = (editor: Editor) => {
  const text = (Node.get(editor, editor?.selection?.focus?.path as Path) as CustomElement).text as string;
  const textLength = text.length;
  return textLength === editor?.selection?.focus?.offset;
};

export const isSelectionLink = (editor: Editor) =>
  (Node.parent(editor, editor?.selection?.focus?.path as Path) as CustomElement).type === 'link';

export const isSelectionLinkEnd = (editor: Editor) => isSelectionLink(editor) && atEnd(editor);

export const insertLink = (editor: Editor, url: string, text = null) => {
  if (editor.selection) {
    wrapLink(editor, url, text);
  }
};

export const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });

  return Boolean(link);
};

export const getCurrentLink = (editor: Editor) => {
  return Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

export const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

export const wrapLink = (editor: Editor, url: string, text: string | null = null) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const selectedText = Editor.string(editor, editor.selection as Location);

  url = url.length > 0 ? url : selectedText;

  if (!isURL(url)) {
    return;
  }
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElementNode = {
    type: 'link',
    url: url,
    children: text ? [{ text: text as string }] : [{ text: url }],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
  editor.insertText('');
};

export const changeLinkNode = (editor: Editor, link: Node[]) => {
  if (link) {
    const text = link?.[0].children?.[0].text;
    if (text?.length === 0) {
      unwrapLink(editor);
    }
  }
};
//#endregion Links

export function isRichTextEmpty(richTextValue: Descendant[]) {
  if (richTextValue.length === 0) {
    return true;
  }

  if (richTextValue.length > 1) {
    return false;
  }

  if (!Array.isArray(richTextValue[0].children)) {
    return true;
  }

  if (richTextValue[0].children.length === 0) {
    return true;
  }

  if (richTextValue[0].children.length > 1) {
    return false;
  }

  if (LIST_TYPES.includes(richTextValue[0]?.type as string)) {
    const listNodeText = richTextValue[0]?.children?.[0].children?.[0]?.text;
    return listNodeText !== undefined && listNodeText === '';
  }

  if (richTextValue[0].children[0].text === undefined || richTextValue[0].children[0].text === '') {
    return true;
  }

  return false;
}
