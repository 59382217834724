import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EditButton } from '@/app/components/Button/EditButton';
import { CardBody } from '@/app/components/Card/CardBody';
import { GoBackLink } from '@/app/components/Page/GoBackLink/GoBackLink';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { getProfileConnectedStudentShowData } from '@/app/modules/profile/connected-student/service';
import { StickyHeader } from '@/app/modules/profile/StickyHeader';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

export function ConnectedStudentShowPage() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const loadingState = useAppSelector((state) => state.profileConnectedStudent.loading);
  const content = useAppSelector((state) => state.profileConnectedStudent.connectedStudent);

  useEffect(function componentDidMount() {
    if (loadingState === LoadingState.Idle) {
      dispatch(getProfileConnectedStudentShowData(Number(id)));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StickyHeader className="py-5" containerClassName="mb-0">
        <PageTop>
          <GoBackLink to="/profile/connected-students">Назад към всички обучаеми</GoBackLink>
          <PageTitle className="my-2 fw-bolder fs-2x text-center">Обучаем</PageTitle>
          <PageActions>
            <EditButton to={`/profile/connected-students/${id}/edit`} />
          </PageActions>
        </PageTop>
      </StickyHeader>
      <Card>
        <CardBody>
          <Row className="g-8 mt-n8">
            <Col md={6}>
              <p className="mb-0 fw-bold mb-1">Имена на обучаемия</p>
              <p className="mb-0">{content?.name ?? ''}</p>
            </Col>
            <Col md={6}>
              <p className="mb-0 fw-bold mb-1">Клас/възраст на обучаемия</p>
              <p className="mb-0">{content?.connectedStudentLevelText ?? ''}</p>
            </Col>
            <Col md={6}>
              <p className="mb-0 fw-bold mb-1">Телефон</p>
              <p className="mb-0">{content?.phone ?? ''}</p>
            </Col>
            <Col md={6}>
              <p className="mb-0 fw-bold mb-1">Имейл адрес</p>
              <p className="mb-0">{content?.email ?? ''}</p>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {loadingState === LoadingState.Pending && <GlobalSpinner />}
    </>
  );
}
