import { ReactNode, useCallback, useMemo, useState } from 'react';

import { BottomNavigationContext } from './BottomNavigationContext';

type BottomNavigationProviderProps = {
  children: ReactNode;
};

export function BottomNavigationProvider({ children }: BottomNavigationProviderProps) {
  const [isVisible, setIsVisible] = useState(true);

  const show = useCallback(() => setIsVisible(true), []);
  const hide = useCallback(() => setIsVisible(false), []);
  const contextValue = useMemo(() => ({ isVisible, show, hide }), [isVisible, show, hide]);

  return <BottomNavigationContext.Provider value={contextValue}>{children}</BottomNavigationContext.Provider>;
}
