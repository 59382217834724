import { MenuComponent } from '@/_metronic/assets/ts/components';
import { HeaderMenuContentItem } from '@/app/layout/HeaderMenu/HeaderMenuContentItem';
import { HeaderMenuContentItemBody } from '@/app/layout/HeaderMenu/HeaderMenuContentItemBody';
import { HeaderMenuContentItemIcon } from '@/app/layout/HeaderMenu/HeaderMenuContentItemIcon';
import { HeaderMenuContentItemText } from '@/app/layout/HeaderMenu/HeaderMenuContentItemText';
import { HeaderMenuContentItemTime } from '@/app/layout/HeaderMenu/HeaderMenuContentItemTime';

import { SupportTicket } from './models';

type SupportTicketItemProps = {
  supportTicket: SupportTicket;
};

export function SupportTicketNotificationItem({ supportTicket }: SupportTicketItemProps) {
  function handleDropdownClose() {
    MenuComponent.hideDropdowns();
  }

  return (
    <HeaderMenuContentItem
      to={`/support-tickets/${supportTicket.id}`}
      createdAt={supportTicket.createdAt}
      isRead={Boolean(supportTicket.isRead)}
      onClick={handleDropdownClose}
    >
      <HeaderMenuContentItemIcon>
        <i className="fas fa-light fa-message-question fa-3x text-primary"></i>
      </HeaderMenuContentItemIcon>
      <HeaderMenuContentItemBody>
        <HeaderMenuContentItemText>{supportTicket.lastMessage}</HeaderMenuContentItemText>
        <HeaderMenuContentItemTime createdAt={supportTicket.createdAt} />
      </HeaderMenuContentItemBody>
    </HeaderMenuContentItem>
  );
}
