import { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import produce from 'immer';
import { BaseEditor, Descendant, Editor, Element as SlateElement, Node as SlateNode, Path, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';

import { EditLinkForm } from '@/app/components/RichTextControl/EditLinkForm';
import { isURL, LinkElementNode } from '@/app/components/RichTextControl/utils';

export function LinkElement({ attributes, children, element }: RenderElementProps) {
  const editor = useSlate();
  const [show, setShow] = useState(false);
  const target = useRef<HTMLAnchorElement>(null);
  const [lastEditor, setLastEditor] = useState<(BaseEditor & ReactEditor) | null>(null);

  function handleClick() {
    setLastEditor({ ...editor });
    setShow((prevState) => !prevState);
  }

  function handleHide() {
    setShow(false);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    const url = event.currentTarget.url.value;
    const text = event.currentTarget.text.value;

    if (!url || !text || !isURL(url)) {
      handleHide();
      return;
    }

    if (!lastEditor || !lastEditor?.selection) {
      handleHide();
      return;
    }

    const [link] = Editor.nodes(lastEditor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
    const editedChildIndex = lastEditor?.selection?.focus?.path?.[2];
    const linkElementNode = link[0] as LinkElementNode;
    const linkElementPath = link[1] as Path;

    const newLink = produce(linkElementNode, (draft) => {
      draft.url = url;
      draft.children[editedChildIndex].text = text;
    });

    Transforms.removeNodes(lastEditor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });

    Transforms.insertNodes(lastEditor, newLink as unknown as SlateNode[], {
      at: linkElementPath,
    });

    handleHide();
  }

  useEffect(
    () => {
      function handleTypeOutside(event: KeyboardEvent) {
        if (target.current && !target.current.contains(event.target as Node)) {
          if (editor.selection) {
            handleHide();
          }
        }
      }
      document.addEventListener('keydown', handleTypeOutside);
      return () => {
        document.removeEventListener('keydown', handleTypeOutside);
      };
    },
    [target] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const defaultText = useMemo(
    () => {
      if (lastEditor && lastEditor?.selection) {
        return (Editor.node(lastEditor, lastEditor?.selection?.focus?.path) as Descendant[])?.[0]?.text;
      }
      return '';
    },
    [lastEditor?.selection?.focus?.path] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        onClick={(e) => {
          if (e.ctrlKey || e.metaKey) {
            window.open(element.url, '_blank');
          } else {
            handleClick();
          }
        }}
        {...attributes}
        href={element.url}
        target="_blank"
        role="button"
        ref={target}
      >
        {children}
      </a>
      <Overlay show={show} target={target.current} placement="bottom" rootClose onHide={handleHide}>
        <Popover id="edit-link-popover" className="rounded-2" style={{ width: '400px' }}>
          <Popover.Body>
            <EditLinkForm
              handleSubmit={handleSubmit}
              defaultText={defaultText}
              defaultUrl={element.url}
              handleHide={handleHide}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}
