import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { TutorAvatar } from '@/app/components/TutorAvatar/TutorAvatar';
import { CourseRatingStars } from '@/app/modules/course/components/CourseRating/CourseRatingStars';
import { CompactCourseItem } from '@/app/modules/course/index/CourseItem/CompactCourseItem';
import { getDistinctKeywords } from '@/app/modules/course/utils';
import { formatDistanceFromDateTimeString } from '@/app/modules/my-schedule/utils';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';
import { formatInfoCount } from '@/app/utils/formatInfoCount';

import { Course, TutorWithCourse } from '../../models';

import './CourseItem.scss';

type TutorWithCoursesItemProps = {
  tutorWithCourses: TutorWithCourse;
};

type TargetAudienceWithKeywords = {
  name: string;
  keywords: SubjectKeyword[];
};

export function TutorWithCoursesItem({ tutorWithCourses }: TutorWithCoursesItemProps) {
  const [showAllTutorCourses, setShowAllTutorCourses] = useState(false);
  const visibleCourse = tutorWithCourses.courses?.[0] ?? null;
  const initiallyHiddenCourses = tutorWithCourses?.courses?.filter((x, idx) => {
    return idx !== 0;
  });

  function getAllDifferentKeywordsForTargetAudienceName(targetAudienceName: string) {
    const differentKeywords = [] as SubjectKeyword[];
    const firstCourseWithSameTargetAudience = tutorWithCourses.courses.find(
      (x) => x.targetAudienceName === targetAudienceName
    );

    if (!firstCourseWithSameTargetAudience) {
      return differentKeywords;
    }

    const firstCourseWithSameTargetAudienceKeywords = firstCourseWithSameTargetAudience?.keywords;

    for (const course of initiallyHiddenCourses) {
      if (course.targetAudienceName === targetAudienceName) {
        const currentCourseKeywords = getDistinctKeywords(course?.keywords);

        for (const currentCourseKeyword of currentCourseKeywords) {
          if (
            firstCourseWithSameTargetAudienceKeywords?.findIndex((x) => x.id === currentCourseKeyword.id) === -1 &&
            differentKeywords?.findIndex((x) => x.id === currentCourseKeyword.id) === -1
          ) {
            differentKeywords.push(currentCourseKeyword);
          }
        }
      }
    }

    return differentKeywords;
  }

  const uniqueTargetAudiencesWithKeywords = useMemo(
    () => {
      const uniqueTargetAudiences = [] as string[];
      const checkedTargetAudiences = [] as string[];
      const uniqueTargetAudiencesWithKeywords = [] as TargetAudienceWithKeywords[];
      uniqueTargetAudiences.push(visibleCourse?.targetAudienceName);

      for (const course of initiallyHiddenCourses) {
        const currentCourseTargetAudience = course.targetAudienceName;
        if (
          currentCourseTargetAudience === visibleCourse?.targetAudienceName &&
          !checkedTargetAudiences.includes(currentCourseTargetAudience)
        ) {
          checkedTargetAudiences.push(currentCourseTargetAudience);
          const differentKeywords = getAllDifferentKeywordsForTargetAudienceName(currentCourseTargetAudience);

          if (differentKeywords?.length > 0) {
            uniqueTargetAudiencesWithKeywords.push({ name: currentCourseTargetAudience, keywords: differentKeywords });
          }
          continue;
        }

        if (!checkedTargetAudiences.includes(currentCourseTargetAudience)) {
          checkedTargetAudiences.push(currentCourseTargetAudience);
          const differentKeywords = getAllDifferentKeywordsForTargetAudienceName(currentCourseTargetAudience);
          uniqueTargetAudiencesWithKeywords.push({ name: currentCourseTargetAudience, keywords: differentKeywords });
        }
      }

      return uniqueTargetAudiencesWithKeywords;
    },
    [visibleCourse, initiallyHiddenCourses] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function handleShow() {
    setShowAllTutorCourses(true);
  }

  function preventTutorAvatarPropagation(e: React.MouseEvent<HTMLDivElement>) {
    if (tutorWithCourses.tutorVideoFileUrl) {
      e.preventDefault();
    }
  }

  return (
    <div className="card card--course-item tutor-with-courses me-3 mb-3 overflow-hidden">
      <Link
        to={`/tutors/${tutorWithCourses.transliteratedSlug}`}
        className="d-flex flex-sm-row text-dark tutor-with-courses__section pb-5"
      >
        <div className="pb-0 min-w-100px me-5" onClick={preventTutorAvatarPropagation}>
          <TutorAvatar
            profileImagePath={tutorWithCourses.tutorProfileImagePath}
            tutorVideoFileUrl={tutorWithCourses.tutorVideoFileUrl}
            className="rounded ms-5 mt-5"
          />
        </div>
        <div className="d-flex flex-column align-items-start text-break justify-content-center pt-5 px-5 w-100">
          <div className="d-flex align-items-center">
            <h2 className="fw-bolder mb-0">{tutorWithCourses.tutorName}</h2>
            <LinkButton
              to={`/messenger/create?recipientIdentityID=${tutorWithCourses.tutorIdentityID}`}
              variant="link"
              className="ms-5 fw-normal btn-sm d-flex align-items-center"
            >
              <i className="far fa-comment-dots text-primary fs-3" />
              <span className="d-none d-lg-inline-block fs-6">Изпрати съобщение</span>
            </LinkButton>
          </div>

          <div className="d-flex align-items-center flex-wrap mt-1 mt-lg-0 ms-n1">
            <CourseRatingStars currentRating={tutorWithCourses.rating} starsClassName="fs-3 mt-0 me-0" />
            <span className="ms-1 ms-sm-2 text-gray-600">({formatInfoCount(tutorWithCourses.ratingCount)} оценки)</span>
          </div>

          {tutorWithCourses.firstAvailableTimeSlotTime && (
            <div className="mt-1 mb-0">
              Най-ранен свободен час след:{' '}
              <strong> {formatDistanceFromDateTimeString(tutorWithCourses.firstAvailableTimeSlotTime)}</strong>
            </div>
          )}
          {tutorWithCourses.averageResponseTime && (
            <div className="mt-0">
              Време за отговор: <strong>{tutorWithCourses.averageResponseTime}</strong>
            </div>
          )}
        </div>
      </Link>
      <hr className="mx-5 my-0 text-gray-400" />

      <CompactCourseItem course={visibleCourse} />

      {Boolean(!showAllTutorCourses && initiallyHiddenCourses?.length > 0) && (
        <>
          <hr className="mx-5 my-0 text-gray-400" />
          <div className="tutor-with-courses__section">
            <div
              className="px-5 d-flex cursor-pointer d-flex pt-3 pb-1 flex-column-reverse flex-md-row"
              onClick={handleShow}
            >
              <p className="tutor-with-courses__additional-courses text-primary fs-8 me-1 mb-1 fw-bold mt-1">
                <i className="fa-solid fa-chevron-down text-primary me-1 fs-8" />
                Още {initiallyHiddenCourses?.length} {initiallyHiddenCourses?.length === 1 ? 'обучение' : 'обучения'} от
                обучителя:
              </p>
              <div>
                {uniqueTargetAudiencesWithKeywords?.map((targetAudienceWithKeywords, idx) => {
                  return (
                    <span
                      key={idx}
                      className="badge bg-gray-200 text-dark mb-2 me-2 text-break tutor-with-courses__course-keyword text-start"
                    >
                      {targetAudienceWithKeywords.name}
                      {targetAudienceWithKeywords?.keywords?.length > 0 && (
                        <span className="text-primary text-break">
                          {' - '}
                          {targetAudienceWithKeywords?.keywords?.map((x, idx) => (
                            <span key={x.id}>
                              {x.text}
                              {`${idx < targetAudienceWithKeywords?.keywords?.length - 1 ? ', ' : ''}`}
                            </span>
                          ))}
                        </span>
                      )}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}

      {showAllTutorCourses &&
        initiallyHiddenCourses.map((course: Course) => (
          <>
            <hr className="mx-5 my-0 text-gray-400" />
            <CompactCourseItem key={course.id} course={course} />
          </>
        ))}
    </div>
  );
}
