import produce from 'immer';

import { COURSE_PACKAGES_LESSON_AMOUNTS, CoursePackageLessonCount } from '../modules/course/constants';
import { CoursePackageModel, createCoursePackage } from '../modules/course/create/models';

export function getInitialCoursePackages(coursePackages: CoursePackageModel[]): CoursePackageModel[] {
  // get the current course package lesson counts in order to add the required ones
  const initialCoursePackageLessonCounts: CoursePackageLessonCount[] = coursePackages.map((coursePackage) => {
    return Number(coursePackage.lessonCount);
  });

  return produce(coursePackages, (draft) => {
    if (!initialCoursePackageLessonCounts.includes(CoursePackageLessonCount.Four)) {
      draft.push(createCoursePackage({ id: -1, lessonCount: CoursePackageLessonCount.Four }));
    }

    if (!initialCoursePackageLessonCounts.includes(CoursePackageLessonCount.Eight)) {
      draft.push(createCoursePackage({ id: -2, lessonCount: CoursePackageLessonCount.Eight }));
    }

    if (!initialCoursePackageLessonCounts.includes(CoursePackageLessonCount.Sixteen)) {
      draft.push(createCoursePackage({ id: -3, lessonCount: CoursePackageLessonCount.Sixteen }));
    }

    // first order them by lesson count ascending
    draft.sort((a: CoursePackageModel, b: CoursePackageModel) => Number(b.lessonCount) - Number(a.lessonCount));

    // then put the required ones on top
    draft.sort((a: CoursePackageModel) => {
      return COURSE_PACKAGES_LESSON_AMOUNTS.includes(a.lessonCount) ? -1 : 1;
    });

    return draft;
  });
}
