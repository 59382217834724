import { ReactNode } from 'react';
import { Card as BaseCard } from 'react-bootstrap';
import clsx from 'clsx';

import { CardVariant } from './models';

import './Card.scss';

type CardProps = {
  children: ReactNode;
  className?: string;
  variant?: CardVariant;
};

export function Card({ children, className, variant = 'custom' }: CardProps) {
  return <BaseCard className={clsx(className, `card-${variant} card-border`)}>{children}</BaseCard>;
}
