import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createTableData, TableDataModel } from '@/app/components/Table/models';
import { LoadingState } from '@/redux/constants';
import { GenericPayloadAction, sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { DEFAULT_PAGE_VALUE } from './constants';
import { ExpectedIncome, Income, MonthlyIncome, SoldCourse, Withdrawal } from './models';
import {
  getProfileProfitData,
  getProfileProfitExpectedIncomeData,
  getProfileProfitIncomeData,
  getProfileProfitMonthlyIncomeData,
  getProfileProfitSoldCoursesData,
  getProfileProfitWithdrawalsData,
  makePayout,
} from './service';

interface ProfileProfitState {
  loading: LoadingState;
  error?: string | null;
  balance: string | null;
  blockedBalance: string | null;
  canPayout: boolean;
  hasBlockedPayouts: boolean;
  minRequiredBalanceToPayout: string | null;
  hasBlockedIncome: boolean;
  hasBlockedExpectedIncome: boolean;
  tabsLoading: LoadingState;
  withdrawals: TableDataModel<Withdrawal[]>;
  income: TableDataModel<Income[]>;
  expectedIncome: TableDataModel<ExpectedIncome[]>;
  soldCourses: TableDataModel<SoldCourse[]>;
  monthlyIncome: TableDataModel<MonthlyIncome[]>;
  currentWithdrawalsPage: number;
  currentIncomePage: number;
  currentExpectedIncomePage: number;
  currentSoldCoursesPage: number;
  currentMonthlyIncomePage: number;
}

const initialState: ProfileProfitState = {
  loading: LoadingState.Idle,
  error: null,
  balance: null,
  blockedBalance: null,
  canPayout: false,
  hasBlockedPayouts: false,
  minRequiredBalanceToPayout: null,
  hasBlockedIncome: false,
  hasBlockedExpectedIncome: false,
  tabsLoading: LoadingState.Idle,
  withdrawals: createTableData(),
  income: createTableData(),
  expectedIncome: createTableData(),
  soldCourses: createTableData(),
  monthlyIncome: createTableData(),
  currentWithdrawalsPage: DEFAULT_PAGE_VALUE,
  currentIncomePage: DEFAULT_PAGE_VALUE,
  currentExpectedIncomePage: DEFAULT_PAGE_VALUE,
  currentSoldCoursesPage: DEFAULT_PAGE_VALUE,
  currentMonthlyIncomePage: DEFAULT_PAGE_VALUE,
};

const profileProfitSlice = createSlice({
  name: 'profileProfit',
  initialState,
  reducers: {
    setCurrentWithdrawalsPage(state, action: PayloadAction<number>) {
      state.currentWithdrawalsPage = action.payload;
    },
    setCurrentIncomePage(state, action: PayloadAction<number>) {
      state.currentIncomePage = action.payload;
    },
    setCurrentExpectedIncomePage(state, action: PayloadAction<number>) {
      state.currentExpectedIncomePage = action.payload;
    },
    setCurrentSoldCoursesPage(state, action: PayloadAction<number>) {
      state.currentSoldCoursesPage = action.payload;
    },
    setCurrentMonthlyIncomePage(state, action: PayloadAction<number>) {
      state.currentMonthlyIncomePage = action.payload;
    },
  },
  extraReducers: (builder) => {
    //#region GET PROFILE PROFIT
    builder
      .addCase(getProfileProfitData.pending, sharedPendingReducer)
      .addCase(getProfileProfitData.rejected, sharedRejectedReducer)
      .addCase(getProfileProfitData.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.balance = action.payload.balance;
          state.blockedBalance = action.payload.blockedBalance;
          state.canPayout = action.payload.canPayout;
          state.hasBlockedPayouts = action.payload.hasBlockedPayouts;
          state.minRequiredBalanceToPayout = action.payload.minRequiredBalanceToPayout;
          state.hasBlockedIncome = action.payload.hasBlockedIncome;
          state.hasBlockedExpectedIncome = action.payload.hasBlockedExpectedIncome;
        }
      });
    //#endregion GET PROFILE PROFIT

    //#region Make Payout
    builder
      .addCase(makePayout.pending, sharedPendingReducer)
      .addCase(makePayout.rejected, sharedRejectedReducer)
      .addCase(makePayout.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.balance = action.payload.balance;
          state.blockedBalance = action.payload.blockedBalance;
          state.canPayout = action.payload.canPayout;
          state.hasBlockedPayouts = action.payload.hasBlockedPayouts;
          state.minRequiredBalanceToPayout = action.payload.minRequiredBalanceToPayout;
          state.hasBlockedIncome = action.payload.hasBlockedIncome;
          state.hasBlockedExpectedIncome = action.payload.hasBlockedExpectedIncome;
        }
      });
    //#endregion Make Payout

    function sharedTabPendingReducer(state: ProfileProfitState) {
      if (state.tabsLoading === LoadingState.Idle) {
        state.tabsLoading = LoadingState.Pending;
      }
    }

    function sharedTabRejectedReducer(state: ProfileProfitState, action: GenericPayloadAction) {
      if (state.tabsLoading === LoadingState.Pending) {
        state.tabsLoading = LoadingState.Idle;
        state.error = action.error.message;
      }
    }

    //#region GET WITHDRAWALS DATA
    builder
      .addCase(getProfileProfitWithdrawalsData.pending, sharedTabPendingReducer)
      .addCase(getProfileProfitWithdrawalsData.rejected, sharedTabRejectedReducer)
      .addCase(getProfileProfitWithdrawalsData.fulfilled, (state, action) => {
        if (state.tabsLoading === LoadingState.Pending) {
          state.tabsLoading = LoadingState.Idle;
          state.currentWithdrawalsPage = action.payload.withdrawals.currentPage;
          state.withdrawals = action.payload.withdrawals;
        }
      });
    //#endregion GET WITHDRAWALS DATA

    //#region GET INCOME DATA
    builder
      .addCase(getProfileProfitIncomeData.pending, sharedTabPendingReducer)
      .addCase(getProfileProfitIncomeData.rejected, sharedTabRejectedReducer)
      .addCase(getProfileProfitIncomeData.fulfilled, (state, action) => {
        if (state.tabsLoading === LoadingState.Pending) {
          state.tabsLoading = LoadingState.Idle;
          state.currentIncomePage = action.payload.income.currentPage;
          state.income = action.payload.income;
        }
      });
    //#endregion GET INCOME DATA

    //#region GET EXPECTED INCOME DATA
    builder
      .addCase(getProfileProfitExpectedIncomeData.pending, sharedTabPendingReducer)
      .addCase(getProfileProfitExpectedIncomeData.rejected, sharedTabRejectedReducer)
      .addCase(getProfileProfitExpectedIncomeData.fulfilled, (state, action) => {
        if (state.tabsLoading === LoadingState.Pending) {
          state.tabsLoading = LoadingState.Idle;
          state.currentExpectedIncomePage = action.payload.expectedIncome.currentPage;
          state.expectedIncome = action.payload.expectedIncome;
        }
      });
    //#endregion GET EXPECTED INCOME DATA

    //#region GET SOLD COURSES DATA
    builder
      .addCase(getProfileProfitSoldCoursesData.pending, sharedTabPendingReducer)
      .addCase(getProfileProfitSoldCoursesData.rejected, sharedTabRejectedReducer)
      .addCase(getProfileProfitSoldCoursesData.fulfilled, (state, action) => {
        if (state.tabsLoading === LoadingState.Pending) {
          state.tabsLoading = LoadingState.Idle;
          state.currentSoldCoursesPage = action.payload.soldCourses.currentPage;
          state.soldCourses = action.payload.soldCourses;
        }
      });
    //#endregion GET SOLD COURSES DATA

    //#region GET MONTHLY PROFIT DATA
    builder
      .addCase(getProfileProfitMonthlyIncomeData.pending, sharedTabPendingReducer)
      .addCase(getProfileProfitMonthlyIncomeData.rejected, sharedTabRejectedReducer)
      .addCase(getProfileProfitMonthlyIncomeData.fulfilled, (state, action) => {
        if (state.tabsLoading === LoadingState.Pending) {
          state.tabsLoading = LoadingState.Idle;
          state.currentMonthlyIncomePage = action.payload.monthlyIncome.currentPage;
          state.monthlyIncome = action.payload.monthlyIncome;
        }
      });
    //#endregion GET MONTHLY PROFIT DATA
  },
});

export default profileProfitSlice;
