import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { TextareaControl } from '@/app/components/Form/TextareaControl/TextareaControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { getAvailableEnrolledCoursesCount } from '@/app/modules/auth/service';
import { useAppDispatch } from '@/redux/store';

import { ReservationCancelationValues } from '../../reservation/models';
import { cancelReservation } from '../../reservation/service';

const DESCRIPTION_FIELD_PLACEHOLDER = 'Напишете причината за отказ';

type CancelReservationModalProps = {
  isModalShown: boolean;
  reservationID: number;
  onClose: () => void;
};

export function CancelReservationModal({ isModalShown, reservationID, onClose }: CancelReservationModalProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const initialValues = useMemo<ReservationCancelationValues>(
    () => ({
      reasonForCancelation: '',
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        reasonForCancelation: yup.string().required().max(256),
      }),
    []
  );

  const formik = useForm<ReservationCancelationValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const isConfirmed = confirm('Сигурни ли сте, че искате да отмените урока?');
      if (!isConfirmed) {
        onClose();
        return;
      }

      const submitWithValidation = handleBackEndValidation<ReservationCancelationValues>(async (values) => {
        return await cancelReservation(reservationID, values);
      });

      const response = await submitWithValidation(values, formikHelpers);

      if (response) {
        await dispatch(getAvailableEnrolledCoursesCount());
        history.push(`/courses/reservations/${reservationID}`);
      }
    },
  });

  return (
    <Modal show={isModalShown} onHide={onClose} contentClassName="text-center" centered>
      <Modal.Header closeButton className="border-0 flex-wrap position-relative">
        <Modal.Title className="ms-auto flex-grow-1 px-10 fs-2x fw-bold">Отказ от час</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-md-10 pt-md-0">
        <Form
          formik={formik}
          disabled={formik.isSubmitting}
          onSubmit={handleFrontEndValidations(formik)}
          skipDirtyPrompt
        >
          <FormGroup controlId="reasonForCancelation">
            <TextareaControl name="reasonForCancelation" rows={4} placeholder={DESCRIPTION_FIELD_PLACEHOLDER} />
          </FormGroup>

          <div className="d-flex justify-content-center flex-wrap mt-7 font-weight-bold font-size-h6">
            <Button type="submit" variant="light-danger" className="me-5 mb-3 mb-sm-0">
              Откажи часа
            </Button>

            <Button onClick={onClose} variant="primary">
              Затвори
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
