import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import IntervalTree from '@flatten-js/interval-tree';
import { add } from 'date-fns';
import { FormikContextType, FormikHelpers } from 'formik';
import Toastr from 'toastr';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { useForm } from '@/app/components/Form/useForm';
import { DAYS_FOR_TUTOR_APPROVAL } from '@/app/constants';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { MINIMUM_SPECIAL_LESSON_PRICE } from '@/app/modules/course/constants';
import { ScheduleStepModel } from '@/app/modules/course/create/models';
import { RecurrenceType } from '@/app/modules/course/models';
import { useAppSelector } from '@/redux/store';

import { EVENT_OVERLAP_ERROR_MESSAGE, INTERVAL_RECURRENCE_TYPE_IDS, RECURRENCE_TYPE_IDS } from './constants';
import { EventForm } from './EventForm';
import { CalendarEvent, CalendarEventFormValues, RecurrenceTypeID, TimeSlotProposalPriceTypeID } from './models';
import {
  getNearestDate,
  isCalendarEventOverlapAnyIntervalTreeNode,
  transformEventFormValuesToCalendarEvent,
} from './utils';

type AddEventModalConfig = {
  duration: number;
  intervalTree: IntervalTree;
  onSubmit?: (calendarEvent: CalendarEvent) => void | Promise<unknown>;
  scheduleFormik?: FormikContextType<ScheduleStepModel>;
};

type AddEventModalInstance = {
  duration: number;
  isShown: boolean;
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  formik: FormikContextType<CalendarEventFormValues>;
};

export function useAddEventModal({
  duration,
  intervalTree,
  onSubmit,
  scheduleFormik,
}: AddEventModalConfig): AddEventModalInstance {
  const [isShown, setIsShown] = useState(false);
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);

  const startDateTime = useMemo<Date>(() => {
    if (tutorStatusID === TutorStatusID.ProfileDetailsReady) {
      return add(new Date(), { days: DAYS_FOR_TUTOR_APPROVAL });
    }
    return getNearestDate();
  }, [tutorStatusID]);

  const initialValues = useMemo<CalendarEventFormValues>(
    () => ({
      recurrenceTypeID: RecurrenceTypeID.Never,
      startDateTime: startDateTime,
      endRecur: null,
      daysOfWeek: [],
      startHour: startDateTime,
      recurrenceTypes: [],
      count: null,
      interval: null,
      hasProposal: 0,
      proposalPriceType: TimeSlotProposalPriceTypeID.Standard,
    }),
    [startDateTime]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        recurrenceTypeID: yup.number().oneOf(RECURRENCE_TYPE_IDS),
        startDateTime: yup.date().required(),
        startHour: yup.string().allowedHours().required(),
        count: yup
          .number()
          .interval(duration)
          .when('recurrenceTypeID', (recurrenceTypeID: RecurrenceTypeID, schema: yup.NumberSchema) => {
            if (INTERVAL_RECURRENCE_TYPE_IDS.includes(recurrenceTypeID)) {
              return schema.required();
            }

            return schema.nullable().notRequired();
          }),
        interval: yup
          .number()
          .interval(duration)
          .when('recurrenceTypeID', (recurrenceTypeID: RecurrenceTypeID, schema: yup.NumberSchema) => {
            if (INTERVAL_RECURRENCE_TYPE_IDS.includes(recurrenceTypeID)) {
              return schema.required();
            }

            return schema.nullable().notRequired();
          }),
        endRecur: yup.date().when('recurrenceTypeID', (recurrenceTypeID: RecurrenceTypeID, schema: yup.DateSchema) => {
          if (recurrenceTypeID === RecurrenceTypeID.Never || recurrenceTypeID === RecurrenceTypeID.DailyInterval) {
            return schema.nullable().notRequired();
          }

          return schema.required();
        }),
        proposalStudent: yup.object().requiredProposalStudent(),
        proposalCourse: yup.object().requiredProposalCourse(),
        proposalPrice: yup.number().when('proposalPriceType', (proposalPriceType: string, schema: yup.NumberSchema) => {
          if (Number(proposalPriceType) === TimeSlotProposalPriceTypeID.Special) {
            return schema.required().min(MINIMUM_SPECIAL_LESSON_PRICE);
          }

          return schema.nullable().notRequired();
        }),
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleInternalSubmit = useCallback(
    async (values: CalendarEventFormValues, formikHelpers: FormikHelpers<CalendarEventFormValues>) => {
      // STEP 1: Transform to CalendarEvent.
      const event = transformEventFormValuesToCalendarEvent(values, duration);

      // STEP 2: Validate if overlaps any event.
      if (isCalendarEventOverlapAnyIntervalTreeNode(event, intervalTree)) {
        Toastr.error(EVENT_OVERLAP_ERROR_MESSAGE);
        formikHelpers.setSubmitting(false);
        scheduleFormik?.setSubmitting(false);
        return;
      }

      // STEP 3: Finish submitting the form.
      await onSubmit?.(event);
      setIsShown(false);
      formikHelpers.setSubmitting(false);
      scheduleFormik?.setSubmitting(false);
    },
    [duration, intervalTree, onSubmit, scheduleFormik]
  );

  const formik = useForm<CalendarEventFormValues>({
    initialValues,
    validationSchema,
    onSubmit: handleInternalSubmit,
  });

  return { duration, isShown, setIsShown, formik };
}

type AddEventModalProps = {
  instance: AddEventModalInstance;
  recurrenceTypeOptions: RecurrenceType[];
  scheduleFormik?: FormikContextType<ScheduleStepModel>;
  header?: string;
  buttonTitle?: string;
  onExternalClose?: () => void;
  isLoading?: boolean;
};

export function AddEventModal({
  instance: { duration, isShown, setIsShown, formik },
  recurrenceTypeOptions,
  scheduleFormik,
  header,
  buttonTitle,
  onExternalClose,
  isLoading = false,
}: AddEventModalProps) {
  const handleClose = useCallback(() => {
    scheduleFormik?.setSubmitting(false);
    onExternalClose?.();
    setIsShown(false);
  }, [setIsShown, onExternalClose]);
  const loading = formik.isSubmitting || isLoading;

  return (
    <Modal show={isShown} onEscapeKeyDown={handleClose}>
      <Modal.Header closeButton onHide={handleClose}>
        <Modal.Title>{header ? header : 'Добави свободно време'}</Modal.Title>
      </Modal.Header>

      <EventForm formik={formik} duration={duration} recurrenceTypeOptions={recurrenceTypeOptions}>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Отказ
          </Button>
          <Button isLoading={loading} variant="primary" type="submit">
            {buttonTitle ? buttonTitle : 'Добави'}
          </Button>
        </Modal.Footer>
      </EventForm>
    </Modal>
  );
}
