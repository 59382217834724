export type ConsultUsInstance = {
  isVisible: boolean;
  isPageLoaded: boolean;
  bottomStickyVariant: BottomStickyVariant;
  show: () => void;
  showOnLoadedPage: () => void;
  hide: () => void;
  hideOnLoadingPage: () => void;
  setBottomSticky: (value: BottomStickyVariant) => void;
};

export enum BottomStickyVariant {
  BottomCTA,
  Default,
}
