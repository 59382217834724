import clsx from 'clsx';

import { formatInfoCount } from '@/app/utils/formatInfoCount';

import { formatDistanceFromDateTimeString } from '../modules/my-schedule/utils';

type TutorAdditionalInfoProps = {
  numberStudents: number | null;
  numberReservedLessons: number | null;
  reactionTime: string | null;
  uniqueVisitsCount: number | null;
  className?: string;
  firstAvailableTutorTimeSlotTime?: string | null;
};

export function TutorAdditionalInfo({
  numberStudents,
  numberReservedLessons,
  reactionTime,
  uniqueVisitsCount,
  className,
  firstAvailableTutorTimeSlotTime = null,
}: TutorAdditionalInfoProps) {
  return (
    <>
      <div className={clsx(className)}>
        {firstAvailableTutorTimeSlotTime && (
          <p className="mb-0">
            Най-ранен свободен час след:{' '}
            <strong>{formatDistanceFromDateTimeString(firstAvailableTutorTimeSlotTime)}</strong>
          </p>
        )}
        {reactionTime && (
          <p className="mb-0">
            Време за отговор: <strong>{reactionTime}</strong>
          </p>
        )}
        <p className="mb-0">
          Брой обучаеми: <strong>{formatInfoCount(numberStudents)}</strong>
        </p>
        <p className="mb-0">
          Резервирани обучения: <strong>{numberReservedLessons ?? 0}</strong>
        </p>
        <p className="mb-0">
          Брой преглеждания: <strong>{uniqueVisitsCount ?? 0}</strong>
        </p>
      </div>
    </>
  );
}
