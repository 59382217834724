import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { createSelect2Option, Select2Option } from '@/app/components/SelectControl/models';
import { StudentProposalStatusID } from '@/app/modules/course/models';
import {
  MessengerMessage,
  MessengerNotificationItemValues,
  MessengerThread,
  MessengerThreadVideoConference,
} from '@/app/modules/messenger/models';
import {
  getMessengerThread,
  getMessengerThreadLastMessages,
  getMessengerThreadLastMessagesReadAt,
  getMessengerThreadPreviousMessages,
  getMessengerThreads,
  getMessengerThreadsPaginated,
  getMessengerThreadVideoConference,
  getRecipient,
  sendVideoConferenceMessage,
  storeMessengerThreadAttachments,
  storeMessengerThreadReply,
} from '@/app/modules/messenger/service';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

export interface MessengerState {
  loading: LoadingState;
  messagesLoading: LoadingState;
  previousMessagesLoading: LoadingState;
  threadsLoading: LoadingState;
  threadShowLoading: LoadingState;
  conferenceLoading: LoadingState;
  error?: string | null;
  recipient: Select2Option;
  messengerThread: MessengerThread | null;
  totalMessages: number | undefined;
  messagesStartIndex: number | undefined;
  messengerMessages: MessengerMessage[];
  messengerThreads: MessengerThread[];
  notifications: {
    loading: LoadingState;
    threads: MessengerNotificationItemValues[];
    unreadThreadCount: number;
    hasMore: boolean;
    currentPage: number;
  };
  videoConference: MessengerThreadVideoConference;
  hasMore: boolean;
  lastMessageID: number | null;
}

const initialState: MessengerState = {
  loading: LoadingState.Idle,
  messagesLoading: LoadingState.Idle,
  previousMessagesLoading: LoadingState.Idle,
  threadsLoading: LoadingState.Idle,
  threadShowLoading: LoadingState.Idle,
  conferenceLoading: LoadingState.Idle,
  error: null,
  recipient: createSelect2Option(null, ''),
  messengerThread: null,
  totalMessages: undefined,
  messagesStartIndex: undefined,
  messengerMessages: [],
  messengerThreads: [],
  notifications: {
    loading: LoadingState.Idle,
    threads: [],
    unreadThreadCount: 0,
    hasMore: true,
    currentPage: 1,
  },
  videoConference: {
    id: null,
    jitsiDomain: '',
    virtualRoomUUID: '',
    jwt: '',
    messengerThreadID: null,
    recipient: null,
  },
  hasMore: true,
  lastMessageID: null,
};

const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    resetRecipient(state) {
      state.recipient = initialState.recipient;
    },
    resetMessages(state) {
      state.lastMessageID = null;
      state.hasMore = true;
      state.messengerMessages = [];
      state.totalMessages = undefined;
      state.messagesStartIndex = undefined;
    },
    loadMore(state) {
      if (state.notifications.loading === LoadingState.Idle && state.notifications.hasMore) {
        state.notifications.currentPage++;
      }
    },
    setLastMessageID(state) {
      if (state.messagesLoading === LoadingState.Idle && state.hasMore) {
        state.lastMessageID = state?.messengerMessages?.[0]?.id ?? null;
      }
    },
    updateMessageStudentProposalStatus(state, action: PayloadAction<number>) {
      state.messengerMessages = produce(state.messengerMessages, (draft) => {
        const idx = draft.findIndex((x) => x.id === action.payload);

        if (idx !== -1) {
          draft[idx].studentProposalStatusID = StudentProposalStatusID.SentToStudent;
        }
      });
    },
  },
  extraReducers: (builder) => {
    //#region GET MESSENGER RECIPIENT
    builder
      .addCase(getRecipient.pending, sharedPendingReducer)
      .addCase(getRecipient.rejected, sharedRejectedReducer)
      .addCase(getRecipient.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.recipient = createSelect2Option(Number(action.payload.recipient.id), action.payload.recipient.text);
        }
      });
    //#endregion GET MESSENGER RECIPIENT

    //#region GET MESSENGER THREAD INDEX
    builder
      .addCase(getMessengerThreads.pending, (state) => {
        if (state.threadsLoading === LoadingState.Idle) {
          state.threadsLoading = LoadingState.Pending;
        }
      })
      .addCase(getMessengerThreads.rejected, (state, action) => {
        if (state.threadsLoading === LoadingState.Pending) {
          state.threadsLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getMessengerThreads.fulfilled, (state, action) => {
        if (state.threadsLoading === LoadingState.Pending) {
          state.threadsLoading = LoadingState.Idle;
          state.messengerThreads = action.payload.threads;
        }
      });
    //#endregion GET MESSENGER THREAD INDEX

    //#region GET MESSENGER THREAD SHOW
    builder
      .addCase(getMessengerThread.pending, (state) => {
        if (state.threadShowLoading === LoadingState.Idle) {
          state.threadShowLoading = LoadingState.Pending;
        }
      })
      .addCase(getMessengerThread.rejected, (state, action) => {
        if (state.threadShowLoading === LoadingState.Pending) {
          state.threadShowLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getMessengerThread.fulfilled, (state, action) => {
        if (state.threadShowLoading === LoadingState.Pending) {
          state.threadShowLoading = LoadingState.Idle;
          state.messengerThread = action.payload.thread;
          state.messengerThreads = action.payload.threads;
          state.hasMore = action.payload.hasMore;
          state.messengerMessages = action.payload.messages;
          state.totalMessages = action.payload.totalMessages ?? state.totalMessages;
          state.messagesStartIndex =
            action.payload.messagesStartIndex ?? Number(state.messagesStartIndex) - action.payload.messages.length;
        }
      });
    //#endregion GET MESSENGER THREAD SHOW

    //#region GET MESSENGER THREAD PREVIOUS MESSAGES
    builder
      .addCase(getMessengerThreadPreviousMessages.pending, (state) => {
        if (state.previousMessagesLoading === LoadingState.Idle) {
          state.previousMessagesLoading = LoadingState.Pending;
        }
      })
      .addCase(getMessengerThreadPreviousMessages.rejected, (state, action) => {
        if (state.previousMessagesLoading === LoadingState.Pending) {
          state.previousMessagesLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getMessengerThreadPreviousMessages.fulfilled, (state, action) => {
        if (state.previousMessagesLoading === LoadingState.Pending) {
          state.previousMessagesLoading = LoadingState.Idle;
          state.hasMore = action.payload.hasMore;
          state.messengerMessages = [...action.payload.messages, ...state.messengerMessages];
          state.totalMessages = state.totalMessages && state.totalMessages + action.payload.totalMessages;
          state.messagesStartIndex = Number(state.messagesStartIndex) - action.payload.messages.length;
        }
      });
    //#endregion GET MESSENGER PREVIOUS MESSAGES

    //#region GET MESSENGER THREAD LAST 20 MESSAGES
    builder
      .addCase(getMessengerThreadLastMessages.pending, (state) => {
        if (state.messagesLoading === LoadingState.Idle) {
          state.messagesLoading = LoadingState.Pending;
        }
      })
      .addCase(getMessengerThreadLastMessages.rejected, (state, action) => {
        if (state.messagesLoading === LoadingState.Pending) {
          state.messagesLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getMessengerThreadLastMessages.fulfilled, (state, action) => {
        if (state.messagesLoading === LoadingState.Pending) {
          state.messagesLoading = LoadingState.Idle;

          for (const message of action.payload.messages) {
            const idx = state.messengerMessages.findIndex((x) => x.id === message.id);

            if (idx === -1) {
              state.messengerMessages = [...state.messengerMessages, message];
            }
          }
        }
      });
    //#endregion GET MESSENGER THREAD LAST 20 MESSAGES

    //#region GET MESSENGER THREAD LAST 20 MESSAGES READ AT
    builder.addCase(getMessengerThreadLastMessagesReadAt.fulfilled, (state, action) => {
      for (const message of action.payload.messages) {
        state.messengerMessages = state.messengerMessages.map((msg) =>
          msg.id === message.id && msg.readAt !== message.readAt ? { ...msg, readAt: message.readAt } : msg
        );
      }
    });
    //#endregion GET MESSENGER THREAD LAST 20 MESSAGES READ AT

    //#region Store message reply
    builder.addCase(storeMessengerThreadReply.fulfilled, (state, action) => {
      const idx = state.messengerMessages.findIndex((x) => x.id === action.payload.message.id);

      if (idx === -1) {
        state.messengerMessages = [...state.messengerMessages, action.payload.message];
      }
    });
    //#endregion Store message reply

    //#region Store message attachments
    builder
      .addCase(storeMessengerThreadAttachments.pending, (state) => {
        if (state.messagesLoading === LoadingState.Idle) {
          state.messagesLoading = LoadingState.Pending;
        }
      })
      .addCase(storeMessengerThreadAttachments.rejected, (state, action) => {
        if (state.messagesLoading === LoadingState.Pending) {
          state.messagesLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(storeMessengerThreadAttachments.fulfilled, (state, action) => {
        if (state.messagesLoading === LoadingState.Pending) {
          state.messagesLoading = LoadingState.Idle;
          state.messengerMessages = [...state.messengerMessages, ...action.payload.messages];
        }
      });
    //#endregion Store message attachments

    //#region GET MESSENGER NOTIFICATIONS INDEX
    builder
      .addCase(getMessengerThreadsPaginated.pending, function (state) {
        if (state.notifications.loading === LoadingState.Idle) {
          state.notifications.loading = LoadingState.Pending;
        }
      })
      .addCase(getMessengerThreadsPaginated.rejected, function (state, action) {
        if (state.notifications.loading === LoadingState.Pending) {
          state.notifications.loading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getMessengerThreadsPaginated.fulfilled, (state, action) => {
        if (state.notifications.loading === LoadingState.Pending) {
          state.notifications.loading = LoadingState.Idle;
          state.notifications.unreadThreadCount = action.payload.unreadThreadCount;
          state.notifications.hasMore = action.payload.hasMore;
          state.notifications.currentPage = action.payload.currentPage;

          if (action.payload.currentPage === 1) {
            state.notifications.threads = action.payload.threads;
          } else {
            for (const thread of action.payload.threads) {
              const idx = state.notifications.threads.findIndex((x) => x.threadID === thread.threadID);

              if (idx === -1) {
                state.notifications.threads.push(thread);
              }

              if (idx > -1) {
                state.notifications.threads[idx] = thread;
              }
            }
          }
        }
      });
    //#endregion GET MESSENGER NOTIFICATIONS INDEX

    //#region SEND VIDEO CONFERENCE MESSAGE
    builder.addCase(sendVideoConferenceMessage.fulfilled, (state, action) => {
      const idx = state.messengerMessages.findIndex((x) => x.id === action.payload.message.id);

      if (idx === -1) {
        state.messengerMessages = [...state.messengerMessages, action.payload.message];
      }
    });
    //#region SEND VIDEO CONFERENCE MESSAGE

    //#region GET MESSENGER THREAD VIDEO CONFERENCE DATA
    builder
      .addCase(getMessengerThreadVideoConference.pending, (state) => {
        if (state.conferenceLoading === LoadingState.Idle) {
          state.conferenceLoading = LoadingState.Pending;
        }
      })
      .addCase(getMessengerThreadVideoConference.rejected, (state, action) => {
        if (state.conferenceLoading === LoadingState.Pending) {
          state.conferenceLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getMessengerThreadVideoConference.fulfilled, (state, action) => {
        state.conferenceLoading = LoadingState.Idle;
        state.videoConference.id = action.payload.id;
        state.videoConference.jitsiDomain = action.payload.jitsiDomain;
        state.videoConference.virtualRoomUUID = action.payload.virtualRoomUUID;
        state.videoConference.jwt = action.payload.jwt;
        state.videoConference.messengerThreadID = action.payload.messengerThreadID;
        state.videoConference.recipient = action.payload.recipient;
      });
    //#endregion GET MESSENGER THREAD VIDEO CONFERENCE DATA
  },
});

export default messengerSlice;
