import { ReactNode } from 'react';
import clsx from 'clsx';

type HeaderMenuContentItemTextProps = {
  children: ReactNode;
  className?: string;
};

export function HeaderMenuContentItemText({ children, className }: HeaderMenuContentItemTextProps) {
  return <p className={clsx('m-0', className)}>{children}</p>;
}
