import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TutorStatusID } from '@/app/models/TutorStatusID';
import { AgreementFormValues } from '@/app/modules/auth/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

//#region AUTH

//#region Get auth data
export const GET_AUTH_DATA_ENDPOINT = '/ajax/auth';

export const getAuthData = createAsyncThunk('auth/getAuthData', async () => {
  const response = await axios.get<GetAuthDataResponse>(GET_AUTH_DATA_ENDPOINT);
  return response.data;
});

export interface GetAuthDataResponse {
  identityID: number;
  firstName: string;
  twoNames: string;
  email: string;
  profilePicturePath: string | null;
  tutorStatusID: TutorStatusID;
  balanceAmount: string;
  studentID: number | null;
  tutorID: number | null;
  isDiaryUser: boolean;
  apps: {
    mainWebAppUrl: string;
    vsWebAppUrl: string;
    portfolioWebAppUrl: string;
    plWebAppUrl: string;
  };
  shouldLeaveCourseFeedback: boolean;
  privacyAcceptedAt: string | null;
  termsAcceptedAt: string | null;
  overFourteenYearsOldAgreedAt: string | null;
}
//#endregion Get auth data

//#region Keep alive
export const KEEP_ALIVE_ENDPOINT = '/ajax/auth/keepalive';

export function keepAlive() {
  return axios.get<string>(KEEP_ALIVE_ENDPOINT);
}
//#endregion Keep alive

//region GET UPDATED IDENTITY AVATAR
export const GET_UPDATED_IDENTITY_AVATAR_ENDPOINT = '/ajax/auth/avatar';

export interface GetUpdatedIdentityAvatarResponse {
  identityAvatar: string;
}

export const getUpdatedIdentityAvatar = createAsyncThunk('auth/getUpdatedIdentityAvatar', async function () {
  const response = await axios.get<GetUpdatedIdentityAvatarResponse>(GET_UPDATED_IDENTITY_AVATAR_ENDPOINT);
  return response.data;
});
//endregion GET UPDATED IDENTITY AVATAR

//#region GET EXTERNAL ID
export const GET_EXTERNAL_ID_ENDPOINT = '/ajax/auth/external-id';

export interface GetExternalIDResponse {
  externalID: string;
}

export const getExternalID = createAsyncThunk('auth/getExternalID', async function () {
  const response = await axios.get<GetExternalIDResponse>(GET_EXTERNAL_ID_ENDPOINT);
  return response.data;
});
//#endregion GET EXTERNAL ID

//#endregion AUTH

//#region BECOME TUTOR

export const TUTOR_STATUS_ENDPOINT = '/ajax/tutor/status';
export const BECOME_TUTOR_ENDPOINT = '/ajax/tutor/become';
export interface SharedBecomeTutorResponse {
  tutorStatusID: TutorStatusID;
  tutorID: number | null;
}

//#region Get become tutor data
export const getTutorStatus = createAsyncThunk('tutor/getTutorStatus', async () => {
  const response = await axios.get<SharedBecomeTutorResponse>(TUTOR_STATUS_ENDPOINT);
  return response.data;
});
//#endregion Get become tutor data

//#region Get has schedule tutor data

export const TUTOR_HAS_SCHEDULE_ENDPOINT = '/ajax/tutor/has-schedule';

export interface TutorHasScheduleResponse {
  tutorHasSchedule: boolean;
}

export const getTutorHasSchedule = createAsyncThunk('tutor/getTutorHasSchedule', async () => {
  const response = await axios.get<TutorHasScheduleResponse>(TUTOR_HAS_SCHEDULE_ENDPOINT);
  return response.data;
});
//#endregion Get has schedule tutor data

//#region Send tutor for approval
export const sendTutorForApproval = createAsyncThunk('tutor/sendTutorForApproval', async () => {
  const response = await axios.put<SharedBecomeTutorResponse>(BECOME_TUTOR_ENDPOINT);
  return response.data;
});
//#endregion Send tutor for approval

//#endregion BECOME TUTOR

//#region STORE STUDENT
export const STUDENT_STORE_ENDPOINT = '/ajax/students';

type CreateStudentResponse = {
  studentID: number;
};

export const storeStudent = createAsyncThunk('student/storeStudent', async () => {
  const response = await axios.post<CreateStudentResponse>(STUDENT_STORE_ENDPOINT);
  return response.data;
});
//#endregion STORE STUDENT

//#region UPDATE IDENTITY TERM AGREEMENT
export const UPDATE_IDENTITY_TERM_AGREEMENT_ENDPOINT = '/ajax/terms-agreement';

export function updateIdentityTermAgreement(values: AgreementFormValues) {
  const formData = serializeFormData(values);
  return axios.put(UPDATE_IDENTITY_TERM_AGREEMENT_ENDPOINT, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
//#endregion UPDATE IDENTITY TERM AGREEMENT

export const FEEDBACK_SURVEY_ENDPOINT = '/ajax/feedback-survey';

//#region GET FEEDBACK SURVEY DATA
export interface GetFeedbackSurveyDataResponse {
  canSeeFeedbackSurvey: boolean;
}

export const getFeedbackSurveyData = createAsyncThunk('auth/getFeedbackSurveyData', async function () {
  const response = await axios.get<GetFeedbackSurveyDataResponse>(FEEDBACK_SURVEY_ENDPOINT);
  return response.data;
});
//#endregion GET FEEDBACK SURVEY DATA

//#region STORE FEEDBACK SURVEY
export interface StoreFeedbackSurveyVisitResponse extends Record<string, unknown> {
  feedbackSurveyVisitID: number;
}

export const storeFeedbackSurveyVisit = createAsyncThunk('auth/storeFeedbackSurveyVisit', async () => {
  const response = await axios.post<StoreFeedbackSurveyVisitResponse>(FEEDBACK_SURVEY_ENDPOINT);
  return response.data;
});
//#endregion STORE FEEDBACK SURVEY

//#region UPDATE FEEDBACK SURVEY
export async function updateFeedbackSurveyVisit() {
  const response = await axios.put(FEEDBACK_SURVEY_ENDPOINT);
  return response.data;
}
//#endregion UPDATE FEEDBACK SURVEY

//#region Get available enrolled courses count
export const GET_AVAILABLE_ENROLLED_COURSES_COUNT = '/ajax/available-enrolled-courses-count';

export interface AvailableEnrolledCoursesCountResponse {
  availableEnrolledCoursesCount: number;
}

export const getAvailableEnrolledCoursesCount = createAsyncThunk('auth/getAvailableEnrolledCoursesCount', async () => {
  const response = await axios.get<AvailableEnrolledCoursesCountResponse>(GET_AVAILABLE_ENROLLED_COURSES_COUNT);
  return response.data;
});
//#endregion  Get available enrolled courses count
