import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

import { KTSVG } from '@/_metronic/helpers';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { useAppSelector } from '@/redux/store';

import './TutorWaitingApprovalPage.scss';

const REDIRECT_TO_BECOME_PAGE = '/tutor/become';
const REDIRECT_TO_PROFILE = '/profile';

export function TutorWaitingApprovalPage() {
  const tutorStatusID = useAppSelector((state) => state.auth.tutorStatusID);
  const history = useHistory();

  useEffect(() => {
    if (tutorStatusID !== TutorStatusID.WaitingApproval) {
      history.push(REDIRECT_TO_BECOME_PAGE);
    }
  }, [history, tutorStatusID]);

  return (
    <>
      <Helmet>
        <title>Вашият профил е изпратен за одобрение</title>
      </Helmet>
      <Page>
        <Content>
          <div className="mx-auto text-center tutor-waiting-approval-page-container">
            <div className="my-10">
              <KTSVG path="/img/svg/HourglassSplit.svg" className="svg-icon-5x" />
            </div>

            <h1 className="text-gray-800 fs-2tx">Вашият профил е изпратен за одобрение.</h1>

            <p className="text-gray-700 fs-4 mb-10">
              Екипът ни ще разгледа регистрацията Ви и ще се свърже с Вас до 5 работни дни.
            </p>

            <Link to={REDIRECT_TO_PROFILE} className="btn btn-light-primary">
              Моят профил
            </Link>
          </div>
        </Content>
      </Page>
    </>
  );
}
