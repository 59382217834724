import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';

import { TestRoomShowPage } from './TestRoomShowPage';

export function TestRoomPage() {
  return (
    <Switch>
      <Route path="/test-room" exact>
        <TestRoomShowPage />
      </Route>

      <Route component={NoMatch} />
    </Switch>
  );
}
