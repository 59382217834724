import { ReactNode } from 'react';
import { RouteProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { isMenuActive } from '@/app/utils/isMenuActive';

import './BottomNavigationItem.scss';

type BottomNavigationItemProps = {
  to: string;
  children: ReactNode;
  className?: string;
  additionalActive?: string | string[] | RouteProps | RouteProps[];
  exact?: boolean;
};

export function BottomNavigationItem({
  to,
  children,
  className,
  additionalActive,
  exact = false,
}: BottomNavigationItemProps) {
  const isActive = isMenuActive(additionalActive);

  return (
    <NavLink
      to={to}
      className={clsx('bottom-navigation-item', className)}
      activeClassName="bottom-navigation-item--active"
      isActive={isActive}
      exact={exact}
    >
      {children}
    </NavLink>
  );
}
