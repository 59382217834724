import { forwardRef, HTMLProps, MouseEvent } from 'react';
import clsx from 'clsx';

import './CustomDateInput.scss';

type CustomDateInputProps = HTMLProps<HTMLInputElement> & {
  isDisabled?: boolean;
  hasSolidBackground?: boolean;
};

export const CustomDateInput = forwardRef<HTMLInputElement, CustomDateInputProps>(function CustomDateInput(
  { isDisabled, hasSolidBackground = true, ...props }: CustomDateInputProps,
  ref
) {
  function handleIconClick(event: MouseEvent) {
    props.onClick?.(event as MouseEvent<HTMLInputElement>);
  }

  return (
    <div className="custom-date-input">
      <input
        {...props}
        disabled={isDisabled}
        type="text"
        className={clsx('form-control form-control-lg pe-13', {
          'bg-lighten': isDisabled,
          'form-control-solid': hasSolidBackground,
        })}
        ref={ref}
      />
      <i
        className={clsx('far fa-calendar custom-date-input__icon', {
          'custom-date-input__icon--disabled': isDisabled,
        })}
        onClick={handleIconClick}
      />
    </div>
  );
});
