import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { StudentLessonFeedbackCreatePage } from '@/app/modules/profile/lesson-feedback/StudentLessonFeedbackCreatePage';
import { ProfileTimelineIndexPage } from '@/app/modules/profile/timeline/ProfileTimelineIndexPage';
import { ProfileTimelineShowPage } from '@/app/modules/profile/timeline/ProfileTimelineShowPage';

export function ProfileTimelinePage() {
  return (
    <Switch>
      <Route path="/profile/timelines" exact>
        <ProfileTimelineIndexPage />
      </Route>
      <Route path="/profile/timelines/lesson-feedback" exact>
        <StudentLessonFeedbackCreatePage />
      </Route>
      <Route path="/profile/timelines/:id">
        <ProfileTimelineShowPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
