import { Descendant } from 'slate';

import { CustomElementType } from '@/types/slate';

export const HOTKEYS: Record<string, string> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

export const EMPTY_VALUE: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const ENTER_LIST = {
  [LIST_TYPES[0]]: true,
  [LIST_TYPES[1]]: true,
};

export const ENTER_BLOCK: Record<string, boolean> = {
  block_quote: true,
  ...ENTER_LIST,
};

export const PARAGRAPH: CustomElementType = 'paragraph';
export const LIST_ITEM: CustomElementType = 'list-item';

export const DEFAULT_INPUT_LENGTH = 500;

export const PROTOCOL_REGEX = /^((http|https|ftp):\/\/)/;
export const MAIL_TO_PREFIX = 'mailto:';

export const DEFAULT_URL_PROTOCOL = 'http://';
