import { useEffect, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { ColumnDescription, ExpandRowProps } from 'react-bootstrap-table-next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { CardToolbar } from '@/app/components/Card/CardToolbar';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { Table } from '@/app/components/Table/Table';
import { formatPrice } from '@/app/utils/money';
import { useQueryParams } from '@/app/utils/query';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import {
  DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
  DEFAULT_PAGE_VALUE,
  ITEMS_PER_PAGE_OPTIONS,
  ProfileProfitFilterInitialValues,
} from './constants';
import {
  ExpectedIncomeColumnRenderer,
  ExpectedIncomeExpandedRow,
  ExpectedIncomeHeaderRenderer,
} from './ExpectedIncomeExpandedRow';
import { ExpectedIncome, ExpectedIncomeFilterQueryParams, ExpectedIncomeFilterValues } from './models';
import { ProfileProfitBasePage } from './ProfileProfitBasePage';
import { getProfileProfitExpectedIncomeData } from './service';
import profileProfitSlice from './store';
import { transformProfileProfitFormikValuesToSearchParams } from './utils';

const columns: ColumnDescription[] = [
  {
    dataField: 'buyerName',
    text: 'Потребител',
  },
  {
    dataField: 'courseName',
    text: 'Обучение',
  },
  {
    dataField: 'lessonsLeft',
    text: 'Оставащи обучения',
  },
  {
    dataField: 'expectedIncome',
    text: 'Очакван приход',
    formatter: formatPrice,
  },
];

const actions = (_: unknown, row: ExpectedIncome) => {
  return (
    <LinkButton
      to={`/messenger/create?recipientIdentityID=${row.studentIdentityID}`}
      variant="light-primary"
      className="fw-normal btn-sm"
    >
      <i className="far fa-comment-dots" />
      <span className="d-none d-lg-inline-block">Изпрати съобщение</span>
    </LinkButton>
  );
};

export function ProfileProfitExpectedIncomePage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const content = useAppSelector((state) => state.profileProfit);
  const [loadingState, isInitialDataLoaded, setLoadingState] = useLoading(content.tabsLoading);
  const isLoading = loadingState === LoadingState.Pending;
  const { currentSearchParams, queryParams } = useQueryParams<ExpectedIncomeFilterQueryParams>();

  useEffect(
    function componentDidMount() {
      dispatch(getProfileProfitExpectedIncomeData(queryParams));
    },
    [queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#region Handle pagination form
  const initialValues = useMemo<ExpectedIncomeFilterValues>(
    () => ({
      page: queryParams.page ?? DEFAULT_PAGE_VALUE,
      itemsPerPage: queryParams?.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
    }),
    [queryParams]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<ExpectedIncomeFilterValues>({
    initialValues,
    validationSchema,
    initialStatus: loadingState,
  });
  //#endregion Handle pagination form

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformProfileProfitFormikValuesToSearchParams(
        formik.values,
        formik.initialValues.itemsPerPage,
        queryParams
      );

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
        setLoadingState(LoadingState.Pending);
      }

      if (newSearchParams === '') {
        formik.setValues(ProfileProfitFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  //#region Handle page change
  useEffect(
    function handlePageChange() {
      formik.setFieldValue('page', content.currentExpectedIncomePage);
    },
    [content.currentExpectedIncomePage] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle page change

  const expandRow: ExpandRowProps<ExpectedIncome> = {
    renderer: ExpectedIncomeExpandedRow,
    showExpandColumn: true,
    expandHeaderColumnRenderer: ExpectedIncomeHeaderRenderer,
    expandColumnRenderer: ExpectedIncomeColumnRenderer,
  };

  return (
    <ProfileProfitBasePage title="Приходи">
      <CardHeader isRow>
        <div>
          <CardTitle>Очаквани приходи</CardTitle>
          <CardSubTitle>Закупени Ваши обучения, които все още не са проведени.</CardSubTitle>
        </div>
        <CardToolbar>
          <Form formik={formik} skipDirtyPrompt>
            <FormGroup>
              <Label htmlFor="itemsPerPage" className="mb-0 me-3">
                Покажи по:
              </Label>
              <SelectControl
                name="itemsPerPage"
                options={ITEMS_PER_PAGE_OPTIONS}
                isSearchable={false}
                hasSolidBackground={false}
                className="w-175px"
              />
            </FormGroup>
          </Form>
        </CardToolbar>
      </CardHeader>
      <CardBody>
        {!isInitialDataLoaded && <Spinner animation="border" variant="primary" className="d-block mx-auto" />}
        {isInitialDataLoaded && (
          <Table
            columns={columns}
            data={content.expectedIncome}
            isLoading={isLoading}
            setCurrentPageAction={profileProfitSlice.actions.setCurrentExpectedIncomePage}
            noDataIndication={
              <>
                <p className="mb-0 text-center">Все още нямате очаквани приходи.</p>
              </>
            }
            expandRow={expandRow}
            actions={actions}
            centerRowVertically
          />
        )}
      </CardBody>
      {isLoading && <GlobalSpinner />}
    </ProfileProfitBasePage>
  );
}
