import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataFormat } from 'select2';

import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { Lesson, LessonEnd } from './models';
import { getLessonEnd, getLessonFeedbackCreateData, getLessonShow } from './service';

interface LessonState {
  loading: LoadingState;
  error?: string | null;
  show: Lesson;
  end: LessonEnd;
  comment: {
    lessons: DataFormat[];
  };
}

const initialState: LessonState = {
  loading: LoadingState.Idle,
  error: null,
  show: {
    id: null,
    jitsiDomain: '',
    virtualRoomUUID: '',
    jwt: '',
    endDateTime: '',
    messengerThreadID: null,
    recipient: null,
    isStudent: false,
  },
  end: {
    endDateTime: '',
    shouldLeaveFeedback: false,
    shouldRateCourse: false,
    shouldLeaveLessonFeedback: false,
    courseID: -1,
    lesson: {
      courseID: -1,
      subjectName: '',
      targetAudienceName: '',
      tutorName: '',
      isTutor: false,
    },
    connectedStudents: [],
  },
  comment: {
    lessons: [],
  },
};

const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    updateMessengerThread(state, action: PayloadAction<number>) {
      state.show.messengerThreadID = action.payload;
    },
  },
  extraReducers: (builder) => {
    //#region Get lesson show data
    builder
      .addCase(getLessonShow.pending, sharedPendingReducer)
      .addCase(getLessonShow.rejected, sharedRejectedReducer)
      .addCase(getLessonShow.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.show.id = action.payload.id;
        state.show.jitsiDomain = action.payload.jitsiDomain;
        state.show.virtualRoomUUID = action.payload.virtualRoomUUID;
        state.show.jwt = action.payload.jwt;
        state.show.endDateTime = action.payload.endDateTime;
        state.show.messengerThreadID = action.payload.messengerThreadID;
        state.show.recipient = action.payload.recipient;
        state.show.isStudent = action.payload.isStudent;
      });
    //#endregion Get lesson show data

    //#region Get lesson end data
    builder
      .addCase(getLessonEnd.pending, sharedPendingReducer)
      .addCase(getLessonEnd.rejected, sharedRejectedReducer)
      .addCase(getLessonEnd.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.end.endDateTime = action.payload.endDateTime;
        state.end.shouldLeaveFeedback = action.payload.shouldLeaveFeedback;
        state.end.shouldRateCourse = action.payload.shouldRateCourse;
        state.end.shouldLeaveLessonFeedback = action.payload.shouldLeaveLessonFeedback;
        state.end.courseID = action.payload.courseID;
        state.end.lesson = action.payload.lesson;
        state.end.connectedStudents = action.payload.connectedStudents;
      });
    //#endregion Get lesson end data

    //#region Get lesson feedback create data
    builder
      .addCase(getLessonFeedbackCreateData.pending, sharedPendingReducer)
      .addCase(getLessonFeedbackCreateData.rejected, sharedRejectedReducer)
      .addCase(getLessonFeedbackCreateData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.comment.lessons = action.payload.lessons;
      });
    //#endregion Get lesson feedback create data
  },
});

export default lessonSlice;
