export const GOOGLE_ANALYTICS_ECOMMERCE_EVENTS = {
  viewItem: 'productDetail',
  productImpression: 'productImpression',
  addToCart: 'addToCart',
  removeFromCart: 'removeFromCart',
  beginCheckout: 'beginCheckout',
  addPaymentInfo: 'payment',
  purchase: 'orderPlaced',
};

export const GOOGLE_ANALYTICS_CUSTOM_EVENTS = {
  registration: 'Registration',
  registrationTutor: 'Registration tutor',
  registrationStudent: 'Registration student',
  bookingCompleted: 'Booking completed',
  bookingConfirmation: 'Booking confirmation',
  bookingCancellation: 'Booking cancellation',
  lessonCompleted: 'Lesson completed',
  newCustomer: 'New customer',
  newMessage: 'New Message',
  lead: 'Lead',
  shkoloConsultation: 'Shkolo consultation',
  consultationComplete: 'Consultation complete',
};

export const FREE_INDIVIDUAL_LESSON_PRICE = 0;
export const FREE_INDIVIDUAL_LESSON_COUPON_TITLE = 'Безплатен индивидуален урок';
