import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { MenuComponent } from '@/_metronic/assets/ts/components/MenuComponent';
import { toAbsoluteUrl } from '@/_metronic/helpers';
import { HeaderAuthenticationMenu } from '@/app/layout/HeaderAuthenticationMenu';
import { useAppSelector } from '@/redux/store';

import { AppSwitcher } from './AppSwitcher/AppSwitcher';
import { Header } from './Header';
import { HeaderContext } from './HeaderContext';
import { HeaderMessengerMenu } from './HeaderMessengerMenu';
import { HeaderNotificationsMenu } from './HeaderNotificationsMenu';
import { HeaderSupportTicketMenu } from './HeaderSupportTicketMenu';
import { Topbar } from './Topbar';

import './HeaderWrapper.scss';

export function HeaderWrapper() {
  const { isAuthenticated, isDiaryUser } = useAppSelector((state) => state.auth);
  const isAuthenticatedDiaryUser = isAuthenticated && isDiaryUser;

  const { isVisible } = useContext(HeaderContext);

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div id="kt_header" className="header align-items-stretch">
      <div className="container-fluid px-0 d-flex align-items-stretch justify-content-between">
        <div className="flex-grow-1 flex-lg-grow-0 bg-primary">
          <div className="d-flex justify-content-between align-items-center h-100 px-5 mx-n2">
            <div className="d-flex align-items-center">
              <Link to="/" className="app-logo flex-grow-0 order-lg-0 mx-2">
                <img alt="Logo" src={toAbsoluteUrl('/img/svg/PrivateLessonsLogo.svg')} className="app-logo__img" />
              </Link>

              {isAuthenticatedDiaryUser && <AppSwitcher />}
            </div>

            <div className="d-flex d-lg-none">
              {isAuthenticated && (
                <>
                  <HeaderSupportTicketMenu />
                  <HeaderNotificationsMenu />
                  <HeaderMessengerMenu />
                </>
              )}
              {!isAuthenticated && (
                <HeaderAuthenticationMenu className="d-flex justify-content-end align-content-center" />
              )}
            </div>
          </div>
        </div>

        {/* begin::Wrapper */}
        <div className="d-none d-lg-flex align-items-stretch justify-content-between container-fluid px-5 overflow-hidden">
          {/* begin::Navbar */}
          <div className="d-flex align-items-stretch overflow-auto" id="kt_header_nav">
            <Header />
          </div>

          <div className="d-none d-lg-flex align-items-center">
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  );
}
