import { useEffect } from 'react';
import clsx from 'clsx';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { Card } from '@/app/components/Card/Card';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { getProfileConnectedStudents } from '@/app/modules/profile/connected-student/service';
import { StickyHeader } from '@/app/modules/profile/StickyHeader';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

export function ConnectedStudentIndexPage() {
  const dispatch = useAppDispatch();
  const loadingState = useAppSelector((state) => state.profileConnectedStudent.loading);
  const content = useAppSelector((state) => state.profileConnectedStudent.connectedStudents);

  useEffect(function componentDidMount() {
    if (loadingState === LoadingState.Idle) {
      dispatch(getProfileConnectedStudents());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StickyHeader className="py-5">
        <PageTop>
          <PageTitle className="fw-bolder fs-2x text-center">Обучаеми</PageTitle>
          <PageActions>
            <LinkButton variant="primary" className="ms-auto" to="connected-students/create">
              Добави обучаем
            </LinkButton>
          </PageActions>
        </PageTop>
      </StickyHeader>
      {content?.length === 0 && (
        <h2 className="mt-15 d-flex justify-content-center">Все още нямате създадени обучаеми.</h2>
      )}

      {content?.map((connectedStudent, idx) => (
        <Card
          key={connectedStudent.id}
          className={clsx(
            'p-8 shadow-sm d-flex flex-column flex-sm-row align-items-sm-center justify-content-between',
            { 'mb-5': idx < content?.length - 1 }
          )}
        >
          <h3 className="me-5 h2 my-0 overflow-hidden text-wrap">{connectedStudent.name}</h3>
          <div className="d-flex flex-wrap justify-content-center justify-content-sm-end mt-5 mt-sm-0 me-n2 mb-n2">
            <LinkButton
              to={`/profile/connected-students/${connectedStudent.id}`}
              className="me-2 mb-2"
              variant="light-primary"
            >
              Виж
            </LinkButton>
            <LinkButton
              to={`/profile/connected-students/${connectedStudent.id}/edit`}
              className="me-2 mb-2"
              variant="primary"
            >
              Редактирай
            </LinkButton>
          </div>
        </Card>
      ))}
      {loadingState === LoadingState.Pending && <GlobalSpinner />}
    </>
  );
}
