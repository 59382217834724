export const COUNTRIES_LOCALIZATION = {
  ab: 'Абхазия',
  ac: 'Възнесение (остров)',
  ad: 'Андора',
  ae: 'Обединени арабски емирства',
  af: 'Афганистан',
  ag: 'Антигуа и Барбуда',
  ai: 'Ангила',
  al: 'Албания',
  am: 'Армения',
  ao: 'Ангола',
  aq: 'Антарктида',
  ar: 'Аржентина',
  as: 'Американска Самоа',
  at: 'Австрия',
  au: 'Австралия',
  aw: 'Аруба',
  ax: 'Аландски острови',
  az: 'Азербайджан',
  ba: 'Босна и Херцеговина',
  bb: 'Барбадос',
  bd: 'Бангладеш',
  be: 'Белгия',
  bf: 'Буркина Фасо',
  bg: 'България',
  bh: 'Бахрейн',
  bi: 'Бурунди',
  bj: 'Бенин',
  bl: 'Свети Бартелеми',
  bm: 'Бермудски острови',
  bn: 'Бруней Дарусалам',
  bo: 'Боливия',
  bq: 'Bonaire, Sint Eustatius and Saba',
  br: 'Бразилия',
  bs: 'Бахамски острови',
  bt: 'Бутан',
  bv: 'Остров Буве',
  bw: 'Ботсвана',
  by: 'Беларус',
  bz: 'Белиз',
  ca: 'Канада',
  cc: 'Кокосови (Кийлинг) острови',
  cd: 'Конго, Демократична република',
  cf: 'Централноафриканска република',
  cg: 'Конго',
  ch: 'Швейцария',
  ci: "Кот д'Ивоар",
  ck: 'Острови на Кук',
  cl: 'Чили',
  cm: 'Камерун',
  cn: 'Китай',
  co: 'Колумбия',
  cr: 'Коста Рика',
  cu: 'Куба',
  cv: 'Кабо Верде',
  cw: 'Кюрасао',
  cx: 'Коледен остров',
  cy: 'Кипър',
  cz: 'Чешка република',
  de: 'Германия',
  dj: 'Джибути',
  dk: 'Дания',
  dm: 'Доминика',
  do: 'Доминиканска република',
  dz: 'Алжир',
  ec: 'Еквадор',
  ee: 'Естония',
  eg: 'Египет',
  eh: 'Западна Сахара',
  er: 'Еритрея',
  es: 'Испания',
  et: 'Етиопия',
  fi: 'Финландия',
  fj: 'Фиджи',
  fk: 'Фолкландски острови',
  fm: 'Федеративни щати Микронезия',
  fo: 'Фарьорски острови',
  fr: 'Франция',
  ga: 'Габон',
  gb: 'Обединено кралство',
  gd: 'Гренада',
  ge: 'Грузия',
  gf: 'Френска Гвиана',
  gg: 'Гернси',
  gh: 'Гана',
  gi: 'Гибралтар',
  gl: 'Гренландия',
  gm: 'Гамбия',
  gn: 'Гвинея',
  gp: 'Гваделупа',
  gq: 'Екваториална Гвинея',
  gr: 'Гърция',
  gs: 'Южна Джорджия и Южните сандвичеви острови',
  gt: 'Гватемала',
  gu: 'Гуам',
  gw: 'Гвинея-Бисау',
  gy: 'Гвиана',
  hk: 'Хонконг',
  hm: 'Острови Хърд и Макдоналд',
  hn: 'Хондурас',
  hr: 'Хърватия',
  ht: 'Хаити',
  hu: 'Унгария',
  id: 'Индонезия',
  ie: 'Ирландия',
  il: 'Израел',
  im: 'Ман (остров)',
  in: 'Индия',
  io: 'Британска територия в Индийския океан',
  iq: 'Ирак',
  ir: 'Иран',
  is: 'Исландия',
  it: 'Италия',
  je: 'Джърси',
  jm: 'Ямайка',
  jo: 'Йордания',
  jp: 'Япония',
  ke: 'Кения',
  kg: 'Киргизстан',
  kh: 'Камбоджа',
  ki: 'Кирибати',
  km: 'Коморски острови',
  kn: 'Сейнт Китс и Невис',
  kp: 'Северна Корея',
  kr: 'Южна Корея',
  kw: 'Кувейт',
  ky: 'Кайманови острови',
  kz: 'Казахстан',
  la: 'Лаос',
  lb: 'Ливан',
  lc: 'Сейнт Лусия',
  li: 'Лихтенщайн',
  lk: 'Шри Ланка',
  lr: 'Либерия',
  ls: 'Лесото',
  lt: 'Литва',
  lu: 'Люксембург',
  lv: 'Латвия',
  ly: 'Либия',
  ma: 'Мароко',
  mc: 'Монако',
  md: 'Молдова',
  me: 'Черна гора',
  mf: 'Свети Мартин (френска част)',
  mg: 'Мадагаскар',
  mh: 'Маршалови острови',
  mk: 'Северна Македония',
  ml: 'Мали',
  mm: 'Бирма',
  mn: 'Монголия',
  mo: 'Макао',
  mp: 'Северни Мариански острови',
  mq: 'Мартиника',
  mr: 'Мавритания',
  ms: 'Монсерат',
  mt: 'Малта',
  mu: 'Мавриций',
  mv: 'Малдиви',
  mw: 'Малави',
  mx: 'Мексико',
  my: 'Малайзия',
  mz: 'Мозамбик',
  na: 'Намибия',
  nc: 'Нова Каледония',
  ne: 'Нигер',
  nf: 'Остров Норфолк',
  ng: 'Нигерия',
  ni: 'Никарагуа',
  nl: 'Холандия',
  no: 'Норвегия',
  np: 'Непал',
  nr: 'Науру',
  nu: 'Ниуе',
  nz: 'Нова Зеландия',
  om: 'Оман',
  os: 'Южна Осетия',
  pa: 'Панама',
  pe: 'Перу',
  pf: 'Френска Полинезия',
  pg: 'Папуа Нова Гвинея',
  ph: 'Филипини',
  pk: 'Пакистан',
  pl: 'Полша',
  pm: 'Сен Пиер и Микелон',
  pn: 'Питкерн',
  pr: 'Пуерто Рико',
  ps: 'Палестина',
  pt: 'Португалия',
  pw: 'Палау',
  py: 'Парагвай',
  qa: 'Катар',
  re: 'Съединение',
  ro: 'Румъния',
  rs: 'Сърбия',
  ru: 'Русия',
  rw: 'Руанда',
  sa: 'Саудитска Арабия',
  sb: 'Соломонови острови',
  sc: 'Сейшелски острови',
  sd: 'Судан',
  se: 'Швеция',
  sg: 'Сингапур',
  sh: 'Света Елена',
  si: 'Словения',
  sj: 'Шпицберген и Ян Майен',
  sk: 'Словакия',
  sl: 'Сиера Леоне',
  sm: 'Сан Марино',
  sn: 'Сенегал',
  so: 'Сомалия',
  sr: 'Суринам',
  ss: 'Южен Судан',
  st: 'Сао Томе и Принсипи',
  sv: 'Ел Салвадор',
  sx: 'Синт Маартен',
  sy: 'Сирия',
  sz: 'Свазиленд',
  ta: 'Тристан да Куня',
  tc: 'Острови Търкс и Кайкос',
  td: 'Чад',
  tf: 'Френски южни територии',
  tg: 'Того',
  th: 'Тайланд',
  tj: 'Таджикистан',
  tk: 'Токелау',
  tl: 'Тимор-Лешти',
  tm: 'Туркменистан',
  tn: 'Тунис',
  to: 'Тонга',
  tr: 'Турция',
  tt: 'Тринидад и Тобаго',
  tv: 'Тувалу',
  tw: 'Тайван',
  tz: 'Танзания',
  ua: 'Украйна',
  ug: 'Уганда',
  um: 'Малки отдалечени острови на САЩ',
  us: 'Съединени щати',
  uy: 'Уругвай',
  uz: 'Узбекистан',
  va: 'Свети престол (държава Ватикан)',
  vc: 'Свети Винсент и Гренадини',
  ve: 'Венецуела',
  vg: 'Вирджински острови, британски',
  vi: 'Вирджински острови, САЩ',
  vn: 'Виетнам',
  vu: 'Вануату',
  wf: 'Уолис и Футуна',
  ws: 'Самоа',
  xk: 'Косово',
  ye: 'Йемен',
  yt: 'Майот',
  za: 'Южна Африка',
  zm: 'Замбия',
  zw: 'Зимбабве',
  zz: 'Международен',
};

export const DEFAULT_GLOBAL_PHONE_PREFIX = '+359';
