import { CardIndexFilterValues, CardIndexItemPerPageOption, CardIndexItemsPerPage } from './models';

export const DEFAULT_PAGE_VALUE = 1;
export const DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE: CardIndexItemsPerPage = 5;
export const CardIndexFilterInitialValues: CardIndexFilterValues = {
  page: DEFAULT_PAGE_VALUE,
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
};

export const ITEMS_PER_PAGE_OPTIONS: CardIndexItemPerPageOption[] = [
  {
    id: 5,
    text: '5 на страница',
  },
  {
    id: 10,
    text: '10 на страница',
  },
  {
    id: 15,
    text: '15 на страница',
  },
  {
    id: 20,
    text: '20 на страница',
  },
];
