import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { NO_SUBJECT_IMAGE_PATH } from '@/app/components/Form/PictureControl/constants';
import { CourseIndexFilterQueryParams, CourseIndexFilterValues, Subject } from '@/app/modules/course/models';
import { transformCourseIndexFormikValuesToSearchParams } from '@/app/modules/course/utils';
import { useQueryParams } from '@/app/utils/query';

import './SubjectItemMobile.scss';

type SubjectDropdownProps = {
  subject: Subject;
  onClick?: () => void;
};

export function SubjectItemMobile({ subject, onClick }: SubjectDropdownProps) {
  const formik = useFormikContext<CourseIndexFilterValues>();
  const { queryParams } = useQueryParams<CourseIndexFilterQueryParams>();

  const previousSearchParams = useMemo(() => {
    if (!formik?.values) {
      return undefined;
    }

    return transformCourseIndexFormikValuesToSearchParams(formik.values, queryParams);
  }, [formik?.values, queryParams]);

  return (
    <Link
      to={{
        pathname: `/${subject.transliteratedSlug}`,
        search: previousSearchParams,
      }}
      className="subject-card__header-btn d-flex align-items-center w-100 mb-3"
      onClick={onClick}
    >
      <Card className="rounded subject-card w-100">
        <Card.Img
          src={subject.imagePath ?? NO_SUBJECT_IMAGE_PATH}
          alt={subject.text}
          className="subject-card__img--mobile subject-card-image cursor-pointer"
        />
        <div className="d-flex align-items-stretch ms-15">
          <h6 className="subject-card__header-text subject-card__header-link subject-card__header-btn-content mt-2 mb-2 ms-2 d-flex justify-content-between subject-card__name--mobile">
            {subject.text}
            <i className="ms-auto me-1 far fa-arrow-right text-primary target-audience-icon" />
          </h6>
        </div>
      </Card>
    </Link>
  );
}
