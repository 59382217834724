import { Children, ReactNode } from 'react';

export function childrenToString(children: ReactNode) {
  let childrenText = '';

  Children.map(children, (child) => {
    childrenText += String(child);
  });

  return childrenText;
}
