import { ReactNode, useEffect } from 'react';
import clsx from 'clsx';

import { usePageData } from '@/_metronic/layout/core/PageData';
import { childrenToString } from '@/app/utils/childrenToString';

import './PageTitle.scss';

type PageTitleProps = {
  children: ReactNode;
  className?: string;
  isVisible?: boolean;
};

export function PageTitle({ children, className, isVisible = true }: PageTitleProps) {
  const { setPageTitle } = usePageData();

  useEffect(() => {
    if (children) {
      setPageTitle(childrenToString(children));
    }

    return () => {
      setPageTitle('');
    };
  }, [children]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isVisible) {
    return null;
  }

  return <h1 className={clsx('page-title text-gray-800', className)}>{children}</h1>;
}
