import clsx from 'clsx';

import { useHiddenConsultUs } from '@/_metronic/layout/components/consult-us/useHiddenConsultUs';
import { Card } from '@/app/components/Card/Card';
import { useHiddenFooter } from '@/app/layout/Footer/useHiddenFooter';

import { CourseIndexPageFilterMobile } from '../../../models';
import { HideFiltersButtonMobile } from './components/HideFiltersButtonMobile';

type FiltersIndexPageMobileProps = {
  isAllFiltersToggled: boolean;
  handleSubjectFilterToggle: () => void;
  handleAllFiltersToggle: () => void;
  setCurrentFilter: React.Dispatch<React.SetStateAction<CourseIndexPageFilterMobile | null>>;
};

export function FiltersIndexPageMobile({
  isAllFiltersToggled,
  handleSubjectFilterToggle,
  handleAllFiltersToggle,
  setCurrentFilter,
}: FiltersIndexPageMobileProps) {
  useHiddenConsultUs();
  useHiddenFooter();

  return (
    <div
      className={clsx('flex-wrap justify-content-between', {
        'd-flex': isAllFiltersToggled,
        'd-none': !isAllFiltersToggled,
      })}
    >
      <HideFiltersButtonMobile onClickHide={handleAllFiltersToggle} />

      <strong className="w-100 fs-2 ms-5 mt-5 mb-3">Филтри</strong>

      <Card className="rounded subject-card w-100">
        <div className="rounded">
          <button
            className="list-group-item p-6 fs-2 w-100 fs-2 d-flex justify-content-between align-items-center bg-white text-dark fw-normal"
            onClick={handleSubjectFilterToggle}
          >
            Предмет <i className="p-0 ms-auto me-1 far fa-arrow-right text-primary fs-1" />
          </button>

          <button
            className="list-group-item p-6 fs-2 w-100 fs-2 d-flex justify-content-between align-items-center bg-white text-dark fw-normal border-top-0"
            onClick={() => setCurrentFilter(CourseIndexPageFilterMobile.TargetAudience)}
          >
            Клас/възраст <i className="ms-auto me-1 far fa-arrow-right text-primary fs-1" />
          </button>

          <button
            className="list-group-item p-6 fs-2 w-100 fs-2 d-flex justify-content-between align-items-center bg-white text-dark fw-normal border-top-0"
            onClick={() => setCurrentFilter(CourseIndexPageFilterMobile.Keywords)}
          >
            Ключови думи <i className="ms-auto me-1 far fa-arrow-right text-primary fs-1" />
          </button>

          <button
            className="list-group-item p-6 fs-2 w-100 fs-2 d-flex justify-content-between align-items-center bg-white text-dark fw-normal border-top-0"
            onClick={() => setCurrentFilter(CourseIndexPageFilterMobile.Price)}
          >
            Цена <i className="ms-auto me-1 far fa-arrow-right text-primary fs-1" />
          </button>

          <button
            className="list-group-item p-6 fs-2 w-100 fs-2 d-flex justify-content-between align-items-center bg-white text-dark fw-normal border-top-0"
            onClick={() => setCurrentFilter(CourseIndexPageFilterMobile.NumberOfLessons)}
          >
            Пакет от уроци <i className="ms-auto me-1 far fa-arrow-right text-primary fs-1" />
          </button>

          <button
            className="list-group-item p-6 fs-2 w-100 fs-2 d-flex justify-content-between align-items-center bg-white text-dark fw-normal border-top-0"
            onClick={() => setCurrentFilter(CourseIndexPageFilterMobile.TimeInterval)}
          >
            Дата/час <i className="ms-auto me-1 far fa-arrow-right text-primary fs-1" />
          </button>
        </div>
      </Card>

      <strong className="w-100 fs-2 ms-5 mt-5 mb-3">Подреждане</strong>

      <Card className="rounded subject-card w-100">
        <div className="rounded">
          <button
            className="list-group-item p-6 fs-2 w-100 fs-2 d-flex justify-content-between align-items-center bg-white text-dark fw-normal"
            onClick={() => setCurrentFilter(CourseIndexPageFilterMobile.Order)}
          >
            Подреди по <i className="ms-auto me-1 far fa-arrow-right text-primary fs-1" />
          </button>

          <button
            className="list-group-item p-6 fs-2 w-100 fs-2 d-flex justify-content-between align-items-center bg-white text-dark fw-normal border-top-0"
            onClick={() => setCurrentFilter(CourseIndexPageFilterMobile.LessonsPerPage)}
          >
            Уроци на страница <i className="ms-auto me-1 far fa-arrow-right text-primary fs-1" />
          </button>
        </div>
      </Card>
    </div>
  );
}
