import { RadioButtonControl } from '@/app/components/Form/RadioButtonControl/RadioButtonControl';

import { TargetAudience } from '../../../models';
import { FilterDesktop } from './components/FilterDesktop';

type TargetAudiencesFilterPageDesktopProps = {
  targetAudiences: TargetAudience[];
};

export function TargetAudiencesFilterPageDesktop({ targetAudiences }: TargetAudiencesFilterPageDesktopProps) {
  return (
    <FilterDesktop name="Клас/възраст" className="overflow-scroll mh-100px pt-1">
      {targetAudiences.map((targetAudience) => (
        <label key={targetAudience.id} className="d-flex align-items-center cursor-pointer mb-3">
          <RadioButtonControl name="targetAudienceSlug" value={targetAudience.id} isInline />
          <span className="ps-2">{targetAudience.text}</span>
        </label>
      ))}
    </FilterDesktop>
  );
}
