import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormikContextType, FormikValues } from 'formik';

import { Card } from '@/app/components/Card/Card';
import { Form } from '@/app/components/Form/Form';
import { handleFrontEndValidations } from '@/app/components/Form/utils';
import { BasePageProps } from '@/app/components/Page/models';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { TabItem } from '@/app/components/Tabs/TabItem';
import { TabNav } from '@/app/components/Tabs/TabNav';
import { getTutorStatus } from '@/app/modules/auth/service';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

import { StickyHeader } from '../../StickyHeader';

type ProfileDetailsEditPageBaseProps<T> = BasePageProps & {
  formik: FormikContextType<T>;
};

export function TutorDetailsEditPageBase<T = FormikValues>({
  title,
  actions,
  children,
  formik,
}: ProfileDetailsEditPageBaseProps<T>) {
  const dispatch = useDispatch();
  const tutorLoading = useAppSelector((state) => state.auth.tutorLoading);

  useEffect(function componentDidMount() {
    if (tutorLoading === LoadingState.Idle) {
      dispatch(getTutorStatus());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form formik={formik} disabled={formik.isSubmitting} onSubmit={handleFrontEndValidations(formik)}>
      <StickyHeader className="pt-5" containerClassName="mb-7">
        <PageTop className="mb-5">
          <PageTitle className="my-2">{title}</PageTitle>
          <PageActions>{actions}</PageActions>
        </PageTop>
        <TabNav>
          <TabItem to="/profile/details/edit">Лични данни</TabItem>
          <TabItem to="/profile/about/edit">За мен</TabItem>
          <TabItem to="/profile/bank-details/edit">Банкова сметка</TabItem>
          <TabItem to="/profile/invoice-details/edit">Фактуриране</TabItem>
        </TabNav>
      </StickyHeader>

      <Card>{children}</Card>
    </Form>
  );
}
