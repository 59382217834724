import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import Toastr from 'toastr';

import { ScrollTopComponent } from '@/_metronic/assets/ts/components';
import { getData, putData } from '@/app/utils/IndexedDB';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

import { VALIDATION_ERROR_TOAST_MESSAGE } from '../Form/constants';
import {
  IDENTITY_ID_KEY,
  STEPPER_CURRENT_STEP_KEY,
  STEPPER_DB_NAME,
  STEPPER_DB_VERSION,
  STEPPER_OBJECT_STORE_NAME,
} from './constants';
import {
  BaseStepperValue,
  createCurrentStepInstance,
  CurrentStepInstance,
  StepInstance,
  StepperInstance,
} from './models';
import {
  clearStepperData,
  getStepperCacheKey,
  getStepperData,
  getStepValidationErrorMessage,
  persistStepperData,
} from './utils';

type StepperConfig<V extends BaseStepperValue, R extends Record<string, unknown>> = {
  slug: string;
  stepInstances: Record<string, StepInstance<any>>; // eslint-disable-line @typescript-eslint/no-explicit-any
  handleSubmit: (values: V) => Promise<R> | Promise<void>;
  handleAfterSubmit?: (response: R) => Promise<void> | void;
  isLoading?: boolean;
};

export function useStepper<V extends BaseStepperValue, R extends Record<string, unknown>>({
  slug,
  stepInstances,
  handleSubmit,
  handleAfterSubmit,
  isLoading = false,
}: StepperConfig<V, R>): StepperInstance {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { identityID } = useAppSelector((state) => state.auth);

  //#region HANDLE PERSISTING
  const [stepperDB, setStepperDB] = useState<IDBDatabase | null>(null);
  const isRestoredRef = useRef(false);

  useEffect(function initializeStepperDB() {
    const openRequest = window.indexedDB.open(STEPPER_DB_NAME, STEPPER_DB_VERSION);

    function handleOpenDBRequestSuccess() {
      setStepperDB(openRequest.result);
    }

    function handleOpenDBRequestError() {
      Toastr.error('Позволете достъпа до файловете.');
    }

    function handleUpgradeNeeded() {
      if (!openRequest.result?.objectStoreNames?.contains?.(STEPPER_OBJECT_STORE_NAME)) {
        openRequest.result?.createObjectStore?.(STEPPER_OBJECT_STORE_NAME);
      }
    }

    openRequest.addEventListener('success', handleOpenDBRequestSuccess);
    openRequest.addEventListener('error', handleOpenDBRequestError);
    openRequest.addEventListener('upgradeneeded', handleUpgradeNeeded);

    return () => {
      openRequest.removeEventListener('success', handleOpenDBRequestSuccess);
      openRequest.removeEventListener('error', handleOpenDBRequestError);
      openRequest.removeEventListener('upgradeneeded', handleUpgradeNeeded);
    };
  }, []);

  useEffect(
    function dbInitiliazed() {
      async function handleInitialState() {
        if (stepperDB === null) {
          return;
        }

        const storedIdentityID = await getData(stepperDB, STEPPER_OBJECT_STORE_NAME, IDENTITY_ID_KEY);

        //Handles first time opening the stepper
        if (storedIdentityID === undefined) {
          putData(stepperDB, STEPPER_OBJECT_STORE_NAME, IDENTITY_ID_KEY, identityID);
          return;
        }

        //If logged in with another identity, clear the stepper data
        if (storedIdentityID !== identityID) {
          clearStepperData(stepperDB, slug);
        }

        putData(stepperDB, STEPPER_OBJECT_STORE_NAME, IDENTITY_ID_KEY, identityID);
      }

      handleInitialState();
    },
    [stepperDB, slug] //eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function handleRestoringData() {
      async function handleRestoringData() {
        // Sanity check.
        if (isRestoredRef.current || stepperDB === null || isLoading) {
          return;
        }

        // STEP 1: Handle individual step values.
        for (const stepKey in stepInstances) {
          // CASE 1.1: Something inherited -> skip.
          if (!Object.prototype.hasOwnProperty.call(stepInstances, stepKey)) {
            continue;
          }

          const stepInstance = stepInstances[stepKey];
          const cacheKey = getStepperCacheKey(stepKey);
          const stepCache = await getStepperData(stepperDB, slug, cacheKey);

          // CASE 1.2: If cache invalidation is custom in this instance and cache values are different -> skip.
          if (stepInstance.cache && stepInstance.cache !== stepCache) {
            continue;
          }

          const stepValues = await getStepperData<FormikValues>(stepperDB, slug, stepKey);

          // CASE 1.3: Has stepValues -> set to formik.
          if (stepValues) {
            stepInstance.formik.setValues(stepValues);
          }
        }

        // STEP 2: Handle current step.
        const currentStep = await getStepperData<number>(stepperDB, slug, STEPPER_CURRENT_STEP_KEY);

        if (currentStep) {
          setCurrentStep(currentStep);
        } else {
          setCurrentStep(1);
        }

        // STEP 3: Mark as restored.
        isRestoredRef.current = true;
      }

      handleRestoringData();
    },
    [isLoading, slug, stepInstances, stepperDB]
  );
  //#endregion HANDLE PERSISTING

  //#region STEP COUNTER
  const [stepCount, setStepCount] = useState(0);
  const incrementStepCount = useCallback(() => setStepCount((stepCount) => stepCount + 1), []);
  const decrementStepCount = useCallback(() => setStepCount((stepCount) => stepCount - 1), []);
  //#endregion STEP COUNTER

  //#region CURRENT STEP
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  const currentStepInstance = useMemo<CurrentStepInstance | null>(() => {
    for (const stepKey in stepInstances) {
      // Sanity check.
      if (!Object.prototype.hasOwnProperty.call(stepInstances, stepKey)) {
        continue;
      }

      const stepInstance = stepInstances[stepKey];

      if (stepInstance.stepNumber !== currentStep) {
        continue;
      }

      return createCurrentStepInstance(stepInstance, stepKey);
    }

    return null;
  }, [currentStep, stepInstances]);

  const nextStep = useCallback(async () => {
    // Sanity checks.
    if (currentStep === null) {
      return;
    }

    if (currentStep === stepCount) {
      return;
    }

    setIsSubmitting(true);

    // STEP 1: Validate step form.
    if (!currentStepInstance) {
      setIsSubmitting(false);
      throw new Error('No step instance for the current step!');
    }

    const stepFormik = currentStepInstance.formik;

    const validationErrors = await stepFormik.validateForm();
    const hasErrors = Object.keys(validationErrors).length > 0;

    if (hasErrors) {
      Toastr.error(VALIDATION_ERROR_TOAST_MESSAGE);
      setIsSubmitting(false);
      return;
    }

    // STEP 2: Persist step form data only if dirty.
    if (stepFormik.dirty && stepperDB !== null) {
      const stepKey = currentStepInstance.stepKey;

      await persistStepperData<FormikValues>(stepperDB, slug, stepKey, stepFormik.values as FormikValues);
      stepFormik.resetForm({ values: stepFormik.values });
    }

    // STEP 3: Go to the next step.
    const nextCurrentStep = currentStep + 1;

    setCurrentStep(nextCurrentStep);

    ScrollTopComponent.goTop();

    if (stepperDB !== null) {
      await persistStepperData(stepperDB, slug, STEPPER_CURRENT_STEP_KEY, nextCurrentStep);
    }

    // STEP 4: Persist `cache` value if exists.
    if (currentStepInstance.cache && stepperDB !== null) {
      const cacheKey = getStepperCacheKey(currentStepInstance.stepKey);
      await persistStepperData(stepperDB, slug, cacheKey, currentStepInstance.cache);
    }

    setIsSubmitting(false);
  }, [currentStep, currentStepInstance, slug, stepCount, stepperDB]);

  const previousStep = useCallback(async () => {
    // Sanity checks.
    if (currentStep === null) {
      return;
    }

    if (currentStep === 1) {
      return;
    }

    const nextCurrentStep = currentStep - 1;

    setCurrentStep(nextCurrentStep);

    ScrollTopComponent.goTop();

    if (stepperDB !== null) {
      await persistStepperData(stepperDB, slug, STEPPER_CURRENT_STEP_KEY, nextCurrentStep);
    }
  }, [currentStep, slug, stepperDB]);

  const goToStep = useCallback(
    async (step: number) => {
      // Sanity checks.
      if (currentStep === null) {
        return;
      }

      if (step === currentStep || step < 1 || step > stepCount) {
        return;
      }

      setCurrentStep(step);

      ScrollTopComponent.goTop();
    },
    [currentStep, stepCount]
  );
  //#endregion CURRENT STEP

  //#region SUBMIT
  const internalHandleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      // Sanity checks.
      if (currentStep === null || currentStepInstance === null) {
        throw new Error('No formik instance for the current step!');
      }

      // Sanity check.
      if (!currentStepInstance) {
        throw new Error('No step instance for the current step!');
      }

      const stepFormik = currentStepInstance.formik;

      // CASE 1: Normal step -> forward to formik's submit handler.
      if (currentStep < stepCount) {
        stepFormik?.handleSubmit(event);
        return;
      }

      // CASE 2: Last step -> handle whole stepper submit.
      setIsSubmitting(true);
      stepFormik.setStatus(LoadingState.Pending);

      // STEP 2.1: Validate current step form.
      const validationErrors = await stepFormik.validateForm();
      const hasErrors = Object.keys(validationErrors).length > 0;

      if (hasErrors) {
        Toastr.error(VALIDATION_ERROR_TOAST_MESSAGE);
        setIsSubmitting(false);
        stepFormik.setStatus(LoadingState.Idle);
        return;
      }

      // STEP 2.2: Validate previous steps (except current).
      for (const stepKey in stepInstances) {
        // Sanity check.
        if (!Object.prototype.hasOwnProperty.call(stepInstances, stepKey)) {
          continue;
        }

        const stepInstance = stepInstances[stepKey];

        if (stepInstance.stepNumber === currentStep) {
          continue;
        }

        const validationErrors = await stepInstance.formik.validateForm();
        const hasErrors = Object.keys(validationErrors).length > 0;

        if (hasErrors) {
          Toastr.error(getStepValidationErrorMessage(stepInstance.stepNumber));
          setIsSubmitting(false);
          stepFormik.setStatus(LoadingState.Idle);
          return;
        }
      }

      // STEP 2.3: Call configured `handleSubmit`.
      if (!handleSubmit) {
        throw new Error(`No \`handleSubmit\` is provided for ${slug}!`);
      }

      const values: BaseStepperValue = {};

      for (const stepKey in stepInstances) {
        if (!Object.prototype.hasOwnProperty.call(stepInstances, stepKey)) {
          continue;
        }

        values[stepKey] = stepInstances[stepKey].formik.values;
      }

      try {
        const response = await handleSubmit(values as V);

        if (stepperDB) {
          await clearStepperData(stepperDB, slug);
        }

        setIsSubmitting(false);
        stepFormik.setStatus(LoadingState.Idle);

        await handleAfterSubmit?.(response as R);
      } catch (error) {
        // Handle back-end validation.
        const internalError = error as AxiosError;

        if (internalError?.response?.status === 422 && internalError.response.data?.errors) {
          const stepNumbersWithValidationErrors: number[] = []; // used for showing validation toast messages per step

          // STEP 1: Iterate over field errors.
          for (const fieldName in internalError.response.data.errors) {
            if (!Object.prototype.hasOwnProperty.call(internalError.response.data.errors, fieldName)) {
              continue;
            }

            // STEP 1.1: Get errors.
            const errors = internalError.response.data.errors[fieldName];
            const errorMessage = errors.join(' ');

            // STEP 1.2: Dissect field name to find step key.
            const fieldNameParts = fieldName.split('.');
            const stepKey = fieldNameParts[0];

            // STEP 1.2.1 Make sure that remaining part (after step key) is concatinated properly as if not touched.
            const remainingFieldNameParts = fieldNameParts.slice(1);
            const finalFieldName = remainingFieldNameParts.join('.');

            // STEP 1.3: Set error to step's formik instance.
            const stepInstance = stepInstances?.[stepKey];

            if (!stepInstance) {
              throw new Error(`No step instance (${stepKey}) for the step with validation error!`);
            }

            const stepFormik = stepInstance?.formik;

            stepFormik.setFieldError(finalFieldName, errorMessage);

            // STEP 1.4: Mark that this step has validation errors;
            if (!stepNumbersWithValidationErrors.includes(stepInstance.stepNumber)) {
              stepNumbersWithValidationErrors.push(stepInstance.stepNumber);
            }
          }

          // STEP 2: Show validation toast messages per step.
          for (const stepNumber of stepNumbersWithValidationErrors) {
            Toastr.error(getStepValidationErrorMessage(stepNumber));
          }
        }

        setIsSubmitting(false);
        stepFormik.setStatus(LoadingState.Idle);
      }
    },
    [currentStep, currentStepInstance, stepInstances, stepCount, handleSubmit, stepperDB, handleAfterSubmit, slug]
  );
  //#endregion SUBMIT

  //#region UTILS
  function getStepData(stepKey: string) {
    // Sanity check.
    if (!Object.prototype.hasOwnProperty.call(stepInstances, stepKey)) {
      return null;
    }

    return stepInstances[stepKey].formik.values;
  }
  //#endregion

  return {
    stepInstances,
    currentStep,
    currentStepInstance,
    stepCount,
    isSubmitting,
    nextStep,
    previousStep,
    goToStep,
    incrementStepCount,
    decrementStepCount,
    handleSubmit: internalHandleSubmit,
    getStepData,
    isLoading,
  };
}
