import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import { Button } from '@/app/components/Button/Button';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { RadioButtonControl } from '@/app/components/Form/RadioButtonControl/RadioButtonControl';
import { CalendarEventType } from '@/app/modules/course/create/models';
import { ProfileCourseScheduleValues } from '@/app/modules/profile/course/models';

type EventTypeActionModalProps = {
  isShown: boolean;
  onHide: () => void;
  onSubmit: () => void;
  isEditAction?: boolean;
  hasIndividualCheckboxOption?: boolean;
  hasThisAndFutureCheckboxOption?: boolean;
  hasRecurringEventCheckboxOption?: boolean;
};

export function EventTypeActionModal({
  isShown,
  onHide,
  onSubmit,
  isEditAction = false,
  hasIndividualCheckboxOption = true,
  hasThisAndFutureCheckboxOption = true,
  hasRecurringEventCheckboxOption = true,
}: EventTypeActionModalProps) {
  const formik = useFormikContext<ProfileCourseScheduleValues>();
  const isRecurringChanged = useMemo(() => {
    return !hasIndividualCheckboxOption && !hasThisAndFutureCheckboxOption;
  }, [hasIndividualCheckboxOption, hasThisAndFutureCheckboxOption]);

  useEffect(() => {
    if (!isShown) {
      return;
    }
    if (formik.values.calendarEventType) {
      return;
    }
    if (hasIndividualCheckboxOption) {
      formik.setFieldValue('calendarEventType', CalendarEventType.IndividualEvent);
      return;
    }
    if (hasThisAndFutureCheckboxOption) {
      formik.setFieldValue('calendarEventType', CalendarEventType.ThisAndFollowingEvents);
      return;
    }
    formik.setFieldValue('calendarEventType', CalendarEventType.RecurringEvent);
  }, [formik, isShown, hasIndividualCheckboxOption, hasThisAndFutureCheckboxOption]);

  function handleInternalHide() {
    onHide();
    formik.setFieldValue('calendarEventType', null);
    formik.setSubmitting(false);
  }

  function handleInternalSubmit() {
    onSubmit();
    formik.setSubmitting(false);
  }
  return (
    <Modal show={isShown} onEscapeKeyDown={handleInternalHide} centered>
      <Modal.Header closeButton onHide={handleInternalHide}>
        <h5>{`${isEditAction ? 'Редактиране' : 'Изтриване'} на повтарящо се събитие`}</h5>
      </Modal.Header>
      <Modal.Body>
        {isRecurringChanged && (
          <p className="text-grey-500 font-weight-normal fs-6">
            Промените, които сте направили могат да бъдат отнесени само към всички събития, от които е част избрания от
            Вас час.
          </p>
        )}
        <FormGroup controlId="calendarEventType">
          {hasIndividualCheckboxOption && (
            <label className="p-3 d-flex flex-row align-items-center cursor-pointer">
              <RadioButtonControl
                name="calendarEventType"
                value={CalendarEventType.IndividualEvent}
                isInline
                className="me-3"
              />

              <div className="d-flex flex-column w-100">
                <div className="d-flex justify-content-between">
                  <span className="mb-0 fw-normal fs-6">Това събитие</span>
                </div>
              </div>
            </label>
          )}
          {hasThisAndFutureCheckboxOption && (
            <label className="p-3 d-flex flex-row align-items-center cursor-pointer">
              <RadioButtonControl
                name="calendarEventType"
                value={CalendarEventType.ThisAndFollowingEvents}
                isInline
                className="me-3"
              />

              <div className="d-flex flex-column w-100">
                <div className="d-flex justify-content-between">
                  <span className="mb-0 fw-normal fs-6">Това и следващите събития</span>
                </div>
              </div>
            </label>
          )}
          {hasRecurringEventCheckboxOption && (
            <label className="p-3 d-flex flex-row align-items-center cursor-pointer">
              <RadioButtonControl
                name="calendarEventType"
                value={CalendarEventType.RecurringEvent}
                isInline
                className="me-3"
              />

              <div className="d-flex flex-column w-100">
                <div className="d-flex justify-content-between">
                  <span className="mb-0 fw-normal fs-6">Всички събития</span>
                </div>
              </div>
            </label>
          )}
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleInternalHide}>
          Отказ
        </Button>
        <Button variant="primary" onClick={handleInternalSubmit} type="submit">
          Запази
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
