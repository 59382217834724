import { createContext } from 'react';

import { BottomStickyVariant, ConsultUsInstance } from './models';

export const ConsultUsContext = createContext<ConsultUsInstance>({
  isVisible: true,
  isPageLoaded: true,
  bottomStickyVariant: BottomStickyVariant.Default,
  show: () => null,
  showOnLoadedPage: () => null,
  hide: () => null,
  hideOnLoadingPage: () => null,
  setBottomSticky: (value: BottomStickyVariant) => value,
});
