import { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import { AttachmentPreviewContainer } from '@/app/components/AttachmentPreview/AttachmentPreviewContainer';
import { AttachmentPreviewItem } from '@/app/components/AttachmentPreview/AttachmentPreviewItem';
import { EditButton } from '@/app/components/Button/EditButton';
import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { PageActions } from '@/app/components/Page/PageActions/PageActions';
import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { serializeToHtml } from '@/app/components/RichTextControl/utils';
import { DEFAULT_PROFILE_PICTURE_PATH } from '@/app/layout/constants';
import { TutorDetailsShowPageBase } from '@/app/modules/profile/details/show/TutorDetailsShowPageBase';
import { LoadingState } from '@/redux/constants';
import { useAppSelector } from '@/redux/store';

import { getTutorProfileDetailsShowData } from '../service';

export function TutorProfileDetailsShowPage() {
  const profileDetails = useAppSelector((state) => state.profileDetails);
  const tutorDetails = profileDetails.show.profileDetails;
  const dispatch = useDispatch();

  const serializedAbout = useMemo(() => serializeToHtml(tutorDetails.about ?? EMPTY_VALUE), [tutorDetails.about]);

  useEffect(function componentDidMount() {
    if (profileDetails.loading === LoadingState.Idle) {
      dispatch(getTutorProfileDetailsShowData());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>Данни за профила</title>
      </Helmet>
      <TutorDetailsShowPageBase
        title="Данни за профила"
        actions={
          <PageActions>
            <EditButton to="/profile/details/edit" />
          </PageActions>
        }
      >
        <CardHeader>
          <CardTitle>Лични данни</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={{ span: 'auto', offset: 1 }} className="mb-4">
              <div
                style={{
                  backgroundImage: `url(${tutorDetails?.profileFile ?? DEFAULT_PROFILE_PICTURE_PATH})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
                className="profile-image me-3 w-125px h-125px rounded"
              />
            </Col>
            <Col md={{ span: 12, offset: 1 }} xl={{ span: 8, offset: 0 }}>
              <Row>
                <p className="mb-0 fw-bold mb-1">Име</p>
                <p>{tutorDetails?.name ?? ''}</p>
              </Row>
              <Row>
                <p className="mb-0 fw-bold mb-1">Телефон</p>
                <p>{tutorDetails?.phone ?? ''}</p>
              </Row>
              <Row>
                <p className="mb-0 fw-bold mb-1">Имейл адрес</p>
                <p>{tutorDetails?.email ?? ''}</p>
              </Row>
              <Row>
                <p className="mb-0 fw-bold mb-1">Потребителско име</p>
                <p>{tutorDetails?.username ?? ''}</p>
              </Row>
              <Row>
                <p className="mb-0 fw-bold mb-1">За мен</p>
                <p dangerouslySetInnerHTML={{ __html: serializedAbout }} />
              </Row>
              {Number(tutorDetails.attachments?.length) > 0 && (
                <Row>
                  <p className="mb-0 fw-bold mb-1">Педагогически постижения</p>
                  <AttachmentPreviewContainer>
                    {tutorDetails.attachments?.map((attachment, idx: number) => {
                      return <AttachmentPreviewItem key={idx} attachment={attachment} />;
                    })}
                  </AttachmentPreviewContainer>
                </Row>
              )}
            </Col>
          </Row>
        </CardBody>
      </TutorDetailsShowPageBase>
    </>
  );
}
