import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_DURATION, DEFAULT_RESERVATION_BUFFER_HOURS } from '@/app/components/Calendar/constants';
import { EditableTimeSlotEntity } from '@/app/components/Calendar/models';
import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { CoursePackageModel, CoursePricingStrategyID } from '@/app/modules/course/create/models';
import { RecurrenceType, Subject, TargetAudience } from '@/app/modules/course/models';
import { ProfileCourse, ProfileCourseDetails } from '@/app/modules/profile/course/models';
import { getReservationProposalTimeSlots } from '@/app/modules/reservation-proposal/service';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import {
  getProfileCourseDetailsEditData,
  getProfileCourseDetailsShowData,
  getProfileCoursePackagesEditData,
  getProfileCoursePackagesShowData,
  getProfileCourses,
  getProfileCourseScheduleEditData,
  getProfileCourseScheduleShowData,
  getProfileCourseScheduleTimeSlots,
  getProfileCourseStatusEditData,
  getProfileCourseStatusShowData,
} from './service';

interface ProfileCourseState {
  loading: LoadingState;
  timeSlotsLoading: LoadingState;
  error?: string | null;
  courseName: string;
  course: ProfileCourseDetails;
  subjects: Subject[];
  targetAudiences: TargetAudience[];
  courseStatusID: number | null;
  reservationBufferHours: number | string;
  duration: number | string;
  canChangeCourseDuration: boolean;
  canUseLegacyCourseDuration: boolean;
  blockNewStudentsReservations: 0 | 1;
  timeSlots: EditableTimeSlotEntity[];
  recurrenceTypes: RecurrenceType[];
  isIndividualLessonEnabled: boolean;
  isCoursePackagesEnabled: boolean;
  individualLesson?: CoursePackageModel | null;
  coursePackages: CoursePackageModel[];
  archivedCoursePackages: CoursePackageModel[];
  courses: ProfileCourse[];
  isSingleCoursePackageEnabled: boolean;
  coursePricingStrategyID: CoursePricingStrategyID;
  singlePackage?: CoursePackageModel | null;
}

const initialState: ProfileCourseState = {
  loading: LoadingState.Idle,
  timeSlotsLoading: LoadingState.Idle,
  error: null,
  courseName: '',
  course: {
    name: '',
    goals: '',
    methodology: '',
    skills: '',
    content: null,
    description: EMPTY_VALUE,
    canUpdateSubject: false,
    canUpdateTargetAudience: false,
    subjectName: '',
    targetAudienceName: '',
    keywords: [],
  },
  subjects: [],
  targetAudiences: [],
  courseStatusID: null,
  reservationBufferHours: DEFAULT_RESERVATION_BUFFER_HOURS,
  duration: DEFAULT_DURATION,
  canChangeCourseDuration: true,
  canUseLegacyCourseDuration: false,
  blockNewStudentsReservations: 0,
  timeSlots: [],
  recurrenceTypes: [],
  isIndividualLessonEnabled: true,
  isCoursePackagesEnabled: true,
  individualLesson: null,
  coursePackages: [],
  archivedCoursePackages: [],
  courses: [],
  isSingleCoursePackageEnabled: false,
  coursePricingStrategyID: CoursePricingStrategyID.MultiplePackages,
  singlePackage: null,
};

const profileCourseSlice = createSlice({
  name: 'profileCourse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region GET PROFILE COURSES
    builder
      .addCase(getProfileCourses.pending, sharedPendingReducer)
      .addCase(getProfileCourses.rejected, sharedRejectedReducer)
      .addCase(getProfileCourses.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.courses = action.payload.courses;
        }
      });
    //#endregion GET PROFILE COURSES

    //#region COURSE STATUS
    builder
      .addCase(getProfileCourseStatusShowData.pending, sharedPendingReducer)
      .addCase(getProfileCourseStatusShowData.rejected, sharedRejectedReducer)
      .addCase(getProfileCourseStatusShowData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseName = action.payload.courseName;
        state.courseStatusID = action.payload.courseStatusID;
      });

    builder
      .addCase(getProfileCourseStatusEditData.pending, sharedPendingReducer)
      .addCase(getProfileCourseStatusEditData.rejected, sharedRejectedReducer)
      .addCase(getProfileCourseStatusEditData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseName = action.payload.courseName;
        state.courseStatusID = action.payload.courseStatusID;
      });
    //#endregion COURSE STATUS

    //#region COURSE DETAILS
    builder
      .addCase(getProfileCourseDetailsShowData.pending, sharedPendingReducer)
      .addCase(getProfileCourseDetailsShowData.rejected, sharedRejectedReducer)
      .addCase(getProfileCourseDetailsShowData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseName = action.payload.course.name;
        state.course.name = action.payload.course.name;
        state.course.subjectName = action.payload.course.subjectName;
        state.course.keywords = action.payload.course.keywords;
        state.course.targetAudienceName = action.payload.course.targetAudienceName;
        state.course.goals = action.payload.course.goals;
        state.course.methodology = action.payload.course.methodology;
        state.course.skills = action.payload.course.skills;
        state.course.content = action.payload.course.content;
        state.course.description = action.payload.course.description;
      });

    builder
      .addCase(getProfileCourseDetailsEditData.pending, sharedPendingReducer)
      .addCase(getProfileCourseDetailsEditData.rejected, sharedRejectedReducer)
      .addCase(getProfileCourseDetailsEditData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseName = action.payload.course.name;
        state.course.name = action.payload.course.name;
        state.course.keywords = action.payload.course.keywords;
        state.course.targetAudienceID = action.payload.course.targetAudienceID;
        state.course.targetAudienceName = action.payload.course.targetAudienceName;
        state.course.goals = action.payload.course.goals;
        state.course.methodology = action.payload.course.methodology;
        state.course.skills = action.payload.course.skills;
        state.course.content = action.payload.course.content;
        state.course.description = action.payload.course.description;
        state.course.subjectID = action.payload.course.subjectID;
        state.course.canUpdateSubject = action.payload.course.canUpdateSubject;
        state.course.canUpdateTargetAudience = action.payload.course.canUpdateTargetAudience;
        state.subjects = action.payload.subjects;
      });
    //#endregion COURSE DETAILS

    //#region COURSE SCHEDULE
    builder
      .addCase(getProfileCourseScheduleShowData.pending, sharedPendingReducer)
      .addCase(getProfileCourseScheduleShowData.rejected, sharedRejectedReducer)
      .addCase(getProfileCourseScheduleShowData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseName = action.payload.courseName;
        state.duration = action.payload.duration;
        state.reservationBufferHours = action.payload.reservationBufferHours;
        state.recurrenceTypes = action.payload.recurrenceTypes;
      });

    builder
      .addCase(getProfileCourseScheduleEditData.pending, sharedPendingReducer)
      .addCase(getProfileCourseScheduleEditData.rejected, sharedRejectedReducer)
      .addCase(getProfileCourseScheduleEditData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseName = action.payload.courseName;
        state.duration = action.payload.duration;
        state.reservationBufferHours = action.payload.reservationBufferHours;
        state.canChangeCourseDuration = action.payload.canChangeCourseDuration;
        state.canUseLegacyCourseDuration = action.payload.canUseLegacyCourseDuration;
        state.blockNewStudentsReservations = action.payload.hasBlockedNewStudentsReservations ? 1 : 0;
        state.recurrenceTypes = action.payload.recurrenceTypes;
      });

    builder
      .addCase(getProfileCourseScheduleTimeSlots.pending, (state) => {
        if (state.timeSlotsLoading === LoadingState.Idle) {
          state.timeSlotsLoading = LoadingState.Pending;
          state.timeSlots = initialState.timeSlots;
        }
      })
      .addCase(getProfileCourseScheduleTimeSlots.rejected, (state, action) => {
        if (state.timeSlotsLoading === LoadingState.Pending) {
          state.timeSlotsLoading = LoadingState.Idle;
          state.error = action.error.message;
        }
      })
      .addCase(getProfileCourseScheduleTimeSlots.fulfilled, (state, action) => {
        state.timeSlotsLoading = LoadingState.Idle;
        state.timeSlots = action.payload.timeSlots;
      });
    //#endregion COURSE SCHEDULE

    //#region COURSE PACKAGES
    builder
      .addCase(getProfileCoursePackagesShowData.pending, sharedPendingReducer)
      .addCase(getProfileCoursePackagesShowData.rejected, sharedRejectedReducer)
      .addCase(getProfileCoursePackagesShowData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseName = action.payload.courseName;
        state.individualLesson = action.payload.individualLesson;
        state.coursePackages = action.payload.coursePackages;
        state.isSingleCoursePackageEnabled = action.payload.isSingleCoursePackageEnabled;
      });

    builder
      .addCase(getProfileCoursePackagesEditData.pending, sharedPendingReducer)
      .addCase(getProfileCoursePackagesEditData.rejected, sharedRejectedReducer)
      .addCase(getProfileCoursePackagesEditData.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.courseName = action.payload.courseName;
        state.individualLesson = action.payload.individualLesson;
        state.coursePackages = action.payload.coursePackages;
        state.archivedCoursePackages = action.payload.archivedCoursePackages;
        state.coursePricingStrategyID = action.payload.coursePricingStrategyID;
        state.singlePackage = action.payload.singlePackage;
      });
    //#endregion COURSE PACKAGES
  },
});

export default profileCourseSlice;
