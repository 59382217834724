import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { AcceleratedCoursesAlert } from '@/app/components/AcceleratedCoursesAlert/AcceleratedCoursesAlert';
import { LinkButton } from '@/app/components/Button/LinkButton';
import { LessonPromotionAlert } from '@/app/components/LessonPromotionAlert/LessonPromotionAlert';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { usePromotionDescription } from '@/app/layout/Footer/usePromotionDescription';
import { PlatformBenefitCardComponent } from '@/app/modules/home/components/PlatformBenefitCardComponent';
import { PlatformWorkStepCardComponent } from '@/app/modules/home/components/PlatformWorkStepCardComponent';
import { MAIN_HOME_SECTIONS } from '@/app/modules/home/constants';
import { SMALL_BREAKPOINTS, useBreakpoint } from '@/app/utils/useBreakpoint';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { SubjectItem } from '../subject/index/SubjectItem/SubjectItem';
import { SubjectItemMobile } from '../subject/index/SubjectItem/SubjectItemMobile';
import { getTopSubjects } from '../subject/service';

import './MainHomePage.scss';

export function MainHomePage() {
  const breakpoint = useBreakpoint();
  const isSmallScreen = SMALL_BREAKPOINTS.includes(breakpoint);

  //#region Handle data
  const dispatch = useAppDispatch();
  const isTopSubjectsPreloaded = useAppSelector((state) => state.subject.isTopSubjectsPreloaded);
  const loading = useAppSelector((state) => state.subject.loading);
  const topSubjects = useAppSelector((state) => state.subject.topSubjects);
  const [loadingState, , , canRefetch] = useLoading(loading, isTopSubjectsPreloaded);
  const isEmpty = loadingState === LoadingState.Idle && topSubjects.length === 0;

  useEffect(() => {
    if (loading === LoadingState.Idle && canRefetch) {
      dispatch(getTopSubjects());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Handle data

  usePromotionDescription();

  return (
    <>
      <Helmet>
        <title>За платформата</title>
        <meta name="keywords" content="Частни уроци, Онлайн уроци, Уроци, Математика, БЕЛ, Английски език" />
        <meta
          name="description"
          content="Намерете топ обучител и заявете индивидуални онлайн уроци за ученици от всяка възраст. Учете онлайн. Каталог с най-добрите обучители"
        />
      </Helmet>
      <Page className="p-0 bg-white">
        <div className="home-header mb-8 mb-md-12">
          <LessonPromotionAlert className="mb-md-8" />
          <AcceleratedCoursesAlert />
          <Content className="hero-section pb-4 py-sm-8 mt-8 mt-sm-0">
            <div className="container">
              <Row className="mx-auto h-75">
                <Col sm={6} md={7} className="order-2 order-sm-1">
                  <div className="h-100 d-flex flex-column align-items-center align-items-sm-start justify-content-center">
                    <h1 className="fs-1x fs-sm-2x fs-lg-3x text-center text-sm-start mt-4 mt-sm-0">
                      Намерете топ обучител и заявете индивидуални онлайн уроци за ученици от всяка възраст
                    </h1>
                    {/* <LinkButton to="/subjects" variant="success" className="mt-4 mt-sm-8 btn-lg hero-section__cta">
                      Избери предмет
                    </LinkButton> */}
                  </div>
                </Col>
                <Col sm={6} md={5} className="order-1 order-sm-2">
                  <picture>
                    <source srcSet={toAbsoluteUrl('/img/home-pages/main/hero-section-image.webp')} type="image/webp" />
                    <img
                      src={toAbsoluteUrl('/img/home-pages/main/hero-section-image.jpg')}
                      className="hero-section__img img-fluid rounded"
                      width="1200"
                      height="800"
                      alt="За платформата"
                    />
                  </picture>
                </Col>
                {/* <Col xs={3}>
                  <div className="d-flex h-100 justify-content-start align-items-end">
                    <SVG src={toAbsoluteUrl('/img/svg/home-pages/1.svg')} />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex h-100 flex-column align-items-center justify-content-center">
                    <h1 className="text-gray-800 text-center fs-3x">Учи, за да си успешен!</h1>
                    <div className="mt-10 d-flex justify-content-center">
                      <LinkButton to="/subjects" variant="primary">
                        Към обученията
                      </LinkButton>
                    </div>
                  </div>
                </Col>
                <Col xs={3} className="mt-5">
                  <div className="d-flex h-100 justify-content-end">
                    <SVG src={toAbsoluteUrl('/img/svg/home-pages/2.svg')} />
                  </div>
                </Col> */}
              </Row>
            </div>
          </Content>
        </div>

        <Content className="mb-15">
          <PageTitle className="text-center fw-bolder fs-2x">Най-популярни предмети</PageTitle>
          {!isSmallScreen && (
            <Content isContainerContent>
              <Row className="g-9">
                {topSubjects.map((subject) => (
                  <Col sm={6} lg={4} xl={3} key={subject.id}>
                    <SubjectItem subject={subject} targetAudiences={[]} />
                  </Col>
                ))}

                {isEmpty && <p className="mb-0 fw-bold text-center">Няма намерени предмети.</p>}
              </Row>
            </Content>
          )}

          {isSmallScreen && (
            <Content className="p-0 pt-7">
              {topSubjects.map((subject) => (
                <SubjectItemMobile subject={subject} key={subject.id} />
              ))}

              {isEmpty && <p className="mb-0 fw-bold text-center">Няма намерени предмети.</p>}
            </Content>
          )}
          <Row className="mw-100 mx-auto">
            <LinkButton
              to="/subjects"
              variant="light-primary"
              className={clsx('mx-auto mt-5 w-fit-content', { 'w-100': isSmallScreen })}
            >
              Всички предмети
            </LinkButton>
          </Row>
        </Content>

        <Content className="mb-8 mb-md-12">
          <PageTitle className="text-center fw-bolder fs-2x">Как работи платформата</PageTitle>
          <Row className="g-7 g-md-9 mt-0 mt-md-2 mb-8 mb-md-12">
            <PlatformWorkStepCardComponent
              header={MAIN_HOME_SECTIONS.findCourse.header}
              paragraph={MAIN_HOME_SECTIONS.findCourse.paragraph}
              svg={MAIN_HOME_SECTIONS.findCourse.svg}
              step={1}
            />
            <PlatformWorkStepCardComponent
              header={MAIN_HOME_SECTIONS.reserveTime.header}
              paragraph={MAIN_HOME_SECTIONS.reserveTime.paragraph}
              svg={MAIN_HOME_SECTIONS.reserveTime.svg}
              step={2}
            />
            <PlatformWorkStepCardComponent
              header={MAIN_HOME_SECTIONS.studyRemote.header}
              paragraph={MAIN_HOME_SECTIONS.studyRemote.paragraph}
              svg={MAIN_HOME_SECTIONS.studyRemote.svg}
              step={3}
            />
          </Row>
          <Card className="bg-light-primary rounded-4">
            <div className="p-10 d-flex">
              <div className="catalogue">
                <h1 className="text-gray-700">Каталог с най-добрите обучители</h1>
                <p className="text-gray-600">
                  Списък с проверени обучители. Най-добрите в страната. Тук може да сте сигурни, че Вашето дете ще
                  получи качествена обучителна услуга и индивидуален подход.
                </p>
                <div className="d-flex align-items-center">
                  <Link to="/tutors" className="h5 text-primary fw-normal">
                    Виж обучителите
                    <i className="fas fa-arrow-right ms-2 text-primary" />
                  </Link>
                </div>
              </div>
              <div className="ms-auto">
                <SVG className="w-125px h-100px" src={toAbsoluteUrl('/img/svg/home-pages/tutors.svg')} />
              </div>
            </div>
          </Card>
          <PageTitle className="mt-8 mt-md-12 mb-8 mb-md-12 text-center fw-bolder fs-2x">
            Предимства на платформата
          </PageTitle>
          <Row className="g-7 g-md-9">
            <PlatformBenefitCardComponent
              header={MAIN_HOME_SECTIONS.intuitiveSearch.header}
              paragraph={MAIN_HOME_SECTIONS.intuitiveSearch.paragraph}
              image={MAIN_HOME_SECTIONS.intuitiveSearch.image}
              imageDimensions={MAIN_HOME_SECTIONS.intuitiveSearch.imageDimensions}
              webp={MAIN_HOME_SECTIONS.intuitiveSearch.webp}
            />
            <PlatformBenefitCardComponent
              header={MAIN_HOME_SECTIONS.participantsRating.header}
              paragraph={MAIN_HOME_SECTIONS.participantsRating.paragraph}
              image={MAIN_HOME_SECTIONS.participantsRating.image}
              imageDimensions={MAIN_HOME_SECTIONS.participantsRating.imageDimensions}
              webp={MAIN_HOME_SECTIONS.participantsRating.webp}
            />
            <PlatformBenefitCardComponent
              header={MAIN_HOME_SECTIONS.virtualClassRoom.header}
              paragraph={MAIN_HOME_SECTIONS.virtualClassRoom.paragraph}
              image={MAIN_HOME_SECTIONS.virtualClassRoom.image}
              imageDimensions={MAIN_HOME_SECTIONS.virtualClassRoom.imageDimensions}
              webp={MAIN_HOME_SECTIONS.virtualClassRoom.webp}
              hasVirtualClassDemo
            />
            <PlatformBenefitCardComponent
              header={MAIN_HOME_SECTIONS.interactiveBoard.header}
              paragraph={MAIN_HOME_SECTIONS.interactiveBoard.paragraph}
              image={MAIN_HOME_SECTIONS.interactiveBoard.image}
              imageDimensions={MAIN_HOME_SECTIONS.interactiveBoard.imageDimensions}
              webp={MAIN_HOME_SECTIONS.interactiveBoard.webp}
            />
            <PlatformBenefitCardComponent
              header={MAIN_HOME_SECTIONS.realTimeSchedule.header}
              paragraph={MAIN_HOME_SECTIONS.realTimeSchedule.paragraph}
              image={MAIN_HOME_SECTIONS.realTimeSchedule.image}
              imageDimensions={MAIN_HOME_SECTIONS.realTimeSchedule.imageDimensions}
              webp={MAIN_HOME_SECTIONS.realTimeSchedule.webp}
            />
            <PlatformBenefitCardComponent
              header={MAIN_HOME_SECTIONS.cardPayments.header}
              paragraph={MAIN_HOME_SECTIONS.cardPayments.paragraph}
              image={MAIN_HOME_SECTIONS.cardPayments.image}
              imageDimensions={MAIN_HOME_SECTIONS.cardPayments.imageDimensions}
              webp={MAIN_HOME_SECTIONS.cardPayments.webp}
            />
          </Row>
          <Card className="p-6 p-md-15 mt-8 mt-md-12 bg-primary action-banner">
            <div className="d-flex justify-content-center justify-content-md-between flex-column flex-md-row align-items-center flex-wrap">
              <div className="d-flex text-center text-md-start mb-2 mb-md-0">
                <h2 className="mb-5 mb-md-0 text-white fw-normal fs-2x action-banner__title">
                  Готов ли си да влезеш в света на знанията?
                </h2>
              </div>
              <div className="d-flex text-nowrap">
                <LinkButton to="/subjects" variant="white" className="btn-sm text-primary">
                  Избери предмет
                  <i className="fas fa-arrow-right ms-2 text-primary" />
                </LinkButton>
              </div>
            </div>
          </Card>
        </Content>
      </Page>
    </>
  );
}
