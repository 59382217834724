import { GoBackToDashboardLinkButton } from '@/app/modules/errors/ErrorBasePage/GoBackToDashboardLinkButton';

import { GENERAL_ERROR_MESSAGE } from './constants';
import { ErrorBasePage } from './ErrorBasePage/ErrorBasePage';

export function GeneralErrorPage() {
  return (
    <ErrorBasePage code={500} actions={<GoBackToDashboardLinkButton />}>
      {GENERAL_ERROR_MESSAGE}
      <br />
      Върнете се малко по-късно.
    </ErrorBasePage>
  );
}
