import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { KTSVG } from '@/_metronic/helpers';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { TutorStatusID } from '@/app/models/TutorStatusID';
import { useAppSelector } from '@/redux/store';

import './CoursePublishedPage.scss';

export function CoursePublishedPage() {
  const { id } = useParams<{ id: string }>();
  const { tutorStatusID } = useAppSelector((state) => state.auth);

  const isTutorWaitingApproval = tutorStatusID === TutorStatusID.WaitingApproval;
  let title = 'Вашето обучение е публикувано успешно';
  let subtitle = `Следете за предстоящи заявки за уроци. В бъдеще обновявайте своя график, за да сте сигурни, че винаги може
  да изпълнявате заявка за час.`;

  if (isTutorWaitingApproval) {
    title = 'Успешно добавихте обучение';
    subtitle = 'След като профилът Ви бъде одобрен, обучението ще бъде автоматично публикувано.';
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Page>
        <Content>
          <div className="mx-auto text-center course-published-page-container">
            <div className="my-10">
              <KTSVG path="/img/svg/BookmarkCheck.svg" className="svg-icon-5x" />
            </div>

            <h1 className="text-gray-800 fs-2tx">{title}</h1>

            <p className="text-gray-700 fs-4 mb-10">{subtitle}</p>

            <Container>
              <Row className="g-5">
                <Col md={6}>
                  <Link to="/profile" className="w-100 btn btn-light-primary">
                    Моят профил
                  </Link>
                </Col>
                <Col md={6}>
                  <Link to={`/profile/courses/${id}`} className="w-100 btn btn-light-primary btn-space">
                    Разгледай обучението
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>
        </Content>
      </Page>
    </>
  );
}
