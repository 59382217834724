import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { wrappedReactLazy } from '@/app/utils/wrapped-react-lazy';

import { TutorAboutDetailsShowPage } from './show/TutorAboutDetailsShowPage';

const TutorAboutDetailsEditPage = wrappedReactLazy(() => import('./edit/TutorAboutDetailsEditPage'));

export function ProfileAboutPage() {
  return (
    <Switch>
      <Route path="/profile/about" exact>
        <TutorAboutDetailsShowPage />
      </Route>
      <Route path="/profile/about/edit" exact>
        <TutorAboutDetailsEditPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
