import React, { MouseEvent, useMemo } from 'react';
import { useSlate } from 'slate-react';

import { isLinkActive, unwrapLink } from '@/app/components/RichTextControl/utils';

import { RichTextButton } from '../RichTextButton/RichTextButton';

type RemoveLinkButtonProps = {
  isDisabled?: boolean;
};

export function RemoveLinkButton({ isDisabled = false }: RemoveLinkButtonProps) {
  const editor = useSlate();

  function handleMouseDown(event: MouseEvent) {
    event.preventDefault();
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }
  }

  const isActive = useMemo(() => isLinkActive(editor), [editor]);

  return (
    <RichTextButton tooltip="Премахни връзка" isDisabled={isDisabled} isActive={isActive} onMouseDown={handleMouseDown}>
      <i className="fas fa-unlink" />
    </RichTextButton>
  );
}
