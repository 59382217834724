import { useEffect, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { CardBody } from '@/app/components/Card/CardBody';
import { CardHeader } from '@/app/components/Card/CardHeader';
import { CardSubTitle } from '@/app/components/Card/CardSubTitle';
import { CardTitle } from '@/app/components/Card/CardTitle';
import { CardToolbar } from '@/app/components/Card/CardToolbar';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { Table } from '@/app/components/Table/Table';
import { useQueryParams } from '@/app/utils/query';
import { useLoading } from '@/app/utils/useLoading';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import {
  DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
  DEFAULT_PAGE_VALUE,
  ITEMS_PER_PAGE_OPTIONS,
  ProfileProfitFilterInitialValues,
} from './constants';
import { MonthlyIncomeFilterQueryParams, MonthlyIncomeFilterValue } from './models';
import { ProfileProfitBasePage } from './ProfileProfitBasePage';
import { getProfileProfitMonthlyIncomeData } from './service';
import profileProfitSlice from './store';
import { formatIncomeAmount, transformProfileProfitFormikValuesToSearchParams } from './utils';

const columns: ColumnDescription[] = [
  {
    dataField: 'month',
    text: 'Месец',
  },
  {
    dataField: 'paidAmount',
    text: 'Приход от обучаеми',
    formatter: formatIncomeAmount,
  },
  {
    dataField: 'bonusAmount',
    text: 'Приход от Школо промоции',
    formatter: formatIncomeAmount,
  },
];

export function ProfileProfitMonthlyIncomePage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const content = useAppSelector((state) => state.profileProfit);
  const [loadingState, isInitialDataLoaded, setLoadingState] = useLoading(content.tabsLoading);
  const isLoading = loadingState === LoadingState.Pending;
  const { currentSearchParams, queryParams } = useQueryParams<MonthlyIncomeFilterQueryParams>();

  useEffect(
    function componentDidMount() {
      dispatch(getProfileProfitMonthlyIncomeData(queryParams));
    },
    [queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#region Handle pagination form
  const initialValues = useMemo<MonthlyIncomeFilterValue>(
    () => ({
      page: queryParams.page ?? DEFAULT_PAGE_VALUE,
      itemsPerPage: queryParams?.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE_FILTER_VALUE,
    }),
    [queryParams]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<MonthlyIncomeFilterValue>({
    initialValues,
    validationSchema,
    initialStatus: loadingState,
  });
  //#endregion Handle pagination form

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformProfileProfitFormikValuesToSearchParams(
        formik.values,
        formik.initialValues.itemsPerPage,
        queryParams
      );

      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
        setLoadingState(LoadingState.Pending);
      }

      if (newSearchParams === '') {
        formik.setValues(ProfileProfitFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  //#region Handle page change
  useEffect(
    function handlePageChange() {
      formik.setFieldValue('page', content.currentMonthlyIncomePage);
    },
    [content.currentMonthlyIncomePage] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle page change

  return (
    <ProfileProfitBasePage title="Месечни приходи">
      <CardHeader isRow>
        <div>
          <CardTitle>Месечни приходи</CardTitle>
          <CardSubTitle>Приходи, добавени към Вашия баланс след провеждане на урок по месеци.</CardSubTitle>
        </div>
        <CardToolbar>
          <Form formik={formik} skipDirtyPrompt>
            <FormGroup>
              <Label htmlFor="itemsPerPage" className="mb-0 me-3">
                Покажи по:
              </Label>
              <SelectControl
                name="itemsPerPage"
                options={ITEMS_PER_PAGE_OPTIONS}
                isSearchable={false}
                hasSolidBackground={false}
                className="w-175px"
              />
            </FormGroup>
          </Form>
        </CardToolbar>
      </CardHeader>
      <CardBody>
        {!isInitialDataLoaded && <Spinner animation="border" variant="primary" className="d-block mx-auto" />}
        {isInitialDataLoaded && (
          <Table
            columns={columns}
            data={content.monthlyIncome}
            isLoading={isLoading}
            setCurrentPageAction={profileProfitSlice.actions.setCurrentMonthlyIncomePage}
            noDataIndication={
              <>
                <p className="mb-0 text-center">Все още нямате приходи.</p>
              </>
            }
          />
        )}
      </CardBody>
      {isLoading && <GlobalSpinner />}
    </ProfileProfitBasePage>
  );
}
