import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { reduxBatch } from '@manaflair/redux-batch';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import authSlice, { createInitialState as createAuthInitialState } from '@/app/modules/auth/store';
import buyCourseLessonSlice from '@/app/modules/buy-course-lesson/store';
import courseSlice, { createInitialState as createCourseInitialState } from '@/app/modules/course/store';
import courseFeedbackSlice from '@/app/modules/course-feedback/store';
import drag from '@/app/modules/drag/store';
import enrolledCourseSlice from '@/app/modules/enrolled-course/store';
import errorsSlice from '@/app/modules/errors/store';
import favoriteCourseSlice from '@/app/modules/favorite-course/store';
import lessonSlice from '@/app/modules/lesson/store';
import messengerSlice from '@/app/modules/messenger/store';
import myScheduleSlice from '@/app/modules/my-schedule/store';
import notificationSlice from '@/app/modules/notification/store';
import orderSlice from '@/app/modules/order/store';
import platformSettingsSlice from '@/app/modules/platform-settings/store';
import cardSlice from '@/app/modules/profile/card/store';
import profileConnectedStudentSlice from '@/app/modules/profile/connected-student/store';
import profileCourseSlice from '@/app/modules/profile/course/store';
import profileDetailsSlice from '@/app/modules/profile/details/store';
import profileMyStudentSlice from '@/app/modules/profile/my-student/store';
import profileNotifications from '@/app/modules/profile/notifications/store';
import profilePaymentSlice from '@/app/modules/profile/payments/store';
import profileProfitSlice from '@/app/modules/profile/profit/store';
import profilePromoCodeSlice from '@/app/modules/profile/promo-codes/store';
import profileTimelineSlice from '@/app/modules/profile/timeline/store';
import promotionSlice, { createInitialState as createPromotionInitialState } from '@/app/modules/promotion/store';
import reservationGroupSlice from '@/app/modules/reservation-group/store';
import reservationProposalSlice from '@/app/modules/reservation-proposal/store';
import subjectSlice, { createInitialState as createSubjectInitialState } from '@/app/modules/subject/store';
import supportTicketSlice from '@/app/modules/support-ticket/store';
import testRoomSlice from '@/app/modules/test-room/store';
import tutorSlice from '@/app/modules/tutor/store';

export function createStore(preloadedState: Record<string, unknown> | undefined = undefined) {
  if (!preloadedState && window?.context) {
    preloadedState = {
      auth: createAuthInitialState(),
      course: createCourseInitialState(),
      promotion: createPromotionInitialState(),
      subject: createSubjectInitialState(),
    };
  }

  return configureStore({
    reducer: {
      auth: authSlice.reducer,
      errors: errorsSlice.reducer,
      drag: drag.reducer,
      course: courseSlice.reducer,
      tutor: tutorSlice.reducer,
      profileCourse: profileCourseSlice.reducer,
      profileDetails: profileDetailsSlice.reducer,
      profilePayment: profilePaymentSlice.reducer,
      profileProfit: profileProfitSlice.reducer,
      profileConnectedStudent: profileConnectedStudentSlice.reducer,
      profileTimeline: profileTimelineSlice.reducer,
      profileMyStudent: profileMyStudentSlice.reducer,
      profilePromoCode: profilePromoCodeSlice.reducer,
      profileNotifications: profileNotifications.reducer,
      subject: subjectSlice.reducer,
      messenger: messengerSlice.reducer,
      mySchedule: myScheduleSlice.reducer,
      notification: notificationSlice.reducer,
      enrolledCourse: enrolledCourseSlice.reducer,
      favoriteCourse: favoriteCourseSlice.reducer,
      order: orderSlice.reducer,
      card: cardSlice.reducer,
      lesson: lessonSlice.reducer,
      testRoom: testRoomSlice.reducer,
      reservationGroup: reservationGroupSlice.reducer,
      courseFeedback: courseFeedbackSlice.reducer,
      supportTicket: supportTicketSlice.reducer,
      promotion: promotionSlice.reducer,
      reservationProposal: reservationProposalSlice.reducer,
      buyCourseLesson: buyCourseLessonSlice.reducer,
      platformSettings: platformSettingsSlice.reducer,
    },
    devTools: import.meta.env.DEV,
    enhancers: [reduxBatch],
    preloadedState,
  });
}

export type RootStore = ReturnType<typeof createStore>;
export type RootState = ReturnType<RootStore['getState']>;
export type AppDispatch = RootStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
