import { Route, Switch } from 'react-router-dom';

import { CourseFreeStudentProposal } from '@/app/modules/course/reservation/CourseFreeStudentProposal';
import { NoMatch } from '@/app/modules/errors/NoMatch';
import { wrappedReactLazy } from '@/app/utils/wrapped-react-lazy';

import { PrivateRoute } from '../auth/PrivateRoute';
import { CourseCheckoutPage } from './checkout/CourseCheckoutPage';
import { CoursePublishedPage } from './create/CoursePublishedPage';
import { CourseReservationCanceledPage } from './reservation/CourseReservationCanceledPage';

const CourseCreatePage = wrappedReactLazy(() => import('./create/CourseCreatePage'));
const FirstCourseEditPage = wrappedReactLazy(() => import('./edit/FirstCourseEditPage'));
const CourseReservationPage = wrappedReactLazy(() => import('./reservation/CourseReservationPage'));
const CourseShowPage = wrappedReactLazy(() => import('./show/CourseShowPage'));

export function CoursePage() {
  return (
    <Switch>
      <PrivateRoute path="/courses/create" exact>
        <CourseCreatePage />
      </PrivateRoute>
      <PrivateRoute path="/courses/:transliteratedSlug/edit" exact>
        <FirstCourseEditPage />
      </PrivateRoute>
      <PrivateRoute path="/courses/published/:id" exact>
        <CoursePublishedPage />
      </PrivateRoute>
      <Route path="/courses/:transliteratedSlug" exact>
        <CourseShowPage />
      </Route>
      <PrivateRoute path="/courses/:transliteratedSlug/free-lesson" exact>
        <CourseShowPage />
      </PrivateRoute>
      <PrivateRoute path="/courses/:id/reservation" exact>
        <CourseReservationPage />
      </PrivateRoute>
      <PrivateRoute path="/courses/reservations/:reservationID" exact>
        <CourseReservationCanceledPage />
      </PrivateRoute>
      <PrivateRoute path="/courses/:id/reservation/free-student-proposal">
        <CourseFreeStudentProposal />
      </PrivateRoute>
      <PrivateRoute path="/courses/:transliteratedSlug/checkout" exact>
        <CourseCheckoutPage />
      </PrivateRoute>
      <Route component={NoMatch} />
    </Switch>
  );
}
