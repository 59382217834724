import produce from 'immer';

import { EnrolledCourseFilterQueryParams, EnrolledCourseFilterValues } from '@/app/modules/enrolled-course/models';
import { stringifyQuery } from '@/app/utils/query';

export function transformEnrolledCourseFormikValuesToSearchParams(
  formikValues: EnrolledCourseFilterValues,
  queryParams: EnrolledCourseFilterQueryParams
) {
  const newQueryParams: EnrolledCourseFilterQueryParams = produce(queryParams, (draft) => {
    if (formikValues.search !== '') {
      draft.search = formikValues.search;
    } else {
      delete draft.search;
    }

    if (formikValues.availableLessonsRange.length > 0) {
      draft.availableLessonsRange = formikValues.availableLessonsRange;
    } else {
      delete draft.availableLessonsRange;
    }
  });

  return stringifyQuery(newQueryParams);
}
