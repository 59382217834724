import { ReactNode } from 'react';
import { Form, FormGroupProps as BaseFormGroupProps } from 'react-bootstrap';
import clsx from 'clsx';

import './FormGroup.scss';

type FormGroupProps = BaseFormGroupProps & {
  children: ReactNode;
};

export function FormGroup({ children, className, ...props }: FormGroupProps) {
  return (
    <Form.Group {...props} className={clsx(className, 'form-group')}>
      {children}
    </Form.Group>
  );
}
