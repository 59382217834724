import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { LinkButton } from '@/app/components/Button/LinkButton';
import { DEFAULT_PROFILE_PICTURE_PATH } from '@/app/layout/constants';
import { CourseStatusID } from '@/app/models/CourseStatusID';
import { trackAnalyticsEventConversionsAPI } from '@/app/modules/analytics/service';
import { transformEcommerceEventToConversionEventValues } from '@/app/modules/analytics/utils';
import { EnrolledCourse } from '@/app/modules/enrolled-course/models';
import { FACEBOOK_PIXEL_ECOMMERCE_EVENTS } from '@/app/utils/facebook-pixel/constants';
import { formatInfoRating } from '@/app/utils/formatInfoRating';
import { tagAddToCartEvent } from '@/app/utils/google-analytics/ecommerceEvents';

import { MAX_RATING } from '../../course/constants';
import { handlePlDeprecatedPurchaseAction } from '../../course/utils';

import './EnrolledCourseItem.scss';

type EnrolledCourseItemProps = {
  course: EnrolledCourse;
};
export function EnrolledCourseItem({ course }: EnrolledCourseItemProps) {
  async function tagEnrolledCourseAddToCartEvent(e: React.MouseEvent<HTMLAnchorElement>) {
    handlePlDeprecatedPurchaseAction();
    e.preventDefault();

    const ecommerceItem = {
      id: Number(course.id),
      name: `${course.subjects?.[0].name} ${course.targetAudience} ${course.tutorName}`,
      price: Number(course.coursePackage.taxedPrice),
      brand: course.tutorName,
      variant: Number(course.coursePackage.lessonCount),
      quantity: 1,
      category: `${course.subjects?.[0].name}/${course.targetAudience}`,
      currency: 'BGN',
    };

    const conversionEventValues = await transformEcommerceEventToConversionEventValues(
      FACEBOOK_PIXEL_ECOMMERCE_EVENTS.addToCart,
      ecommerceItem
    );

    tagAddToCartEvent(ecommerceItem, conversionEventValues.eventID);

    await trackAnalyticsEventConversionsAPI(conversionEventValues);
  }

  return (
    <Card className="shadow-sm d-flex flex-column flex-sm-row align-items-stretch justify-content-between p-0">
      <Col
        sm={8}
        md={9}
        className="d-flex flex-wrap justify-content-center justify-content-sm-start flex-nowrap p-5 mt-n5"
      >
        <img
          src={course.tutorProfileImagePath ?? DEFAULT_PROFILE_PICTURE_PATH}
          alt="Профилна снимка"
          className="profile-image w-100px h-100px rounded mt-5"
        />
        <div className="d-flex flex-column align-items-center align-items-sm-start mx-5 mt-5">
          <Link
            to={`/courses/${course.courseTransliteratedSlug}`}
            className="d-block h4 text-center text-sm-start text-gray-700 mb-3 text-break"
          >
            {course.courseName}
          </Link>
          <div className="ms-n2 me-n2 mb-3 d-flex flex-wrap justify-content-center">
            <span className="badge bg-gray-200 text-gray-800 fs-4 fw-normal px-4 py-2 m-2">
              {formatInfoRating(course.rating)} / {MAX_RATING}
            </span>
            <span className="badge bg-gray-200 text-gray-800 fs-4 fw-normal px-4 py-2 m-2">
              {course.targetAudience}
            </span>
          </div>
          <Link to={`/tutors/${course.tutorTransliteratedSlug}`} className="h5 text-gray-700 fw-normal">
            {course.tutorName}
          </Link>
        </div>
      </Col>
      <Col sm={4} md={3} className="enrolled-course-item__actions">
        {Number(course.availableLessonCount) > 0 && (
          <>
            <h6 className="text-gray-700 fw-normal">
              Вие имате {Number(course.availableLessonCount) > 1 ? 'налични' : 'наличен'}
            </h6>
            <h3>
              {course.availableLessonCount} {Number(course.availableLessonCount) > 1 ? 'урока' : 'урок'}
            </h3>
            <LinkButton to={`/courses/${course.courseID}/reservation`} variant="primary" className="text-nowrap">
              Резервирайте час
            </LinkButton>
            {course.courseStatusID !== CourseStatusID.Archived && Boolean(course.hasAvailableFutureTimeSlots) && (
              <Link
                to={`/courses/${course.courseTransliteratedSlug}/checkout`}
                className="text-decoration-underline d-block mt-2 fs-5"
                target="_blank"
                onClick={tagEnrolledCourseAddToCartEvent}
              >
                Закупете още уроци
              </Link>
            )}
          </>
        )}
        {Number(course.availableLessonCount) === 0 && (
          <>
            <h6 className={clsx('text-gray-700 fw-normal mb-0', { 'mb-3': course.hasAvailableFutureTimeSlots })}>
              Нямате налични уроци за това обучение
            </h6>

            {course.courseStatusID !== CourseStatusID.Archived && Boolean(course.hasAvailableFutureTimeSlots) && (
              <LinkButton
                to={`/courses/${course.courseTransliteratedSlug}/checkout`}
                variant="light-primary"
                className="mt-4"
                onClick={tagEnrolledCourseAddToCartEvent}
              >
                Закупете уроци
              </LinkButton>
            )}

            {course.courseStatusID !== CourseStatusID.Archived && !course.hasAvailableFutureTimeSlots && (
              <LinkButton
                to={`/courses/${course.courseID}/reservation/free-student-proposal`}
                variant="light-primary"
                className="btn btn-sm mt-4"
              >
                Изпратете запитване за час
              </LinkButton>
            )}
          </>
        )}
      </Col>
    </Card>
  );
}
