import React from 'react';

export const wrappedReactLazy: typeof React.lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error) {
      console.error(error);
      window.location.reload();
      throw new Error('[wrappedReactLazy] never');
    }
  };

  return React.lazy(retryImport);
};
