import React from 'react';

import './Timeline.scss';

type TimelineProps = {
  children: React.ReactNode;
};

export function Timeline({ children }: TimelineProps) {
  return (
    <div className="timeline timeline-3">
      <div className="timeline-items">{children}</div>
    </div>
  );
}
