import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';
import { wrappedReactLazy } from '@/app/utils/wrapped-react-lazy';

import { ProfileDetailsShowPage } from './ProfileDetailsShowPage';

const ProfileDetailsEditPage = wrappedReactLazy(() => import('./ProfileDetailsEditPage'));

export function ProfileDetailsPage() {
  return (
    <Switch>
      <Route path="/profile/details" exact>
        <ProfileDetailsShowPage />
      </Route>
      <Route path="/profile/details/edit" exact>
        <ProfileDetailsEditPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
