import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { OrderDetailsData } from './models';

const ENDPOINT_PREFIX = '/ajax/orders';

export const ENDPOINTS = {
  details: (orderID: number) => `${ENDPOINT_PREFIX}/${orderID}`,
};

//#region Get Order details
export type GetOrderDetailsResponse = OrderDetailsData;

export const getOrderDetails = createAsyncThunk('order/getOrderDetails', async function (orderID: number) {
  const response = await axios.get<GetOrderDetailsResponse>(ENDPOINTS.details(orderID));

  return response.data;
});
//#endregion Get Order details
