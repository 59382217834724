import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '@/app/components/Button/Button';
import { EMAIL_NOTIFICATION_QUERY_PARAMETER } from '@/app/modules/auth/constants';
import { useAppSelector } from '@/redux/store';

export function LinkIsPersonalizedForOtherUserModal() {
  const { email, isAuthenticated } = useAppSelector((state) => state.auth);
  const { search } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(search);
  const queryEmail = queryParams.get(EMAIL_NOTIFICATION_QUERY_PARAMETER);
  const [isShown, setIsShown] = useState(false);
  const from = encodeURIComponent(window.location.pathname + window.location.search);
  const emailEncoded = encodeURIComponent(queryEmail ?? '');

  useEffect(() => {
    if (queryEmail && queryEmail !== email) {
      setIsShown(true);
    }
  }, [email, queryEmail]);

  function removeEmailNotificationQueryParameter() {
    queryParams.delete(EMAIL_NOTIFICATION_QUERY_PARAMETER);
    const newSearch = queryParams.toString();
    history.replace({
      search: newSearch,
    });
  }

  function handleOnHide() {
    setIsShown(false);
    removeEmailNotificationQueryParameter();
  }

  return (
    <Modal
      show={isShown}
      onHide={handleOnHide}
      backdrop="static"
      backdropClassName="modal-backdrop--blurred"
      keyboard={false}
      className="d-block"
    >
      <Modal.Header className="border-0 pb-0" closeButton />
      <Modal.Body className="text-center">
        Тази препратка е персонализирана за потребител с имейл {queryEmail}. Влезте с този профил.
      </Modal.Body>
      <div className="d-flex justify-content-center mb-7">
        <Button onClick={handleOnHide} className="btn btn-light-primary me-2 mw-200px d-block">
          Отказ
        </Button>
        <Button
          href={
            isAuthenticated
              ? `/auth/logout-redirect-to-log-in?from=${from}&notificationEmail=${emailEncoded}`
              : `/auth?from=${from}&notificationEmail=${emailEncoded}`
          }
          className="btn btn-primary ms-2 mw-200px d-block"
        >
          Влез в профил
        </Button>
      </div>
    </Modal>
  );
}
