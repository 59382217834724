import { Route, Switch } from 'react-router-dom';

import { NoMatch } from '@/app/modules/errors/NoMatch';

import { TutorBankDetailsEditPage } from './edit/TutorBankDetailsEditPage';
import { TutorBankDetailsShowPage } from './show/TutorBankDetailsShowPage';

export function ProfileBankDetailsPage() {
  return (
    <Switch>
      <Route path="/profile/bank-details" exact>
        <TutorBankDetailsShowPage />
      </Route>
      <Route path="/profile/bank-details/edit" exact>
        <TutorBankDetailsEditPage />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
}
