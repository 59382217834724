import IntervalTree from '@flatten-js/interval-tree';
import { EventApi, EventContentArg } from '@fullcalendar/react';
import clsx from 'clsx';
import cuid from 'cuid';
import {
  add,
  addDays,
  addMinutes,
  differenceInMinutes,
  endOfDay,
  format,
  getDay,
  isAfter,
  isBefore,
  isEqual,
  isPast,
  parse,
  roundToNearestMinutes,
  subDays,
} from 'date-fns';
import produce from 'immer';

import { PROPOSED_RESERVATION_STATUS_IDS, WEEKLY_RECURRENCE_TYPE_IDS } from '@/app/components/Calendar/constants';
import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_TIME_FORMAT,
  END_OF_DAY_TIME_IN_HOURS,
  START_OF_DAY_TIME_IN_HOURS,
} from '@/app/components/Form/DatePickerControl/constants';

import { EventInterval } from './EventInterval';
import {
  CalendarEvent,
  CalendarEventFormValues,
  createDailyCalendarEvent,
  createDailyIntervalCalendarEvent,
  createNotRepeatingCalendarEvent,
  createWeeklyCalendarEvent,
  createWeeklyWithDailyIntervalCalendarEvent,
  EditableTimeSlotEntity,
  RecurrenceTypeID,
  ReservationStatusID,
  ReservedTimeSlot,
  TimeSlotProposalPriceTypeID,
  TimeSlotStatusID,
  WeekDay,
} from './models';

export function getNearestDate(nearestTo = 30) {
  return roundToNearestMinutes(new Date(), { nearestTo });
}

export function transformEventFormValuesToCalendarEvent(
  values: CalendarEventFormValues,
  duration: number
): CalendarEvent {
  switch (Number(values.recurrenceTypeID)) {
    case RecurrenceTypeID.Weekly: {
      let startDateTime = new Date(values.startDateTime.getTime());
      startDateTime.setHours(values.startHour.getHours());
      startDateTime.setMinutes(values.startHour.getMinutes());
      const endDateTime = add(startDateTime, { minutes: duration });

      // Iterate while we find the first available date and use that date to set the value of startRecur
      while (isBefore(startDateTime, values.endRecur as Date) || isEqual(startDateTime, values.endRecur as Date)) {
        // Skip this date if this is a weekly recurring time slot and today is not included in `daysOfWeek`.
        const daysOfWeek = values.daysOfWeek?.map?.((dayOfWeek) => Number(dayOfWeek));
        const currentDayOfWeek = getDay(startDateTime);

        if (!daysOfWeek?.includes(currentDayOfWeek)) {
          startDateTime = addDays(startDateTime, 1);
          continue;
        }

        if (startDateTime) {
          break;
        }
      }

      return createWeeklyCalendarEvent(
        String(values.id ?? cuid()),
        format(startDateTime, DEFAULT_TIME_FORMAT),
        format(endDateTime, DEFAULT_TIME_FORMAT),
        startDateTime,
        values.endRecur as Date,
        values.daysOfWeek as WeekDay[],
        Number(duration),
        values.timeSlots as ReservedTimeSlot[],
        values.recurringTimeSlotID,
        values.hasProposal,
        values.proposalStudent,
        values.proposalCourse,
        Number(values.proposalPriceType),
        values.proposalPrice,
        values.isFreeProposal
      );
    }
    case RecurrenceTypeID.Daily: {
      const startDateTime = values.startDateTime;
      startDateTime.setHours(values.startHour.getHours());
      startDateTime.setMinutes(values.startHour.getMinutes());

      const endDateTime = add(startDateTime, { minutes: duration });

      return createDailyCalendarEvent(
        String(values.id ?? cuid()),
        format(startDateTime, DEFAULT_TIME_FORMAT),
        format(endDateTime, DEFAULT_TIME_FORMAT),
        startDateTime,
        values.endRecur as Date,
        Number(duration),
        values.timeSlots as ReservedTimeSlot[],
        values.recurringTimeSlotID,
        values.hasProposal,
        values.proposalStudent,
        values.proposalCourse,
        Number(values.proposalPriceType),
        values.proposalPrice,
        values.isFreeProposal
      );
    }
    case RecurrenceTypeID.DailyInterval: {
      // Calculate duration in minutes for the whole event for a day. Add that value to the start day time of the event to get end date time for the day.
      const minutesToAdd = calculateIntervalCalendarEventDayDuration(
        duration,
        values.count as number | string,
        values.interval as number | string
      );
      const startDateTime = values.startDateTime;
      startDateTime.setHours(values.startHour.getHours());
      startDateTime.setMinutes(values.startHour.getMinutes());

      const endRecur = add(startDateTime, { minutes: minutesToAdd });

      return createDailyIntervalCalendarEvent(
        String(values.id ?? cuid()),
        format(startDateTime as Date, DEFAULT_TIME_FORMAT),
        format(endRecur as Date, DEFAULT_TIME_FORMAT),
        startDateTime as Date,
        endRecur,
        Number(values.count),
        Number(values.interval),
        Number(duration),
        values.timeSlots as ReservedTimeSlot[],
        values.recurringTimeSlotID,
        values.hasProposal,
        values.proposalStudent,
        values.proposalCourse,
        Number(values.proposalPriceType),
        values.proposalPrice,
        values.isFreeProposal
      );
    }
    case RecurrenceTypeID.WeeklyInterval: {
      const startDateTime = values.startDateTime;
      startDateTime.setHours(values.startHour.getHours());
      startDateTime.setMinutes(values.startHour.getMinutes());

      // Calculate duration in minutes for the whole event for a day. Add that value to the start day time of the event to get end date time for the day.
      const minutesToAdd = calculateIntervalCalendarEventDayDuration(
        duration,
        values.count as number,
        values.interval as number
      );
      let endRecur = endOfDay(values.endRecur as Date);
      const daysOfWeek = values.daysOfWeek?.map?.((dayOfWeek) => Number(dayOfWeek));
      let currentDate = new Date(startDateTime.getTime());

      while (isBefore(currentDate, endRecur || isEqual(currentDate, endRecur))) {
        const currentDayOfWeek = getDay(currentDate);

        if (!daysOfWeek?.includes(currentDayOfWeek)) {
          currentDate = addDays(currentDate, 1);
          continue;
        }
        currentDate = addDays(currentDate, 1);
      }

      endRecur = subDays(currentDate, 1);
      endRecur = add(endRecur, { minutes: minutesToAdd });

      const id = String(values.id ?? cuid());

      return createWeeklyWithDailyIntervalCalendarEvent(
        id,
        format(startDateTime, DEFAULT_TIME_FORMAT),
        format(endRecur, DEFAULT_TIME_FORMAT),
        startDateTime as Date,
        endRecur,
        values.daysOfWeek as WeekDay[],
        Number(values.count),
        Number(values.interval),
        Number(duration),
        values.timeSlots as ReservedTimeSlot[],
        values.recurringTimeSlotID,
        values.hasProposal,
        values.proposalStudent,
        values.proposalCourse,
        Number(values.proposalPriceType),
        values.proposalPrice,
        values.isFreeProposal
      );
    }
    case RecurrenceTypeID.Never:
    default: {
      const startDateTime = values.startDateTime;
      startDateTime.setHours(values.startHour.getHours());
      startDateTime.setMinutes(values.startHour.getMinutes());

      return createNotRepeatingCalendarEvent(
        String(values.id ?? cuid()),
        startDateTime,
        add(startDateTime, { minutes: duration }),
        Number(duration),
        values.timeSlots,
        null,
        values.recurringTimeSlotID,
        values.hasProposal,
        values.proposalStudent,
        values.proposalCourse,
        Number(values.proposalPriceType),
        values.proposalPrice,
        values.isFreeProposal
      );
    }
  }
}

export function transformCalendarEventToFormValues(calendarEvent: CalendarEvent): CalendarEventFormValues {
  const recurrenceTypeID = calendarEvent.extendedProps?.recurrenceTypeID as RecurrenceTypeID;

  const formValues: CalendarEventFormValues = {
    id: calendarEvent.id,
    recurrenceTypeID: recurrenceTypeID,
    recurringTimeSlotID: calendarEvent.extendedProps?.recurringTimeSlotID as number | string,
    startDateTime: new Date(calendarEvent.start as Date),
    daysOfWeek: [],
    startHour: new Date(calendarEvent.start as Date),
    recurrenceTypes: [],
    count: calendarEvent.extendedProps?.count as number,
    interval: calendarEvent.extendedProps?.interval as number,
    recurringStartRecur: parse(calendarEvent.extendedProps?.startRecur as string, DEFAULT_DATETIME_FORMAT, new Date()),
    hasProposal: (calendarEvent.extendedProps?.hasProposal as number) ?? 0,
    hasInitialProposal: (calendarEvent.extendedProps?.hasProposal as number) ?? 0,
    proposalPrice: calendarEvent.extendedProps?.proposalPrice as number,
    isFreeProposal: calendarEvent.extendedProps?.isFreeProposal as boolean,
  };

  if (formValues.proposalPrice && !formValues.isFreeProposal) {
    formValues.proposalPriceType = TimeSlotProposalPriceTypeID.Special;
  } else if (!formValues.isFreeProposal) {
    formValues.proposalPriceType = TimeSlotProposalPriceTypeID.Standard;
  } else {
    formValues.proposalPriceType = TimeSlotProposalPriceTypeID.Free;
  }

  if (recurrenceTypeID === RecurrenceTypeID.Never) {
    formValues.startDateTime = new Date(calendarEvent.start as Date);
    formValues.startHour = new Date(calendarEvent.start as Date);
  }

  if (recurrenceTypeID === RecurrenceTypeID.Daily) {
    formValues.startDateTime = new Date(calendarEvent.start as Date);
    formValues.startHour = new Date(calendarEvent.start as Date);
    formValues.endRecur = parse(calendarEvent.extendedProps?.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date());
  }

  if (recurrenceTypeID === RecurrenceTypeID.Weekly) {
    formValues.recurrenceTypes.push(recurrenceTypeID);
    formValues.startDateTime = new Date(calendarEvent.start as Date);
    formValues.startHour = new Date(calendarEvent.start as Date);
    formValues.endRecur = parse(calendarEvent.extendedProps?.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date());
    formValues.daysOfWeek = calendarEvent.extendedProps?.daysOfWeek as WeekDay[];
  }

  if (recurrenceTypeID === RecurrenceTypeID.DailyInterval) {
    formValues.recurrenceTypes.push(recurrenceTypeID);
    formValues.startDateTime = new Date(calendarEvent.start as Date);
    formValues.startHour = new Date(calendarEvent.start as Date);
  }

  if (recurrenceTypeID === RecurrenceTypeID.WeeklyInterval) {
    formValues.recurrenceTypes.push(RecurrenceTypeID.DailyInterval);
    formValues.recurrenceTypes.push(RecurrenceTypeID.Weekly);
    formValues.startDateTime = new Date(calendarEvent.start as Date);
    formValues.startHour = new Date(calendarEvent.start as Date);
    formValues.endRecur = parse(calendarEvent.extendedProps?.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date());
    formValues.daysOfWeek = calendarEvent.extendedProps?.daysOfWeek as WeekDay[];
  }

  if (formValues.hasProposal) {
    formValues.proposalStudent = {
      id: calendarEvent.extendedProps?.reservationStudentID as number,
      text: calendarEvent.extendedProps?.reservationStudentName as string,
    };

    formValues.proposalCourse = {
      id: calendarEvent.extendedProps?.reservationCourseID as number,
      text: calendarEvent.extendedProps?.reservationCourseName as string,
    };
  }

  return formValues;
}

export function transformCalendarEventToIntervals(event: CalendarEvent): EventInterval[] {
  const intervals: EventInterval[] = [];
  const recurringID = event.extendedProps?.recurringTimeSlotID;

  // CASE 1: Never recurring event.
  if (event.recurrenceTypeID === RecurrenceTypeID.Never) {
    intervals.push(new EventInterval(event.id, recurringID as string | number, event.start, event.end));

    return intervals;
  }

  if (event.recurrenceTypeID === RecurrenceTypeID.DailyInterval) {
    intervals.push(
      new EventInterval(event.id, recurringID as string | number, event.startRecur, event.endRecur as Date)
    );

    return intervals;
  }

  // CASE 2.2. Recurring events -> loop days until current date is equal to the end recurrence date.
  const startRecurDate = event.startRecur ?? event.start ?? new Date();
  const endRecurDate = event.endRecur ?? event.end ?? new Date();
  const startTime = parse(event.startTime ?? '', DEFAULT_TIME_FORMAT, new Date());
  const endTime = parse(event.endTime ?? '', DEFAULT_TIME_FORMAT, new Date());
  const daysOfWeek = event.daysOfWeek?.map?.((dayOfWeek) => Number(dayOfWeek));

  let duration = differenceInMinutes(endTime, startTime);
  if (event.recurrenceTypeID === RecurrenceTypeID.WeeklyInterval) {
    duration = calculateIntervalCalendarEventDayDuration(
      event.duration as number,
      event.count as number,
      event.interval as number
    );
  }

  let currentDate = new Date(startRecurDate.getTime());

  while (isBefore(currentDate, endRecurDate) || isEqual(currentDate, endRecurDate)) {
    // Skip this date if this is a weekly recurring time event and today is not included in `daysOfWeek`.
    const isWeeklyRecurring = WEEKLY_RECURRENCE_TYPE_IDS.includes(event.recurrenceTypeID);
    const currentDayOfWeek = getDay(currentDate);

    if (isWeeklyRecurring && !daysOfWeek?.includes(currentDayOfWeek)) {
      currentDate = addDays(currentDate, 1);
      continue;
    }

    const endDate = addMinutes(currentDate, duration);

    intervals.push(new EventInterval(event.id, recurringID as string | number, currentDate, endDate));

    currentDate = addDays(currentDate, 1);
  }

  return intervals;
}

export function transformCalendarEventsToIntervalTree(events: CalendarEvent[]): IntervalTree<EventInterval> {
  const intervalTree = new IntervalTree();

  for (const event of events) {
    const intervals = transformCalendarEventToIntervals(event);

    for (const interval of intervals) {
      intervalTree.insert(interval.getInterval(), interval.getID());
    }
  }

  return intervalTree;
}

export function isCalendarEventOverlapAnyIntervalTreeNode(event: CalendarEvent, intervalTree: IntervalTree) {
  const intervals = transformCalendarEventToIntervals(event);

  for (const interval of intervals) {
    // STEP 1: Find intersected Event IDs.
    const intersectedIntervalEventIDs = intervalTree.search(interval.getInterval());

    // STEP 2: Go through intersected Event IDs and check if they are different Event.
    for (const intersectedIntervalEventID of intersectedIntervalEventIDs) {
      // CASE 2.1: If it is the same Event -> skip.
      if (
        intersectedIntervalEventID.includes(event.id) ||
        intersectedIntervalEventID.includes(event.recurringTimeSlotID)
      ) {
        continue;
      }

      // CASE 2.2: Different Event -> overlap.
      return true;
    }
  }

  return false;
}

export function hasOverlappingCalendarEventsOnDurationChange(
  calendarEvents: CalendarEvent[],
  durationDifference: number
) {
  const intervalTree = new IntervalTree();
  for (const calendarEvent of calendarEvents) {
    const durationUpdatedCalendarEvent = produce(calendarEvent, (draft) => {
      draft.end = add(calendarEvent.end as Date, { minutes: durationDifference });
    });
    const interval = new EventInterval(
      durationUpdatedCalendarEvent.id,
      durationUpdatedCalendarEvent.recurringTimeSlotID,
      durationUpdatedCalendarEvent.start as Date,
      durationUpdatedCalendarEvent.end as Date
    );
    const intersectedIntervalEventIDs = intervalTree.search(interval.getInterval()) as string[];
    if (
      intersectedIntervalEventIDs.length !== 0 &&
      !intersectedIntervalEventIDs.includes(durationUpdatedCalendarEvent.id as string)
    ) {
      return true;
    }
    intervalTree.insert(interval.getInterval(), interval.getID());
  }
  return false;
}

export function hasOverlappingCalendarEventsOnIntervalIndividualTimeSlotChange(
  initialTimeSlot: EditableTimeSlotEntity,
  changedCalendarEvent: CalendarEvent
) {
  // Step 1. Get start and end recur of the whole recurring event
  const startRecur = parse(initialTimeSlot.startRecur as string, DEFAULT_DATETIME_FORMAT, new Date());
  const endRecur = parse(initialTimeSlot.endRecur as string, DEFAULT_DATETIME_FORMAT, new Date());

  // Step 2. Get selected individual time slot start and end date time before any changes were made (used below to skip overlap validation for self)
  const initialIndividualEventStartDateTime = parse(
    initialTimeSlot.startDateTime as string,
    DEFAULT_DATETIME_FORMAT,
    new Date()
  );
  const initialIndividualEventEndDateTime = parse(
    initialTimeSlot.endDateTime as string,
    DEFAULT_DATETIME_FORMAT,
    new Date()
  );
  // Step 3. Get selected individual time slot new start and end date time
  const individualEventStartDateTime = changedCalendarEvent.startRecur as Date;
  const individualEventEndDateTime = add(individualEventStartDateTime, {
    minutes: Number(initialTimeSlot.duration),
  });

  // Step 4. Set current date as starting point and get week days if there are any
  let currentDate = new Date(startRecur);
  const daysOfWeek = initialTimeSlot.daysOfWeek?.map?.((dayOfWeek) => Number(dayOfWeek));

  // Step 5. Iterate through the recurring time slot, until end recur date is reached
  while (isBefore(currentDate, endRecur)) {
    const isWeeklyRecurring = WEEKLY_RECURRENCE_TYPE_IDS.includes(initialTimeSlot.recurrenceTypeID);
    const currentDayOfWeek = getDay(currentDate);

    // Step 5.1. If weekly recurring and recurring doesn't have that day, add 1 day to current date iterator and continue
    if (isWeeklyRecurring && !daysOfWeek?.includes(currentDayOfWeek)) {
      currentDate = addDays(currentDate, 1);
      continue;
    }
    // Step 5.2. Calculate end datetime for that current day and sum of interval + duration as iterator increment value, until end datetime for the current day is reached
    const minutesToAdd = Number(initialTimeSlot.interval) + Number(initialTimeSlot.duration);
    const currentDateEndHour = new Date(currentDate);
    if (initialTimeSlot.recurrenceTypeID === RecurrenceTypeID.Weekly) {
      addMinutes(currentDateEndHour, Number(initialTimeSlot.duration));
    } else {
      currentDateEndHour.setHours(endRecur.getHours());
      currentDateEndHour.setMinutes(endRecur.getMinutes());
    }

    // Step 5.3. Calculate each time slot end datetime
    const timeSlotEndTime = add(currentDate, { minutes: Number(initialTimeSlot.duration) });

    // Step 5.4. Check if we are validating same time slot as initial time slot, and if that is true => skip that check and increment current date
    if (
      isEqual(currentDate, initialIndividualEventStartDateTime) &&
      isEqual(timeSlotEndTime, initialIndividualEventEndDateTime)
    ) {
      // Step 5.4.1. Increment by sum of duration + interval for both daily_interval and weekly_interval
      currentDate = addMinutes(currentDate, minutesToAdd);
      // Step 5.4.2. Increment current date by one day and set its` time to start recur time
      if (isWeeklyRecurring && (isEqual(currentDate, currentDateEndHour) || isAfter(currentDate, currentDateEndHour))) {
        currentDate = addDays(currentDate, 1);
        currentDate.setHours(startRecur.getHours());
        currentDate.setMinutes(startRecur.getMinutes());
      }
      continue;
    }

    // Step 5.5. Check if edited individual time slot's new start datetime is between currently validated time slot from the iterator
    // Subtract a second from the validated time slot end to handle persisting time slots that start when other time slots end (0 interval between)
    // If new start is between, there's an overlap between those events => return true
    if (
      individualEventStartDateTime.getTime() >= currentDate.getTime() &&
      individualEventStartDateTime.getTime() <= timeSlotEndTime.getTime() - 1
    ) {
      return true;
    }

    // Step 5.6. Check if edited individual time slot's new end datetime is between currently validated time slot from the iterator
    // Subtract a second from the edited time slot new end to handle persisting time slots that start when other time slots end (0 interval between)
    // If new end is between, there's an overlap between those events => return true
    if (
      individualEventEndDateTime.getTime() - 1 >= currentDate.getTime() &&
      individualEventEndDateTime.getTime() <= timeSlotEndTime.getTime()
    ) {
      return true;
    }

    // Step 5.7 Increment by sum of duration + interval for both daily_interval and weekly_interval.
    // Increment current date by one day and set its` time to start recur time
    currentDate = addMinutes(currentDate, minutesToAdd);
    if (isWeeklyRecurring && (isEqual(currentDate, currentDateEndHour) || isAfter(currentDate, currentDateEndHour))) {
      currentDate = addDays(currentDate, 1);
      currentDate.setHours(startRecur.getHours());
      currentDate.setMinutes(startRecur.getMinutes());
    }
  }

  // Step 6. No overlaps were found => return false
  return false;
}

export function hasTimeSlotsOutOfDayLimit(calendarEvent: CalendarEvent) {
  const startRecur = calendarEvent.startRecur as Date;
  const endRecur = calendarEvent.endRecur as Date;

  // If end recur time of the whole recurring event  in hours is between 22:00 - 06:00 => event has at least one time slot out of day limit
  if (endRecur.getHours() > END_OF_DAY_TIME_IN_HOURS || endRecur.getHours() <= START_OF_DAY_TIME_IN_HOURS) {
    return true;
  }

  // If event is of type 'daily_interval' and start recur date is different from end recur date => event has at least one time slot after 00:00 (outside of day limit)
  if (calendarEvent.recurrenceTypeID === RecurrenceTypeID.DailyInterval) {
    return startRecur.getDate() !== endRecur.getDate();
  }

  if (calendarEvent.recurrenceTypeID === RecurrenceTypeID.WeeklyInterval) {
    let currentDate = new Date(startRecur);
    const daysOfWeek = calendarEvent.daysOfWeek?.map?.((dayOfWeek) => Number(dayOfWeek));

    // Duration for the whole day of the event - all time slots with their duration and interval time, needed to calculate each day's end date time
    const duration = calculateIntervalCalendarEventDayDuration(
      calendarEvent.duration as number,
      calendarEvent.count as number,
      calendarEvent.interval as number
    );

    // End date time of each day of the weekly interval event
    let currentDateEndDate = addMinutes(currentDate, duration);

    while (isBefore(currentDate, endRecur) || isEqual(currentDate, endRecur)) {
      // Skip this date if today is not included in `daysOfWeek`.
      const currentDayOfWeek = getDay(currentDate);

      if (!daysOfWeek?.includes(currentDayOfWeek)) {
        currentDate = addDays(currentDate, 1);
        currentDateEndDate = addDays(currentDateEndDate, 1);
        continue;
      }

      // For early return before checking each time slot separately, for every day of the weekly interval event check if its start date (of the current day)
      // and end date (of the current day) are the same day. If they are different dates => event has at least one time slot after 00:00 (outside of day limit)
      if (currentDate.getDate() !== currentDateEndDate.getDate()) {
        return true;
      }

      // Check each time slot separately if its start date time or end date time is out of day limit hours
      const minutesToAdd = Number(calendarEvent.duration) + Number(calendarEvent.interval);
      const endDateTime = addMinutes(currentDate, Number(calendarEvent.duration));

      if (currentDate.getHours() > END_OF_DAY_TIME_IN_HOURS || currentDate.getHours() < START_OF_DAY_TIME_IN_HOURS) {
        return true;
      }

      if (endDateTime.getHours() > END_OF_DAY_TIME_IN_HOURS || endDateTime.getHours() < START_OF_DAY_TIME_IN_HOURS) {
        return true;
      }

      currentDate = addMinutes(currentDate, minutesToAdd);

      // If end date for that current day is reached, add one day to go on the next day of the weekly interval calendar event
      if (isAfter(currentDate, currentDateEndDate) || isEqual(currentDate, currentDateEndDate)) {
        currentDate = addDays(currentDate, 1);
        currentDate.setHours(startRecur.getHours());
        currentDate.setMinutes(startRecur.getMinutes());
        currentDateEndDate = addDays(currentDateEndDate, 1);
      }
    }
  }

  return false;
}

function getTimeSlotStatusClassName(event: EventApi, studentID: number | null = null) {
  let extendedProps = event.extendedProps;
  if (event.extendedProps.timeSlots) {
    const timeSlots = event.extendedProps.timeSlots as ReservedTimeSlot[];

    const timeSlot = timeSlots?.find((x) =>
      isEqual(event.start as Date, parse(x.startDate as string, DEFAULT_DATETIME_FORMAT, new Date()))
    );

    if (timeSlot) {
      extendedProps = timeSlot;
    }
  }

  if (extendedProps.isHoliday) {
    return 'fc-event--holiday';
  }

  if (
    extendedProps.reservationStatusID === ReservationStatusID.Rejected &&
    extendedProps.reservationStudentID === studentID
  ) {
    return 'fc-event--rejected';
  }

  if (event.start !== null && isPast(event.start)) {
    return 'fc-event--done';
  }

  if (
    extendedProps.timeSlotStatusID === TimeSlotStatusID.Available &&
    extendedProps.reservationStatusID !== ReservationStatusID.Approved &&
    !extendedProps.isSelected
  ) {
    return 'fc-event--available';
  }

  if (
    extendedProps.timeSlotStatusID === TimeSlotStatusID.Available &&
    extendedProps.reservationStatusID !== ReservationStatusID.Approved &&
    extendedProps.isSelected
  ) {
    return 'fc-event--reserved';
  }

  if (
    extendedProps.timeSlotStatusID === TimeSlotStatusID.Unavailable &&
    extendedProps.reservationStatusID === ReservationStatusID.Approved &&
    (extendedProps.reservationStudentID === studentID || extendedProps.isTutor)
  ) {
    return 'fc-event--approved';
  }

  if (
    extendedProps.timeSlotStatusID === TimeSlotStatusID.Unavailable &&
    extendedProps.reservationStatusID === ReservationStatusID.WaitingApproval &&
    (extendedProps.reservationStudentID === studentID || extendedProps.isTutor)
  ) {
    return 'fc-event--waiting-approval';
  }

  if (
    PROPOSED_RESERVATION_STATUS_IDS.includes(extendedProps.reservationStatusID) &&
    extendedProps.timeSlotStatusID === TimeSlotStatusID.Unavailable &&
    (extendedProps.reservationStudentID === studentID || extendedProps.isTutor)
  ) {
    return 'fc-event--reservation-proposal';
  }

  return 'fc-event--done';
}

type ClassNameGeneratorConfig = {
  studentID?: number | null;
  isClickable?: boolean;
  isIdentifiable?: boolean;
};

export function eventClassNameGenerator({
  studentID,
  isClickable = false,
  isIdentifiable = false,
}: ClassNameGeneratorConfig = {}) {
  const baseClassName = clsx({
    'fc-event--clickable': isClickable,
  });

  return ({ event }: EventContentArg) => {
    const timeSlotStatusClassName = getTimeSlotStatusClassName(event, studentID);

    return clsx(baseClassName, timeSlotStatusClassName, {
      'fc-event--selected': event.extendedProps.isSelected ?? false,
      [generateIdentifiableEventClassName(event.id)]: isIdentifiable,
    });
  };
}

export function getIntervalFormat(start?: Date | null, end?: Date | null): string {
  // Sanity check.
  if (start === null || start === undefined || end == null || end === undefined) {
    return '';
  }

  const startTime = format(start, DEFAULT_TIME_FORMAT);
  const endTime = format(end, DEFAULT_TIME_FORMAT);

  return `${startTime} - ${endTime}`;
}

export function generateIdentifiableEventClassName(id: string) {
  return `fc-event--${id}`;
}

export function calculateIntervalCalendarEventDayDuration(
  duration: number | string,
  count: number | string,
  interval: number | string
): number {
  return Number(count) * (Number(interval) + Number(duration)) - Number(interval);
}
