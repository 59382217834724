import { createSlice } from '@reduxjs/toolkit';

import { ProfilePromoCode } from '@/app/modules/profile/promo-codes/models';
import { getProfilePromoCodes } from '@/app/modules/profile/promo-codes/services';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

interface ProfilePromoCodeState {
  loading: LoadingState;
  error?: string | null;
  promoCodes: ProfilePromoCode[];
}

const initialState: ProfilePromoCodeState = {
  loading: LoadingState.Idle,
  error: null,
  promoCodes: [],
};

const profilePromoCodeSlice = createSlice({
  name: 'profilePromoCode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region GET PROFILE PROMO CODE INDEX
    builder
      .addCase(getProfilePromoCodes.pending, sharedPendingReducer)
      .addCase(getProfilePromoCodes.rejected, sharedRejectedReducer)
      .addCase(getProfilePromoCodes.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.promoCodes = action.payload.promoCodes;
        }
      });
    //#endregion GET PROFILE PROMO CODE INDEX
  },
});

export default profilePromoCodeSlice;
