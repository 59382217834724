import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableDataModel } from '@/app/components/Table/models';
import { PaymentIndexFilterQueryParams, PaymentTransaction } from '@/app/modules/profile/payments/models';

const ENDPOINT_PREFIX = '/ajax/profile/payments';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
};

//#region GET PROFILE PAYMENTS
export type getProfilePaymentsParams = {
  currentPage: number;
  itemsPerPage: number;
};

export const getProfilePayments = createAsyncThunk(
  'profilePayment/getProfilePayments',
  async function (queryParams: PaymentIndexFilterQueryParams) {
    const response = await axios.get<GetProfilePaymentsResponse>(ENDPOINTS.resource, {
      params: queryParams,
    });
    return response.data;
  }
);

export interface GetProfilePaymentsResponse {
  transactions: TableDataModel<PaymentTransaction[]>;
}
//#endregion GET PROFILE PAYMENTS
