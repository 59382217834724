import { useContext } from 'react';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { BottomNavigationContext } from '@/app/layout/BottomNavigation/BottomNavigationContext';
import { BottomNavigationItem } from '@/app/layout/BottomNavigation/BottomNavigationItem';
import { BottomNavigationMenu } from '@/app/layout/BottomNavigation/BottomNavigationMenu';
import { LARGE_BREAKPOINTS, useBreakpoint } from '@/app/utils/useBreakpoint';
import { useAppSelector } from '@/redux/store';

export function BottomNavigation() {
  const breakpoint = useBreakpoint();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const isLargeScreen = LARGE_BREAKPOINTS.includes(breakpoint);
  const { isVisible } = useContext(BottomNavigationContext);

  if (isLargeScreen) {
    return null;
  }

  if (!isVisible) {
    return null;
  }

  return (
    <BottomNavigationMenu>
      <BottomNavigationItem to="/subjects" additionalActive="/courses" className="bottom-navigation-item">
        <SVG src={toAbsoluteUrl('/img/svg/Subjects.svg')} className="bottom-navigation-item__svg" />
        <span className="bottom-navigation-item__text">Предмети</span>
      </BottomNavigationItem>

      {!isAuthenticated && (
        <>
          <BottomNavigationItem to="/" exact>
            <i className="fas fa-info bottom-navigation-item__icon" />
            <span className="bottom-navigation-item__text">За платформата</span>
          </BottomNavigationItem>

          <BottomNavigationItem to="/tutor-home">
            <i className="fas fa-chalkboard-user bottom-navigation-item__icon" />
            <span className="bottom-navigation-item__text">Стани обучител</span>
          </BottomNavigationItem>
        </>
      )}

      {isAuthenticated && (
        <>
          <BottomNavigationItem to="/my-schedule">
            <i className="fa-regular fa-calendar bottom-navigation-item__icon" />
            <span className="bottom-navigation-item__text">Моят график</span>
          </BottomNavigationItem>

          <BottomNavigationItem to="/profile">
            <i className="fa-regular fa-user-alt bottom-navigation-item__icon" />
            <span className="bottom-navigation-item__text">Профил</span>
          </BottomNavigationItem>

          <a className="bottom-navigation-item" id="kt_aside_mobile_toggle">
            <i className="fas fa-ellipsis-h bottom-navigation-item__icon" />
            <span className="bottom-navigation-item__text">Още</span>
          </a>
        </>
      )}
    </BottomNavigationMenu>
  );
}
