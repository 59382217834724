export function getData<T>(db: IDBDatabase, storeName: string, key: string): Promise<T> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName);
    const store = transaction.objectStore(storeName);
    const getRequest = store.get(key);

    function handleGetRequestSuccess() {
      getRequest.removeEventListener('success', handleGetRequestSuccess);
      getRequest.removeEventListener('error', handleGetRequestError);

      resolve(getRequest.result);
    }

    function handleGetRequestError() {
      getRequest.removeEventListener('success', handleGetRequestSuccess);
      getRequest.removeEventListener('error', handleGetRequestError);

      reject(getRequest.error);
    }

    getRequest.addEventListener('success', handleGetRequestSuccess);
    getRequest.addEventListener('error', handleGetRequestError);
  });
}

export function putData<T>(db: IDBDatabase, storeName: string, key: string, value: T) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const putRequest = store.put(value, key);

    function handlePutRequestSuccess() {
      putRequest.removeEventListener('success', handlePutRequestSuccess);
      putRequest.removeEventListener('error', handlePutRequestError);

      resolve(putRequest.result);
    }

    function handlePutRequestError() {
      putRequest.removeEventListener('success', handlePutRequestSuccess);
      putRequest.removeEventListener('error', handlePutRequestError);

      reject(putRequest.error);
    }

    putRequest.addEventListener('success', handlePutRequestSuccess);
    putRequest.addEventListener('error', handlePutRequestError);
  });
}

export function deleteData(db: IDBDatabase, storeName: string, key: string) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const deleteRequest = store.delete(key);

    function handleDeleteRequestSuccess() {
      deleteRequest.removeEventListener('success', handleDeleteRequestSuccess);
      deleteRequest.removeEventListener('error', handleDeleteRequestError);

      resolve(deleteRequest.result);
    }

    function handleDeleteRequestError() {
      deleteRequest.removeEventListener('success', handleDeleteRequestSuccess);
      deleteRequest.removeEventListener('error', handleDeleteRequestError);

      reject(deleteRequest.error);
    }

    deleteRequest.addEventListener('success', handleDeleteRequestSuccess);
    deleteRequest.addEventListener('error', handleDeleteRequestError);
  });
}

export function clearStore(db: IDBDatabase, storeName: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const clearRequest = store.clear();

    function handleGetRequestSuccess() {
      clearRequest.removeEventListener('success', handleGetRequestSuccess);
      clearRequest.removeEventListener('error', handleGetRequestError);

      resolve(clearRequest.result);
    }

    function handleGetRequestError() {
      clearRequest.removeEventListener('success', handleGetRequestSuccess);
      clearRequest.removeEventListener('error', handleGetRequestError);

      reject(clearRequest.error);
    }

    clearRequest.addEventListener('success', handleGetRequestSuccess);
    clearRequest.addEventListener('error', handleGetRequestError);
  });
}
