import { useCallback, useEffect, useRef } from 'react';

import { debounce } from '@/app/utils/debounce';

type InfiniteScrollProps = {
  onLoadMore: () => void;
  hasMore: boolean;
};

export function InfiniteScroll({ onLoadMore, hasMore }: InfiniteScrollProps) {
  const observerEl = useRef<HTMLParagraphElement | null>(null);

  const handleObserver = useCallback(
    function handleObserver() {
      // Sanity check.
      if (Number(observerEl.current?.getClientRects?.()?.length) === 0) {
        return;
      }

      onLoadMore();
    },
    [onLoadMore]
  );

  useEffect(
    function handleIntersectionObserver() {
      const internalObserverEl = observerEl.current;
      const observer = new IntersectionObserver(debounce(handleObserver, 600));

      if (internalObserverEl) {
        observer.observe(internalObserverEl);
      }

      return function componentWillUnmount() {
        if (internalObserverEl) {
          observer.unobserve(internalObserverEl);
        }
      };
    },
    [handleObserver]
  );

  if (!hasMore) {
    return <p ref={observerEl} className="d-block w-10px h-10px bg-gray-300 rounded m-5 mx-auto" />;
  }

  return (
    <p ref={observerEl} className="m-5 text-center">
      Зарежда се...
    </p>
  );
}
