import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import Sticky from 'react-stickynode';
import clsx from 'clsx';
import * as yup from 'yup';

import { AcceleratedCoursesAlert } from '@/app/components/AcceleratedCoursesAlert/AcceleratedCoursesAlert';
import { Button } from '@/app/components/Button/Button';
import { Form } from '@/app/components/Form/Form';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { useForm } from '@/app/components/Form/useForm';
import { LessonPromotionAlert } from '@/app/components/LessonPromotionAlert/LessonPromotionAlert';
import { Content } from '@/app/components/Page/Content/Content';
import { Page } from '@/app/components/Page/Page';
import { PageTitle } from '@/app/components/Page/PageTitle/PageTitle';
import { PageTop } from '@/app/components/Page/PageTop/PageTop';
import { ITEMS_PER_PAGE_DATA, ITEMS_PER_PAGE_SHORT_DATA } from '@/app/components/Pagination/constants';
import { Pagination } from '@/app/components/Pagination/Pagination';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { GlobalSpinner } from '@/app/components/Spinner/GlobalSpinner';
import { usePromotionDescription } from '@/app/layout/Footer/usePromotionDescription';
import { useQueryParams } from '@/app/utils/query';
import { LARGE_BREAKPOINTS, useBreakpoint } from '@/app/utils/useBreakpoint';
import { useCollapsableFilter } from '@/app/utils/useCollapsableFilter';
import { useDebounce } from '@/app/utils/useDebounce';
import { useLoading } from '@/app/utils/useLoading';
import { useScrollTopOnHistoryChange } from '@/app/utils/useScrollTopOnHistoryChange';
import { LoadingState } from '@/redux/constants';
import { useAppDispatch, useAppSelector } from '@/redux/store';

import { TUTOR_INDEX_FILTER_SORT_BY_DATA, TutorIndexFilterInitialValues } from '../constants';
import { TutorIndexData, TutorIndexFilterQueryParams, TutorIndexFilterValues } from '../models';
import { getAllTutors } from '../service';
import { transformTutorIndexFormikValuesToSearchParams } from '../utils';
import { TutorItem } from './TutorItem/TutorItem';

export function TutorIndexPage() {
  const history = useHistory();
  const location = useLocation();
  const { currentSearchParams, queryParams } = useQueryParams<TutorIndexFilterQueryParams>();

  useScrollTopOnHistoryChange();
  usePromotionDescription();

  //#region Handle data
  const dispatch = useAppDispatch();
  const breakpoint = useBreakpoint();
  const isLargeScreen = LARGE_BREAKPOINTS.includes(breakpoint);
  const store = useAppSelector((state) => state.tutor);
  const debouncedQueryParams = useDebounce<TutorIndexFilterQueryParams>(queryParams, 600);
  const [loadingState] = useLoading(store.loading);
  const isEmpty = loadingState === LoadingState.Idle && store.index.tutors.length === 0;

  useEffect(
    function handleFetchingData() {
      if (store.loading === LoadingState.Idle) {
        dispatch(getAllTutors(debouncedQueryParams));
      }
    },
    [debouncedQueryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //#endregion Handle data

  //#region Handle filter form
  const initialValues = useMemo<TutorIndexFilterValues>(
    () => ({
      page: queryParams.page ?? TutorIndexFilterInitialValues.page,
      sortBy: queryParams.sortBy ?? TutorIndexFilterInitialValues.sortBy,
      itemsPerPage: queryParams.itemsPerPage ?? TutorIndexFilterInitialValues.itemsPerPage,
      search: queryParams.search ?? TutorIndexFilterInitialValues.search,
      subject: queryParams.subject ?? TutorIndexFilterInitialValues.subject,
    }),
    [queryParams.itemsPerPage, queryParams.page, queryParams.sortBy, queryParams.search, queryParams.subject]
  );

  const validationSchema = useMemo(() => yup.object().shape({}), []);

  const formik = useForm<TutorIndexFilterValues>({
    initialValues,
    validationSchema,
  });

  const handlePageChange = useCallback(
    (page: number) => {
      formik.setFieldValue('page', page);
    },
    [formik]
  );
  //#endregion Handle filter form

  //#region Handle query params
  useEffect(
    function handleFilterFormChange() {
      const newSearchParams = transformTutorIndexFormikValuesToSearchParams(formik.values, queryParams);
      if (newSearchParams !== currentSearchParams) {
        history.replace({ pathname: location.pathname, search: newSearchParams });
      }

      if (newSearchParams === '') {
        formik.setValues(TutorIndexFilterInitialValues);
      }
    },
    [currentSearchParams, formik.values, history, location.pathname, queryParams] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //#endregion Handle query params

  //#region Handle filter toggle
  const [stickyStateStatusCode, setStickyStateStatusCode] = useState<Sticky.StatusCode>(Sticky.STATUS_ORIGINAL);
  const contentContainerRef = useRef<HTMLDivElement>(null);

  function handleStickyStateChange(state: Sticky.Status) {
    setStickyStateStatusCode(state.status);
  }

  const { isFilterToggled, handleFilterToggle } = useCollapsableFilter({
    stickyStateStatusCode: stickyStateStatusCode,
    contentContainerRef: contentContainerRef,
  });

  function clearFilters() {
    formik.setValues(TutorIndexFilterInitialValues);
  }
  //#endregion Handle filter toggle

  return (
    <>
      <Helmet>
        <title>Всички обучители</title>
        <meta name="keywords" content="Учители, Обучители" />
        <meta
          name="description"
          content="Каталог с най-добрите обучители. Най-добрите в страната. Тук може да сте сигурни, че Вашето дете ще получи качествена обучителна услуга и индивидуален подход."
        />
      </Helmet>
      <Page
        className="bg-white p-0"
        renderContent={(props) => <Form {...props} formik={formik} disabled={formik.isSubmitting} skipDirtyPrompt />}
      >
        <div className="bg-gray-200">
          <LessonPromotionAlert />
          <AcceleratedCoursesAlert />
          <Content isContainerContent className="pb-0">
            <PageTop isVertical>
              <PageTitle className="d-flex align-items-center w-100 my-2 fw-bolder fs-2x">Всички обучители</PageTitle>
            </PageTop>
          </Content>
        </div>

        <Sticky
          top="#kt_header"
          bottomBoundary="#kt_content"
          className="z-index-3"
          onStateChange={handleStickyStateChange}
        >
          <div className="bg-gray-200 w-100">
            <Content isContainerContent>
              <div className="d-flex flex-column">
                <button
                  type="button"
                  className={clsx(
                    'btn btn-bg-white d-flex align-items-center border border-gray-300 w-100 px-3 d-xl-none',
                    { 'mb-5': isFilterToggled }
                  )}
                  onClick={handleFilterToggle}
                >
                  <span className="d-block mx-auto">{!isFilterToggled ? 'Покажи филтри' : 'Скрий филтри'}</span>
                  <i
                    className={clsx('far', { 'fa-chevron-up': isFilterToggled, 'fa-chevron-down': !isFilterToggled })}
                  />
                </button>

                <div
                  className={clsx('d-xl-flex flex-wrap justify-content-between mb-n4', {
                    'd-flex': isFilterToggled,
                    'd-none': !isFilterToggled,
                  })}
                >
                  <div className="d-flex flex-wrap align-items-center w-100">
                    <FormGroup
                      controlId="search"
                      className={clsx('d-flex align-items-center mb-4 w-100', {
                        'mb-9': isLargeScreen,
                      })}
                    >
                      <FormControl
                        name="search"
                        type="text"
                        placeholder="Търси"
                        value={formik.values.search}
                        hasSolidBackground={false}
                        className="border-0"
                      />
                    </FormGroup>
                  </div>

                  <div className="d-flex flex-wrap align-items-center w-100">
                    <FormGroup className="d-flex align-items-center mb-4 me-4">
                      <div
                        className={clsx({
                          'non-default-select-filter': Boolean(formik.values.subject),
                        })}
                      >
                        <SelectControl
                          name="subject"
                          options={store.index.subjects}
                          hasSolidBackground={false}
                          placeholder="Избери предмет"
                          className="w-175px"
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="d-flex align-items-center mb-4 me-4">
                      <Label htmlFor="sortBy" className="mb-0 me-3">
                        Подреди по:
                      </Label>
                      <div
                        className={clsx({
                          'non-default-select-filter': formik.values.sortBy !== TutorIndexFilterInitialValues.sortBy,
                        })}
                      >
                        <SelectControl
                          name="sortBy"
                          options={TUTOR_INDEX_FILTER_SORT_BY_DATA}
                          isSearchable={false}
                          hasSolidBackground={false}
                          className="w-150px"
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="d-flex align-items-center mb-4 me-4">
                      <Label htmlFor="itemsPerPage" className="mb-0 me-3">
                        Обучители на страница:
                      </Label>
                      <div
                        className={clsx({
                          'non-default-select-filter':
                            formik.values.itemsPerPage != TutorIndexFilterInitialValues.itemsPerPage,
                        })}
                      >
                        {isLargeScreen && (
                          <SelectControl
                            name="itemsPerPage"
                            options={ITEMS_PER_PAGE_DATA}
                            isSearchable={false}
                            hasSolidBackground={false}
                            className="w-175px"
                          />
                        )}
                        {!isLargeScreen && (
                          <SelectControl
                            name="itemsPerPage"
                            options={ITEMS_PER_PAGE_SHORT_DATA}
                            isSearchable={false}
                            hasSolidBackground={false}
                            className="w-80px"
                          />
                        )}
                      </div>
                    </FormGroup>

                    <Button onClick={clearFilters} variant="light-danger" className="align-self-start ms-xl-auto">
                      <i className="fas fa-times p-0" />
                    </Button>
                  </div>
                </div>
              </div>
            </Content>
          </div>
        </Sticky>

        <div id="content-container-wrapper" ref={contentContainerRef}>
          <Content isContainerContent>
            <Row className="g-9">
              {store.index.tutors.map((tutor: TutorIndexData) => (
                <TutorItem key={tutor.id} tutor={tutor} />
              ))}

              {isEmpty && <p className="mb-0 fw-bold text-center">Няма намерени обучители.</p>}
            </Row>
          </Content>

          <Content isContainerContent className={clsx('pt-0', { 'p-0': isEmpty })}>
            <div className="d-flex justify-content-center">
              <Pagination
                total={store.index.tutorsTotal}
                perPage={Number(formik.values.itemsPerPage)}
                currentPage={Number(formik.values.page)}
                onChange={handlePageChange}
                isLoading={loadingState === LoadingState.Pending}
              />
            </div>
          </Content>
        </div>
        {loadingState === LoadingState.Pending && <GlobalSpinner />}
      </Page>
    </>
  );
}
