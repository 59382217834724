import { createSlice } from '@reduxjs/toolkit';

import { FavoriteCourse } from '@/app/modules/favorite-course/models';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

import { getFavoriteCourses } from './service';

interface EnrolledCourseState {
  loading: LoadingState;
  error?: string | null;
  favoriteCourses: FavoriteCourse[];
}

const initialState: EnrolledCourseState = {
  loading: LoadingState.Idle,
  error: null,
  favoriteCourses: [],
};

const favoriteCourseSlice = createSlice({
  name: 'favoriteCourse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //#region Get favorite courses
    builder
      .addCase(getFavoriteCourses.pending, sharedPendingReducer)
      .addCase(getFavoriteCourses.rejected, sharedRejectedReducer)
      .addCase(getFavoriteCourses.fulfilled, (state, action) => {
        state.loading = LoadingState.Idle;
        state.favoriteCourses = action.payload.favoriteCourses;
      });
    //#endregion Get favorite courses
  },
});

export default favoriteCourseSlice;
