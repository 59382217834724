//#region BASE
export type ProfileProfitItemsPerPage = 10 | 20 | 30 | 40 | 50 | 60;

export interface ProfileProfitFilterValues {
  itemsPerPage: ProfileProfitItemsPerPage;
  page?: number;
}

export interface ProfileProfitFilterQueryParams extends Omit<ProfileProfitFilterValues, 'itemsPerPage'> {
  itemsPerPage?: ProfileProfitItemsPerPage;
}

export interface ProfileProfitItemPerPageOption {
  id: ProfileProfitItemsPerPage;
  text: string;
}

export enum TutorBalanceTransferStatusID {
  AutomaticallyTransferred = 1,
  BonusBlocked = 2,
  BonusTransferred = 3,
}
//#endregion BASE

//#region WITHDRAWAL
export interface Withdrawal {
  createdAt: string;
  beneficiery: string;
  IBAN: string;
  amount: string;
}

export type WithdrawalsFilterValues = ProfileProfitFilterValues;
export type WithdrawalsFilterQueryParams = ProfileProfitFilterQueryParams;
//#endregion WITHDRAWAL

//#region INCOME
export interface Income {
  createdAt: string;
  courseName: string;
  buyerName: string;
  amount: string;
  tutorBalanceTransferTypeName: string;
  tutorBalanceTransferStatusID: TutorBalanceTransferStatusID;
}

export type IncomeFilterValues = ProfileProfitFilterValues;
export type IncomeFilterQueryParams = ProfileProfitFilterQueryParams;
//#endregion INCOME

//#region EXPECTED INCOME
export interface ExpectedIncomePackage {
  id: number;
  name: string;
  lessonCount: number;
  incomePerLesson: string;
  income: string;
}
export interface ExpectedIncome extends Record<string, unknown> {
  courseName: string;
  buyerName: string;
  lessonCount: number;
  expectedIncome: string;
  studentIdentityID: string;
  packages: ExpectedIncomePackage[];
  willBeBlocked: number;
}

export type ExpectedIncomeFilterValues = ProfileProfitFilterValues;
export type ExpectedIncomeFilterQueryParams = ProfileProfitFilterQueryParams;
//#endregion EXPECTED INCOME

//#region SOLD COURSE
export interface SoldCourse {
  createdAt: string;
  courseName: string;
  buyerName: string;
  lessonCount: number;
  amount: string;
}

export type SoldCoursesFilterValues = ProfileProfitFilterValues;
export type SoldCoursesFilterQueryParams = ProfileProfitFilterQueryParams;
//#endregion SOLD COURSE

//#region MONTHLY INCOME
export interface MonthlyIncome {
  month: string;
  paidAmount: string;
  bonusAmount: string;
}

export type MonthlyIncomeFilterValue = ProfileProfitFilterValues;
export type MonthlyIncomeFilterQueryParams = ProfileProfitFilterQueryParams;
//#endregion MONTHLY INCOME
