import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { FileControlValue } from '@/app/components/Form/models';
import { serializeFormData } from '@/app/utils/serializeFormData';

import {
  ProfileAboutDetailsValues,
  ProfileBankDetails,
  ProfileDetailsValues,
  ProfileInvoiceDetails,
  TutorProfileDetailsShowData,
} from './models';

const ENDPOINT_PREFIX = '/ajax/profile';

export const ENDPOINTS = {
  tutor: {
    entity: `${ENDPOINT_PREFIX}/details/tutor`,
    edit: `${ENDPOINT_PREFIX}/details/tutor/edit`,
    about: `${ENDPOINT_PREFIX}/about-details`,
    invoice: `${ENDPOINT_PREFIX}/invoice-details`,
    bank: `${ENDPOINT_PREFIX}/bank-details`,
  },
  student: {
    entity: `${ENDPOINT_PREFIX}/details/student`,
    edit: `${ENDPOINT_PREFIX}/details/student/edit`,
  },
};

interface GetProfileDetailsShowDataResponse {
  details: TutorProfileDetailsShowData;
}

interface GetProfileDetailsEditDataResponse {
  profile: ProfileDetailsValues;
}

//#region TUTOR PROFILE

//#region Get tutor profile details show data
export const getTutorProfileDetailsShowData = createAsyncThunk(
  'profileCourse/getTutorProfileShowData',
  async function () {
    const response = await axios.get<GetProfileDetailsShowDataResponse>(ENDPOINTS.tutor.entity);
    return response.data;
  }
);
//#endregion Get tutor profile details show data

//#region Get tutor profile details edit data
export const getTutorProfileDetailsEditData = createAsyncThunk(
  'profileCourse/getTutorProfileDetailsEditData',
  async function () {
    const response = await axios.get<GetProfileDetailsEditDataResponse>(ENDPOINTS.tutor.edit);
    return response.data;
  }
);
//#endregion Get tutor profile details edit data

//#region Get tutor profile about show data
interface GetProfileAboutDetailsResponse {
  aboutDetails: ProfileAboutDetailsValues;
  attachments: FileControlValue[];
}

export const getTutorProfileAboutDetails = createAsyncThunk(
  'profileDetails/getTutorProfileAboutDetails',
  async function () {
    const response = await axios.get<GetProfileAboutDetailsResponse>(ENDPOINTS.tutor.about);
    return response.data;
  }
);
//#endregion Get tutor profile about show data

export async function updateTutorProfileAboutDetails(values: ProfileAboutDetailsValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.tutor.about, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

export async function updateTutorProfileDetails(values: ProfileDetailsValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.tutor.entity, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion TUTOR PROFILE

//#region STUDENT PROFILE

//#region Get student profile details show data
export const getStudentProfileDetailsShowData = createAsyncThunk(
  'profileDetails/getStudentProfileShowData',
  async function () {
    const response = await axios.get<GetProfileDetailsShowDataResponse>(ENDPOINTS.student.entity);
    return response.data;
  }
);
//#endregion Get student profile details show data

//#region Get student profile details edit data
export const getStudentProfileDetailsEditData = createAsyncThunk(
  'profileDetails/getStudentProfileEditData',
  async function () {
    const response = await axios.get<GetProfileDetailsEditDataResponse>(ENDPOINTS.student.edit);
    return response.data;
  }
);
//#endregion Get student profile details edit data

export async function updateStudentProfileDetails(values: ProfileDetailsValues) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.student.entity, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}

//#endregion STUDENT PROFILE

//#region Get profile invoice details
export type GetProfileInvoiceDetailsResponse = {
  invoiceDetails: ProfileInvoiceDetails;
};

export const getProfileInvoiceDetails = createAsyncThunk('profileDetails/getProfileInvoiceDetails', async function () {
  const response = await axios.get<GetProfileInvoiceDetailsResponse>(ENDPOINTS.tutor.invoice);
  return response.data;
});
//#endregion Get profile invoice details

//#region Update profile invoice details
export async function updateProfileInvoiceDetails(values: ProfileInvoiceDetails) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.tutor.invoice, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion Update profile invoice details

//#region Get profile bank details
export type GetProfileBankDetailsResponse = {
  bankDetails: ProfileBankDetails;
};

export const getProfileBankDetails = createAsyncThunk('profileDetails/getProfileBankDetails', async function () {
  const response = await axios.get<GetProfileBankDetailsResponse>(ENDPOINTS.tutor.bank);
  return response.data;
});
//#endregion Get profile bank details

//#region Update profile bank details
export async function updateProfileProfileBankDetails(values: ProfileBankDetails) {
  const formData = serializeFormData(values);
  const response = await axios.put(ENDPOINTS.tutor.bank, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
}
//#endregion Update profile bank details
