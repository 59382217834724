import { Token } from '@stripe/stripe-js';

import { CardControlError } from '@/app/components/Form/CardControl/models';

import { CoursePackageModel } from '../create/models';
import { STRIPE_CARD_ERROR_FIELD_NAME } from './constants';

export type CoursePackageCheckoutModel = CoursePackageModel;

export interface CourseCheckoutValues extends Record<string, unknown> {
  coursePackageID: number;
  cardHolderName: string;
  saveCard: 0 | 1;
  stripeToken: Token | null;
  paymentMethod: number | undefined;
  [STRIPE_CARD_ERROR_FIELD_NAME]?: CardControlError;
  promoCode: string;
}

export interface CourseCheckoutQueryParams {
  coursePackageID: number | null;
  reservationID: number | null;
}

export enum CourseCheckoutPaymentState {
  paymentIntentNotStarted = 0,
  paymentIntentInitiated = 1,
  paymentIntentSucceeded = 2,
  confirmPaymentSucceeded = 3,
  confirmCheckoutSucceeded = 4,
}
