import { Descendant, Node } from 'slate';

import { EditableTimeSlotEntity, TimeSlotEntity, TimeSlotStatusID, WeekDay } from '@/app/components/Calendar/models';
import { ItemsPerPage } from '@/app/components/Pagination/models';
import { CourseStatusID } from '@/app/models/CourseStatusID';
import { Reservation } from '@/app/models/Reservation';
import { Slug } from '@/app/models/Slug';
import { CoursePackageModel } from '@/app/modules/course/create/models';
import { CourseFeedbackComment } from '@/app/modules/course-feedback/models';
import { ConnectedStudentLevel, ConnectedStudentValues } from '@/app/modules/profile/connected-student/models';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';
import { LoadingState } from '@/redux/constants';

import { CoursePackageCheckoutModel } from './checkout/models';

export type Subject = {
  id: number;
  text: string;
  transliteratedSlug: string;
  imagePath: string | null;
  targetAudiencesIDs?: number[];
};

export type SubjectDesktop = {
  id: string;
  text: string;
};

export interface TargetAudience extends Slug {
  transliteratedSlug: string;
}
export type RecurrenceType = Slug;

export enum CourseIndexFilterSortBy {
  Rating = 'rating',
  Popularity = 'popularity',
  PriceAsc = 'price_asc',
  PriceDesc = 'price_desc',
}

export enum CourseIndexFilterPriceRange {
  From10To20 = 'from_10_to_20',
  From20To30 = 'from_20_to_30',
  From30To40 = 'from_30_to_40',
  From40To50 = 'from_40_to_50',
  Over50 = 'over_50',
}

export enum CourseIndexFilterPriceRangeLabel {
  From10To20 = '10-20 лв.',
  From20To30 = '20-30 лв.',
  From30To40 = '30-40 лв.',
  From40To50 = '40-50 лв.',
  Over50 = 'над 50 лв.',
}

export enum CourseIndexFilterTimeRange {
  From6To7 = 'from_6_to_7',
  From7To8 = 'from_7_to_8',
  From8To9 = 'from_8_to_9',
  From9To10 = 'from_9_to_10',
  From10To11 = 'from_10_to_11',
  From11To12 = 'from_11_to_12',
  From12To13 = 'from_12_to_13',
  From13To14 = 'from_13_to_14',
  From14To15 = 'from_14_to_15',
  From15To16 = 'from_15_to_16',
  From16To17 = 'from_16_to_17',
  From17To18 = 'from_17_to_18',
  From18To19 = 'from_18_to_19',
  From19To20 = 'from_19_to_20',
  From20To21 = 'from_20_to_21',
  From21To22 = 'from_21_to_22',
}

export enum CourseIndexFilterTimeRangeLabel {
  From6To7 = '06:00-07:00',
  From7To8 = '07:00-08:00',
  From8To9 = '08:00-09:00',
  From9To10 = '09:00-10:00',
  From10To11 = '10:00-11:00',
  From11To12 = '11:00-12:00',
  From12To13 = '12:00-13:00',
  From13To14 = '13:00-14:00',
  From14To15 = '14:00-15:00',
  From15To16 = '15:00-16:00',
  From16To17 = '16:00-17:00',
  From17To18 = '17:00-18:00',
  From18To19 = '18:00-19:00',
  From19To20 = '19:00-20:00',
  From20To21 = '20:00-21:00',
  From21To22 = '21:00-22:00',
}

export enum CourseIndexFilterDaysOfWeekLabel {
  Monday = 'Понеделник',
  Tuesday = 'Вторник',
  Wednesday = 'Сряда',
  Thursday = 'Четвъртък',
  Friday = 'Петък',
  Saturday = 'Събота',
  Sunday = 'Неделя',
}

export enum CourseIndexFilterNumberOfLessons {
  FourLessons = '4',
  EightLessons = '8',
  SixteenLessons = '16',
  ThirtyTwoLessons = '32',
}

export enum CourseIndexFilterNumberOfLessonsLabel {
  FourLessons = '4 урока',
  EightLessons = '8 урока',
  SixteenLessons = '16 урока',
  ThirtyTwoLessons = '32 урока',
}

export interface CourseIndexFilterPriceOption {
  id: CourseIndexFilterPriceRange;
  text: string;
}

export interface CourseIndexFilterValues {
  sortBy: CourseIndexFilterSortBy;
  itemsPerPage: ItemsPerPage;
  targetAudienceSlug?: string;
  priceRanges: CourseIndexFilterPriceRange[];
  numberOfLessons: CourseIndexFilterNumberOfLessons[];
  timeRanges: string[];
  keywordIds: string[];
  daysOfWeek: WeekDay[];
  fromDate?: string;
  toDate?: string;
  page?: number;
  hasFutureTimeSlots?: number;
  subjectSlug: string;

  [key: string]: unknown;
}

export type CourseIndexFilterQueryParams = Partial<CourseIndexFilterValues>;

export interface CourseIndexValues extends CourseIndexFilterQueryParams {
  subjectID?: string | null;
  targetAudienceID?: string | null;
}

export interface CourseSubject {
  id: number;
  name: string;
}

export interface LabelItem {
  [key: string]: string | null;
}

export interface Course {
  id: number;
  name: string;
  tutorID: number;
  tutorName: string;
  tutorProfileImagePath: string | null;
  lessonDuration: number;
  startingPrice: string;
  targetAudienceName: string;
  currentRating: number | null;
  transliteratedSlug?: string;
  keywords: SubjectKeyword[];
}

export interface TutorWithCourse {
  id: number;
  transliteratedSlug: string;
  tutorIdentityID: number;
  tutorName: string;
  tutorProfileImagePath: string | null;
  tutorVideoFileUrl: string | null;
  rating: number;
  ratingCount: number;
  firstAvailableTimeSlotTime: string | null;
  averageResponseTime: string | null;
  courses: Course[];
}

export interface CourseDetailsData {
  tutor: {
    name: string | null;
    identityID: number | null;
    profileFile: string | null;
    about: Node[];
    videoFile: string | null;
    hasAvailableFutureTimeSlots: boolean;
  };
  additionalTutorInfo: {
    reactionTime: string | null;
    numberStudents: number | null;
    numberReservedLessons: number | null;
    uniqueVisitsCount: number | null;
  };
  course: {
    id?: number;
    name: string | null;
    subjects: CourseSubject[];
    keywords: SubjectKeyword[];
    goals: string;
    methodology: string;
    skills: string;
    content: string | null;
    description: Descendant[];
    targetAudienceName: string | null;
    targetAudienceTransliteratedSlug?: string | null;
    currentRating?: number | null;
    ratingCount?: number | null;
    tutorID?: number | null;
    courseStatusID?: CourseStatusID;
    lessonDuration: number | null;
    courseTransliteratedSlug?: string;
    tutorTransliteratedSlug?: string;
  };
  courseSubjectTransliteratedSlug?: string | null;
  availableLessonCount: number;
  initialCoursePackageID: string | number | null;
  individualLesson: CoursePackageModel | null;
  coursePackages: CoursePackageModel[];
  isCourseOwner: boolean;
  canBuyCourse: boolean;
  isFavorite: boolean;
  canReturnIndividualLessonBonus: boolean;
  canRefund: boolean;
  firstAvailableTimeSlotDateTime: string | null;
}

export interface CourseFeedbacksPaginatedData {
  loading: LoadingState;
  data: CourseFeedbackComment[];
  hasMore: boolean;
  currentPage: number;
}

export interface CourseReviewData {
  loading: LoadingState;
  courseSummary: CourseSummary;
  courseRatingStats: CourseRatingStat[];
  courseFeedbacks: CourseFeedbacksPaginatedData;
  canLeaveFeedback: boolean;
  shouldLeaveFeedback: boolean;
}

export interface CourseFreeStudentProposalData {
  id: number;
  name: string;
  currentRating: number;
  tutorIdentityID: number;
  tutorName: string;
  tutorProfileImagePath: string;
}

export interface PreselectedTimeSlotData {
  id: number;
  dateTime: string;
  timeSlotStatusID: TimeSlotStatusID;
}

export interface CourseReservationData {
  course: {
    id?: number;
    name: string;
    currentRating: number | null;
    tutorID: number | null;
    tutorName: string;
    tutorProfileImagePath: string | null;
    availableLessonCount: number;
    courseStatusID: number;
    courseTransliteratedSlug: string;
    subjectName: string | null;
    targetAudienceName: string | null;
  };
  timeSlots: TimeSlotEntity[];
  defaultConnectedStudent: ConnectedStudentValues;
  connectedStudents: ConnectedStudentValues[];
  connectedStudentLevels: ConnectedStudentLevel[];
  reservationConnectedStudentIds: number[];
  preselectedTimeSlot: PreselectedTimeSlotData | null;
}

export enum CourseReservationType {
  MakeReservationType = 'make_reservation_type',
  MakeProposalType = 'make_proposal_type',
}

export interface CourseStudentProposal {
  id: number;
  courseID: number;
  courseName: string;
  studentID: number;
  studentName: string;
  studentIdentityID: number;
  tutorIdentityID: number;
  startDate: string;
  endDate: string;
  daysOfWeek: WeekDay[] | null;
}

export enum StudentProposalStatusID {
  Created = 1,
  ReceivedFromTutor = 2,
  SentToStudent = 3,
}

export interface CourseStudentProposalDataPayload {
  id: number;
  studentProposalID: number;
}

export interface CourseReservationValues extends Record<string, unknown> {
  timeSlotIDs: number[];
  connectedStudentID?: number | null;
  connectedStudentName: string;
  connectedStudentPhone: string;
  connectedStudentEmail: string;
  connectedStudentLevelID: number | null;
  expectations: string;
  reservationType?: CourseReservationType | null;
  daysOfWeek?: WeekDay[];
  dayTimeIntervals?: DayTimeInterval[];
  startDate?: Date | string | null;
  endDate?: Date | string | null;
}

export interface CourseReservationQueryParams {
  orderID: number | null;
}

export interface PaymentMethod {
  id: number;
  stripeID: string;
  holderName: string;
  brandID: number;
  expiryMonth: number;
  expiryYear: number;
  lastFourDigits: string;
}

export interface CourseCheckoutData {
  course: {
    id?: number;
    name: string | null;
    currentRating: number | null;
    tutorName: string | undefined;
    tutorIdentityID: number | null;
    tutorProfileImagePath: string | undefined;
    targetAudienceName: string | null;
    subjects: CourseSubject[];
  };
  coursePackages: CoursePackageCheckoutModel[];
  paymentMethods: PaymentMethod[];
  customerID?: string | null;
  timeSlotsCount: number;
}

export interface PaymentIntent {
  id: string;
  client_secret: string;
}

export interface CourseScheduleData {
  duration: number;
  timeSlots: EditableTimeSlotEntity[];
  hasValidTimeSlotsForApproval: boolean;
  hasBlockedNewStudentsReservations: boolean;
}

export enum CheckboxFilterValue {
  Unchecked = 0,
  Checked = 1,
}

export interface CanceledReservationDetails {
  course: {
    id: number | null;
    name: string;
  };
  reservation: Reservation | null;
  isStudent: boolean | null;
  promoCode: string | null;
}

export interface CourseRatingStat {
  courseRating: number;
  courseRatingCount: number;
}

export interface CourseSummary {
  currentRating: number;
  ratingCount: number;
}

export interface CourseCheckoutDataPayload {
  transliteratedSlug: string;
  reservationID: number | null;
}

export interface PromoCodeData {
  value: number | null;
  discountType: string | null;
  applicableLessonCounts: number[] | null;
}

export interface FreeCourseLessonUsingPromoCodeDataResponse {
  orderID: number;
  isStudentsFirstOrder: boolean;
  canUseLessonPromotion: boolean;
  promoCodeRefundAmount: string;
  generatedPromoCode: string;
}

export interface CompleteCheckoutResponse {
  orderID: number;
  isStudentsFirstOrder: boolean;
  canUseLessonPromotion: boolean;
}

export interface ConnectedStudentSchedulePayload {
  id: number;
  connectedStudentID: number;
}

export enum CourseIndexPageFilterMobile {
  TargetAudience,
  Keywords,
  Order,
  Price,
  NumberOfLessons,
  LessonsPerPage,
  TimeInterval,
}

export interface FirstCourseEditData {
  course: {
    courseName: string;
    description: Node[];
    goals: string;
    methodology: string;
    content: string;
    keywords: SubjectKeyword[];
    skills: string;
    subjectID: number | null;
    subjectName: string;
    targetAudienceID: number | null;
    targetAudienceName: string | null;
    individualLesson: CoursePackageModel | null;
    coursePackages: CoursePackageModel[];
  };
  reservationBufferHours: number | string;
  duration: number | string | null;
  canChangeCourseDuration: boolean;
  canUseLegacyCourseDuration: boolean;
  blockNewStudentsReservations: 0 | 1;
  hasBlockedNewStudentsReservations: boolean;
  subjects: Subject[];
  recurrenceTypes: RecurrenceType[];
  lastTimeSlotUpdatedAt: string | null;
  hasValidTimeSlotsForApproval: boolean;
}

export enum DayTimeInterval {
  Morning = 1,
  LateMorning = 2,
  Noon = 3,
  Afternoon = 4,
  Evening = 5,
}

export enum DayTimeIntervalHours {
  MorningStart = 6,
  LateMorningStart = 9,
  NoonStart = 12,
  AfternoonStart = 15,
  EveningStart = 18,
  DayEnd = 22,
}

export interface CourseFreeStudentProposalValues extends Record<string, unknown> {
  daysOfWeek?: WeekDay[];
  dayTimeIntervals?: DayTimeInterval[];
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  comment: string;
}
