import { ReservationProposalData } from '@/app/modules/reservation-proposal/models';
import { EcommerceEventItem } from '@/app/utils/google-analytics/models';

export function getEcommerceItemFromFreeReservationProposal(data: ReservationProposalData): EcommerceEventItem {
  return {
    id: Number(data.course.id),
    name: `${data.course.subjectName} ${data.course.targetAudienceName} ${data.course.tutorName}`,
    price: 0,
    brand: data.course.tutorName as string,
    category: `${data.course.subjectName as string}/${data.course.targetAudienceName as string}`,
    variant: 1,
    quantity: 1,
    currency: 'BGN',
    discount: 0,
  };
}
