import { useFormikContext } from 'formik';

import { FormControl } from '@/app/components/Form/FormControl/FormControl';

import './PhoneControl.scss';

type PhoneControlProps = {
  name: string;
  disabled?: boolean;
};

export function PhoneControl({ name, disabled = false }: PhoneControlProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<string>(name);

  return (
    <>
      <FormControl name={name} disabled={disabled} />
    </>
  );
}
