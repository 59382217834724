import React, { useMemo } from 'react';
import { Button as BaseButton, ButtonProps as BaseButtonProps } from 'react-bootstrap';
import clsx from 'clsx';

type ButtonsProps = React.PropsWithChildren<BaseButtonProps> & {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  isIndicatorOnlyLoading?: boolean;
};

export function Button({ children, isLoading = false, isIndicatorOnlyLoading = false, ...props }: ButtonsProps) {
  const internalProps = useMemo(() => {
    const newProps: Record<string, unknown> = {};

    if (isLoading) {
      newProps['data-kt-indicator'] = 'on';
      newProps['className'] = clsx(props.className, 'disabled');
    }

    return newProps;
  }, [isLoading, props]);

  return (
    <BaseButton {...props} {...internalProps} onClick={props.onClick}>
      {isLoading && (
        <>
          <span className="indicator-progress">
            {!isIndicatorOnlyLoading && 'Изчакайте...'}{' '}
            <span
              className={clsx('spinner-border spinner-border-sm align-middle', { 'ms-2': !isIndicatorOnlyLoading })}
            ></span>
          </span>
        </>
      )}
      {!isLoading && children}
    </BaseButton>
  );
}
