export function formatInfoRating(rating: string | number | null | undefined) {
  if (!rating || rating === '-') {
    return '-';
  }

  if (rating.toString().length === 1) {
    return rating.toString() + '.0';
  }

  return rating.toString();
}
