import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Select2Option } from '@/app/components/SelectControl/models';
import { TimelineIndexFiltersQueryParams } from '@/app/components/Timeline/TimelineIndexFilters/models';
import { ProfileTimelinePayload } from '@/app/models/ProfileTimeline';
import { ProfileTimeline, TimelineEvent } from '@/app/models/Timeline';

const ENDPOINT_PREFIX = '/ajax/profile/timelines';

export const ENDPOINTS = {
  resource: ENDPOINT_PREFIX,
  entity: (id: number) => `${ENDPOINT_PREFIX}/${id}`,
};

//#region PROFILE TIMELINE INDEX
export const getProfileTimelines = createAsyncThunk(
  'profileTimeline/getProfileTimelines',
  async function (filters: TimelineIndexFiltersQueryParams) {
    const response = await axios.get<GetProfileTimelinesResponse>(ENDPOINTS.resource, {
      params: filters,
    });
    return response.data;
  }
);

export interface GetProfileTimelinesResponse {
  timelines: ProfileTimeline[];
}
//#endregion PROFILE TIMELINE INDEX

//#region PROFILE TIMELINE SHOW
export interface GetProfileTimelineResponse {
  timeline: ProfileTimeline;
  timelineEvents: TimelineEvent[];
  hasMore: boolean;
  currentPage: number;
  timelineEventTypes: Select2Option[];
  timeSlots: Select2Option[];
}

export const getProfileTimelineShowData = createAsyncThunk(
  'profileTimeline/getProfileTimeline',
  async function ({ timelineID, page, filters = {} }: ProfileTimelinePayload) {
    const response = await axios.get<GetProfileTimelineResponse>(ENDPOINTS.entity(timelineID), {
      params: { page, filters },
    });
    return response.data;
  }
);
//#endregion PROFILE TIMELINE SHOW
