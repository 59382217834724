import React, { useEffect } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';

type UseMessagesLengthChangeProps = {
  isAddedNewMessageToThread: React.MutableRefObject<boolean>;
  virtuosoRef: React.MutableRefObject<VirtuosoHandle | null>;
  messengerMessagesLength: number;
  forceRerunMessagesLengthChange: number;
};

export function useMessagesLengthChange({
  isAddedNewMessageToThread,
  virtuosoRef,
  messengerMessagesLength,
  forceRerunMessagesLengthChange,
}: UseMessagesLengthChangeProps) {
  useEffect(
    function handleMessagesLengthChange() {
      if (isAddedNewMessageToThread.current) {
        virtuosoRef?.current?.scrollToIndex?.({
          index: messengerMessagesLength,
          behavior: 'smooth',
        });
        isAddedNewMessageToThread.current = false;
      }
    },
    [messengerMessagesLength, forceRerunMessagesLengthChange] // eslint-disable-line react-hooks/exhaustive-deps
  );
}
