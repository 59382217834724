import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Toastr from 'toastr';
import * as yup from 'yup';

import { Button } from '@/app/components/Button/Button';
import { Form } from '@/app/components/Form/Form';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { Label } from '@/app/components/Form/Label/Label';
import { MultipleFileControl } from '@/app/components/Form/MultipleFileControl/MultipleFileControl';
import { useForm } from '@/app/components/Form/useForm';
import { handleBackEndValidation, handleFrontEndValidations } from '@/app/components/Form/utils';
import { EMPTY_VALUE } from '@/app/components/RichTextControl/constants';
import { RichTextControl } from '@/app/components/RichTextControl/RichTextControl';
import {
  SUPPORT_TICKET_ATTACHMENT_ACCEPT,
  SUPPORT_TICKET_ATTACHMENT_MAX_FILES,
  SUPPORT_TICKET_ATTACHMENT_MAX_SIZE,
} from '@/app/modules/support-ticket/constants';
import {
  createSupportTicketFromCantactUsForm,
  CreateSupportTicketResponse,
  getSupportTicketsNotifications,
} from '@/app/modules/support-ticket/service';
import { useAppDispatch } from '@/redux/store';

import { SupportTicketValues } from '../models';
import supportTicketStore from '../store';

type SupportTicketModalProps = {
  isModalShown: boolean;
  handleModalClose: () => void;
};

export function SupportTicketContactUsModal({ isModalShown, handleModalClose }: SupportTicketModalProps) {
  const dispatch = useAppDispatch();

  const initialValues = useMemo<SupportTicketValues>(
    () => ({
      message: EMPTY_VALUE,
      attachments: [],
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        message: yup.array().requiredRichText(),
        attachments: yup.array().optional(),
      }),
    []
  );

  const formik = useForm<SupportTicketValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const submitWithValidation = handleBackEndValidation<SupportTicketValues>(async (values) => {
        return await createSupportTicketFromCantactUsForm(values);
      });

      const response = (await submitWithValidation(values, formikHelpers)) as CreateSupportTicketResponse;

      if (response.supportTicketID) {
        await dispatch(supportTicketStore.actions.resetNotifications());
        dispatch(getSupportTicketsNotifications({ page: 1 }));
        handleModalClose();
        Toastr.success('Успешно изпратихте запитване!');
      }
    },
  });

  return (
    <Modal show={isModalShown} onHide={handleModalClose} centered>
      <Modal.Header className="border-0 flex-wrap position-relative">
        <Modal.Title className="ms-auto flex-grow-1 px-10 fs-2 fw-bold text-center">
          Опишете подробно Вашите критерии и екипът ни ще се постарае да намери най-подходящото за Вас обучение.
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-md-10 pt-md-0">
        <Form
          formik={formik}
          disabled={formik.isSubmitting}
          onSubmit={handleFrontEndValidations(formik)}
          skipDirtyPrompt
        >
          <FormGroup controlId="message">
            <Label>Вашето запитване</Label>
            <RichTextControl name="message" />
          </FormGroup>
          <FormGroup controlId="attachments">
            <Label>Файлове</Label>
            <MultipleFileControl
              name="attachments"
              accept={SUPPORT_TICKET_ATTACHMENT_ACCEPT}
              maxSize={SUPPORT_TICKET_ATTACHMENT_MAX_SIZE}
              maxFiles={SUPPORT_TICKET_ATTACHMENT_MAX_FILES}
            />
          </FormGroup>

          <div className="d-flex justify-content-center flex-wrap mt-7 font-weight-bold font-size-h6">
            <Button type="submit" variant="primary" className="me-5 mb-3 mb-sm-0">
              Изпрати
            </Button>

            <Button onClick={handleModalClose} variant="light-primary" className="mb-3 mb-sm-0">
              Откажи
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
