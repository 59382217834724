import { ChangeEvent } from 'react';
import { Form, FormCheck, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import clsx from 'clsx';
import { useField } from 'formik';
import produce from 'immer';

import { CheckboxControlProps } from './models';

import './CheckboxControl.scss';

export function CheckboxControl({
  name,
  value,
  label,
  className,
  hasSolidBackground = true,
  isInline = false,
  isDisabled = false,
  labelFirst = false,
  hasBorder = false,
  labelSubtitle,
  tooltipTitle,
}: CheckboxControlProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, fieldMeta, fieldHelpers] = useField({ name, value, type: 'checkbox' });
  const isInvalid = Boolean(fieldMeta.error);
  const id = `${name}[${value}]`;
  let isChecked = false;

  if (Array.isArray(fieldMeta.value)) {
    isChecked = fieldMeta.value?.includes?.(value) || fieldMeta.value?.includes?.(String(value));
  }

  if (!Array.isArray(fieldMeta.value)) {
    isChecked = fieldMeta.value === value || fieldMeta.value === String(value);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (Array.isArray(fieldMeta.value) && event.target.checked && !fieldMeta.value?.includes?.(value)) {
      fieldHelpers.setValue(
        produce(fieldMeta.value, (draft) => {
          draft.push(value);
        })
      );
      return;
    }

    if (
      Array.isArray(fieldMeta.value) &&
      !event.target.checked &&
      (fieldMeta.value?.includes?.(value) || fieldMeta.value?.includes(String(value)))
    ) {
      fieldHelpers.setValue(
        produce(fieldMeta.value, (draft) => {
          const idx = draft.findIndex((x) => x === value || x === String(value));

          if (idx !== -1) {
            draft.splice(idx, 1);
          }
        })
      );
      return;
    }

    if (fieldMeta.value === 0) {
      return fieldHelpers.setValue(1);
    }

    if (fieldMeta.value === 1) {
      return fieldHelpers.setValue(0);
    }
  }

  const checkboxTemplate = (
    <FormCheck.Label
      htmlFor={id}
      className={clsx(className, {
        'me-5': isInline,
        'is-invalid': isInvalid,
        border: hasBorder,
        selected: hasBorder && isChecked,
      })}
    >
      {Boolean(labelFirst && !labelSubtitle) && <span>{label}</span>}
      <Form.Check
        type="checkbox"
        inline={isInline}
        label={!labelFirst && !labelSubtitle ? label : ''}
        onChange={handleChange}
        checked={isChecked}
        className={clsx('form-check-custom', {
          'form-check-solid': hasSolidBackground,
          'fw-bold': !hasSolidBackground,
          'hidden-label': labelSubtitle,
          'd-block': labelSubtitle,
        })}
        id={id}
        disabled={isDisabled}
      />
      {Boolean(label && labelSubtitle) && (
        <>
          <p className="text-dark fs-4 mt-3 mb-0">{label}</p>
          <p className="text-gray-600 fs-5 my-0">{labelSubtitle}</p>
        </>
      )}
    </FormCheck.Label>
  );

  return (
    <>
      {tooltipTitle && (
        <OverlayTrigger overlay={<Tooltip id={`${name}-tooltip`}>{tooltipTitle}</Tooltip>}>
          {checkboxTemplate}
        </OverlayTrigger>
      )}
      {!tooltipTitle && checkboxTemplate}
      {isInvalid && <Feedback type="invalid">{fieldMeta.error}</Feedback>}
    </>
  );
}
