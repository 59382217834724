import { ReactNode } from 'react';
import Sticky from 'react-stickynode';
import clsx from 'clsx';

type StickyProps = {
  children: ReactNode;
  className: string;
  containerClassName?: string;
};

export function StickyHeader({ children, className, containerClassName = 'mb-4' }: StickyProps) {
  return (
    <div className={clsx(containerClassName)}>
      <Sticky enabled={true} top="#kt_header" bottomBoundary="#kt_content" innerZ={2}>
        <div className={clsx('bg-gray-050', className)}>{children}</div>
      </Sticky>
    </div>
  );
}
