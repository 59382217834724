import { PlayVideoModal } from '@/app/components/PlayVideoModal/PlayVideoModal';

const VIMEO_VIDEO_URL = 'https://player.vimeo.com/video/769371804?title=0&byline=0';

type VirtualClassDemoModalProps = {
  isShown: boolean;
  onHide: () => void;
};

export function VirtualClassDemoModal({ isShown, onHide }: VirtualClassDemoModalProps) {
  return <PlayVideoModal isShown={isShown} onHide={onHide} videoFileURL={VIMEO_VIDEO_URL} />;
}
