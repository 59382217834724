import { ReactNode } from 'react';

type AttachmentPreviewMediaProps = {
  children: ReactNode;
};

export function AttachmentPreviewMedia({ children }: AttachmentPreviewMediaProps) {
  return (
    <div className="d-flex justify-content-center align-items-center rounded bg-white w-75px h-75px border">
      {children}
    </div>
  );
}
