import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { toAbsoluteUrl } from '@/_metronic/helpers';
import { useVideoPlayerModal } from '@/app/components/PlayVideoModal/PlayVideoModal';
import { VirtualClassDemoModal } from '@/app/modules/home/components/VirtualClassDemoModal';

type PlatformBenefitCardComponentProps = {
  header: string;
  paragraph: string;
  image: string;
  imageDimensions: {
    width: number;
    height: number;
  };
  webp: string;
  hasVirtualClassDemo?: boolean;
};

export function PlatformBenefitCardComponent({
  header,
  paragraph,
  image,
  imageDimensions,
  webp,
  hasVirtualClassDemo = false,
}: PlatformBenefitCardComponentProps) {
  const { isVideoPlayerModalShown, showVideoPlayerModal, hideVideoPlayerModal } = useVideoPlayerModal();
  return (
    <>
      <Col md={6}>
        <Card className="p-7 benefit rounded border border-secondary h-100">
          <div className="overflow-hidden">
            <picture>
              <source srcSet={toAbsoluteUrl(webp)} type="image/webp" />
              <img
                src={toAbsoluteUrl(image)}
                className="img-fluid"
                width={imageDimensions.width}
                height={imageDimensions.height}
                alt="Виртуална класна стая"
              />
            </picture>
          </div>
          <h2 className="text-gray-700">{header}</h2>
          <p className="text-gray-600">{paragraph}</p>
          {hasVirtualClassDemo && (
            <Link to="" onClick={showVideoPlayerModal}>
              <i className="fas fa-play text-primary me-2" />
              Виж демо на виртуалната стая
            </Link>
          )}
        </Card>
      </Col>
      <VirtualClassDemoModal isShown={isVideoPlayerModalShown} onHide={hideVideoPlayerModal} />
    </>
  );
}
