import { CoursePackageModel } from '@/app/modules/course/create/models';
import { CourseCheckoutData } from '@/app/modules/course/models';
import { EcommerceEventItem } from '@/app/utils/google-analytics/models';
import { calculateDiscountWithoutFormat, calculatePriceWithoutSurchargeWithoutFormat } from '@/app/utils/money';

export function getEcommerceItemFromCourseCheckout(
  courseCheckoutData: CourseCheckoutData,
  coursePackage: CoursePackageModel,
  hasDiscount = false,
  promotionDiscount = 0
): EcommerceEventItem {
  let price = Number(coursePackage?.taxedPrice);
  let discount = 0;

  if (hasDiscount) {
    discount = calculateDiscountWithoutFormat(price, promotionDiscount);
    price = calculatePriceWithoutSurchargeWithoutFormat(price, promotionDiscount);
  }

  return {
    id: Number(courseCheckoutData.course.id),
    name: `${courseCheckoutData.course.subjects?.[0]?.name} ${courseCheckoutData.course.targetAudienceName} ${courseCheckoutData.course.tutorName}`,
    price: price,
    brand: courseCheckoutData.course.tutorName as string,
    category: `${courseCheckoutData.course.subjects?.[0]?.name as string}/${
      courseCheckoutData.course.targetAudienceName as string
    }`,
    variant: Number(coursePackage?.lessonCount),
    quantity: 1,
    currency: 'BGN',
    discount: discount,
  };
}
