import clsx from 'clsx';

type StaticPageVideoItemProps = {
  videoURL: string;
  className?: string;
};

export function StaticPageVideoItem({ videoURL, className }: StaticPageVideoItemProps) {
  return (
    <div className={clsx('d-flex justify-content-center', className)}>
      <iframe className="w-100 h-100 static-page__video" id="video" src={videoURL} frameBorder="0" allowFullScreen />
    </div>
  );
}
