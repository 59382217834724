import { useMemo } from 'react';

import { CheckboxControl } from '@/app/components/Form/CheckboxControl/CheckboxControl';
import { Form } from '@/app/components/Form/Form';
import { SearchControl } from '@/app/components/Form/SearchControl/SearchControl';
import { useForm } from '@/app/components/Form/useForm';
import { KEYWORD_SEARCH_PLACEHOLDER, NO_KEYWORDS_FOUND_MESSAGE } from '@/app/modules/course/index/filters/constants';
import { SubjectKeyword } from '@/app/modules/subject-keywords/models';

import { FilterDesktop } from './components/FilterDesktop';

type KeywordsFilterPageDesktopProps = {
  keywords: SubjectKeyword[];
};

export function KeywordsFilterPageDesktop({ keywords }: KeywordsFilterPageDesktopProps) {
  const formik = useForm({ initialValues: { search: '' } });
  const filteredKeywords = useMemo(() => {
    const search = String(formik.values.search).toLocaleLowerCase();
    return keywords.filter((x) => x.text.toLocaleLowerCase().includes(search));
  }, [formik.values.search, keywords]);

  return (
    <FilterDesktop name="Ключови думи" className="pt-1">
      <Form formik={formik} skipDirtyPrompt className="mb-4">
        <SearchControl placeholder={KEYWORD_SEARCH_PLACEHOLDER} size="sm" />
      </Form>
      <div className="overflow-scroll mh-100px pt-1">
        {filteredKeywords.map((keyword) => (
          <CheckboxControl
            key={keyword.id}
            name="keywordIds"
            value={keyword.id}
            label={keyword.text}
            className="w-100 mb-3 cursor-pointer"
          />
        ))}
      </div>
      {filteredKeywords.length === 0 && <p className="mb-0 fw-bold text-center">{NO_KEYWORDS_FOUND_MESSAGE}</p>}
    </FilterDesktop>
  );
}
