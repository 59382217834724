import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createTableData, TableDataModel } from '@/app/components/Table/models';
import { DEFAULT_PAGE_VALUE } from '@/app/modules/profile/payments/constants';
import { PaymentTransaction } from '@/app/modules/profile/payments/models';
import { getProfilePayments } from '@/app/modules/profile/payments/service';
import { LoadingState } from '@/redux/constants';
import { sharedPendingReducer, sharedRejectedReducer } from '@/redux/utils';

interface ProfilePaymentState {
  loading: LoadingState;
  error?: string | null;
  transactions: TableDataModel<PaymentTransaction[]>;
  currentPage: number;
}

const initialState: ProfilePaymentState = {
  loading: LoadingState.Idle,
  error: null,
  transactions: createTableData(),
  currentPage: DEFAULT_PAGE_VALUE,
};

const profilePaymentSlice = createSlice({
  name: 'profilePayment',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    //#region GET PROFILE PAYMENTS
    builder
      .addCase(getProfilePayments.pending, sharedPendingReducer)
      .addCase(getProfilePayments.rejected, sharedRejectedReducer)
      .addCase(getProfilePayments.fulfilled, (state, action) => {
        if (state.loading === LoadingState.Pending) {
          state.loading = LoadingState.Idle;
          state.currentPage = action.payload.transactions.currentPage;
          state.transactions = action.payload.transactions;
        }
      });
    //#endregion GET PROFILE PAYMENTS
  },
});

export default profilePaymentSlice;
