import React from 'react';
import { Link } from 'react-router-dom';

export function GoBackToDashboardLinkButton() {
  return (
    <Link to="/" className="font-size-h3 font-weight-light">
      Върни се в началото
    </Link>
  );
}
