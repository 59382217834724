import { useEffect, useMemo, useState } from 'react';
import Feedback from 'react-bootstrap/Feedback';
import DatePicker, { registerLocale } from 'react-datepicker';
import clsx from 'clsx';
import { endOfDay, format, isSameDay, parse, startOfDay } from 'date-fns'; // eslint-disable-line import/no-duplicates
import bg from 'date-fns/locale/bg'; // eslint-disable-line import/no-duplicates
import { useFormikContext } from 'formik';

import { DEFAULT_TIME_FORMAT } from '@/app/components/Form/DatePickerControl/constants';
import { CustomDateInput } from '@/app/components/Form/DatePickerControl/CustomDateInput';
import { isDateValid } from '@/app/components/Form/DatePickerControl/utils';
import { isString } from '@/app/utils/isString';

import '../DatePickerControl/DatePickerControl.scss';

registerLocale('bg', bg);

type DatePickerControlProps = {
  name: string;
  dateFormat?: string;
  onChange?: (value: Date) => void | unknown | Date;
  dayStart?: number;
  dayEnd?: number;
  timeIntervals?: number;
  isValueDateObject?: boolean;
  isDisabled?: boolean;
};

export function TimePickerControl({
  name,
  dateFormat,
  onChange,
  dayStart,
  dayEnd,
  timeIntervals = 30,
  isValueDateObject = false,
  isDisabled = false,
}: DatePickerControlProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta<string | Date>(name);
  const fieldHelpers = formik.getFieldHelpers<string | Date | null>(name);
  const isInvalid = Boolean(field.error);

  const startOfToday = useMemo(() => {
    if (!dayStart) {
      return startOfDay(new Date());
    }
    const date = new Date();
    date.setHours(dayStart);
    return date;
  }, [dayStart]);
  const endOfToday = useMemo(() => {
    if (!dayEnd) {
      return endOfDay(new Date());
    }
    const date = new Date();
    date.setHours(dayEnd);
    return date;
  }, [dayEnd]);

  const internalDateFormat = useMemo(() => {
    if (dateFormat) {
      return dateFormat;
    }

    return DEFAULT_TIME_FORMAT;
  }, [dateFormat]);

  //#region Handle value changes
  const [internalValue, setInternalValue] = useState<Date | null>(null);

  useEffect(
    function handleFieldValueChange() {
      let newValue: Date | string = field.value;

      if (isString(newValue)) {
        newValue = parse(field.value as string, internalDateFormat, new Date());
      }

      if (isDateValid(newValue)) {
        setInternalValue(newValue);
      } else {
        setInternalValue(null);
        fieldHelpers.setValue(null);
      }
    },
    [field.value] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function handleChange(date: Date) {
    const newDate = onChange?.(date) as Date;

    if (newDate) {
      return fieldHelpers.setValue(newDate);
    }

    setInternalValue(date);

    if (isValueDateObject) {
      return fieldHelpers.setValue(date);
    }

    fieldHelpers.setValue(date ? format(date, internalDateFormat) : null);
  }
  //#endregion Handle value changes

  //#region Min/max time

  const minTime = useMemo(() => {
    let fieldValue = field.value as Date;

    if (isString(fieldValue)) {
      fieldValue = parse(fieldValue, internalDateFormat, new Date());
    }

    if (!isSameDay(fieldValue, new Date())) {
      return startOfToday;
    }

    return startOfToday;
  }, [field.value, internalDateFormat, startOfToday]);
  //#endregion Min/max dates

  return (
    <>
      <DatePicker
        selected={internalValue}
        dateFormat={internalDateFormat}
        onChange={handleChange}
        locale="bg"
        wrapperClassName={clsx({
          'is-invalid': isInvalid,
        })}
        customInput={<CustomDateInput isDisabled={isDisabled} />}
        timeCaption="Час"
        minTime={minTime}
        maxTime={endOfToday}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={timeIntervals}
        disabled={isDisabled}
      />

      {isInvalid && <Feedback type="invalid">{field.error}</Feedback>}
    </>
  );
}
