import { FormEvent, ReactNode, useEffect, useMemo } from 'react';
import { Col, Modal } from 'react-bootstrap';
import clsx from 'clsx';
import { add, endOfDay, format, isAfter, isEqual } from 'date-fns';
import { FormikContextType } from 'formik';
import produce from 'immer';

import { TIME_SLOT_PROPOSAL_PRICE_TYPE_OPTIONS } from '@/app/components/Calendar/constants';
import {
  DEFAULT_TIME_FORMAT,
  END_OF_DAY_TIME_IN_HOURS,
  START_OF_DAY_TIME_IN_HOURS,
} from '@/app/components/Form/DatePickerControl/constants';
import { DatePickerControl } from '@/app/components/Form/DatePickerControl/DatePickerControl';
import { DaysOfWeekControl } from '@/app/components/Form/DaysOfWeekControl/DaysOfWeekControl';
import { Form } from '@/app/components/Form/Form';
import { FormControl } from '@/app/components/Form/FormControl/FormControl';
import { FormGroup } from '@/app/components/Form/FormGroup/FormGroup';
import { PriceControl } from '@/app/components/Form/Price/PriceControl';
import { TimePickerControl } from '@/app/components/Form/TimePickerControl/TimePickerControl';
import { handleFrontEndValidations } from '@/app/components/Form/utils';
import { AsyncSelectControl } from '@/app/components/SelectControl/AsyncSelectControl';
import { SelectControl } from '@/app/components/SelectControl/SelectControl';
import { PlatformTaxFormText } from '@/app/modules/course/components/PlatformTaxFormText';
import { RecurrenceType } from '@/app/modules/course/models';

import { CheckboxControl } from '../Form/CheckboxControl/CheckboxControl';
import { Label } from '../Form/Label/Label';
import { CalendarEventFormValues, RecurrenceTypeID, TimeSlotProposalPriceTypeID } from './models';

type EventFormProps = {
  formik: FormikContextType<CalendarEventFormValues>;
  duration: number;
  recurrenceTypeOptions: RecurrenceType[];
  children?: ReactNode;
  isStartDateTimeDisabled?: boolean;
};

export function EventForm({ formik, duration, children, isStartDateTimeDisabled = false }: EventFormProps) {
  const now = useMemo(() => new Date(), []);
  const endHour = useMemo(() => {
    if (formik.values.startHour) {
      const date = add(formik.values.startHour as Date, { minutes: duration });
      return format(date, DEFAULT_TIME_FORMAT);
    }
  }, [formik.values.startHour, duration]);

  const hasInitialProposal = Boolean(formik.values.hasInitialProposal);
  const isSpecialProposalPriceType = Number(formik.values.proposalPriceType) === TimeSlotProposalPriceTypeID.Special;

  function handleStartDateTimeChange(startDateTime: Date) {
    if (formik.values?.endRecur && isAfter(startDateTime, formik.values?.endRecur)) {
      const endRecurEndOfDay = endOfDay(startDateTime);
      formik.setFieldValue('endRecur', endRecurEndOfDay);
    }
  }

  useEffect(() => {
    if (Number(formik.values.recurrenceTypes?.length) === 0) {
      formik.setFieldValue('recurrenceTypeID', RecurrenceTypeID.Never);
      return;
    }
    if (
      formik.values.recurrenceTypes.includes(RecurrenceTypeID.DailyInterval) &&
      formik.values.recurrenceTypes.includes(RecurrenceTypeID.Weekly)
    ) {
      formik.setFieldValue('recurrenceTypeID', RecurrenceTypeID.WeeklyInterval);
      return;
    }
    if (formik.values.recurrenceTypes.includes(RecurrenceTypeID.DailyInterval)) {
      formik.setFieldValue('recurrenceTypeID', RecurrenceTypeID.DailyInterval);
      return;
    }
    if (formik.values.recurrenceTypes.includes(RecurrenceTypeID.Weekly)) {
      formik.setFieldValue('recurrenceTypeID', RecurrenceTypeID.Weekly);
      return;
    }
  }, [formik.values.recurrenceTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!formik.values.hasProposal) {
      const newValues = produce(formik.values, (draft) => {
        draft.proposalPriceType = TimeSlotProposalPriceTypeID.Standard;
        delete draft.proposalCourse;
        delete draft.proposalStudent;
        delete draft.proposalPrice;
      });
      formik.setValues(newValues);
    }
  }, [formik.values.hasProposal]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleEndRecurChange(endRecur: Date) {
    // Sanity check.
    if (!endRecur) {
      return;
    }

    // STEP 1: Make sure `endRecur` is end of day.
    const endRecurEndOfDay = endOfDay(endRecur);

    if (isEqual(endRecur, endRecurEndOfDay)) {
      return;
    }

    // STEP 2: If not end of day -> set it to end of day.
    return endRecurEndOfDay;
  }

  function handleSubmit(event: FormEvent) {
    event.stopPropagation();
    const submitForm = handleFrontEndValidations(formik);
    return submitForm();
  }

  return (
    <Form formik={formik} disabled={formik.isSubmitting} onSubmit={handleSubmit} skipDirtyPrompt>
      <Modal.Body>
        <div className="d-flex justify-content-between flex-wrap mb-n5">
          <FormGroup controlId="startHour">
            <Label>Начален час</Label>
            <FormGroup controlId="startHour" className="w-125px">
              <TimePickerControl
                name="startHour"
                timeIntervals={duration}
                dayStart={START_OF_DAY_TIME_IN_HOURS}
                dayEnd={END_OF_DAY_TIME_IN_HOURS}
                onChange={handleStartDateTimeChange}
                isDisabled={isStartDateTimeDisabled}
                isValueDateObject
              />
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label>Краен час</Label>
            <p className="mt-4 fs-5 fw-normal">{endHour}</p>
          </FormGroup>
          <FormGroup>
            <Label>Продължителност</Label>
            <p className="mt-4 fs-5 fw-normal">{duration}</p>
          </FormGroup>
        </div>

        <FormGroup controlId="startDateTime">
          <DatePickerControl
            name="startDateTime"
            timeIntervals={duration}
            minDate={now}
            onChange={handleStartDateTimeChange}
            isDisabled={isStartDateTimeDisabled}
            isValueDateObject
          />
        </FormGroup>

        <hr className="mt-n5" />

        <Label>Повторения</Label>

        <FormGroup controlId="recurrenceTypes">
          <div className="d-flex flex-column align-items-start mb-3">
            <CheckboxControl name="recurrenceTypes" label="Дневни" value={RecurrenceTypeID.DailyInterval} />
          </div>
          {formik.values.recurrenceTypes?.includes(RecurrenceTypeID.DailyInterval) && (
            <div className="ms-9">
              <FormGroup className="d-flex mb-3 align-items-center" controlId="count">
                <div className="d-flex flex-column">
                  <FormControl className="w-85px me-3" name="count" />
                </div>
                <Label className={clsx({ 'ms-n13 mb-8': formik.errors.count })}>пъти през деня</Label>
              </FormGroup>
              <FormGroup className="d-flex align-items-center" controlId="interval">
                <div className="d-flex flex-column">
                  <FormControl className="w-85px me-3" name="interval" />
                </div>
                <Label className={clsx({ 'ms-n13 mb-8': formik.errors.interval })}>
                  минути интервал между повторенията
                </Label>
              </FormGroup>
            </div>
          )}
          <div className="d-flex flex-column align-items-start my-3">
            <CheckboxControl name="recurrenceTypes" label="Седмични" value={RecurrenceTypeID.Weekly} />
          </div>
          {formik.values.recurrenceTypes?.includes(RecurrenceTypeID.Weekly) && (
            <div className="ms-9">
              <DaysOfWeekControl name="daysOfWeek" formGroupClassName="mb-7" />
              <FormGroup controlId="endRecur">
                <Label>Крайна дата на повторенията</Label>
                <DatePickerControl
                  name="endRecur"
                  timeIntervals={duration}
                  minDate={now}
                  onChange={handleEndRecurChange}
                  minDateFieldName="startDateTime"
                  isValueDateObject
                />
              </FormGroup>
            </div>
          )}
        </FormGroup>

        {!formik.values.isStudentProposal && (
          <>
            <Label>Допълнителни настройки</Label>

            <FormGroup>
              <CheckboxControl
                name="hasProposal"
                label="Може да присъства само конкретен обучаем"
                value={1}
                isDisabled={hasInitialProposal}
              />
              {hasInitialProposal && (
                <div className="mb-1">
                  <i className="far fa-exclamation-triangle text-gray-700 me-1" />
                  <span>
                    За да промените допълнителните настройки, отменете отправеното предложение от страница График.
                  </span>
                </div>
              )}
              {Boolean(formik.values.hasProposal) && (
                <div className="ms-9">
                  <FormGroup controlId="proposalStudent" className="mb-2 row g-5 align-items-center">
                    <Col md={3} className="text-end">
                      <Label>Обучаем</Label>
                    </Col>
                    <Col md={9}>
                      <AsyncSelectControl
                        className="mw-325px"
                        placeholder="Изберете обучаем"
                        name="proposalStudent"
                        endpoint="/ajax/schedule/proposable-students"
                        isDisabled={hasInitialProposal}
                        hasSolidBackground
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup controlId="proposalCourse" className="mb-2 row g-5 align-items-center">
                    <Col md={3} className="text-end">
                      <Label>Обучение</Label>
                    </Col>
                    <Col md={9}>
                      <AsyncSelectControl
                        className="mw-325px"
                        placeholder="Изберете обучение"
                        name="proposalCourse"
                        endpoint="/ajax/schedule/proposable-courses"
                        isDisabled={hasInitialProposal}
                        hasSolidBackground
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup controlId="proposalPriceType" className="mb-2 row g-5 align-items-center">
                    <Col md={3} className="text-end">
                      <Label>Заплащане</Label>
                    </Col>
                    <Col md={9}>
                      <SelectControl
                        className="mw-325px"
                        name="proposalPriceType"
                        options={TIME_SLOT_PROPOSAL_PRICE_TYPE_OPTIONS}
                        isDisabled={hasInitialProposal}
                        hasSolidBackground
                      />
                    </Col>
                  </FormGroup>
                  {isSpecialProposalPriceType && (
                    <>
                      <FormGroup controlId="proposalPrice" className="row mb-2 g-5 align-items-center">
                        <Col md={3} className="text-end">
                          <Label>Цена на урок</Label>
                        </Col>
                        <Col md={9}>
                          <PriceControl
                            className="mw-325px"
                            name="proposalPrice"
                            disabled={hasInitialProposal}
                            hasSolidBackground
                          />
                        </Col>
                      </FormGroup>
                      <div className="d-flex justify-content-center">
                        <PlatformTaxFormText taxedPrice={formik.values.proposalPrice as number} className="mt-0" />
                      </div>
                    </>
                  )}
                </div>
              )}
            </FormGroup>
          </>
        )}
      </Modal.Body>
      {children}
    </Form>
  );
}
